var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "open-street-map fill-height" },
    [
      _c(
        "v-overlay",
        { attrs: { value: _vm.show } },
        [
          _c("v-progress-circular", {
            attrs: { color: "primary", indeterminate: "", size: "64" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "editable-map",
        {
          ref: "map",
          staticStyle: { height: "100%" },
          attrs: {
            editable: "",
            options: _vm.mapOptions,
            maxZoom: _vm.config.map_max_zoom,
            minZoom: _vm.config.map_min_zoom,
            center: _vm.defaultCenter
          },
          on: {
            ready: _vm.ready,
            "update:bounds": _vm.mapMovement,
            "update:zoom": _vm.mapZoom
          }
        },
        [
          _c("TileLayers"),
          _vm._v(" "),
          _vm.isMobileView
            ? _c(
                "l-control",
                { attrs: { position: "bottomright" } },
                [
                  _c(
                    "v-fab-transition",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", fab: "", elevation: "20" },
                          on: {
                            click: function($event) {
                              _vm.modal.objects = true
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "l-control",
            { attrs: { position: "bottomleft" } },
            [
              !_vm.uiSettings.map_control
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "", color: "tooltip" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          color: "white",
                                          elevation: "20",
                                          width: "40",
                                          height: "40",
                                          "min-width": "40",
                                          light: ""
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.saveUISettings({
                                              prop: "map_control",
                                              value: true
                                            })
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "primary" } },
                                      [_vm._v("mdi-tune")]
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2552642791
                      )
                    },
                    [_vm._v(" "), _c("span", [_vm._v("Controls")])]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.uiSettings.map_control
                ? _c(
                    "v-sheet",
                    {
                      directives: [
                        {
                          name: "resize",
                          rawName: "v-resize",
                          value: _vm.onResize,
                          expression: "onResize"
                        }
                      ],
                      staticClass: "pa-1 cursor-pointer",
                      staticStyle: { overflow: "auto" },
                      attrs: {
                        elevation: "3",
                        width: "auto",
                        rounded: "",
                        dark: "",
                        height:
                          _vm.contentHeight > 425
                            ? "auto"
                            : _vm.contentHeight - 20
                      }
                    },
                    [
                      _c(
                        "v-container",
                        [
                          _c(
                            "v-row",
                            { staticClass: "d-flex flex-grow-1 justify-end" },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", color: "grey", small: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.saveUISettings({
                                        prop: "map_control",
                                        value: false
                                      })
                                    }
                                  }
                                },
                                [_c("v-icon", [_vm._v("mdi-close")])],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          !_vm.historyRouteData
                            ? _c(
                                "v-row",
                                { staticClass: "d-flex flex-column" },
                                [
                                  _c("p", { staticClass: "text-subtitle-1" }, [
                                    _vm._v("Object Controls")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-row flex-wrap mb-2"
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            bottom: "",
                                            color: "tooltip"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "div",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column mr-2",
                                                            staticStyle: {
                                                              width: "50px"
                                                            }
                                                          },
                                                          "div",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  depressed:
                                                                    _vm.toggles
                                                                      .trackers,
                                                                  outlined: !_vm
                                                                    .toggles
                                                                    .trackers,
                                                                  color: _vm
                                                                    .toggles
                                                                    .trackers
                                                                    ? "#ffa700"
                                                                    : "grey",
                                                                  height:
                                                                    "45px",
                                                                  width: "45px",
                                                                  "min-width":
                                                                    "40px"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.updateToggleProperty(
                                                                      "trackers"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("v-img", {
                                                                  attrs: {
                                                                    width:
                                                                      "30px",
                                                                    height:
                                                                      "30px",
                                                                    src:
                                                                      _vm.publicPath +
                                                                      "assets/img/images/object-arrow.svg"
                                                                  }
                                                                })
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-body-2 text-center",
                                                            staticStyle: {
                                                              color: "#c8c8c8"
                                                            }
                                                          },
                                                          [_vm._v("Tracker")]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            1815621492
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [_vm._v("Tracker")])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            bottom: "",
                                            color: "tooltip"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "div",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column mr-2",
                                                            staticStyle: {
                                                              width: "50px"
                                                            }
                                                          },
                                                          "div",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  depressed:
                                                                    _vm.toggles
                                                                      .object_labels,
                                                                  outlined: !_vm
                                                                    .toggles
                                                                    .object_labels,
                                                                  color: _vm
                                                                    .toggles
                                                                    .object_labels
                                                                    ? "#ffa700"
                                                                    : "grey",
                                                                  height:
                                                                    "45px",
                                                                  width: "45px",
                                                                  "min-width":
                                                                    "40px"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.updateToggleProperty(
                                                                      "object_labels"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("v-img", {
                                                                  attrs: {
                                                                    width:
                                                                      "30px",
                                                                    height:
                                                                      "30px",
                                                                    src:
                                                                      _vm.publicPath +
                                                                      "assets/img/images/text.svg"
                                                                  }
                                                                })
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-body-2 text-center",
                                                            staticStyle: {
                                                              color: "#c8c8c8"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Object Label"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            232883491
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [_vm._v("Object Label")])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            bottom: "",
                                            color: "tooltip"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "div",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column mr-2",
                                                            staticStyle: {
                                                              width: "50px"
                                                            }
                                                          },
                                                          "div",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  depressed:
                                                                    _vm.toggles
                                                                      .clusters,
                                                                  outlined: !_vm
                                                                    .toggles
                                                                    .clusters,
                                                                  color: _vm
                                                                    .toggles
                                                                    .clusters
                                                                    ? "#ffa700"
                                                                    : "grey",
                                                                  height:
                                                                    "45px",
                                                                  width: "45px",
                                                                  "min-width":
                                                                    "40px"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.updateToggleProperty(
                                                                      "clusters"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("v-img", {
                                                                  attrs: {
                                                                    width:
                                                                      "30px",
                                                                    height:
                                                                      "30px",
                                                                    src:
                                                                      _vm.publicPath +
                                                                      "assets/img/images/clusters.svg"
                                                                  }
                                                                })
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-body-2 text-center",
                                                            staticStyle: {
                                                              color: "#c8c8c8"
                                                            }
                                                          },
                                                          [_vm._v("Cluster")]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            1698326718
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [_vm._v("Cluster")])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.historyRouteData
                            ? _c(
                                "v-row",
                                { staticClass: "d-flex flex-column" },
                                [
                                  _c("v-divider", { staticClass: "mb-2" }),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "text-subtitle-1" }, [
                                    _vm._v("Place Controls")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-row flex-wrap mb-2"
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            bottom: "",
                                            color: "tooltip"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "div",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column mr-2",
                                                            staticStyle: {
                                                              width: "50px"
                                                            }
                                                          },
                                                          "div",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  depressed:
                                                                    _vm.toggles
                                                                      .zones,
                                                                  outlined: !_vm
                                                                    .toggles
                                                                    .zones,
                                                                  color: _vm
                                                                    .toggles
                                                                    .zones
                                                                    ? "#ffa700"
                                                                    : "grey",
                                                                  height:
                                                                    "45px",
                                                                  width: "45px",
                                                                  "min-width":
                                                                    "40px"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.updateMapToggles(
                                                                      "zones"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("v-img", {
                                                                  attrs: {
                                                                    width:
                                                                      "30px",
                                                                    height:
                                                                      "30px",
                                                                    src:
                                                                      _vm.publicPath +
                                                                      "assets/img/images/zones.svg"
                                                                  }
                                                                })
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-body-2 text-center",
                                                            staticStyle: {
                                                              color: "#c8c8c8"
                                                            }
                                                          },
                                                          [_vm._v("Zone")]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            4177601782
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [_vm._v("Zone")])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            bottom: "",
                                            color: "tooltip"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "div",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column mr-2",
                                                            staticStyle: {
                                                              width: "50px"
                                                            }
                                                          },
                                                          "div",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  depressed:
                                                                    _vm.toggles
                                                                      .markers,
                                                                  outlined: !_vm
                                                                    .toggles
                                                                    .markers,
                                                                  color: _vm
                                                                    .toggles
                                                                    .markers
                                                                    ? "#ffa700"
                                                                    : "grey",
                                                                  height:
                                                                    "45px",
                                                                  width: "45px",
                                                                  "min-width":
                                                                    "40px"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.updateMapToggles(
                                                                      "markers"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("v-img", {
                                                                  attrs: {
                                                                    width:
                                                                      "30px",
                                                                    height:
                                                                      "30px",
                                                                    src:
                                                                      _vm.publicPath +
                                                                      "assets/img/images/marker.svg"
                                                                  }
                                                                })
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-body-2 text-center",
                                                            staticStyle: {
                                                              color: "#c8c8c8"
                                                            }
                                                          },
                                                          [_vm._v("Marker")]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2474161573
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [_vm._v("Marker")])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            bottom: "",
                                            color: "tooltip"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "div",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column mr-2",
                                                            staticStyle: {
                                                              width: "50px"
                                                            }
                                                          },
                                                          "div",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  depressed:
                                                                    _vm.toggles
                                                                      .routes,
                                                                  outlined: !_vm
                                                                    .toggles
                                                                    .routes,
                                                                  color: _vm
                                                                    .toggles
                                                                    .routes
                                                                    ? "#ffa700"
                                                                    : "grey",
                                                                  height:
                                                                    "45px",
                                                                  width: "45px",
                                                                  "min-width":
                                                                    "40px"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.updateMapToggles(
                                                                      "routes"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("v-img", {
                                                                  attrs: {
                                                                    width:
                                                                      "30px",
                                                                    height:
                                                                      "30px",
                                                                    src:
                                                                      _vm.publicPath +
                                                                      "assets/img/images/routes.svg"
                                                                  }
                                                                })
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-body-2 text-center",
                                                            staticStyle: {
                                                              color: "#c8c8c8"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t("ROUTES")
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            3598932025
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.$t("ROUTES")))
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.historyRouteData
                            ? _c(
                                "v-row",
                                { staticClass: "d-flex flex-column" },
                                [
                                  _c("v-divider", { staticClass: "mb-2" }),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "text-subtitle-1" }, [
                                    _vm._v("Map Controls")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-row flex-wrap mb-2"
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            bottom: "",
                                            color: "tooltip"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "div",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column mr-2",
                                                            staticStyle: {
                                                              width: "50px"
                                                            }
                                                          },
                                                          "div",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  depressed:
                                                                    _vm.toggles
                                                                      .fit_all,
                                                                  outlined: !_vm
                                                                    .toggles
                                                                    .fit_all,
                                                                  color: _vm
                                                                    .toggles
                                                                    .fit_all
                                                                    ? "#ffa700"
                                                                    : "grey",
                                                                  height:
                                                                    "45px",
                                                                  width: "45px",
                                                                  "min-width":
                                                                    "40px"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.fitAllToggleClicked = true
                                                                    _vm.updateMapToggles(
                                                                      "fit_all"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("v-img", {
                                                                  attrs: {
                                                                    width:
                                                                      "30px",
                                                                    height:
                                                                      "30px",
                                                                    src:
                                                                      _vm.publicPath +
                                                                      "assets/img/images/fit.svg"
                                                                  }
                                                                })
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-body-2 text-center",
                                                            staticStyle: {
                                                              color: "#c8c8c8"
                                                            }
                                                          },
                                                          [_vm._v("Fit All")]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            3722427257
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [_vm._v("Fit All")])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.historyRouteData
                            ? _c(
                                "v-row",
                                { staticClass: "d-flex flex-column" },
                                [
                                  _c("p", { staticClass: "text-subtitle-1" }, [
                                    _vm._v("History Controls")
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-row flex-wrap mb-2"
                                    },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            bottom: "",
                                            color: "tooltip"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "div",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column mr-2",
                                                            staticStyle: {
                                                              width: "50px"
                                                            }
                                                          },
                                                          "div",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  depressed:
                                                                    _vm.toggles
                                                                      .history
                                                                      .route,
                                                                  outlined: !_vm
                                                                    .toggles
                                                                    .history
                                                                    .route,
                                                                  color: _vm
                                                                    .toggles
                                                                    .history
                                                                    .route
                                                                    ? "#ffa700"
                                                                    : "grey",
                                                                  height:
                                                                    "45px",
                                                                  width: "45px",
                                                                  "min-width":
                                                                    "40px"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.updateToggleHistoryProperty(
                                                                      "route"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("v-img", {
                                                                  attrs: {
                                                                    width:
                                                                      "30px",
                                                                    height:
                                                                      "30px",
                                                                    src:
                                                                      _vm.publicPath +
                                                                      "assets/img/images/route-route.svg"
                                                                  }
                                                                })
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-body-2 text-center",
                                                            staticStyle: {
                                                              color: "#c8c8c8"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t("ROUTE")
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            1051565387
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(_vm.$t("ROUTE")))
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            bottom: "",
                                            color: "tooltip"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "div",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column mr-2",
                                                            staticStyle: {
                                                              width: "50px"
                                                            }
                                                          },
                                                          "div",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  depressed:
                                                                    _vm.toggles
                                                                      .history
                                                                      .snap,
                                                                  outlined: !_vm
                                                                    .toggles
                                                                    .history
                                                                    .snap,
                                                                  color: _vm
                                                                    .toggles
                                                                    .history
                                                                    .snap
                                                                    ? "#ffa700"
                                                                    : "grey",
                                                                  height:
                                                                    "45px",
                                                                  width: "45px",
                                                                  "min-width":
                                                                    "40px"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.updateToggleHistoryProperty(
                                                                      "snap"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("v-img", {
                                                                  attrs: {
                                                                    width:
                                                                      "30px",
                                                                    height:
                                                                      "30px",
                                                                    src:
                                                                      _vm.publicPath +
                                                                      "assets/img/images/route-snap.svg"
                                                                  }
                                                                })
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-body-2 text-center",
                                                            staticStyle: {
                                                              color: "#c8c8c8"
                                                            }
                                                          },
                                                          [_vm._v("Route Snap")]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            89440046
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [_vm._v("Route Snap")])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            bottom: "",
                                            color: "tooltip"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "div",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column mr-2",
                                                            staticStyle: {
                                                              width: "50px"
                                                            }
                                                          },
                                                          "div",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  depressed:
                                                                    _vm.toggles
                                                                      .history
                                                                      .arrows,
                                                                  outlined: !_vm
                                                                    .toggles
                                                                    .history
                                                                    .arrows,
                                                                  color: _vm
                                                                    .toggles
                                                                    .history
                                                                    .arrows
                                                                    ? "#ffa700"
                                                                    : "grey",
                                                                  height:
                                                                    "45px",
                                                                  width: "45px",
                                                                  "min-width":
                                                                    "40px"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.updateToggleHistoryProperty(
                                                                      "arrows"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("v-img", {
                                                                  attrs: {
                                                                    width:
                                                                      "30px",
                                                                    height:
                                                                      "30px",
                                                                    src:
                                                                      _vm.publicPath +
                                                                      "assets/img/images/route-arrow.svg"
                                                                  }
                                                                })
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-body-2 text-center",
                                                            staticStyle: {
                                                              color: "#c8c8c8"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Route Arrow"
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            982062926
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [_vm._v("Route Arrow")])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            bottom: "",
                                            color: "tooltip"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "div",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column mr-2",
                                                            staticStyle: {
                                                              width: "50px"
                                                            }
                                                          },
                                                          "div",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  depressed:
                                                                    _vm.toggles
                                                                      .history
                                                                      .data_points,
                                                                  outlined: !_vm
                                                                    .toggles
                                                                    .history
                                                                    .data_points,
                                                                  color: _vm
                                                                    .toggles
                                                                    .history
                                                                    .data_points
                                                                    ? "#ffa700"
                                                                    : "grey",
                                                                  height:
                                                                    "45px",
                                                                  width: "45px",
                                                                  "min-width":
                                                                    "40px"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.updateToggleHistoryProperty(
                                                                      "data_points"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("v-img", {
                                                                  attrs: {
                                                                    width:
                                                                      "30px",
                                                                    height:
                                                                      "30px",
                                                                    src:
                                                                      _vm.publicPath +
                                                                      "assets/img/images/route-data-point.svg"
                                                                  }
                                                                })
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-body-2 text-center",
                                                            staticStyle: {
                                                              color: "#c8c8c8"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t("ROUTE")
                                                              ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "DATA_POINTS"
                                                                  )
                                                                )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            1209851279
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("ROUTE")) +
                                                " " +
                                                _vm._s(_vm.$t("DATA_POINTS"))
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            bottom: "",
                                            color: "tooltip"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "div",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column mr-2",
                                                            staticStyle: {
                                                              width: "50px"
                                                            }
                                                          },
                                                          "div",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  depressed:
                                                                    _vm.toggles
                                                                      .history
                                                                      .stops,
                                                                  outlined: !_vm
                                                                    .toggles
                                                                    .history
                                                                    .stops,
                                                                  color: _vm
                                                                    .toggles
                                                                    .history
                                                                    .stops
                                                                    ? "#ffa700"
                                                                    : "grey",
                                                                  height:
                                                                    "45px",
                                                                  width: "45px",
                                                                  "min-width":
                                                                    "40px"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.updateToggleHistoryProperty(
                                                                      "stops"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("v-img", {
                                                                  attrs: {
                                                                    width:
                                                                      "30px",
                                                                    height:
                                                                      "30px",
                                                                    src:
                                                                      _vm.publicPath +
                                                                      "assets/img/images/route-stop.svg"
                                                                  }
                                                                })
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-body-2 text-center",
                                                            staticStyle: {
                                                              color: "#c8c8c8"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t("ROUTE")
                                                              ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm.$t("STOP")
                                                                )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            2396425134
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("ROUTE")) +
                                                " " +
                                                _vm._s(_vm.$t("STOP"))
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            bottom: "",
                                            color: "tooltip"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "div",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "d-flex flex-column",
                                                            staticStyle: {
                                                              width: "50px"
                                                            }
                                                          },
                                                          "div",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c(
                                                          "div",
                                                          [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  depressed:
                                                                    _vm.toggles
                                                                      .history
                                                                      .events,
                                                                  outlined: !_vm
                                                                    .toggles
                                                                    .history
                                                                    .events,
                                                                  color: _vm
                                                                    .toggles
                                                                    .history
                                                                    .events
                                                                    ? "#ffa700"
                                                                    : "grey",
                                                                  height:
                                                                    "45px",
                                                                  width: "45px",
                                                                  "min-width":
                                                                    "40px"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.updateToggleHistoryProperty(
                                                                      "events"
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("v-img", {
                                                                  attrs: {
                                                                    width:
                                                                      "30px",
                                                                    height:
                                                                      "30px",
                                                                    src:
                                                                      _vm.publicPath +
                                                                      "assets/img/images/route-event.svg"
                                                                  }
                                                                })
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-body-2 text-center",
                                                            staticStyle: {
                                                              color: "#c8c8c8"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t("ROUTE")
                                                              ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "EVENT"
                                                                  )
                                                                )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            1749970926
                                          )
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("ROUTE")) +
                                                " " +
                                                _vm._s(_vm.$t("EVENT"))
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.searchPopup !== null
            ? _c(
                "l-marker",
                {
                  ref: "searchMarker",
                  attrs: { "lat-lng": _vm.searchPopup.latlng, opacity: 0 }
                },
                [
                  _c(
                    "l-popup",
                    [
                      _c(
                        "v-container",
                        { staticClass: "pa-1" },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { staticClass: "py-0", attrs: { cols: "12" } },
                                [
                                  _c("p", { staticClass: "text-subtitle-1" }, [
                                    _vm._v(_vm._s(_vm.searchPopup.address))
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "", color: "black" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      { attrs: { block: "" } },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { dark: "" } },
                                                      [
                                                        _vm._v(
                                                          " mdi-content-copy "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        3752153686
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [_vm._v("Copy as message")])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "", color: "black" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          block: "",
                                                          color: "primary"
                                                        }
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { dark: "" } },
                                                      [
                                                        _vm._v(
                                                          " mdi-map-marker-plus "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        4277470420
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [_vm._v("Save as marker")])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.searchPopup !== null
        ? _c(
            "v-snackbar",
            {
              attrs: {
                dark: "",
                top: "",
                text: "",
                value: _vm.searchPopup !== null,
                timeout: "-1"
              }
            },
            [
              _c(
                "v-container",
                { staticClass: "pa-1" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "py-0", attrs: { cols: "12" } },
                        [
                          _c("p", { staticClass: "text-subtitle-1" }, [
                            _vm._v(_vm._s(_vm.searchPopup.address))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "", color: "black" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              { attrs: { block: "" } },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { dark: "" } },
                                              [_vm._v(" mdi-content-copy ")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3752153686
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [_vm._v("Copy as message")])
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "", color: "black" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  block: "",
                                                  color: "primary"
                                                },
                                                on: { click: _vm.saveAsMarker }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { dark: "" } },
                                              [_vm._v(" mdi-map-marker-plus ")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                3699916128
                              )
                            },
                            [
                              _vm._v(" "),
                              _c("span", [_vm._v("Save as marker")])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("ObjectsForm", {
        attrs: { show: _vm.modal.objects },
        on: { "show-objects-dialog": _vm.setShowObjectsModal }
      }),
      _vm._v(" "),
      _c("ConfirmDialog", {
        attrs: {
          info: _vm.confirmDetails,
          show: _vm.historyProgress.showConfirm
        },
        on: { "show-confirm-dialog": _vm.confirmHistoryLoad }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }