import { imagesService } from '@/services/images'
import Vue from 'vue'

const actions = {
  
  async fetchEventVideos ({ commit }, params = null) {
    return await imagesService
      .getEventVideos(params)
      .then(function (response) {
        Vue.$log.debug('fetchEventVideos', 'success')
        if (params && !Object.keys(params).length) {
          // not filtered
        } else {
        }

        commit('SET_EVENT_VIDEOS', response.data)
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },

  async requestVideoFootage ({ commit }, params) {
    if (process.env.NODE_ENV === 'development') {
      var response = {"err":"Request Sent, please wait","id":2}
      var checkVideoRequest = response.hasOwnProperty('message') ? true : false;
      commit('SET_HAS_VIDEO_REQUEST', checkVideoRequest)
      return response
    }
    return await imagesService
      .requestVideoFootage(params)
      .then(function (response) {
        Vue.$log.debug('requestVideoFootage', 'success')
        var checkVideoRequest = response.data.hasOwnProperty('message') ? true : false;
        commit('SET_HAS_VIDEO_REQUEST', checkVideoRequest)

          if (response.data.hasOwnProperty('message')) {
            Vue.notify({
              type: 'info',
              title: "INFO",
              text: response.data.message,
              duration: 5000,
              closeOnClick: false
            });
          } else {
            // console.log(response.data.err);
            Vue.notify({
              type: 'error',
              title: "ERROR",
              text: response.data.err,
              duration: 5000,
              closeOnClick: false
            });
          }
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },

  async fetchEventVideo ({ commit }, params) {
    if (process.env.NODE_ENV === 'development') {
      return [{"img_id":48,"dt_tracker":"2023-04-14 07:17:33","imei":"864431044831123","obj_name":"864431044831123","img_file":"https:\/\/gatortwa.s3.ap-southeast-2.amazonaws.com\/861585046396576\/20230414091331_9509_man.mp4?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2U3K2AVLYVW63UOZ%2F20230515%2Fap-southeast-2%2Fs3%2Faws4_request&X-Amz-Date=20230515T101302Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7200&X-Amz-Signature=68ab2f19ce985db0c81c4d29f9b7cbc67b22079054c1fcb2ae17e7192bf26fff","lat":-35.25689,"lng":147.75676,"angle":0,"speed":47,"params":{"acc":"1","arm":"0","batl":"6","cellid":7211,"gpslev":"8","gsmlev":"4","lac":"0000","mcc":"01F9","mnc":"03","odo":32149},"location":"","event_id":0}];
    }
    return await imagesService
      .getVideo(params)
      .then(function (response) {
        Vue.$log.debug('getVideo', 'success')
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },


  async fetchVideoRequestQueue ({ commit }, params) {
    if (process.env.NODE_ENV === 'development') {
      // var response = {"pending":[{"msg_id":6,"dt_server":"2023-05-19 07:18:14","imei":"861585046396576","side":"A","msg":"","status":0}],"complete":[]}

      var response = {"pending":[],"complete":[{"msg_id":6,"dt_server":"2023-05-19 07:18:14","imei":"861585046396576","side":"A","msg":"a","status":2},{"msg_id":7,"dt_server":"2023-05-19 07:18:14","imei":"861585046396576","side":"B","msg":"a","status":4}]};
      commit('SET_VIDEO_REQUESTS', response)
      return response
    }
    return await imagesService
      .getVideoRequestQueue(params)
      .then(function (response) {
        
        commit('SET_VIDEO_REQUESTS', response.data)
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },

}

export default actions
