<template>
  <v-container
    fluid
    id="scroll-target"
    style="max-height: calc(100vh - 56px)"
    class="overflow-y-auto"
  >
    <v-row class="ma-1 d-flex flex-column align-content-center">
      <v-col cols="12" md="8" xl="6">
        <v-card outlined class="w-100 mb-3">
          <validation-observer ref="observer">
            <v-container fluid>
              <v-row align="center">
                <v-col class="d-flex" cols="12" sm="6">
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    rules="required"
                    :name="$t('OBJECT')"
                  >
                   <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t("OBJECT") }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("OBJECT") }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                    <v-select
                      menu-props="dark"
                      item-color="#272727"
                      color="grey"
                      v-model="fields.object"
                      :items="objectItems"
                      :label="$t('OBJECT')"
                      item-text="text"
                      item-value="value"
                      hide-details="auto"
                      :error-messages="errors"
                      required
                      solo
                      dense
                      outlined
                    ></v-select
                  ></validation-provider>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    rules="required"
                    :name="$t('FILTER')"
                  >
                  <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t("FILTER") }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("FILTER") }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                    <v-select
                      menu-props="dark"
                      item-color="#272727"
                      @change="setFieldDates()"
                      color="grey"
                      v-model="fields.filter"
                      :items="options.filter"
                      :label="$t('FILTER')"
                      item-text="text"
                      item-value="value"
                      hide-details="auto"
                      :error-messages="errors"
                      required
                      solo
                      dense
                      outlined
                    >
                      <template v-slot:item="{ item }">
                        {{
                          $t(item.trans) == item.trans
                            ? item.text
                            : $t(item.trans)
                        }}
                      </template>
                    </v-select>
                  </validation-provider>
                </v-col>
                <v-col class="d-flex flex-column" cols="12" sm="6" md="3">
                  <v-menu
                    v-model="timeFromPicker.date"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        class="w-100"
                        v-slot="{ errors }"
                        :rules="fields.exact_time === true ? 'required' : ''"
                        :name='$t("TIME_FROM")'
                      >
                        <div class="mb-2 d-flex flex-row">
                          <span class="text-body-2 mr-1">{{$t("TIME_FROM")}}</span>

                          <FormTooltip>
                            <template v-slot:tooltip-trigger>
                              <v-btn x-small color="primary lighten-1" text icon>
                                <v-icon>mdi-information-outline</v-icon>
                              </v-btn>
                            </template>
                            <template v-slot:tooltip-content>{{$t("TIME_FROM")}} </template>
                          </FormTooltip>
                        </div>
                        <v-text-field
                          v-model="fields.time_from.date"
                          :label='$t("TIME_FROM")'
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          required
                          :error-messages="errors"
                          hint="YYYY-MM-DD format"
                          solo
                          dense
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="fields.time_from.date"
                      @input="timeFromPicker.date = false;"
                      :max="max"
                      :min="min"
                    ></v-date-picker>

                  </v-menu>
                </v-col>
                <v-col class="d-flex flex-column" cols="12" sm="6" md="3" >
                  <v-menu
                    v-model="timeFromPicker.time"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        class="w-100"
                        v-slot="{ errors }"
                        :rules="fields.exact_time === true ? 'required' : ''"
                        :name='$t("TIME_FROM")'
                      >
                        <div class="mt-0 mt-sm-5 mb-2 d-flex flex-row">

                          <!-- <span class="text-body-2 mr-1">{{$t("TIME_FROM")}}</span>

                          <FormTooltip>
                            <template v-slot:tooltip-trigger>
                              <v-btn x-small color="primary lighten-1" text icon>
                                <v-icon>mdi-information-outline</v-icon>
                              </v-btn>
                            </template>
                            <template v-slot:tooltip-content>{{$t("TIME_FROM")}} </template>
                          </FormTooltip> -->
                        </div>
                        <v-text-field
                          v-model="fields.time_from.time"
                          :label='$t("TIME_FROM")'
                          prepend-inner-icon="mdi-clock-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          required
                          :error-messages="errors"
                          solo
                          dense
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                      </validation-provider>
                    </template>

                    <v-time-picker
                      v-model="fields.time_from.time"
                      width="275"
                      @click:minute="timeFromPicker.time = false"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col class="d-flex flex-column" cols="12" sm="6" md="3">
                  <v-menu
                    v-model="timeToPicker.date"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        class="w-100"
                        v-slot="{ errors }"
                        :rules="fields.exact_time === true ? 'required' : ''"
                        :name='$t("TIME_TO")'
                      >
                        <div class="mb-2 d-flex flex-row">
                          <span class="text-body-2 mr-1">{{$t("TIME_TO")}}</span>

                          <FormTooltip>
                            <template v-slot:tooltip-trigger>
                              <v-btn x-small color="primary lighten-1" text icon>
                                <v-icon>mdi-information-outline</v-icon>
                              </v-btn>
                            </template>
                            <template v-slot:tooltip-content>{{$t("TIME_TO")}} </template>
                          </FormTooltip>
                        </div>
                        <v-text-field
                          v-model="fields.time_to.date"
                          :label='$t("TIME_TO")'
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          required
                          :error-messages="errors"
                          hint="YYYY-MM-DD format"
                          solo
                          dense
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="fields.time_to.date"
                      @input="timeToPicker.date = false;"
                      :max="max"
                      :min="min"
                      show-current
                    ></v-date-picker>

                  </v-menu>
                </v-col>
                <v-col class="d-flex flex-column" cols="12" sm="6" md="3" >
                  <v-menu
                    v-model="timeToPicker.time"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        class="w-100"
                        v-slot="{ errors }"
                        :rules="fields.exact_time === true ? 'required' : ''"
                        :name='$t("TIME_TO")'
                      >
                        <div class="mt-0 mt-sm-5 mb-2 d-flex flex-row">

                          <!-- <span class="text-body-2 mr-1">{{$t("TIME_TO")}}</span>

                          <FormTooltip>
                            <template v-slot:tooltip-trigger>
                              <v-btn x-small color="primary lighten-1" text icon>
                                <v-icon>mdi-information-outline</v-icon>
                              </v-btn>
                            </template>
                            <template v-slot:tooltip-content>{{$t("TIME_TO")}} </template>
                          </FormTooltip> -->
                        </div>
                        <v-text-field
                          v-model="fields.time_to.time"
                          :label='$t("TIME_TO")'
                          prepend-inner-icon="mdi-clock-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          required
                          :error-messages="errors"
                          solo
                          dense
                          outlined
                          hide-details="auto"
                        ></v-text-field>
                      </validation-provider>
                    </template>

                    <v-time-picker
                      v-model="fields.time_to.time"
                      width="275"
                      @click:minute="timeToPicker.time = false"
                    ></v-time-picker>
                  </v-menu>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6">
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    rules="required"
                    :name="$t('STOP_DURATION')"
                  >
                  <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t("STOP_DURATION") }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("STOP_DURATION") }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                    <v-select
                      menu-props="dark"
                      item-color="#272727"
                      color="grey"
                      v-model="fields.duration"
                      :items="options.duration"
                      :label="$t('STOP_DURATION')"
                      item-text="text"
                      item-value="value"
                      hide-details="auto"
                      :error-messages="errors"
                      required
                      solo
                      dense
                      outlined
                    ></v-select
                  ></validation-provider>
                </v-col>
                <v-col class="d-flex" cols="6" sm="3">
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    rules="required"
                    :name="$t('STOPS')"
                    ><v-checkbox
                      color="primary"
                      hide-details="auto"
                      v-model="fields.stops"
                      :label="$t('STOPS')"
                      required
                      :error-messages="errors"
                    ></v-checkbox
                  ></validation-provider>
                </v-col>

                <v-col class="d-flex" cols="6" sm="3">
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    rules="required"
                    :name="$t('EVENTS')"
                    ><v-checkbox
                      color="primary"
                      hide-details="auto"
                      v-model="fields.events"
                      :label="$t('EVENTS')"
                      required
                      :error-messages="errors"
                    ></v-checkbox></validation-provider
                ></v-col>
              </v-row>
            </v-container>
          </validation-observer>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" xl="6">
        <v-row
          class="
            mb-3
            justify-center
            align-content-center
            flex-column flex-sm-row
            d-flex
          "
        >
          <v-btn
            @click="show()"
            color="primary"
            min-width="200px"
            max-width="50%"
            >Show</v-btn
          >
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import moment from 'moment'

import FormTooltip from '@/components/includes/FormTooltip.vue'
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormTooltip
  },
  data: () => ({
    timeFromPicker: {
      date: false,
      time: false
    },
    timeToPicker: {
      date: false,
      time: false
    },
    max: moment().format('YYYY-MM-DD'),
    min: moment().subtract(14, 'months').format('YYYY-MM-DD'),
    fields: {
      object: '',
      filter: '2',
      duration: '5',
      stops: true, // trigger historyRouteStops
      events: true, // trigger historyRouteEvents();

      time_from: {
        date: moment().format('YYYY-MM-DD'),
        time: moment().startOf('day').format('HH:mm')
      },
      time_to: {
        date: moment().add(1, 'days').format('YYYY-MM-DD'),
        time: moment().add(1, 'days').startOf('day').format('HH:mm')
      }

    },
    options: {
      filter: [
        { value: '1', text: 'Last Hour', trans: 'LAST_HOUR' },
        { value: '2', text: 'Today', trans: 'TODAY' },
        { value: '3', text: 'Yesterday', trans: 'YESTERDAY' },
        { value: '4', text: 'Before 2 days', trans: 'BEFORE_2_DAYS' },
        { value: '5', text: 'Before 3 days', trans: 'BEFORE_3_DAYS' },
        { value: '6', text: 'This week', trans: 'THIS_WEEK' },
        { value: '7', text: 'Last week', trans: 'LAST_WEEK' },
        { value: '8', text: 'This month', trans: 'THIS_MONTH' },
        { value: '9', text: 'Last month', trans: 'LAST_MONTH' }
      ],
      duration: [
        { value: '1', text: '> 1 min' },
        { value: '2', text: '> 2 min' },
        { value: '5', text: '> 5 min' },
        { value: '10', text: '> 10 min' },
        { value: '20', text: '> 20 min' },
        { value: '30', text: '> 30 min' },
        { value: '60', text: '> 1 h' },
        { value: '120', text: '> 2 h' },
        { value: '300', text: '> 5 h' }
      ]
    }
  }),
  computed: {
    ...mapGetters({
      objectsNew: 'objects/objectsNew',
      cmdTemplateData: 'cmd/cmdTemplateData',
      objectItems: 'objects/objectItems'
    })
  },
  created () {
    if (this.$route.params.imei) {
      this.fields.object = this.$route.params.imei
    } else {
      if (this.objectItems.length) {
        this.fields.object = this.objectItems[0].value
      }
    }
  },
  methods: {
    ...mapActions({
      fetchObjectRouteHistory: 'objects/fetchObjectRouteHistory',
      setHistoryFilter: 'objects/setHistoryFilter',
      setRawHistory: 'map/setRawHistory',
      clearHistory: 'map/clearHistory',
      setHistoryRouteData: 'map/setHistoryRouteData',
      setHistoryProgress: 'map/setHistoryProgress'
    }),
    setFieldDates () {
      try {
        let timeFrom = ''
        let timeTo = ''
        switch (this.fields.filter) {
          case '1':
            timeTo = moment()
            timeFrom = moment().subtract(1, 'hours')
            break
          case '2':
            timeFrom = moment().startOf('day')
            timeTo = moment().add(1, 'days').startOf('day')

            break
          case '3':
            timeTo = moment().startOf('day')
            timeFrom = moment()
              .subtract(1, 'days')
              .startOf('day')

            break
          case '4':
            timeFrom = moment()
              .subtract(2, 'days')
              .startOf('day')

            timeTo = moment()
              .subtract(1, 'days')
              .startOf('day')

            break

          case '5':
            timeFrom = moment()
              .subtract(3, 'days')
              .startOf('day')

            timeTo = moment()
              .subtract(2, 'days')
              .startOf('day')

            break

          case '6':
            timeFrom = moment()
              .startOf('week')
              .add(1, 'days')

            timeTo = moment().add(1, 'days').startOf('day')
            break

          case '7':
            timeTo = moment()
              .startOf('week')
              .add(1, 'days').startOf('day')

            timeFrom = moment().subtract(8, 'days').startOf('day')
            break
          case '8':
            timeTo = moment().add(1, 'days').startOf('day')
            timeFrom = moment().startOf('month')

            break
          case '9':
            timeTo = moment().startOf('month').startOf('day')
            timeFrom = moment().subtract(1, 'months').startOf('month').startOf('day')
            break
          default:
            break
        }

        this.fields.time_from = {
          date: timeFrom.format('YYYY-MM-DD'),
          time: timeFrom.format('HH:mm')
        }
        this.fields.time_to = {
          date: timeTo.format('YYYY-MM-DD'),
          time: timeTo.format('HH:mm')
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    async show () {
      try {
        if (this.checkPrivilege('history')) {
          const valid = await this.$refs.observer.validate()
          if (valid) {
            const params = {
              cmd: 'load_route_data',
              imei: this.fields.object.toString(),
              dtf: this.fields.time_from.date + ' ' + this.fields.time_from.time + ':00',
              dtt: this.fields.time_to.date + ' ' + this.fields.time_to.time + ':00',
              min_stop_duration: parseInt(this.fields.duration)
            }
            this.$log.debug(params)

            this.setHistoryFilter(params).then(() => {
              this.$router.push({ name: 'mobile/map' })
            })
          }
        } else {
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'error',
            title: this.$t('ERROR'),
            text: this.$t('THIS_ACCOUNT_HAS_NO_PRIVILEGES_TO_DO_THAT')
          })
        }
      } catch (error) {
        this.handleError(error)
      }
    }
  },
  watch: {
    objectItems: function () {
      if (!this.fields.object) {
        if (this.objectItems.length) {
          this.fields.object = this.objectItems[0].value
        }
      }
    }
  }
}
</script>

<style></style>
