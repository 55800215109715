import Vue from 'vue'

const mutations = {

  SET_TRIGGERS: (state, data) => (state.triggers = data), //
  MOCK_TRIGGER_ADDITION: (state, data) => {
    const triggers = state.triggers
    const eventId = data.event_id
    delete data.event_id
    delete data.cmd
    if (eventId) {
      // let values
      triggers[eventId] = data
    } else {
      const triggerKeys = Object.keys(state.triggers)
      let key = 0
      if (triggerKeys.length >= 1) {
        key = triggerKeys.reduce((a, b) => { return Math.max(a, b) })
      }
      key = parseInt(key) + 1
      Vue.$log.debug(key)
      // let values = {}
      // delete data['event_id'];
      // delete data['cmd'];
      // let values
      triggers[key] = data
    }

    state.triggers = []
    state.triggers = triggers
    Vue.$log.debug(triggers)
  },

  MOCK_TRIGGER_UPDATE: (state, data) => {
    const triggers = state.triggers
    const eventId = data.event_id
    delete data.event_id
    triggers[eventId] = data
    state.triggers = []
    state.triggers = triggers
    Vue.$log.debug(state.triggers)
  },

  MOCK_TRIGGER_MUTE: (state, triggerIds) => {
    const triggers = state.triggers
    // triggerIds.forEach((value, id) => {
    for (const [id, value] of Object.entries(triggerIds)) {
      Vue.$log.debug(id, value)
      const trigger = triggers[id]
      const notify = trigger.notify_system.split(',')
      notify[0] = value
      trigger.notify_system = notify.join(',')
      triggers[id] = trigger
    }
    state.triggers = []
    state.triggers = triggers
  },

  MOCK_TRIGGER_DELETION: (state, triggerIds) => {
    Vue.$log.debug(triggerIds)
    const triggers = state.triggers

    triggerIds.forEach(id => {
      // Vue.$log.debug(event_id)
      // triggers.splice(event_id, 1)
      delete triggers[id]
    })
    state.triggers = []
    state.triggers = triggers
  }

}

export default mutations
