<template>
  <div class="container--fluid upper-content">
    <div class="upper-content__table">
      <PersonnelTable :table-data="personnelData" />
    </div>
    <!-- <div class="upper-content__action">
      <div>
        <label for="" class="pr-5">Filter:</label>
        <input type="text" name="first-filter" />
        <label for="" class="px-5">2nd Filter</label>
        <input type="text" name="second-filter" />
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PersonnelTable from '@/components/personnel/PersonnelTable.vue'
export default {
  components: {
    PersonnelTable
  },
  computed: {
    ...mapGetters({
      drivers: 'person/drivers'
    }),
    personnelData: function () {
      const tableData = []
      try {
        const drivers = this.drivers

        for (const [key, driver] of Object.entries(drivers)) {
          tableData.push({
            id: key,
            group: 0,
            locate: false,
            visible: true,
            follow: true,
            driver_name: driver.name,
            group_name: '',
            beacon_id: '',
            beacon_distance: '',
            breaks: '',
            non_stop: ''
          })
        }
      } catch (error) {
        this.handleError(error)
      }

      return tableData
    }
  }
}
</script>

<style></style>
