import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  cmd: [],
  cmd_gprs_list: [],
  cmd_schedule_list: [],
  cmd_sms_list: [],
  cmd_template_list: [],
  cmd_template_data: [],
  cmd_sms_data: [],
  cmd_gprs_data: [],
  cmd_schedule_data: []

}

export default {
  state,
  getters,
  actions,
  mutations
}
