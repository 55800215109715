import { mapActions } from 'vuex'
import PlaceMarkersForm from '@/components/forms/PlaceMarkersForm.vue'
import PlaceRoutesForm from '@/components/forms/PlaceRoutesForm.vue'
import PlaceZonesForm from '@/components/forms/PlaceZonesForm.vue'
import ConfirmDialog from '@/components/includes/ConfirmDialog.vue'
export const placeMixin = {
  data () {
    return {
      showForm: false,
      action: '',
      type: '',
      showConfirm: false,
      confirmDetails: {
        title: '',
        message: '',
        action: {
          yes: '',
          no: ''
        }
      },
      toDelete: null
    }
  },
  components: {
    PlaceMarkersForm,
    PlaceRoutesForm,
    PlaceZonesForm,
    ConfirmDialog
  },
  methods: {
    ...mapActions({
      updateMapPlacesDraft: 'places/updateMapPlacesDraft',
      mockPlaceObjectDeletion: 'places/mockPlaceObjectDeletion',
      removePlaceObject: 'places/removePlaceObject',
      initializePlacesData: 'places/initializePlacesData'
    }),
    addPlaceObject (type) {
      this.$log.debug(type)
      if (this.checkPrivilege('viewer') && this.checkPrivilege('subuser')) {
        this.updateMapPlacesDraft({ action: 'add', type: type, fields: { image: 'img/markers/places/pin-1.svg' } })
        this.showForm = true
        this.action = 'add'
        this.type = type
        this.$log.debug('SHOW DIALOG', type)
      } else {
        this.$notify({
                    duration: 5000,
                    closeOnClick: false,
          type: 'error',
          title: this.$t('ERROR'),
          content: this.$t('THIS_ACCOUNT_HAS_NO_PRIVILEGES_TO_DO_THAT')
        })
      }
    },
    editPlaceObject (item) {
      if (this.checkPrivilege('viewer') && this.checkPrivilege('subuser')) {
        let place, params
        const type = item.group.toLowerCase()
        switch (type) {
          case 'markers':
            place = this.placeMarkers[item.id].data
            this.$log.debug(place)
            params = {
              id: item.id,
              image: place.icon,
              name: place.name,
              description: place.desc,
              group: place.group_id,
              visible: Boolean(place.visible),
              lat: Number(place.lat),
              lng: Number(place.lng)
            }
            break
          case 'routes':
            place = this.placeRoutes[item.id].data
            params = {
              id: item.id,
              group_id: place.group_id,
              route_name: place.name,
              route_color: place.color,
              route_visible: place.visible,
              route_name_visible: place.name_visible,
              route_deviation: place.deviation,
              route_points: place.points
            }
            break
          case 'zones':
            place = this.placeZones[item.id].data
            params = {
              id: item.id,
              group_id: place.group_id,
              zone_name: place.name,
              zone_color: place.color,
              zone_visible: place.visible,
              zone_name_visible: place.name_visible,
              zone_area: place.area,
              zone_vertices: place.vertices
            }
            break
        }

        this.updateMapPlacesDraft({
          action: 'edit',
          type: type,
          fields: params
        })
        this.showForm = true
        this.action = 'edit'
        this.type = type
        this.$log.debug(this.action, this.type, this.showForm)
      } else {
        this.$notify({
                    duration: 5000,
                    closeOnClick: false,
          type: 'error',
          title: this.$t('ERROR'),
          content: this.$t('THIS_ACCOUNT_HAS_NO_PRIVILEGES_TO_DO_THAT')
        })
      }
    },
    showConfirmDialog (item) {
      if (this.checkPrivilege('viewer') && this.checkPrivilege('subuser')) {
        this.showConfirm = true
        this.confirmDetails = {
          title: 'Confirm',
          message: this.$t('ARE_YOU_SURE_YOU_WANT_TO_DELETE'),
          action: {
            yes: this.$t('YES'),
            no: this.$t('NO')
          },
          func: this.confirm
        }

        this.toDelete = item.id
        const type = item.group.toLowerCase()
        this.$log.debug(type)
        // let cmd
        // switch (type) {
        //   case 'markers':
        //     cmd = { cmd: 'load_marker_data' }
        //     break
        //   case 'routes':
        //     cmd = { cmd: 'load_route_data' }
        //     break
        //   case 'zones':
        //     cmd = { cmd: 'load_zone_data' }
        //     break
        //   default:
        //     break
        // }
        this.updateMapPlacesDraft({
          type: type,
          id: item.id
        })
      } else {
        this.$notify({
                    duration: 5000,
                    closeOnClick: false,
          type: 'error',
          title: this.$t('ERROR'),
          content: this.$t('THIS_ACCOUNT_HAS_NO_PRIVILEGES_TO_DO_THAT')
        })
      }
    },
    async deletePlaceObject () {
      const response = await this.removePlaceObject()
      if (response.message === 'OK') {
        this.$notify({
                    duration: 5000,
                    closeOnClick: false,
          type: 'success',
          title: this.$t('INFORMATION'),
          content: this.$t('CHANGES_SAVED_SUCCESSFULLY')
        })

        if (process.env.NODE_ENV === 'production') {
          this.initializePlacesData()
        }
      }
    },
    confirm (val) {
      this.showConfirm = false
      this.$log.debug('confirm', val)
      if (val) {
        this.$log.debug('go and delete')
        this.deletePlaceObject()
      }
      this.toDelete = null
    },
    setShowModal: function (isShow) {
      this.showForm = isShow
      this.updateMapPlacesDraft({ action: '', type: '', fields: {} })
    }
  }
}
