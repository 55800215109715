<template>
  <v-dialog
    persistent
    v-model="show"
    hide-overlay
    no-click-animation
    max-width="370"
    width="auto"
  >
    <v-card outlined>
      <v-toolbar color="" dense elevation="0">
        <v-toolbar-title class="text-subtitle-2">{{
          $t("ADD_OBJECT")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn plain icon x-small @click="closeDialog">
          <v-icon dense>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container fluid>
        <validation-observer ref="observer">
          <v-row>
            <v-col cols="12"
              ><validation-provider
                v-slot="{ errors }"
                rules="max:25"
                :name="$t('OBJECT_NAME')"
                ><v-text-field
                  v-model="fields.name"
                  :error-messages="errors"
                  :label="$t('OBJECT_NAME')"
                  placeholder="New Object Name"
                  color="grey"
                  hide-details="auto"
                  solo
                  dense
                  outlined
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
              ><validation-provider
                v-slot="{ errors }"
                rules="required|imei"
                :name="$t('IMEI')"
                mode="passive"
                ><v-text-field
                  v-model="fields.imei"
                  :label="$t('IMEI')"
                  :error-messages="errors"
                  required
                  placeholder="IMEI On Tracker"
                  color="grey"
                  hide-details="auto"
                  solo
                  dense
                  outlined
                  @keypress="isNumber($event)"
                ></v-text-field
              ></validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="" cols="12">
              <div v-html="$t('TDJ_SUB_ADD_PLEASE_READ')"></div>
              <div v-html="$t('TDJ_SUB_WOULD_YOU')"></div>
              <div v-html="$t('TDJ_SUB_ADD_OPTIONAL')"></div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex" cols="12">
              <v-btn
                class="flex-shrink-1 mr-1"
                color="primary"
                small
                @click="subscribe"
              >
                {{ $t("TDJ_SUB_ADD_OPTION_A") }}
              </v-btn>
              <v-btn
                class="flex-shrink-1"
                color="primary"
                small
                outlined
                @click="save"
              >
                {{ $t("TDJ_SUB_ADD_OPTION_B") }}
              </v-btn>
            </v-col>
          </v-row>
        </validation-observer>
      </v-container>
    </v-card>
    <ConfirmDialog
      :info="confirmDetails"
      :show="showConfirm"
      v-on:show-confirm-dialog="confirm"
    />
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ConfirmDialog from '@/components/includes/ConfirmDialog.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ConfirmDialog
  },
  props: {
    show: Boolean,
    id: String
  },
  data () {
    return {
      fields: {
        name: '',
        imei: ''
      },
      showConfirm: false,
      confirmDetails: {
        title: '',
        message: '',
        action: {
          yes: '',
          no: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      message: 'messages/message',
      placeGroupData: 'places/placeGroupData',
      filesPlaceMarkers: 'files/filesPlaceMarkers',
      placeDraft: 'places/placeDraft'
    })
  },
  watch: {},
  methods: {
    ...mapActions({
      updateMapPlacesDraft: 'places/updateMapPlacesDraft',
      savePlaceObject: 'places/savePlaceObject',
      fetchPlacesData: 'places/fetchPlacesData',
      // mockPlaceObjectAddition: 'places/mockPlaceObjectAddition',
      // mockPlaceObjectUpdate: 'places/mockPlaceObjectUpdate',
      clearMapPlacesDraft: 'places/clearMapPlacesDraft',
      saveObject: 'objects/saveObject'
    }),
    isNumber (evt) {
      var charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    async save () {
      try {
        const valid = await this.$refs.observer.validate()

        if (valid) {
          const params = {
            cmd: 'add_object',
            name: this.fields.name ? this.fields.name : 'New Object',
            imei: this.fields.imei
          }
          // }

          // if (!this.id) {
          this.saveObject(params)
            .then((response) => {
              switch (response) {
                case 'OK':
                  this.$notify({
                    duration: 5000,
                    closeOnClick: false,
                    type: 'success',
                    title: this.$t('INFORMATION'),
                    text: this.$t('CHANGES_SAVED_SUCCESSFULLY')
                  })
                  this.$emit('show-objects-dialog', false, true)
                  break
                case 'ERROR_IMEI_EXISTS':
                  this.$notify({
                    duration: 5000,
                    closeOnClick: false,
                    type: 'error',
                    title: this.$t('ERROR'),
                    text: this.$t('THIS_IMEI_ALREADY_EXISTS')
                  })
                  break
                case 'ERROR_OBJECT_LIMIT':
                  this.confirmDetails = {
                    title: 'Confirm',
                    message: this.$t('OBJECT_LIMIT_IS_REACHED'),
                    action: {
                      yes: this.$t('TDJ_SUB_ADD_OPTION_A'),
                      no: this.$t('NO')
                    }
                  }
                  this.showConfirm = true

                  break
                case 'TDJ_RESTRICTED_IMEI':
                  this.$notify({
                    duration: 5000,
                    closeOnClick: false,
                    type: 'error',
                    title: this.$t('ERROR'),
                    text:
                      'This device (IMEI) does not appear to be a Gator device.<br />This server is for Gator devices only.'
                  })
                  break
                default:
                  this.handleError(response)
                  break
              }
            })
            .catch((error) => {
              this.handleError(error)
            })
          // }
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    async subscribe () {
      const valid = await this.$refs.observer.validate()
      if (valid) {
        window.open('/subscribe/index.php?imei=' + this.fields.imei)
      }
    },
    confirm (val) {
      this.showConfirm = false
      if (val) {
        location.href = '/subscribe/index.php?imei=' + this.fields.imei
      }
    },
    closeDialog () {
      this.$refs.observer.reset()
      this.$emit('show-objects-dialog', false)
    }
  }
}
</script>

<style></style>
