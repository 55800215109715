<template>
  <v-dialog
    persistent
    v-model="show"
    hide-overlay
    no-click-animation
    max-width="370"
    width="auto"
  >
    <v-card outlined>
      <v-toolbar color="" dense elevation="0">
        <v-toolbar-title class="text-subtitle-2">{{
          $t("OBJECT_GROUP_PROPERTIES")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn plain icon x-small @click="closeDialog">
          <v-icon dense>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container fluid>
        <validation-observer ref="observer">
          <v-row>
            <v-col cols="12"
              ><validation-provider
                v-slot="{ errors }"
                rules="required|max:30"
                :name="$t('NAME')"
              >
                <div class="mb-2 d-flex flex-row">
                  <span class="text-body-2 mr-1">{{ $t("NAME") }}</span>

                  <FormTooltip>
                    <template v-slot:tooltip-trigger>
                      <v-btn x-small color="primary lighten-1" text icon>
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:tooltip-content
                      >{{ $t("NAME") }}
                    </template>
                  </FormTooltip>
                </div>
                <v-text-field
                  v-model="fields.name"
                  :error-messages="errors"
                  :label="$t('NAME')"
                  required
                  solo
                  color="grey"
                  dense
                  hide-details="auto"
                  outlined
                ></v-text-field
              ></validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
              ><validation-provider
                v-slot="{ errors }"
                rules="max:256"
                :name="$t('DESCRIPTION')"
              >
                <div class="mb-2 d-flex flex-row">
                  <span class="text-body-2 mr-1">{{ $t("DESCRIPTION") }}</span>

                  <FormTooltip>
                    <template v-slot:tooltip-trigger>
                      <v-btn x-small color="primary lighten-1" text icon>
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:tooltip-content
                      >{{ $t("DESCRIPTION") }}
                    </template>
                  </FormTooltip>
                </div>
                <v-textarea
                  rows="2"
                  dense
                  solo
                  color="grey"
                  hide-details="auto"
                  outlined
                  v-model="fields.description"
                  :label="$t('DESCRIPTION')"
                  :error-messages="errors"
                ></v-textarea
              ></validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <validation-provider
                class="w-100"
                v-slot="{ errors }"
                :name="$t('OBJECTS')"
              >
                <div class="mb-2 d-flex flex-row">
                  <span class="text-body-2 mr-1">{{ $t("OBJECTS") }}</span>

                  <FormTooltip>
                    <template v-slot:tooltip-trigger>
                      <v-btn x-small color="primary lighten-1" text icon>
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:tooltip-content
                      >{{ $t("OBJECTS") }}
                    </template>
                  </FormTooltip>
                </div>
                <v-autocomplete
                  menu-props="dark"
                  item-color="#272727"
                  v-model="fields.objects"
                  :items="objectItems"
                  :label="$t('OBJECT')"
                  item-text="text"
                  item-value="value"
                  solo
                  color="grey"
                  dense
                  hide-details="auto"
                  outlined
                  :error-messages="errors"
                  multiple
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @mousedown.prevent @click="toggle">
                      <v-list-item-action>
                        <v-icon>
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title> Select All </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-autocomplete>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row class="mb-3 justify-center flex-column flex-sm-row d-flex">
            <v-btn
              @click="closeDialog"
              color="primary"
              outlined
              class="mr-sm-2 mb-2"
              max-width="50%"
              >Cancel</v-btn
            >
            <v-btn @click="save" color="primary" max-width="50%">Save</v-btn>
          </v-row>
        </validation-observer>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import FormTooltip from '@/components/includes/FormTooltip.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormTooltip
  },
  props: {
    show: Boolean,
    id: String
  },
  data () {
    return {
      fields: {
        name: '',
        description: '',
        objects: []
      },
      showConfirm: false,
      confirmDetails: {
        title: '',
        message: '',
        action: {
          yes: '',
          no: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      message: 'messages/message',
      groups: 'groups/groups',
      objectItems: 'objects/objectItems',
      getGroupedObjects: 'objects/getGroupedObjects'
    }),
    checksAll () {
      return this.fields.objects.length === this.objectItems.length
    },
    checksSome () {
      return this.fields.objects.length > 0 && !this.checksAll
    },
    icon () {
      if (this.checksAll) return 'mdi-close-box'
      if (this.checksSome) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
  },
  watch: {
    'fields.objects': function () {
      this.$log.debug(this.fields.objects)
    },
    show: function () {
      try {
        if (this.id) {
          // this.$log.debug('set values', )
          const group = this.groups[this.id]
          this.fields.name = group.name
          this.fields.description = group.desc
          const grouped = this.getGroupedObjects
          this.$log.debug(grouped[this.id])
          this.fields.objects = []
          if (Object.prototype.hasOwnProperty.call(grouped, this.id)) {
            grouped[this.id].forEach((obj) => {
              this.fields.objects.push(obj.object_id)
            })
          }
          this.$log.debug(this.fields.objects)
        } else {
          this.fields = {
            name: '',
            description: '',
            objects: []
          }
        }
      } catch (error) {
        this.handleError(error)
      }
    }
  },
  methods: {
    ...mapActions({
      saveGroup: 'groups/saveGroup',
      initializeObjectData: 'objects/initializeObjectData',
      fetchGroupSettings: 'groups/fetchGroupSettings'
    }),
    async save () {
      try {
        const valid = await this.$refs.observer.validate()
        // this.fields.name = this.fields.name ? this.fields.name : "New Object";
        this.$log.debug(valid)

        if (valid) {
          const params = {
            cmd: 'save_object_group',
            group_id: this.id ? this.id : false,
            group_name: this.fields.name,
            group_desc: this.fields.description,
            group_imei: this.fields.objects.join(',')
          }

          // upload https://gator.net.au/func/fn_upload.php?file=driver_photo
          // data:image/png;base64 payload

          const response = await this.saveGroup(params)
          this.$log.debug('save object response', response)

          if (response.message === 'OK') {
            this.$notify({
                    duration: 5000,
                    closeOnClick: false,
              type: 'success',
              title: this.$t('INFORMATION'),
              text: this.$t('CHANGES_SAVED_SUCCESSFULLY')
            })

            if (process.env.NODE_ENV === 'production') {
              this.fetchGroupSettings()
              this.initializeObjectData()
            }

            this.closeDialog()
          } else {
            this.$notify({
                    duration: 5000,
                    closeOnClick: false,
              type: 'error',
              title: this.$t('ERROR'),
              text:
                'Something went wrong! Please contact system administrator.'
            })
            this.closeDialog()
          }
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    closeDialog () {
      this.$refs.observer.reset()
      this.$emit('show-groups-dialog', false)
    },
    toggle () {
      this.$nextTick(() => {
        if (this.checksAll) {
          this.fields.objects = []
        } else {
          const imeis = []
          this.$log.debug()
          this.objectItems.forEach(obj => {
            imeis.push(obj.value)
          })
          this.fields.objects = imeis
        }
      })
    }
  }
}
</script>

<style></style>
