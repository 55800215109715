var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "triggers-table",
        style: "width:" + _vm.width + "px",
        attrs: {
          search: _vm.search,
          dense: "",
          headers: _vm.headers,
          items: _vm.tableData,
          "item-key": "id",
          "fixed-header": "",
          "mobile-breakpoint": "300",
          "show-select": "",
          height: _vm.height,
          "footer-props": { "items-per-page-options": [100, 200, 300, 400] }
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c(
                  "v-toolbar",
                  {
                    attrs: { extended: _vm.$vuetify.breakpoint.xs },
                    scopedSlots: _vm._u(
                      [
                        _vm.$vuetify.breakpoint.xs
                          ? {
                              key: "default",
                              fn: function() {
                                return [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "mr-3",
                                      attrs: {
                                        color: "primary",
                                        small: "",
                                        outlined: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.remove()
                                        }
                                      }
                                    },
                                    [_vm._v("\n            Remove\n          ")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: { "offset-y": "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                          small: ""
                                                        }
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { left: "" } },
                                                      [_vm._v(" mdi-plus ")]
                                                    ),
                                                    _vm._v(
                                                      "\n                Add\n              "
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        2646656606
                                      )
                                    },
                                    [
                                      _vm._v(" "),
                                      _c(
                                        "v-list",
                                        { attrs: { dense: "", tile: "" } },
                                        _vm._l(_vm.types, function(type) {
                                          return _c(
                                            "v-list-item",
                                            {
                                              key: type,
                                              on: {
                                                click: function($event) {
                                                  _vm.showInfoModal = true
                                                  _vm.fields.type = type
                                                }
                                              }
                                            },
                                            [
                                              _c("v-list-item-title", [
                                                _vm._v(_vm._s(type))
                                              ])
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          : null,
                        _vm.$vuetify.breakpoint.xs
                          ? {
                              key: "extension",
                              fn: function() {
                                return [
                                  _c("v-text-field", {
                                    attrs: {
                                      solo: "",
                                      dense: "",
                                      "single-line": "",
                                      "hide-details": "",
                                      clearable: "",
                                      outlined: "",
                                      "prepend-inner-icon": "mdi-magnify",
                                      label: "Search",
                                      color: "grey"
                                    },
                                    model: {
                                      value: _vm.search,
                                      callback: function($$v) {
                                        _vm.search = $$v
                                      },
                                      expression: "search"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          : null
                      ],
                      null,
                      true
                    )
                  },
                  [
                    !_vm.$vuetify.breakpoint.xs
                      ? _c(
                          "v-row",
                          { staticClass: "align-center" },
                          [
                            _c(
                              "v-col",
                              [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: { "offset-y": "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        small: ""
                                                      }
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    { attrs: { left: "" } },
                                                    [_vm._v(" mdi-plus ")]
                                                  ),
                                                  _vm._v(
                                                    "\n                  Add\n                "
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      2173061854
                                    )
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "v-list",
                                      { attrs: { dense: "", tile: "" } },
                                      _vm._l(_vm.types, function(type) {
                                        return _c(
                                          "v-list-item",
                                          {
                                            key: type,
                                            on: {
                                              click: function($event) {
                                                return _vm.$emit(
                                                  "show-info-modal",
                                                  [true, type]
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(_vm._s(type))
                                            ])
                                          ],
                                          1
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "ml-3",
                                    attrs: {
                                      color: "primary",
                                      small: "",
                                      outlined: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.remove()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n              Remove\n            "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              {
                                staticClass: "d-flex justify-content-end",
                                attrs: { cols: "12", sm: "6", md: "5", lg: "4" }
                              },
                              [
                                _c("v-text-field", {
                                  attrs: {
                                    solo: "",
                                    dense: "",
                                    "single-line": "",
                                    "hide-details": "",
                                    clearable: "",
                                    outlined: "",
                                    "prepend-inner-icon": "mdi-magnify",
                                    label: "Search",
                                    color: "grey"
                                  },
                                  model: {
                                    value: _vm.search,
                                    callback: function($$v) {
                                      _vm.search = $$v
                                    },
                                    expression: "search"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "item.name",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-btn",
                  {
                    staticClass: "caption",
                    attrs: {
                      dense: "",
                      plain: "",
                      to: {
                        name: "mobile/triggers/edit",
                        params: { trigger_id: item.id }
                      }
                    }
                  },
                  [_vm._v("\n        " + _vm._s(item.name) + "\n      ")]
                )
              ]
            }
          },
          {
            key: "item.active",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-chip",
                  {
                    staticClass: "ma-2",
                    attrs: {
                      small: "",
                      color: item.active ? "success" : "error",
                      "text-color": "white"
                    }
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(item.active ? "ACTIVE" : "INACTIVE") +
                        "\n    "
                    )
                  ]
                )
              ]
            }
          },
          {
            key: "item.edit",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "", color: "tooltip" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        "x-small": "",
                                        icon: "",
                                        dense: "",
                                        plain: "",
                                        to: {
                                          name: "mobile/triggers/edit",
                                          params: { trigger_id: item.id }
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { dense: "" } }, [
                                    _vm._v("mdi-pencil")
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [_vm._v(" "), _c("span", [_vm._v("Edit trigger details")])]
                )
              ]
            }
          },
          {
            key: "item.delete",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "", color: "tooltip" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        "x-small": "",
                                        icon: "",
                                        dense: "",
                                        plain: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.selectedTriggers = [item]
                                          _vm.selectedTriggerIds = [item.id]
                                          _vm.remove()
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { dense: "" } }, [
                                    _vm._v("mdi-trash-can-outline")
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [_vm._v(" "), _c("span", [_vm._v("Remove trigger")])]
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.selectedTriggers,
          callback: function($$v) {
            _vm.selectedTriggers = $$v
          },
          expression: "selectedTriggers"
        }
      }),
      _vm._v(" "),
      _c("ConfirmDialog", {
        attrs: { info: _vm.confirmDetails, show: _vm.showConfirm },
        on: { "show-confirm-dialog": _vm.confirmDelete }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }