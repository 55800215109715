<template>
<div>
<v-tabs>
  <v-tab exact to="/mobile/events">Events</v-tab>
  <v-tab exact to="/mobile/events/video">Video</v-tab>
</v-tabs>
<router-view></router-view>
</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      events: 'events/events',
      getObjectSettingsById: 'objects/getObjectSettingsById',
      getDriverName: 'person/getDriverName',
      objectsNew: 'objects/objectsNew',
      getObjectByName: 'objects/getObjectByName'
    })
    // eventsData: function () {
    //   const tableData = []
    //   try {
    //     for (const [id] of Object.entries(this.events)) {
    //       const eventData = this.events[id]
    //       const obj = this.getObjectByName(eventData.name)

    //       tableData.push({
    //         id: id,
    //         locate: true,
    //         device_name: eventData.name,
    //         person_name: obj ? this.getDriverName(obj.settings.driver_id) : '',
    //         trigger_name: eventData.event_desc,
    //         date: eventData.dt_server
    //       })
    //     }

    //     return tableData
    //   } catch (error) {
    //     this.handleError(error)
    //   }
    //   return tableData
    // }
  },
  async created () {
    this.onResize()
    await Promise.all([
      this.fetchDrivers(),
      this.initializeObjectData(),
      this.fetchEventNames(),
      this.fetchEventTypes()

      // this.fetchSiteSettings('load_server_data'),
      // this.fetchSiteSettings('load_user_data'),
      // this.fetchEvents()
    ]).catch((error) => {
      this.handleError(error)
    })
  },
  methods: {
    ...mapActions({
      // fetchEvents: 'events/fetchEvents',
      fetchSiteSettings: 'settings/fetchSiteSettings',
      fetchDrivers: 'person/fetchDrivers',
      initializeObjectData: 'objects/initializeObjectData',
      fetchEventNames: 'events/fetchEventNames',
      fetchEventTypes: 'events/fetchEventTypes'
    })

  }
}
</script>

<style></style>
