var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "personnel-table",
        attrs: {
          dense: "",
          headers: _vm.headers,
          items: _vm.tableData,
          "item-key": "name",
          "group-by": "group",
          "item-class": _vm.selectedRow,
          "fixed-header": "",
          height: "500px",
          "single-line": "",
          "hide-details": "",
          dark: "",
          "items-per-page": -1,
          "hide-default-footer": ""
        },
        on: { "click:row": _vm.handleRowClick },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c("v-toolbar", {
                  attrs: { extended: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function() {
                        return [
                          _c(
                            "v-btn",
                            {
                              staticClass: "border-1--yellow",
                              attrs: { small: "" },
                              on: {
                                click: function($event) {
                                  _vm.showForm = true
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v(" mdi-plus ")
                              ]),
                              _vm._v("\n          Add New Person\n        ")
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "extension",
                      fn: function() {
                        return [
                          _c(
                            "v-col",
                            { staticClass: "pl-0" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  solo: "",
                                  dense: "",
                                  "single-line": "",
                                  "hide-details": "",
                                  clearable: "",
                                  outlined: "",
                                  "prepend-inner-icon": "mdi-magnify",
                                  label: "Filter",
                                  color: "grey"
                                },
                                model: {
                                  value: _vm.filter1,
                                  callback: function($$v) {
                                    _vm.filter1 = $$v
                                  },
                                  expression: "filter1"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "pr-0" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  solo: "",
                                  dense: "",
                                  "single-line": "",
                                  "hide-details": "",
                                  clearable: "",
                                  outlined: "",
                                  "prepend-inner-icon": "mdi-magnify",
                                  label: "2nd Filter",
                                  color: "grey"
                                },
                                model: {
                                  value: _vm.filter2,
                                  callback: function($$v) {
                                    _vm.filter2 = $$v
                                  },
                                  expression: "filter2"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ]
            },
            proxy: true
          },
          {
            key: "header",
            fn: function() {
              return [
                _c("thead", [
                  _c("tr", [
                    _c(
                      "th",
                      {
                        staticClass: "text-align-center",
                        attrs: { colspan: "3" }
                      },
                      [_vm._v(_vm._s(_vm.$t("MAP")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass: "text-align-center",
                        attrs: { colspan: "2" }
                      },
                      [_vm._v("Personnel")]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass: "text-align-center hidden-md-and-down",
                        attrs: { colspan: "5" }
                      },
                      [_vm._v("\n            Metrics\n          ")]
                    )
                  ])
                ])
              ]
            },
            proxy: true
          },
          {
            key: "group.header",
            fn: function(ref) {
              var items = ref.items
              var isOpen = ref.isOpen
              var toggle = ref.toggle
              return [
                _c(
                  "th",
                  {
                    staticClass: "group-header",
                    attrs: { colspan: "10" },
                    on: { click: toggle }
                  },
                  [_vm._v("\n        " + _vm._s(items[0].group) + "\n      ")]
                )
              ]
            }
          },
          {
            key: "header.follow",
            fn: function(ref) {
              return [_vm._v("\n      " + _vm._s(_vm.$t("FOLLOW")) + "\n    ")]
            }
          },
          {
            key: "item.locate",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("v-img", {
                  attrs: {
                    "max-width": "18",
                    src:
                      _vm.publicPath +
                      "assets/img/icons/" +
                      (item.locate === true ? "locate-green.svg" : "locate.svg")
                  }
                })
              ]
            }
          },
          {
            key: "item.follow",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("v-simple-checkbox", {
                  model: {
                    value: item.follow,
                    callback: function($$v) {
                      _vm.$set(item, "follow", $$v)
                    },
                    expression: "item.follow"
                  }
                })
              ]
            }
          },
          {
            key: "item.visible",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("v-simple-checkbox", {
                  model: {
                    value: item.visible,
                    callback: function($$v) {
                      _vm.$set(item, "visible", $$v)
                    },
                    expression: "item.visible"
                  }
                })
              ]
            }
          },
          {
            key: "item.actions",
            fn: function(ref) {
              return [
                _c("v-img", {
                  attrs: {
                    "max-width": "18",
                    src: _vm.publicPath + "assets/img/icons/gear.svg"
                  }
                })
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }