var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "video" }, [
      _c("div", { staticClass: "video__content" }, [
        _c("div", [_vm._v("1")]),
        _vm._v(" "),
        _c("div", [_vm._v("2")]),
        _vm._v(" "),
        _c("div", [_vm._v("3")]),
        _vm._v(" "),
        _c("div", [_vm._v("4")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "video__actions" }, [
        _c(
          "div",
          {
            staticClass: "border-1--yellow",
            staticStyle: { "font-size": "12px" }
          },
          [_vm._v("Live view")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex flex-wrap live-view" }, [
          _c("button", { staticClass: "d-flex flex-wrap live-view__buttons" }, [
            _c("div", { staticClass: "border-1--yellow" }, [_vm._v("1")]),
            _vm._v(" "),
            _c("div", { staticClass: "border-1--yellow" }, [_vm._v("2")]),
            _vm._v(" "),
            _c("div", { staticClass: "border-1--yellow" }, [_vm._v("3")]),
            _vm._v(" "),
            _c("div", { staticClass: "border-1--yellow" }, [_vm._v("4")])
          ]),
          _vm._v(" "),
          _c("button", { staticClass: "d-flex flex-wrap live-view__buttons" }, [
            _c("div", [_vm._v("1")]),
            _vm._v(" "),
            _c("div", { staticClass: "border-1--yellow" }, [_vm._v("2")])
          ]),
          _vm._v(" "),
          _c("button", { staticClass: "d-flex flex-wrap live-view__buttons" }, [
            _c("div", [_vm._v("1")]),
            _vm._v(" "),
            _c("div", { staticClass: "border-1--yellow" }, [_vm._v("3")])
          ]),
          _vm._v(" "),
          _c("button", { staticClass: "d-flex flex-wrap live-view__buttons" }, [
            _c("div", [_vm._v("1")]),
            _vm._v(" "),
            _c("div", { staticClass: "border-1--yellow" }, [_vm._v("4")])
          ]),
          _vm._v(" "),
          _c("button", { staticClass: "live-view__buttons border-1--yellow" }, [
            _vm._v("\n        1\n      ")
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticStyle: { "font-size": "12px" } }, [
          _vm._v("Playback Mode")
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "playback-mode d-flex flex-wrap" }, [
          _c(
            "button",
            { staticClass: "playback-mode__buttons border-1--yellow" },
            [_vm._v("\n        1\n      ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            { staticClass: "playback-mode__buttons border-1--yellow" },
            [_vm._v("\n        2\n      ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            { staticClass: "playback-mode__buttons border-1--yellow" },
            [_vm._v("\n        3\n      ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            { staticClass: "playback-mode__buttons border-1--yellow" },
            [_vm._v("\n        4\n      ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            { staticClass: "playback-mode__buttons border-1--yellow" },
            [_vm._v("5")]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticStyle: { "font-size": "9px" } }, [
          _vm._v("Date start")
        ]),
        _vm._v(" "),
        _c("div", {}, [_vm._v(" ")]),
        _vm._v(" "),
        _c("div", { staticStyle: { "font-size": "9px" } }, [
          _vm._v("Date end")
        ]),
        _vm._v(" "),
        _c("div", {}, [_vm._v(" ")]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "border-1--yellow",
            staticStyle: { "font-size": "12px" }
          },
          [_vm._v("\n      Search Date Range\n    ")]
        ),
        _vm._v(" "),
        _c("div", {}, [_vm._v(" ")]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "border-1--yellow",
            staticStyle: { "font-size": "12px" }
          },
          [_vm._v("\n      Full Screen Viewer\n    ")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }