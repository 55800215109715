import { placesService } from '@/services/places'
import Vue from 'vue'

// import { marker } from 'leaflet'
const actions = {
  async initializePlacesData ({ commit, rootState, rootGetters }) {
    const placeMarkers = placesService.getPlacesData({ cmd: 'load_marker_data' })
    const placeRoutes = placesService.getPlacesData({ cmd: 'load_route_data' })
    const placeZones = placesService.getPlacesData({ cmd: 'load_zone_data' })
    const placeGroups = placesService.getPlacesData({ cmd: 'load_place_group_data' })

    return await Promise.all([placeMarkers, placeRoutes, placeZones, placeGroups])
      .then(([markers, routes, zones, groups]) => {
        Vue.$log.debug('initializePlacesData', 'success')
        Vue.$log.debug('place module action', zones)
        const objects = rootGetters['objects/objectsNew']
        const user = rootGetters['settings/user']
        let data = {
          objects: objects,
          markers: markers.data,
          user: user
        }
        commit('SET_PLACE_MARKERS', data)
        data = {
          objects: objects,
          routes: routes.data,
          user: user
        }
        commit('SET_PLACE_ROUTES', data)
        data = {
          objects: objects,
          zones: zones.data,
          user: user
        }
        commit('SET_PLACE_ZONES', data)
        commit('SET_PLACE_GROUPS', groups.data)
        return true
      })
      .catch(error => {
        throw error
      })
  },

  async fetchPlacesData ({ commit, rootState, rootGetters }, params) {
    // placesService.getPlacesData({ cmd: 'load_marker_data' })
    return await placesService.getPlacesData(params)
      .then(function (response) {
        Vue.$log.debug('fetchPlacesData', 'success', response)
        const objects = rootGetters['objects/objectsNew']
        const user = rootGetters['settings/user']
        const data = {
          objects: objects,
          user: user
        }

        switch (params.cmd) {
          case 'load_marker_data':
            data.markers = response.data
            commit('SET_PLACE_MARKERS', data)
            // commit('SET_PLACE_MARKER_DATA', response.data)

            break
          case 'load_place_group_data':
            commit('SET_PLACE_GROUPS', response.data)
            break
          case 'load_route_data':
            Vue.$log.debug(response.data)
            data.routes = response.data
            commit('SET_PLACE_ROUTES', data)
            break
          case 'load_zone_data':
            data.zones = response.data
            commit('SET_PLACE_ZONES', data)
            break
          default:
            break
        }
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },

  // async fetchPlacesList ({ commit }, params) {
  //   return await placesService.getPlacesList(params)
  //     .then(function (response) {
  //       Vue.$log.debug('fetchPlacesList', 'success')

  //       switch (params.cmd) {
  //         case 'load_marker_list':
  //           commit('SET_PLACE_MARKER_LIST', response.data)
  //           break
  //         case 'load_places_group_list':
  //           commit('SET_PLACE_GROUP_LIST', response.data)
  //           break
  //         case 'load_route_list':
  //           commit('SET_PLACE_ROUTE_LIST', response.data)
  //           break
  //         case 'load_zone_list':
  //           commit('SET_PLACE_ZONE_LIST', response.data)
  //           break
  //         default:
  //           break
  //       }

  //       return response
  //     })
  //     .catch(function (error) {
  //       Vue.$log.debug('fetchPlacesList' + error)
  //       throw error;
  //     })
  // },

  async setPlaceProperty ({ commit }, params) {
    Vue.$log.debug('setPlaceProperty')
    commit('SET_PLACE_PROPERTY', params)
  },

  // async setPlaceLayers ({ commit }, params) {
  //   Vue.$log.debug('setPlaceLayers', params)
  //   commit('SET_PLACE_LAYERS', params)
  // },

  async savePlaceObject ({ commit, rootState, rootGetters }, data) {
    // Vue.$log.debug(rootGetters['places/placeDraft'])
    // const draft = rootGetters['places/placeDraft'].fields
    // const type = rootGetters['places/placeDraft'].type
    // const action = rootGetters['places/placeDraft'].action
    // Vue.$log.debug('rootGetters.placeDraft.', type, draft, action)

    const draft = data.fields
    const type = data.type
    const action = data.action
    // Vue.$log.debug('rootGetters.placeDraft.', type, draft, action)
    let params
    // console.log(data)

    switch (type) {
      case 'markers':
        params = {
          cmd: 'save_marker',
          marker_id: action === 'add' ? false : draft.id,
          group_id: draft.group,
          marker_name: draft.name,
          marker_desc: draft.description,
          marker_icon: (action === 'add' ? 'img/markers/places/' : '') + draft.image,
          marker_visible: draft.visible,
          marker_lat: draft.lat.toFixed(6),
          marker_lng: draft.lng.toFixed(6)
        }
        break
      case 'routes':
        params = {
          cmd: 'save_route',
          route_id: action === 'add' ? false : draft.id,
          group_id: draft.group_id,
          route_name: draft.route_name,
          route_color: draft.route_color,
          route_visible: draft.route_visible,
          route_name_visible: draft.route_name_visible,
          route_deviation: draft.route_deviation,
          route_points: draft.route_points
        }
        break
      case 'zones':
        params = {
          cmd: 'save_zone',
          zone_id: action === 'add' ? false : draft.id,
          group_id: draft.group_id,
          zone_name: draft.zone_name,
          zone_color: draft.zone_color,
          zone_visible: draft.zone_visible,
          zone_name_visible: draft.zone_name_visible,
          zone_area: draft.zone_area,
          zone_vertices: draft.zone_vertices
        }
        break
    }

    // Vue.$log.debug('savePlaceObject', params)

    if (process.env.NODE_ENV === 'production') {
      return await placesService.savePlaceObject(params)
        .then(function (response) {
          Vue.$log.debug('savePlaceObject', 'success')
          return response.data
        })
        .catch(function (error) {
          throw error
        })
    } else {
      // const response = 'OK'
      // marker OK, ERROR_MARKER_LIMIT
      // ZONE OK, ERROR_ZONE_LIMIT
      // ROUTE OK ERROR_ROUTE_LIMIT
      let limitErr = ''
      if (type === 'markers') limitErr = 'ERROR_MARKER_LIMIT'
      else if (type === 'routes') limitErr = 'ERROR_ROUTE_LIMIT'
      else if (type === 'zones') limitErr = 'ERROR_ZONE_LIMIT'
      Vue.$log.debug('LIMIT', limitErr)
      const mockResponses = [
        'OK'
        // limitErr
      ]
      const response = mockResponses[Math.floor((Math.random() * mockResponses.length))]
      if (response === 'OK') {
        if (action === 'add') {
          commit('MOCK_PLACE_ADDITION')
        } else {
          commit('MOCK_PLACE_UPDATE')
        }
      }
      return response
    }
  },

  async removePlaceObject ({ commit, rootState, rootGetters }) {
    Vue.$log.debug('removePlaceObject', rootGetters.placeDraft)
    const draft = rootGetters['places/placeDraft']
    Vue.$log.debug('removePlaceObject', draft)
    const type = draft.type
    let params
    switch (type) {
      case 'markers':
        params = {
          cmd: 'delete_marker',
          marker_id: draft.id
        }
        break
      case 'routes':
        params = {
          cmd: 'delete_route',
          route_id: draft.id
        }
        break
      case 'zones':
        params = {
          cmd: 'delete_zone',
          zone_id: draft.id
        }
        break
    }
    Vue.$log.debug('to delete', draft.id)
    if (process.env.NODE_ENV === 'production') {
      return await placesService.removePlaceObject(params)
        .then(function (response) {
          Vue.$log.debug('removePlaceObject', 'success', response)
          return response.data
        })
        .catch(function (error) {
          throw error
        })
    } else {
      commit('MOCK_PLACE_DELETION')
    }
  },
  // mockPlaceObjectAddition ({ commit, rootState, rootGetters }) {
  //   const draft = { draft: rootGetters.placeDraft.fields, type: rootGetters.placeDraft.type }
  //   Vue.$log.debug('mockPlaceObjectAddition', draft)
  //   commit('MOCK_PLACE_ADDITION', draft)
  // },

  // mockPlaceObjectUpdate ({ commit, rootState, rootGetters }) {
  //   const draft = { draft: rootGetters.placeDraft.fields, type: rootGetters.placeDraft.type }
  //   Vue.$log.debug('mockPlaceObjectUpdate', draft)
  //   commit('MOCK_PLACE_UPDATE', draft)
  // },

  // mockPlaceObjectDeletion ({ commit, rootState, rootGetters }, id) {
  //   Vue.$log.debug('mockPlaceObjectDeletion', id, rootGetters.placeDraft)
  //   // commit('MOCK_PLACE_DELETION', { id: id})
  // },

  updateMapPlacesDraft ({ commit }, params) {
    Vue.$log.debug(params)
    commit('SET_PLACE_DRAFT', params)
  },

  clearMapPlacesDraft ({ commit }, type) {
    commit('CLEAR_PLACE_DRAFT', type)
  },

  async importMarkers ({ commit }, params) {
    if (process.env.NODE_ENV === 'production') {
      return await placesService.importMarkers(params)
        .then(function (response) {
          Vue.$log.debug('importMarkers', 'success', response)
          return response.data
        })
        .catch(function (error) {
          throw error
        })
    } else {
      return { message: 'OK' }
    }
  },

  async importRoutes ({ commit }, params) {
    if (process.env.NODE_ENV === 'production') {
      return await placesService.importRoutes(params)
        .then(function (response) {
          Vue.$log.debug('importRoutes', 'success', response)
          return response.data
        })
        .catch(function (error) {
          throw error
        })
    } else {
      return { message: 'OK' }
    }
  },

  async importZones ({ commit }, params) {
    if (process.env.NODE_ENV === 'production') {
      return await placesService.importZones(params)
        .then(function (response) {
          Vue.$log.debug('importZones', 'success', response)
          return response.data
        })
        .catch(function (error) {
          throw error
        })
    } else {
      return { message: 'OK' }
    }
  },

  async searchAddress ({ commit }, params) {
    if (process.env.NODE_ENV === 'production') {
      return await placesService.searchAddress(params)
        .then(function (response) {
          // Vue.$log.debug('searchAddress', 'success', response)
          return response.data
        })
        .catch(function (error) {
          throw error
        })
    } else {
      if (params.by === 'address') {
        return {
          address: 'Braeside VIC 3195, Australia',
          lat: -37.9987562,
          lng: 145.1189431
        }
      }
      return ['Dummy Address']
    }
  }

}
export default actions
