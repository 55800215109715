<template>
  <div>
    <v-data-table
      dense
      :headers="headers"
      :items="filteredTableData"
      item-key="name"
      class="places-table"
      group-by="group"
      :item-class="selectedRow"
      @click:row="handleRowClick"
      fixed-header
      dark
      height="calc(100vh - 350px)"
      :items-per-page="-1"
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar extended>
          <template v-slot:default>
            <v-menu offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  class="border-1--yellow v-btn--parallelogram ml-1"
                  v-bind="attrs"
                  v-on="on"
                >
                  Add Place
                </v-btn>
              </template>

              <v-list dense tile>
                <v-list-item @click="addPlaceObject('markers')">
                  <v-list-item-title>Markers</v-list-item-title>
                </v-list-item>
                <v-list-item @click="addPlaceObject('routes')">
                  <v-list-item-title>Routes</v-list-item-title>
                </v-list-item>
                <v-list-item @click="addPlaceObject('zones')">
                  <v-list-item-title>Zones</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn small class="border-1--yellow v-btn--parallelogram mx-2">
              Clear Filters
            </v-btn>
            <v-btn
              small
              class="border-1--yellow v-btn--parallelogram mr-1"
              @click="exportData()"
            >
              Export
            </v-btn>
            <v-btn
              small
              class="border-1--yellow v-btn--parallelogram mr-1"
              @click="uploadFile()"
            >
              Import
            </v-btn>
          </template>
          <template v-slot:extension>
            <v-col class="pl-0"
              ><v-text-field
                solo
                dense
                single-line
                hide-details
                clearable
                outlined
                prepend-inner-icon="mdi-magnify"
                v-model="filter1"
                label="Filter"
                color="grey"
                class=""
              ></v-text-field
            ></v-col>
            <v-col class="pr-0"
              ><v-text-field
                solo
                dense
                single-line
                hide-details
                clearable
                outlined
                prepend-inner-icon="mdi-magnify"
                v-model="filter2"
                label="2nd Filter"
                color="grey"
                class=""
              ></v-text-field
            ></v-col>
          </template>
        </v-toolbar>
      </template>
      <template v-slot:header="">
        <thead>
          <tr>
            <th colspan="2" class="text-align-center">{{ $t("MAP") }}</th>
            <th class="text-align-center">{{ $t("PLACE") }}</th>
            <th colspan="5" class="text-align-center">Closest Asset</th>
          </tr>
        </thead>
      </template>

      <!-- <template v-slot:body.prepend="">
      <tbody>
        <tr class="d-none d-lg-block">
          <td colspan="9" class="text-align-center">Un-Grouped</td>
        </tr>
        <tr class="d-block d-lg-none">
          <td colspan="3" class="text-align-center">Un-Grouped</td>
        </tr>
      </tbody>
    </template> -->
      <template v-slot:group.header="{ items, isOpen, toggle }">
        <th colspan="7" class="group-header" @click="toggle">
          {{ items[0].group }}
        </th>
      </template>

      <template v-slot:header.driver_name="{}">
        {{ $t("DRIVER") }}
      </template>

      <template v-slot:item.locate="{ item }">
        <v-img
          @click="locatePlaceObject(item.id, item.locate, item.group)"
          max-width="18"
          :src="
            publicPath +
            'assets/img/icons/' +
            (item.locate === true ? 'locate-green.svg' : 'locate.svg')
          "
          class="mr-1"
        ></v-img>
      </template>

      <!-- <template v-slot:item.driver_name="{ item }">
        {{ getDriverName(item.driver_name) }}
      </template> -->

      <template v-slot:item.distance="{ item }">
        {{ item.distance + $t("UNIT_DISTANCE") }}
      </template>

      <template v-slot:item.visible="{ item }">
        <v-checkbox
          dense
          v-model="item.visible"
          @change="setPlaceVisibility(item.id, item.visible, item.group)"
        >
        </v-checkbox>
      </template>

      <template v-slot:item.actions="{ item }">
        <!-- <v-menu :offset-x="true">
          <template v-slot:activator="{ on, attrs }">
            <v-img
              class="btn-settings"
              v-bind="attrs"
              v-on="on"
              max-width="18"
              :src="`${publicPath}assets/img/icons/gear.svg`"
            ></v-img>
          </template>

          <v-list dense tile>
            <v-list-item @click="editPlaceObject(item)">
              <v-list-item-title
                >Edit</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="showConfirmDialog(item)">
              <v-list-item-title
                >Delete</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu> -->
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn x-small icon dense plain v-bind="attrs" v-on="on">
              <v-icon color="" dense>mdi-settings-outline</v-icon>
            </v-btn>
          </template>
          <v-list dense tile>
            <v-list-item @click="editPlaceObject(item)">
              <v-list-item-title>Edit</v-list-item-title>
            </v-list-item>
            <v-list-item @click="showConfirmDialog(item)">
              <v-list-item-title>Delete</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <PlaceMarkersForm
      :show="showForm && type === 'markers'"
      v-on:show-place-dialog="setShowModal"
    />
    <PlaceRoutesForm
      :show="showForm && type === 'routes'"
      v-on:show-place-dialog="setShowModal"
    />
    <PlaceZonesForm
      :show="showForm && type === 'zones'"
      v-on:show-place-dialog="setShowModal"
    />
    <ConfirmDialog
      :info="confirmDetails"
      :show="showConfirm"
      v-on:show-confirm-dialog="confirmDetails.func"
    />

    <input ref="uploader" class="d-none" type="file" @change="onFileChanged" />
  </div>
</template>

<script>
import { mapFunctions } from '@/plugins/map'
import { latLng } from 'leaflet'
import { mapGetters, mapActions } from 'vuex'
import { placeMixin } from '@/mixins/placeMixin'
import moment from 'moment'
export default {
  mixins: [placeMixin],
  components: {},
  props: {
    tableData: Array
  },
  computed: {
    ...mapGetters({
      getDriverName: 'person/getDriverName',
      placeMarkers: 'places/placeMarkers',
      placeRoutes: 'places/placeRoutes',
      placeZones: 'places/placeZones'
    }),
    filteredTableData: function () {
      let data = this.tableData
      try {
        if (this.filter1 || this.filter2) {
          data = this.tableData.filter((data) => {
            if (
              (this.filter1 &&
                data.place_name
                  .toLowerCase()
                  .includes(this.filter1.toLowerCase())) ||
              (this.filter1 &&
                data.device_name
                  .toLowerCase()
                  .includes(this.filter1.toLowerCase())) ||
              (this.filter1 &&
                data.driver_name
                  .toLowerCase()
                  .includes(this.filter1.toLowerCase())) ||
              (this.filter1 &&
                data.distance.toString().includes(this.filter1)) ||
              (this.filter2 &&
                data.place_name
                  .toLowerCase()
                  .includes(this.filter2.toLowerCase())) ||
              (this.filter2 &&
                data.device_name
                  .toLowerCase()
                  .includes(this.filter2.toLowerCase())) ||
              (this.filter1 &&
                data.driver_name
                  .toLowerCase()
                  .includes(this.filter1.toLowerCase())) ||
              (this.filter2 && data.distance.toString().includes(this.filter2))
            ) {
              return true
            }
          })
        }
      } catch (error) {
        this.handleError(error)
      }

      return data
    }
  },
  data: () => ({
    filter1: '',
    filter2: '',
    id: '',
    selectedRowId: '',
    singleSelect: false,
    selected: [],
    headers: [
      { text: 'Locate', value: 'locate', sortable: false },
      {
        text: 'Visible',
        value: 'visible',
        sortable: false
      },
      { text: 'Place Name', value: 'place_name' },
      {
        text: 'Device',
        value: 'device_name',
        class: 'hidden-sm-and-down',
        cellClass: 'hidden-sm-and-down',
        sortable: false
      },
      {
        text: 'Driver',
        value: 'driver_name',
        class: 'hidden-lg-and-down',
        cellClass: 'hidden-lg-and-down'
      },
      {
        text: ' Distance',
        value: 'distance',
        sortable: false,
        class: 'hidden-md-and-down',
        cellClass: 'hidden-md-and-down'
      },
      { text: '', value: 'actions', sortable: false }
    ],

    confirmDetails: {
      title: '',
      message: '',
      action: {
        yes: '',
        no: ''
      },
      func: () => {}
    }
  }),
  methods: {
    ...mapActions({
      updateMapBounds: 'map/updateMapBounds',
      updateMapCenter: 'map/updateMapCenter',
      setPlaceProperty: 'places/setPlaceProperty',
      importMarkers: 'places/importMarkers',
      importRoutes: 'places/importRoutes',
      importZones: 'places/importZones'
    }),
    handleRowClick: function (item) {
      // this.selectedRowId = item.id
      // this.$emit('selected-object', item.id)
      // let bounds = []
      // if (item.group === 'Markers') {
      //   Object.keys(this.placeMarkers).forEach((key) => {
      //     if (key === item.id) {
      //       bounds.push(
      //         latLng(
      //           this.placeMarkers[key].data.lat,
      //           this.placeMarkers[key].data.lng
      //         )
      //       )
      //     }
      //   })
      // }
      // if (item.group === 'Routes') {
      //   Object.keys(this.placeRoutes).forEach((key) => {
      //     if (key === item.id) {
      //       const points = mapFunctions.convertPointStringToLatLng(
      //         this.placeRoutes[key].data.points
      //       )
      //       bounds = bounds.concat(points)
      //     }
      //   })
      // }
      // if (item.group === 'Zones') {
      //   Object.keys(this.placeZones).forEach((key) => {
      //     if (key === item.id) {
      //       const points = mapFunctions.convertPointStringToLatLng(
      //         this.placeZones[key].data.vertices
      //       )
      //       bounds = bounds.concat(points)
      //     }
      //   })
      // }
      // if (bounds.length > 1) {
      //   this.updateMapBounds(bounds)
      // } else if (bounds.length === 1) {
      //   this.updateMapCenter(bounds[0])
      // }
    },
    selectedRow (item) {
      return this.selectedRowId && item.id === this.selectedRowId
        ? 'active-row'
        : ''
    },
    locatePlaceObject (id, currentState, type) {
      try {
        this.updateTableData(id, 'locate', type)
        this.setPlaceProperty({
          id: id,
          property_name: 'locate',
          value: !currentState,
          type: type.toLowerCase()
        })

        let bounds = []
        Object.keys(this.placeMarkers).forEach((key) => {
          if (this.placeMarkers[key].locate) {
            bounds.push(
              latLng(
                this.placeMarkers[key].data.lat,
                this.placeMarkers[key].data.lng
              )
            )
          }
        })

        Object.keys(this.placeRoutes).forEach((key) => {
          if (this.placeRoutes[key].locate) {
            const points = mapFunctions.convertPointStringToLatLng(
              this.placeRoutes[key].data.points
            )
            bounds = bounds.concat(points)
          }
        })

        Object.keys(this.placeZones).forEach((key) => {
          if (this.placeZones[key].locate) {
            const points = mapFunctions.convertPointStringToLatLng(
              this.placeZones[key].data.vertices
            )
            bounds = bounds.concat(points)
          }
        })

        if (bounds.length > 1) {
          this.updateMapBounds(bounds)
        } else if (bounds.length === 1) {
          this.updateMapCenter(bounds[0])
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    setPlaceVisibility (id, value, type) {
      try {
        this.setPlaceProperty({
          id: id,
          property_name: 'visible',
          value: value,
          type: type.toLowerCase()
        })
      } catch (error) {
        this.handleError(error)
      }
    },
    updateTableData (id, prop, type) {
      try {
        this.tableData.forEach((data, index) => {
          if (data.id === id && data.group === type) {
            this.tableData[index][prop] = !data[prop]
          }
        })
      } catch (error) {
        this.handleError(error)
      }
    },
    exportData () {
      if (this.checkPrivilege('viewer')) {
        const data = {
          plc: '0.1v',
          markers: [],
          routes: [],
          zones: []
        }

        Object.values(this.placeMarkers).forEach(marker => {
          data.markers.push({
            name: marker.data.name,
            desc: marker.data.desc,
            icon: marker.data.icon,
            visible: ~~JSON.parse(marker.data.visible),
            lat: marker.data.lat,
            lng: marker.data.lng
          })
        })

        Object.values(this.placeRoutes).forEach(route => {
          data.routes.push({
            name: route.data.name,
            color: route.data.color,
            visible: route.data.visible,
            name_visible: route.data.name_visible,
            deviation: route.data.deviation,
            points: route.data.points
          })
        })

        Object.values(this.placeZones).forEach(zone => {
          data.zones.push({
            name: zone.data.name,
            color: zone.data.color,
            visible: zone.data.visible,
            name_visible: zone.data.name_visible,
            area: zone.data.area,
            vertices: zone.data.vertices
          })
        })

        var fileURL = window.URL.createObjectURL(
          new Blob([JSON.stringify(data)])
        )
        var fileLink = document.createElement('a')

        fileLink.href = fileURL
        const fileName =
          'places ' + moment().format('YYYY-MM-DD HH:mm:ss') + '.plc'
        fileLink.setAttribute('download', fileName)
        document.body.appendChild(fileLink)

        fileLink.click()
        fileLink.remove()
      } else {
        this.$notify({
                    duration: 5000,
                    closeOnClick: false,
          type: 'error',
          title: this.$t('ERROR'),
          text: this.$t('THIS_ACCOUNT_HAS_NO_PRIVILEGES_TO_DO_THAT')
        })
      }
    },

    uploadFile () {
      if (this.checkPrivilege('viewer') && this.checkPrivilege('subuser')) {
        this.$log.debug('show file input')
        // Trigger click on the FileInput
        this.$refs.uploader.click()
      } else {
        this.$notify({
                    duration: 5000,
                    closeOnClick: false,
          type: 'error',
          title: this.$t('ERROR'),
          text: this.$t('THIS_ACCOUNT_HAS_NO_PRIVILEGES_TO_DO_THAT')
        })
      }
    },

    onFileChanged (e) {
      this.$log.debug('file uploaded')
      const uploadedFile = e.target.files[0]
      // let groups = {};
      const reader = new FileReader()
      reader.onload = (e) => {
        try {
          this.selectedFile = e.target.result
          const fileContent = JSON.parse(e.target.result)

          if (fileContent.plc === '0.1v') {
            let markers = []
            let routes = []
            let zones = []
            const confirmString = []

            if (Object.prototype.hasOwnProperty.call(fileContent, 'markers')) {
              markers = fileContent.markers
              if (markers.length) {
                confirmString.push(markers.length + ' markers')
              }
            }

            if (Object.prototype.hasOwnProperty.call(fileContent, 'routes')) {
              routes = fileContent.routes
              if (routes.length) {
                confirmString.push(routes.length + ' routes')
              }
            }

            if (Object.prototype.hasOwnProperty.call(fileContent, 'zones')) {
              zones = fileContent.zones
              if (zones.length) {
                confirmString.push(zones.length + ' zones')
              }
            }

            if (markers.length || routes.length || zones.length) {
              this.confirmDetails = {
                title: 'Confirm',
                message:
                  confirmString.join(', ') +
                  ' found. ' +
                  this.$t('ARE_YOU_SURE_YOU_WANT_TO_IMPORT'),
                func: this.confirmImport
              }
              this.showConfirm = true
            } else {
              this.$notify({
                    duration: 5000,
                    closeOnClick: false,
                type: 'info',
                title: this.$t('INFORMATION'),
                text: this.$t('NOTHING_HAS_BEEN_FOUND_TO_IMPORT')
              })
            }
          } else {
            this.$notify({
                    duration: 5000,
                    closeOnClick: false,
              type: 'error',
              title: this.$t('ERROR'),
              text: this.$t('INVALID_FILE_FORMAT')
            })
          }
        } catch (error) {
          this.handleError(error)
        }
      }
      reader.onerror = (err) => this.handleError(err)
      reader.readAsText(uploadedFile)
    },
    confirmImport (confirmed) {
      try {
        this.showConfirm = false
        if (confirmed) {
          const params = {
            format: 'plc',
            data: this.selectedFile,
            markers: true,
            routes: true,
            zones: true
          }
          Promise.all([
            this.importMarkers(params),
            this.importRoutes(params),
            this.importZones(params)
          ])
            .then(([m, r, z]) => {
              if (
                m.message === 'OK' &&
                r.message === 'OK' &&
                z.message === 'OK'
              ) {
                this.$notify({
                    duration: 5000,
                    closeOnClick: false,
                  type: 'success',
                  title: 'Import',
                  text: 'Data imported successfully.'
                })
              } else {
                let message = ''
                if (m.message === 'ERROR_MARKER_LIMIT') {
                  message += this.$t('MARKER_LIMIT_IS_REACHED') + ' <br/>'
                } else {
                  message += 'Markers imported successfully. <br/>'
                }

                if (r.message === 'ERROR_ROUTE_LIMIT') {
                  message += this.$t('ROUTE_LIMIT_IS_REACHED') + ' <br/>'
                } else {
                  message += 'Routes imported successfully. <br/>'
                }

                if (z.message === 'ERROR_ZONE_LIMIT') {
                  message += this.$t('ZONE_LIMIT_IS_REACHED') + ' <br/>'
                } else {
                  message += 'Zones imported successfully. <br/>'
                }

                this.$notify({
                    duration: 5000,
                    closeOnClick: false,
                  type: 'info',
                  title: this.$t('INFORMATION'),
                  text: message
                })
              }
              if (process.env.NODE_ENV === 'production') {
                this.initializePlacesData()
              }
            })
            .catch((error) => {
              this.handleError(error)
            })
        }
      } catch (error) {
        this.handleError(error)
      }
    }
  }
}
</script>

<style></style>
