var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "hide-overlay": "",
        "no-click-animation": "",
        "max-width": "370",
        width: "auto"
      },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-toolbar",
            { attrs: { color: "", dense: "", elevation: "0" } },
            [
              _c("v-toolbar-title", { staticClass: "text-subtitle-2" }, [
                _vm._v(_vm._s(_vm.formTitle))
              ]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { plain: "", icon: "", "x-small": "" },
                  on: { click: _vm.closeDialog }
                },
                [_c("v-icon", { attrs: { dense: "" } }, [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "validation-observer",
                { ref: "observer" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          [
                            _c("validation-provider", {
                              attrs: {
                                rules: "dimensions:640,480",
                                name: _vm.$t("NAME")
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    var validate = ref.validate
                                    return [
                                      _c("v-file-input", {
                                        attrs: {
                                          accept: ".png, .jpg, .jpeg",
                                          label: "File input",
                                          filled: "",
                                          "prepend-icon": "mdi-camera",
                                          "error-messages": errors
                                        },
                                        on: { change: validate }
                                      })
                                    ]
                                  }
                                }
                              ])
                            })
                          ]
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "div",
                            { staticClass: "mb-2 d-flex flex-row" },
                            [
                              _c("span", { staticClass: "text-body-2 mr-1" }, [
                                _vm._v(_vm._s(_vm.$t("NAME")))
                              ]),
                              _vm._v(" "),
                              _c("FormTooltip", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "tooltip-trigger",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              "x-small": "",
                                              color: "primary lighten-1",
                                              text: "",
                                              icon: ""
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-information-outline")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "tooltip-content",
                                    fn: function() {
                                      return [
                                        _vm._v(_vm._s(_vm.$t("NAME")) + " ")
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("validation-provider", {
                            attrs: {
                              rules: "required|max:30",
                              name: _vm.$t("NAME")
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        "error-messages": errors,
                                        label: _vm.$t("NAME"),
                                        required: "",
                                        solo: "",
                                        color: "grey",
                                        dense: "",
                                        "hide-details": "auto",
                                        outlined: ""
                                      },
                                      model: {
                                        value: _vm.fields.name,
                                        callback: function($$v) {
                                          _vm.$set(_vm.fields, "name", $$v)
                                        },
                                        expression: "fields.name"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "div",
                            { staticClass: "mb-2 d-flex flex-row" },
                            [
                              _c("span", { staticClass: "text-body-2 mr-1" }, [
                                _vm._v("Token")
                              ]),
                              _vm._v(" "),
                              _c("FormTooltip", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "tooltip-trigger",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              "x-small": "",
                                              color: "primary lighten-1",
                                              text: "",
                                              icon: ""
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-information-outline")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "tooltip-content",
                                    fn: function() {
                                      return [_vm._v("Token ")]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("validation-provider", {
                            attrs: { rules: "max:30", name: "Token" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Token",
                                        "error-messages": errors,
                                        solo: "",
                                        color: "grey",
                                        dense: "",
                                        "hide-details": "auto",
                                        outlined: ""
                                      },
                                      model: {
                                        value: _vm.fields.token,
                                        callback: function($$v) {
                                          _vm.$set(_vm.fields, "token", $$v)
                                        },
                                        expression: "fields.token"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "div",
                            { staticClass: "mb-2 d-flex flex-row" },
                            [
                              _c("span", { staticClass: "text-body-2 mr-1" }, [
                                _vm._v(_vm._s(_vm.$t("ID_NUMBER")))
                              ]),
                              _vm._v(" "),
                              _c("FormTooltip", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "tooltip-trigger",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              "x-small": "",
                                              color: "primary lighten-1",
                                              text: "",
                                              icon: ""
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-information-outline")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "tooltip-content",
                                    fn: function() {
                                      return [
                                        _vm._v(
                                          _vm._s(_vm.$t("ID_NUMBER")) +
                                            "\n                "
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("validation-provider", {
                            attrs: {
                              rules: "max:30",
                              name: _vm.$t("ID_NUMBER")
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: _vm.$t("ID_NUMBER"),
                                        "error-messages": errors,
                                        solo: "",
                                        color: "grey",
                                        dense: "",
                                        "hide-details": "auto",
                                        outlined: ""
                                      },
                                      model: {
                                        value: _vm.fields.idnum,
                                        callback: function($$v) {
                                          _vm.$set(_vm.fields, "idnum", $$v)
                                        },
                                        expression: "fields.idnum"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "div",
                            { staticClass: "mb-2 d-flex flex-row" },
                            [
                              _c("span", { staticClass: "text-body-2 mr-1" }, [
                                _vm._v(_vm._s(_vm.$t("ADDRESS")))
                              ]),
                              _vm._v(" "),
                              _c("FormTooltip", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "tooltip-trigger",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              "x-small": "",
                                              color: "primary lighten-1",
                                              text: "",
                                              icon: ""
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-information-outline")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "tooltip-content",
                                    fn: function() {
                                      return [
                                        _vm._v(
                                          _vm._s(_vm.$t("ADDRESS")) +
                                            "\n                "
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("validation-provider", {
                            attrs: {
                              rules: "max:100",
                              name: _vm.$t("ADDRESS")
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-textarea", {
                                      attrs: {
                                        rows: "3",
                                        label: _vm.$t("ADDRESS"),
                                        "error-messages": errors,
                                        solo: "",
                                        color: "grey",
                                        dense: "",
                                        "hide-details": "auto",
                                        outlined: ""
                                      },
                                      model: {
                                        value: _vm.fields.address,
                                        callback: function($$v) {
                                          _vm.$set(_vm.fields, "address", $$v)
                                        },
                                        expression: "fields.address"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "div",
                            { staticClass: "mb-2 d-flex flex-row" },
                            [
                              _c("span", { staticClass: "text-body-2 mr-1" }, [
                                _vm._v(_vm._s(_vm.$t("PHONE")))
                              ]),
                              _vm._v(" "),
                              _c("FormTooltip", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "tooltip-trigger",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              "x-small": "",
                                              color: "primary lighten-1",
                                              text: "",
                                              icon: ""
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-information-outline")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "tooltip-content",
                                    fn: function() {
                                      return [
                                        _vm._v(_vm._s(_vm.$t("PHONE")) + " ")
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("validation-provider", {
                            attrs: { rules: "max:50", name: _vm.$t("PHONE") },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: _vm.$t("PHONE"),
                                        "error-messages": errors,
                                        solo: "",
                                        color: "grey",
                                        dense: "",
                                        "hide-details": "auto",
                                        outlined: ""
                                      },
                                      model: {
                                        value: _vm.fields.phone,
                                        callback: function($$v) {
                                          _vm.$set(_vm.fields, "phone", $$v)
                                        },
                                        expression: "fields.phone"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "div",
                            { staticClass: "mb-2 d-flex flex-row" },
                            [
                              _c("span", { staticClass: "text-body-2 mr-1" }, [
                                _vm._v(_vm._s(_vm.$t("EMAIL")))
                              ]),
                              _vm._v(" "),
                              _c("FormTooltip", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "tooltip-trigger",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              "x-small": "",
                                              color: "primary lighten-1",
                                              text: "",
                                              icon: ""
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-information-outline")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "tooltip-content",
                                    fn: function() {
                                      return [
                                        _vm._v(_vm._s(_vm.$t("EMAIL")) + " ")
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("validation-provider", {
                            attrs: {
                              rules: "email|max:50",
                              name: _vm.$t("EMAIL")
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: _vm.$t("EMAIL"),
                                        "error-messages": errors,
                                        solo: "",
                                        color: "grey",
                                        dense: "",
                                        "hide-details": "auto",
                                        outlined: ""
                                      },
                                      model: {
                                        value: _vm.fields.email,
                                        callback: function($$v) {
                                          _vm.$set(_vm.fields, "email", $$v)
                                        },
                                        expression: "fields.email"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "div",
                            { staticClass: "mb-2 d-flex flex-row" },
                            [
                              _c("span", { staticClass: "text-body-2 mr-1" }, [
                                _vm._v(_vm._s(_vm.$t("DESCRIPTION")))
                              ]),
                              _vm._v(" "),
                              _c("FormTooltip", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "tooltip-trigger",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              "x-small": "",
                                              color: "primary lighten-1",
                                              text: "",
                                              icon: ""
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-information-outline")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "tooltip-content",
                                    fn: function() {
                                      return [
                                        _vm._v(
                                          _vm._s(_vm.$t("DESCRIPTION")) +
                                            "\n                "
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("validation-provider", {
                            attrs: {
                              rules: "max:256",
                              name: _vm.$t("DESCRIPTION")
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-textarea", {
                                      attrs: {
                                        rows: "2",
                                        label: _vm.$t("DESCRIPTION"),
                                        "error-messages": errors,
                                        solo: "",
                                        color: "grey",
                                        dense: "",
                                        "hide-details": "auto",
                                        outlined: ""
                                      },
                                      model: {
                                        value: _vm.fields.description,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.fields,
                                            "description",
                                            $$v
                                          )
                                        },
                                        expression: "fields.description"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "mb-3 justify-center flex-column flex-sm-row d-flex"
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-sm-2 mb-2",
                          attrs: {
                            color: "primary",
                            outlined: "",
                            "max-width": "50%"
                          },
                          on: { click: _vm.closeDialog }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", "max-width": "50%" },
                          on: { click: _vm.save }
                        },
                        [_vm._v("Save")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }