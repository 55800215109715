import Vue from 'vue'

const mutations = {
  SET_LANG_SETTINGS: (state, data) => (state.lang = data),
  SET_SERVER: (state, data) => {
    state.server = data

    // Vue.$log.debug('SET SERVER MUTATION', state.server)
  },

  SET_LANG_LIST: (state, data) => (state.language_list = data),
  SET_USER: (state, data) => (state.user = data),
  setCustomField: (state, data) => (state.custom_field = data),
  setEvents: (state, data) => (state.events = data),
  setEventList: (state, data) => (state.event_list = data),
  setGroups: (state, data) => (state.groups = data),
  setGroupList: (state, data) => (state.group_list = data),
  setSensorList: (state, data) => (state.sensor_list = data),
  setServiceList: (state, data) => (state.service_list = data),
  setSubaccounts: (state, data) => (state.subaccounts = data),
  setSubaccountList: (state, data) => (state.subaccount_list = data),
  setTemplates: (state, data) => (state.templates = data),
  setTemplateList: (state, data) => (state.template_list = data),
  SAVE_MAP_TYPE: (state, data) => {
    state.user.map_layer = data.map
    // Vue.$log.debug('SET SAVE_MAP_TYPE MUTATION', data.map, state.user.map_layer);
    Vue.$log.debug(state.user.map_layer)
  },
  SAVE_USER_LANGUAGE: (state, lang) => {
    state.user.language = lang
    // Vue.$log.debug('SET SAVE_MAP_TYPE MUTATION', data.map, state.user.map_layer);
    Vue.$log.debug(state.user.language)
  },
  SAVE_USER_SETTINGS: (state, data) => {
    Object.keys(data).forEach((prop) => {
      if (prop === 'units') {
        const units = data[prop].split(',')
        state.user.unit_distance = units[0]
        state.user.unit_capacity = units[1]
        state.user.unit_temperature = units[2]
      } else {
        state.user[prop] = data[prop]
      }
    })
  },
  // SET_ADDRESS_STATE: (state, data) => (state.search_address_state = data),

  SET_UI_SETTINGS: (state, data) => {
    state.ui_settings[data.prop] = data.value
  },

  SET_LANGUAGE: (state, lang) => {
    state.language = lang
    // Vue.$log.debug('SET SAVE_MAP_TYPE MUTATION', data.map, state.user.map_layer);
    Vue.$log.debug(state.language)
  }
}

export default mutations
