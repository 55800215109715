<template>
  <div class="right-content right__container right-content__container" v-if="!isMobileView && $router.currentRoute.name !== 'live'">
    <div class="right-content__map">
      <TheMapTabs />
    </div>
    <!-- <div class="right-content__actions">
      <v-container fluid class="d-flex flex-column flex-wrap">
        <v-row no-gutters class="action-section d-lg-inline-flex">
          <v-col class="d-lg-flex action-section__address-search">
            <label for="message" class="mr-1">
              {{ $t("ADDRESS_SEARCH") }}</label
            >
            <input
              type="text"
              name="message"
              id=""
              placeholder="Enter Address Here"
            />
            <v-btn small class="btn-save-marker">Save as marker</v-btn>
            <v-btn small class="mr-1 btn-copy-message">Copy to message</v-btn>
          </v-col>
          <v-col
            class="ml-5 action-section__map-actions d-none d-lg-inline-block"
          >
            <v-btn-toggle
              multiple
              borderless
              class="mr-2"
              v-model="place_toggles"
            >
              <v-btn
                class="border-1--yellow"
                x-small
                value="zones"
                @click="updateToggleProperty('zones')"
              >
                <span class="hidden-sm-and-down">{{ $t("ZONES") }}</span>
              </v-btn>
              <v-btn
                class="border-1--yellow"
                x-small
                value="markers"
                @click="updateToggleProperty('markers')"
              >
                <span class="hidden-sm-and-down">Markers</span>
              </v-btn>
              <v-btn
                @click="updateToggleProperty('routes')"
                class="border-1--yellow"
                x-small
                value="routes"
              >
                <span class="hidden-sm-and-down">{{ $t("ROUTES") }}</span>
              </v-btn>
            </v-btn-toggle>

            <v-btn-toggle
              multiple
              borderless
              class="mr-2"
              v-model="object_toggles"
            >
              <v-btn
                @click="updateToggleProperty('trackers')"
                class="border-1--yellow"
                x-small
                value="trackers"
              >
                <span class="hidden-sm-and-down">Trackers</span>
              </v-btn>
              <v-btn
                @click="updateToggleProperty('clusters')"
                class="border-1--yellow"
                x-small
                value="clusters"
              >
                <span class="hidden-sm-and-down">{{ $t("CLUSTERS") }}</span>
              </v-btn>
              <v-btn
                @click="updateToggleProperty('object_labels')"
                class="border-1--yellow"
                x-small
                value="labels"
              >
                <span class="hidden-sm-and-down">{{ $t("LABELS") }}</span>
              </v-btn>
            </v-btn-toggle>

            <v-btn-toggle borderless class="mr-2" v-model="fit_all_toggle">
              <v-btn
                @click="updateToggleProperty('fit_all')"
                class="border-1--yellow"
                x-small
                value="fit_all"
              >
                <span class="hidden-sm-and-down">Fit All</span>
              </v-btn>
            </v-btn-toggle>

            <v-btn-toggle borderless class="mr-2" v-model="traffic_toggle">
              <v-btn
                @click="updateToggleProperty('traffic')"
                class="border-1--yellow"
                x-small
                value="traffic"
              >
                <v-img
                  max-width="13"
                  src="./assets/img/icons/traffic.png"
                  class="mr-1"
                ></v-img>
                <span class="hidden-sm-and-down">{{ $t("TRAFFIC") }}</span>
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-container>
    </div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TheMapTabs from '@/layout/TheMapTabs.vue'
export default {
  components: {
    TheMapTabs
  },
  props: {},
  computed: {
    ...mapGetters({})
  },
  methods: {
    ...mapActions({})
  }
}
</script>

<style></style>
