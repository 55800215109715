import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { globals } from '@/plugins/global'
import store from '@/store/store'

// import messages from '@/lang/en'
// import axios from 'axios'

// if (process.env.NODE_ENV === 'production') {
//   axios.defaults.baseURL = process.env.API_URL
// } else {
//   axios.defaults.baseURL = '/'
// }

// axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8';
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
// axios.defaults.headers['Access-Control-Allow-Origin'] = '*'

// axios.defaults.headers['Accept'] = 'application/json';

const requestUrl = process.env.BASE_URL + 'src/assets/lang'
Vue.use(VueI18n)
const messages = {}
export const i18n = new VueI18n({
  locale: '', // set locale
  fallbackLocale: 'english',
  messages // set locale messages
})

const loadedLanguages = [] // our default language that is preloaded

function setI18nLanguage (lang) {
  i18n.locale = lang
  // axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync (lang) {
  this.$log.debug('loadLanguageAsync', lang)
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  return store.dispatch('settings/fetchLanguageTranslation', lang)
    .then(response => {
      // this.$log.debug('GET LANGUAGE', response.data)
      i18n.setLocaleMessage(lang, response)
      loadedLanguages.push(lang)
      return setI18nLanguage(lang)
    })
    .catch(err => {
      // Handle Error Here
      console.error('Error getting language settings', err)
    })
}
