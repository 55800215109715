import axios from 'axios'
import { globals } from '@/plugins/global'

export const authService = {
  checkSession,
  authenticate,
  fetchCsrfToken,
  verifyUser,
  verifyUserCode,
  register,
  recoverUrl,
  recoverToken,
  recover
}

function checkSession () {
  return axios.post(
    //  '/session',
    // globals.convertJsonToFormData({ cmd: 'session_check' }),
    // axiosConfig
    '/session',
    { cmd: 'session_check' },
    {
      headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        // 'Accept': 'application/json'
      }
    }
  )
}

function authenticate (params) {
  return axios.post('/login', globals.convertJsonToFormData(params))
}
function fetchCsrfToken () {
  return axios.post('/csrf')
}

function verifyUser (params) {
  return axios.post('/user/verify', globals.convertJsonToFormData(params))
}

function verifyUserCode (params) {
  return axios.post('/user/verify_code', globals.convertJsonToFormData(params))
}

function register (params) {
  return axios.post('/register', globals.convertJsonToFormData(params))
}

function recoverUrl (params) {
  return axios.post('/recover_url', globals.convertJsonToFormData(params))
}

function recoverToken (params) {
  return axios.post('/recover_token', globals.convertJsonToFormData(params))
}

function recover (params) {
  return axios.post('/recover', globals.convertJsonToFormData(params))
}
