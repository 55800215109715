var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "validation-observer",
        { ref: "register" },
        [
          _c("p", { staticClass: "text-body-2 error--text" }, [
            _vm._v(_vm._s(_vm.errorMessage))
          ]),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("validation-provider", {
                    attrs: { rules: "required|email", name: _vm.$t("EMAIL") },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("v-text-field", {
                              attrs: {
                                "error-messages": errors,
                                placeholder: _vm.$t("EMAIL"),
                                required: "",
                                solo: "",
                                color: "grey",
                                dense: "",
                                "hide-details": "auto",
                                outlined: ""
                              },
                              model: {
                                value: _vm.email,
                                callback: function($$v) {
                                  _vm.email = $$v
                                },
                                expression: "email"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("validation-provider", {
                    attrs: { rules: "agree", name: "agree" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("v-checkbox", {
                              attrs: {
                                "error-messages": errors,
                                label: "Agree",
                                required: "",
                                solo: "",
                                color: "grey",
                                dense: "",
                                "hide-details": "auto",
                                outlined: ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c("div", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$t("TDJ_AGREE_TERMS")
                                            )
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.agree,
                                callback: function($$v) {
                                  _vm.agree = $$v
                                },
                                expression: "agree"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {},
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "w-100",
                      attrs: { color: "primary" },
                      on: { click: _vm.register }
                    },
                    [_vm._v("Register")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }