import axios from 'axios'
import { globals } from '@/plugins/global'

const requestUrl = process.env.BASE_URL + 'src/assets/json'

export const objectsService = {
  getObjects,
  getObjectSettings,
  // getObjectListSettings,
  saveObject,
  removeObject,
  getObjectRouteHistory,
  // searchAddress,
  forceObjectSubscription,
  getLiveCam
}

function getObjects (params = null) {
  const data = { cmd: 'load_object_data' }
  let ctr = ''
  let imei = false
  if (params) {
    if (Object.prototype.hasOwnProperty.call(params, 'imei')) {
      data.imei = params.imei
      imei = true
    }
    if (Object.prototype.hasOwnProperty.call(params, 'ctr')) {
      ctr = '_' + params.ctr
    }
  }

  if (process.env.NODE_ENV === 'development') {
    return axios.get(`${requestUrl}${ctr ? '/follow' : ''}${imei ? '/single' : ''}/objects${ctr}.json`)
  }

  return axios.post('/objects/data', globals.convertJsonToFormData(data))
}

function getObjectSettings () {
  if (process.env.NODE_ENV === 'development') {
    return axios.get(`${requestUrl}/settings/objects_data.json`)
  }

  return axios.post('/objects/info', globals.convertJsonToFormData({ cmd: 'load_object_data_spa' }))
}

// function getObjectListSettings (params) {
//   if (process.env.NODE_ENV === 'development') {
//     if (params.cmd === 'load_object_list') {
//       return axios.get(`${requestUrl}/settings/object_list_load.json`)
//     } else {
//       return axios.get(`${requestUrl}/settings/object_list.json`)
//     }
//   }

//   return axios.get('/func/fn_settings.objects.php', params)
// }

function saveObject (params) {
  return axios.post('/objects/add', globals.convertJsonToFormData(params))
}

function removeObject (params) {
  return axios.post('/func/fn_settings.objects.php', params,
    { baseURL: '' })
}

function getObjectRouteHistory (params) {
  // cmd: load_route_data
  // imei: 123
  // dtf: 2021-10-20 06:50:00
  // dtt: 2021-10-20 07:50:00
  // min_stop_duration: 1
  if (process.env.NODE_ENV === 'development') {
    return axios.get(`${requestUrl}/history_route_data_${params.imei}.json`)
  }
  return axios.post('/history', globals.convertJsonToFormData(params))
}

// function searchAddress (params, isMobile = false) {
//   return axios.post(
//     '/tools/gc_post.php',
//     globals.convertJsonToFormData(params),
//     { baseURL: '' }
//   )
// }

function forceObjectSubscription (params) {
  // cmd: force_subscription
  // imei: ""
  return axios.post(
    '/func/fn_settings.objects.php',
    globals.convertJsonToFormData(params),
    { baseURL: '' }
  )
}

function getLiveCam (params, isMobile = false) {
  return axios.post(
    '/tools/gc_post.php',
    globals.convertJsonToFormData(params),
    { baseURL: '' }
  )
}
