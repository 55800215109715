import L, { latLng } from 'leaflet'
// import * as L from 'leaflet';
import 'leaflet.markercluster'
// import { globals } from '@/plugins/global'

export const mapFunctions = {
  convertPointStringToLatLng,
  convertLatLngToString,
  createCluster,
  generatePolygon,
  generatePolylines,
  generateMarker,
  bindTooltip,
  generateIcon,
  bindPopup
}
function convertPointStringToLatLng (points) {
  const t = points.split(',')
  const a = []
  for (let j = 0; j < t.length; j += 2) {
    const lat = t[j]
    const lng = t[j + 1]
    a.push(latLng(lat, lng))
  }
  return a
}

function convertLatLngToString (points) {
  const latlng = []
  for (let i = 0; i < points.length; i++) {
    const o = points[i]
    const lat = o.lat
    const lng = o.lng
    latlng.push(parseFloat(lat).toFixed(6) + ',' + parseFloat(lng).toFixed(6))
  }
  return latlng.toString()
}

function createCluster (type, mapIs, mapMinZoom, mapMaxZoom, clusters = true) {
  // type should be objects or markers
  const iconUrl = `${process.env.ASSET_PATH}assets/img/markers/clusters/${type}.svg`
  let clusterZoom = mapMinZoom
  if (clusters) {
    clusterZoom = mapMaxZoom + 1
  }
  return new L.MarkerClusterGroup({
    spiderfyDistanceMultiplier: 2 * mapIs,
    spiderfyOnMaxZoom: true,
    showCoverageOnHover: false,
    maxClusterRadius: 60,
    disableClusteringAtZoom: clusterZoom, // zoom 18 and below, markers will be clustered
    iconCreateFunction: function (cluster) {
      const size = cluster.getChildCount()
      let sizeClass = 'cluster-'
      sizeClass += size < 10 ? 'small' : size < 100 ? 'medium' : 'large'
      // iconClass += 'small'
      return L.divIcon({
        html: '<div><img src="' + iconUrl + '"><span>' + size + '</span></div>',
        className: 'marker-cluster ' + sizeClass,
        iconSize: L.point(40 * mapIs, 40 * mapIs),
        iconAnchor: [14 * mapIs, 14 * mapIs],
        popupAnchor: [40 * mapIs, 0]
      })
    }
  })
}

function generatePolygon (p) {
  return L.polygon(p.points, {
    color: p.color,
    fill: true,
    fillColor: p.color,
    fillOpacity: 0.4,
    opacity: 0.8,
    weight: 3
  })
}

function generatePolylines (l) {
  return L.polyline(l.points, {
    color: l.color,
    opacity: 0.8,
    weight: 3
  })
}

function generateIcon (i, mapIs) {
  return L.icon({
    iconUrl: `${process.env.ASSET_PATH}assets/${i.icon}`,
    iconSize: [28 * mapIs, 28 * mapIs],
    iconAnchor: [14 * mapIs, 28 * mapIs],
    popupAnchor: [0, 0]
  })
}

function generateMarker (m, mapIs) {
  const icon = generateIcon(m, mapIs)
  const markerOptions = {
    icon: icon
  }

  if (Object.prototype.hasOwnProperty.call(m, 'angle')) {
    markerOptions.rotationAngle = m.angle
  }

  return L.marker([m.lat, m.lng], markerOptions)
}

function bindTooltip (object, content, options, position = '') {
  object
    .bindTooltip(content, options)

  if (position) {
    object.openTooltip(position)
  }
  return object
}

function bindPopup (object, content, options, position) {
  object
    .bindPopup(content, options)

  if (position) {
    object.openPopup(position)
  }
  return object
}
