<template>
  <v-dialog
    persistent
    v-model="show"
    hide-overlay
    no-click-animation
    max-width="370"
    width="auto"
  >
    <v-card outlined>
      <v-toolbar color="" dense elevation="0">
        <v-toolbar-title class="text-subtitle-2">{{
          $t("OBJECT_TRAILER_PROPERTIES")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn plain icon x-small @click="closeDialog">
          <v-icon dense>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container fluid>
        <validation-observer ref="observer">
          <v-row>
            <v-col>
              <div class="mb-2 d-flex flex-row">
                <span class="text-body-2 mr-1">{{ $t("NAME") }}</span>

                <FormTooltip>
                  <template v-slot:tooltip-trigger>
                    <v-btn x-small color="primary lighten-1" text icon>
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:tooltip-content
                    >{{ $t("NAME") }}
                  </template>
                </FormTooltip>
              </div>
              <validation-provider
                v-slot="{ errors }"
                rules="required|max:25"
                :name="$t('NAME')"
                ><v-text-field
                  v-model="fields.name"
                  :error-messages="errors"
                  :label="$t('NAME')"
                  required

                  solo
                      color="grey"
                      dense
                      hide-details="auto"
                      outlined
                ></v-text-field
              ></validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="mb-2 d-flex flex-row">
                <span class="text-body-2 mr-1">Token</span>

                <FormTooltip>
                  <template v-slot:tooltip-trigger>
                    <v-btn x-small color="primary lighten-1" text icon>
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:tooltip-content
                    >Token
                  </template>
                </FormTooltip>
              </div>
              <validation-provider
                v-slot="{ errors }"
                rules="max:30"
                name="Token"
                ><v-text-field

                  v-model="fields.token"

                  label="Token"
                  :error-messages="errors"
                  solo
                      color="grey"
                      dense
                      hide-details="auto"
                      outlined
                ></v-text-field
              ></validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="mb-2 d-flex flex-row">
                <span class="text-body-2 mr-1">{{ $t("TRANSPORT_MODEL") }}</span>

                <FormTooltip>
                  <template v-slot:tooltip-trigger>
                    <v-btn x-small color="primary lighten-1" text icon>
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:tooltip-content
                    >{{ $t("TRANSPORT_MODEL") }}
                  </template>
                </FormTooltip>
              </div>
              <validation-provider
                v-slot="{ errors }"
                rules="max:30"
                :name="$t('TRANSPORT_MODEL')"
                ><v-text-field

                  v-model="fields.model"

                  :label="$t('TRANSPORT_MODEL')"
                  :error-messages="errors"
                  solo
                      color="grey"
                      dense
                      hide-details="auto"
                      outlined
                ></v-text-field
              ></validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="mb-2 d-flex flex-row">
                <span class="text-body-2 mr-1">{{ $t("VIN") }}</span>

                <FormTooltip>
                  <template v-slot:tooltip-trigger>
                    <v-btn x-small color="primary lighten-1" text icon>
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:tooltip-content
                    >{{ $t("VIN") }}
                  </template>
                </FormTooltip>
              </div>
              <validation-provider
                v-slot="{ errors }"
                rules="max:20"
                :name="$t('VIN')"
                ><v-text-field

                  v-model="fields.vin"

                  :label="$t('VIN')"
                  :error-messages="errors"
                  solo
                      color="grey"
                      dense
                      hide-details="auto"
                      outlined
                ></v-text-field
              ></validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="mb-2 d-flex flex-row">
                <span class="text-body-2 mr-1">{{ $t("PLATE_NUMBER") }}</span>

                <FormTooltip>
                  <template v-slot:tooltip-trigger>
                    <v-btn x-small color="primary lighten-1" text icon>
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:tooltip-content
                    >{{ $t("PLATE_NUMBER") }}
                  </template>
                </FormTooltip>
              </div>
              <validation-provider
                v-slot="{ errors }"
                rules="max:20"
                :name="$t('PLATE_NUMBER')"
                ><v-text-field

                  v-model="fields.plate_number"

                  :label="$t('PLATE_NUMBER')"
                  :error-messages="errors"
                  solo
                      color="grey"
                      dense
                      hide-details="auto"
                      outlined
                ></v-text-field
              ></validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="mb-2 d-flex flex-row">
                <span class="text-body-2 mr-1">{{ $t("DESCRIPTION") }}</span>

                <FormTooltip>
                  <template v-slot:tooltip-trigger>
                    <v-btn x-small color="primary lighten-1" text icon>
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:tooltip-content
                    >{{ $t("DESCRIPTION") }}
                  </template>
                </FormTooltip>
              </div>
              <validation-provider
                v-slot="{ errors }"
                rules="max:256"
                :name="$t('DESCRIPTION')"
                ><v-textarea
                  rows="2"

                  v-model="fields.description"
                  :label="$t('DESCRIPTION')"
                  :error-messages="errors"
                  solo
                      color="grey"
                      dense
                      hide-details="auto"
                      outlined
                ></v-textarea
              ></validation-provider>
            </v-col>
          </v-row>
          <v-row class="mb-3 justify-center flex-column flex-sm-row d-flex">
            <v-btn
              @click="closeDialog"
              color="primary"
              outlined
              class="mr-sm-2 mb-2"
              max-width="50%"
              >Cancel</v-btn
            >
            <v-btn @click="save" color="primary" max-width="50%">Save</v-btn>
          </v-row>
        </validation-observer>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import FormTooltip from '@/components/includes/FormTooltip.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormTooltip
  },
  props: {
    show: Boolean,
    id: String
  },
  data () {
    return {
      fields: {
        name: '',
        token: '',
        model: '',
        vin: '',
        plate_number: '',
        description: ''
      },
      showConfirm: false,
      confirmDetails: {
        title: '',
        message: '',
        action: {
          yes: '',
          no: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      message: 'messages/message',
      trailers: 'trailers/trailers'
    })
  },
  watch: {
    show: function () {
      try {
        if (this.id) {
          const trailer = this.trailers[this.id]

          this.fields = {
            name: trailer.name,
            token: trailer.assign_id,
            model: trailer.model,
            vin: trailer.vin,
            plate_number: trailer.plate_number,
            description: trailer.desc
          }
        } else {
          this.fields = {
            name: '',
            token: '',
            model: '',
            vin: '',
            plate_number: '',
            description: ''
          }
        }
      } catch (error) {
        this.handleError(error)
      }
    }
  },
  methods: {
    ...mapActions({
      saveTrailer: 'trailers/saveTrailer',
      fetchTrailers: 'trailers/fetchTrailers'
    }),
    async save () {
      try {
        const valid = await this.$refs.observer.validate()
        // this.fields.name = this.fields.name ? this.fields.name : "New Object";
        this.$log.debug(valid)

        if (valid) {
          const params = {
            cmd: 'save_object_trailer',
            trailer_id: this.id ? this.id : false, // false if adding
            trailer_name: this.fields.name,
            trailer_assign_id: this.fields.token,
            trailer_model: this.fields.model,
            trailer_vin: this.fields.vin,
            trailer_plate_number: this.fields.plate_number,
            trailer_desc: this.fields.description

            // add https://gator.net.au/func/fn_settings.trailers.php post
            // cmd: save_object_trailer
            // trailer_id: false
            // trailer_name: Test trailer 2
            // trailer_assign_id: token
            // trailer_model: model
            // trailer_vin: vin
            // trailer_plate_number: plate num
            // trailer_desc: desc

            // edit
            // cmd: save_object_trailer
            // trailer_id: 3
            // trailer_name: Test Trailer
            // trailer_assign_id: TOKEN
            // trailer_model: model
            // trailer_vin: vin
            // trailer_plate_number: plate
            // trailer_desc: desc
          }

          const response = await this.saveTrailer(params)
          this.$log.debug('save trailer response', response)

          if (response.message === 'OK') {
            this.$notify({
                    duration: 5000,
                    closeOnClick: false,
              type: 'success',
              title: this.$t('INFORMATION'),
              text: this.$t('CHANGES_SAVED_SUCCESSFULLY')
            })
            if (process.env.NODE_ENV === 'production') {
              this.fetchTrailers()
            }
            this.closeDialog()
          } else {
            this.$notify({
                    duration: 5000,
                    closeOnClick: false,
              type: 'error',
              title: this.$t('ERROR'),
              text:
                'Something went wrong! Please contact system administrator.'
            })
            this.closeDialog()
          }
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    closeDialog () {
      this.$refs.observer.reset()
      this.$emit('show-trailers-dialog', false)
    }
  }
}
</script>

<style></style>
