const getters = {
  drivers: state => state.drivers,
  passengers: state => state.passengers,
  getDriverById: state => id => {
    if (typeof state.drivers === 'undefined') return null
    if (state.drivers[id]) return state.drivers[id]
    else return null
  },
  getDriverName: state => id => {
    // Vue.$log.debug(id, 'driver')
    if (typeof state.drivers === 'undefined') return ''
    if (state.drivers[id]) {
      return state.drivers[id].name
    }
    return ''
  },
  getPassengerById: state => id => {
    let passenger = state.passengers.filter((passenger) => {
      // Vue.$log.debug(passenger)
      return passenger.id === parseInt(id)
    })
    passenger = passenger.shift()
    return passenger
  },
  driverItems: state => {
    const data = []
    // if (state.drivers)
    for (const [id] of Object.entries(state.drivers)) {
      const driver = state.drivers[id]
      data.push({
        text: driver.name,
        value: id
      })
    };
    return data
  }
}

export default getters
