<template>
  <div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="scheduleData"
      item-key="id"
      show-select
      class="elevation-1"
      :search="search"
      dense
      fixed-header
      height="calc(100vh - 220px)"
    >
    <template v-slot:top>
        <v-toolbar>
          <v-row class="align-center">
            <v-col>
              <v-tooltip bottom color="tooltip" v-if="checkPrivilege('viewer')">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="border-1--yellow"
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="openForm"
                  >
                    <v-icon left> mdi-plus </v-icon> {{ $t("ADD") }}
                  </v-btn>
                </template>
                <span>Add</span>
              </v-tooltip>
              <v-menu offset-y v-if="checkPrivilege('viewer')">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="border-1--yellow"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left> mdi-settings-outline </v-icon> Options
                  </v-btn>
                </template>
                <v-list dense tile>
                  <v-list-item @click="deleteSchedule(selected)">
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-tooltip bottom color="tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="border-1--yellow"
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="getCmdScheduleData()"
                  >
                    <v-icon > mdi-refresh </v-icon>
                  </v-btn>
                </template>
                <span>Refresh</span>
              </v-tooltip>
            </v-col>
            <v-col
              cols="8"
              sm="6"
              md="5"
              lg="4"
              class="d-flex justify-content-end"
            >
              <v-text-field
                solo
                dense
                single-line
                hide-details
                clearable
                outlined
                prepend-inner-icon="mdi-magnify"
                v-model="search"
                label="Search"
                color="grey"
                class=""
              ></v-text-field
            ></v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.active="{ item }">
        <v-img
          max-width="18"
          :src="
            `${publicPath}assets/img/images/` +
            (item.active ? 'tick-green.svg' : 'remove-red.svg')
          "
          class="mr-1"
        ></v-img>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom color="tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-2"
              x-small
              icon
              dense
              plain
              v-bind="attrs"
              v-on="on"
              @click="scheduleId = item.id; showScheduleForm = true"
            >
              <v-icon dense>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip bottom color="tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              x-small
              icon
              dense
              plain
              v-bind="attrs"
              v-on="on"
              @click="deleteSchedule([item])"
            >
              <v-icon dense>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>

    </v-data-table>
    <ScheduleForm
      :showScheduleForm="showScheduleForm"
      :scheduleId="scheduleId"
      @show-schedule-dialog="() => {showScheduleForm = false; scheduleId = null}"
      :imei="imei"
    />

    <ConfirmDialog
      :info="confirmDetails"
      :show="showConfirm"
      v-on:show-confirm-dialog="confirmDetails.func"
    />

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ScheduleForm from '@/components/control/ScheduleForm.vue'
import ConfirmDialog from '@/components/includes/ConfirmDialog.vue'
// import moment from 'moment'
export default {
  components: {
    ScheduleForm,
    ConfirmDialog
  },
  props: {
    imei: {
      default: null
    }
  },
  data () {
    return {
      scheduleId: null,
      showScheduleForm: false,
      search: '',
      selected: [],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Active', value: 'active' },
        { text: 'Schedule', value: 'schedule' },
        { text: 'Gateway', value: 'gateway' },
        { text: 'Type', value: 'type' },
        { text: 'Command', value: 'cmd' },
        { text: '', value: 'actions', sortable: false }
      ],
      showConfirm: false,
      confirmDetails: {
        title: '',
        message: '',
        action: {
          yes: '',
          no: ''
        },
        func: () => {}
      },
      toDelete: ''
    }
  },
  computed: {
    ...mapGetters({
      cmdScheduleData: 'cmd/cmdScheduleData',
      getObjectSettingsById: 'objects/getObjectSettingsById'
    }),
    scheduleData: function () {
      const data = []

      for (const [id, cmd] of Object.entries(this.cmdScheduleData)) {
        data.push({
          name: cmd.name,
          active: JSON.parse(cmd.active), // tick-green remove-red
          schedule: JSON.parse(cmd.exact_time) ? this.$t('EXACT_TIME') : this.$t('RECURRING'),
          gateway: cmd.gateway.toUpperCase(),
          type: cmd.type.toUpperCase(),
          cmd: cmd.cmd,
          id: id
        })
      }

      return data
    }
  },
  watch: {},
  created () {
    this.getCmdScheduleData().catch(error => this.handleError(error))
  },
  methods: {
    ...mapActions({
      deleteCmdSchedule: 'cmd/deleteCmdSchedule',
      getCmdData: 'cmd/getCmdData',
      getCmdScheduleData: 'cmd/getCmdScheduleData'

    }),

    openForm () {
      try {
        if (this.checkPrivilege('viewer')) {
          this.showScheduleForm = true
        } else {
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'error',
            title: this.$t('ERROR'),
            text: this.$t('THIS_ACCOUNT_HAS_NO_PRIVILEGES_TO_DO_THAT')
          })
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    setShowModal: function (isShow) {
      this.showScheduleForm = false
    },
    deleteSchedule (items) {
      // console.log(items)
      this.selected = items
      this.confirmDetails = {
        title: 'Confirm',
        message: this.$t('ARE_YOU_SURE_YOU_WANT_TO_DELETE'),
        func: this.confirm
      }
      this.showConfirm = true
    },
    async confirm (val) {
      // console.log('delete', this.selected)
      try {
        this.showConfirm = false
        if (val) {
          const items = []
          this.selected.forEach(item => {
            items.push(item.id)
          })

          // console.log('go and delete', items)
          const response = await this.deleteCmdSchedule(items)
          if (response.message === 'OK') {
            this.$notify({
                    duration: 5000,
                    closeOnClick: false,
              type: 'success',
              title: this.$t('INFORMATION'),
              text: this.$t('CHANGES_SAVED_SUCCESSFULLY')
            })
            if (process.env.NODE_ENV === 'production') {
              this.getCmdScheduleData()
            }
          } else {
            this.$notify({
                    duration: 5000,
                    closeOnClick: false,
              type: 'error',
              title: this.$t('ERROR'),
              text:
                'Something went wrong! Please contact system administrator.'
            })
          }

          this.selected = []
        } else {
          // console.log('dont delete')
          // this.toDelete = ''
        }
      } catch (error) {
        this.handleError(error)
      }
    }

  }
}
</script>

<style>
</style>
