<template>
  <div>
    <v-data-table
      dense
      :headers="headers"
      :items="tableData"
      item-key="name"
      class="personnel-table"
      group-by="group"
      :item-class="selectedRow"
      @click:row="handleRowClick"
      fixed-header
      height="500px"
      single-line
      hide-details
      dark
      :items-per-page="-1"
      hide-default-footer
    >
    <template v-slot:top>
      <v-toolbar extended>
        <template v-slot:default>
          <v-btn class="border-1--yellow" small @click="showForm = true">
            <v-icon left> mdi-plus </v-icon>
            Add New Person
          </v-btn>
        </template>
        <template v-slot:extension>
          <v-col class="pl-0"><v-text-field
            solo
            dense
            single-line
            hide-details
            clearable
            outlined
            prepend-inner-icon="mdi-magnify"
            v-model="filter1"
            label="Filter"
            color="grey"
            class=""
          ></v-text-field></v-col>
          <v-col class="pr-0"><v-text-field
            solo
            dense
            single-line
            hide-details
            clearable
            outlined
            prepend-inner-icon="mdi-magnify"
            v-model="filter2"
            label="2nd Filter"
            color="grey"
            class=""
          ></v-text-field></v-col>

        </template>
      </v-toolbar>
    </template>
      <template v-slot:header="">
        <thead>
          <tr>
            <th colspan="3" class="text-align-center">{{ $t("MAP") }}</th>
            <th colspan="2" class="text-align-center">Personnel</th>
            <th colspan="5" class="text-align-center hidden-md-and-down">
              Metrics
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:group.header="{ items, isOpen, toggle }">
        <th colspan="10" class="group-header" @click="toggle">
          {{ items[0].group }}
        </th>
      </template>

      <template v-slot:header.follow="{}">
        {{ $t("FOLLOW") }}
      </template>

      <template v-slot:item.locate="{ item }">
        <v-img
          max-width="18"
          :src="
            `${publicPath}assets/img/icons/` +
            (item.locate === true ? 'locate-green.svg' : 'locate.svg')
          "
        ></v-img>
      </template>

      <template v-slot:item.follow="{ item }">
        <v-simple-checkbox v-model="item.follow"></v-simple-checkbox>
      </template>
      <template v-slot:item.visible="{ item }">
        <v-simple-checkbox v-model="item.visible"></v-simple-checkbox>
      </template>

      <template v-slot:item.actions="{}">
        <v-img max-width="18" :src="`${publicPath}assets/img/icons/gear.svg`"></v-img>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    tableData: Array
  },
  data: () => ({
    filter1: '',
    filter2: '',
    selectedRowId: '',
    singleSelect: false,
    selected: [],
    headers: [
      {
        text: 'Group',
        value: 'group',
        class: 'hidden-md-and-down',
        cellClass: 'hidden-md-and-down'
      },
      { text: 'Locate', value: 'locate', sortable: false },
      {
        text: 'Visible',
        value: 'visible',
        sortable: false,
        class: 'hidden-md-and-down',
        cellClass: 'hidden-md-and-down'
      },
      {
        text: 'Follow',
        value: 'follow',
        sortable: false,
        class: 'hidden-md-and-down',
        cellClass: 'hidden-md-and-down'
      },
      { text: 'Driver Name', value: 'driver_name' },
      {
        text: 'Group Name',
        value: 'group_name',
        class: 'hidden-md-and-down',
        cellClass: 'hidden-md-and-down'
      },
      {
        text: 'ID',
        value: 'beacon_id',
        class: 'hidden-lg-and-down',
        cellClass: 'hidden-lg-and-down'
      },
      {
        text: 'Distance',
        value: 'beacon_distance',
        class: 'hidden-md-and-down',
        cellClass: 'hidden-md-and-down'
      },
      {
        text: 'Breaks',
        value: 'breaks',
        class: 'hidden-md-and-down',
        cellClass: 'hidden-md-and-down'
      },
      {
        text: 'Non stop',
        value: 'non_stop',
        class: 'hidden-md-and-down',
        cellClass: 'hidden-md-and-down'
      },
      { text: '', value: 'actions', sortable: false }
    ]
  }),
  methods: {
    handleRowClick: function (item) {
      this.selectedRowId = item.id
      this.$log.debug(this.selectedObj)
    },
    selectedRow (item) {
      return this.selectedRowId && item.id === this.selectedRowId
        ? 'active-row'
        : !this.selectedRowId && item.id === this.selectedObject
          ? 'active-row'
          : ''
    }
  }
}
</script>

<style></style>
