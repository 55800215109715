<template>
  <div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="templateData"
      item-key="id"
      show-select
      class="elevation-1"
      :search="search"
      dense
      fixed-header
      height="calc(100vh - 220px)"
    >
    <template v-slot:top>
        <v-toolbar>
          <v-row class="align-center">
            <v-col>
              <v-tooltip bottom color="tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="border-1--yellow"
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="openForm"
                  >
                    <v-icon left> mdi-plus </v-icon> {{ $t("ADD") }}
                  </v-btn>
                </template>
                <span>Add</span>
              </v-tooltip>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="border-1--yellow"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left> mdi-settings-outline </v-icon> Options
                  </v-btn>
                </template>
                <v-list dense tile>
                  <v-list-item @click="deleteTemplates(selected)">
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
                <v-list dense tile>
                  <v-list-item @click="loadTemplateDefaults()">
                    <v-list-item-title>{{ $t("TDJ_LOAD_GATOR_DEFAULTS") }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-tooltip bottom color="tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="border-1--yellow"
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="getCmdTemplateUnfilteredData()"
                  >
                    <v-icon > mdi-refresh </v-icon>
                  </v-btn>
                </template>
                <span>Refresh</span>
              </v-tooltip>
            </v-col>
            <v-col
              cols="8"
              sm="6"
              md="5"
              lg="4"
              class="d-flex justify-content-end"
            >
              <v-text-field
                solo
                dense
                single-line
                hide-details
                clearable
                outlined
                prepend-inner-icon="mdi-magnify"
                v-model="search"
                label="Search"
                color="grey"
                class=""
              ></v-text-field
            ></v-col>
          </v-row>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom color="tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-2"
              x-small
              icon
              dense
              plain
              v-bind="attrs"
              v-on="on"
              @click="templateId = item.id; showTemplateForm = true"
            >
              <v-icon dense>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip bottom color="tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              x-small
              icon
              dense
              plain
              v-bind="attrs"
              v-on="on"
              @click="deleteTemplates([item])"
            >
              <v-icon dense>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>

    </v-data-table>
    <TemplateForm
      :showTemplateForm="showTemplateForm"
      :id="templateId"
      v-on:show-template-dialog="setShowModal"
    />

    <ConfirmDialog
      :info="confirmDetails"
      :show="showConfirm"
      v-on:show-confirm-dialog="confirmDetails.func"
    />

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TemplateForm from '@/components/control/TemplateForm.vue'
import ConfirmDialog from '@/components/includes/ConfirmDialog.vue'
// import moment from 'moment'
export default {
  components: {
    TemplateForm,
    ConfirmDialog
  },
  props: {
    id: {
      default: null
    }
  },
  data () {
    return {
      showTemplateForm: false,
      search: '',
      selected: [],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Protocol', value: 'protocol' },
        { text: 'Gateway', value: 'gateway' },
        { text: 'Type', value: 'type' },
        { text: 'Command', value: 'cmd' },
        { text: '', value: 'actions', sortable: false }
      ],
      showConfirm: false,
      confirmDetails: {
        title: '',
        message: '',
        action: {
          yes: '',
          no: ''
        },
        func: () => {}
      },
      templateId: null
    }
  },
  computed: {
    ...mapGetters({
      cmdTemplateData: 'cmd/cmdTemplateData',
      getObjectSettingsById: 'objects/getObjectSettingsById',
      objectsNew: 'objects/objectsNew'
    }),
    templateData: function () {
      const data = []

      for (const [id, cmd] of Object.entries(this.cmdTemplateData)) {
        data.push({
          name: cmd.name,
          protocol: cmd.protocol,
          gateway: cmd.gateway.toUpperCase(),
          type: cmd.type.toUpperCase(),
          cmd: cmd.cmd,
          id: id
        })
      }

      return data
    }
  },
  watch: {},
  created () {
    this.getCmdTemplateUnfilteredData().catch(error => this.handleError(error))
  },
  methods: {
    ...mapActions({
      deleteCmdTemplate: 'cmd/deleteCmdTemplate',
      getCmdData: 'cmd/getCmdData',
      getCmdTemplateUnfilteredData: 'cmd/getCmdTemplateUnfilteredData',
      loadCmdTemplateDefaults: 'cmd/loadCmdTemplateDefaults'
    }),
    deleteTemplates (items) {
      // console.log(items)
      this.selected = items
      this.confirmDetails = {
        title: 'Confirm',
        message: this.$t('ARE_YOU_SURE_YOU_WANT_TO_DELETE'),
        func: this.confirm
      }
      this.showConfirm = true
    },
    async confirm (val) {
      // console.log('delete', this.selected)
      try {
        this.showConfirm = false
        if (val) {
          const items = []
          this.selected.forEach(item => {
            items.push(item.id)
          })

          // console.log('go and delete', items)
          const response = await this.deleteCmdTemplate(items)
          if (response.message === 'OK') {
            this.$notify({
                    duration: 5000,
                    closeOnClick: false,
              type: 'success',
              title: this.$t('INFORMATION'),
              text: this.$t('CHANGES_SAVED_SUCCESSFULLY')
            })
            if (process.env.NODE_ENV === 'production') {
              this.getCmdTemplateUnfilteredData()
            }
          } else {
            this.$notify({
                    duration: 5000,
                    closeOnClick: false,
              type: 'error',
              title: this.$t('ERROR'),
              text:
                'Something went wrong! Please contact system administrator.'
            })
          }

          this.selected = []
        } else {
          // console.log('dont delete')
        }
      } catch (error) {
        this.handleError(error)
      }
    },

    setShowModal: function (isShow) {
      this.showTemplateForm = false
      this.templateId = null
    },

    openForm () {
      try {
        if (this.checkPrivilege('viewer')) {
          this.showTemplateForm = true
        } else {
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'error',
            title: this.$t('ERROR'),
            text: this.$t('THIS_ACCOUNT_HAS_NO_PRIVILEGES_TO_DO_THAT')
          })
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    async loadTemplateDefaults () {
      // console.log('loadTemplateDefaults')
      try {
        const response = await this.loadCmdTemplateDefaults()
        if (response.message === 'OK') {
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'success',
            title: this.$t('INFORMATION'),
            text: 'Gator default templates have been loaded'
          })
          if (process.env.NODE_ENV === 'production') {
            this.getCmdTemplateUnfilteredData()
          }
        } else {
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'error',
            title: this.$t('ERROR'),
            text:
            'Something went wrong! Please contact system administrator.'
          })
        }
      } catch (error) {
        this.handleError(error)
      }
    }

  }
}
</script>

<style>
</style>
