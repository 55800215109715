import { i18n } from '@/plugins/i18n'
export const globals = {
  sortArrayByElement,
  getTimeDetails,
  urlPosition,
  getSensorValueFuelLevelSumUp,
  getSensorValue,
  getParamValue,
  convertJsonToFormData

}

// var evalFunc = eval

export const config = {
  map_refresh: 3.7,
  map_min_zoom: 3,
  map_max_zoom: 18,
  event_refresh: 30,
  cmd_status_refresh: 60,
  img_refresh: 60,
  chat_refresh: 10,
  billing_refresh: 60,
  session_check: 30
}

function sortArrayByElement (arr, el) {
  arr = arr.slice(0)

  arr.sort(function (a, b) {
    const x = a[el].toLowerCase()
    const y = b[el].toLowerCase()
    return x < y ? -1 : x > y ? 1 : 0
  })

  return arr
}

function getTimeDetails (sec, showDays) {
  let seconds = 0
  let hours = 0
  let minutes = 0
  let days

  if (sec % 86400 <= 0) {
    days = sec / 86400
  }
  if (sec % 86400 > 0) {
    const rest = sec % 86400
    days = (sec - rest) / 86400

    if (rest % 3600 > 0) {
      const rest1 = rest % 3600
      hours = (rest - rest1) / 3600

      if (rest1 % 60 > 0) {
        const rest2 = rest1 % 60
        minutes = (rest1 - rest2) / 60
        seconds = rest2
      } else {
        minutes = rest1 / 60
      }
    } else {
      hours = rest / 3600
    }
  }

  days = parseFloat(days)
  hours = parseFloat(hours)
  minutes = parseFloat(minutes)
  seconds = parseFloat(seconds)

  if (showDays === false) {
    hours += days * 24
    days = 0
  }

  if (days > 0) {
    days = days + ' ' + i18n.t('UNIT_D') + ' '
  } else {
    days = ''
  }
  if (hours > 0) {
    hours = hours + ' ' + i18n.t('UNIT_H') + ' '
  } else {
    hours = ''
  }
  if (minutes > 0) {
    minutes = minutes + ' ' + i18n.t('UNIT_MIN') + ' '
  } else {
    minutes = ''
  }
  seconds = seconds + ' ' + i18n.t('UNIT_S')

  return days + hours + minutes + seconds
}
function urlPosition (lat, lng) {
  // Do we need to offer other links?
  return (
    '<a href="http://maps.google.com/maps?q=' +
    lat +
    ',' +
    lng +
    '&t=m" target="_blank">' +
    parseFloat(lat).toFixed(6) +
    ' &deg;, ' +
    parseFloat(lng).toFixed(6) +
    ' &deg;</a>'
  )
}
function getSensorValueFuelLevelSumUp (imei, params, sensor) {
  const result = []
  result.value = 0
  result.value_full = ''

  const fuelSensors = this.getSensorFromType(imei, 'fuel')

  for (let i = 0; i < fuelSensors.length; i++) {
    if (fuelSensors[i].result_type === 'value') {
      const sensorData = this.getSensorValue(params, fuelSensors[i])
      result.value += parseFloat(sensorData.value)
    }
  }

  result.value = Math.round(result.value * 100) / 100

  result.value_full = result.value
  result.value_full += ' ' + sensor.units

  return result
}

function calcString (str) {
  const result = 0
  try {
    str = str.trim()
    str = str.replace(/[^-()\d/*+.]/g, '')
    return result + this.eval(str)
  } catch (err) {
    return result
  }
}

function getSensorValue (params, sensor) {
  const result = []
  result.value = 0
  result.value_full = ''

  let paramValue = this.getParamValue(params, sensor.param)

  // formula
  if (
    sensor.result_type === 'abs' ||
    sensor.result_type === 'rel' ||
    sensor.result_type === 'value'
  ) {
    paramValue = parseFloat(paramValue)

    if (!isNumber(paramValue)) {
      paramValue = 0
    }

    if (sensor.formula !== '') {
      let formula = sensor.formula.toLowerCase()
      formula = formula.replace('x', paramValue)
      paramValue = calcString(formula)
    }
  }

  if (sensor.result_type === 'abs' || sensor.result_type === 'rel') {
    paramValue = Math.round(paramValue * 1000) / 1000

    result.value = paramValue
    result.value_full = paramValue
  } else if (sensor.result_type === 'logic') {
    if (paramValue === 1) {
      result.value = paramValue
      result.value_full = sensor.text_1
    } else {
      result.value = paramValue
      result.value_full = sensor.text_0
    }
  } else if (sensor.result_type === 'value') {
    // calibration
    let outOfCal = true
    const calibration = sensor.calibration

    // function to get calibration Y value
    const calGetY = function (x) {
      let result = 0
      for (let j = 0; j < calibration.length; j++) {
        if (calibration[j].x === x) {
          result = parseFloat(calibration[j].y)
        }
      }
      return result
    }

    if (calibration.length >= 2) {
      // put all X values to separate array
      const xArr = []
      for (let i = 0; i < calibration.length; i++) {
        xArr.push(parseFloat(calibration[i].x))
      }

      // xArr.sort(this.sortNumber());
      xArr.sort()

      // loop and check if in cal
      for (let i = 0; i < xArr.length; i++) {
        const xLow = xArr[i]
        const xHigh = xArr[i + 1]

        if (paramValue >= xLow && paramValue <= xHigh) {
          // get Y low and high
          const yLow = calGetY(xLow)
          const yHigh = calGetY(xHigh)

          // get coeficient
          const a = paramValue - xLow
          const b = xHigh - xLow

          let coef = a / b

          const c = yHigh - yLow
          coef = c * coef

          paramValue = yLow + coef

          outOfCal = false

          break
        }
      }

      if (outOfCal) {
        // check if lower than cal
        const xLow = xArr[0]

        if (paramValue < xLow) {
          paramValue = calGetY(xLow)
        }

        // check if higher than cal
        const xHigh = xArr.slice(-1)[0]

        if (paramValue > xHigh) {
          paramValue = calGetY(xHigh)
        }
      }
    }

    paramValue = Math.round(paramValue * 100) / 100

    // dictionary
    const dictionary = sensor.dictionary
    if (dictionary.length >= 1) {
      let paramText = paramValue

      for (let j = 0; j < dictionary.length; j++) {
        if (dictionary[j].value === paramValue) {
          paramText = dictionary[j].text
        }
      }

      result.value = paramValue
      result.value_full = paramText + ' ' + sensor.units
    } else {
      result.value = paramValue
      result.value_full = paramValue + ' ' + sensor.units
    }
  } else if (sensor.result_type === 'string') {
    result.value = paramValue
    result.value_full = paramValue
  } else if (sensor.result_type === 'percentage') {
    paramValue = parseFloat(paramValue)
    sensor.lv = parseFloat(sensor.lv)
    sensor.hv = parseFloat(sensor.hv)

    if (paramValue > sensor.lv && paramValue < sensor.hv) {
      const a = paramValue - sensor.lv
      const b = sensor.hv - sensor.lv

      result.value = Math.round((a / b) * 100)
    } else if (paramValue <= sensor.lv) {
      result.value = 0
    } else if (paramValue >= sensor.hv) {
      result.value = 100
    }

    result.value_full = result.value + ' ' + sensor.units
  }

  return result
}
function isNumber (num) {
  return Number(parseFloat(num)) === num
}

function getParamValue (params, param) {
  let paramValue = 0

  if (params !== null) {
    if (params[param] !== undefined) {
      paramValue = parseInt(params[param])
    }
  }

  return paramValue
}

function convertJsonToFormData (object) {
  const formData = new FormData()

  Object.entries(object).forEach(([key, value]) => {
    formData.append(key, value)
  })

  return formData
}
