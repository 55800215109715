
const getters = {
  reports: (state) => state.reports,
  reportsList: (state) => state.report_list,
  getReportType: () => (type) => {
    let reportType = ''
    switch (type) {
      case 'general':
        reportType = 'General information'
        break
      case 'general_merged':
        reportType = 'General information (merged)'
        break
      case 'object_info':
        reportType = 'Object information'
        break
      case 'current_position':
        reportType = 'Current position'
        break
      case 'current_position_off':
        reportType = 'Current position (offline)'
        break
      case 'drives_stops':
        reportType = 'Drives and stops'
        break
      case 'travel_sheet':
        reportType = 'Travel sheet'
        break
      case 'mileage_daily':
        reportType = 'Mileage (daily)'
        break
      case 'overspeed':
        reportType = 'Overspeeds'
        break
      case 'underspeed':
        reportType = 'Underspeeds'
        break
      case 'zone_in_out':
        reportType = 'Zone in/out'
        break
      case 'events':
        reportType = 'Events'
        break
      case 'service':
        reportType = 'Service'
        break
      case 'fuelfillings':
        reportType = 'Fuel fillings'
        break
      case 'fuelthefts':
        reportType = 'Fuel thefts'
        break
      case 'logic_sensors':
        reportType = 'Logic sensors'
        break
      case 'rag':
        reportType = 'Driver behavior (RAG by object)'
        break
      case 'rag_driver':
        reportType = 'Driver behavior (RAG by driver)'
        break
      case 'rilogbook':
        reportType = 'RFID and iButton logbook'
        break
      case 'dtc':
        reportType = 'DTC (Diagnostic Trouble Codes)'
        break
      case 'speed_graph':
        reportType = 'Speed'
        break
      case 'altitude_graph':
        reportType = 'Altitude'
        break
      case 'acc_graph':
        reportType = 'Ignition'
        break
      case 'fuellevel_graph':
        reportType = 'Fuel level'
        break
      case 'temperature_graph':
        reportType = 'Temperature'
        break
      case 'sensor_graph':
        reportType = 'Sensor'
        break
      case 'routes':
        reportType = 'Routes'
        break
    }
    return reportType
  },
  getReportSchedule: () => (code) => {
    let reportType = ''
    switch (code) {
      case 'd':
        reportType = 'Daily'
        break
      case 'w':
        reportType = 'Weekly'
        break
      case 'm':
        reportType = 'Monthly'
        break
    }
    return reportType
  }
}

export default getters
