<template>
  <div class="fill-height">
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="groupsData"
      item-key="id"
      show-select
      class="elevation-1"
      :search="search"
      dense
      fixed-header
      height="calc(100vh - 172px)"
      :items-per-page="-1"
    >
      <template v-slot:top>
        <v-toolbar>
          <v-row class="align-center">
            <v-col>
              <v-tooltip bottom color="tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="border-1--yellow"
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="openForm"
                  >
                    <v-icon left> mdi-plus </v-icon> Add
                  </v-btn>
                </template>
                <span>Add</span>
              </v-tooltip>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="border-1--yellow"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left> mdi-settings-outline </v-icon> Options
                  </v-btn>
                </template>
                <v-list dense tile>
                  <v-list-item @click="uploadFile()">
                    <v-list-item-title>Import</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="exportData()">
                    <v-list-item-title>Export</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="deleteSelected()">
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

            </v-col>
            <v-col
              cols="8"
              sm="6"
              md="5"
              lg="4"
              class="d-flex justify-content-end"
            >
              <v-text-field
                solo
                dense
                single-line
                hide-details
                clearable
                outlined
                prepend-inner-icon="mdi-magnify"
                v-model="search"
                label="Search"
                color="grey"
                class=""
              ></v-text-field
            ></v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom color="tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-2"
              x-small
              icon
              dense
              plain
              v-bind="attrs"
              v-on="on"
              @click="edit(item.id)"
            >
              <v-icon dense>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip bottom color="tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              x-small
              icon
              dense
              plain
              v-bind="attrs"
              v-on="on"
              @click="remove(item.id)"
            >
              <v-icon dense>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <input
        ref="uploader"
        class="d-none"
        type="file"
        @change="onFileChanged"
    >
    <GroupsForm
      :show="showForm"
      :id="id"
      v-on:show-groups-dialog="setShowModal"
    />

    <ConfirmDialog
      :info="confirmDetails"
      :show="showConfirm"
      v-on:show-confirm-dialog="confirmDetails.func"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import GroupsForm from '@/components/forms/GroupsForm.vue'
import ConfirmDialog from '@/components/includes/ConfirmDialog.vue'
import moment from 'moment'
export default {
  components: {
    GroupsForm,
    ConfirmDialog
  },
  props: {},
  data () {
    return {
      showForm: false,
      id: '',
      showConfirm: false,
      confirmDetails: {
        title: '',
        message: '',
        action: {
          yes: '',
          no: ''
        },
        func: () => {}
      },
      toDelete: '',
      search: '',
      selected: [],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Objects', value: 'objects' },
        { text: 'Description', value: 'description' },
        { text: '', value: 'actions', sortable: false }
      ],
      selectedFile: null
    }
  },
  computed: {
    ...mapGetters({
      groups: 'groups/groups',
      getGroupedObjects: 'objects/getGroupedObjects'
    }),
    groupsData: function () {
      const grouped = this.getGroupedObjects
      this.$log.debug('grouped', grouped)
      const data = []
      Object.keys(this.groups).forEach((key) => {
        if (key !== '0') {
          const group = this.groups[key]

          let numOfObjects = 0
          if (grouped.length >= 1) {
            if (grouped[parseInt(key)]) {
              numOfObjects = grouped[parseInt(key)].length
            }
          }
          data.push({
            name: group.name,
            objects: numOfObjects,
            description: group.desc,
            id: key
          })
        }
      })
      return data
    }
  },
  watch: {},
  created () {},
  methods: {
    ...mapActions({
      removeGroup: 'groups/removeGroup',
      importGroups: 'groups/importGroups',
      fetchGroupSettings: 'groups/fetchGroupSettings'
    }),
    openForm: function () {
      try {
        if (this.checkPrivilege('viewer')) {
          this.showForm = true
        } else {
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'error',
            title: this.$t('ERROR'),
            text: this.$t('THIS_ACCOUNT_HAS_NO_PRIVILEGES_TO_DO_THAT')
          })
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    setShowModal: function (isShow) {
      this.showForm = isShow
      this.id = ''
    },
    edit (id) {
      this.id = id
      this.openForm()
    },
    remove (id) {
      try {
        if (this.checkPrivilege('viewer')) {
          this.toDelete = id
          this.confirmDetails = {
            title: 'Confirm',
            message: this.$t('ARE_YOU_SURE_YOU_WANT_TO_DELETE'),
            func: this.confirm
          }
          this.showConfirm = true
        } else {
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'error',
            title: this.$t('ERROR'),
            text: this.$t('THIS_ACCOUNT_HAS_NO_PRIVILEGES_TO_DO_THAT')
          })
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    async confirm (val) {
      try {
        this.showConfirm = false
        if (val) {
          const items = []
          if (this.toDelete) {
            items.push(this.toDelete)
          } else if (this.selected.length) {
            this.selected.forEach(group => {
              items.push(group.id)
            })
          }
          const response = await this.removeGroup(items)
          if (response.message === 'OK') {
            this.$notify({
                    duration: 5000,
                    closeOnClick: false,
              type: 'success',
              title: this.$t('INFORMATION'),
              text: this.$t('CHANGES_SAVED_SUCCESSFULLY')
            })
            if (process.env.NODE_ENV === 'production') {
              this.fetchGroupSettings()
            }
          } else {
            this.$notify({
                    duration: 5000,
                    closeOnClick: false,
              type: 'error',
              title: this.$t('ERROR'),
              text:
                'Something went wrong! Please contact system administrator.'
            })
          }
          if (this.toDelete) {
            this.toDelete = ''
          } else if (this.selected.length) {
            this.selected = []
          }
        } else {
          this.toDelete = ''
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    uploadFile () {
      if (this.checkPrivilege('viewer')) {
        this.$log.debug('show file input')
        // Trigger click on the FileInput
        this.$refs.uploader.click()
      } else {
        this.$notify({
                    duration: 5000,
                    closeOnClick: false,
          type: 'error',
          title: this.$t('ERROR'),
          text: this.$t('THIS_ACCOUNT_HAS_NO_PRIVILEGES_TO_DO_THAT')
        })
      }
    },
    onFileChanged (e) {
      this.$log.debug('file uploaded')
      const uploadedFile = e.target.files[0]
      // let groups = {};
      const reader = new FileReader()
      reader.onload = (e) => {
        try {
          this.$log.debug('read file')
          // this.$log.debug(e.target.result);
          this.selectedFile = e.target.result
          const fileContent = JSON.parse(e.target.result)
          if (fileContent.ogr === '0.1v') {
            this.confirmDetails = {
              title: 'Confirm',
              message: this.$t('GROUPS_FOUND').replace('%s', fileContent.groups.length) + ' ' + this.$t('ARE_YOU_SURE_YOU_WANT_TO_IMPORT'),
              func: this.confirmImport
            }
            this.showConfirm = true
          }
        } catch (error) {
          this.$log.debug(error)
        }
      }
      reader.onerror = (err) => this.$log.debug(err)
      reader.readAsText(uploadedFile)
    },
    async confirmImport (val) {
      this.$log.debug('confirm import')
      try {
        this.showConfirm = false
        if (val) {
          const params = {
            data: this.selectedFile
          }
          const response = await this.importGroups(params)
          if (response.message === 'OK') {
            this.$notify({
                    duration: 5000,
                    closeOnClick: false,
              type: 'success',
              title: this.$t('INFORMATION'),
              text: this.$t('CHANGES_SAVED_SUCCESSFULLY')
            })
            if (process.env.NODE_ENV === 'production') {
              this.fetchGroupSettings()
            }
          } else {
            this.$notify({
                    duration: 5000,
                    closeOnClick: false,
              type: 'error',
              title: this.$t('ERROR'),
              text:
                'Something went wrong! Please contact system administrator.'
            })
          }
        }
        this.toDelete = ''
      } catch (error) {
        this.handleError(error)
      }
    },
    exportData () {
      this.$log.debug('export')
      if (this.checkPrivilege('viewer')) {
        const data = {
          ogr: '0.1v',
          groups: []
        }
        const groups = []
        Object.keys(this.groups).forEach((key) => {
          groups.push(
            {
              group_name: this.groups[key].name,
              group_desc: this.groups[key].desc
            }
          )
        })
        data.groups = groups
        var fileURL = window.URL.createObjectURL(new Blob([JSON.stringify(data)]))
        var fileLink = document.createElement('a')

        fileLink.href = fileURL
        const fileName = 'object_groups ' + moment().format('YYYY-MM-DD HH:mm:ss') + '.ogr'
        fileLink.setAttribute('download', fileName)
        document.body.appendChild(fileLink)

        fileLink.click()
        fileLink.remove()
      } else {
        this.$notify({
                    duration: 5000,
                    closeOnClick: false,
          type: 'error',
          title: this.$t('ERROR'),
          text: this.$t('THIS_ACCOUNT_HAS_NO_PRIVILEGES_TO_DO_THAT')
        })
      }
    },
    deleteSelected () {
      this.$log.debug('delete selected', this.selected)
      if (this.checkPrivilege('viewer')) {
        // this.toDelete = id;
        this.confirmDetails = {
          title: 'Confirm',
          message: this.$t('ARE_YOU_SURE_YOU_WANT_TO_DELETE'),
          func: this.confirm
        }
        this.showConfirm = true
      } else {
        this.$notify({
                    duration: 5000,
                    closeOnClick: false,
          type: 'error',
          title: this.$t('ERROR'),
          text: this.$t('THIS_ACCOUNT_HAS_NO_PRIVILEGES_TO_DO_THAT')
        })
      }
    }
  }
}
</script>

<style>
</style>
