var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isMobileView && _vm.$router.currentRoute.name !== "live"
    ? _c(
        "div",
        { staticClass: "left-content left__container left-content__container" },
        [
          _c(
            "v-tabs",
            {
              staticClass: "secondary-tabs tracking-page-tabs",
              attrs: {
                "slider-color": "#FF5100",
                "background-color": "#3c3c3c",
                "show-arrows": "",
                height: "30px"
              }
            },
            [
              _c("v-tabs-slider", { attrs: { color: "#FF5100" } }),
              _vm._v(" "),
              _c(
                "v-tab",
                {
                  attrs: { exact: "", to: "/tracking" },
                  on: { change: _vm.handleTabChange }
                },
                [
                  _c("v-img", {
                    staticClass: "mr-1",
                    attrs: {
                      "max-width": "9",
                      src: _vm.publicPath + "assets/img/icons/tabs/tracking.svg"
                    }
                  }),
                  _vm._v("\n      Tracking\n    ")
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab",
                {
                  attrs: { exact: "", to: "/devices" },
                  on: { change: _vm.handleTabChange }
                },
                [
                  _c("v-img", {
                    staticClass: "mr-1",
                    attrs: {
                      "max-width": "17",
                      src: _vm.publicPath + "assets/img/icons/tabs/devices.svg"
                    }
                  }),
                  _vm._v("\n      Devices\n    ")
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab",
                {
                  attrs: { exact: "", to: "/personnel" },
                  on: { change: _vm.handleTabChange }
                },
                [
                  _c("v-img", {
                    staticClass: "mr-1",
                    attrs: {
                      "max-width": "12",
                      src:
                        _vm.publicPath + "assets/img/icons/tabs/personnel.svg"
                    }
                  }),
                  _vm._v("\n      Personnel\n    ")
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab",
                {
                  attrs: { exact: "", to: "/events" },
                  on: { change: _vm.handleTabChange }
                },
                [
                  _c("v-img", {
                    staticClass: "mr-1",
                    attrs: {
                      "max-width": "12",
                      src: _vm.publicPath + "assets/img/icons/tabs/events.svg"
                    }
                  }),
                  _vm._v("\n      " + _vm._s(_vm.$t("EVENTS")) + "\n    ")
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab",
                {
                  attrs: { exact: "", to: "/places" },
                  on: { change: _vm.handleTabChange }
                },
                [
                  _c("v-img", {
                    staticClass: "mr-1",
                    attrs: {
                      "max-width": "14",
                      src: _vm.publicPath + "assets/img/icons/tabs/places.svg"
                    }
                  }),
                  _vm._v("\n      " + _vm._s(_vm.$t("PLACES")) + "\n    ")
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab",
                {
                  attrs: { exact: "", to: "/beacons" },
                  on: { change: _vm.handleTabChange }
                },
                [
                  _c("v-img", {
                    staticClass: "mr-1",
                    attrs: {
                      "max-width": "10",
                      src: _vm.publicPath + "assets/img/icons/tabs/beacons.svg"
                    }
                  }),
                  _vm._v("\n      Beacons\n    ")
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tab",
                {
                  attrs: { exact: "", to: "/reports" },
                  on: { change: _vm.handleTabChange }
                },
                [
                  _c("v-img", {
                    staticClass: "mr-1",
                    attrs: {
                      "max-width": "12",
                      src: _vm.publicPath + "assets/img/icons/tabs/reports.svg"
                    }
                  }),
                  _vm._v("\n      " + _vm._s(_vm.$t("REPORTS")) + "\n    ")
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("router-view")
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }