import Vue from 'vue'

const mutations = {
  SET_DRIVERS: (state, data) => (state.drivers = data),
  SET_PASSENGERS: (state, data) => (state.passengers = data),
  MOCK_DRIVER_ADDITION: (state, data) => {
    Vue.$log.debug('add driver mock', data)
    const drivers = state.drivers
    let key

    const driverKeys = Object.keys(drivers)
    if (driverKeys.length >= 1) {
      key = driverKeys.reduce((a, b) => { return Math.max(a, b) })
    }
    key = parseInt(key) + 1

    drivers[key] = {
      name: data.driver_name,
      assign_id: data.driver_assign_id,
      idn: data.driver_idn,
      address: data.driver_address,
      phone: data.driver_phone,
      email: data.driver_email,
      desc: data.driver_desc,
      img: data.driver_img_file
    }
    state.drivers = []
    state.drivers = drivers
  },
  MOCK_DRIVER_UPDATE: (state, data) => {
    Vue.$log.debug('update obj mock')

    const drivers = state.drivers
    drivers[data.driver_id] = {
      name: data.driver_name,
      assign_id: data.driver_assign_id,
      idn: data.driver_idn,
      address: data.driver_address,
      phone: data.driver_phone,
      email: data.driver_email,
      desc: data.driver_desc,
      img: data.driver_img_file
    }
    state.drivers = []
    state.drivers = drivers
  },
  MOCK_PASSENGER_ADDITION: (state, data) => {
    const passengers = state.passengers

    passengers.push({
      name: data.passenger_name,
      assign_id: data.passenger_assign_id,
      idn: data.passenger_idn,
      address: data.passenger_address,
      phone: data.passenger_phone,
      email: data.passenger_email,
      desc: data.passenger_desc,
      id: passengers.length
    })
    Vue.$log.debug(passengers)

    state.passengers = []
    state.passengers = passengers
  },
  MOCK_PASSENGER_UPDATE: (state, data) => {
    const passengers = state.passengers

    passengers.forEach((passenger, key) => {
      Vue.$log.debug(passenger.id, data.passenger_id)
      if (passenger.id === data.passenger_id) {
        passengers[key] = {
          name: data.passenger_name,
          assign_id: data.passenger_assign_id,
          idn: data.passenger_idn,
          address: data.passenger_address,
          phone: data.passenger_phone,
          email: data.passenger_email,
          desc: data.passenger_desc,
          id: data.passenger_id
        }
        Vue.$log.debug('updated passenger', passengers[key])
      }
    })

    state.passengers = []
    state.passengers = passengers
  },
  MOCK_DRIVER_DELETION: (state, data) => {
    const id = data.driver_id
    const drivers = state.drivers

    delete drivers[id]

    state.drivers = []
    state.drivers = drivers
  },
  MOCK_PASSENGER_DELETION: (state, data) => {
    const passengers = state.passengers

    let toDelete = ''
    passengers.forEach((passenger, key) => {
      Vue.$log.debug(passenger.id, data.passenger_id)
      if (passenger.id === data.passenger_id) {
        toDelete = key
      }
    })

    delete passengers[toDelete]

    state.passengers = []
    state.passengers = passengers
  }

}
export default mutations
