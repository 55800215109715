
import Vue from 'vue'

const getters = {
  filesPlaceMarkers: (state) => {
    Vue.$log.debug('filesPlaceMarkers', state.filesPlaceMarkers)
    Vue.$log.debug(state.place)
    return state.places
  }
}

export default getters
