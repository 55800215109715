<template>
  <v-container
    fluid
    id="scroll-target"
    style="max-height: calc(100vh - 56px)"
    class="overflow-y-auto"
  >
    <v-row
      class="ma-1 d-flex flex-column align-content-center"
      style="height: auto"
    >
      <v-col cols="12" md="8" xl="6">
        <v-card outlined class="w-100 mb-3">
          <validation-observer ref="observer">
            <v-container fluid>
              <v-row align="center">
                <v-col class="d-flex" cols="12" sm="6">
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    rules="required"
                    :name="$t('OBJECT')"
                  >
                    <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t("OBJECT") }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("OBJECT") }}
                        </template>
                      </FormTooltip>
                    </div>
                    <v-select
                      color="grey"
                      menu-props="dark"
                      item-color="#272727"
                      v-model="fields.object"
                      :items="objectItems"
                      :label="$t('OBJECT')"
                      item-text="text"
                      item-value="value"
                      hide-details="auto"
                      :error-messages="errors"
                      required
                      solo
                      dense
                      outlined
                      @change="setTemplateItems"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    rules="required"
                    :name="$t('TEMPLATE')"
                  >
                    <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t("TEMPLATE") }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("TEMPLATE") }}
                        </template>
                      </FormTooltip>
                    </div>

                    {{ loadingTemplates ? "Loading templates..." : "" }}
                    <v-select
                      color="grey"
                      menu-props="dark"
                      item-color="#272727"
                      @change="setCommand()"
                      v-model="fields.template"
                      :items="templateItems"
                      :label="$t('TEMPLATE')"
                      item-text="text"
                      item-value="value"
                      hide-details="auto"
                      :error-messages="errors"
                      :disabled="loadingTemplates"
                      required
                      solo
                      dense
                      outlined
                    ></v-select>
                  </validation-provider>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6">
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    rules="required"
                    :name="$t('GATEWAY')"
                  >
                    <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t("GATEWAY") }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("GATEWAY") }}
                        </template>
                      </FormTooltip>
                    </div>
                    <v-select
                      color="grey"
                      menu-props="dark"
                      item-color="#272727"
                      :readonly="
                        fields.template && fields.template !== 'custom'
                          ? true
                          : false
                      "
                      v-model="fields.gateway"
                      :items="['gprs', 'sms']"
                      :label="$t('GATEWAY')"
                      item-text="text"
                      item-value="value"
                      hide-details="auto"
                      :error-messages="errors"
                      required
                      solo
                      dense
                      outlined
                    >
                      <template v-slot:item="{ item }">
                        {{ item.toUpperCase() }}
                      </template>
                      <template v-slot:selection="{ item }">
                        {{ item.toUpperCase() }}
                      </template>
                    </v-select>
                  </validation-provider>
                </v-col>
                <!-- <v-col class="d-flex" cols="12" sm="6">
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    :rules="
                      !fields.gateway || fields.gateway === 'gprs'
                        ? 'required'
                        : ''
                    "
                    :name="$t('TYPE')"
                  >
                  <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t("TYPE") }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("TYPE") }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                    <v-select color="grey"
                      menu-props="dark"
                      item-color="#272727"
                      :required="
                        !fields.gateway || fields.gateway === 'gprs'
                          ? true
                          : false
                      "
                      :readonly="
                        fields.template && fields.template !== 'custom'
                          ? true
                          : false
                      "
                      v-model="fields.type"
                      :items="options.type"
                      :label="$t('TYPE')"
                      item-text="text"
                      item-value="value"
                      hide-details="auto"
                      :error-messages="errors"
                      solo
                      dense
                      outlined
                    ></v-select>
                  </validation-provider>
                </v-col> -->

                <v-col class="d-flex" cols="12" sm="6">
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    rules="required"
                    :name="$t('COMMAND')"
                  >
                    <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t("COMMAND") }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("COMMAND") }}
                        </template>
                      </FormTooltip>
                    </div>
                    <v-text-field
                      color="grey"
                      @keydown="setTemplateToCustom()"
                      v-model="fields.command"
                      :label="$t('COMMAND')"
                      hide-details="auto"
                      :error-messages="errors"
                      required
                      solo
                      dense
                      outlined
                    ></v-text-field>
                  </validation-provider>
                </v-col>
              </v-row>
            </v-container>
          </validation-observer>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" xl="6">
        <v-row
          class="
            mb-3
            justify-center
            align-content-center
            flex-column flex-sm-row
            d-flex
          "
          ><v-btn
            @click="save()"
            color="primary"
            min-width="200px"
            max-width="50%"
            >{{ $t("SEND") }}</v-btn
          >
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { commandMixin } from '@/mixins/commandMixin'

export default {
  mixins: [commandMixin]
}
</script>

<style></style>
