<template>
  <div class="tab-content">
    <!-- <div class="desktop" v-if="!$vuetify.breakpoint.mobile"> -->
      <TrackingUpperSection
        v-on:selected-object="getSelectedObject"
        v-if="selectedObject || defaultObjectId"
        :selected-object="selectedObject ? selectedObject : defaultObjectId"
      />
      <TrackingLowerSection
        v-if="selectedObject || defaultObjectId"
        :selected-object="selectedObject ? selectedObject : defaultObjectId"
      />
    <!-- </div> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TrackingUpperSection from '@/components/tracking/TrackingUpperSection.vue'
import TrackingLowerSection from '@/components/tracking/TrackingLowerSection.vue'

export default {
  components: {
    TrackingUpperSection,
    TrackingLowerSection
  },
  data: function () {
    return {
      selectedObject: ''
    }
  },
  computed: {
    ...mapGetters({ getGroupedObjects: 'objects/getGroupedObjects' }),
    defaultObjectId: function () {
      try {
        const groupedObj = this.getGroupedObjects
        if (Object.keys(groupedObj).length >= 1) {
          const key = Object.keys(groupedObj)[0]
          return groupedObj[key][0].object_id
        }
      } catch (error) {
        this.handleError(error)
      }
      return null
    }
  },
  methods: {
    ...mapActions([
      'fetchDrivers',
      'fetchGroupSettings',
      'initializeObjectData',
      'fetchSiteSettings',
      'fetchObjectData',
      'updateMapData'
    ]),
    // accepts the value emitted from TrackingUpperSection.vue
    getSelectedObject: function (id) {
      this.selectedObject = id
    },
    init () {
      // clearInterval(this.$timer.objectLoad);
      // this.$timer.objectLoad = setInterval(
      //   function () {
      //     let ctr = Math.floor(Math.random() * 10) + 1;
      //     this.fetchObjectData({ ctr: ctr });
      //   }.bind(this),
      //   this.config.map_refresh * 1000
      // );
    }
  },
  created () {
    this.$log.debug('Tracking.vue created')
    // this.fetchSiteSettings("load_user_data");
    // this.fetchSiteSettings("load_server_data");
    // this.initializeObjectData();
    // this.fetchDrivers();
    // this.fetchGroupSettings();
    // this.init();
  },
  mounted () {}
}
</script>

<style></style>
