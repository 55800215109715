
const getters = {
  // places: ( state) => state.places,
  // placeMarkerList: (state) => state.place_marker_list,
  // placeGroupList: (state) => state.place_group_list,
  // placeRouteList: (state) => state.place_route_list,
  // placeZoneList: (state) => state.place_zone_list,
  // placeLayers: (state) => state.place_layers,
  placeMarkers: (state) => state.place_markers,
  placeGroups: (state) => state.place_groups,
  placeRoutes: (state) => state.place_routes,
  placeZones: (state) => state.place_zones,
  placesData: (state) => state.places_data,
  placeDraft: (state) => state.place.draft,
  placeGroupItems: state => {
    let data = []
    Object.keys(state.place_groups).forEach((key) => {
      data.push({
        value: key,
        text: state.place_groups[key].name
      })
    })
    data = data.sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
    return data
  },
  placeZonesItems: (state) => {
    let data = []
    Object.keys(state.place_zones).forEach((key) => {
      const zone = state.place_zones[key]
      data.push({
        text: zone.data.name ? zone.data.name : 'Zone ID: ' + key,
        value: key
      })
    })
    data = data.sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
    return data
  },
  placeRoutesItems: (state) => {
    let data = []
    Object.keys(state.place_routes).forEach((key) => {
      const route = state.place_routes[key]
      data.push({
        text: route.data.name ? route.data.name : 'Route ID: ' + key,
        value: key
      })
    })
    data = data.sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))
    return data
  }
}

export default getters
