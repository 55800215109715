<template>
  <v-dialog
    persistent
    v-model="show"
    content-class="dialog-settings"
    hide-overlay
    no-click-animation
    fullscreen
  >
    <v-card>
      <v-toolbar color="" dense elevation="2">
        <v-toolbar-title class="text-subtitle-2">{{
          $t("OBJECT_CONTROL")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn plain icon x-small @click="closeDialog">
          <v-icon dense>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container fluid class="fill-height pa-0" style="height: calc(100vh - 48px);
    align-items: flex-start;
   ">
        <v-tabs class="fill-height ">
          <v-tab>{{ $t("GPRS") }}</v-tab>
          <v-tab>{{ $t("SMS") }}</v-tab>
          <v-tab>{{ $t("SCHEDULE") }}</v-tab>
          <v-tab>{{ $t("TEMPLATES") }}</v-tab>

          <v-tab-item eager> <GPRSTab :id="id" /> </v-tab-item>
          <v-tab-item eager> <SMSTab :id="id" /> </v-tab-item>
          <v-tab-item eager> <ScheduleTab :imei="id"/> </v-tab-item>
          <v-tab-item eager> <TemplatesTab /> </v-tab-item>
        </v-tabs>
      </v-container>
      <!-- <v-card-actions class="justify-center"> </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import GPRSTab from '@/components/control/GPRSTab.vue'
import SMSTab from '@/components/control/SMSTab.vue'
import ScheduleTab from '@/components/control/ScheduleTab.vue'
import TemplatesTab from '@/components/control/TemplatesTab.vue'

export default {
  components: { GPRSTab, SMSTab, ScheduleTab, TemplatesTab },
  props: {
    show: Boolean,
    id: {
      default: null
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters({})
  },
  created () {
  },
  methods: {
    ...mapActions({
    }),
    closeDialog () {
      this.$emit('show-object-control-dialog', false)
    }
  },
  watch: {
    '$vuetify.breakpoint.mobile': function (newVal, oldVal) {

    }
  }
}
</script>

<style></style>
