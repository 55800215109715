import { authService } from '@/services/auth'

const actions = {
  async checkSession ({ commit }) {
    if (process.env.NODE_ENV === 'development') {
      return { session: 'true' }
      // return { error: 'assert(count($_SESSION) > 1)' }
    }
    return await authService
      .checkSession()
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },

  async authenticate ({ commit }, params) {
    if (process.env.NODE_ENV === 'development') {
      return 'LOGIN_TRACKING'
    }
    return await authService
      .authenticate(params)
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },

  async verifyUser ({ commit }, params) {
    if (process.env.NODE_ENV === 'development') {
      return 'LOGIN_TRACKING'
    }
    return await authService
      .verifyUser(params)
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },

  async verifyUserCode ({ commit }, params) {
    if (process.env.NODE_ENV === 'development') {
      return 'LOGIN_TRACKING'
    }
    return await authService
      .verifyUserCode(params)
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },
  async fetchCsrfToken ({ commit }) {
    if (process.env.NODE_ENV === 'development') {
      return 'csrf token'
    }
    return await authService
      .fetchCsrfToken()
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },

  async registerUser ({ commit }, params) {
    if (process.env.NODE_ENV === 'development') {
      return 'ERROR_EMAIL_EXISTS'
    }
    return await authService
      .register(params)
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },

  async recoverUrl ({ commit }, params) {
    if (process.env.NODE_ENV === 'development') {
      return 'ERROR_EMAIL_NOT_FOUND'
    }
    return await authService
      .recoverUrl(params)
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },

  async recoverToken ({ commit }, params) {
    if (process.env.NODE_ENV === 'development') {
      return 'true'
    }
    return await authService
      .recoverToken(params)
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },

  async recoverAccount ({ commit }, params) {
    if (process.env.NODE_ENV === 'development') {
      return 'ERROR_RECOVER_EXPIRED'
    }
    return await authService
      .recover(params)
      .then(function (response) {
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  }

}

export default actions
