
const getters = {
  trailers: (state) => state.trailers,
  trailerItems: state => {
    let data = []
    for (const [id] of Object.entries(state.trailers)) {
      const trailer = state.trailers[id]
      data.push({
        text: trailer.name,
        value: id
      })
    }

    data = data.sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))

    return data
  }
}
export default getters

// trailerSettings
// fetchTrailerSettings
