import { filesService } from '@/services/files'
import Vue from 'vue'

const actions = {
  async initializeFilesData ({ commit }) {
    const placeMarkers = filesService.getFilesData({ path: 'img/markers/places' })
    const placeCustomMarkers = filesService.getFilesData({ path: 'data/user/places' })

    return await Promise.all([placeMarkers, placeCustomMarkers])
      .then(([markers, customMarkers]) => {
        Vue.$log.debug('initializeFilesData', 'success')
        Vue.$log.debug('initializeFilesData', markers, customMarkers)
        commit('SET_FILES_PLACE_MARKER', markers.data)
        commit('SET_FILES_PLACE_ICONS_CUSTOM', customMarkers.data)
        return true
      })
      .catch(error => {
        throw error
      })
  },

  async fetchFilesData ({ commit }, params) {
    return await filesService.getFilesData(params)
      .then(function (response) {
        Vue.$log.debug('fetchFilesData', 'success')
        switch (params.path) {
          case 'img/markers/places':
            commit('SET_FILES_PLACE_MARKER', response.data)
            break
          case 'data/user/places':
            commit('SET_FILES_PLACE_ICONS_CUSTOM', response.data)
            break

          default:
            break
        }
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  }
}
export default actions
