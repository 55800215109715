<template>
  <v-data-table
    :headers="headers"
    :items="tableData"
    item-key="id"
    class="tracking-table"
    group-by="group"
    :item-class="selectedRow"
    fixed-header
    :items-per-page="-1"
    hide-default-footer
    :search="search"
    height="100%"
    :expanded.sync="expanded"
    show-expand
  >
  <template v-slot:top>
      <v-toolbar>
        <v-row class="align-center">
          <v-col>
            <v-btn
              class="border-1--yellow"
              small
              @click="$emit('show-form', true)"
            >
              <v-icon left> mdi-message </v-icon>
              Send Message
            </v-btn>
          </v-col>
          <v-col
            cols="8"
            md="7"
            class="d-flex justify-content-end"
          >
            <v-text-field
              solo
              dense
              single-line
              hide-details
              clearable
              outlined
              prepend-inner-icon="mdi-magnify"
              v-model="search"
              label="Search"
              color="grey"
              class=""
            ></v-text-field
          ></v-col>
        </v-row>
      </v-toolbar>
    </template>
    <template v-slot:header="">
      <thead>
        <tr>
          <th colspan="2" class="text-align-center">{{ $t("MAP") }}</th>
          <th colspan="3" class="text-align-center">Tracking Pair</th>
          <th colspan="4" class="text-align-center hidden-md-and-down">
            Metrics
          </th>
        </tr>
      </thead>
    </template>

    <template v-slot:group.header="{ items, isOpen, toggle }">
      <th colspan="9" class="group-header" @click="toggle">
        {{ getGroupName(items[0].group) }}
      </th>
    </template>

    <template v-slot:header.follow="{}">
      {{ $t("FOLLOW") }}
    </template>

    <template v-slot:item.locate="{ item }">
      <v-tooltip bottom color="tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dense
            plain
            @click="locateAnObject(item.id, item.locate)"
            v-bind="attrs"
            v-on="on"
            x-small icon
          >
            <v-img

              max-width="18"
              :src="
                publicPath + 'assets/img/icons/' +
                (item.locate === true ? 'locate-green.svg' : 'locate.svg')
              "
              class="mr-1"
            ></v-img>
          </v-btn>

        </template>
        <span>Click here to locate object</span>
      </v-tooltip>

    </template>

    <template v-slot:item.follow="{ item }">
      <v-checkbox
        dense
        v-model="item.follow"
        @change="followCheckboxChanged(item.id, item.follow)"
      ></v-checkbox>
    </template>

    <template v-slot:item.device_name="{ item }">
      <v-tooltip bottom color="tooltip">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="cursor-pointer" @click="handleRowClick(item);subscribe(item.id)">
            {{ item.device_name }}
          </div>
        </template>
        <span>Click here to locate object</span>
      </v-tooltip>

    </template>

    <template v-slot:item.driver_name="{ item }">
      <v-tooltip bottom color="tooltip">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="" @click="handleRowClick(item)">
            {{ item.driver_name }}
          </div>
        </template>
        <span>Click here to locate object</span>
      </v-tooltip>
    </template>

    <template v-slot:item.ign="{ item }">
      <v-tooltip bottom color="tooltip">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on"  @click="handleRowClick(item)">
            <v-img
              max-width="18"
              :src="
                publicPath + 'assets/img/icons/' +
                (item.ign === 'On'
                  ? 'ignition-indicator-red.svg'
                  : 'ignition-indicator.svg')
              "
              class="mr-1"
            ></v-img>
          </div>
        </template>
        <span>Click here to locate object</span>
      </v-tooltip>

    </template>

    <template v-slot:item.link="{ item }">
       <v-tooltip bottom color="tooltip">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on"  @click="handleRowClick(item)">

      <TheLinkBar :signal="item.link" />
          </div>
        </template>
        <span>Click here to locate object</span>
      </v-tooltip>
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <td colspan="8">

        <div class="d-flex flex-row py-2">
          <div class=" d-flex align-center mr-3">
            <div class="">
              <v-img
                max-width="50"
                :src="publicPath + 'assets/' + getObjectSettingsById(item.id)['icon']"
              ></v-img>
            </div>
          </div>
          <div class="">
            <table class>
          <tr>
            <th align="left">Sat:</th>
            <td>{{ item.sat}}</td>
          </tr>
          <tr>
            <th align="left">Trip Hrs:</th>
            <td>{{ item.trip_hrs}}</td>
          </tr>
        </table>
          </div>
        </div>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TheLinkBar from '@/components/includes/TheLinkBar.vue'
import { mapMixin } from '@/mixins/mapMixin'

export default {
  mixins: [mapMixin],
  components: {
    TheLinkBar

  },
  props: {},
  data: () => ({
    expanded: [],
    search: '',
    selectedRowId: '',
    singleSelect: false,
    selected: [],
    headers: [
      { text: 'Locate', value: 'locate', sortable: false },
      {
        text: 'Follow',
        value: 'follow',
        sortable: false
        // class: "hidden-md-and-down",
        // cellClass: "hidden-md-and-down",
      },
      { text: 'Device', value: 'device_name' },
      {
        text: 'Driver Name',
        value: 'driver_name',
        class: 'hidden-lg-and-down',
        cellClass: 'hidden-lg-and-down'
      },
      // {
      //   text: "Group",
      //   value: "group",
      //   class: "hidden-md-and-down",
      //   cellClass: "hidden-md-and-down",
      // },
      {
        text: 'Ign',
        value: 'ign',
        class: 'hidden-sm-and-down',
        cellClass: 'hidden-sm-and-down',
        sortable: false
      },
      {
        text: 'Link',
        value: 'link',
        sortable: false,
        class: 'hidden-sm-and-down',
        cellClass: 'hidden-sm-and-down'
      },
      {
        text: 'Sat',
        value: 'sat',
        class: 'hidden-lg-and-down',
        cellClass: 'hidden-lg-and-down'
      },
      {
        text: 'Trip Hrs',
        value: 'trip_hrs',
        sortable: false,
        class: 'hidden-lg-and-down',
        cellClass: 'hidden-lg-and-down'
      },
      {
        text: '',
        value: 'data-table-expand',
        sortable: false,
        class: 'hidden-xl-only',
        cellClass: 'hidden-xl-only'
      }
    ]
  }),
  computed: {
    ...mapGetters({
      getDriverName: 'person/getDriverName',
      getGroupName: 'groups/getGroupName',
      getObjectById: 'objects/getObjectById',
      getObjectSettingsById: 'objects/getObjectSettingsById'
    })
  },
  created () {},
  methods: {
    ...mapActions({
      forceObjectSubscription: 'objects/forceObjectSubscription'
    }),
    // handleRowClick: function (item) {
    //   // this.selectedRowId = item.id;
    //   this.$emit("selected-object", item.id); //emits value to TrackingUpperSection.vue method getSelectedObject
    //   this.centerObject(item.id);
    // },
    selectedRow (item) {
      return this.selectedRowId && item.id === this.selectedRowId
        ? 'active-row'
        : !this.selectedRowId && item.id === this.selectedObject
          ? 'active-row'
          : ''
    },
    async subscribe (imei) {
      this.$log.debug('subscribe', imei)
      if (process.env.NODE_ENV === 'production') {
        await this.forceObjectSubscription({
          cmd: 'force_subscription',
          imei: imei
        })
      }
    }

  }
}
</script>

<style></style>
