import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  places: {
    markers: [],
    custom_markers: []
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
