import { groupService } from '@/services/groups'
import Vue from 'vue'

const actions = {
  async fetchGroupSettings ({ commit }) {
    // return await groupService.getGroupSettings()
    //   .then(function (response) {
    //     Vue.$log.debug('getGroupSettings', 'success')
    //     commit('SET_GROUPS', response.data)
    //     return response.data
    //   })
    //   .catch(function (error) {
    //     Vue.$log.debug('getGroupSettings' + error)
    //     throw error
    //   })
  },

  async saveGroup ({ commit }, params) {
    if (process.env.NODE_ENV === 'production') {
      return await groupService.saveGroup(params)
        .then(function (response) {
          Vue.$log.debug('saveGroup', 'success', response)
          return response.data
        })
        .catch(function (error) {
          Vue.$log.debug('saveGroup' + error)
          throw error
        })
    } else {
      if (params.cmd === 'save_object_group') {
        if (params.group_id === false) {
          commit('MOCK_GROUP_ADDITION', params)
        } else {
          commit('MOCK_GROUP_UPDATE', params)
        }
        return { message: 'OK' }
      }
      return ''
    }
  },

  async removeGroup ({ commit }, params) {
    if (process.env.NODE_ENV === 'production') {
      return await groupService.removeGroup(params)
        .then(function (response) {
          Vue.$log.debug('removeGroup', 'success', response)
          return response.data
        })
        .catch(function (error) {
          Vue.$log.debug('removeGroup' + error)
          throw error
        })
    } else {
      if (params.cmd === 'delete_object_group') {
        commit('MOCK_GROUP_DELETION', params)
      }
      return { message: 'OK' }
    }
  },
  async importGroups ({ commit }, params) {
    if (process.env.NODE_ENV === 'production') {
      return await groupService.importGroups(params)
        .then(function (response) {
          Vue.$log.debug('importGroups', 'success', response)
          return response.data
        })
        .catch(function (error) {
          Vue.$log.debug('importGroups' + error)
          throw error
        })
    } else {
      return { message: 'OK' }
    }
  }

}

export default actions
