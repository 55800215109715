<template>
  <v-tabs
    class="quaternary-tabs lower-tabs"
    slider-color="#FF5100"
    background-color="#3c3c3c"
    show-arrows
    height="30px" dark
  >
    <v-tab dark>
      <v-img
        max-width="13"
        :src="`${publicPath}assets/img/icons/tabs/dashboard.svg`"
        class="mr-1"
      ></v-img>
      Dashboard
    </v-tab>
    <v-tab>
      <v-img
        max-width="12"
        max-height="17"
        :src="`${publicPath}assets/img/icons/tabs/street-view.svg`"
        class="mr-1"
      ></v-img>
      {{ $t("STREET_VIEW") }}
    </v-tab>
    <v-tab>
      <v-img
        max-width="17"
        :src="`${publicPath}assets/img/icons/tabs/video.svg`"
        class="mr-1"
      ></v-img>
      Video
    </v-tab>

    <slot name="lower-tab-items"></slot>
  </v-tabs>
</template>

<script>
export default {}
</script>

<style></style>
