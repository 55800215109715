var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.mapTypes.openstreetmap, function(tileProvider, key) {
        return _c("l-tile-layer", {
          key: key,
          attrs: {
            visible: key === _vm.selectedMap.value ? true : false,
            url: tileProvider.url,
            attribution: tileProvider.attribution
          }
        })
      }),
      _vm._v(" "),
      _vm._l(_vm.mapTypes.google, function(tileProvider, key) {
        return _c("l-tile-layer", {
          key: key,
          attrs: {
            visible: key === _vm.selectedMap.value ? true : false,
            url: tileProvider.url,
            subdomains: tileProvider.subdomains
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }