<template>
  <v-dialog
    persistent
    v-model="show"
    hide-overlay
    no-click-animation
    max-width="370"
    width="auto"
  >
    <v-card outlined>
      <v-toolbar color="" dense elevation="0">
        <v-toolbar-title class="text-subtitle-2">{{
          $t("ZONE_PROPERTIES")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn plain icon x-small @click="closeDialog">
          <v-icon dense>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container fluid>
        <validation-observer ref="observer">
          <v-row>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                rules="requiredPlaceName"
              >
                <div class="mb-2 d-flex flex-row">
                  <span class="text-body-2 mr-1">{{ $t("NAME") }}</span>

                  <FormTooltip>
                    <template v-slot:tooltip-trigger>
                      <v-btn x-small color="primary lighten-1" text icon>
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:tooltip-content
                      >{{ $t("NAME") }}
                    </template>
                  </FormTooltip>
                </div>
                <v-text-field
                  v-model="fields.zone_name"
                  :error-messages="errors"
                  :label="$t('NAME')"
                  color="grey"
                  hide-details="auto"
                  solo
                  dense
                  outlined
                  required
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
              ><validation-provider v-slot="{ errors }">
                <div class="mb-2 d-flex flex-row">
                  <span class="text-body-2 mr-1">{{ $t("GROUP") }}</span>

                  <FormTooltip>
                    <template v-slot:tooltip-trigger>
                      <v-btn x-small color="primary lighten-1" text icon>
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:tooltip-content
                      >{{ $t("GROUP") }}
                    </template>
                  </FormTooltip>
                </div>
                <v-autocomplete
                  menu-props="dark"
                  item-color="#272727"
                  required
                  v-model="fields.group_id"
                  :label="$t('GROUP')"
                  :items="placeGroupItems"
                  item-text="text"
                  item-value="value"
                  solo
                  color="grey"
                  dense
                  hide-details="auto"
                  outlined
                  :error-messages="errors"
                ></v-autocomplete>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="mb-2 d-flex flex-row">
                <span class="text-body-2 mr-1">Zone Color</span>

                <FormTooltip>
                  <template v-slot:tooltip-trigger>
                    <v-btn x-small color="primary lighten-1" text icon>
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:tooltip-content>Zone Color </template>
                </FormTooltip>
              </div>
              <div class="d-flex">
                <v-menu class="">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      class="mr-2"
                      v-model="fields.zone_color"
                      label="Zone Color"
                      solo
                      color="grey"
                      dense
                      hide-details="auto"
                      outlined
                      required
                      append-icon="mdi-eyedropper-variant"
                    ></v-text-field>
                  </template>
                  <div>
                    <div class="color-picker-container">
                      <v-color-picker
                        dark
                        v-model="fields.zone_color"
                        dot-size="15"
                        swatches-max-height="250"
                        hide-inputs
                        hide-mode-switch
                      ></v-color-picker>
                    </div>
                  </div>
                </v-menu>

                <v-sheet
                  width="40"
                  height="40"
                  light
                  class="pa-2"
                  :color="fields.zone_color"
                >
                  <pre>&nbsp;</pre>
                </v-sheet>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
              ><v-checkbox
                color="primary"
                hide-details="auto"
                v-model="fields.zone_visible"
                :label="$t('ZONE_VISIBLE')"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
              ><v-checkbox
                color="primary"
                hide-details="auto"
                v-model="fields.zone_name_visible"
                :label="$t('NAME_VISIBLE')"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
              ><validation-provider v-slot="{ errors }">
                <div class="mb-2 d-flex flex-row">
                  <span class="text-body-2 mr-1">{{ $t("MEASURE_AREA") }}</span>

                  <FormTooltip>
                    <template v-slot:tooltip-trigger>
                      <v-btn x-small color="primary lighten-1" text icon>
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:tooltip-content
                      >{{ $t("MEASURE_AREA") }}
                    </template>
                  </FormTooltip>
                </div>
                <v-select
                  menu-props="dark"
                  item-color="#272727"
                  required
                  v-model="fields.zone_area"
                  :label="$t('MEASURE_AREA')"
                  :items="measureArea"
                  item-text="text"
                  item-value="value"
                  solo
                  color="grey"
                  dense
                  hide-details="auto"
                  outlined
                  :error-messages="errors"
                ></v-select>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
              ><validation-provider
                v-slot="{ validate, errors }"
                rules="requiredPlacePoints|minPoints:3|maxPoints:40"
                ref="zoneVertices"
                name="zoneVertices"
              >
                <div class="mb-2 d-flex flex-row">
                  <span class="text-body-2 mr-1">Zone Vertices</span>

                  <FormTooltip>
                    <template v-slot:tooltip-trigger>
                      <v-btn x-small color="primary lighten-1" text icon>
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:tooltip-content>Zone Vertices </template>
                  </FormTooltip>
                </div>
                <v-textarea
                  @change="validateZoneVertices"
                  :value="
                    placeDraft.fields.hasOwnProperty('zone_vertices')
                      ? placeDraft.fields.zone_vertices
                      : ''
                  "
                  :error-messages="errors"
                  label="Zone Vertices"
                  color="grey"
                  hide-details="auto"
                  solo
                  dense
                  outlined
                  required
                  readonly
                  rows="3"
                  auto-grow
                ></v-textarea>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row class="mb-3 justify-center flex-column flex-sm-row d-flex">
            <v-btn
              @click="closeDialog"
              color="primary"
              outlined
              class="mr-sm-2 mb-2"
              max-width="50%"
              >Cancel</v-btn
            >
            <v-btn @click="save" color="primary" max-width="50%">Save</v-btn>
          </v-row>
        </validation-observer>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import FormTooltip from '@/components/includes/FormTooltip.vue'
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormTooltip
  },
  props: {
    show: Boolean
  },
  data () {
    return {
      fields: {
        zone_name: '',
        group_id: '0',
        zone_color: '#FF0000',
        zone_visible: true,
        zone_name_visible: true,
        zone_area: 0,
        zone_vertices: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      message: 'messages/message',
      placeGroupItems: 'places/placeGroupItems',
      placeDraft: 'places/placeDraft'
    }),
    measureArea: function () {
      return [
        { value: 0, text: this.$t('OFF') },
        { value: 1, text: this.$t('ACRES') },
        { value: 2, text: this.$t('HECTARES') },
        { value: 3, text: this.$t('SQ_M') },
        { value: 4, text: this.$t('SQ_KM') },
        { value: 5, text: this.$t('SQ_FT') },
        { value: 6, text: this.$t('SQ_MI') }
      ]
    }
  },
  watch: {
    show: function () {
      try {
        this.$log.debug('ZONES', this.show, this.placeDraft.action)
        if (this.show) {
          if (this.placeDraft.action === 'edit') {
            this.$log.debug('edit')
            this.fields = {
              group_id: this.placeDraft.fields.group_id,
              zone_name: this.placeDraft.fields.zone_name,
              zone_color: this.placeDraft.fields.zone_color,
              zone_visible: this.placeDraft.fields.zone_visible,
              zone_name_visible: this.placeDraft.fields.zone_name_visible,
              zone_area: parseInt(this.placeDraft.fields.zone_area),
              zone_vertices: this.placeDraft.fields.zone_vertices
            }
          }
        } else {
          this.fields = {
            zone_name: '',
            group_id: '0',
            zone_color: '#FF0000',
            zone_visible: true,
            zone_name_visible: true,
            zone_area: 0,
            zone_vertices: ''
          }
        }
      } catch (error) {
        this.handleError(error)
      }
    }
  },
  methods: {
    ...mapActions({
      updateMapPlacesDraft: 'places/updateMapPlacesDraft',
      clearMapPlacesDraft: 'places/clearMapPlacesDraft',
      savePlaceObject: 'places/savePlaceObject',
      fetchPlacesData: 'places/fetchPlacesData'
      // mockPlaceObjectAddition: 'places/mockPlaceObjectAddition',
      // mockPlaceObjectUpdate: 'places/mockPlaceObjectUpdate'
    }),
    async save () {
      try {
        const isValidPoints = await this.$refs.zoneVertices.validate()
        this.$log.debug('isValidPoints', isValidPoints)
        if (!isValidPoints.valid) {
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'error',
            title: this.$t('ERROR'),
            text: isValidPoints.errors[0]
          })
        }
        const valid = await this.$refs.observer.validate()
        if (valid && isValidPoints.valid) {
          this.fields.zone_vertices = this.placeDraft.fields.zone_vertices
          this.updateMapPlacesDraft({ fields: this.fields })
          const response = await this.savePlaceObject(this.placeDraft)
          this.$log.debug(response)
          if (response.message === 'OK') {
            if (process.env.NODE_ENV === 'production') {
              this.fetchPlacesData({ cmd: 'load_zone_data' })
              this.$notify({
                    duration: 5000,
                    closeOnClick: false,
                type: 'success',
                title: this.$t('INFORMATION'),
                text: this.$t('CHANGES_SAVED_SUCCESSFULLY')
              })
            }
            this.closeDialog()
          } else if (response.message === 'ERROR_ZONE_LIMIT') {
            this.$notify({
                    duration: 5000,
                    closeOnClick: false,
              type: 'error',
              title: this.$t('ERROR'),
              text: this.$t('ZONE_LIMIT_IS_REACHED')
            })
          }
        }
      } catch (error) {
        this.handleError()
      }
    },
    async validateZoneVertices (e) {
      await this.$refs.zoneVertices.validate()
    },
    closeDialog () {
      try {
        this.clearMapPlacesDraft()
        this.$refs.observer.reset()
        this.$emit('show-place-dialog', false)
      } catch (error) {
        this.handleError()
      }
    }
  }
}
</script>

<style></style>
