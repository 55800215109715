const getters = {
  langSettings: (state) => state.lang,
  server: (state) => state.server,
  user: (state) => state.user,
  customFieldSettings: (state) => state.custom_field,
  eventSettings: (state) => state.events,
  eventListSettings: (state) => state.event_list,
  sensorListSettings: (state) => state.sensor_list,
  serviceListSettings: (state) => state.service_list,
  subaccountSettings: (state) => state.subaccounts,
  subaccountListSettings: (state) => state.subaccount_list,
  templateSettings: (state) => state.templates,
  templateListSettings: (state) => state.template_list,
  searchAddressState: (state) => state.search_address_state,
  uiSettings: (state) => state.ui_settings,
  checkPrivilege: state => type => {
    switch (type) {
      case 'viewer':
        if ((state.user.privileges === '' || state.user.privileges === 'viewer') && !state.user.cpanel_privileges) { return false }
        break
      case 'subuser':
        if (state.user.privileges === 'subuser') { return false }
        break
      case 'obj_add':
        if (state.user.manager_id !== 0 || state.user.obj_add === 'false') { return false }
        break
      case 'obj_history_clear':
        if (state.user.obj_history_clear !== 'true') { return false }
        break
      case 'obj_edit':
        if (state.user.obj_edit !== 'true') { return false }
        break
      case 'history':
        if (!state.user.privileges_history) { return false }
        break
      case 'reports':
        if (!state.user.privileges_reports) { return false }
        break
      case 'tasks':
        if (!state.user.privileges_tasks) { return false }
        break
      case 'rilogbook':
        if (!state.user.privileges_rilogbook) { return false }
        break
      case 'dtc':
        if (!state.user.privileges_dtc) { return false }
        break
      case 'object_control':
        if (!state.user.privileges_object_control) { return false }
        break
      case 'image_gallery':
        if (!state.user.privileges_image_gallery) { return false }
        break
      case 'chat':
        if (!state.user.privileges_chat) { return false }
        break
      case 'subaccounts':
        if (!state.user.privileges_subaccounts) { return false }
    }
    return true
  },
  mapList: state => {
    const mapList = []

    if (typeof state.server.map_osm !== 'undefined' && JSON.parse(state.server.map_osm)) {
      mapList.push({ value: 'osm', name: 'OSM Map' })
    }
    if (typeof state.server.map_bing !== 'undefined' && JSON.parse(state.server.map_bing)) {
      mapList.push({ value: 'broad', name: 'Bing Road' })
      mapList.push({ value: 'baer', name: 'Bing Aerial' })
      mapList.push({ value: 'bhyb', name: 'Bing Hybrid' })
    }
    if (typeof state.server.map_google !== 'undefined' && JSON.parse(state.server.map_google)) {
      mapList.push({ value: 'gmap', name: 'Google Streets' })
      mapList.push({ value: 'gsat', name: 'Google Satellite' })
      mapList.push({ value: 'ghyb', name: 'Google Hybrid' })
      mapList.push({ value: 'gter', name: 'Google Terrain' })
    }
    if (typeof state.server.map_mapbox !== 'undefined' && JSON.parse(state.server.map_mapbox)) {
      mapList.push({ value: 'mbmap', name: 'Mapbox Streets' })
      mapList.push({ value: 'mbsat', name: 'Mapbox Satellite' })
    }
    if (typeof state.server.map_yandex !== 'undefined' && JSON.parse(state.server.map_yandex)) {
      mapList.push({ value: 'yandex', name: 'Yandex' })
    }

    if (typeof state.server.map_custom !== 'undefined') {
      for (let i = 0; i < state.server.map_custom.length; i++) {
        const layerId = state.server.map_custom[i].layer_id
        const name = state.server.map_custom[i].name

        // select.options.add(new Option(name, layerId))
        mapList.push({ value: layerId, name: name })
      }
    }
    return mapList
  },
  mapSettings: (state, getters, rootState, rootGetters) => {
    // Vue.$log.debug(state.mapList)
    // const mapList =
    const mapList = rootGetters['settings/mapList']
    const user = rootGetters['settings/user']
    // Vue.$log.debug(user)
    // mapList.forEach(type => {
    //   Vue.$log.debug(type)
    // });
    let map = mapList.filter((type) => {
      return type.value === user.map_layer
    })
    map = map.shift()
    return map

    // return "ass";
    // const object = Object.values(state.mapList).filter(function (object) {
    //   return object.name === name
    // })
    // return object
  }
}
export default getters

// trailerSettings
// fetchTrailerSettings
