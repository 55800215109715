var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { staticClass: "d-flex", attrs: { cols: "12", md: "6" } },
        [
          _c(
            "v-sheet",
            {
              directives: [
                {
                  name: "resize",
                  rawName: "v-resize",
                  value: _vm.onResize,
                  expression: "onResize"
                }
              ],
              staticClass: "pa-1 pt-3 w-100",
              staticStyle: {
                overflow: "auto",
                "background-color": "transparent"
              },
              attrs: { dark: "", height: _vm.contentHeight }
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-2 float-right",
                          attrs: { dark: "", color: "#ffa700" },
                          on: {
                            click: function($event) {
                              _vm.showFilter = true
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { dark: "", left: "" } }, [
                            _vm._v("mdi-format-list-bulleted-square")
                          ]),
                          _vm._v("\n            Filters\n          ")
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-2 float-right",
                          attrs: { dark: "", color: "#ffa700" },
                          on: {
                            click: function($event) {
                              _vm.showRequestFootage = true
                            }
                          }
                        },
                        [
                          _c("v-icon", { attrs: { dark: "", left: "" } }, [
                            _vm._v("mdi-video-plus")
                          ]),
                          _vm._v("\n            Request Footage\n          ")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.filteredEventVideos, function(event, index) {
                    return _c(
                      "v-col",
                      {
                        key: index,
                        staticClass: "d-flex child-flex",
                        attrs: { cols: "12", lg: "6" }
                      },
                      [
                        _c(
                          "v-card",
                          {
                            attrs: { color: "black", raised: "", outlined: "" }
                          },
                          [
                            _c(
                              "v-card-text",
                              [
                                _c("video-player", {
                                  ref: "videoPlayer-" + index,
                                  refInFor: true,
                                  staticClass: "vjs-custom-skin",
                                  attrs: {
                                    id: "videoPlayer-" + index,
                                    options: _vm.videoOptions(event)
                                  },
                                  on: {
                                    play: function($event) {
                                      return _vm.onPlayerPlay($event)
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "m-2",
                                    attrs: {
                                      small: "",
                                      color: "#ffa700",
                                      plain: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        event["name"] = event.obj_name
                                        event["event_desc"] = ""
                                        _vm.viewOnMap(event)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                View on Map\n                "
                                    ),
                                    _c("v-icon", { attrs: { right: "" } }, [
                                      _vm._v("mdi-map-marker")
                                    ])
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-btn",
                                  {
                                    staticClass: "m-2",
                                    attrs: {
                                      small: "",
                                      color: "#4bf542",
                                      plain: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.selectedVideo(index)
                                      }
                                    }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "\n                  Locate\n                "
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c("v-img", {
                                      staticClass: "ml-2",
                                      attrs: {
                                        "max-width": "18",
                                        src:
                                          _vm.publicPath +
                                          "assets/img/icons/locate-green.svg"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { staticClass: "d-none d-md-flex", attrs: { md: "6" } },
        [
          _c(
            "v-sheet",
            {
              directives: [
                {
                  name: "resize",
                  rawName: "v-resize",
                  value: _vm.onResize,
                  expression: "onResize"
                }
              ],
              staticClass: "w-100",
              staticStyle: { overflow: "auto" },
              attrs: { dark: "", height: _vm.contentHeight }
            },
            [
              _c(
                "l-map",
                {
                  ref: "map",
                  staticStyle: { height: "100%" },
                  attrs: {
                    options: _vm.mapOptions,
                    maxZoom: _vm.config.map_max_zoom,
                    minZoom: _vm.config.map_min_zoom,
                    center: _vm.defaultCenter,
                    zoom: _vm.defaultZoom
                  },
                  on: { ready: _vm.ready }
                },
                [_c("TileLayers")],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("EventsVideoFilter", {
        attrs: { show: _vm.showFilter },
        on: { "show-filter-dialog": _vm.setShowFilterModal }
      }),
      _vm._v(" "),
      _c("EventsVideoRequestFootage", {
        attrs: { show: _vm.showRequestFootage },
        on: { "show-request-dialog": _vm.setShowRequestFootage }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }