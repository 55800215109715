import { triggersService } from '@/services/triggers'
import Vue from 'vue'

const actions = {
  async getTriggers ({ commit }) {
    return await triggersService
      .getTriggers()
      .then(function (response) {
        Vue.$log.debug('getTriggers', response.data)
        commit('SET_TRIGGERS', response.data)
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },

  async saveTrigger ({ commit, rootState, rootGetters }, params) {
    // for overspeed default values
    // event_id: false
    // type: overspeed
    // name: Test
    // imei: 123
    // checked_value: 60
    // zones:
    // notify_system: false, false, false,.

    // for ignition on
    // event_id: false
    // type: sensor
    // name: Test Ignition
    // imei: 864431044831123
    // checked_value: [{ "src": "Ignition", "cn": "eq", "val": "1" }]
    // zones:
    // notify_system: false, false, false, alarm1.mp3

    // for geofence
    // event_id: false
    // type: zone_out or zone_in
    // name: Test Geofence
    // imei: 864431044831123
    // checked_value:
    // zones: 1294
    // notify_system: false, false, false, alarm1.mp3

    if (typeof params.checked_value === 'object') {
      params.checked_value = JSON.stringify(params.checked_value)
    }
    const defaultParams = {
      cmd: 'save_event',
      active: true, // to string
      duration_from_last_event: false, // to string
      duration_from_last_event_minutes: 0,
      week_days: 'true,true,true,-,true,true,true',
      day_time: JSON.stringify({ dt: false, mon: false, mon_from: '00:00', mon_to: '24:00', tue: false, tue_from: '00:00', tue_to: '24:00', wed: false, wed_from: '00:00', wed_to: '24:00', thu: false, thu_from: '00:00', thu_to: '24:00', fri: false, fri_from: '00:00', fri_to: '24:00', sat: false, sat_from: '00:00', sat_to: '24:00', sun: false, sun_from: '00:00', sun_to: '24:00' }),
      route_trigger: 'off',
      zone_trigger: 'off',
      routes: '',
      notify_email: false, // to string
      notify_email_address: '',
      notify_sms: false, // to string
      notify_sms_number: '',
      email_template_id: 0,
      sms_template_id: 0,
      notify_arrow: false, // to string
      notify_arrow_color: 'arrow_yellow',
      notify_ohc: false, // to string
      notify_ohc_color: '#FFFF00',
      cmd_send: false, // to string
      cmd_gateway: 'gprs',
      cmd_type: 'ascii',
      cmd_string: ''
    }

    const combinedParams = {
      ...defaultParams,
      ...params
    }

    Vue.$log.debug(combinedParams.event_id)
    Vue.$log.debug(combinedParams)

    if (process.env.NODE_ENV === 'production') {
      delete combinedParams.notifications
      delete combinedParams.objects
      delete combinedParams.parameters
      return await triggersService
        .saveTrigger(combinedParams)
        .then(function (response) {
          Vue.$log.debug('saveTrigger', response.data)
          return response.data
        })
        .catch(function (error) {
          throw error
        })
    } else {
      const mockParams = combinedParams
      mockParams.webhook_send = ''
      mockParams.webhook_url = ''

      mockParams.active = 'true'
      mockParams.duration_from_last_event = 'false'
      mockParams.notify_email = 'false'
      mockParams.notify_sms = 'false'
      mockParams.notify_arrow = 'false'
      mockParams.notify_ohc = 'false'
      mockParams.cmd_send = 'false'

      if (params.event_id) {
        commit('MOCK_TRIGGER_UPDATE', mockParams)
      } else {
        commit('MOCK_TRIGGER_ADDITION', mockParams)
      }
      return 'OK'
    }
  },

  async deleteTrigger ({ commit }, triggerIds) {
    Vue.$log.debug('deleteTrigger', triggerIds)
    if (process.env.NODE_ENV === 'production') {
      return await triggersService
        .deleteTrigger(triggerIds)
        .then(function (response) {
          Vue.$log.debug('deleteTrigger', response.data)
          return response.data
        })
        .catch(function (error) {
          throw error
        })
    } else {
      Vue.$log.debug(triggerIds)
      commit('MOCK_TRIGGER_DELETION', triggerIds)
      return 'OK'
    }
  },
  async muteTrigger ({ commit }, params) {
    Vue.$log.debug('muteTrigger', params)
    const triggerIds = Object.keys(params)
    Vue.$log.debug(params, triggerIds)
    if (process.env.NODE_ENV === 'production') {
      return await triggersService
        .muteTrigger(triggerIds)
        .then(function (response) {
          Vue.$log.debug('muteTrigger', response.data)
          return response.data
        })
        .catch(function (error) {
          throw error
        })
    } else {
      commit('MOCK_TRIGGER_MUTE', params)
      return 'OK'
    }
  }

}

export default actions
