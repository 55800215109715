<template>
  <div class="link-bar mx-auto">
    <span
      class="link-bar--100"
      :style="signal <= 80 ? 'background:none' : ''"
    ></span>
    <span
      class="link-bar--80"
      :style="signal <= 60 ? 'background:none' : ''"
    ></span>
    <span
      class="link-bar--60"
      :style="signal <= 40 ? 'background:none' : ''"
    ></span>
    <span
      class="link-bar--40"
      :style="signal <= 20 ? 'background:none' : ''"
    ></span>
  </div>
</template>

<script>
export default {
  props: {
    signal: Number
  }
}
</script>

<style></style>
