<template>
  <div class="fill-height">
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="driverData"
      item-key="name"
      show-select
      class="elevation-1"
      :search="search"
      dense
      fixed-header
      height="calc(100vh - 172px)"
    >
    <template v-slot:top>
        <v-toolbar>
          <v-row class="align-center">
            <v-col>
              <v-tooltip bottom color="tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="border-1--yellow"
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="openForm"
                  >
                    <v-icon left> mdi-plus </v-icon> Add
                  </v-btn>
                </template>
                <span>Add</span>
              </v-tooltip>
              <v-menu offset-y tile>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="border-1--yellow"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left> mdi-settings-outline </v-icon> Options
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item dense>
                    <v-list-item-title>Import</v-list-item-title>
                  </v-list-item>
                  <v-list-item dense>
                    <v-list-item-title>Export</v-list-item-title>
                  </v-list-item>
                  <v-list-item dense>
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
            <v-col
              cols="8"
              sm="6"
              md="5"
              lg="4"
              class="d-flex justify-content-end"
            >
              <v-text-field
                solo
                dense
                single-line
                hide-details
                clearable
                outlined
                prepend-inner-icon="mdi-magnify"
                v-model="search"
                label="Search"
                color="grey"
                class=""
              ></v-text-field
            ></v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom color="tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-2"
              x-small
              icon
              dense
              plain
              v-bind="attrs"
              v-on="on"
              @click="edit(item.id)"
            >
              <v-icon dense>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip bottom color="tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              x-small
              icon
              dense
              plain
              v-bind="attrs"
              v-on="on"
              @click="remove(item.id)"
            >
              <v-icon dense>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>

    </v-data-table>
    <DriverForm
      :show="showForm"
      v-on:show-driver-dialog="setShowModal"
    />

    <ConfirmDialog
      :info="confirmDetails"
      :show="showConfirm"
      v-on:show-confirm-dialog="confirm"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DriverForm from '@/components/forms/DriverForm.vue'
import ConfirmDialog from '@/components/includes/ConfirmDialog.vue'
export default {
  components: {
    DriverForm,
    ConfirmDialog
  },
  props: {},
  data () {
    return {
      showForm: false,
      id: '',
      showConfirm: false,
      confirmDetails: {
        title: '',
        message: '',
        action: {
          yes: '',
          no: ''
        }
      },
      toDelete: '',
      type: 'driver',
      search: '',
      selected: [],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'ID number', value: 'idnum' },
        { text: 'Description', value: 'description' },
        { text: '', value: 'actions', sortable: false }
      ]
    }
  },
  computed: {
    ...mapGetters({ drivers: 'person/drivers' }),
    driverData: function () {
      const data = []
      try {
        Object.keys(this.drivers).forEach((key) => {
          const driver = this.drivers[key]
          data.push({
            name: driver.name,
            idnum: driver.idn,
            description: driver.desc,
            id: key
          })
        })
      } catch (error) {
        this.handleError()
      }

      return data
    }
  },
  watch: {},
  created () {},
  methods: {
    ...mapActions({
      removeDriver: 'person/removeDriver',

      fetchDrivers: 'person/fetchDrivers'
    }),
    openForm: function () {
      try {
        if (this.checkPrivilege('viewer')) {
          this.showForm = true
        } else {
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'error',
            title: this.$t('ERROR'),
            text: this.$t('THIS_ACCOUNT_HAS_NO_PRIVILEGES_TO_DO_THAT')
          })
        }
      } catch (error) {
        this.handleError()
      }
    },
    setShowModal: function (isShow) {
      this.showForm = isShow
      this.id = ''
    },
    edit (id) {
      this.id = id
      this.openForm()
    },
    remove (id) {
      try {
        if (this.checkPrivilege('viewer')) {
          this.toDelete = id
          this.confirmDetails = {
            title: 'Confirm',
            message: this.$t('ARE_YOU_SURE_YOU_WANT_TO_DELETE')
          }
          this.showConfirm = true
        } else {
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'error',
            title: this.$t('ERROR'),
            text: this.$t('THIS_ACCOUNT_HAS_NO_PRIVILEGES_TO_DO_THAT')
          })
        }
      } catch (error) {
        this.handleError()
      }
    },
    confirm (val) {
      try {
        this.showConfirm = false
        if (val) {
          const params = {
            cmd: 'delete_object_driver',
            driver_id: this.toDelete
          }
          this.removeDriver(params)
          if (process.env.NODE_ENV === 'production') {
            this.fetchDrivers()
          }
        }
        this.toDelete = ''
      } catch (error) {
        this.handleError()
      }
    }
  }
}
</script>

<style>
</style>
