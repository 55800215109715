<template>
  <div class="tab-content">
  <div class="container--fluid upper-content fill-height">
    <div class="upper-content__table">
      <EventsTable />
    </div>
  </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import EventsTable from '@/components/events/EventsTable.vue'

export default {
  components: {
    EventsTable
  },
  methods: {
    ...mapActions({
      // fetchEvents: 'events/fetchEvents',
      initializeObjectData: 'objects/initializeObjectData',
      fetchDrivers: 'person/fetchDrivers'
    })
  },
  created () {
    try {
      this.$log.debug('created')
      // this.fetchEvents({
      //   cmd: 'load_event_list',
      //   _search: false,
      //   nd: 1624238299003,
      //   rows: 25,
      //   page: 1,
      //   sidx: 'dt_tracker',
      //   sord: 'desc'
      // })

      this.initializeObjectData()
      this.fetchDrivers()
    } catch (error) {
      this.handleError(error)
    }
  },
  computed: {
    ...mapGetters({
      events: 'events/events',
      getObjectSettingsById: 'objects/getObjectSettingsById',
      getDriverName: 'person/getDriverName',
      objectsNew: 'objects/objectsNew',
      getObjectByName: 'objects/getObjectByName'
    })
    // eventsData: function () {
    //   const tableData = []
    //   try {
    //     if (this.events.length === 0) {
    //       return tableData
    //     }

    //     // for (const [key, event] of Object.entries(events)) {

    //     for (const [id] of Object.entries(this.events)) {
    //       const eventData = this.events[id]
    //       const obj = this.getObjectByName(eventData.name)

    //       tableData.push({
    //         id: id,
    //         locate: true,
    //         device_name: eventData.name,
    //         person_name: obj ? this.getDriverName(obj.settings.driver_id) : '',
    //         trigger_name: eventData.event_desc,
    //         date: eventData.dt_server
    //       })
    //     }
    //   } catch (error) {
    //     this.handleError(error)
    //   }

    //   return tableData
    // }
  }
}
</script>

<style></style>
