<template>
  <v-dialog
    persistent
    v-model="showScheduleForm"
    hide-overlay
    no-click-animation
    max-width="700"
    width="auto"
  >
    <v-card>
      <v-toolbar color="" dense elevation="2">
        <v-toolbar-title class="text-subtitle-2">
          Schedule Form</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn plain icon x-small @click="closeDialog">
          <v-icon dense>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container fluid>
        <validation-observer ref="observer">
          <v-row>
            <v-col cols="12"
              ><v-switch
                color="primary"
                hide-details="auto"
                dense
                label="Active"
                v-model="fields.active"
              ></v-switch
            ></v-col>

            <v-col cols="12">
              <validation-provider
                class="w-100"
                v-slot="{ errors }"
                rules="required"
                :name="$t('NAME')"
              >
                <div class="mb-2 d-flex flex-row">
                  <span class="text-body-2 mr-1">{{ $t("NAME") }}</span>

                  <FormTooltip>
                    <template v-slot:tooltip-trigger>
                      <v-btn x-small color="primary lighten-1" text icon>
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:tooltip-content
                      >{{ $t("NAME") }}
                    </template>
                  </FormTooltip>
                </div>
                <v-text-field
                  v-model="fields.name"
                  :label="$t('NAME')"
                  solo
                  color="grey"
                  dense
                  hide-details="auto"
                  outlined
                  required
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider
                class="w-100"
                v-slot="{ errors }"
                :name="$t('OBJECT')"
                rules="required"
              >
                <div class="mb-2 d-flex flex-row">
                  <span class="text-body-2 mr-1">{{ $t("OBJECT") }}</span>

                  <FormTooltip>
                    <template v-slot:tooltip-trigger>
                      <v-btn x-small color="primary lighten-1" text icon>
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:tooltip-content
                      >{{ $t("OBJECT") }}
                    </template>
                  </FormTooltip>
                </div>
                <v-select
                  menu-props="dark"
                  item-color="#272727"
                  v-model="fields.object"
                  :items="objectItems"
                  :label="$t('OBJECT')"
                  item-text="text"
                  item-value="value"
                  solo
                  color="grey"
                  dense
                  hide-details="auto"
                  outlined
                  :error-messages="errors"
                  required
                  @change="setTemplateItems"
                >
                </v-select>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="6">
              <validation-provider
                class="w-100"
                v-slot="{ errors }"
                rules="requiredProtocol"
              >
                <div class="mb-2 d-flex flex-row">
                  <span class="text-body-2 mr-1">{{ $t("PROTOCOL") }}</span>

                  <FormTooltip>
                    <template v-slot:tooltip-trigger>
                      <v-btn x-small color="primary lighten-1" text icon>
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:tooltip-content
                      >{{ "Select object to set " + $t("PROTOCOL") }}
                    </template>
                  </FormTooltip>
                </div>
                <v-text-field
                  v-model="fields.protocol"
                  :label="'Select object to set ' + $t('PROTOCOL')"
                  solo
                  color="grey"
                  dense
                  hide-details="auto"
                  outlined
                  required
                  :error-messages="errors"
                  readonly
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider
                class="w-100"
                v-slot="{ errors }"
                rules="required"
                :name="$t('TEMPLATE')"
              >
                <div class="mb-2 d-flex flex-row">
                  <span class="text-body-2 mr-1">{{ $t("TEMPLATE") }}</span>

                  <FormTooltip>
                    <template v-slot:tooltip-trigger>
                      <v-btn x-small color="primary lighten-1" text icon>
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:tooltip-content
                      >Select object and/or gateway to set the templates
                    </template>
                  </FormTooltip>
                </div>
                {{ loadingTemplates ? "Loading templates..." : "" }}
                <v-select
                  color="grey"
                  menu-props="dark"
                  item-color="#272727"
                  @change="setCommand()"
                  v-model="fields.template"
                  :items="templateItems"
                  :label="$t('TEMPLATE')"
                  item-text="text"
                  item-value="value"
                  hide-details="auto"
                  :error-messages="errors"
                  :disabled="loadingTemplates"
                  required
                  solo
                  dense
                  outlined
                ></v-select>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <validation-provider
                class="w-100"
                v-slot="{ errors }"
                rules="required"
                :name="$t('GATEWAY')"
              >
                <div class="mb-2 d-flex flex-row">
                  <span class="text-body-2 mr-1">{{ $t("GATEWAY") }}</span>

                  <FormTooltip>
                    <template v-slot:tooltip-trigger>
                      <v-btn x-small color="primary lighten-1" text icon>
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:tooltip-content
                      >{{ $t("GATEWAY") }}
                    </template>
                  </FormTooltip>
                </div>
                <v-select
                  color="grey"
                  menu-props="dark"
                  item-color="#272727"
                  v-model="fields.gateway"
                  :items="['gprs', 'sms']"
                  :label="$t('GATEWAY')"
                  item-text="text"
                  item-value="value"
                  hide-details="auto"
                  :error-messages="errors"
                  required
                  solo
                  dense
                  outlined
                  :readonly="
                    fields.template !== 'custom' && fields.template !== ''
                  "
                >
                  <template v-slot:item="{ item }">
                    {{ item.toUpperCase() }}
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ item.toUpperCase() }}
                  </template>
                </v-select>
              </validation-provider>
            </v-col>

            <v-col cols="12" md="6">
              <validation-provider
                class="w-100"
                v-slot="{ errors }"
                rules="required"
                :name="$t('TYPE')"
              >
                <div class="mb-2 d-flex flex-row">
                  <span class="text-body-2 mr-1">{{ $t("TYPE") }}</span>

                  <FormTooltip>
                    <template v-slot:tooltip-trigger>
                      <v-btn x-small color="primary lighten-1" text icon>
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:tooltip-content
                      >{{ $t("TYPE") }}
                    </template>
                  </FormTooltip>
                </div>
                <v-select
                  color="grey"
                  menu-props="dark"
                  item-color="#272727"
                  v-model="fields.type"
                  :items="['ascii', 'hex']"
                  :label="$t('TYPE')"
                  item-text="text"
                  item-value="value"
                  hide-details="auto"
                  :error-messages="errors"
                  required
                  solo
                  dense
                  outlined
                  :readonly="
                    fields.template !== 'custom' && fields.template !== ''
                  "
                >
                  <template v-slot:item="{ item }">
                    {{ item.toUpperCase() }}
                  </template>
                  <template v-slot:selection="{ item }">
                    {{ item.toUpperCase() }}
                  </template>
                </v-select>
              </validation-provider>
            </v-col>
            <v-col cols="12" md="6">
              <div class="mb-2 d-flex flex-row">
                <span class="text-body-2 mr-1">{{ $t("COMMAND") }}</span>

                <FormTooltip>
                  <template v-slot:tooltip-trigger>
                    <v-btn x-small color="primary lighten-1" text icon>
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:tooltip-content
                    >{{ $t("COMMAND") }}
                  </template>
                </FormTooltip>
              </div>
              <validation-provider
                class="w-100"
                v-slot="{ errors }"
                rules="required"
                :name="$t('COMMAND')"
              >
                <v-text-field
                  color="grey"
                  @keydown="setTemplateToCustom()"
                  v-model="fields.command"
                  :label="$t('COMMAND')"
                  hide-details="auto"
                  :error-messages="errors"
                  required
                  solo
                  dense
                  outlined
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12"
              ><v-switch
                color="primary"
                hide-details="auto"
                dense
                label="Recurring Event"
                v-model="fields.exact_time"
                @change="changeExactTime"
              ></v-switch>

              <v-btn
                v-if="fields.exact_time === false"
                color="primary lighten-1"
                plain
                text
                @click="showPicker = true"
              >
                Add Schedule
              </v-btn>
              <SchedulePicker
                v-if="fields.exact_time === false"
                :show="showPicker"
                :selectedSchedules="fields.schedule"
                @show-picker-dialog="() => (showPicker = false)"
                @selected-sched="setSelectedSchedules"
              />
            </v-col>
            <v-col
              cols="12"
              v-if="fields.exact_time === false && fields.schedule.length >= 1"
            >
              <v-chip
                class="mr-1"
                v-for="(sched, index) in fields.schedule"
                :key="index"
                close
                @click:close="fields.schedule.splice(index, 1)"
              >
                {{ sched.name }} - {{ sched.time }}
              </v-chip>
            </v-col>

            <v-col cols="12" md="6" v-if="fields.exact_time === true">
              {{ fields.exact_time_d }}
              <v-menu
                v-model="datePickerMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    :rules="fields.exact_time === true ? 'required' : ''"
                    name="Date"
                  >
                    <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">Date</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content>Date </template>
                      </FormTooltip>
                    </div>
                    <v-text-field
                      v-model="fields.exact_time_d"
                      label="Date"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :required="fields.exact_time === true"
                      :error-messages="errors"
                      hint="YYYY-MM-DD format"
                      solo
                      dense
                      outlined
                    ></v-text-field>
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="fields.exact_time_d"
                  @input="datePickerMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" v-if="fields.exact_time === true">
              {{ fields.exact_time_t }}
              <v-menu
                v-model="timePickerMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    :rules="fields.exact_time === true ? 'required' : ''"
                    name="Time"
                  >
                    <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">Time</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content>Time </template>
                      </FormTooltip>
                    </div>
                    <v-text-field
                      v-model="fields.exact_time_t"
                      label="Time"
                      prepend-inner-icon="mdi-clock-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :required="fields.exact_time === true"
                      solo
                      dense
                      outlined
                      :error-messages="errors"
                      placeholder="hh:mm"
                    ></v-text-field>
                  </validation-provider>
                </template>
                <v-time-picker
                  v-if="timePickerMenu"
                  v-model="fields.exact_time_t"
                  full-width
                  @click:minute="datePickerMenu = false"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row class="mb-3 justify-center flex-column flex-sm-row d-flex">
            <v-btn
              @click="closeDialog"
              color="primary"
              outlined
              class="mr-sm-2 mb-2"
              max-width="50%"
              >Cancel</v-btn
            >
            <v-btn @click="save" color="primary" max-width="50%">Save</v-btn>
          </v-row>
        </validation-observer>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import FormTooltip from '@/components/includes/FormTooltip.vue'
import SchedulePicker from '@/components/control/SchedulePicker.vue'
export default {
  // mixins: [commandMixin],

  components: {
    ValidationProvider,
    ValidationObserver,
    FormTooltip,
    SchedulePicker
  },
  props: {
    showScheduleForm: Boolean,
    scheduleId: {
      default: null
    },
    imei: {
      default: null
    }
  },
  data () {
    return {
      datePickerMenu: false,
      timePickerMenu: false,
      fields: {
        active: true, //
        name: '', //
        object: '', //
        protocol: '', //
        gateway: '',
        template: '',
        type: '',
        command: '',
        exact_time: false,
        exact_time_d: '',
        exact_time_t: '',
        schedule: [],
        day_time: {
          mon: false,
          mon_time: '00:00',
          tue: false,
          tue_time: '00:00',
          wed: false,
          wed_time: '00:00',
          thu: false,
          thu_time: '00:00',
          fri: false,
          fri_time: '00:00',
          sat: false,
          sat_time: '00:00',
          sun: false,
          sun_time: '00:00'
        }
      },
      showPicker: false,
      loadingTemplates: false,
      templateList: null,
      templateItems: [
        {
          text: 'Custom',
          value: 'custom'
        }
      ]
    }
  },
  created () {
    this.initFields()
  },
  computed: {
    ...mapGetters({
      //   user: 'settings/user',
      //   mapList: 'settings/mapList',
      objectsNew: 'objects/objectsNew',
      objectItems: 'objects/objectItems',
      // templateItems: 'cmd/templateItems',
      cmdTemplateData: 'cmd/cmdTemplateData',

      cmdScheduleData: 'cmd/cmdScheduleData',
      selectedTemplate: 'cmd/selectedTemplate',
      getObjectSettingsById: 'objects/getObjectSettingsById'
    })
  },
  methods: {
    ...mapActions({
      saveCmdSchedule: 'cmd/saveCmdSchedule',
      getCmdScheduleData: 'cmd/getCmdScheduleData',
      getCmdTemplateData: 'cmd/getCmdTemplateData'
    }),
    closeDialog () {
      this.$refs.observer.reset()
      // this.fields = {
      //   object: '',
      //   template: '',
      //   gateway: '',
      //   type: '',
      //   command: ''
      // }
      this.$emit('show-schedule-dialog', false)
    },

    async setTemplateItems () {
      if (this.fields.object) {
        this.loadingTemplates = true
        this.fields.template = ''
        this.getCmdTemplateData({ imei: this.fields.object })
          .then(data => {
            this.templateList = data
          })
          .catch(error => {
            this.handleError(error)
          })
      }
    },
    async save () {
      if (this.checkPrivilege('viewer')) {
        let valid = await this.$refs.observer.validate()

        if (
          valid &&
          this.fields.exact_time === false &&
          this.fields.schedule.length === 0
        ) {
          valid = false

          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'error',
            title: this.$t('ERROR'),
            text: 'Please add schedule/s'
          })
        }
        if (valid) {
          this.fields.exact_time_dt = ''
          if (this.fields.exact_time) {
            this.fields.exact_time_dt =
              this.fields.exact_time_d + ' ' + this.fields.exact_time_t
          } else {
          }
          this.fields.schedule.forEach(sched => {
            this.fields.day_time[sched.day] = true
            this.fields.day_time[sched.day + '_time'] = sched.time
          })

          const params = {
            cmd_id: this.scheduleId !== null ? this.scheduleId : false,
            name: this.fields.name,
            active: this.fields.active,
            exact_time: this.fields.exact_time,
            exact_time_dt: this.fields.exact_time_dt,
            day_time: JSON.stringify(this.fields.day_time),
            protocol: this.fields.protocol,
            imei: this.fields.object,
            gateway: this.fields.gateway,
            type: this.fields.type,
            cmd: this.fields.command
          }
          // console.log(params);

          this.saveCmdSchedule(params)
            .then(response => {
              if (response.message === 'OK') {
                this.$notify({
                    duration: 5000,
                    closeOnClick: false,
                  type: 'success',
                  title: this.$t('INFORMATION'),
                  text: this.$t('CHANGES_SAVED_SUCCESSFULLY')
                })
                if (process.env.NODE_ENV === 'production') {
                  this.getCmdScheduleData()
                }
                this.closeDialog()
              }
            })
            .catch(error => {
              this.handleError(error)
            })
        }
      } else {
        this.$notify({
                    duration: 5000,
                    closeOnClick: false,
          type: 'error',
          title: this.$t('ERROR'),
          text: this.$t('THIS_ACCOUNT_HAS_NO_PRIVILEGES_TO_DO_THAT')
        })
      }
    },
    setCommand () {
      try {
        let cmd = null
        if (this.fields.template !== 'custom') {
          // console.log(this.fields.template);

          cmd = this.cmdTemplateData[this.fields.template]
          // console.log(cmd);
        }
        if (cmd) {
          this.fields.command = cmd.cmd
          this.fields.gateway = cmd.gateway.toLowerCase()
          this.fields.type = cmd.type
        } else {
          // this.fields.command = ''
          // this.fields.gateway = ''
          // this.fields.type = ''
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    setSelectedSchedules (days) {
      // console.log(days);
      this.fields.schedule = days
    },
    setTemplateToCustom () {
      // console.log("set to custom");
      this.fields.template = 'custom'
    },
    initFields () {
      if (this.scheduleId !== null) {
        // this.fields.object = this.id
        const cmd = this.cmdScheduleData[this.scheduleId]
        // console.log(
        //   this.cmdScheduleData[this.scheduleId],
        //   cmd.exact_time_dt.split(" "),
        //   cmd.exact_time_dt.split(" ")[1]
        // );
        const dt = cmd.exact_time_dt.split(' ')

        // let d = cmd.exact_time_dt.split(' ')[0];
        // let t = cmd.exact_time_dt.split(' ')[1];
        // // console.log(d, t)

        this.fields = {
          active: JSON.parse(cmd.active), //
          name: cmd.name, //
          object: cmd.imei, //
          protocol: cmd.protocol, //
          gateway: cmd.gateway,
          // template: this.selectedTemplate(cmd.imei, cmd.cmd),
          template: '',
          type: cmd.type,
          command: cmd.cmd,
          exact_time: JSON.parse(cmd.exact_time),
          exact_time_d: '',
          exact_time_t: '',
          schedule: [],
          day_time: cmd.day_time
        }

        if (this.fields.exact_time) {
          this.fields.exact_time_d = dt[0]
          this.fields.exact_time_t = dt[1]
        } else {
          const days = {
            mon: 'Monday',
            tue: 'Tuesday',
            wed: 'Wednesday',
            thu: 'Thursday',
            fri: 'Friday',
            sat: 'Saturday',
            sun: 'Sunday'
          }
          if (cmd.day_time !== null && Object.entries(cmd.day_time).length) {
            for (const [key, d] of Object.entries(cmd.day_time)) {
              if (typeof d === 'boolean' && d === true) {
                this.fields.schedule.push({
                  day: key,
                  name: days[key],
                  time: cmd.day_time[key + '_time']
                })
              }
            }
          }
        }
      } else {
        this.fields = {
          active: true, //
          name: '', //
          object: this.imei !== null ? this.imei : this.objectItems[0].value, //
          protocol:
            this.imei !== null
              ? this.objectsNew[this.imei].settings.protocol
              : '', //
          gateway: '',
          template: '',
          type: '',
          command: '',
          exact_time: false,
          exact_time_d: '',
          exact_time_t: '',
          schedule: [],
          day_time: {
            mon: false,
            mon_time: '00:00',
            tue: false,
            tue_time: '00:00',
            wed: false,
            wed_time: '00:00',
            thu: false,
            thu_time: '00:00',
            fri: false,
            fri_time: '00:00',
            sat: false,
            sat_time: '00:00',
            sun: false,
            sun_time: '00:00'
          }
        }
      }
    },
    changeExactTime () {
      this.fields.schedule = []
      this.fields.exact_time_t = ''
      this.fields.exact_time_d = ''
    }
  },
  watch: {
    showScheduleForm: function () {
      this.initFields()
    },
    'fields.object': function () {
      this.fields.protocol = ''
      if (this.fields.object) {
        this.fields.protocol = this.objectsNew[
          this.fields.object
        ].settings.protocol
      }
    },
    'fields.exact_time': function () {},

    templateList: function () {
      let data = []

      Object.keys(this.templateList).forEach(id => {
        const template = this.templateList[id]
        data.push({
          text: template.name,
          value: id
        })
      })
      data = data.sort((a, b) =>
        a.text > b.text ? 1 : b.text > a.text ? -1 : 0
      )

      data.unshift({
        text: 'Custom',
        value: 'custom'
      })

      this.templateItems = data

      this.loadingTemplates = false
    }
  }
}
</script>

<style></style>
