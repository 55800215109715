import axios from 'axios'
import { globals } from '@/plugins/global'

export const cmdService = {
  getCmdTemplateUnfilteredData,
  getCmdTemplateData,
  sendCommand,
  getCmdData,
  deleteCmd,
  getCmdScheduleData,
  saveCmdSchedule,
  deleteCmdSchedule,
  deleteCmdTemplate,
  saveCmdTemplate,
  loadCmdTemplateDefaults
}

const requestUrl = process.env.BASE_URL + 'src/assets/json/'

function getCmdTemplateUnfilteredData () {
  if (process.env.NODE_ENV === 'development') {
    return axios.get(`${requestUrl}/cmd_template_data.json`)
  }
  return axios.post('/cmd/template/data')
}

function getCmdTemplateData (params) {
  if (process.env.NODE_ENV === 'development') {
    return axios.get(`${requestUrl}/cmd_template_data.json`)
  }
  return axios.post(
    '/cmd/manufacturer/template/data',
    globals.convertJsonToFormData(params)
  )
}

function sendCommand (params) {
  let type = 'gprs'
  if (params.cmd === 'exec_cmd_sms') {
    type = 'sms'
  }

  return axios.post(
    '/cmd/' + type + '/exec',
    globals.convertJsonToFormData(params)
  )
}

function getCmdData (params) {
  if (process.env.NODE_ENV === 'development') {
    if (params.gateway === 'sms') {
      return axios.get(`${requestUrl}/cmd_sms_data.json`)
    } else {
      return axios.get(`${requestUrl}/cmd_gprs_data.json`)
    }
  }
  return axios.post('/cmd/data', globals.convertJsonToFormData(params))
}

function getCmdScheduleData (params) {
  if (process.env.NODE_ENV === 'development') {
    return axios.get(`${requestUrl}/cmd_schedule_data.json`)
  }
  return axios.post('/cmd/schedule/data')
}

function deleteCmd (ids) {
  const formData = new FormData()
  // formData.append('cmd', 'delete_selected_events')
  ids.forEach(id => {
    formData.append('items[]', id)
  })
  return axios.post('/cmd/delete', formData)
}

function saveCmdSchedule (params) {
  return axios.post(
    '/cmd/schedule/save',
    globals.convertJsonToFormData(params)
  )
}

function deleteCmdSchedule (ids) {
  const formData = new FormData()
  // formData.append('cmd', 'delete_selected_events')
  ids.forEach(id => {
    formData.append('items[]', id)
  })
  return axios.post('/cmd/schedule/delete', formData)
}

function deleteCmdTemplate (ids) {
  const formData = new FormData()
  // formData.append('cmd', 'delete_selected_events')
  ids.forEach(id => {
    formData.append('items[]', id)
  })
  return axios.post('/cmd/manufacturer/template/delete', formData)
}

function saveCmdTemplate (params) {
  return axios.post(
    '/cmd/manufacturer/template/save',
    globals.convertJsonToFormData(params)
  )
}

function loadCmdTemplateDefaults () {
  if (process.env.NODE_ENV === 'development') {
    return axios.get(`${requestUrl}/cmd_template_data.json`)
  }
  return axios.post('/objects/default_templates')
}
