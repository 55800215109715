<template>
  <v-dialog
    persistent
    v-model="show"
    hide-overlay
    no-click-animation
    max-width="340"
  >
    <v-card outlined>
      <v-toolbar dense elevation="0">
        <v-toolbar-title class="text-subtitle-2">
          Trigger Information</v-toolbar-title
        >
      </v-toolbar>

      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <TriggerHelpInformation :type="type" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn
              small
              outlined
              class="mr-2"
              color="primary"
              @click="$emit('set-next-step', false)"
            >
              Cancel
            </v-btn>
            <v-btn small color="primary" @click="$emit('set-next-step', true)">
              Proceed
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import TriggerHelpInformation from '@/components/triggers/TriggerHelpInformation.vue'
export default {
  props: {
    show: Boolean,
    type: String
  },
  components: {
    TriggerHelpInformation
  }
}
</script>

<style></style>
