import { reportsService } from '@/services/reports'
import Vue from 'vue'

const actions = {
  async fetchReports ({ commit }) {
    return await reportsService.getReports()
      .then(function (response) {
        Vue.$log.debug('fetchReports', 'success')
        commit('SET_REPORTS', response.data)
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  }

  // async fetchReportsList ({ commit }, params) {
  //   return await reportsService.getReportsList(params)
  //     .then(function (response) {
  //       Vue.$log.debug('fetchReportsList', 'success')
  //       commit('SET_REPORTS_LIST', response.data)
  //       return response
  //     })
  //     .catch(function (error) {
  //       Vue.$log.debug('fetchReportsList' + error)
  //       throw error
  //     })
  // }
}

export default actions
