<template>
  <v-dialog
    persistent
    v-model="show"
    hide-overlay
    no-click-animation
    fullscreen
  >
    <!-- {{objectsNew['12345'].settings.name}} -->
    <v-card class="">
      <v-toolbar dense elevation="0">
        <v-btn icon x-small @click="setSelectedObjects(false)">
          <v-icon dense>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="text-subtitle-2"
          >Select Vehicle(s)</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn color="primary" text @click="setSelectedObjects(true)">
            Save selected
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container fluid style="height: calc(100vh - 48px)">
        <!-- <v-container> -->
        <v-list>
          <v-subheader>
            <v-checkbox
              :label="selectAll ? 'De-select All' : 'Select All'"
              @click="toggleMassSelect"
            ></v-checkbox>
          </v-subheader>
          <v-divider></v-divider>
          <v-list-item-group multiple v-model="selectedObjects">
            <v-list-item v-for="imei in Object.keys(objectsNew)" :key="imei">
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>

                <v-list-item-icon>
                  <v-img
                    min-width="25"
                    max-width="50"
                    height="auto"
                    :src="
                      `${publicPath}assets/` + objectsNew[imei].settings.icon
                    "
                  ></v-img>
                </v-list-item-icon>
                <v-list-item-content class="ml-5">
                  <v-list-item-title
                    v-text="objectsNew[imei].settings.name"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {},
  props: {
    show: Boolean
  },
  data () {
    return {
      selectedObjects: [],
      selectAll: false
    }
  },
  computed: {
    ...mapGetters({
      // objectSettings: "objects/objectSettings",
      objectsNew: 'objects/objectsNew'
    })
  },
  methods: {
    ...mapActions({
      initializeObjectData: 'objects/initializeObjectData'
    }),
    setSelectedObjects (isSave) {
      try {
        if (this.selectedObjects.length >= 1) {
          const imeis = Object.keys(this.objectsNew)
          this.$log.debug(imeis, this.selectedObjects)

          const selectedImeis = []
          this.selectedObjects.forEach((key) => {
            selectedImeis.push(imeis[key])
          })
          this.$log.debug(selectedImeis)
          this.$emit('set-objects', selectedImeis, isSave)
        } else {
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'error',
            title: this.$t('ERROR'),
            text: 'Please select at least 1 vehicle'
          })
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    toggleMassSelect () {
      try {
        this.selectAll = !this.selectAll
        this.selectedObjects = []
        if (this.selectAll) {
          Object.keys(this.objectsNew).forEach((key, index) => {
            this.selectedObjects.push(index)
          })
        }
      } catch (error) {
        this.handleError(error)
      }
    }
  },
  watch: {
    show: function () {
      this.selectedObjects = []
      this.$log.debug(this.objectsNew)
    }
  },
  created () {
    this.selectedObjects = []
    this.initializeObjectData().catch((error) => {
      this.handleError(error)
    })
  }
}
</script>

<style></style>
