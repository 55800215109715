<template>
  <v-data-table
    dense
    :headers="headers"
    :items="filteredTableData"
    item-key="name"
    class="reports-table"
    :item-class="selectedRow"
    @click:row="handleRowClick"
    fixed-header
    dark
    height="100%"
    hide-default-footer
    :items-per-page="-1"
  >
      <template v-slot:top>
      <v-toolbar>
        <template v-slot:default>
          <v-col class="pl-0"><v-text-field
            solo
            dense
            single-line
            hide-details
            clearable
            outlined
            prepend-inner-icon="mdi-magnify"
            v-model="filter1"
            label="Filter"
            color="grey"
            class=""
          ></v-text-field></v-col>
          <v-col class="pr-0"><v-text-field
            solo
            dense
            single-line
            hide-details
            clearable
            outlined
            prepend-inner-icon="mdi-magnify"
            v-model="filter2"
            label="2nd Filter"
            color="grey"
            class=""
          ></v-text-field></v-col>
        </template>
      </v-toolbar>
    </template>
    <template v-slot:header="">
      <thead>
        <tr>
          <th colspan="2" class="text-align-center">Report Details</th>
          <th colspan="4" class="text-align-center hidden-md-and-down">
            Metrics
          </th>
        </tr>
      </thead>
    </template>
    <template v-slot:header.follow="{}">
      {{ $t("SCHEDULE") }}
    </template>

    <template v-slot:item.action2="{}">
      <v-img max-width="18" :src="`${publicPath}assets/img/icons/reports.svg`"></v-img>
    </template>
    <template v-slot:item.action1="{}">
      <v-img max-width="18" :src="`${publicPath}assets/img/icons/gear.svg`"></v-img>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    tableData: Array
  },
  data: () => ({
    filter1: '',
    filter2: '',
    selectedRowId: '',
    singleSelect: false,
    selected: [],
    headers: [
      { text: 'Name', value: 'report_name' },
      { text: 'Report Type', value: 'report_type' },
      { text: 'Schedule', value: 'schedule' },
      { text: 'Period', value: 'period', sortable: false },
      { text: '', value: 'action1', sortable: false },
      { text: '', value: 'action2', sortable: false }
    ]
  }),
  computed: {
    ...mapGetters({
      getReportType: 'reports/getReportType',
      getReportSchedule: 'reports/getReportSchedule'
    }),
    filteredTableData: function () {
      let data = this.tableData
      try {
        if (this.filter1 || this.filter2) {
          data = this.tableData.filter(data => {
            if (
              (this.filter1 && data.report_name.toLowerCase().includes(this.filter1.toLowerCase())) ||
              (this.filter1 && data.report_type.toLowerCase().includes(this.filter1.toLowerCase())) ||
              (this.filter1 && data.schedule.toLowerCase().includes(this.filter1)) ||

              (this.filter2 && data.report_name.toLowerCase().includes(this.filter2.toLowerCase())) ||
              (this.filter2 && data.report_type.toLowerCase().includes(this.filter2.toLowerCase())) ||
              (this.filter2 && data.schedule.toLowerCase().includes(this.filter2))
            ) {
              return true
            }
          })
        }
      } catch (error) {
        this.handleError(error)
      }

      return data
    }
  },
  methods: {
    handleRowClick: function (item) {
      this.selectedRowId = item.id
      this.$log.debug(this.selectedObj)
      this.$emit('selected-object', item.id)
    },
    selectedRow (item) {
      return this.selectedRowId && item.id === this.selectedRowId
        ? 'active-row'
        : ''
    }
  }
}
</script>

<style></style>
