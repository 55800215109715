<template>
<v-layout fluid v-resize="onResize">
  <!-- <div style="height: calc(100vh - 56px)"> -->
    <ObjectsTable :table-data="tableData" v-on:show-form="showForm = $event" :height="contentHeight" :width="windowSize.x" />
    <ObjectsForm
      :show="showForm"
      :id="id"
      v-on:show-objects-dialog="setShowModal"
    />
  <!-- </div> -->
</v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ObjectsTable from '@/components/objects/ObjectsTable.vue'
import ObjectsForm from '@/components/forms/ObjectsForm.vue'
export default {
  components: { ObjectsTable, ObjectsForm },
  data () {
    return {
      showForm: false,
      id: ''
    }
  },
  computed: {
    ...mapGetters({
      // objects: "objects/objectsData",
      // objectSettings: "objects/objectSettings",
      getObjectSensorByName: 'objects/getObjectSensorByName',
      getSensorValue: 'objects/getSensorValue',
      getGroupName: 'groups/getGroupName',
      objectsNew: 'objects/objectsNew'
    }),
    tableData: function () {
      const tableData = []
      try {
        for (const [imei] of Object.entries(this.objectsNew)) {
          const obj = this.objectsNew[imei]
          const dataSignal = this.getObjectSensorByName(imei, 'Data Signal')

          let link = 0
          if (dataSignal && dataSignal.length >= 1) {
            if (dataSignal[0].formula === '') {
              if (obj.data.params[dataSignal[0].param] >= dataSignal[0].hv) {
                link = 100
              } else {
                link =
                  (obj.data.params[dataSignal[0].param] / dataSignal[0].hv) *
                  100
              }
            }
          }
          const ignition = this.getObjectSensorByName(imei, 'Ignition')
          let ign = ''

          if (ignition && ignition.length >= 1) {
            const sensorValue = this.getSensorValue(imei, 'Ignition')

            ign = sensorValue ? sensorValue.value_full : ''
          }

          tableData.push({
            id: imei,
            locate: obj.locate,
            visible: obj.visible,
            follow: obj.follow,
            name: obj.settings.name,
            group: obj.settings.group_id,
            group_name: this.getGroupName(obj.settings.group_id),
            ign: ign,
            link: link,
            // sat: satellite,
            speed: obj.data ? obj.data.speed : '',
            odometer: obj.odometer
          })
        }

        return tableData
      } catch (error) {
        this.handleError(error)
      }
      return tableData
    }
  },
  methods: {
    ...mapActions({
      initializeObjectData: 'objects/initializeObjectData',
      fetchGroupSettings: 'groups/fetchGroupSettings',
      fetchObjectData: 'objects/fetchObjectData'
    }),
    setShowModal: function (isShow, reload = false) {
      try {
        this.showForm = isShow
        this.id = ''

        if (reload && process.env.NODE_ENV === 'production') {
          this.initializeObjectData().catch((error) => {
            this.handleError(error)
          })
        }
      } catch (error) {
        this.handleError(error)
      }
    }
  },
  async created () {
    try {
      this.onResize()
      await Promise.all([
        // this.initializeObjectData(),
        this.fetchGroupSettings()
      ]).catch((error) => {
        this.handleError(error)
      })
      clearInterval(this.$timer.objectLoad)
      this.$timer.objectLoad = setInterval(() => {
        const ctr = Math.floor(Math.random() * 10) + 1

        this.fetchObjectData({
          ctr: ctr
        }).catch((error) => {
          this.handleError(error)
          clearInterval(this.$timer.objectLoad)
        })
      }, this.config.map_refresh * 1000)
    } catch (error) {
      this.handleError(error)
    }
  }
}
</script>

<style></style>
