<template>
  <div>
    <l-tile-layer
      v-for="(tileProvider, key) in mapTypes.openstreetmap"
      :key="key"
      :visible="key === selectedMap.value ? true : false"
      :url="tileProvider.url"
      :attribution="tileProvider.attribution"
    />
    <l-tile-layer
      v-for="(tileProvider, key) in mapTypes.google"
      :key="key"
      :visible="key === selectedMap.value ? true : false"
      :url="tileProvider.url"
      :subdomains="tileProvider.subdomains"
    />
  </div>
</template>

<script>
import {
  LTileLayer
  // LPopup
} from 'vue2-leaflet'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    LTileLayer
  },
  data () {
    return {
      mapTypes: {
        openstreetmap: {
          osm: {
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution:
              '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          },
          toner: {
            url: 'https://stamen-tiles.a.ssl.fastly.net/toner/{z}/{x}/{y}.png',
            attribution:
              'Map tiles by <a href="http://stamen.com">Stamen Design</a>, under <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, under <a href="http://www.openstreetmap.org/copyright">ODbL</a>.'
          },
          water: {
            url: 'https://stamen-tiles.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.png',
            attribution:
              'Map tiles by <a href="http://stamen.com">Stamen Design</a>, under <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, under <a href="http://www.openstreetmap.org/copyright">ODbL</a>.'
          }
        },
        google: {
          gmap: {
            url: 'https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
          },
          gsat: {
            url: 'https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
          },
          ghyb: {
            url: 'https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
          },
          gter: {
            url: 'https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}',
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      mapSettings: 'settings/mapSettings'
    }),
    selectedMap: function () {
      // name: "OSM Map",
      // value: "osm",
      if (this.mapSettings) {
        return this.mapSettings
      }
      return {
        name: 'OSM Map',
        value: 'osm'
      }
    }
  },
  methods: {
    ...mapActions({
    })
  }
}
</script>

<style>
</style>
