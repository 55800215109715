<template>
  <v-tabs
    class="map-tabs h-100"
    slider-color="#FF5100"
    background-color="#3c3c3c"
    show-arrows
    height="30px"
  >
    <!-- <v-tab :to="{ path: 'open-street-map' }" append> -->
    <v-tab>
      <v-img
        max-width="13"
        :src="`${publicPath}assets/img/icons/tabs/open-street-map.svg`"
        class="mr-1"
      ></v-img>
      Open Street Map
    </v-tab>

    <!-- <v-tab :to="{ path: 'open-street-map' }" append> -->
    <v-tab>
      <v-img
        max-width="13"
        :src="`${publicPath}assets/img/icons/tabs/google-map.svg`"
        class="mr-1"
      ></v-img>
      Google Map
    </v-tab>
<!-- style="height: calc(100vh - 100px) !important" -->
    <v-tab-item > <OpenStreetMap /> </v-tab-item>
    <v-tab-item> <GoogleMaps /> </v-tab-item>
  </v-tabs>

  <!-- <router-view></router-view> -->
</template>

<script>
import OpenStreetMap from '@/components/maps/OpenStreetMap.vue'
import GoogleMaps from '@/components/maps/GoogleMaps.vue'
export default {
  components: { OpenStreetMap, GoogleMaps }
}
</script>

<style></style>
