<template>
  <v-container
    fluid
    id="scroll-target"
    style="max-height: calc(100vh - 56px)"
    class="overflow-y-auto"
  >
    <v-row
      class="ma-1 d-flex flex-column align-content-center"
      style="height: auto"
      v-if="settings && object"
    >
      <!-- <v-card class="mx-auto w-100" color="#272727" flat tile> -->

      <!-- </v-card> -->
      <v-col cols="12" md="8" xl="6">
        <v-card outlined class="w-100">
          <v-card-title
            ><h4 color="primary">{{ $t("GENERAL") }}</h4></v-card-title
          >
          <v-card-text>
            <v-row class="" dense>
              <v-col class="font-weight-bold">{{ $t("OBJECT") }}</v-col>
              <v-col>{{ settings.name }}</v-col>
            </v-row>
            <v-row class="" dense>
              <v-col class="font-weight-bold">{{ $t("ODOMETER") }}</v-col>
              <v-col
                >{{ getObjectOdometer(imei, false) }}
                {{ $t("UNIT_DISTANCE") }}</v-col
              >
            </v-row>
            <v-row class="" dense>
              <v-col class="font-weight-bold">{{ $t("STATUS") }}</v-col>
              <v-col>{{ object.status_string }}</v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" xl="6">
        <v-card outlined class="w-100">
          <v-card-title
            ><h4>{{ $t("LOCATION") }}</h4></v-card-title
          >
          <v-card-text>
            <v-row class="" dense>
              <v-col class="font-weight-bold">{{ $t("ADDRESS") }}</v-col>
              <v-col>{{ address }}</v-col>
            </v-row>
            <v-row class="" dense>
              <v-col class="font-weight-bold">{{ $t("POSITION") }}</v-col>
              <v-col
                >{{ object.data.lat }}&deg;, {{ object.data.lng }}&deg;</v-col
              >
            </v-row>
            <v-row class="" dense>
              <v-col class="font-weight-bold">{{ $t("ALTITUDE") }}</v-col>
              <v-col>{{ object.data.altitude }} {{ $t("UNIT_HEIGHT") }}</v-col>
            </v-row>

            <v-row class="" dense>
              <v-col class="font-weight-bold">{{ $t("ANGLE") }}</v-col>
              <v-col>{{ object.data.angle }}&deg;</v-col>
            </v-row>

            <v-row class="" dense>
              <v-col class="font-weight-bold">{{ $t("SPEED") }}</v-col>
              <v-col>{{ object.data.speed }} {{ $t("UNIT_SPEED") }}</v-col>
            </v-row>

            <v-row class="" dense>
              <v-col class="font-weight-bold">{{ $t("TIME_POSITION") }}</v-col>
              <v-col>{{ object.data.dt_tracker }}</v-col>
            </v-row>
            <v-row class="" dense>
              <v-col class="font-weight-bold">{{ $t("TIME_SERVER") }}</v-col>
              <v-col>{{ object.data.dt_server }}</v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="8"
        xl="6"
        v-if="Object.keys(settings.sensors).length"
      >
        <v-card outlined class="w-100">
          <v-card-title
            ><h4>{{ $t("SENSORS") }}</h4></v-card-title
          >
          <v-card-text>
            <v-row
              class=""
              dense
              v-for="(sensor, index) in settings.sensors"
              :key="index"
            >
              <v-col class="font-weight-bold">{{ sensor.name }}</v-col>
              <v-col>
                {{ getSensorValue(imei, sensor.name)["value_full"] }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="8"
        xl="6"
        v-if="object.status === 'm' && settings.driver_id && getDriverName(settings.driver_id)"
      >
        <v-card outlined class="w-100">
          <v-card-title
            ><h4>{{ $t("DRIVER_INFO") }}</h4></v-card-title
          >
          <v-card-text>
            <v-row class="" dense>
              <v-col class="font-weight-bold">{{ $t("NAME") }}</v-col>
              <v-col>{{ getDriverName(settings.driver_id) }}</v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {},
  // props: {
  //   imei:  String
  // },
  data () {
    return {
      // imei: this.$route.params.imei,
      address: '',
      addressError: false,
      imei: this.$route.params.imei
    }
  },
  computed: {
    ...mapGetters({
      getGroupName: 'groups/getGroupName',
      getObjectSettingsById: 'objects/getObjectSettingsById',
      getObjectOdometer: 'objects/getObjectOdometer',
      getDriverName: 'person/getDriverName',
      getSensorValue: 'objects/getSensorValue'
    }),
    settings: function () {
      let settings = null
      try {
        if (Object.prototype.hasOwnProperty.call(this.objectsNew, this.imei)) {
          settings = this.objectsNew[this.imei].settings
        }
        this.$log.debug(this.imei)
      } catch (error) {
        this.handleError(error)
      }
      return settings
    },
    object: function () {
      let data = null
      try {
        if (Object.prototype.hasOwnProperty.call(this.objectsNew, this.imei)) {
          data = this.objectsNew[this.imei]
        }
      } catch (error) {
        this.handleError(error)
      }

      return data
    }
  },
  async created () {
    try {
      this.$log.debug('details', this.imei)
      await this.initializeObjectData()
      await this.fetchGroupSettings()
      await this.fetchDrivers()
      this.address = await this.getAddress(
        {
          by: 'latlng',
          lat: this.object.data.lat,
          lng: this.object.data.lng
        }
      )

      if (!this.$timer.objectLoad) {
        clearInterval(this.$timer.objectLoad)
        this.$timer.objectLoad = setInterval(() => {
          const ctr = Math.floor(Math.random() * 10) + 1

          this.fetchObjectData({
            ctr: ctr
          }
          ).catch((error) => {
            this.handleError(error)
            clearInterval(this.$timer.objectLoad)
          })
          this.getAddress(
            {
              by: 'latlng',
              lat: this.object.data.lat,
              lng: this.object.data.lng
            }
          ).then((address) => {
            this.address = address
          })
        }, this.config.map_refresh * 1000)
      }
    } catch (error) {
      this.handleError(error)
    }
  },

  methods: {
    ...mapActions({
      initializeObjectData: 'objects/initializeObjectData',
      fetchGroupSettings: 'groups/fetchGroupSettings',
      fetchDrivers: 'person/fetchDrivers',
      fetchObjectData: 'objects/fetchObjectData',

      searchAddress: 'places/searchAddress'
    })
  }
}
</script>

<style></style>
