<template>
  <div class="tab-content">
    <PersonnelUpperSection />
    <PersonnelLowerSection />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PersonnelUpperSection from '@/components/personnel/PersonnelUpperSection.vue'
import PersonnelLowerSection from '@/components/personnel/PersonnelLowerSection.vue'

export default {
  components: {
    PersonnelUpperSection,
    PersonnelLowerSection
  },
  methods: {
    ...mapActions({ fetchDrivers: 'person/fetchDrivers' })
  },
  created () {
    try {
      this.fetchDrivers()
    } catch (error) {
      this.handleError(error)
    }
  }
}
</script>

<style></style>
