import axios from 'axios'
import { globals } from '@/plugins/global'

export const groupService = {
  getGroupSettings,
  saveGroup,
  removeGroup,
  importGroups
}

const requestUrl = process.env.BASE_URL + 'src/assets/json/settings'

function getGroupSettings () {
  if (process.env.NODE_ENV === 'development') {
    return axios.get(`${requestUrl}/groups_data.json`)
  }
  return axios.post('/groups/data', globals.convertJsonToFormData({ cmd: 'load_object_group_data' }))
}

// function getGroupListSettings (params) {
//   if (process.env.NODE_ENV === 'development') {
//     return axios.get(`${requestUrl}/group_list.json`)
//   }
//   return axios.get( '', params)
// }

function saveGroup (params) {
  return axios.post('/groups/save', globals.convertJsonToFormData(params))
}

function removeGroup (ids) {
  const formData = new FormData()
  ids.forEach(id => {
    formData.append('items[]', id)
  })
  return axios.post('/groups/delete', formData)
}

function importGroups (params) {
  return axios.post('/groups/import', globals.convertJsonToFormData(params))
}
