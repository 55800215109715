<template>
  <div class="container--fluid upper-content">
    <div class="upper-content__table">
      <TrackingTable
        v-if="trackingData"
        :table-data="trackingData"
        v-on:selected-object="getSelectedObject"
        v-on:show-form="showForm = $event"
        :selected-object="selectedObject"
      />
    </div>
    <SendMessageForm
      :show="showForm"
      v-on:show-message-dialog="showForm = $event"
    />
    <!-- <div class="upper-content__action">
      <div>
        <label for="">Send message</label>
        <input
          type="text"
          name="message"
          id=""
          placeholder="Enter Message Here"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TrackingTable from '@/components/tracking/TrackingTable.vue'

import SendMessageForm from '@/components/tracking/SendMessageForm.vue'
export default {
  components: {
    TrackingTable, SendMessageForm
  },
  props: {
    selectedObject: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      showForm: false
    }
  },
  computed: {
    ...mapGetters({
      // objects: 'objects/objectsData',
      // objectSettings: 'objects/objectSettings',
      getObjectSensorByName: 'objects/getObjectSensorByName',
      getSensorValue: 'objects/getSensorValue',
      getDriverName: 'person/getDriverName'
    }),
    trackingData: function () {
      const tableData = []
      try {
        Object.keys(this.objectsNew).forEach((key) => {
          const dataSignal = this.getObjectSensorByName(key, 'Data Signal')
          let link = 0
          if (dataSignal && dataSignal.length >= 1) {
            if (dataSignal[0].formula === '') {
              if (
                this.objectsNew[key].data.params[dataSignal[0].param] >=
                dataSignal[0].hv
              ) {
                link = 100
              } else {
                link =
                  (this.objectsNew[key].data.params[dataSignal[0].param] /
                    dataSignal[0].hv) *
                  100
              }
            }
          }

          const satellitesInUse = this.getObjectSensorByName(
            key,
            'Satellites In Use'
          )
          const satellite =
            satellitesInUse && satellitesInUse.length >= 1
              ? this.objectsNew[key].data.params[satellitesInUse[0].param]
              : ''

          const ignition = this.getObjectSensorByName(key, 'Ignition')
          let ign = ''

          if (ignition && ignition.length >= 1) {
            const sensorValue = this.getSensorValue(
              key,
              'Ignition')

            ign = sensorValue ? sensorValue.value_full : ''
          }
          // const ign =

          //     ? this.getSensorValue(
          //       key,
          //       'Ignition',
          //       this.objectsNew[key].data.params[ignition[0].param]
          //     )
          //     : ''

          tableData.push({
            id: key,
            locate: this.objectsNew[key].locate,
            follow: this.objectsNew[key].follow,
            device_name: this.objectsNew[key].settings.name,
            driver_name: this.getDriverName(this.objectsNew[key].settings.driver_id),
            group: this.objectsNew[key].settings.group_id,
            ign: ign,
            link: link,
            sat: satellite,
            trip_hrs: '10:25'
          })
        })
      } catch (error) {
        this.handleError(error)
      }
      // let getObjectSensorByName = this.getObjectSensorByName;
      // let getSensorValue = this.getSensorValue;

      return tableData
    }
  },
  created () {
    this.$log.debug('TrackingUpperSection.vue created')
  },
  methods: {
    // accepts the value emitted from TrackingTable.vue
    getSelectedObject: function (objectId) {
      // this.$log.debug("getSelectedObject", objectId);
      this.$emit('selected-object', objectId) // emits value to Tracking.vue method getSelectedObject
    }
  }
}
</script>

<style></style>
