<template>
  <v-row>
    <v-col cols="12" md="6" class="d-flex">
      <v-sheet
        dark
        class="pa-1 pt-3 w-100"
        style="overflow: auto; background-color: transparent;"
        :height="contentHeight"
        v-resize="onResize"
      >
        <v-row>
          <v-col cols="12">
            <v-btn
              class="mx-2 float-right"
              dark
              color="#ffa700"
              @click="showFilter = true"
            >
              <v-icon dark left>mdi-format-list-bulleted-square</v-icon>
              Filters
            </v-btn>
            <v-btn
              class="mx-2 float-right"
              dark
              color="#ffa700"
              @click="showRequestFootage = true"
            >
              <v-icon dark left>mdi-video-plus</v-icon>
              Request Footage
            </v-btn>
          </v-col>
          <v-col
            v-for="(event, index) in filteredEventVideos"
            :key="index"
            class="d-flex child-flex"
            cols="12"
            lg="6"
          >
            <!-- @mouseover="showFilter ? '' : loadEventDetails(null, index)"
            @mouseleave="showFilter ? '' : hideEventDetails()" -->
            <v-card color="black" raised outlined>
              <v-card-text>
                <video-player
                  :ref="`videoPlayer-${index}`"
                  :id="`videoPlayer-${index}`"
                  class="vjs-custom-skin"
                  :options="videoOptions(event)"
                  @play="onPlayerPlay($event)"
                ></video-player>
                <v-btn
                  class="m-2"
                  small
                  color="#ffa700"
                  plain
                  @click="
                    event['name'] = event.obj_name
                    event['event_desc'] = ''
                    viewOnMap(event)
                  "
                >
                  View on Map
                  <v-icon right>mdi-map-marker</v-icon>
                </v-btn>

                <v-btn
                  class="m-2"
                  small
                  color="#4bf542"
                  plain
                  @click="selectedVideo(index)"
                >
                  <span>
                    Locate
                  </span>
                  <v-img
                    class="ml-2"
                    max-width="18"
                    :src="publicPath + 'assets/img/icons/locate-green.svg'"
                  ></v-img>
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>

    <v-col class="d-none d-md-flex" md="6">
      <v-sheet
        dark
        class="w-100"
        style="overflow: auto;"
        :height="contentHeight"
        v-resize="onResize"
      >
        <l-map
          :options="mapOptions"
          style="height: 100%;"
          :maxZoom="config.map_max_zoom"
          :minZoom="config.map_min_zoom"
          ref="map"
          @ready="ready"
          :center="defaultCenter"
          :zoom="defaultZoom"
        >
          <TileLayers />
        </l-map>
      </v-sheet>
    </v-col>
    <EventsVideoFilter
      :show="showFilter"
      v-on:show-filter-dialog="setShowFilterModal"
    />
    <EventsVideoRequestFootage
      :show="showRequestFootage"
      v-on:show-request-dialog="setShowRequestFootage"
    />
  </v-row>
</template>

<script>
import { globals } from '@/plugins/global'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import { mapFunctions } from '@/plugins/map'
import EventsVideoFilter from '@/components/events/EventsVideoFilter.vue'
import EventsVideoRequestFootage from '@/components/events/EventsVideoRequestFootage.vue'
import TileLayers from '@/components/includes/TileLayers.vue'
import { LMap, LControl, LPopup, LMarker, LIcon } from 'vue2-leaflet'
import L from 'leaflet'
import { eventMixin } from '@/mixins/eventMixin'
export default {
  mixins: [eventMixin],
  components: {
    LMap,
    EventsVideoFilter,
    EventsVideoRequestFootage,
    TileLayers,
    LControl,
    LIcon,
    LPopup,
    LMarker,
  },
  data: () => ({
    currentEvents: {},
    filteredEventVideos: {},
    showFilter: false,
    showRequestFootage: false,
    filters: {},
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution:
      '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    mapOptions: {
      zoomSnap: 0.5,
    },
    init: true,
    zoom: 15,
    map: null,

    mapLayers: {
      events: {
        markers: {},
      },
    },
    bounds: [],
    eventMarkers: null,
    hoveredEvent: null,
  }),
  computed: {
    ...mapGetters({
      events: 'events/events',
      eventVideos: 'images/eventVideos',
      getObjectSettingsById: 'objects/getObjectSettingsById',
      getDriverName: 'person/getDriverName',
      objectsNew: 'objects/objectsNew',
      getObjectByName: 'objects/getObjectByName',
      server: 'settings/server',
      user: 'settings/user',
      getObjectOdometer: 'objects/getObjectOdometer',
      getObjectEngineHours: 'objects/getObjectEngineHours',
    }),
  },
  watch: {
    eventVideos: {
      deep: true,
      handler() {
        if (!Object.keys(this.filters).length) {
          this.currentEvents = this.filteredEventVideos = this.eventVideos
        }
        this.loadEventMarkers()
      },
    },
  },
  async created() {
    this.onResize()
    clearInterval(this.$timer.objectLoad)
    await Promise.all([this.fetchEventVideos({})])
      .then(() => {})
      .catch((error) => {
        this.handleError(error)
      })
  },
  methods: {
    ...mapActions({
      fetchEventVideos: 'images/fetchEventVideos',
      requestVideoFootage: 'images/requestVideoFootage',
      updateEventId: 'map/updateEventId',
    }),

    videoOptions: function (event) {
      return {
        preload: 'none',
        autoplay: false,
        muted: true,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        controls: true,
        controlBar: {
          timeDivider: false,
          durationDisplay: false,
        },
        sources: [
          {
            // type: "video/mp4",
            src: event.img_file,
          },
        ],
      }
    },
    playVideo: function (player) {
      this.$refs.player.play()
    },
    onPlayerPlay(player) {},
    viewOnMap(event) {
      this.updateEventId(event.event_id)
      this.$router.push({
        name: 'mobile/event/details',
        query: { id: event.img_id, type: 'video' },
      })
    },

    selectedVideo(index) {
      let videoIndeces = Object.keys(this.filteredEventVideos)
      let selectedIndex = videoIndeces.indexOf(index.toString())
      this.loadEventDetails(null, selectedIndex)
    },

    setShowFilterModal(filters) {
      this.filters = filters
      this.showFilter = false

      if (Object.keys(this.filters).length >= 1) {
        // filter

        this.filters.dtf = this.filters.dtf
          ? moment(this.filters.dtf)
              .startOf('day')
              .format('YYYY-MM-DD HH:mm:ss')
          : this.filters.dtf
        this.filters.dtt = this.filters.dtt
          ? moment(this.filters.dtt).endOf('day').format('YYYY-MM-DD HH:mm:ss')
          : this.filters.dtt
        // this.filterEventVideos(this.filters)
        this.fetchEventVideos(this.filters).then((d) => {
          if (Object.keys(d).length) {
            this.currentEvents = this.filteredEventVideos = d

            // this.loadEventMarkers();
          } else {
            this.$notify({
              duration: 5000,
              closeOnClick: false,
              type: 'info',
              title: this.$t('INFORMATION'),
              text: this.$t('NOTHING_HAS_BEEN_FOUND_ON_YOUR_REQUEST'),
            })
          }
        })
      }
    },
    setShowRequestFootage() {
      this.showRequestFootage = false
    },
    changeDisplay($error, index) {
      let videoId = 'videoPlayer-' + index

      console.log(videoId)
      console.log(index)
      console.log(this.$refs[videoId])
      console.log(document.getElementById(videoId))

      let vid = document.getElementById(videoId)
      vid.classList.add('d-none')

      let errorContainer = document.getElementById('errorContainer-' + index)

      errorContainer.classList.remove('d-none')
      errorContainer.classList.add('d-flex')

      // this.$refs[elementId].classList.add('d-none');
    },
  },
}
</script>

<style>
.player {
  /* position: absolute !important; */
  /* width: 100%;
    height: 100%; */
}
.vjs-custom-skin {
  /* > div {
      width: auto;
    } */
  /* height: 100% !important; */
}
.video-js {
  width: inherit;
  height: 225px;
}

.vjs-custom-skin /deep/ .video-js {
  width: inherit;
  height: 225px;
  /* width: inherit; */
  /* height: 100%;
    width: 100% */
}

.leaflet-popup-content {
  width: 280px;
}
.leaflet-popup-content > div {
  display: flex;
  flex-direction: column;
}
</style>
