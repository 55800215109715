import { render, staticRenderFns } from "./TrailersTab.vue?vue&type=template&id=08974159&"
import script from "./TrailersTab.vue?vue&type=script&lang=js&"
export * from "./TrailersTab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Applications/XAMPP/xamppfiles/htdocs/gator-spa/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('08974159')) {
      api.createRecord('08974159', component.options)
    } else {
      api.reload('08974159', component.options)
    }
    module.hot.accept("./TrailersTab.vue?vue&type=template&id=08974159&", function () {
      api.rerender('08974159', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/settings/TrailersTab.vue"
export default component.exports