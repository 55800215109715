import { personService } from '@/services/person'
import Vue from 'vue'

const actions = {
  async fetchDrivers ({ commit }) {
    return await personService
      .getDrivers()
      .then(function (response) {
        Vue.$log.debug('getDrivers', 'success')
        commit('SET_DRIVERS', response.data)
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },

  async saveDriver ({ commit }, params) {
    if (process.env.NODE_ENV === 'production') {
      return await personService.saveDriver(params)
        .then(function (response) {
          Vue.$log.debug('saveDriver', 'success', response)
          return response.data
        })
        .catch(function (error) {
          Vue.$log.debug('saveDriver' + error)
          throw error
        })
    } else {
      if (params.cmd === 'save_object_driver') {
        if (params.driver_id === false) {
          commit('MOCK_DRIVER_ADDITION', params)
        } else {
          commit('MOCK_DRIVER_UPDATE', params)
        }
        return 'OK'
      }
      return ''
    }
  },

  async fetchPassengers ({ commit }) {
    return await personService
      .getPassengers()
      .then(function (response) {
        Vue.$log.debug('getPassengers', 'success')
        commit('SET_PASSENGERS', response.data)
        return response.data
      })
      .catch(function (error) {
        Vue.$log.debug('getPassengers' + error)
        throw error
      })
  },

  async savePassenger ({ commit }, params) {
    if (process.env.NODE_ENV === 'production') {
      return await personService.savePassenger(params)
        .then(function (response) {
          Vue.$log.debug('savePassenger', 'success', response)
          return response.data
        })
        .catch(function (error) {
          Vue.$log.debug('savePassenger' + error)
          throw error
        })
    } else {
      if (params.cmd === 'save_object_passenger') {
        if (params.passenger_id === false) {
          commit('MOCK_PASSENGER_ADDITION', params)
        } else {
          commit('MOCK_PASSENGER_UPDATE', params)
        }
        return { message: 'OK' }
      }
      return ''
    }
  },

  async removeDriver ({ commit }, params) {
    if (process.env.NODE_ENV === 'production') {
      return await personService.removeDriver(params)
        .then(function (response) {
          Vue.$log.debug('removeDriver', 'success', response)
          return response.data
        })
        .catch(function (error) {
          Vue.$log.debug('removeDriver' + error)
          throw error
        })
    } else {
      if (params.cmd === 'delete_object_driver') {
        commit('MOCK_DRIVER_DELETION', params)
      }

      return { message: 'OK' }
    }
  },

  async removePassenger ({ commit }, params) {
    if (process.env.NODE_ENV === 'production') {
      return await personService.removePassenger(params)
        .then(function (response) {
          Vue.$log.debug('removePassenger', 'success', response)
          return response.data
        })
        .catch(function (error) {
          Vue.$log.debug('removePassenger' + error)
          throw error
        })
    } else {
      if (params.cmd === 'delete_object_passenger') {
        commit('MOCK_PASSENGER_DELETION', params)
      }
      return { message: 'OK' }
    }
  },

  async importPassengers ({ commit }, params) {
    if (process.env.NODE_ENV === 'production') {
      return await personService.importPassengers(params)
        .then(function (response) {
          Vue.$log.debug('importPassengers', 'success', response)
          return response.data
        })
        .catch(function (error) {
          Vue.$log.debug('importPassengers' + error)
          throw error
        })
    } else {
      return { message: 'OK' }
    }
  }

}
export default actions
