var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "hide-overlay": "",
        "no-click-animation": "",
        "max-width": "340"
      },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-toolbar",
            { attrs: { dense: "", elevation: "0" } },
            [
              _c("v-toolbar-title", { staticClass: "text-subtitle-2" }, [
                _vm._v("\n        Trigger Information")
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("TriggerHelpInformation", {
                        attrs: { type: _vm.type }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-end",
                      attrs: { cols: "12" }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { small: "", outlined: "", color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("set-next-step", false)
                            }
                          }
                        },
                        [_vm._v("\n            Cancel\n          ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { small: "", color: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("set-next-step", true)
                            }
                          }
                        },
                        [_vm._v("\n            Proceed\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }