import { cmdService } from '@/services/cmd'
import Vue from 'vue'

const actions = {
  async getCmdTemplateUnfilteredData ({ commit }) {
    return await cmdService
      .getCmdTemplateUnfilteredData()
      .then(function (response) {
        Vue.$log.debug('getCmdTemplateUnfilteredData', 'success')

        commit('SET_TEMPLATE_DATA', response.data)
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },
  async getCmdTemplateData ({ commit }, params) {
    return await cmdService
      .getCmdTemplateData(params)
      .then(function (response) {
        Vue.$log.debug('getCmdTemplateData', 'success')

        commit('SET_TEMPLATE_DATA', response.data)
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },

  async sendCommand ({ commit }, params) {
    if (process.env.NODE_ENV === 'development') {
      return { message: 'OK' }
    }

    return await cmdService
      .sendCommand(params)
      .then(function (response) {
        Vue.$log.debug('sendCommand', 'success')
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },
  async getCmdData ({ commit }, params) {
    return await cmdService
      .getCmdData(params)
      .then(function (response) {
        if (params.gateway === 'sms') {
          commit('SET_SMS_DATA', response.data)
        } else {
          commit('SET_GPRS_DATA', response.data)
        }
        Vue.$log.debug('getCmdData', 'success')
      })
      .catch(function (error) {
        throw error
      })
  },

  async getCmdScheduleData ({ commit }) {
    return await cmdService
      .getCmdScheduleData()
      .then(function (response) {
        commit('SET_SCHEDULE_DATA', response.data)
        Vue.$log.debug('getCmdScheduleData', 'success')
      })
      .catch(function (error) {
        throw error
      })
  },

  async deleteCmd ({ commit }, params) {
    if (process.env.NODE_ENV === 'development') {
      return { message: 'OK' }
    }

    return await cmdService
      .deleteCmd(params)
      .then(function (response) {
        Vue.$log.debug('deleteCmd', 'success')
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },

  async saveCmdSchedule ({ commit }, params) {
    if (process.env.NODE_ENV === 'development') {
      return { message: 'OK' }
    }

    return await cmdService
      .saveCmdSchedule(params)
      .then(function (response) {
        Vue.$log.debug('saveCmdSchedule', 'success')
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },
  async deleteCmdSchedule ({ commit }, params) {
    if (process.env.NODE_ENV === 'development') {
      return { message: 'OK' }
    }

    return await cmdService
      .deleteCmdSchedule(params)
      .then(function (response) {
        Vue.$log.debug('deleteCmdSchedule', 'success')
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },

  async deleteCmdTemplate ({ commit }, params) {
    if (process.env.NODE_ENV === 'development') {
      return { message: 'OK' }
    }

    return await cmdService
      .deleteCmdTemplate(params)
      .then(function (response) {
        Vue.$log.debug('deleteCmdTemplate', 'success')
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },

  async saveCmdTemplate ({ commit }, params) {
    if (process.env.NODE_ENV === 'development') {
      return { message: 'OK' }
    }

    return await cmdService
      .saveCmdTemplate(params)
      .then(function (response) {
        Vue.$log.debug('saveCmdTemplate', 'success')
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },

  async loadCmdTemplateDefaults ({ commit }) {
    // console.log("loadCmdTemplateDefaults");
    if (process.env.NODE_ENV === 'development') {
      return { message: 'OK' }
    }

    return await cmdService
      .loadCmdTemplateDefaults()
      .then(function (response) {
        Vue.$log.debug('loadCmdTemplateDefaults', 'success')
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  }
}

export default actions
