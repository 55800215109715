<template>
  <v-data-table
    dense
    :headers="headers"
    :items="tableData"
    :options.sync="options"
    :server-items-length="totalEvents"
    :loading="loading"
    item-key="name"
    class="events-table overflow-y-auto"
    :item-class="selectedRow"
    fixed-header
    mobile-breakpoint="300"
    :height="height"
    :style="'width:' + width + 'px'"
    :footer-props="{'items-per-page-options':[100, 200, 300, 400]}"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    must-sort
  >
    <!-- <template v-slot:top>

    </template> -->

    <template v-slot:top>
            <v-toolbar>
        <template v-slot:default>
          <v-col class="pl-0"
            >
            <v-select
                      color="grey"
                      menu-props="dark"
                      item-color="#272727"
                      v-model="imei"
                      :items="objectItems"
                      label="Filter by Object"
                      item-text="text"
                      item-value="value"
                      hide-details="auto"
                      required
                      solo
                      dense
                      outlined
                      @change="getDataFromApi()"
                      clearable
                    ></v-select>

            </v-col>
          <v-col class="pr-0"
            >

            <v-select
                      color="grey"
                      menu-props="dark"
                      item-color="#272727"
                      v-model="trigger"
                      :items="eventNames"
                      label="Filter by Trigger Name"
                      hide-details="auto"
                      required
                      solo
                      dense
                      outlined
                      @change="getDataFromApi()"
                      clearable
                    ></v-select>
            </v-col>

          <v-col class="flex-shrink-1" cols="2"
            >

           <v-btn  plain outlined @click="trigger = ''; imei = '';">Clear Filters</v-btn>
            </v-col>
        </template>
      </v-toolbar>
      <!-- <v-data-footer
        :pagination="pagination"
        :options="options"
        @update:options="updateOptions"
        items-per-page-text="$vuetify.dataTable.itemsPerPageText"/> -->
    </template>
    <template v-slot:header="">
      <thead v-if="$vuetify.breakpoint.width >= 300">
        <tr>
          <th class="text-align-center">{{ $t("MAP") }}</th>
          <th colspan="2" class="text-align-center">Who</th>
          <th colspan="3" class="text-align-center">Event Details</th>
        </tr>
      </thead>
    </template>
    <template v-slot:header.time="{}">
      {{ $t("TIME") }}
    </template>
    <template v-slot:item.locate="{ item }">
      <v-tooltip bottom color="tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="viewOnMap(item.id)"
            x-small
            icon
            dense
            plain
            v-bind="attrs"
            v-on="on"
            ><v-img
              max-width="18"
              :src="
                publicPath +
                'assets/img/icons/' +
                (item.locate === true ? 'locate-green.svg' : 'locate.svg')
              "
            ></v-img
          ></v-btn>
        </template>
        <span>Click here to view event on map</span>
      </v-tooltip>
    </template>
    <template v-slot:item.device_name="{ item }">
      <v-tooltip bottom color="tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="viewOnMap(item.id)"
            dense
            plain
            v-bind="attrs"
            v-on="on"
            class="caption"
          >
            {{ item.device_name }}
          </v-btn>
        </template>
        <span>Click here to view event on map</span>
      </v-tooltip>
    </template>

    <template v-slot:item.dt_server="{ item }">
      {{ [item.dt_server, "YYYY-MM-DD HH:mm:ss"] | moment("D/M/YYYY HH:mm:ss") }}
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    height: Number,
    width: Number
  },
  data: () => ({
    // search: '',
    // filter1: '',
    // filter2: '',
    sortBy: 'dt_server',
    sortDesc: true, // descending by default
    selectedRowId: '',
    totalEvents: 0,
    tableData: [],
    currentEvents: {},
    loading: true,
    options: {},
    imei: '',
    trigger: ''

  }),
  computed: {
    ...mapGetters({
      getObjectByName: 'objects/getObjectByName',
      getDriverName: 'person/getDriverName',
      objectItems: 'objects/objectItems',
      triggers: 'triggers/triggers',

      eventNames: 'events/eventNames',
      // eventTypes: 'events/eventTypes',

      events: 'events/events'
    }),
    headers: function () {
      return [
        {
          text: 'Locate',
          value: 'locate',
          sortable: false
          // filterable: false
        },
        {
          text: 'Device',
          value: 'device_name',
          class: 'text-center',
          cellClass: 'text-center',
          sortable: false
        },
        {
          text: 'Person',
          value: 'person_name',
          class: 'hidden-md-and-down',
          cellClass: 'hidden-md-and-down',
          sortable: false
          // filterable: !this.$vuetify.breakpoint.mobile
        },
        {
          text: 'Trigger Name',
          value: 'trigger_name',
          sortable: false
        },
        {
          text: 'Date',
          value: 'dt_server'
        }
      ]
    }

  },
  watch: {
    options: {
      handler () {
        this.getDataFromApi()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      updateEventId: 'map/updateEventId',
      fetchEventsByBatch: 'events/fetchEventsByBatch'
    }),
    handleRowClick: function (item) {
      this.selectedRowId = item.id
    },
    selectedRow (item) {
      return this.selectedRowId && item.id === this.selectedRowId
        ? 'active-row'
        : ''
    },
    viewOnMap (id) {
      this.updateEventId(id)
      // this.$router.push({ name: 'mobile/map' })
      this.$router.push({ name: 'mobile/event/details', params: { id: id } })
    },
    async getDataFromApi () {
      try {
        this.loading = true
        const { sortBy, sortDesc, page, itemsPerPage } = this.options

        if (itemsPerPage === -1) {
          this.options.itemsPerPage = 20
        }
        const params = {
          page: page,
          items_per_page: itemsPerPage === -1 ? 20 : itemsPerPage,
          sort_by: sortBy.length ? sortBy[0] : 'dt_server',
          order: sortDesc.length ? sortDesc[0] : true,
          imei: this.imei === null ? '' : this.imei,
          event_desc: this.trigger === null ? '' : this.trigger
        }

        let data

        if (Object.keys(this.events).length >= 1 && params.page === 1 && params.items_per_page === 100 && params.order === true && params.sort_by === 'dt_server' && params.imei === '' && params.event_desc === '') {
          data = this.events
        } else {
          try {
            data = await this.fetchEventsByBatch(params)
          } catch (error) {
            this.handleError(error)
          }
        }

        const items = []
        this.currentEvents = data.events
        for (const [id] of Object.entries(data.events)) {
          const eventData = data.events[id]
          const obj = this.getObjectByName(eventData.name)

          items.push({
            id: id,
            locate: true,
            device_name: eventData.name,
            person_name: obj ? this.getDriverName(obj.settings.driver_id) : '',
            trigger_name: eventData.event_desc,
            dt_server: eventData.dt_server
          })
        }
        this.tableData = items
        this.totalEvents = data.total
        this.loading = false
      } catch (error) {
        this.handleError(error)
      }
    }
  },
  created () {
    // this.fetchEventNames().then(d => {
    //   this.triggerItems = []
    //   Object.values(d).forEach(trigger => {
    //     this.triggerItems.push(trigger)
    //   })
    // }).catch(e => this.handleError(e))
  }
}
</script>

<style></style>
