var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize"
        }
      ],
      attrs: { fluid: "" }
    },
    [
      _c("ObjectsTable", {
        attrs: {
          "table-data": _vm.tableData,
          height: _vm.contentHeight,
          width: _vm.windowSize.x
        },
        on: {
          "show-form": function($event) {
            _vm.showForm = $event
          }
        }
      }),
      _vm._v(" "),
      _c("ObjectsForm", {
        attrs: { show: _vm.showForm, id: _vm.id },
        on: { "show-objects-dialog": _vm.setShowModal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }