<template>
  <tippy
    interactive
    :animate-fill="false"
    placement="bottom"
    distant="7"
    theme="dark"
    animation="scale"
    arrow
  >
    <template v-slot:trigger>
      <slot name="tooltip-trigger"></slot>
    </template>
    <v-container>
      <slot name="tooltip-content"></slot>
    </v-container>
  </tippy>
</template>

<script>
export default {}
</script>

<style>
</style>
