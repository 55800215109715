import Vue from 'vue'

const actions = {

  setMessage ({ commit }, message) {
    Vue.$log.debug('setMessage', message)
    commit('SET_MESSAGE', message)
  },

  clear ({ commit }) {
    commit('SET_MESSAGE', null)
  }

}

export default actions
