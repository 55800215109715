import Vue from 'vue'
import { closestAsset } from '@/plugins/closestAsset'

const mutations = {
  SET_PLACE_GROUPS: (state, data) => (state.place_groups = data),
  SET_PLACE_MARKERS: (state, data) => {
    const markers = data.markers
    const objects = data.objects
    const unitDistance = data.user.unit_distance

    for (const [mkey, marker] of Object.entries(markers)) {
      const markerLat = marker.data.lat
      const markerLng = marker.data.lng
      let closest = null
      let distance = 0
      for (const [key, object] of Object.entries(objects)) {
        const lat = object.data.lat
        const lng = object.data.lng
        const temp = closestAsset.getDistanceFromMarker(
          lat,
          lng,
          markerLat,
          markerLng,
          unitDistance
        )

        if (distance > temp || distance === 0) {
          distance = temp
          closest = key
        }
      }

      markers[mkey].data.visible = JSON.parse(marker.data.visible)

      markers[mkey].locate = false
      if (Object.entries(state.place_markers).length) {
        if (Object.prototype.hasOwnProperty.call(state.place_markers, mkey) && Object.prototype.hasOwnProperty.call(state.place_markers[mkey], 'locate')) {
          markers[mkey].locate = state.place_markers[mkey].locate
        }
      }

      if (Object.entries(state.place_markers).length) {
        if (Object.prototype.hasOwnProperty.call(state.place_markers, mkey) && Object.prototype.hasOwnProperty.call(state.place_markers[mkey], 'visible')) {
          markers[mkey].visible = state.place_markers[mkey].visible
        }
      }

      markers[mkey].closest_asset = {
        distance: distance,
        object: closest
      }
    }
    state.place_markers = {}
    state.place_markers = markers
    Vue.$log.debug(data)
  },
  SET_PLACE_ROUTES: (state, data) => {
    const routes = data.routes
    const objects = data.objects
    const unitDistance = data.user.unit_distance
    for (const [rkey, route] of Object.entries(routes)) {
      let closest = null
      let distance = 0
      for (const [key, object] of Object.entries(objects)) {
        const lat = object.data.lat
        const lng = object.data.lng

        const temp = closestAsset.getDistanceFromZone(
          lat,
          lng,
          route.data.points,
          unitDistance
        )

        if (distance > temp || distance === 0) {
          distance = temp
          closest = key
        }
      }

      routes[rkey].data.visible = JSON.parse(route.data.visible)
      routes[rkey].data.name_visible = JSON.parse(route.data.name_visible)

      routes[rkey].locate = false
      if (Object.entries(state.place_routes).length) {
        if (Object.prototype.hasOwnProperty.call(state.place_routes, rkey) && Object.prototype.hasOwnProperty.call(state.place_routes[rkey], 'locate')) {
          routes[rkey].locate = state.place_routes[rkey].locate
        }
      }

      if (Object.entries(state.place_routes).length) {
        if (Object.prototype.hasOwnProperty.call(state.place_routes, rkey) && Object.prototype.hasOwnProperty.call(state.place_routes[rkey], 'visible')) {
          routes[rkey].visible = state.place_routes[rkey].visible
        }
      }

      routes[rkey].closest_asset = {
        distance: distance,
        object: closest
      }
    }
    state.place_routes = {}
    state.place_routes = routes
  },
  SET_PLACE_ZONES: (state, data) => {
    const zones = data.zones
    const objects = data.objects
    const unitDistance = data.user.unit_distance

    for (const [zkey, zone] of Object.entries(zones)) {
      let closest = null
      let distance = 0
      for (const [key, object] of Object.entries(objects)) {
        const lat = object.data.lat
        const lng = object.data.lng

        const temp = closestAsset.getDistanceFromZone(
          lat,
          lng,
          zone.data.vertices,
          unitDistance
        )

        if (distance > temp || distance === 0) {
          distance = temp
          closest = key
        }
      }
      zones[zkey].data.visible = JSON.parse(zone.data.visible)
      zones[zkey].data.name_visible = JSON.parse(zone.data.name_visible)

      zones[zkey].locate = false
      if (Object.entries(state.place_zones).length) {
        if (Object.prototype.hasOwnProperty.call(state.place_zones, zkey) && Object.prototype.hasOwnProperty.call(state.place_zones[zkey], 'locate')) {
          zones[zkey].locate = state.place_zones[zkey].locate
        }
      }

      if (Object.entries(state.place_zones).length) {
        if (Object.prototype.hasOwnProperty.call(state.place_zones, zkey) && Object.prototype.hasOwnProperty.call(state.place_zones[zkey], 'visible')) {
          zones[zkey].visible = state.place_zones[zkey].visible
        }
      }

      zones[zkey].closest_asset = {
        distance: distance,
        object: closest
      }
    }
    state.place_zones = {}
    state.place_zones = zones
    Vue.$log.debug(state.place_zones)
  },
  SET_PLACE_PROPERTY: (state, params) => {
    const id = params.id
    const prop = params.property_name
    const val = params.value
    const type = params.type // markers, route, zone

    const placeProp = 'place_' + type
    Vue.$log.debug(placeProp)

    const object = state[placeProp]

    // object[id].data[prop] = val
    // if (prop === 'visible') {
    object[id][prop] = val
    // }

    state[placeProp] = []
    state[placeProp] = object
  },

  MOCK_PLACE_ADDITION: (state) => {
    // const fields = data.draft

    const fields = state.place.draft.fields
    const type = state.place.draft.type
    let key = 0
    switch (type) {
      case 'markers': {
        const markerKeys = Object.keys(state.place_markers)
        if (markerKeys.length >= 1) {
          key = markerKeys.reduce((a, b) => { return Math.max(a, b) })
        }
        key = parseInt(key) + 1

        const markers = state.place_markers
        markers[key] = {
          visible: fields.visible,
          data: {
            group_id: fields.group,
            name: fields.name,
            desc: fields.description,
            icon: fields.image,
            visible: fields.visible,
            lat: fields.lat.toFixed(6),
            lng: fields.lng.toFixed(6),
            locate: false
          }
        }
        state.place_markers = []
        state.place_markers = markers
        break
      }

      case 'routes': {
        const routeKeys = Object.keys(state.place_routes)
        if (routeKeys.length >= 1) {
          key = routeKeys.reduce((a, b) => { return Math.max(a, b) })
        }
        key = parseInt(key) + 1

        const routes = state.place_routes
        routes[key] = {
          visible: fields.route_visible,
          data: {
            group_id: fields.group_id,
            name: fields.route_name,
            color: fields.route_color,
            visible: fields.route_visible,
            name_visible: fields.route_name_visible,
            deviation: fields.route_deviation,
            points: fields.route_points
          }
        }
        state.place_routes = []
        state.place_routes = routes

        break
      }

      case 'zones': {
        const zoneKeys = Object.keys(state.place_zones)
        if (zoneKeys.length >= 1) {
          key = zoneKeys.reduce((a, b) => { return Math.max(a, b) })
        }
        key = parseInt(key) + 1

        const zones = state.place_zones
        zones[key] = {
          visible: fields.zone_visible,
          data: {
            group_id: fields.group_id,
            name: fields.zone_name,
            color: fields.zone_color,
            visible: fields.zone_visible,
            name_visible: fields.zone_name_visible,
            area: fields.zone_area,
            vertices: fields.zone_vertices
          }
        }
        state.place_zones = []
        state.place_zones = zones
        break
      }
    }
  },

  MOCK_PLACE_UPDATE: (state) => {
    const fields = state.place.draft.fields
    const type = state.place.draft.type
    Vue.$log.debug('update', fields, type)
    switch (type) {
      case 'markers': {
        const markers = state.place_markers
        markers[fields.id] = {
          visible: fields.visible,
          data: {
            group_id: fields.group,
            name: fields.name,
            desc: fields.description,
            icon: fields.image,
            visible: fields.visible,
            lat: fields.lat.toFixed(6),
            lng: fields.lng.toFixed(6)
          }
        }
        state.place_markers = []
        state.place_markers = markers
        break
      }

      case 'routes': {
        const routes = state.place_routes
        routes[fields.id] = {
          visible: fields.route_visible,
          data: {
            group_id: fields.group_id,
            name: fields.route_name,
            color: fields.route_color,
            visible: fields.route_visible,
            name_visible: fields.route_name_visible,
            deviation: fields.route_deviation,
            points: fields.route_points
          }
        }

        state.place_routes = []
        state.place_routes = routes
        break
      }

      case 'zones': {
        const zones = state.place_zones
        zones[fields.id] = {
          visible: fields.zone_visible,
          data: {
            group_id: fields.group_id,
            name: fields.zone_name,
            color: fields.zone_color,
            visible: fields.zone_visible,
            name_visible: fields.zone_name_visible,
            area: fields.zone_area,
            vertices: fields.zone_vertices
          }
        }

        state.place_zones = []
        state.place_zones = zones
        break
      }
    }
  },

  MOCK_PLACE_DELETION: (state) => {
    const id = state.place.draft.id
    const type = state.place.draft.type
    Vue.$log.debug(state.place.draft.type)
    const placeProp = 'place_' + type
    Vue.$log.debug(placeProp)
    const placePropData = state[placeProp]
    Vue.$log.debug(placePropData)
    delete placePropData[id]
    // placePropData.splice(id, 1)

    state[placeProp] = []
    state[placeProp] = placePropData
    Vue.$log.debug('MOCK_PLACE_DELETION', state[placeProp])
  },
  SET_PLACE_DRAFT: (state, params) => {
    Vue.$log.debug(params)
    const draft = state.place.draft
    Object.keys(params).forEach(prop => {
      if (prop === 'fields') {
        const fields = draft[prop] // fields
        Object.keys(params[prop]).forEach((fieldProp) => {
          fields[fieldProp] = params[prop][fieldProp]
        })
        draft[prop] = fields
      } else {
        draft[prop] = []
        draft[prop] = params[prop]
      }
    })

    Vue.$log.debug(draft)
    state.place.draft = []
    state.place.draft = draft
  },
  CLEAR_PLACE_DRAFT: (state, type) => {
    state.place.draft = {
      action: '',
      type: '',
      fields: {
        // image: 'pin-1.svg'
      },
      id: ''
    }
  }

}

export default mutations
