
const getters = {
  // markers: (state) => state.markers,
  polylines: (state) => state.polylines,
  center: (state) => state.center,

  visibleObjects: (state) => state.visible_objects,
  // zoom: (state) => state.zoom,
  bounds: (state) => state.bounds,
  follow: (state) => state.follow,
  // placeMarkers: (state) => state.place.markers,
  // placeRoutes: (state) => state.place.routes,
  // placeZones: (state) => state.place.zones,
  toggles: (state) => state.toggles,
  // polygons: (state) => state.polygons,
  history: (state) => state.history,
  rawHistory: (state) => state.raw_history,
  eventId: (state) => state.event_id,
  historyRouteData: (state) => state.history_route_data,
  placeMarkerLayer: (state) => state.place_marker_layer,
  historyProgress: (state) => state.history_progress
  // placeDraft: (state) => state.place.draft
}

export default getters
