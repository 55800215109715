import axios from 'axios'

export const gatorService = {
  connect,
  loadDevices
  // getHistoryList,
  // getImgList
}

const requestUrl = process.env.BASE_URL + 'src/assets/json/'

function connect () {
  if (process.env.NODE_ENV === 'development') {
    return axios.get(`${requestUrl}/connect.json`)
  }
  return axios.post('/func/fn_connect.php', { cmd: 'session_check' },
    { baseURL: '' })
}

function loadDevices (params) {
  if (process.env.NODE_ENV === 'development') {
    return axios.get(`${requestUrl}/gator_devices.json`)
  }
  return axios.get('/func/fn_gator.php', params,
    { baseURL: '' })
}
