<template>
  <div class="video">
    <!-- <div>{{ videoContentData }}</div> -->
    <div class="video__content">

        <div>1</div>
        <div>2</div>
        <div>3</div>
        <div>4</div>

    </div>
    <div class="video__actions">
      <div class="border-1--yellow" style="font-size:12px">Live view</div>
      <div class="d-flex flex-wrap live-view">
        <button class="d-flex flex-wrap live-view__buttons">
          <div class="border-1--yellow">1</div>
          <div class="border-1--yellow">2</div>
          <div class="border-1--yellow">3</div>
          <div class="border-1--yellow">4</div>
        </button>
        <button class="d-flex flex-wrap live-view__buttons">
          <div>1</div>
          <div class="border-1--yellow">2</div>
        </button>
        <button class="d-flex flex-wrap live-view__buttons">
          <div>1</div>
          <div class="border-1--yellow">3</div>
        </button>
        <button class="d-flex flex-wrap live-view__buttons">
          <div>1</div>
          <div class="border-1--yellow">4</div>
        </button>
        <button class="live-view__buttons border-1--yellow">
          1
        </button>
      </div>
      <div class="" style="font-size:12px">Playback Mode</div>
      <div class="playback-mode d-flex flex-wrap">
        <button class="playback-mode__buttons border-1--yellow">
          1
        </button>
        <button class="playback-mode__buttons border-1--yellow">
          2
        </button>
        <button class="playback-mode__buttons border-1--yellow">
          3
        </button>
        <button class="playback-mode__buttons border-1--yellow">
          4
        </button>
        <button class="playback-mode__buttons border-1--yellow">5</button>
      </div>
      <div class="" style="font-size:9px">Date start</div>
      <div class="">&nbsp;</div>
      <div class="" style="font-size:9px">Date end</div>
      <div class="">&nbsp;</div>
      <div class="border-1--yellow" style="font-size:12px">
        Search Date Range
      </div>
      <div class="">&nbsp;</div>
      <div class="border-1--yellow" style="font-size:12px">
        Full Screen Viewer
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['videoData']
}
</script>

<style></style>
