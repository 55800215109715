var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$router.currentRoute.name === "forgot"
    ? _c(
        "v-container",
        [
          _c(
            "validation-observer",
            { ref: "forget" },
            [
              _c("p", { staticClass: "text-body-2 error--text" }, [
                _vm._v(_vm._s(_vm.errorMessage))
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    [
                      _c("validation-provider", {
                        attrs: {
                          rules: "required|email",
                          name: _vm.$t("EMAIL")
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var errors = ref.errors
                                return [
                                  _c("v-text-field", {
                                    attrs: {
                                      "error-messages": errors,
                                      placeholder: _vm.$t("EMAIL"),
                                      required: "",
                                      solo: "",
                                      color: "grey",
                                      dense: "",
                                      "hide-details": "auto",
                                      outlined: ""
                                    },
                                    model: {
                                      value: _vm.forgetEmail,
                                      callback: function($$v) {
                                        _vm.forgetEmail = $$v
                                      },
                                      expression: "forgetEmail"
                                    }
                                  })
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3809074843
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                {},
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "w-100",
                          attrs: { color: "primary" },
                          on: { click: _vm.recover }
                        },
                        [_vm._v("Recover")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "w-100",
                          attrs: { plain: "", exact: "", to: "/mobile/login" }
                        },
                        [_vm._v("Go back to login?")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }