import { mapGetters, mapActions } from 'vuex'
export const mapMixin = {
  data () {
    return {
      isFollow: false
    }
  },
  props: {
    tableData: Array,
    selectedObject: String
  },
  computed: {
    ...mapGetters(
      {
      }
    )
  },
  methods: {
    ...mapActions({
      updateMapFollow: 'map/updateMapFollow',
      updateMapVisibleObjects: 'map/updateMapVisibleObjects',
      updateMapBounds: 'map/updateMapBounds',
      updateMapCenter: 'map/updateMapCenter',
      updateMapData: 'map/updateMapData',
      setObjectProperty: 'objects/setObjectProperty',
      fetchObjectData: 'objects/fetchObjectData'
    }),
    setObjectVisibility (id, value) {
      this.setObjectProperty({
        id: id,
        property_name: 'visible',
        value: value
      })
      const imeis = []
      for (const [imei] of Object.entries(this.objectsNew)) {
        const obj = this.objectsNew[imei]
        if (obj.visible) {
          imeis.push(imei)
        }
      }
      this.updateMapVisibleObjects(imeis)
    },
    locateAnObject (id, currentState) {
      this.updateTableData(id, 'locate')
      this.setObjectProperty({
        id: id,
        property_name: 'locate',
        value: !currentState
      })
      const bounds = []
      Object.keys(this.objectsNew).forEach((key) => {
        if (this.objectsNew[key].locate) {
          const lat = this.objectsNew[key].data.lat
          const lng = this.objectsNew[key].data.lng
          bounds.push([lat, lng])
        }
      })
      if (bounds.length > 1) {
        this.updateMapBounds(bounds)
      } else if (bounds.length === 1) {
        this.updateMapCenter(bounds[0])
      }
    },
    followCheckboxChanged (id, value) {
      this.$log.debug('follow', id, value)
      this.setObjectProperty({
        id: id,
        property_name: 'follow',
        value: value // current val
      })

      const imeis = []
      for (const [imei] of Object.entries(this.objectsNew)) {
        const obj = this.objectsNew[imei]
        if (obj.follow) {
          imeis.push(imei)
        }
      }
      this.updateMapFollow(imeis)
      // this.isFollow = (imeis.length >= 1)
    },
    updateTableData (id, prop) {
      this.tableData.forEach((data, index) => {
        if (data.id === id) {
          this.tableData[index][prop] = !data[prop]
        }
      })
    },
    handleRowClick: function (item) {
      this.$log.debug('clicked')
      // this.selectedRowId = item.id;
      this.$emit('selected-object', item.id) // emits value to TrackingUpperSection.vue method getSelectedObject
      Object.keys(this.objectsNew).forEach((key) => {
        let val = false
        if (key === item.id) {
          val = true
        }
        this.setObjectProperty({
          id: key,
          property_name: 'selected',
          value: val
        })
      })

      const lat = this.objectsNew[item.id].data.lat
      const lng = this.objectsNew[item.id].data.lng

      // if (!this.isFollow) {
      this.$log.debug('here')
      this.updateMapCenter([lat, lng])
      // }
      // this.isFollow = false
      // this.$log.debug('map', this.$refs.osm)
    }

  }
}
