
import firebase from 'firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyD4VbrvEyOqfd4inkN7KnyjgH_6OuevVo0',
  authDomain: 'gator-driver.firebaseapp.com',
  databaseURL: 'https://gator-driver-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'gator-driver',
  storageBucket: 'gator-driver.appspot.com',
  messagingSenderId: '224864734676',
  appId: '1:224864734676:web:83d55325a3848458609a41',
  measurementId: 'G-QF2V3RBW3H'
}
firebase.initializeApp(firebaseConfig)
