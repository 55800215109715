import Vue from 'vue'

const mutations = {
  SET_GROUPS: (state, data) => (state.groups = data),
  SET_GROUP_LIST: (state, data) => (state.group_list = data),
  MOCK_GROUP_ADDITION: (state, data) => {
    Vue.$log.debug('add group mock', data)
    const groups = state.groups
    let key

    const groupKeys = Object.keys(groups)
    if (groupKeys.length >= 1) {
      key = groupKeys.reduce((a, b) => { return Math.max(a, b) })
    }
    key = parseInt(key) + 1

    groups[key] = {
      name: data.group_name,
      desc: data.group_desc,
      visible: true,
      follow: false
    }
    state.groups = []
    state.groups = groups
  },
  MOCK_GROUP_UPDATE: (state, data) => {
    Vue.$log.debug('update group mock')

    const groups = state.groups
    groups[data.group_id].name = data.group_name
    groups[data.group_id].desc = data.group_desc
    state.groups = []
    state.groups = groups
  },

  MOCK_GROUP_DELETION: (state, data) => {
    const id = data.group_id
    const groups = state.groups

    delete groups[id]

    state.groups = []
    state.groups = groups
  }

}

export default mutations
