<template>
  <div style="height: calc(100vh - 56px)" class="open-street-map">
    <l-map
      ref="map"
      :options="mapOptions"
      style="height: 100%"
      :maxZoom="config.map_max_zoom"
      :minZoom="config.map_min_zoom"
      @ready="ready"
      :center="defaultCenter"
      :zoom="defaultZoom"
    >
      <TileLayers />

      <l-marker
        ref="marker"
        v-if="event !== null"
        :lat-lng="[event.lat, event.lng]"
        @ready="markerReady"
      >
        <l-icon
          :icon-size="[28, 28]"
          :icon-url="publicPath + 'assets/img/markers/route-event.svg'"
        />

           <!-- offset: [0, -28 * user.map_is], -->
        <l-popup :options="{
           closeOnClick: false,
           autoClose: false
           }">
          <div class="div" v-html="eventTableString">

          </div>
          <v-btn v-if="objectsNew[event.imei].settings.is_video_enabled" @click="requestVideo" color="primary" outlined>Request Footage</v-btn>
        </l-popup>
      </l-marker>

      <l-control position="bottomleft">
        <v-tooltip right color="tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-fab-transition>
              <v-btn
                v-bind="attrs"
                v-on="on"
                elevation="20"
                fab
                color="secondary"
                @click="$router.go(-1)"
                small
              >
                <v-icon>mdi-arrow-left-bold</v-icon>
              </v-btn>
            </v-fab-transition>
          </template>
          <span>Go back?</span>
        </v-tooltip>
      </l-control>
    </l-map>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { globals } from "@/plugins/global";
import { latLng } from "leaflet";
import { LMap, LControl, LPopup, LMarker, LIcon } from "vue2-leaflet";
import TileLayers from "@/components/includes/TileLayers.vue";
export default {
  components: {
    LMap,
    LControl,
    TileLayers,
    LIcon,
    LPopup,
    LMarker,
  },
  // props: {
  //   id: {
  //     default: null,
  //   },
  //   type: {
  //     default: null,
  //   },
  // },
  data() {
    return {
      id: null,
      type: null,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      mapOptions: {
        zoomSnap: 0.5,
      },
      init: true,
      zoom: 15,
      map: null,
      marker: null,
      eventTableString: "",
      eventMarker: null,
      event: null,
    };
  },
  computed: {
    ...mapGetters({
      getObjectOdometer: "objects/getObjectOdometer",
      getObjectEngineHours: "objects/getObjectEngineHours",
      objectsNew: "objects/objectsNew",
      user: "settings/user",
    }),
  },
  methods: {
    ...mapActions({
      fetchEvent: "events/fetchEvent",
      initializeObjectData: "objects/initializeObjectData",
      fetchSiteSettings: "settings/fetchSiteSettings",
      fetchEventVideo: "images/fetchEventVideo",
      requestVideoFootage: "images/requestVideoFootage",
    }),
    ready() {
      this.map = this.$refs.map.mapObject;
      this.map.zoomControl.setPosition("topright");
    },
    markerReady() {
      this.marker = this.$refs.marker.mapObject;
      this.map.setView(this.marker.getLatLng());
      this.marker.openPopup();
    },
    loadEvent() {
      try {
        
        if (this.event !== null) {
          const event = this.event;
          this.$log.debug("set event", event);
          const data = {};
          let sensorArray = Object.keys(
            this.objectsNew[event.imei].settings.sensors
          ).map((k) => this.objectsNew[event.imei].settings.sensors[k]);
          sensorArray = globals.sortArrayByElement(sensorArray, "name");
          let sensorString = "";
          sensorArray.forEach((sensor) => {
            if (sensor.popup === "true") {
              let sensorValue = [];
              if (sensor.type === "fuelsumup") {
                sensorValue = globals.getSensorValueFuelLevelSumUp(
                  event.imei,
                  event.params,
                  sensor
                );
              } else {
                sensorValue = globals.getSensorValue(event.params, sensor);
              }

              sensorString +=
                "<tr><td><strong>" +
                sensor.name +
                ":</strong></td><td>" +
                sensorValue.value_full +
                "</td></tr>";
            }
          });

          const position = globals.urlPosition(event.lat, event.lng);

          var tableString =
            "<table><tr><td><strong>" +
            this.$t("OBJECT") +
            ":</strong></td><td>" +
            event.name +
            "</td></tr><tr><td><strong>" +
            this.$t("EVENT") +
            ":</strong></td><td>" +
            event.event_desc +
            "</td></tr><tr><td><strong>" +
            this.$t("ADDRESS") +
            ":</strong></td><td>" +
            event.location +
            "</td></tr><tr><td><strong>" +
            this.$t("POSITION") +
            ":</strong></td><td>" +
            position +
            "</td></tr><tr><td><strong>" +
            this.$t("ALTITUDE") +
            ":</strong></td><td>" +
            event.altitude +
            " " +
            this.$t("UNIT_HEIGHT") +
            "</td></tr><tr><td><strong>" +
            this.$t("ANGLE") +
            ":</strong></td><td>" +
            event.angle +
            " &deg;</td></tr><tr><td><strong>" +
            this.$t("SPEED") +
            ":</strong></td><td>" +
            event.speed +
            " " +
            this.$t("UNIT_SPEED") +
            "</td></tr><tr><td><strong>" +
            this.$t("TIME") +
            ":</strong></td><td>" +
            this.convertUTCToLocalTimezone(event.dt_server) +
            "</td></tr>";
          const odometer = this.getObjectOdometer(event.imei, event.params);

          if (odometer !== -1) {
            tableString +=
              "<tr><td><strong>" +
              this.$t("ODOMETER") +
              ":</strong></td><td>" +
              odometer +
              " " +
              this.$t("UNIT_DISTANCE") +
              "</td></tr>";
          }
          const engineHours = this.getObjectEngineHours(
            event.imei,
            event.params
          );

          if (engineHours !== -1) {
            tableString +=
              "<tr><td><strong>" +
              this.$t("ENGINE_HOURS") +
              ":</strong></td><td>" +
              engineHours +
              "</td></tr>";
          }
          tableString += sensorString +=
            "<tr><td><strong>" +
            "Manufacturer" +
            ":</strong></td><td>" +
            this.objectsNew[event.imei].settings.manufacturer +
            "</td></tr></table>";

          // let div = '<div><button class="button button2">Request footage</button></div>'

          // tableString += d
          data.latLng = [JSON.parse(event.lat), JSON.parse(event.lng)];
          data.content = tableString;
          this.eventTableString = tableString;
          this.eventMarker = latLng(
            JSON.parse(event.lat),
            JSON.parse(event.lng)
          );

        } else {
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: "error",
            title: this.$t("ERROR"),
            text: this.$t("NO_RECORDS_TO_VIEW"),
          });
        }
      } catch (error) {
        this.$notify({
                    duration: 5000,
                    closeOnClick: false,
          type: "error",
          title: this.$t("ERROR"),
          text: this.$t("NO_RECORDS_TO_VIEW"),
        });
        this.handleError(error);
      }
    },
    requestVideo () {
      this.requestVideoFootage({ imei: this.event.imei, dtf: this.convertToISOFormat(this.event.dt_server), event_id:this.event.event_id }).then((response) => {
        this.$notify({
                    duration: 5000,
                    closeOnClick: false,
          type: "info",
          title: this.$t("INFO"),
          text: response.message,
        });
      });
    },
  },
  async created() {

    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
      try {
        Promise.all([
          this.fetchSiteSettings("load_user_data"),
          this.initializeObjectData(),
        ])
          .then((u, o) => {
            var fetchFn = this.fetchEvent
            var params = { event_id: this.id }

            if (this.type === 'video') {
              fetchFn = this.fetchEventVideo
              params = { img_id: this.id }
            }

            fetchFn(params).then(r => {

              if (this.type === 'video') {
                this.event = Object.values(r).shift()
              } else {
                this.event = r
              }

              if (this.event !== null) {
                this.loadEvent();
              } else {
                this.$notify({
                    duration: 5000,
                    closeOnClick: false,
                  type: "error",
                  title: this.$t("ERROR"),
                  text: this.$t("NO_RECORDS_TO_VIEW"),
                });
              }
              
            })
          })
          .catch((error) => {
            this.handleError(error);
          });
      } catch (error) {
        this.handleError(error);
      }

  },
};
</script>
<style>
.leaflet-popup-content {
  width: 280px;
}
.leaflet-popup-content > div {
    display: flex;
  flex-direction: column;
}
</style>
