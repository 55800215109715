<template>
  <div class="player h-100 fill-height">
    <video-player ref="videoPlayer"
                  class="vjs-custom-skin"
                  :options="playerOptions"
                  @play="onPlayerPlay($event)"
                  @ready="onPlayerReady($event)">
    </video-player>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
  },
  data () {
    return {
      imei: this.$route.params.imei,
      playerOptions: {
        autoplay: true,
        controls: true,
        controlBar: {
          timeDivider: false,
          durationDisplay: false
        }
        // poster: 'https://surmon-china.github.io/vue-quill-editor/static/images/surmon-5.jpg'
      }
    }
  },
  computed: {
    ...mapGetters({
    }),
    player () {
      return this.$refs.videoPlayer.player
    }

  },
  methods: {
    ...mapActions({
      getLiveCam: 'objects/getLiveCam'
    }),
    onPlayerPlay (player) {
      // console.log('player play!', player)
    },
    onPlayerReady (player) {
      // console.log('player ready!', player)
      this.player.play()
    },
    playVideo: function (source) {
      const video = {
        withCredentials: false,
        type: 'application/x-mpegurl',
        src: source
      }
      if (typeof this.player !== 'undefined') {
        this.player.reset() // in IE11 (mode IE10) direct usage of src() when <src> is already set, generated errors,
        this.player.src(video)
        // this.player.load()
        this.player.play()
      }
    }

  },
  async created () {
    // console.log('created')
  },
  mounted () {
    try {
      this.getLiveCam({ imei: this.imei }).then((data) => {
        this.playVideo(data)
      }).catch((err) => this.handleError(err))
    } catch (error) {
      this.handleError(error)
    }
  },
  unmounted () {
    this.$log.debug('unmounted')
  },
  updated () {
    this.$log.debug('updated')
  },
  renderTracked () {
    this.$log.debug('renderTracked')
  },
  deactivated () {
    this.$log.debug('deactivated')
  },
  beforeRouteUpdate (to, from, next) {
    // react to route changes...
    // don't forget to call next()
    this.$log.debug('chnaging route')
  },
  beforeRouteLeave (to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
  }
  // mounted () {
  //   this.follow()
  // }
}
</script>
<style scoped>
  .player {
    position: absolute !important;
    width: 100%;
    height: 100%;
  }
  .vjs-custom-skin {
    height: 100% !important;
  }

  .vjs-custom-skin /deep/ .video-js {
    height: 100%;
    width: 100%
  }
</style>
