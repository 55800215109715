var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "overflow-y-auto",
      staticStyle: { "max-height": "calc(100vh - 56px)" },
      attrs: { fluid: "", id: "scroll-target" }
    },
    [
      _c(
        "v-row",
        {
          staticClass: "ma-1 d-flex flex-column align-content-center",
          staticStyle: { height: "auto" }
        },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "8", xl: "6" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "w-100 mb-3",
                  attrs: { dark: "", outlined: "" }
                },
                [
                  _c(
                    "validation-observer",
                    { ref: "observer" },
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex",
                                  attrs: { cols: "12", sm: "6" }
                                },
                                [
                                  _c("validation-provider", {
                                    staticClass: "w-100",
                                    attrs: {
                                      rules: "required",
                                      name: _vm.$t("MAP_STARTUP_POSITION")
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mb-2 d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-body-2 mr-1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "MAP_STARTUP_POSITION"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("FormTooltip", {
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "tooltip-trigger",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                  color:
                                                                    "primary lighten-1",
                                                                  text: "",
                                                                  icon: ""
                                                                }
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-information-outline"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      },
                                                      {
                                                        key: "tooltip-content",
                                                        fn: function() {
                                                          return [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "MAP_STARTUP_POSITION"
                                                                )
                                                              ) +
                                                                "\n                        "
                                                            ),
                                                            _c(
                                                              "a",
                                                              {
                                                                attrs: {
                                                                  href:
                                                                    "google.com",
                                                                  target:
                                                                    "_blank"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "test link hey"
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("v-select", {
                                              attrs: {
                                                color: "grey",
                                                "menu-props": "dark",
                                                "item-color": "#272727",
                                                items:
                                                  _vm.options.startupPosition,
                                                label: _vm.$t(
                                                  "MAP_STARTUP_POSITION"
                                                ),
                                                "item-text": "text",
                                                "item-value": "value",
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                required: "",
                                                solo: "",
                                                dense: "",
                                                outlined: ""
                                              },
                                              model: {
                                                value: _vm.fields.position,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.fields,
                                                    "position",
                                                    $$v
                                                  )
                                                },
                                                expression: "fields.position"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex",
                                  attrs: { cols: "12", sm: "6" }
                                },
                                [
                                  _c("validation-provider", {
                                    staticClass: "w-100",
                                    attrs: {
                                      rules: "required",
                                      name: _vm.$t("MAP_ICON_SIZE")
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mb-2 d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-body-2 mr-1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("MAP_ICON_SIZE")
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("FormTooltip", {
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "tooltip-trigger",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                  color:
                                                                    "primary lighten-1",
                                                                  text: "",
                                                                  icon: ""
                                                                }
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-information-outline"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      },
                                                      {
                                                        key: "tooltip-content",
                                                        fn: function() {
                                                          return [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "MAP_ICON_SIZE"
                                                                )
                                                              ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("v-select", {
                                              attrs: {
                                                color: "grey",
                                                "menu-props": "dark",
                                                "item-color": "#272727",
                                                items: _vm.options.size,
                                                label: _vm.$t("MAP_ICON_SIZE"),
                                                "item-text": "text",
                                                "item-value": "value",
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                required: "",
                                                solo: "",
                                                dense: "",
                                                outlined: ""
                                              },
                                              model: {
                                                value: _vm.fields.size,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.fields,
                                                    "size",
                                                    $$v
                                                  )
                                                },
                                                expression: "fields.size"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex",
                                  attrs: { cols: "12", sm: "6" }
                                },
                                [
                                  _c("validation-provider", {
                                    staticClass: "w-100",
                                    attrs: {
                                      rules: "required",
                                      name: _vm.$t("LANGUAGE")
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mb-2 d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-body-2 mr-1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("LANGUAGE"))
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("FormTooltip", {
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "tooltip-trigger",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                  color:
                                                                    "primary lighten-1",
                                                                  text: "",
                                                                  icon: ""
                                                                }
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-information-outline"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      },
                                                      {
                                                        key: "tooltip-content",
                                                        fn: function() {
                                                          return [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "LANGUAGE"
                                                                )
                                                              ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("v-select", {
                                              attrs: {
                                                color: "grey",
                                                "menu-props": "dark",
                                                "item-color": "#272727",
                                                items: _vm.options.language,
                                                label: _vm.$t("LANGUAGE"),
                                                "item-text": "text",
                                                "item-value": "value",
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                required: "",
                                                solo: "",
                                                dense: "",
                                                outlined: ""
                                              },
                                              model: {
                                                value: _vm.fields.language,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.fields,
                                                    "language",
                                                    $$v
                                                  )
                                                },
                                                expression: "fields.language"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex",
                                  attrs: { cols: "12", sm: "6" }
                                },
                                [
                                  _c("validation-provider", {
                                    staticClass: "w-100",
                                    attrs: {
                                      rules: "required",
                                      name: _vm.$t("UNIT_OF_DISTANCE")
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mb-2 d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-body-2 mr-1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "UNIT_OF_DISTANCE"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("FormTooltip", {
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "tooltip-trigger",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                  color:
                                                                    "primary lighten-1",
                                                                  text: "",
                                                                  icon: ""
                                                                }
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-information-outline"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      },
                                                      {
                                                        key: "tooltip-content",
                                                        fn: function() {
                                                          return [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "UNIT_OF_DISTANCE"
                                                                )
                                                              ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("v-select", {
                                              attrs: {
                                                color: "grey",
                                                "menu-props": "dark",
                                                "item-color": "#272727",
                                                items: _vm.options.distance,
                                                label: _vm.$t(
                                                  "UNIT_OF_DISTANCE"
                                                ),
                                                "item-text": "text",
                                                "item-value": "value",
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                required: "",
                                                solo: "",
                                                dense: "",
                                                outlined: ""
                                              },
                                              model: {
                                                value: _vm.fields.distance,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.fields,
                                                    "distance",
                                                    $$v
                                                  )
                                                },
                                                expression: "fields.distance"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex",
                                  attrs: { cols: "12", sm: "6" }
                                },
                                [
                                  _c("validation-provider", {
                                    staticClass: "w-100",
                                    attrs: {
                                      rules: "required",
                                      name: _vm.$t("UNIT_OF_CAPACITY")
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mb-2 d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-body-2 mr-1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "UNIT_OF_CAPACITY"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("FormTooltip", {
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "tooltip-trigger",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                  color:
                                                                    "primary lighten-1",
                                                                  text: "",
                                                                  icon: ""
                                                                }
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-information-outline"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      },
                                                      {
                                                        key: "tooltip-content",
                                                        fn: function() {
                                                          return [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "UNIT_OF_CAPACITY"
                                                                )
                                                              ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("v-select", {
                                              attrs: {
                                                color: "grey",
                                                "menu-props": "dark",
                                                "item-color": "#272727",
                                                items: _vm.options.capacity,
                                                label: _vm.$t(
                                                  "UNIT_OF_CAPACITY"
                                                ),
                                                "item-text": "text",
                                                "item-value": "value",
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                required: "",
                                                solo: "",
                                                dense: "",
                                                outlined: ""
                                              },
                                              model: {
                                                value: _vm.fields.capacity,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.fields,
                                                    "capacity",
                                                    $$v
                                                  )
                                                },
                                                expression: "fields.capacity"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex",
                                  attrs: { cols: "12", sm: "6" }
                                },
                                [
                                  _c("validation-provider", {
                                    staticClass: "w-100",
                                    attrs: {
                                      rules: "required",
                                      name: _vm.$t("UNIT_OF_TEMPERATURE")
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mb-2 d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-body-2 mr-1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "UNIT_OF_TEMPERATURE"
                                                        )
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("FormTooltip", {
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "tooltip-trigger",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                  color:
                                                                    "primary lighten-1",
                                                                  text: "",
                                                                  icon: ""
                                                                }
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-information-outline"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      },
                                                      {
                                                        key: "tooltip-content",
                                                        fn: function() {
                                                          return [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "UNIT_OF_TEMPERATURE"
                                                                )
                                                              ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("v-select", {
                                              attrs: {
                                                color: "grey",
                                                "menu-props": "dark",
                                                "item-color": "#272727",
                                                items: _vm.options.temperature,
                                                label: _vm.$t(
                                                  "UNIT_OF_TEMPERATURE"
                                                ),
                                                "item-text": "text",
                                                "item-value": "value",
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                required: "",
                                                solo: "",
                                                dense: "",
                                                outlined: ""
                                              },
                                              model: {
                                                value: _vm.fields.temperature,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.fields,
                                                    "temperature",
                                                    $$v
                                                  )
                                                },
                                                expression: "fields.temperature"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex",
                                  attrs: { cols: "12", sm: "6" }
                                },
                                [
                                  _c("validation-provider", {
                                    staticClass: "w-100",
                                    attrs: {
                                      rules: "required",
                                      name: _vm.$t("TIMEZONE")
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mb-2 d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-body-2 mr-1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("TIMEZONE"))
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("FormTooltip", {
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "tooltip-trigger",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                  color:
                                                                    "primary lighten-1",
                                                                  text: "",
                                                                  icon: ""
                                                                }
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-information-outline"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      },
                                                      {
                                                        key: "tooltip-content",
                                                        fn: function() {
                                                          return [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "TIMEZONE"
                                                                )
                                                              ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("v-select", {
                                              attrs: {
                                                color: "grey",
                                                "menu-props": "dark",
                                                "item-color": "#272727",
                                                items: _vm.options.timezone,
                                                label: _vm.$t("TIMEZONE"),
                                                "item-text": "text",
                                                "item-value": "value",
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                required: "",
                                                solo: "",
                                                dense: "",
                                                outlined: ""
                                              },
                                              model: {
                                                value: _vm.fields.timezone,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.fields,
                                                    "timezone",
                                                    $$v
                                                  )
                                                },
                                                expression: "fields.timezone"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex",
                                  attrs: { cols: "12", sm: "6" }
                                },
                                [
                                  _c("validation-provider", {
                                    staticClass: "w-100",
                                    attrs: {
                                      rules: "required",
                                      name: _vm.$t("MAP")
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mb-2 d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-body-2 mr-1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("MAP"))
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("FormTooltip", {
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "tooltip-trigger",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                  color:
                                                                    "primary lighten-1",
                                                                  text: "",
                                                                  icon: ""
                                                                }
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-information-outline"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      },
                                                      {
                                                        key: "tooltip-content",
                                                        fn: function() {
                                                          return [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t("MAP")
                                                              ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("v-select", {
                                              attrs: {
                                                color: "grey",
                                                "menu-props": "dark",
                                                "item-color": "#272727",
                                                items: _vm.mapList,
                                                label: _vm.$t("MAP"),
                                                "item-text": "name",
                                                "item-value": "value",
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                required: "",
                                                solo: "",
                                                dense: "",
                                                outlined: ""
                                              },
                                              model: {
                                                value: _vm.fields.map,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.fields,
                                                    "map",
                                                    $$v
                                                  )
                                                },
                                                expression: "fields.map"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "8", xl: "6" } },
            [
              _c(
                "v-row",
                {
                  staticClass:
                    "\n          justify-center\n          align-content-center\n          d-flex\n          flex-column flex-sm-row\n        "
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-sm-2 mb-2",
                      attrs: { color: "primary", outlined: "", width: "200px" },
                      on: {
                        click: function($event) {
                          return _vm.reset()
                        }
                      }
                    },
                    [_vm._v("Reset")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", width: "200px" },
                      on: {
                        click: function($event) {
                          return _vm.save()
                        }
                      }
                    },
                    [_vm._v("Save")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }