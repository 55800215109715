import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  // places: [],
  // place_marker_list: [],
  // place_group_list: [],
  // place_route_list: [],
  // place_zone_list: [],
  place_markers: {},
  place_groups: {},
  place_routes: {},
  place_zones: {},
  /* places_data is used for map, adding layers, and settings that are only changeable thru front end and no backend effect like locate */
  places_data: {
    markers: {},
    routes: {},
    zones: {}
  },
  place: {
    draft: {
      action: '',
      type: '',
      fields: {
        // image: 'img/markers/places/pin-1.svg',
        id: ''
      }
    }
  }
  // place_layers: {
  //   markers: {},
  //   routes: {},
  //   zones: {},
  // },

  // place_marker_layers: [],

}

export default {
  state,
  getters,
  actions,
  mutations
}
