var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "validation-observer",
        { ref: "observer" },
        [
          _c("p", { staticClass: "text-body-2 error--text" }, [
            _vm._v(_vm._s(_vm.errorMessage))
          ]),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("validation-provider", {
                    attrs: { rules: "required|email", name: _vm.$t("EMAIL") },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("v-text-field", {
                              attrs: {
                                "error-messages": errors,
                                label: _vm.$t("EMAIL"),
                                placeholder: _vm.$t("EMAIL"),
                                required: "",
                                solo: "",
                                color: "grey",
                                dense: "",
                                "hide-details": "auto",
                                outlined: ""
                              },
                              model: {
                                value: _vm.loginFields.username,
                                callback: function($$v) {
                                  _vm.$set(_vm.loginFields, "username", $$v)
                                },
                                expression: "loginFields.username"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("validation-provider", {
                    attrs: { rules: "required", name: _vm.$t("PASSWORD") },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("v-text-field", {
                              attrs: {
                                type: "password",
                                "error-messages": errors,
                                label: _vm.$t("PASSWORD"),
                                placeholder: _vm.$t("PASSWORD"),
                                required: "",
                                solo: "",
                                color: "grey",
                                dense: "",
                                "hide-details": "auto",
                                outlined: ""
                              },
                              model: {
                                value: _vm.loginFields.password,
                                callback: function($$v) {
                                  _vm.$set(_vm.loginFields, "password", $$v)
                                },
                                expression: "loginFields.password"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {},
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "w-100",
                      attrs: { color: "primary" },
                      on: { click: _vm.login }
                    },
                    [_vm._v(_vm._s(_vm.$t("LOGIN")))]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "w-100",
                      attrs: { plain: "", exact: "", to: "/mobile/forgot" }
                    },
                    [_vm._v("Forgot Password?")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }