<template>
  <div class="tab-content">
    <div class="container--fluid upper-content">
    <div class="upper-content__table">
      <ReportsTable :table-data="reportsData" v-if="reportsData" />
    </div>
    <!-- <div class="upper-content__action">
      <div>
        <label for="" class="pr-5">Filter:</label>
        <input type="text" name="first-filter" />
        <label for="" class="px-5">2nd Filter</label>
        <input type="text" name="second-filter" />
      </div>
    </div> -->
  </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ReportsTable from '@/components/reports/ReportsTable.vue'

export default {
  components: {
    ReportsTable
  },
  methods: {
    ...mapActions({ fetchReports: 'reports/fetchReports' })
  },
  computed: {
    ...mapGetters({
      reports: 'reports/reports',
      getReportType: 'reports/getReportType',
      getReportSchedule: 'reports/getReportSchedule'
    }),
    reportsData: function () {
      const tableData = []
      try {
        const reports = this.reports

        for (const [key, report] of Object.entries(reports)) {
          tableData.push({
            id: key,
            report_name: report.name,
            report_type: this.getReportType(report.type),
            schedule: this.getReportSchedule(report.schedule_period),
            period: ''
          })
        }
      } catch (error) {
        this.handleError(error)
      }

      return tableData
    }
  },
  created () {
    try {
      this.$log.debug('created')
      this.fetchReports()
    } catch (error) {
      this.handleError(error)
    }
  }
}
</script>

<style></style>
