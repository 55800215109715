var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("AlertMessage"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-row fill-height justify-center align-center",
          style:
            "background-image:url(" +
            _vm.publicPath +
            "assets/img/Gator_dot_net_Back_drop_ds_2x_uazvwp_c_scale,w_3198.png)",
          attrs: { id: "login" }
        },
        [
          _c(
            "v-card",
            { attrs: { height: "70%", width: "80%" } },
            [
              _c(
                "v-container",
                {
                  staticClass: "fill-height",
                  staticStyle: { "max-width": "100%" }
                },
                [
                  _c(
                    "v-row",
                    { staticClass: "fill-height pa-0" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "d-none d-md-block",
                          style:
                            "background-image:url(" +
                            _vm.publicPath +
                            "assets/img/Gator_dot_net_Back_drop_ds_2x_uazvwp_c_scale,w_3198.png)",
                          attrs: { id: "login-layout" }
                        },
                        [
                          _c(
                            "v-carousel",
                            {
                              staticClass: "fill-height",
                              attrs: {
                                "show-arrows": false,
                                cycle: "",
                                height: "100%"
                              }
                            },
                            [
                              _c(
                                "v-carousel-item",
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "fill-height",
                                      attrs: {
                                        align: "center",
                                        justify: "center"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        { attrs: { dark: "" } },
                                        [
                                          _c(
                                            "v-card-title",
                                            {
                                              staticClass: "text-h5",
                                              attrs: { color: "primary" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      Gator Tracking Updates\n                    "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("v-card-subtitle", [
                                            _vm._v(
                                              "New Gator GKTPRO2 4G LTE GPS Tracker is now\n                      available"
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    text: "",
                                                    color: "primary"
                                                  }
                                                },
                                                [_vm._v(" Check it out here ")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-carousel-item",
                                [
                                  _c(
                                    "v-row",
                                    {
                                      staticClass: "fill-height",
                                      attrs: {
                                        align: "center",
                                        justify: "center"
                                      }
                                    },
                                    [
                                      _c("iframe", {
                                        attrs: {
                                          width: "100%",
                                          height: "300px",
                                          src:
                                            "https://www.youtube.com/embed/wkK6e_VilKY",
                                          title: "YouTube video player",
                                          frameborder: "0",
                                          allow:
                                            "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                                          allowfullscreen: ""
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-container",
                            {
                              staticClass: "form-container",
                              staticStyle: { display: "contents" }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  staticClass: "fill-height",
                                  attrs: { align: "center", justify: "center" }
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "mx-auto",
                                      attrs: { flat: "", width: "auto" }
                                    },
                                    [
                                      _c("v-img", {
                                        staticClass: "mx-auto my-3",
                                        attrs: {
                                          "max-width": "150",
                                          src:
                                            _vm.publicPath +
                                            "assets/img/icons/gator-logo-orange.png"
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.$router.currentRoute.name ===
                                        "login" ||
                                      _vm.$router.currentRoute.name ===
                                        "register"
                                        ? _c(
                                            "v-tabs",
                                            [
                                              _c(
                                                "v-tab",
                                                {
                                                  attrs: {
                                                    exact: "",
                                                    to: "/mobile/login"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("LOGIN"))
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-tab",
                                                {
                                                  attrs: {
                                                    exact: "",
                                                    to: "/mobile/register"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.$t("REGISTER"))
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("router-view")
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }