import { driversService } from '@/services/drivers'
import Vue from 'vue'

const actions = {
  // async fetchDrivers ({ commit }) {
  //   return await personService
  //     .getDrivers()
  //     .then(function (response) {
  //       Vue.$log.debug('getDrivers', 'success')
  //       commit('SET_DRIVERS', response.data)
  //       return response.data
  //     })
  //     .catch(function (error) {
  //       throw error
  //     })
  // },

  async inviteDriver ({ commit }, params) {
    if (process.env.NODE_ENV === 'production') {
      return await driversService.inviteDriver(params)
        .then(function (response) {
          Vue.$log.debug('inviteDriver', 'success', response)
          return response.data
        })
        .catch(function (error) {
          Vue.$log.debug('inviteDriver' + error)
          throw error
        })
    } else {
      return { message: 'OK' }
    }
  }

}
export default actions
