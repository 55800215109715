import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  // objects: [],
  // object_list: [],
  // object_settings: [],
  objects_new: {},
  object_layers: {
    markers: {},
    tails: {}
  },
  history: {
    filter: null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
