var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "devices-table",
        attrs: {
          headers: _vm.headers,
          items: _vm.filteredTableData,
          "item-key": "id",
          "group-by": "group",
          "item-class": _vm.selectedRow,
          "fixed-header": "",
          dark: "",
          height: "100%",
          "items-per-page": -1,
          "hide-default-footer": "",
          expanded: _vm.expanded,
          "show-expand": ""
        },
        on: {
          "update:expanded": function($event) {
            _vm.expanded = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c("v-toolbar", {
                  attrs: { extended: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function() {
                        return [
                          _c(
                            "v-btn",
                            {
                              staticClass: "border-1--yellow",
                              attrs: { small: "", width: "100" },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("show-form", true)
                                }
                              }
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v(" mdi-plus ")
                              ]),
                              _vm._v("\n          Add\n        ")
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "extension",
                      fn: function() {
                        return [
                          _c(
                            "v-col",
                            { staticClass: "pl-0" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  solo: "",
                                  dense: "",
                                  "single-line": "",
                                  "hide-details": "",
                                  clearable: "",
                                  outlined: "",
                                  "prepend-inner-icon": "mdi-magnify",
                                  label: "Filter",
                                  color: "grey"
                                },
                                model: {
                                  value: _vm.filter1,
                                  callback: function($$v) {
                                    _vm.filter1 = $$v
                                  },
                                  expression: "filter1"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "pr-0" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  solo: "",
                                  dense: "",
                                  "single-line": "",
                                  "hide-details": "",
                                  clearable: "",
                                  outlined: "",
                                  "prepend-inner-icon": "mdi-magnify",
                                  label: "2nd Filter",
                                  color: "grey"
                                },
                                model: {
                                  value: _vm.filter2,
                                  callback: function($$v) {
                                    _vm.filter2 = $$v
                                  },
                                  expression: "filter2"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ]
            },
            proxy: true
          },
          {
            key: "header",
            fn: function() {
              return [
                _c("thead", [
                  _c("tr", [
                    _c(
                      "th",
                      {
                        staticClass: "text-align-center",
                        attrs: { colspan: "3" }
                      },
                      [_vm._v(_vm._s(_vm.$t("MAP")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass: "text-align-center",
                        attrs: { colspan: "2" }
                      },
                      [_vm._v("Tracker")]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass: "text-align-center hidden-md-and-down",
                        attrs: { colspan: "6" }
                      },
                      [_vm._v("\n          Metrics\n        ")]
                    )
                  ])
                ])
              ]
            },
            proxy: true
          },
          {
            key: "group.header",
            fn: function(ref) {
              var items = ref.items
              var isOpen = ref.isOpen
              var toggle = ref.toggle
              return [
                _c(
                  "th",
                  {
                    staticClass: "group-header",
                    attrs: { colspan: "11" },
                    on: { click: toggle }
                  },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.getGroupName(items[0].group)) +
                        "\n    "
                    )
                  ]
                )
              ]
            }
          },
          {
            key: "header.follow",
            fn: function(ref) {
              return [_vm._v("\n    " + _vm._s(_vm.$t("FOLLOW")) + "\n  ")]
            }
          },
          {
            key: "header.speed",
            fn: function(ref) {
              return [_vm._v("\n    " + _vm._s(_vm.$t("SPEED")) + "\n  ")]
            }
          },
          {
            key: "header.odometer",
            fn: function(ref) {
              return [_vm._v("\n    " + _vm._s(_vm.$t("ODOMETER")) + "\n  ")]
            }
          },
          {
            key: "item.locate",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "", color: "tooltip" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        dense: "",
                                        plain: "",
                                        "x-small": "",
                                        icon: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.locateAnObject(
                                            item.id,
                                            item.locate
                                          )
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-img", {
                                    staticClass: "mr-1",
                                    attrs: {
                                      "max-width": "18",
                                      src:
                                        _vm.publicPath +
                                        "assets/img/icons/" +
                                        (item.locate === true
                                          ? "locate-green.svg"
                                          : "locate.svg")
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _vm._v(" "),
                    _c("span", [_vm._v("Click here to locate object")])
                  ]
                )
              ]
            }
          },
          {
            key: "item.visible",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("v-checkbox", {
                  attrs: { dense: "" },
                  on: {
                    change: function($event) {
                      return _vm.setObjectVisibility(item.id, item.visible)
                    }
                  },
                  model: {
                    value: item.visible,
                    callback: function($$v) {
                      _vm.$set(item, "visible", $$v)
                    },
                    expression: "item.visible"
                  }
                })
              ]
            }
          },
          {
            key: "item.follow",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("v-checkbox", {
                  attrs: { dense: "" },
                  on: {
                    change: function($event) {
                      return _vm.followCheckboxChanged(item.id, item.follow)
                    }
                  },
                  model: {
                    value: item.follow,
                    callback: function($$v) {
                      _vm.$set(item, "follow", $$v)
                    },
                    expression: "item.follow"
                  }
                })
              ]
            }
          },
          {
            key: "item.device_name",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "", color: "tooltip" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "div",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "cursor-pointer",
                                      on: {
                                        click: function($event) {
                                          return _vm.handleRowClick(item)
                                        }
                                      }
                                    },
                                    "div",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(item.device_name) +
                                      "\n        "
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _vm._v(" "),
                    _c("span", [_vm._v("Click here to locate object")])
                  ]
                )
              ]
            }
          },
          {
            key: "item.ign",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "", color: "tooltip" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "div",
                                _vm._g(
                                  _vm._b(
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.handleRowClick(item)
                                        }
                                      }
                                    },
                                    "div",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-img", {
                                    staticClass: "mr-1",
                                    attrs: {
                                      "max-width": "18",
                                      src:
                                        _vm.publicPath +
                                        "assets/img/icons/" +
                                        (item.ign === "On"
                                          ? "ignition-indicator-red.svg"
                                          : "ignition-indicator.svg")
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _vm._v(" "),
                    _c("span", [_vm._v("Click here to locate object")])
                  ]
                )
              ]
            }
          },
          {
            key: "item.link",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "", color: "tooltip" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "div",
                                _vm._g(
                                  _vm._b(
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.handleRowClick(item)
                                        }
                                      }
                                    },
                                    "div",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("TheLinkBar", {
                                    attrs: { signal: item.link }
                                  })
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _vm._v(" "),
                    _c("span", [_vm._v("Click here to locate object")])
                  ]
                )
              ]
            }
          },
          {
            key: "item.actions",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-menu",
                  {
                    attrs: { "offset-y": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        "x-small": "",
                                        icon: "",
                                        dense: "",
                                        plain: ""
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "", dense: "" } },
                                    [_vm._v("mdi-settings-outline")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _vm._v(" "),
                    _c(
                      "v-list",
                      { attrs: { dense: "" } },
                      [
                        _c(
                          "v-list-item",
                          {
                            attrs: { dense: "" },
                            on: {
                              click: function($event) {
                                return _vm.followCheckboxChanged(
                                  item.id,
                                  !item.follow
                                )
                              }
                            }
                          },
                          [
                            _c("v-list-item-title", { attrs: { dense: "" } }, [
                              _vm._v("\n            Follow\n          ")
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-list-item",
                          { attrs: { dense: "" } },
                          [
                            _c(
                              "v-list-item-title",
                              { attrs: { dense: "" } },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "follow",
                                        params: { imei: item.id }
                                      },
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v("Follow (New Window)")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-list-item",
                          { attrs: { dense: "" } },
                          [
                            _c(
                              "v-list-item-title",
                              { attrs: { dense: "" } },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "live",
                                        params: { imei: item.id }
                                      }
                                    }
                                  },
                                  [_vm._v("Live View")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-list-item",
                          {
                            attrs: { dense: "" },
                            on: {
                              click: function($event) {
                                return _vm.showObjectControlDialog(item.id)
                              }
                            }
                          },
                          [
                            _c("v-list-item-title", { attrs: { dense: "" } }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("SEND_COMMAND")) +
                                  "\n          "
                              )
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          },
          {
            key: "expanded-item",
            fn: function(ref) {
              var headers = ref.headers
              var item = ref.item
              return [
                _c("td", { attrs: { colspan: "8" } }, [
                  _c("div", { staticClass: "d-flex flex-row py-2" }, [
                    _c("div", { staticClass: "d-flex align-center mr-3" }, [
                      _c(
                        "div",
                        {},
                        [
                          _c("v-img", {
                            attrs: {
                              "max-width": "50",
                              src:
                                _vm.publicPath +
                                "assets/" +
                                _vm.getObjectSettingsById(item.id)["icon"]
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", {}, [
                      _c("table", [
                        _c("tr", [
                          _c("th", { attrs: { align: "left" } }, [
                            _vm._v("Group Name:")
                          ]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(item.group_name))])
                        ]),
                        _vm._v(" "),
                        _vm.$vuetify.breakpoint.lgAndDown
                          ? _c("tr", [
                              _c("th", { attrs: { align: "left" } }, [
                                _vm._v("Ign:")
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c("v-img", {
                                    attrs: {
                                      "max-width": "18",
                                      src:
                                        _vm.publicPath +
                                        "assets/img/icons/" +
                                        (item.ign == "On"
                                          ? "ignition-indicator-red.svg"
                                          : "ignition-indicator.svg")
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.$vuetify.breakpoint.lgAndDown
                          ? _c("tr", [
                              _c("th", { attrs: { align: "left" } }, [
                                _vm._v("Link:")
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c("TheLinkBar", {
                                    attrs: { signal: item.link }
                                  })
                                ],
                                1
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("tr", [
                          _c("th", { attrs: { align: "left" } }, [
                            _vm._v("Sat:")
                          ]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(item.sat))])
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("th", { attrs: { align: "left" } }, [
                            _vm._v("Speed:")
                          ]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(item.speed))])
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("th", { attrs: { align: "left" } }, [
                            _vm._v("Odometer:")
                          ]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(item.odometer))])
                        ])
                      ])
                    ])
                  ])
                ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("ObjectControl", {
        attrs: { show: _vm.showObjectControl, id: _vm.id },
        on: { "show-object-control-dialog": _vm.setShowObjectControl }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }