<template>
  <div class="h-100">
     <!-- <v-progress-linear
      indeterminate
    ></v-progress-linear> -->
    <!-- <v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular> -->
    <!-- <v-progress-linear
      v-if="$progress.show"
      :value="$progress.value"
     :indeterminate="value >= 1"
    ></v-progress-linear>
    {{$progress.show}} -->
     <!-- <v-progress-linear
      v-model="value"
      :buffer-value="bufferValue"
      color="red lighten-2"
    ></v-progress-linear> -->
    <OpenStreetMap
      v-on:show-progress="startBuffer()"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import OpenStreetMap from '@/components/maps/OpenStreetMap.vue'
export default {
  components: {
    OpenStreetMap
  },
  data () {
    return {
      value: 10,
      bufferValue: 20,
      interval: 0
    }
  },
  computed: {
    ...mapGetters({

      historyProgress: 'map/historyProgress'
    })
  },
  watch: {

    value: function () {
      this.$log.debug(this.value)
    },
    showProgress: function () {
      this.$log.debug(this.showProgress)
    }
  },
  methods: {
    ...mapActions({

      setHistory: 'map/setHistory',
      setHistoryProgress: 'map/setHistoryProgress'
    }),
    startBuffer () {
      this.$log.debug('start buffer enabled on Map.vue')
      clearInterval(this.interval)

      this.interval = setInterval(() => {
        this.value += Math.random() * (15 - 5) + 5
        this.bufferValue += Math.random() * (15 - 5) + 6
      }, 2000)
    }
  },
  created () {
    this.$log.debug('mobile map')
    // this.startBuffer();
  }
}
</script>

<style>
</style>
