var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticStyle: { padding: "0" }, attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [_vm._t("dashboard-table-header")],
        2
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            { attrs: { lg: "6", md: "6", sm: "12" } },
            [_vm._t("active-tracker-table")],
            2
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { lg: "6", md: "6", sm: "12" } },
            [_vm._t("active-driver-table")],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }