var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "hide-overlay": "",
        "no-click-animation": "",
        "max-width": "370",
        width: "auto"
      },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-toolbar",
            { attrs: { color: "", dense: "", elevation: "0" } },
            [
              _c("v-toolbar-title", { staticClass: "text-subtitle-2" }, [
                _vm._v(_vm._s(_vm.$t("ADD_OBJECT")))
              ]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { plain: "", icon: "", "x-small": "" },
                  on: { click: _vm.closeDialog }
                },
                [_c("v-icon", { attrs: { dense: "" } }, [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "validation-observer",
                { ref: "observer" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("validation-provider", {
                            attrs: {
                              rules: "max:25",
                              name: _vm.$t("OBJECT_NAME")
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        "error-messages": errors,
                                        label: _vm.$t("OBJECT_NAME"),
                                        placeholder: "New Object Name",
                                        color: "grey",
                                        "hide-details": "auto",
                                        solo: "",
                                        dense: "",
                                        outlined: ""
                                      },
                                      model: {
                                        value: _vm.fields.name,
                                        callback: function($$v) {
                                          _vm.$set(_vm.fields, "name", $$v)
                                        },
                                        expression: "fields.name"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("validation-provider", {
                            attrs: {
                              rules: "required|imei",
                              name: _vm.$t("IMEI"),
                              mode: "passive"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: _vm.$t("IMEI"),
                                        "error-messages": errors,
                                        required: "",
                                        placeholder: "IMEI On Tracker",
                                        color: "grey",
                                        "hide-details": "auto",
                                        solo: "",
                                        dense: "",
                                        outlined: ""
                                      },
                                      on: {
                                        keypress: function($event) {
                                          return _vm.isNumber($event)
                                        }
                                      },
                                      model: {
                                        value: _vm.fields.imei,
                                        callback: function($$v) {
                                          _vm.$set(_vm.fields, "imei", $$v)
                                        },
                                        expression: "fields.imei"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(_vm.$t("TDJ_SUB_ADD_PLEASE_READ"))
                          }
                        }),
                        _vm._v(" "),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(_vm.$t("TDJ_SUB_WOULD_YOU"))
                          }
                        }),
                        _vm._v(" "),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(_vm.$t("TDJ_SUB_ADD_OPTIONAL"))
                          }
                        })
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "d-flex", attrs: { cols: "12" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "flex-shrink-1 mr-1",
                              attrs: { color: "primary", small: "" },
                              on: { click: _vm.subscribe }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("TDJ_SUB_ADD_OPTION_A")) +
                                  "\n            "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "flex-shrink-1",
                              attrs: {
                                color: "primary",
                                small: "",
                                outlined: ""
                              },
                              on: { click: _vm.save }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("TDJ_SUB_ADD_OPTION_B")) +
                                  "\n            "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("ConfirmDialog", {
        attrs: { info: _vm.confirmDetails, show: _vm.showConfirm },
        on: { "show-confirm-dialog": _vm.confirm }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }