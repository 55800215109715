var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "hide-overlay": "",
        "no-click-animation": "",
        "max-width": "340",
        height: "auto"
      },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-toolbar",
            { attrs: { dense: "", elevation: "0" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", "x-small": "", plain: "" },
                  on: {
                    click: function($event) {
                      return _vm.setParameters(false)
                    }
                  }
                },
                [_c("v-icon", { attrs: { dense: "" } }, [_vm._v("mdi-close")])],
                1
              ),
              _vm._v(" "),
              _c("v-toolbar-title"),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-toolbar-items",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.setParameters(true)
                        }
                      }
                    },
                    [_vm._v("\n          Save\n        ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "validation-observer",
            { ref: "observer" },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("validation-provider", {
                            staticClass: "w-100",
                            attrs: { rules: "required", name: "Source" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-select", {
                                      attrs: {
                                        "menu-props": "dark",
                                        "item-color": "#272727",
                                        filled: "",
                                        items:
                                          _vm.type == "sensor"
                                            ? _vm.sensorList
                                            : _vm.parameterList,
                                        label: "Source",
                                        solo: "",
                                        color: "grey",
                                        "hide-details": "auto",
                                        dense: "",
                                        outlined: "",
                                        required: "",
                                        "error-messages": errors
                                      },
                                      model: {
                                        value: _vm.src,
                                        callback: function($$v) {
                                          _vm.src = $$v
                                        },
                                        expression: "src"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("validation-provider", {
                            staticClass: "w-100",
                            attrs: { rules: "required", name: "Comparator" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-select", {
                                      attrs: {
                                        "menu-props": "dark",
                                        "item-color": "#272727",
                                        filled: "",
                                        items: _vm.comparatorList,
                                        "item-value": "value",
                                        "item-text": "text",
                                        label: "Comparator",
                                        solo: "",
                                        color: "grey",
                                        "hide-details": "auto",
                                        dense: "",
                                        outlined: "",
                                        required: "",
                                        "error-messages": errors
                                      },
                                      model: {
                                        value: _vm.cn,
                                        callback: function($$v) {
                                          _vm.cn = $$v
                                        },
                                        expression: "cn"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("validation-provider", {
                            staticClass: "w-100",
                            attrs: { rules: "required", name: "Value" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        filled: "",
                                        label: "Value",
                                        solo: "",
                                        color: "grey",
                                        dense: "",
                                        "hide-details": "auto",
                                        required: "",
                                        outlined: "",
                                        "error-messages": errors
                                      },
                                      model: {
                                        value: _vm.val,
                                        callback: function($$v) {
                                          _vm.val = $$v
                                        },
                                        expression: "val"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }