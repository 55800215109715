export const historyFunctions = {
  transformToHistoryRoute
}

function transformToHistoryRoute (data) {
  let tempAngle = 0

  const sroute = data.route
  const route = []
  for (let i = 0; i < sroute.length; i++) {
    // filter invalid routes.
    if (typeof sroute[i] === 'undefined' || !sroute[i]) {
      continue
    }
    // calculate angle
    if (i > 0) {
      const lat1 = sroute[i - 1][1] || 0
      const lon1 = sroute[i - 1][2] || 0
      const lat2 = sroute[i][1] || 0
      const lon2 = sroute[i][2] || 0
      sroute[i][4] = getAngle(lat1, lon1, lat2, lon2)
    }

    // fix 0 angle during speed 0
    if (sroute[i][4] > 0) {
      tempAngle = sroute[i][4]
    }

    if (sroute[i][5] === 0) {
      sroute[i][4] = tempAngle
    }

    route.push({
      dt_tracker: sroute[i][0],
      lat: sroute[i][1],
      lng: sroute[i][2],
      altitude: sroute[i][3],
      angle: sroute[i][4],
      speed: sroute[i][5],
      params: sroute[i][6]
    })
  }

  const sstops = data.stops
  const stops = []
  if (sstops !== undefined) {
    for (let i = 0; i < sstops.length; i++) {
      // filter invalid stops.
      if (typeof sstops[i] === 'undefined' || !sstops[i]) {
        continue
      }
      stops.push({
        id_start: sstops[i][0],
        id_end: sstops[i][1],
        lat: sstops[i][2],
        lng: sstops[i][3],
        altitude: sstops[i][4],
        angle: sstops[i][5],
        dt_start: sstops[i][6],
        dt_end: sstops[i][7],
        duration: sstops[i][8],
        fuel_consumption: sstops[i][9],
        fuel_cost: sstops[i][10],
        engine_idle: sstops[i][11],
        params: sstops[i][12]
      })
    }
  }

  const sdrives = data.drives
  const drives = []
  if (sdrives !== undefined) {
    for (let i = 0; i < sdrives.length; i++) {
      // Filter invalid drives
      if (typeof sdrives[i] === 'undefined' || !sdrives[i]) {
        continue
      }
      drives.push({
        id_start_s: sdrives[i][0],
        id_start: sdrives[i][1],
        id_end: sdrives[i][2],
        dt_start_s: sdrives[i][3],
        dt_start: sdrives[i][4],
        dt_end: sdrives[i][5],
        duration: sdrives[i][6],
        route_length: sdrives[i][7],
        top_speed: sdrives[i][8],
        avg_speed: sdrives[i][9],
        fuel_consumption: sdrives[i][10],
        fuel_cost: sdrives[i][11],
        engine_work: sdrives[i][12]
      })
    }
  }

  const sevents = data.events
  const events = []
  if (sevents !== undefined) {
    for (let i = 0; i < sevents.length; i++) {
      events.push({
        event_desc: sevents[i][0],
        dt_tracker: sevents[i][1],
        lat: sevents[i][2],
        lng: sevents[i][3],
        altitude: sevents[i][4],
        angle: sevents[i][5],
        speed: sevents[i][6],
        params: sevents[i][7]
      })
    }
  }

  data.route = route
  data.stops = stops
  data.drives = drives
  data.events = events

  return data
}

function getAngle (lat1, lon1, lat2, lon2) {
  // let dLat = toRad(lat2 - lat1)
  const dLon = toRad(lon2 - lon1)

  lat1 = toRad(lat1)
  lat2 = toRad(lat2)

  const y = Math.sin(dLon) * Math.cos(lat2)
  const x = Math.cos(lat1) * Math.sin(lat2) - Math.sin(lat1) * Math.cos(lat2) * Math.cos(dLon)
  let angle = toDeg(Math.atan2(y, x))

  if (angle < 0) {
    angle = 360 + angle
  }

  return Math.abs(angle).toFixed(0)
}

function toRad (Value) {
  return Value * Math.PI / 180
}
function toDeg (Value) {
  return Value * 180 / Math.PI
}
