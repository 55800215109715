var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "open-street-map",
      staticStyle: { height: "calc(100vh - 56px)" }
    },
    [
      _vm.object
        ? _c(
            "l-map",
            {
              staticStyle: { height: "100%" },
              attrs: {
                options: _vm.mapOptions,
                maxZoom: _vm.config.map_max_zoom,
                minZoom: _vm.config.map_min_zoom,
                zoom: _vm.zoom,
                center: _vm.center
              },
              on: { ready: _vm.follow }
            },
            [
              _c("TileLayers"),
              _vm._v(" "),
              _vm.marker
                ? _c(
                    "v-rotated-marker",
                    {
                      attrs: {
                        "lat-lng": _vm.marker,
                        rotationAngle: _vm.rotationAngle
                      }
                    },
                    [
                      _c("l-icon", {
                        attrs: {
                          "icon-size": _vm.iconSize,
                          "icon-url":
                            _vm.publicPath +
                            "assets/img/markers/" +
                            _vm.getMarkerIcon(_vm.imei) +
                            ".png"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "l-tooltip",
                        { attrs: { options: { permanent: true } } },
                        [
                          _c("div", [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.settings["name"]) +
                                " (" +
                                _vm._s(_vm.object["data"]["speed"]) +
                                "\n          kph)\n        "
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("l-popup", [
                        _c("div", { staticClass: "div" }, [
                          _vm._v(
                            "\n          Object: " +
                              _vm._s(_vm.settings["name"]) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n          Address: " +
                              _vm._s(_vm.object["address"]) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n          Position: " +
                              _vm._s(_vm.object["data"]["lat"]) +
                              " °," +
                              _vm._s(_vm.object["data"]["lng"]) +
                              "\n          ° "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n          Altitude: " +
                              _vm._s(_vm.object["data"]["altitude"]) +
                              " m "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n          Angle: " +
                              _vm._s(_vm.object["data"]["angle"]) +
                              " ° "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n          Speed: " +
                              _vm._s(_vm.object["data"]["speed"]) +
                              " kph "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n          Time: " +
                              _vm._s(_vm.object["data"]["dt_tracker"]) +
                              " "
                          ),
                          _c("br"),
                          _vm._v(
                            "\n          Odometer: " +
                              _vm._s(_vm.object["odometer"]) +
                              " km\n        "
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "l-control",
                { attrs: { position: "bottomleft" } },
                [
                  !_vm.showHistoryControl
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { right: "", color: "tooltip" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-fab-transition",
                                      [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  elevation: "20",
                                                  fab: "",
                                                  color: "secondary",
                                                  small: ""
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.$router.go(-1)
                                                  }
                                                }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-arrow-left-bold")
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            544506535
                          )
                        },
                        [_vm._v(" "), _c("span", [_vm._v("Go back?")])]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }