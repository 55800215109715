<template>
  <v-dialog
    persistent
    v-model="show"
    hide-overlay
    no-click-animation
    max-width="340"
  >
    <v-card outlined>
      <v-toolbar dense elevation="0">
        <v-btn plain icon x-small @click="closeDialog(false)">
          <v-icon dark dense>mdi-close</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialog(true)"> Save </v-btn>
      </v-toolbar>
      <TriggerMapModal :show="showMapModal" v-on:set-geofence="setGeofence" />
      <validation-observer ref="observer">
        <v-container fluid>
          <v-row>
            <v-col>
              <TriggerHelpInformation :type="type" />
            </v-col>
          </v-row>

          <v-row v-if="type === 'zone_out'">
            <v-col>
              <v-select
                menu-props="dark"
                item-color="#272727"
                color="grey"
                v-model="fields.inout"
                :items="['Both', 'In', 'Out']"
                label="Geofensing Type"
                hide-details="auto"
                solo
                dense
                outlined
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if="type === 'zone_out'">
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                :rules="`${
                  type === 'zone_out' && !fields.polygon ? 'required' : ''
                }`"
                name="Zones"
                ><v-select
                  menu-props="dark"
                  item-color="#272727"
                  color="grey"
                  @change="zoneChange"
                  v-model="fields.zone"
                  :items="placeZonesItems"
                  item-text="text"
                  item-value="value"
                  label="Zones"
                  hide-details="auto"
                  :required="type === 'zone_out' && !fields.polygon"
                  :error-messages="errors"
                  solo
                  dense
                  outlined
                ></v-select
              ></validation-provider>
            </v-col>
            <a class="ml-3" @click="showMapModal = true" color="primary"
              >Create a new zone</a
            >
          </v-row>
          <v-row v-if="type === 'zone_out'"
            ><v-col
              >
              <!-- <validation-provider
                v-slot="{ errors }"
                :rules="`${type === 'zone_out' ? 'required' : ''}`"
                name="Zone Vertices"
                ><v-textarea
                  color="grey"
                  v-model="fields.polygon"
                  label="Zone Vertices"
                  hide-details="auto"
                  :error-messages="errors"
                  :required="type === 'zone_out'"
                  auto-grow
                  readonly
                  solo
                  dense
                  outlined
                ></v-textarea>
              </validation-provider> -->
              <v-sheet color="white" elevation="1" height="200px" width="100%"
                >
                <editable-map
          ref="map"
          editable
          :options="mapOptions"
          :maxZoom="config.map_max_zoom"
          :minZoom="config.map_min_zoom"
          @ready="ready"
          :center="defaultCenter"
          :zoom="parseInt(server.map_zoom)"
        >
          <l-tile-layer :url="url" :attribution="attribution" />
           <l-polygon v-if="fields.polygon"
              :lat-lngs="polygonLatLngs"
              :color="polygon.color"
            />
          </editable-map
      >
                </v-sheet
              >
            </v-col></v-row
          >
          <v-row v-if="type === 'sensor'">
            <v-col>
              <v-switch
                color="primary"
                hide-details="auto"
                dense
                v-model="fields.trigger_on"
                :label="fields.trigger_on ? 'Trigger when Ignition starts' : 'Trigger when Ignition ends'"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row v-if="type === 'overspeed'">
            <v-col>
              <v-radio-group
                class="ma-0"
                label="Speed Type"
                v-model="fields.speed_type"
                dense
                hide-details="auto"
              >
                <v-radio
                  color="primary"
                  label="Over limit"
                  value="overspeed"
                ></v-radio>
                <v-radio
                  color="primary"
                  label="Under limit"
                  value="underspeed"
                ></v-radio>
              </v-radio-group> </v-col
          ></v-row>
          <v-row v-if="type === 'overspeed'">
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                :rules="`${type === 'overspeed' ? 'required|' : ''}number`"
                :name="$t('SPEED_LIMIT') + ' (' + $t('UNIT_SPEED') + ')'"
              >
                <v-text-field
                  :required="type === 'overspeed'"
                  v-model="fields.speed"
                  :label="
                    'Set ' + $t('SPEED_LIMIT') + ' (' + $t('UNIT_SPEED') + ')'
                  "
                  :error-messages="errors"
                  filled
                  flat
                  dense
                  outlined
                  color="grey"
                ></v-text-field> </validation-provider></v-col
          ></v-row>
        </v-container>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import TriggerMapModal from '@/components/triggers/TriggerMapModal.vue'
import TriggerHelpInformation from '@/components/triggers/TriggerHelpInformation.vue'

import { mapFunctions } from '@/plugins/map'
import L from 'leaflet'
import {
  LTileLayer,
  // LControl,
  LPolygon
} from 'vue2-leaflet'
import { EditableMap } from 'vue2-leaflet-editable'

export default {
  components: {
    TriggerMapModal,
    ValidationProvider,
    ValidationObserver,
    TriggerHelpInformation,

    LTileLayer,
    EditableMap,
    // LControl,
    LPolygon
  },
  props: {
    show: Boolean,
    type: String
  },
  data () {
    return {
      showMapModal: false,
      fields: {
        trigger_on: true,
        speed: '70',
        polygon: '',
        zone: '',
        inout: 'Out',
        speed_type: 'overspeed'
      },
      polygonLatLngs: [],
      mapLayers: {
        zone: {}
      },
      visible: false,
      polygon: '',
      points: [],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 0,
      mapOptions: {
        zoomSnap: 0.5
      },
      map: null,
      iconSize: [28, 28],
      init: true,
      mapTypes: {
        openstreetmap: {
          osm: {
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution:
              '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      placeZones: 'places/placeZones',
      placeZonesItems: 'places/placeZonesItems',
      server: 'settings/server'
    }),

    defaultCenter: function () {
      try {
        let lat = 0
        let lng = 0
        if (this.server.map_lat && this.server.map_zoom) {
          lat = this.server.map_lat
          lng = this.server.map_lng
        }
        return [lat, lng]
      } catch (error) {
        this.handleError(error)
      }
      return [0, 0]
    }
  },
  watch: {
    show: function () {
      this.fields = {
        trigger_on: true,
        speed: '70',
        polygon: '',
        zone: '',
        inout: 'Out',
        speed_type: 'overspeed'
      }
    },
    'fields.polygon': function () {
      try {
        this.$log.debug('changed')
        if (this.map && this.fields.polygon) {
          //  const zone = {
          //     points:
          //   };
          //   this.mapLayers.zones.addLayer(mapFunctions.generatePolygon(zone));
          this.polygonLatLngs = mapFunctions.convertPointStringToLatLng(this.fields.polygon)
          this.map.fitBounds(this.polygonLatLngs)
        }
      } catch (error) {
        this.handleError(error)
      }
    }
  },
  methods: {
    ...mapActions({
      fetchPlacesData: 'places/fetchPlacesData',
      savePlaceObject: 'places/savePlaceObject'
    }),
    async closeDialog (isSave) {
      try {
        const valid = await this.$refs.observer.validate()
        if (isSave) {
          if (this.type === 'zone_out') {
            // updating a geofence
            if (!this.fields.polygon) {
              // no selected zone or didnt create a new one
              this.$notify({
                    duration: 5000,
                    closeOnClick: false,
                type: 'error',
                title: this.$t('ERROR'),
                text:
                  'Please select from the available zones or create a new one.'
              })
            } else {
              if (!this.fields.zone) {
                // creating a new zone
                this.savePlaceObject({
                  fields: {
                    zone_name: '',
                    group_id: '0',
                    zone_color: '#FF0000',
                    zone_visible: false,
                    zone_name_visible: false,
                    zone_area: 0,
                    zone_vertices: this.fields.polygon
                  },
                  type: 'zones',
                  action: 'add'
                })
                  .then((response) => {
                    if (response.message === 'OK') {
                      if (process.env.NODE_ENV === 'production') {
                        this.fetchPlacesData({
                          cmd: 'load_zone_data',
                          initialize: true
                        })
                          .then((data) => {
                            this.fields.zone = Object.keys(data).pop()
                            if (valid) {
                              this.$emit('set-details', this.fields, isSave)
                            }
                          })
                          .catch((error) => {
                            this.handleError(error)
                          })
                      } else {
                        this.fields.zone = Object.keys(this.placeZones).pop()

                        if (valid) {
                          this.$emit('set-details', this.fields, isSave)
                        }
                      }
                    } else {
                      this.$notify({
                    duration: 5000,
                    closeOnClick: false,
                        type: 'error',
                        title: this.$t('ERROR'),
                        text:
                          'Sorry. There was a problem creating a zone. ' +
                          this.$t('ZONE_LIMIT_IS_REACHED')
                      })
                    }
                  })
                  .catch((error) => {
                    this.handleError(error)
                  })
              } else {
                // selected an existng zone
                // const valid = await this.$refs.observer.validate()
                if (valid) {
                  this.$emit('set-details', this.fields, isSave)
                }
              }
            }
          } else {
            // updating other trigger types i.e speed, ignition
            // const valid = await this.$refs.observer.validate()
            if (valid) {
              this.$emit('set-details', this.fields, isSave)
            }
          }
        } else {
          this.$emit('set-details', this.fields, isSave)
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    setGeofence: function (geofence, isSave) {
      try {
        this.showMapModal = false
        if (isSave) {
          this.fields.zone = ''
          this.fields.polygon = geofence
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    zoneChange () {
      try {
        this.fields.polygon = this.placeZones[this.fields.zone].data.vertices
      } catch (error) {
        this.handleError(error)
      }
    },
    ready () {
      try {
        this.map = this.$refs.map.mapObject
        this.mapLayers.zones = L.layerGroup()
        this.mapLayers.zones.addTo(this.map)

        // this.map.on("editable:editing editable:drag", this.drawObject);
        // this.map.on("editable:drawing:end", this.drawObject);

        this.map.doubleClickZoom.disable()
        // this.map.editTools.startPolygon();
        this.$refs.map.mapObject._onResize()
      } catch (error) {
        this.handleError(error)
      }
    }

  },
  created () {
    this.fetchPlacesData({ cmd: 'load_zone_data' }).catch((error) => {
      this.handleError(error)
    })
  }
}
</script>

<style></style>
