<template>
  <v-dialog
    persistent
    v-model="show"
    hide-overlay
    no-click-animation
    max-width="370"
    width="auto"
  >
    <v-card outlined>
      <v-toolbar color="" dense elevation="0">
        <v-toolbar-title class="text-subtitle-2">{{
          formTitle
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn plain icon x-small @click="closeDialog">
          <v-icon dense>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container fluid>
        <validation-observer ref="observer">
          <v-row>
            <v-col>
              <template>
                <validation-provider
                v-slot="{ errors, validate }"
                rules="dimensions:640,480"
                :name="$t('NAME')"
                >
                <v-file-input
                  accept=".png, .jpg, .jpeg"
                  label="File input"
                  filled
                  prepend-icon="mdi-camera"
                 :error-messages="errors"
                 @change="validate"
                ></v-file-input>
                </validation-provider>
              </template>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="mb-2 d-flex flex-row">
                <span class="text-body-2 mr-1">{{ $t("NAME") }}</span>

                <FormTooltip>
                  <template v-slot:tooltip-trigger>
                    <v-btn x-small color="primary lighten-1" text icon>
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:tooltip-content>{{ $t("NAME") }} </template>
                </FormTooltip>
              </div>
              <validation-provider
                v-slot="{ errors }"
                rules="required|max:30"
                :name="$t('NAME')"
                ><v-text-field
                  v-model="fields.name"
                  :error-messages="errors"
                  :label="$t('NAME')"
                  required
                  solo
                  color="grey"
                  dense
                  hide-details="auto"
                  outlined
                ></v-text-field
              ></validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="mb-2 d-flex flex-row">
                <span class="text-body-2 mr-1">Token</span>

                <FormTooltip>
                  <template v-slot:tooltip-trigger>
                    <v-btn x-small color="primary lighten-1" text icon>
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:tooltip-content>Token </template>
                </FormTooltip>
              </div>
              <validation-provider
                v-slot="{ errors }"
                rules="max:30"
                name="Token"
                ><v-text-field
                  v-model="fields.token"
                  label="Token"
                  :error-messages="errors"
                  solo
                  color="grey"
                  dense
                  hide-details="auto"
                  outlined
                ></v-text-field
              ></validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="mb-2 d-flex flex-row">
                <span class="text-body-2 mr-1">{{ $t("ID_NUMBER") }}</span>

                <FormTooltip>
                  <template v-slot:tooltip-trigger>
                    <v-btn x-small color="primary lighten-1" text icon>
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:tooltip-content
                    >{{ $t("ID_NUMBER") }}
                  </template>
                </FormTooltip>
              </div>
              <validation-provider
                v-slot="{ errors }"
                rules="max:30"
                :name="$t('ID_NUMBER')"
                ><v-text-field
                  v-model="fields.idnum"
                  :label="$t('ID_NUMBER')"
                  :error-messages="errors"
                  solo
                  color="grey"
                  dense
                  hide-details="auto"
                  outlined
                ></v-text-field
              ></validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="mb-2 d-flex flex-row">
                <span class="text-body-2 mr-1">{{ $t("ADDRESS") }}</span>

                <FormTooltip>
                  <template v-slot:tooltip-trigger>
                    <v-btn x-small color="primary lighten-1" text icon>
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:tooltip-content
                    >{{ $t("ADDRESS") }}
                  </template>
                </FormTooltip>
              </div>
              <validation-provider
                v-slot="{ errors }"
                rules="max:100"
                :name="$t('ADDRESS')"
                ><v-textarea
                  rows="3"
                  v-model="fields.address"
                  :label="$t('ADDRESS')"
                  :error-messages="errors"
                  solo
                  color="grey"
                  dense
                  hide-details="auto"
                  outlined
                ></v-textarea
              ></validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="mb-2 d-flex flex-row">
                <span class="text-body-2 mr-1">{{ $t("PHONE") }}</span>

                <FormTooltip>
                  <template v-slot:tooltip-trigger>
                    <v-btn x-small color="primary lighten-1" text icon>
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:tooltip-content>{{ $t("PHONE") }} </template>
                </FormTooltip>
              </div>
              <validation-provider
                v-slot="{ errors }"
                rules="max:50"
                :name="$t('PHONE')"
                ><v-text-field
                  v-model="fields.phone"
                  :label="$t('PHONE')"
                  :error-messages="errors"
                  solo
                  color="grey"
                  dense
                  hide-details="auto"
                  outlined
                ></v-text-field
              ></validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="mb-2 d-flex flex-row">
                <span class="text-body-2 mr-1">{{ $t("EMAIL") }}</span>

                <FormTooltip>
                  <template v-slot:tooltip-trigger>
                    <v-btn x-small color="primary lighten-1" text icon>
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:tooltip-content>{{ $t("EMAIL") }} </template>
                </FormTooltip>
              </div>
              <validation-provider
                v-slot="{ errors }"
                rules="email|max:50"
                :name="$t('EMAIL')"
                ><v-text-field
                  v-model="fields.email"
                  :label="$t('EMAIL')"
                  :error-messages="errors"
                  solo
                  color="grey"
                  dense
                  hide-details="auto"
                  outlined
                ></v-text-field
              ></validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="mb-2 d-flex flex-row">
                <span class="text-body-2 mr-1">{{ $t("DESCRIPTION") }}</span>

                <FormTooltip>
                  <template v-slot:tooltip-trigger>
                    <v-btn x-small color="primary lighten-1" text icon>
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:tooltip-content
                    >{{ $t("DESCRIPTION") }}
                  </template>
                </FormTooltip>
              </div>
              <validation-provider
                v-slot="{ errors }"
                rules="max:256"
                :name="$t('DESCRIPTION')"
                ><v-textarea
                  rows="2"
                  v-model="fields.description"
                  :label="$t('DESCRIPTION')"
                  :error-messages="errors"
                  solo
                  color="grey"
                  dense
                  hide-details="auto"
                  outlined
                ></v-textarea
              ></validation-provider>
            </v-col>
          </v-row>
          <v-row class="mb-3 justify-center flex-column flex-sm-row d-flex">
            <v-btn
              @click="closeDialog"
              color="primary"
              outlined
              class="mr-sm-2 mb-2"
              max-width="50%"
              >Cancel</v-btn
            >
            <v-btn @click="save" color="primary" max-width="50%">Save</v-btn>
          </v-row>
        </validation-observer>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import FormTooltip from '@/components/includes/FormTooltip.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormTooltip
  },
  props: {
    show: Boolean,
    id: String,
    type: String
  },
  data () {
    return {
      fields: {
        name: '',
        token: '',
        idnum: '',
        address: '',
        phone: '',
        email: '',
        description: ''
      },
      showConfirm: false,
      confirmDetails: {
        title: '',
        message: '',
        action: {
          yes: '',
          no: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      message: 'messages/message',
      drivers: 'person/drivers',
      passengers: 'person/passengers',
      getPassengerById: 'person/getPassengerById'
    }),
    formTitle: function () {
      let title = ''
      switch (this.type) {
        case 'driver':
          title = this.$t('OBJECT_DRIVER_PROPERTIES')
          break
        case 'passenger':
          title = this.$t('OBJECT_PASSENGER_PROPERTIES')
          break

        default:
          break
      }
      return title
    }
  },
  watch: {
    show: function () {
      try {
        if (this.id) {
          let person = {}
          if (this.type === 'driver') {
            person = this.drivers[this.id]
          } else if (this.type === 'passenger') {
            person = this.getPassengerById(this.id)
          }
          this.fields = {
            name: person.name,
            token: person.assign_id,
            idnum: person.idn,
            address: person.address,
            phone: person.phone,
            email: person.email,
            description: person.desc
          }
        } else {
          this.fields = {
            name: '',
            token: '',
            idnum: '',
            address: '',
            phone: '',
            email: '',
            description: ''
          }
          if (this.type === 'driver') this.fields.image = ''
        }
      } catch (error) {
        this.handleError(error)
      }
    }
  },
  methods: {
    ...mapActions({
      saveDriver: 'person/saveDriver',
      savePassenger: 'person/savePassenger',
      fetchDrivers: 'person/fetchDrivers',

      fetchPassengers: 'person/fetchPassengers'
    }),
    async save () {
      try {
        const valid = await this.$refs.observer.validate()

        let response

        if (valid) {
          if (this.type === 'driver') {
            const params = {
              // cmd: 'save_object_driver',
              name: this.fields.name,
              email: this.fields.email,
              phone_number: this.fields.phone,
              access_level: 'driver'
            }

            // upload https://gator.net.au/func/fn_upload.php?file=driver_photo
            // data:image/png;base64 payload

            response = await this.saveDriver(params)
          } else if (this.type === 'passenger') {
            const params = {
              cmd: 'save_object_passenger',
              passenger_id: this.id ? parseInt(this.id) : false,
              passenger_name: this.fields.name,
              passenger_assign_id: this.fields.token,
              passenger_idn: this.fields.idnum,
              passenger_address: this.fields.address,
              passenger_phone: this.fields.phone,
              passenger_email: this.fields.email,
              passenger_desc: this.fields.description
            }
            response = await this.savePassenger(params)
          }

          if (response.message === 'ok') {
            this.$notify({
                    duration: 5000,
                    closeOnClick: false,
              type: 'success',
              title: this.$t('INFORMATION'),
              text: this.$t('CHANGES_SAVED_SUCCESSFULLY')
            })
            if (process.env.NODE_ENV === 'production') {
              if (this.type === 'passenger') {
                this.fetchPassengers()
              } else {
                this.fetchDrivers()
              }
            }
            this.closeDialog()
          } else {
            this.$notify({
                    duration: 5000,
                    closeOnClick: false,
              type: 'error',
              title: this.$t('ERROR'),
              text:
                'Something went wrong! Please contact system administrator.'
            })
            this.closeDialog()
          }
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    closeDialog () {
      this.$refs.observer.reset()
      this.$emit('show-person-dialog', false)
    }
  }
}
</script>

<style></style>
