<template>
  <v-dialog
    persistent
    v-model="show"
    hide-overlay
    no-click-animation
    max-width="370"
    width="auto"
  >
    <v-card>
      <v-toolbar color="" dense elevation="2">
        <v-toolbar-title class="text-subtitle-2"> Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn plain icon x-small @click="$emit('show-filter-dialog', {})">
          <v-icon dense>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container fluid>
        <validation-observer ref="observer">
          <v-row>
            <v-col cols="12">
              <v-select
                color="grey"
                menu-props="dark"
                item-color="#272727"
                v-model="filters.imei"
                :items="objectItems"
                label="Filter by Object"
                item-text="text"
                item-value="value"
                hide-details="auto"
                required
                solo
                dense
                outlined
              ></v-select>
            </v-col>
            <!-- <v-col cols="12">
              <v-select
                color="grey"
                menu-props="dark"
                item-color="#272727"
                v-model="filters.name"
                :items="eventNames"
                label="Filter by Trigger Name"
                hide-details="auto"
                required
                solo
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                color="grey"
                menu-props="dark"
                item-color="#272727"
                v-model="filters.type"
                :items="eventTypes"
                label="Filter by Trigger Type"
                hide-details="auto"
                required
                solo
                dense
                outlined
              ></v-select>
            </v-col> -->
            <v-col cols="12">
              <v-menu
                ref="dateFromMenu"
                v-model="dateFromMenu"
                :close-on-content-click="false"
                :return-value.sync="filters.dtf"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="filters.dtf"
                    label="Date From"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-calendar"
                    required
                    hint="YYYY-MM-DD format"
                    solo
                    dense
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filters.dtf"
                  no-title
                  scrollable
                  :max="max"
                  @input="$refs.dateFromMenu.save(filters.dtf)"
                >
                  <!-- <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="dateFromMenu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dateFromMenu.save(filters.dtf)"
                  >
                    OK
                  </v-btn> -->
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12">
              <v-menu
                ref="dateToMenu"
                v-model="dateToMenu"
                :close-on-content-click="false"
                :return-value.sync="filters.dtt"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }"
                  ><validation-provider
                    v-slot="{ validate, errors }"
                    :rules="
                      filters.dtf && filters.dtt
                        ? `dateGreaterThan:${filters.dtf}`
                        : ''
                    "
                    name="dateTo"
                  >
                    <v-text-field
                      v-model="filters.dtt"
                      label="Date To"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      append-icon="mdi-calendar"
                      required
                      hint="YYYY-MM-DD format"
                      solo
                      dense
                      outlined
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="filters.dtt"
                  no-title
                  scrollable
                  :max="max"
                  @input="$refs.dateToMenu.save(filters.dtt)"
                >
                  <!-- <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="dateToMenu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dateToMenu.save(filters.dtt)"
                  >
                    OK
                  </v-btn> -->
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row class="mb-3 justify-center flex-column flex-sm-row d-flex">
            <v-btn
              @click="$emit('show-filter-dialog', {})"
              color="primary"
              outlined
              class="mr-sm-2 mb-2"
              max-width="50%"
              >Cancel</v-btn
            >
            <v-btn @click="save" color="primary" max-width="50%">Filter</v-btn>
          </v-row>
        </validation-observer>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import moment from 'moment'
export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    dateFromMenu: false,
    dateToMenu: false,
    filters: {
      imei: '',
      dtf: '',
      dtt: ''
    },
    max: moment().format('YYYY-MM-DD')
  }),
  props: {
    show: Boolean
  },
  watch: {
    show: function () {
      if (!this.filters.imei) {
        if (this.objectItems.length) {
          this.filters.imei = this.objectItems[0].value
        }
      }
    },

    objectItems: function () {
      if (!this.filters.imei) {
        if (this.objectItems.length) {
          this.filters.imei = this.objectItems[0].value
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      objectItems: 'objects/objectItems',
      eventNames: 'events/eventNames'
      // eventTypes: 'events/eventTypes'
    })
  },
  methods: {
    ...mapActions({
    }),
    save () {
      this.$emit('show-filter-dialog', this.filters)
    }
  }
}
</script>

<style>
</style>
