var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "overflow-y-auto",
      staticStyle: { "max-height": "calc(100vh - 56px)" },
      attrs: { fluid: "", id: "scroll-target" }
    },
    [
      _vm.settings && _vm.object
        ? _c(
            "v-row",
            {
              staticClass: "ma-1 d-flex flex-column align-content-center",
              staticStyle: { height: "auto" }
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "8", xl: "6" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "w-100", attrs: { outlined: "" } },
                    [
                      _c("v-card-title", [
                        _c("h4", { attrs: { color: "primary" } }, [
                          _vm._v(_vm._s(_vm.$t("GENERAL")))
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c("v-col", { staticClass: "font-weight-bold" }, [
                                _vm._v(_vm._s(_vm.$t("OBJECT")))
                              ]),
                              _vm._v(" "),
                              _c("v-col", [_vm._v(_vm._s(_vm.settings.name))])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c("v-col", { staticClass: "font-weight-bold" }, [
                                _vm._v(_vm._s(_vm.$t("ODOMETER")))
                              ]),
                              _vm._v(" "),
                              _c("v-col", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getObjectOdometer(_vm.imei, false)
                                  ) +
                                    "\n              " +
                                    _vm._s(_vm.$t("UNIT_DISTANCE"))
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c("v-col", { staticClass: "font-weight-bold" }, [
                                _vm._v(_vm._s(_vm.$t("STATUS")))
                              ]),
                              _vm._v(" "),
                              _c("v-col", [
                                _vm._v(_vm._s(_vm.object.status_string))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "8", xl: "6" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "w-100", attrs: { outlined: "" } },
                    [
                      _c("v-card-title", [
                        _c("h4", [_vm._v(_vm._s(_vm.$t("LOCATION")))])
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c("v-col", { staticClass: "font-weight-bold" }, [
                                _vm._v(_vm._s(_vm.$t("ADDRESS")))
                              ]),
                              _vm._v(" "),
                              _c("v-col", [_vm._v(_vm._s(_vm.address))])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c("v-col", { staticClass: "font-weight-bold" }, [
                                _vm._v(_vm._s(_vm.$t("POSITION")))
                              ]),
                              _vm._v(" "),
                              _c("v-col", [
                                _vm._v(
                                  _vm._s(_vm.object.data.lat) +
                                    "°, " +
                                    _vm._s(_vm.object.data.lng) +
                                    "°"
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c("v-col", { staticClass: "font-weight-bold" }, [
                                _vm._v(_vm._s(_vm.$t("ALTITUDE")))
                              ]),
                              _vm._v(" "),
                              _c("v-col", [
                                _vm._v(
                                  _vm._s(_vm.object.data.altitude) +
                                    " " +
                                    _vm._s(_vm.$t("UNIT_HEIGHT"))
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c("v-col", { staticClass: "font-weight-bold" }, [
                                _vm._v(_vm._s(_vm.$t("ANGLE")))
                              ]),
                              _vm._v(" "),
                              _c("v-col", [
                                _vm._v(_vm._s(_vm.object.data.angle) + "°")
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c("v-col", { staticClass: "font-weight-bold" }, [
                                _vm._v(_vm._s(_vm.$t("SPEED")))
                              ]),
                              _vm._v(" "),
                              _c("v-col", [
                                _vm._v(
                                  _vm._s(_vm.object.data.speed) +
                                    " " +
                                    _vm._s(_vm.$t("UNIT_SPEED"))
                                )
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c("v-col", { staticClass: "font-weight-bold" }, [
                                _vm._v(_vm._s(_vm.$t("TIME_POSITION")))
                              ]),
                              _vm._v(" "),
                              _c("v-col", [
                                _vm._v(_vm._s(_vm.object.data.dt_tracker))
                              ])
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c("v-col", { staticClass: "font-weight-bold" }, [
                                _vm._v(_vm._s(_vm.$t("TIME_SERVER")))
                              ]),
                              _vm._v(" "),
                              _c("v-col", [
                                _vm._v(_vm._s(_vm.object.data.dt_server))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              Object.keys(_vm.settings.sensors).length
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", md: "8", xl: "6" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "w-100", attrs: { outlined: "" } },
                        [
                          _c("v-card-title", [
                            _c("h4", [_vm._v(_vm._s(_vm.$t("SENSORS")))])
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-card-text",
                            _vm._l(_vm.settings.sensors, function(
                              sensor,
                              index
                            ) {
                              return _c(
                                "v-row",
                                { key: index, attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "font-weight-bold" },
                                    [_vm._v(_vm._s(sensor.name))]
                                  ),
                                  _vm._v(" "),
                                  _c("v-col", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.getSensorValue(
                                            _vm.imei,
                                            sensor.name
                                          )["value_full"]
                                        ) +
                                        "\n            "
                                    )
                                  ])
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.object.status === "m" &&
              _vm.settings.driver_id &&
              _vm.getDriverName(_vm.settings.driver_id)
                ? _c(
                    "v-col",
                    { attrs: { cols: "12", md: "8", xl: "6" } },
                    [
                      _c(
                        "v-card",
                        { staticClass: "w-100", attrs: { outlined: "" } },
                        [
                          _c("v-card-title", [
                            _c("h4", [_vm._v(_vm._s(_vm.$t("DRIVER_INFO")))])
                          ]),
                          _vm._v(" "),
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    "v-col",
                                    { staticClass: "font-weight-bold" },
                                    [_vm._v(_vm._s(_vm.$t("NAME")))]
                                  ),
                                  _vm._v(" "),
                                  _c("v-col", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getDriverName(
                                          _vm.settings.driver_id
                                        )
                                      )
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }