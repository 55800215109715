import Vue from 'vue'

const mutations = {
  SET_TRAILERS: (state, data) => (state.trailers = data),
  MOCK_TRAILER_ADDITION: (state, data) => {
    Vue.$log.debug('add trailer mock', data)
    const trailers = state.trailers
    let key

    const trailerKeys = Object.keys(trailers)
    if (trailerKeys.length >= 1) {
      key = trailerKeys.reduce((a, b) => { return Math.max(a, b) })
    }
    key = parseInt(key) + 1

    trailers[key] = {
      name: data.trailer_name,
      assign_id: data.trailer_assign_id,
      model: data.trailer_model,
      vin: data.trailer_vin,
      plate_number: data.trailer_plate_number,
      desc: data.trailer_desc
    }
    state.trailers = []
    state.trailers = trailers
  },
  MOCK_TRAILER_UPDATE: (state, data) => {
    Vue.$log.debug('update trailer mock')

    const trailers = state.trailers
    trailers[data.trailer_id] = {
      name: data.trailer_name,
      assign_id: data.trailer_assign_id,
      model: data.trailer_model,
      vin: data.trailer_vin,
      plate_number: data.trailer_plate_number,
      desc: data.trailer_desc
    }
    state.trailers = []
    state.trailers = trailers
  },
  MOCK_TRAILER_DELETION: (state, data) => {
    const id = data.trailer_id
    const trailers = state.trailers

    delete trailers[id]

    state.trailers = []
    state.trailers = trailers
  }
}

export default mutations
