var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize"
        }
      ],
      attrs: { fluid: "" }
    },
    [
      _c("TriggersTable", {
        attrs: {
          "table-data": _vm.triggersData,
          height: _vm.contentHeight,
          width: _vm.windowSize.x
        },
        on: {
          "show-info-modal": function($event) {
            _vm.showInfoModal = $event[0]
            _vm.fields.type = $event[1]
          }
        }
      }),
      _vm._v(" "),
      _vm.event_id !== false
        ? _c("TriggerDetailsModal", {
            attrs: {
              show: _vm.showDetailsModal,
              type: _vm.triggers[_vm.event_id].type
            },
            on: { "set-details": _vm.setDetails }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.event_id !== false && _vm.showSuccessSheet
        ? _c(
            "v-bottom-sheet",
            {
              model: {
                value: _vm.showSuccessSheet,
                callback: function($$v) {
                  _vm.showSuccessSheet = $$v
                },
                expression: "showSuccessSheet"
              }
            },
            [
              _c(
                "v-sheet",
                {
                  staticClass: "text-center",
                  attrs: { "min-height": "200px" }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "float-right ma-5",
                      attrs: { "x-small": "", plain: "", icon: "" },
                      on: {
                        click: function($event) {
                          _vm.showSuccessSheet = !_vm.showSuccessSheet
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-h5 pt-9" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.success_msg) + "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-6",
                      attrs: {
                        to: {
                          name: "mobile/triggers/edit",
                          params: { trigger_id: _vm.event_id }
                        },
                        color: "primary"
                      }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            ["overspeed", "underspeed"].includes(
                              _vm.triggers[_vm.event_id].type
                            )
                              ? "Change speed limit"
                              : "Change " +
                                  _vm.triggers[
                                    _vm.event_id
                                  ].name.toLowerCase() +
                                  " settings"
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("TriggerObjectModal", {
        attrs: { show: _vm.showObjectModal },
        on: { "set-objects": _vm.setObjects }
      }),
      _vm._v(" "),
      _c("TriggerInformationModal", {
        attrs: { show: _vm.showInfoModal, type: _vm.fields.type },
        on: { "set-next-step": _vm.setNextStep }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }