
const mutations = {
  setCmd: (state, data) => (state.cmd = data),
  SET_TEMPLATE_DATA: (state, data) => {
    state.cmd_template_data = []
    state.cmd_template_data = data
  },
  SET_SMS_DATA: (state, data) => (state.cmd_sms_data = data),
  SET_GPRS_DATA: (state, data) => (state.cmd_gprs_data = data),

  SET_SCHEDULE_DATA: (state, data) => (state.cmd_schedule_data = data)
}

export default mutations
