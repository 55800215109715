<template>
  <v-app>
    <TheHeader v-on:selected-menu="getSelectedMenu" />
    <AlertMessage />

    <v-main id="content">
      <v-container
        fluid
        class="main-content tracking-page d-flex"
        v-if="!isMobileView && $router.currentRoute.name !== 'live' && $router.currentRoute.name !== 'objects/details'"
      >
        <TheLeftContent />
        <TheRightContent />
      </v-container>
      <div class="main-content tracking-page d-block" v-if="(isMobileView ||  $router.currentRoute.name === 'objects/details') && $router.currentRoute.name !== 'live'">
        <router-view></router-view>
      </div>
      <div class="pa-0" v-if="$router.currentRoute.name === 'live'">
        <Live />
      </div>
    </v-main>
    <!-- <v-bottom-navigation
      color="primary"
      height="56px"
      v-if="$router.currentRoute.name !== 'mobile/map' && isMobileView"
      grow
    >
      <v-btn :to="{ name: 'mobile/map' }" text exact>
        <span>{{ $t("MAP") }}</span>

        <v-icon>mdi-earth</v-icon>
      </v-btn>

      <v-btn exact text :to="{ name: 'mobile/objects' }">
        <span>{{ $t("OBJECTS") }}</span>

        <v-icon>mdi-map-marker-outline</v-icon>
      </v-btn>

      <v-btn text exact :to="{ name: 'mobile/events' }">
        <span>{{ $t("EVENTS") }}</span>

        <v-icon>mdi-alert-outline</v-icon>
      </v-btn>

      <v-btn
        text
        exact
        class="d-none d-sm-block"
        :to="{ name: 'mobile/triggers' }"
      >
        <span>{{ $t("TDJ_EVENT_TRIGGERS") }}</span>

        <v-icon>mdi-alert-outline</v-icon>
      </v-btn>

      <v-btn
        text
        exact
        class="d-none d-sm-block"
        :to="{ name: 'mobile/history' }"
      >
        <span>{{ $t("HISTORY") }}</span>

        <v-icon>mdi-history</v-icon>
      </v-btn>

      <v-btn
        text
        exact
        class="d-none d-sm-block"
        :to="{ name: 'mobile/objects/control' }"
      >
        <span>{{ $t("OBJECT_CONTROL") }}</span>

        <v-icon>mdi-send-outline</v-icon>
      </v-btn>
      <v-btn
        text
        exact
        class="d-none d-sm-block"
        :to="{ name: 'mobile/settings' }"
      >
        <span>{{ $t("SETTINGS") }}</span>

        <v-icon>mdi-settings-outline</v-icon>
      </v-btn>
      <v-btn text exact class="d-block d-sm-none">
        <span>more</span>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-bottom-navigation> -->
    <!-- FOOTER GOES HERE-->
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TheHeader from '@/layout/TheHeader.vue'
import AlertMessage from '@/components/includes/AlertMessage.vue'
import TheLeftContent from '@/layout/TheLeftContent.vue'
import TheRightContent from '@/layout/TheRightContent.vue'
import Live from '@/pages/Live.vue'

export default {
  components: {
    TheHeader,
    TheLeftContent,
    TheRightContent,
    AlertMessage,
    Live
  },
  data () {
    return {
      dialog: true,
      selectedMenu: 'Map'
    }
  },
  methods: {
    ...mapActions({
      initializeObjectData: 'objects/initializeObjectData',
      updateMapPlacesData: 'map/updateMapPlacesData',
      updateMapData: 'map/updateMapData',
      fetchDrivers: 'person/fetchDrivers',
      fetchGroupSettings: 'groups/fetchGroupSettings',
      fetchSiteSettings: 'settings/fetchSiteSettings',
      initializePlacesData: 'places/initializePlacesData',
      fetchObjectData: 'objects/fetchObjectData',
      setHistory: 'map/setHistory',
      fetchVideoRequestQueue: 'images/fetchVideoRequestQueue'
    }),
    getSelectedMenu: function (menu) {
      this.selectedMenu = menu
    },
    pollVideoRequests: function () {
      clearInterval(this.$timer.checkVideoRequest)
      this.$timer.checkVideoRequest = setInterval(() => {
        let pendingIds = {pendingIds: this.pendingVideoRequestIds}
        this.fetchVideoRequestQueue(pendingIds)
        .then((response) => {
          if (this.pendingVideoRequestIds.length == 0) {
            clearInterval(this.$timer.checkVideoRequest)
          }
          response.complete.forEach(async (c, i) => {
            setTimeout(() => {
                  this.$notify({
                  duration: 5000,
                  closeOnClick: false,
                  type: c.status === 2 ? 'success' : 'error',
                  title: 'Video Request',
                  text: c.status === 2 ? 'Your video request for <b>'+c.imei+'</b> has been completed successfully.' : 'We have encountered an error while processing your video request for <b>'+c.imei+'</b>.',
                });
            }, i * 2000);

          })

        })
        .catch((error) => {
          this.handleError(error)
          clearInterval(this.$timer.checkVideoRequest)
        })
      }, 3000)
    }
  },

  computed: {
    ...mapGetters({
      objectsNew: 'objects/objectsNew',
      // objects: "objects/objectsData",
      placeMarkers: 'places/placeMarkers',
      placeRoutes: 'places/placeRoutes',
      placeZones: 'places/placeZones',
      hasVideoRequest: 'images/hasVideoRequest',
      pendingVideoRequestIds: 'images/pendingVideoRequestIds',
      
    }),
    mobile () {
      return this.$vuetify.breakpoint.mobile
    }
  },
  async created () {
    try {
      Promise.all([
        this.fetchSiteSettings('load_server_data'),
        this.fetchSiteSettings('load_user_data'),
        this.initializeObjectData(),
        // this.initializePlacesData(),
        this.fetchDrivers(),
        this.fetchGroupSettings()
      ]).then(() => {
        this.pollVideoRequests();
        
      }).catch((error) => {
        this.handleError(error)
      })
    } catch (error) {
      this.handleError(error)
    }
  },
  watch: {
    hasVideoRequest: function () {
      if (this.hasVideoRequest === true) {
        this.pollVideoRequests();
        
      } else {
        clearInterval(this.$timer.checkVideoRequest)
      }
    },
    '$vuetify.breakpoint.mobile': function (newVal, oldVal) {
      // this.$log.debug(
      //   "redirect mobile",
      //   this.$vuetify.breakpoint.mobile,
      //   this.$route.name
      // );
      if (this.$vuetify.breakpoint.mobile) {
        if (this.$router.currentRoute.name.indexOf('mobile') === -1) {
          // this.$router.push({ name: 'mobile/map' })
        }
      } else {
        this.setHistory(null)
        // if (process.env.NODE_ENV === 'development') {
        // this.$router.push({ name: 'tracking' })
        // }
      }
    }
  }
}
</script>
