var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "content-class": "dialog-settings",
        "hide-overlay": "",
        "no-click-animation": "",
        fullscreen: ""
      },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            { attrs: { color: "", dense: "", elevation: "2" } },
            [
              _c("v-toolbar-title", { staticClass: "text-subtitle-2" }, [
                _vm._v(_vm._s(_vm.$t("OBJECT_CONTROL")))
              ]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { plain: "", icon: "", "x-small": "" },
                  on: { click: _vm.closeDialog }
                },
                [_c("v-icon", { attrs: { dense: "" } }, [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-container",
            {
              staticClass: "fill-height pa-0",
              staticStyle: {
                height: "calc(100vh - 48px)",
                "align-items": "flex-start"
              },
              attrs: { fluid: "" }
            },
            [
              _c(
                "v-tabs",
                { staticClass: "fill-height " },
                [
                  _c("v-tab", [_vm._v(_vm._s(_vm.$t("GPRS")))]),
                  _vm._v(" "),
                  _c("v-tab", [_vm._v(_vm._s(_vm.$t("SMS")))]),
                  _vm._v(" "),
                  _c("v-tab", [_vm._v(_vm._s(_vm.$t("SCHEDULE")))]),
                  _vm._v(" "),
                  _c("v-tab", [_vm._v(_vm._s(_vm.$t("TEMPLATES")))]),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    { attrs: { eager: "" } },
                    [_c("GPRSTab", { attrs: { id: _vm.id } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    { attrs: { eager: "" } },
                    [_c("SMSTab", { attrs: { id: _vm.id } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    { attrs: { eager: "" } },
                    [_c("ScheduleTab", { attrs: { imei: _vm.id } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-tab-item",
                    { attrs: { eager: "" } },
                    [_c("TemplatesTab")],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }