import Vue from 'vue'

import { gatorService } from '@/services/gator'
const actions = {
  async connect ({ commit }) {
    return await gatorService.connect()
      .then(function (response) {
        Vue.$log.debug('connect', 'success')
        commit('setSession', response.data)
        return response.data
      })
      .catch(function (error) {
        Vue.$log.debug('connect' + error)
        throw error
      })
  },

  async loadDevices ({ commit }, params) {
    return await gatorService.loadDevices(params)
      .then(function (response) {
        Vue.$log.debug('loadDevices', 'success')
        commit('setGatorDevices', response.data)
        return response.data
      })
      .catch(function (error) {
        Vue.$log.debug('loadDevices' + error)
        throw error
      })
  },

  async getHistoryList ({ commit }, params) {
    return await gatorService.getHistoryList(params)
      .then(function (response) {
        Vue.$log.debug('getHistoryList', 'success')
        commit('setHistoryList', response.data)
        return response.data
      })
      .catch(function (error) {
        Vue.$log.debug('getHistoryList' + error)
        throw error
      })
  },

  async getImgList ({ commit }, params) {
    return await gatorService.getImgList(params)
      .then(function (response) {
        Vue.$log.debug('getImgList', 'success')
        commit('setImgList', response.data)
        return response.data
      })
      .catch(function (error) {
        Vue.$log.debug('getImgList' + error)
        throw error
      })
  }
}

export default actions
