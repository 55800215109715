import axios from 'axios'

export const reportsService = {
  getReports
  // getReportsList
}

const requestUrl = process.env.BASE_URL + 'src/assets/json/'

function getReports () {
  if (process.env.NODE_ENV === 'development') {
    return axios.get(`${requestUrl}/reports.json`)
  }
  return axios.get('/func/fn_reports.php', { params: { cmd: 'load_report_data' }},
    { baseURL: '' })
}
