import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  lang: [],
  language_list: [],
  server: {},
  user: [],
  custom_field: [],
  events: [],
  event_list: [],
  sensor_list: [],
  service_list: [],
  subaccounts_data: [],
  subaccount_list: [],
  templates_data: [],
  template_list: [],
  search_address_state: true,
  ui_settings: {
    map_control: false,
    table_default_rows: 200
  },
  language: 'english'
}

export default {
  state,
  getters,
  actions,
  mutations
}
