import { trailersService } from '@/services/trailers'
import Vue from 'vue'

const actions = {
  async fetchTrailers ({ commit }) {
    trailersService.getTrailers()
      .then(function (response) {
        Vue.$log.debug('getTrailers', 'success')
        commit('SET_TRAILERS', response.data)
      })
      .catch(function (error) {
        throw error
      })
  },

  async saveTrailer ({ commit }, params) {
    if (process.env.NODE_ENV === 'production') {
      return await trailersService.saveTrailer(params)
        .then(function (response) {
          Vue.$log.debug('saveTrailer', 'success', response)
          return response.data
        })
        .catch(function (error) {
          throw error
        })
    } else {
      if (params.cmd === 'save_object_trailer') {
        if (params.trailer_id === false) {
          commit('MOCK_TRAILER_ADDITION', params)
        } else {
          commit('MOCK_TRAILER_UPDATE', params)
        }
        return { message: 'OK' }
      }
      return ''
    }
  },

  async removeTrailer ({ commit }, params) {
    if (process.env.NODE_ENV === 'production') {
      return await trailersService.removeTrailer(params)
        .then(function (response) {
          Vue.$log.debug('removeTrailer', 'success', response)
          return response.data
        })
        .catch(function (error) {
          throw error
        })
    } else {
      if (params.cmd === 'delete_object_trailer') {
        commit('MOCK_TRAILER_DELETION', params)
      }
      return { message: 'OK' }
    }
  },

  async importTrailers ({ commit }, params) {
    if (process.env.NODE_ENV === 'production') {
      return await trailersService.importTrailers(params)
        .then(function (response) {
          Vue.$log.debug('importTrailers', 'success', response)
          return response.data
        })
        .catch(function (error) {
          Vue.$log.debug('importTrailers' + error)
          throw error
        })
    } else {
      return { message: 'OK' }
    }
  }
}

export default actions
