var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "hide-overlay": "",
        "no-click-animation": "",
        "max-width": "370",
        width: "auto"
      },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-toolbar",
            { attrs: { color: "", dense: "", elevation: "0" } },
            [
              _c("v-toolbar-title", { staticClass: "text-subtitle-2" }, [
                _vm._v(_vm._s(_vm.$t("OBJECT_TRAILER_PROPERTIES")))
              ]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { plain: "", icon: "", "x-small": "" },
                  on: { click: _vm.closeDialog }
                },
                [_c("v-icon", { attrs: { dense: "" } }, [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "validation-observer",
                { ref: "observer" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "div",
                            { staticClass: "mb-2 d-flex flex-row" },
                            [
                              _c("span", { staticClass: "text-body-2 mr-1" }, [
                                _vm._v(_vm._s(_vm.$t("NAME")))
                              ]),
                              _vm._v(" "),
                              _c("FormTooltip", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "tooltip-trigger",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              "x-small": "",
                                              color: "primary lighten-1",
                                              text: "",
                                              icon: ""
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-information-outline")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "tooltip-content",
                                    fn: function() {
                                      return [
                                        _vm._v(
                                          _vm._s(_vm.$t("NAME")) +
                                            "\n                "
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("validation-provider", {
                            attrs: {
                              rules: "required|max:25",
                              name: _vm.$t("NAME")
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        "error-messages": errors,
                                        label: _vm.$t("NAME"),
                                        required: "",
                                        solo: "",
                                        color: "grey",
                                        dense: "",
                                        "hide-details": "auto",
                                        outlined: ""
                                      },
                                      model: {
                                        value: _vm.fields.name,
                                        callback: function($$v) {
                                          _vm.$set(_vm.fields, "name", $$v)
                                        },
                                        expression: "fields.name"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "div",
                            { staticClass: "mb-2 d-flex flex-row" },
                            [
                              _c("span", { staticClass: "text-body-2 mr-1" }, [
                                _vm._v("Token")
                              ]),
                              _vm._v(" "),
                              _c("FormTooltip", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "tooltip-trigger",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              "x-small": "",
                                              color: "primary lighten-1",
                                              text: "",
                                              icon: ""
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-information-outline")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "tooltip-content",
                                    fn: function() {
                                      return [_vm._v("Token\n                ")]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("validation-provider", {
                            attrs: { rules: "max:30", name: "Token" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: "Token",
                                        "error-messages": errors,
                                        solo: "",
                                        color: "grey",
                                        dense: "",
                                        "hide-details": "auto",
                                        outlined: ""
                                      },
                                      model: {
                                        value: _vm.fields.token,
                                        callback: function($$v) {
                                          _vm.$set(_vm.fields, "token", $$v)
                                        },
                                        expression: "fields.token"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "div",
                            { staticClass: "mb-2 d-flex flex-row" },
                            [
                              _c("span", { staticClass: "text-body-2 mr-1" }, [
                                _vm._v(_vm._s(_vm.$t("TRANSPORT_MODEL")))
                              ]),
                              _vm._v(" "),
                              _c("FormTooltip", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "tooltip-trigger",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              "x-small": "",
                                              color: "primary lighten-1",
                                              text: "",
                                              icon: ""
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-information-outline")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "tooltip-content",
                                    fn: function() {
                                      return [
                                        _vm._v(
                                          _vm._s(_vm.$t("TRANSPORT_MODEL")) +
                                            "\n                "
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("validation-provider", {
                            attrs: {
                              rules: "max:30",
                              name: _vm.$t("TRANSPORT_MODEL")
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: _vm.$t("TRANSPORT_MODEL"),
                                        "error-messages": errors,
                                        solo: "",
                                        color: "grey",
                                        dense: "",
                                        "hide-details": "auto",
                                        outlined: ""
                                      },
                                      model: {
                                        value: _vm.fields.model,
                                        callback: function($$v) {
                                          _vm.$set(_vm.fields, "model", $$v)
                                        },
                                        expression: "fields.model"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "div",
                            { staticClass: "mb-2 d-flex flex-row" },
                            [
                              _c("span", { staticClass: "text-body-2 mr-1" }, [
                                _vm._v(_vm._s(_vm.$t("VIN")))
                              ]),
                              _vm._v(" "),
                              _c("FormTooltip", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "tooltip-trigger",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              "x-small": "",
                                              color: "primary lighten-1",
                                              text: "",
                                              icon: ""
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-information-outline")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "tooltip-content",
                                    fn: function() {
                                      return [
                                        _vm._v(
                                          _vm._s(_vm.$t("VIN")) +
                                            "\n                "
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("validation-provider", {
                            attrs: { rules: "max:20", name: _vm.$t("VIN") },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: _vm.$t("VIN"),
                                        "error-messages": errors,
                                        solo: "",
                                        color: "grey",
                                        dense: "",
                                        "hide-details": "auto",
                                        outlined: ""
                                      },
                                      model: {
                                        value: _vm.fields.vin,
                                        callback: function($$v) {
                                          _vm.$set(_vm.fields, "vin", $$v)
                                        },
                                        expression: "fields.vin"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "div",
                            { staticClass: "mb-2 d-flex flex-row" },
                            [
                              _c("span", { staticClass: "text-body-2 mr-1" }, [
                                _vm._v(_vm._s(_vm.$t("PLATE_NUMBER")))
                              ]),
                              _vm._v(" "),
                              _c("FormTooltip", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "tooltip-trigger",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              "x-small": "",
                                              color: "primary lighten-1",
                                              text: "",
                                              icon: ""
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-information-outline")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "tooltip-content",
                                    fn: function() {
                                      return [
                                        _vm._v(
                                          _vm._s(_vm.$t("PLATE_NUMBER")) +
                                            "\n                "
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("validation-provider", {
                            attrs: {
                              rules: "max:20",
                              name: _vm.$t("PLATE_NUMBER")
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-text-field", {
                                      attrs: {
                                        label: _vm.$t("PLATE_NUMBER"),
                                        "error-messages": errors,
                                        solo: "",
                                        color: "grey",
                                        dense: "",
                                        "hide-details": "auto",
                                        outlined: ""
                                      },
                                      model: {
                                        value: _vm.fields.plate_number,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.fields,
                                            "plate_number",
                                            $$v
                                          )
                                        },
                                        expression: "fields.plate_number"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "div",
                            { staticClass: "mb-2 d-flex flex-row" },
                            [
                              _c("span", { staticClass: "text-body-2 mr-1" }, [
                                _vm._v(_vm._s(_vm.$t("DESCRIPTION")))
                              ]),
                              _vm._v(" "),
                              _c("FormTooltip", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "tooltip-trigger",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              "x-small": "",
                                              color: "primary lighten-1",
                                              text: "",
                                              icon: ""
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-information-outline")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "tooltip-content",
                                    fn: function() {
                                      return [
                                        _vm._v(
                                          _vm._s(_vm.$t("DESCRIPTION")) +
                                            "\n                "
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("validation-provider", {
                            attrs: {
                              rules: "max:256",
                              name: _vm.$t("DESCRIPTION")
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-textarea", {
                                      attrs: {
                                        rows: "2",
                                        label: _vm.$t("DESCRIPTION"),
                                        "error-messages": errors,
                                        solo: "",
                                        color: "grey",
                                        dense: "",
                                        "hide-details": "auto",
                                        outlined: ""
                                      },
                                      model: {
                                        value: _vm.fields.description,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.fields,
                                            "description",
                                            $$v
                                          )
                                        },
                                        expression: "fields.description"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "mb-3 justify-center flex-column flex-sm-row d-flex"
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-sm-2 mb-2",
                          attrs: {
                            color: "primary",
                            outlined: "",
                            "max-width": "50%"
                          },
                          on: { click: _vm.closeDialog }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", "max-width": "50%" },
                          on: { click: _vm.save }
                        },
                        [_vm._v("Save")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }