<template>
  <v-dialog
    persistent
    v-model="show"
    content-class="dialog-settings"
    hide-overlay
    no-click-animation
    fullscreen
  >
    <v-card>
      <!-- <v-card-title>
        <span>{{ $t("SETTINGS") }}</span>
        <v-spacer></v-spacer>
        <v-btn icon x-small @click="closeDialog">
          <v-icon color="#E6E6E6" dense>mdi-close</v-icon>
        </v-btn>
      </v-card-title> -->
      <v-toolbar color="" dense elevation="2">
        <v-toolbar-title class="text-subtitle-2">{{
          $t("SETTINGS")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn plain icon x-small @click="closeDialog">
          <v-icon dense>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container fluid class="fill-height pa-0" style="height: calc(100vh - 48px);
    align-items: flex-start;
   ">
        <v-tabs vertical class="fill-height ">
          <v-tab>{{ $t("OBJECTS") }}</v-tab>
          <v-tab>{{ $t("GROUPS") }}</v-tab>
          <v-tab>{{ $t("DRIVERS") }}</v-tab>
          <v-tab>{{ $t("PASSENGERS") }}</v-tab>
          <v-tab>{{ $t("TRAILERS") }}</v-tab>

          <v-tab-item eager> <ObjectsTab /> </v-tab-item>
          <v-tab-item eager> <GroupsTab /> </v-tab-item>
          <v-tab-item eager> <DriversTab /> </v-tab-item>
          <v-tab-item eager> <PassengersTab /> </v-tab-item>
          <v-tab-item eager> <TrailersTab /> </v-tab-item>
        </v-tabs>
      </v-container>
      <!-- <v-card-actions class="justify-center"> </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ObjectsTab from '@/components/settings/ObjectsTab.vue'
import GroupsTab from '@/components/settings/GroupsTab.vue'
import DriversTab from '@/components/settings/DriversTab.vue'
import TrailersTab from '@/components/settings/TrailersTab.vue'
import PassengersTab from '@/components/settings/PassengersTab.vue'

export default {
  components: { ObjectsTab, GroupsTab, DriversTab, TrailersTab, PassengersTab },
  props: {
    show: Boolean
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters({})
  },
  created () {
    this.fetchPassengers()
    this.fetchTrailers()
  },
  methods: {
    ...mapActions({
      fetchPassengers: 'person/fetchPassengers',
      fetchTrailers: 'trailers/fetchTrailers'
    }),
    closeDialog () {
      this.$emit('show-settings-dialog', false)
    }
  },
  watch: {
    '$vuetify.breakpoint.mobile': function (newVal, oldVal) {
      if (this.$vuetify.breakpoint.mobile) {
        this.closeDialog()
      }
    }
  }
}
</script>

<style></style>
