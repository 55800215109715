<template>
  <div class="container--fluid upper-content">
    <div class="upper-content__table">
      <DevicesTable
        v-if="devicesData"
        :table-data="devicesData"
        v-on:selected-object="getSelectedObject"
        v-on:show-form="showForm = $event"
        :selected-object="selectedObject"
      />
    </div>
    <ObjectsForm
      :show="showForm"
      v-on:show-objects-dialog="setShowModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DevicesTable from '@/components/devices/DevicesTable.vue'
import ObjectsForm from '@/components/forms/ObjectsForm.vue'
export default {
  components: {
    DevicesTable, ObjectsForm
  },
  data () {
    return {
      showForm: false
    }
  },
  props: {
    selectedObject: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      // objects: 'objects/objectsData',
      // objectSettings: 'objects/objectSettings',
      getObjectSensorByName: 'objects/getObjectSensorByName',
      getSensorValue: 'objects/getSensorValue',
      getGroupName: 'groups/getGroupName'
    }),
    devicesData: function () {
      const tableData = []
      try {
        Object.keys(this.objectsNew).forEach((key) => {
          const dataSignal = this.getObjectSensorByName(key, 'Data Signal')

          let link = 0
          if (dataSignal && dataSignal.length >= 1) {
            if (dataSignal[0].formula === '') {
              if (
                this.objectsNew[key].data.params[dataSignal[0].param] >=
                dataSignal[0].hv
              ) {
                link = 100
              } else {
                link =
                  (this.objectsNew[key].data.params[dataSignal[0].param] /
                    dataSignal[0].hv) *
                  100
              }
            }
          }

          const satellitesInUse = this.getObjectSensorByName(
            key,
            'Satellites In Use'
          )
          const satellite =
            satellitesInUse && satellitesInUse.length >= 1
              ? this.objectsNew[key].data.params[satellitesInUse[0].param]
              : ''

          const ignition = this.getObjectSensorByName(key, 'Ignition')
          // const ign =
          //   ignition && ignition.length >= 1
          //     ? this.getSensorValue(
          //       key,
          //       'Ignition',
          //       this.objectsNew[key].data.params[ignition[0].param]
          //     )
          //     : ''
          let ign = ''

          if (ignition && ignition.length >= 1) {
            const sensorValue = this.getSensorValue(
              key,
              'Ignition')

            ign = sensorValue ? sensorValue.value_full : ''
          }

          tableData.push({
            id: key,
            locate: this.objectsNew[key].locate,
            visible: this.objectsNew[key].visible,
            follow: this.objectsNew[key].follow,
            device_name: this.objectsNew[key].settings.name,
            group: this.objectsNew[key].settings.group_id,
            group_name: this.getGroupName(this.objectsNew[key].settings.group_id),
            ign: ign,
            link: link,
            sat: satellite,
            speed: this.objectsNew[key].data ? this.objectsNew[key].data.speed : '',
            odometer: this.objectsNew[key].odometer
          })
        })
      } catch (error) {
        this.handleError(error)
      }

      return tableData
    }
  },
  methods: {
    getSelectedObject: function (objectId) {
      this.$emit('selected-object', objectId)
    },
    setShowModal: function (isShow, reload = false) {
      this.showForm = isShow
      this.id = ''

      if (reload && process.env.NODE_ENV === 'production') {
        this.initializeObjectData().catch((error) => {
          this.handleError(error)
        })
      }
    }
  }
}
</script>

<style></style>
