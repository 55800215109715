<template>
  <v-row :class="contentHeight">
    <v-col cols="12" md="6" class="d-flex pr-0">
      <v-data-table
        dense
        :headers="headers"
        :items="tableData"
        :options.sync="options"
        :server-items-length="totalEvents"
        :loading="loading"
        loading-text="Loading... Please wait"
        item-key="name"
        class="events-table overflow-y-auto"
        fixed-header
        mobile-breakpoint="300"
        :height="contentHeight"
        :style="'width:' + windowSize.x + 'px'"
        :footer-props="{ 'items-per-page-options': [100, 200, 300, 400] }"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        must-sort
        single-select
        @click:row="selectedRow"
        @pagination="paginated"
      >
        <template v-slot:top>
          <v-toolbar>
            <template v-slot:default>
              <v-col>
                <v-select
                  color="grey"
                  menu-props="dark"
                  item-color="#272727"
                  v-model="filter.imei"
                  :items="objectItems"
                  label="Filter by Object"
                  item-text="text"
                  item-value="value"
                  hide-details="auto"
                  required
                  solo
                  dense
                  outlined
                  @change="getDataFromApi()"
                  clearable
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  color="grey"
                  menu-props="dark"
                  item-color="#272727"
                  v-model="filter.trigger"
                  :items="eventNames"
                  label="Filter by Trigger Name"
                  hide-details="auto"
                  required
                  solo
                  dense
                  outlined
                  @change="getDataFromApi()"
                  clearable
                ></v-select>
              </v-col>

              <v-col class="flex-shrink-1">
                <v-btn
                  plain
                  outlined
                  @click="
                    trigger = ''
                    imei = ''
                  "
                >
                  Clear Filters
                </v-btn>
              </v-col>
            </template>
          </v-toolbar>
        </template>
        <template v-slot:header="">
          <thead v-if="$vuetify.breakpoint.width >= 300">
            <tr>
              <th class="text-align-center" colspan="2">{{ $t('MAP') }}</th>
              <th colspan="2" class="text-align-center">Event Details</th>
            </tr>
          </thead>
        </template>
        <template v-slot:header.time="{}">
          {{ $t('TIME') }}
        </template>
        <template v-slot:item.locate="{ item }">
          <v-tooltip bottom color="tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="viewOnMap(item.id)"
                x-small
                icon
                dense
                plain
                v-bind="attrs"
                v-on="on"
              >
                <v-img
                  max-width="18"
                  :src="
                    publicPath +
                    'assets/img/icons/' +
                    (item.locate === true ? 'locate-green.svg' : 'locate.svg')
                  "
                ></v-img>
              </v-btn>
            </template>
            <span>Click here to view event on map</span>
          </v-tooltip>
        </template>
        <template v-slot:item.device_name="{ item }">
          <v-tooltip bottom color="tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="viewOnMap(item.id)"
                dense
                plain
                v-bind="attrs"
                v-on="on"
                class="caption"
              >
                {{ item.device_name }}
              </v-btn>
            </template>
            <span>Click here to view event on map</span>
          </v-tooltip>
        </template>

        <template v-slot:item.dt_server="{ item }">
          {{
            [item.dt_server, 'YYYY-MM-DD HH:mm:ss']
              | moment('D/M/YYYY HH:mm:ss')
          }}
        </template>
      </v-data-table>
    </v-col>

    <v-col class="d-none d-md-flex pl-0" md="6">
      <v-sheet
        dark
        class="w-100 pl-2"
        style="overflow: auto;"
        v-resize="onResize"
      >
        <l-map
          :options="mapOptions"
          style="height: 100%;"
          :maxZoom="config.map_max_zoom"
          :minZoom="config.map_min_zoom"
          ref="map"
          @ready="ready"
          :center="defaultCenter"
          :zoom="defaultZoom"
        >
          <TileLayers />
        </l-map>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import TileLayers from '@/components/includes/TileLayers.vue'
import { LMap, LControl, LPopup, LMarker, LIcon, LTooltip } from 'vue2-leaflet'
import { eventMixin } from '@/mixins/eventMixin'
export default {
  mixins: [eventMixin],
  components: {
    LMap,
    TileLayers,
    LControl,
    LIcon,
    LPopup,
    LMarker,
    LTooltip,
  },
  data: () => ({
    // eventMarkers: {},
    sortBy: 'dt_server',
    sortDesc: true, // descending by default
    totalEvents: 0,
    tableData: [],
    currentEvents: {},
    loading: false,
    options: {},
    filter: {
      imei: '',
      trigger: '',
    },
    mapOptions: {
      zoomSnap: 0.5,
    },
    map: null,
    mapLayers: {
      events: {
        markers: {},
      },
    },
    headers: [
      {
        text: 'Locate',
        value: 'locate',
        sortable: false,
        align: 'center',
      },
      {
        text: 'Device',
        value: 'device_name',
        sortable: false,
        align: 'center',
      },
      {
        text: 'Trigger Name',
        value: 'trigger_name',
        sortable: false,

        align: 'center',
      },
      {
        text: 'Date',
        value: 'dt_server',

        align: 'center',
      },
    ],
  }),
  computed: {
    ...mapGetters({
      events: 'events/events',
      objectItems: 'objects/objectItems',
      eventNames: 'events/eventNames',
    }),
  },
  async created() {
    this.onResize()
  },
  methods: {
    ...mapActions({
      updateEventId: 'map/updateEventId',
      fetchEventsByBatch: 'events/fetchEventsByBatch',
      requestVideoFootage: 'images/requestVideoFootage',
    }),
    viewOnMap(id) {
      this.updateEventId(id)
      this.$router.push({
        name: 'mobile/event/details',
        params: { id: id, type: 'event' },
        query: { id: id, type: 'event' },
      })
    },
    async getDataFromApi() {
      try {
        this.loading = true
        const { sortBy, sortDesc, page, itemsPerPage } = this.options

        if (itemsPerPage === -1) {
          this.options.itemsPerPage = 20
        }
        const params = {
          page: page,
          rows: itemsPerPage === -1 ? 20 : itemsPerPage,
          sidx: sortBy.length ? sortBy[0] : 'dt_server',
          sord: sortDesc.length ? 'ASC' : 'DESC',
          imei: this.filter.imei === null ? '' : this.filter.imei,
          event_desc: this.filter.trigger === null ? '' : this.filter.trigger,
        }
        let data
        if (
          Object.keys(this.events).length >= 1 &&
          params.page === 1 &&
          params.items_per_page === 100 &&
          params.order === true &&
          params.sort_by === 'dt_server' &&
          params.imei === '' &&
          params.event_desc === ''
        ) {
          data = this.events
        } else {
          try {
            data = await this.fetchEventsByBatch(params)
          } catch (error) {
            this.handleError(error)
          }
        }

        const items = []
        this.currentEvents = data.events

        Object.values(this.currentEvents).forEach((ev, index) => {
          const eventData = ev
          const obj = this.getObjectByName(eventData.name)

          items.push({
            id: eventData.event_id,
            locate: true,
            device_name: eventData.name,
            trigger_name: eventData.event_desc,
            dt_server_orig: eventData.dt_server,
            dt_server: this.convertUTCToLocalTimezone(eventData.dt_server),
            index: index,
          })
        })
        this.tableData = items
        this.totalEvents = data.total
        this.loadEventMarkers()
        this.loading = false
      } catch (error) {
        this.handleError(error)
      }
    },

    selectedRow(item) {
      this.loadEventDetails(null, item.index)
    },
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true,
    },
    map: function () {
      if (this.map !== null && Object.keys(this.currentEvents).length) {
        this.loadEventMarkers()
      }
    },
  },
}
</script>

<style></style>
