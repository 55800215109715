var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isMobileView && _vm.$router.currentRoute.name !== "live"
    ? _c(
        "div",
        {
          staticClass: "right-content right__container right-content__container"
        },
        [
          _c(
            "div",
            { staticClass: "right-content__map" },
            [_c("TheMapTabs")],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }