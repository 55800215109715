<template>
  <div>
    <v-data-table
      :search="search"
      v-model="selectedTriggers"
      dense
      :headers="headers"
      :items="tableData"
      item-key="id"
      class="triggers-table"
      fixed-header
      mobile-breakpoint="300"
      show-select
      :height="height"
      :style="'width:' + width + 'px'"
      :footer-props="{'items-per-page-options':[100, 200, 300, 400]}"
    >
      <template v-slot:top>
        <v-toolbar :extended="$vuetify.breakpoint.xs">
          <v-row class="align-center" v-if="!$vuetify.breakpoint.xs">
            <v-col>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" v-bind="attrs" v-on="on" small>
                    <v-icon left> mdi-plus </v-icon>
                    Add
                  </v-btn>
                </template>
                <v-list dense tile>
                  <v-list-item
                    v-for="type in types"
                    :key="type"
                    @click="$emit('show-info-modal', [true, type])"
                  >
                    <v-list-item-title>{{ type }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-btn
                class="ml-3"
                color="primary"
                @click="remove()"
                small
                outlined
              >
                Remove
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="5"
              lg="4"
              class="d-flex justify-content-end"
            >
              <v-text-field
                solo
                dense
                single-line
                hide-details
                clearable
                outlined
                prepend-inner-icon="mdi-magnify"
                v-model="search"
                label="Search"
                color="grey"
                class=""
              ></v-text-field>
            </v-col>
          </v-row>
          <template v-slot:default v-if="$vuetify.breakpoint.xs">
            <v-btn
              class="mr-3"
              color="primary"
              @click="remove()"
              small
              outlined
            >
              Remove
            </v-btn>

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on" small>
                  <v-icon left> mdi-plus </v-icon>
                  Add
                </v-btn>
              </template>
              <v-list dense tile>
                <v-list-item
                  v-for="type in types"
                  :key="type"
                  @click="
                    showInfoModal = true;
                    fields.type = type;
                  "
                >
                  <v-list-item-title>{{ type }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:extension v-if="$vuetify.breakpoint.xs">
            <v-text-field
              solo
              dense
              single-line
              hide-details
              clearable
              outlined
              prepend-inner-icon="mdi-magnify"
              v-model="search"
              label="Search"
              color="grey"
              class=""
            ></v-text-field>
          </template>
        </v-toolbar>
      </template>

      <template v-slot:item.name="{ item }">
        <v-btn dense plain class="caption"
        :to="{
          name: 'mobile/triggers/edit',
          params: { trigger_id: item.id },
        }">
          {{ item.name }}
        </v-btn>
      </template>

      <template v-slot:item.active="{ item }">
        <v-chip
        class="ma-2"
        small
        :color="item.active ? 'success' : 'error'"
        text-color="white"
      >
        {{ item.active ? 'ACTIVE' : 'INACTIVE' }}
      </v-chip>
      </template>
      <template v-slot:item.edit="{ item }">
        <v-tooltip bottom color="tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              x-small
              icon
              dense
              plain
              v-bind="attrs"
              v-on="on"
              :to="{
                name: 'mobile/triggers/edit',
                params: { trigger_id: item.id },
              }"
            >
              <v-icon dense>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit trigger details</span>
        </v-tooltip>
      </template>
      <template v-slot:item.delete="{ item }">
        <v-tooltip bottom color="tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              x-small
              icon
              dense
              plain
              v-bind="attrs"
              v-on="on"
              @click="
                selectedTriggers = [item];
                selectedTriggerIds = [item.id];
                remove();
              "
            >
              <v-icon dense>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
          <span>Remove trigger</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <ConfirmDialog
      :info="confirmDetails"
      :show="showConfirm"
      v-on:show-confirm-dialog="confirmDelete"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import ConfirmDialog from '@/components/includes/ConfirmDialog.vue'
export default {
  components: {
    ConfirmDialog
  },
  props: {
    tableData: Array,
    height: Number,
    width: Number
  },
  data: () => ({
    types: ['Geofence', 'Ignition', 'Overspeed'],
    search: '',
    selectedTriggers: [],
    selectedTriggerIds: [],
    showConfirm: false,
    confirmDetails: {
      title: '',
      message: '',
      action: {
        yes: '',
        no: ''
      }
    },
    headers: [
      {
        text: 'Triggers',
        value: 'name',
        sortable: false,
        filterable: false
      },

      {
        text: 'Status',
        value: 'active',
        sortable: true,
        filterable: true
      },
      {
        text: '',
        value: 'edit',
        sortable: false,
        filterable: false
      },
      {
        text: '',
        value: 'delete',
        sortable: false,
        filterable: false
      }
    ]
  }),
  computed: {
    ...mapGetters({
      triggers: 'triggers/triggers'
    })
  },
  watch: {
    selectedTriggers: function () {
      const triggerIds = []
      try {
        this.selectedTriggers.forEach((trigger) => {
          triggerIds.push(trigger.id)
        })
        this.selectedTriggerIds = triggerIds
      } catch (error) {
        this.handleError(error)
      }
      return triggerIds
    }
  },
  methods: {
    ...mapActions({
      getTriggers: 'triggers/getTriggers',
      deleteTrigger: 'triggers/deleteTrigger'
    }),
    remove () {
      try {
        if (this.selectedTriggerIds.length === 0) {
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'error',
            title: this.$t('ERROR'),
            text: 'Please select at least 1 trigger'
          })
        } else {
          this.$log.debug(this.selectedTriggerIds)
          this.$log.debug(this.selectedTriggers)
          this.confirmDetails = {
            title: 'Confirm',
            message: 'Are you sure?'
          }
          this.showConfirm = true
        }
      } catch (error) {
        this.handleError(error)
      }
    },

    confirmDelete (val) {
      try {
        this.showConfirm = false
        if (val) {
          this.deleteTrigger(this.selectedTriggerIds)
            .then((response) => {
              if (response.message === 'OK') {
                this.$notify({
                    duration: 5000,
                    closeOnClick: false,
                  type: 'success',
                  title: this.$t('INFORMATION'),
                  text: this.$t('CHANGES_SAVED_SUCCESSFULLY')
                })
                this.getTriggers().catch((error) => {
                  this.handleError(error)
                })
              }
            })
            .catch((error) => {
              this.handleError(error)
            })
        }
      } catch (error) {
        this.handleError(error)
      }
    }
  },
  created () {}
}
</script>

<style></style>
