<template>
  <v-container
    fluid
    id="scroll-target"
    style="max-height: calc(100vh - 56px)"
    class="overflow-y-auto"
  >
    <v-row
      class="ma-1 d-flex flex-column align-content-center"
      style="height: auto"
    >
      <v-col cols="12" md="8" xl="6">
        <v-card dark outlined class="w-100 mb-3">
          <validation-observer ref="observer">
            <v-container fluid>
              <v-row align="center">
                <v-col class="d-flex" cols="12" sm="6">
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    rules="required"
                    :name="$t('MAP_STARTUP_POSITION')"
                  >
                    <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{
                        $t("MAP_STARTUP_POSITION")
                      }}</span>
                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("MAP_STARTUP_POSITION") }}
                          <a href="google.com" target="_blank"
                            >test link hey</a
                          ></template
                        >
                      </FormTooltip>
                    </div>
                    <v-select
                      color="grey"
                      menu-props="dark"
                      item-color="#272727"
                      v-model="fields.position"
                      :items="options.startupPosition"
                      :label="$t('MAP_STARTUP_POSITION')"
                      item-text="text"
                      item-value="value"
                      hide-details="auto"
                      :error-messages="errors"
                      required
                      solo
                      dense
                      outlined
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    rules="required"
                    :name="$t('MAP_ICON_SIZE')"
                  >
                    <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{
                        $t("MAP_ICON_SIZE")
                      }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("MAP_ICON_SIZE") }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                    <v-select
                      color="grey"
                      menu-props="dark"
                      item-color="#272727"
                      v-model="fields.size"
                      :items="options.size"
                      :label="$t('MAP_ICON_SIZE')"
                      item-text="text"
                      item-value="value"
                      hide-details="auto"
                      :error-messages="errors"
                      required
                      solo
                      dense
                      outlined
                    ></v-select
                  ></validation-provider>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6">
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    rules="required"
                    :name="$t('LANGUAGE')"
                  >
                    <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t("LANGUAGE") }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("LANGUAGE") }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                    <v-select
                      color="grey"
                      menu-props="dark"
                      item-color="#272727"
                      v-model="fields.language"
                      :items="options.language"
                      :label="$t('LANGUAGE')"
                      item-text="text"
                      item-value="value"
                      hide-details="auto"
                      :error-messages="errors"
                      required
                      solo
                      dense
                      outlined
                    ></v-select
                  ></validation-provider>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    rules="required"
                    :name="$t('UNIT_OF_DISTANCE')"
                  >
                    <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{
                        $t("UNIT_OF_DISTANCE")
                      }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("UNIT_OF_DISTANCE") }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                    <v-select
                      color="grey"
                      menu-props="dark"
                      item-color="#272727"
                      v-model="fields.distance"
                      :items="options.distance"
                      :label="$t('UNIT_OF_DISTANCE')"
                      item-text="text"
                      item-value="value"
                      hide-details="auto"
                      :error-messages="errors"
                      required
                      solo
                      dense
                      outlined
                    ></v-select
                  ></validation-provider>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6">
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    rules="required"
                    :name="$t('UNIT_OF_CAPACITY')"
                  >
                    <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{
                        $t("UNIT_OF_CAPACITY")
                      }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("UNIT_OF_CAPACITY") }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                    <v-select
                      color="grey"
                      menu-props="dark"
                      item-color="#272727"
                      v-model="fields.capacity"
                      :items="options.capacity"
                      :label="$t('UNIT_OF_CAPACITY')"
                      item-text="text"
                      item-value="value"
                      hide-details="auto"
                      :error-messages="errors"
                      required
                      solo
                      dense
                      outlined
                    ></v-select
                  ></validation-provider>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    rules="required"
                    :name="$t('UNIT_OF_TEMPERATURE')"
                  >
                    <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{
                        $t("UNIT_OF_TEMPERATURE")
                      }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("UNIT_OF_TEMPERATURE") }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                    <v-select
                      color="grey"
                      menu-props="dark"
                      item-color="#272727"
                      v-model="fields.temperature"
                      :items="options.temperature"
                      :label="$t('UNIT_OF_TEMPERATURE')"
                      item-text="text"
                      item-value="value"
                      hide-details="auto"
                      :error-messages="errors"
                      required
                      solo
                      dense
                      outlined
                    ></v-select
                  ></validation-provider>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    rules="required"
                    :name="$t('TIMEZONE')"
                  >
                    <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t("TIMEZONE") }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("TIMEZONE") }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                    <v-select
                      color="grey"
                      menu-props="dark"
                      item-color="#272727"
                      v-model="fields.timezone"
                      :items="options.timezone"
                      :label="$t('TIMEZONE')"
                      item-text="text"
                      item-value="value"
                      hide-details="auto"
                      :error-messages="errors"
                      required
                      solo
                      dense
                      outlined
                    ></v-select
                  ></validation-provider>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6">
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    rules="required"
                    :name="$t('MAP')"
                  >
                    <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t("MAP") }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("MAP") }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                    <v-select
                      color="grey"
                      menu-props="dark"
                      item-color="#272727"
                      v-model="fields.map"
                      :items="mapList"
                      :label="$t('MAP')"
                      item-text="name"
                      item-value="value"
                      hide-details="auto"
                      :error-messages="errors"
                      required
                      solo
                      dense
                      outlined
                    ></v-select
                  ></validation-provider>
                </v-col>
              </v-row>
            </v-container>
          </validation-observer>
        </v-card>
      </v-col>
      <v-col cols="12" md="8" xl="6">
        <v-row
          class="
            justify-center
            align-content-center
            d-flex
            flex-column flex-sm-row
          "
        >
          <v-btn
            class="mr-sm-2 mb-2"
            @click="reset()"
            color="primary"
            outlined
            width="200px"
            >Reset</v-btn
          >
          <v-btn @click="save()" color="primary" width="200px">Save</v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import FormTooltip from '@/components/includes/FormTooltip.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormTooltip
  },
  data: () => ({
    tooltip: {
      position: false
    },
    fields: {
      position: '', //
      size: '', //
      language: '', //
      distance: '', //
      capacity: '', //
      temperature: '', //
      timezone: '', //
      map: ''
    },
    options: {
      startupPosition: [
        { value: 'default', text: 'Default' },
        { value: 'last', text: 'Remember last' },
        { value: 'fit', text: 'Fit objects' }
      ],
      size: [
        { value: 1, text: '100%' },
        { value: 1.25, text: '125%' },
        { value: 1.5, text: '150%' },
        { value: 1.75, text: '175%' },
        { value: 2, text: '200%' }
      ],
      distance: [
        { value: 'km', text: 'Kilometer' },
        { value: 'mi', text: 'Mile' },
        { value: 'nm', text: 'Nautical mile' }
      ],
      capacity: [
        { value: 'l', text: 'Liter' },
        { value: 'g', text: 'Gallon' }
      ],
      temperature: [
        { value: 'c', text: 'Celsius' },
        { value: 'f', text: 'Fahrenheit' }
      ],
      timezone: [
        { value: '- 12 hour', text: '(UTC -12:00)' },
        { value: '- 11 hour', text: '(UTC -11:00)' },
        { value: '- 10 hour - 30 minutes', text: '(UTC -10:30)' },
        { value: '- 10 hour', text: '(UTC -10:00)' },
        { value: '- 9 hour', text: '(UTC -9:00)' },
        { value: '- 8 hour', text: '(UTC -8:00)' },
        { value: '- 7 hour', text: '(UTC -7:00)' },
        { value: '- 6 hour', text: '(UTC -6:00)' },
        { value: '- 5 hour', text: '(UTC -5:00)' },
        { value: '- 4 hour - 30 minutes', text: '(UTC -4:30)' },
        { value: '- 4 hour', text: '(UTC -4:00)' },
        { value: '- 3 hour - 30 minutes', text: '(UTC -3:30)' },
        { value: '- 3 hour', text: '(UTC -3:00)' },
        { value: '- 2 hour', text: '(UTC -2:00)' },
        { value: '- 1 hour', text: '(UTC -1:00)' },
        { value: '+ 0 hour', text: '(UTC 0:00)' },
        { value: '+ 1 hour', text: '(UTC +1:00)' },
        { value: '+ 2 hour', text: '(UTC +2:00)' },
        { value: '+ 3 hour', text: '(UTC +3:00)' },
        { value: '+ 3 hour + 30 minutes', text: '(UTC +3:30)' },
        { value: '+ 4 hour', text: '(UTC +4:00)' },
        { value: '+ 4 hour + 30 minutes', text: '(UTC +4:30)' },
        { value: '+ 4 hour + 45 minutes', text: '(UTC +4:45)' },
        { value: '+ 5 hour', text: '(UTC +5:00)' },
        { value: '+ 5 hour + 30 minutes', text: '(UTC +5:30)' },
        { value: '+ 5 hour + 45 minutes', text: '(UTC +5:45)' },
        { value: '+ 6 hour', text: '(UTC +6:00)' },
        { value: '+ 6 hour + 30 minutes', text: '(UTC +6:30)' },
        { value: '+ 7 hour', text: '(UTC +7:00)' },
        { value: '+ 8 hour', text: '(UTC +8:00)' },
        { value: '+ 9 hour', text: '(UTC +9:00)' },
        { value: '+ 9 hour + 30 minutes', text: '(UTC +9:30)' },
        { value: '+ 10 hour', text: '(UTC +10:00)' },
        { value: '+ 10 hour + 30 minutes', text: '(UTC +10:30)' },
        { value: '+ 11 hour', text: '(UTC +11:00)' },
        { value: '+ 12 hour', text: '(UTC +12:00)' },
        { value: '+ 12 hour + 45 minutes', text: '(UTC +12:45)' },
        { value: '+ 13 hour', text: '(UTC +13:00)' },
        { value: '+ 14 hour', text: '(UTC +14:00)' }
      ],
      language: [
        { value: 'albanian', text: 'Albanian' },
        { value: 'arabic', text: 'Arabic' },
        { value: 'bulgarian', text: 'Bulgarian' },
        { value: 'chinese', text: 'Chinese' },
        { value: 'croatian', text: 'Croatian' },
        { value: 'danish', text: 'Danish' },
        { value: 'dutch', text: 'Dutch' },
        { value: 'english', text: 'English' },
        { value: 'estonian', text: 'Estonian' },
        { value: 'farsi', text: 'Farsi' },
        { value: 'french', text: 'French' },
        { value: 'german', text: 'German' },
        { value: 'greek', text: 'Greek' },
        { value: 'hungarian', text: 'Hungarian' },
        { value: 'italian', text: 'Italian' },
        { value: 'japanese', text: 'Japanese' },
        { value: 'latvian', text: 'Latvian' },
        { value: 'lithuanian', text: 'Lithuanian' },
        { value: 'nederlands', text: 'Nederlands' },
        { value: 'norsk', text: 'Norsk' },
        { value: 'polish', text: 'Polish' },
        { value: 'portuguese', text: 'Portuguese' },
        { value: 'romanian', text: 'Romanian' },
        { value: 'russian', text: 'Russian' },
        { value: 'serbian', text: 'Serbian' },
        { value: 'slovak', text: 'Slovak' },
        { value: 'spanish', text: 'Spanish' },
        { value: 'swedish', text: 'Swedish' },
        { value: 'thai', text: 'Thai' },
        { value: 'turkish', text: 'Turkish' }
      ]
    }
  }),
  computed: {
    ...mapGetters({
      user: 'settings/user',
      mapList: 'settings/mapList'
    })
  },
  async created () {
    await Promise.all([
      this.fetchSiteSettings('load_server_data'),
      this.fetchSiteSettings('load_user_data')
    ])
      .then(() => {
        this.reset()
      })
      .catch((error) => {
        this.handleError(error)
      })
  },
  methods: {
    ...mapActions({
      saveSettings: 'settings/saveSettings',
      fetchSiteSettings: 'settings/fetchSiteSettings'
    }),
    reset () {
      try {
        this.fields = {
          position: this.user.map_sp,
          size: this.user.map_is,
          language: this.user.language,
          distance: this.user.unit_distance,
          capacity: this.user.unit_capacity,
          temperature: this.user.unit_temperature,
          timezone: this.user.timezone,
          map: this.user.map_layer
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    async save () {
      try {
        if (this.checkPrivilege('viewer')) {
          const valid = await this.$refs.observer.validate()
          if (valid) {
            const changedParams = {
              cmd: 'save_user_settings',
              map_sp: this.fields.position,
              map_is: this.fields.size,
              language: this.fields.language,
              timezone: this.fields.timezone,
              units:
                this.fields.distance +
                ',' +
                this.fields.capacity +
                ',' +
                this.fields.temperature
              // map_layer: this.fields.map
            }

            const otherParams = {
              sms_gateway: 'na',
              sms_gateway_type: 'na',
              sms_gateway_url: 'na',
              sms_gateway_identifier: 'na',
              chat_notify: 'na',
              map_rc: 'na',
              map_rhc: 'na',
              groups_collapsed: 'na',
              od: 'na',
              ohc: 'na',
              currency: 'na',
              dst: 'na',
              dst_start: 'na',
              dst_end: 'na',
              info: 'na',
              old_password: '',
              new_password: ''
            }

            let params = changedParams
            if (process.env.NODE_ENV === 'production') {
              params = { ...params, ...otherParams }
            }

            const settings = {
              map: {
                cmd: 'save_map',
                map: this.fields.map
              },
              settings: params
            }
            this.saveSettings(settings)
              .then((response) => {
                if (response) {
                  Promise.all([
                    this.fetchSiteSettings('load_server_data'),
                    this.fetchSiteSettings('load_user_data')
                  ])
                    .then(() => {
                      this.$loadLanguageAsync(params.language)
                      this.$notify({
                    duration: 5000,
                    closeOnClick: false,
                        type: 'success',
                        title: this.$t('INFORMATION'),
                        text: this.$t('CHANGES_SAVED_SUCCESSFULLY')
                      })
                    })
                    .catch((error) => {
                      this.handleError(error)
                    })
                }
              })
              .catch((error) => {
                this.handleError(error)
              })
          }
        } else {
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'error',
            title: this.$t('ERROR'),
            text: this.$t('THIS_ACCOUNT_HAS_NO_PRIVILEGES_TO_DO_THAT')
          })
        }
      } catch (error) {
        this.handleError(error)
      }
    }
  }
}
</script>

<style></style>
