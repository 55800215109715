var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("notifications", {
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function(ref) {
          var item = ref.item
          var close = ref.close
          return [
            _c("div", { class: "vue-notification " + item.type }, [
              _c(
                "p",
                { staticClass: "notification-title mb-2" },
                [
                  item.type === "success"
                    ? _c("v-icon", { attrs: { dense: "" } }, [
                        _vm._v("mdi-check")
                      ])
                    : item.type === "warn"
                    ? _c("v-icon", { attrs: { dense: "" } }, [
                        _vm._v("mdi-alert")
                      ])
                    : item.type === "error"
                    ? _c("v-icon", { attrs: { dense: "" } }, [
                        _vm._v("mdi-close-octagon")
                      ])
                    : _c("v-icon", { attrs: { dense: "" } }, [
                        _vm._v("mdi-information")
                      ]),
                  _vm._v("\n\n        " + _vm._s(item.title) + "\n\n        "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "float-right",
                      attrs: { icon: "", "x-small": "", plain: "", text: "" },
                      on: { click: close }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", {
                staticClass: "notification-content",
                domProps: { innerHTML: _vm._s(item.text) }
              })
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }