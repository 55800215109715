<template>
  <div class="street-view">
    <div class="street-view__content"></div>
  </div>
</template>

<script>
export default {
  props: ['streetViewData']
}
</script>

<style></style>
