<template>
  <v-dialog
    persistent
    v-model="showCommandForm"
    hide-overlay
    no-click-animation
    max-width="370"
    width="auto"
  >
    <v-card>
      <v-toolbar color="" dense elevation="2">
        <v-toolbar-title class="text-subtitle-2">
          Send {{ $t(gateway) }} Command</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn plain icon x-small @click="closeDialog">
          <v-icon dense>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container fluid>
        <validation-observer ref="observer">
          <v-row>
            <v-col cols="12">
              <validation-provider
                class="w-100"
                v-slot="{ errors }"
                :name="$t('OBJECTS')"
              >
                <div class="mb-2 d-flex flex-row">
                  <span class="text-body-2 mr-1">{{ $t("OBJECTS") }}</span>

                  <FormTooltip>
                    <template v-slot:tooltip-trigger>
                      <v-btn x-small color="primary lighten-1" text icon>
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:tooltip-content
                      >{{ $t("OBJECTS") }}
                    </template>
                  </FormTooltip>
                </div>
                <v-select
                  menu-props="dark"
                  item-color="#272727"
                  v-model="fields.object"
                  :items="objectItems"
                  :label="$t('OBJECT')"
                  item-text="text"
                  item-value="value"
                  solo
                  color="grey"
                  dense
                  hide-details="auto"
                  outlined
                  :error-messages="errors"
                >
                </v-select>
              </validation-provider>
            </v-col>
            <v-col cols="12">
              <validation-provider
                class="w-100"
                v-slot="{ errors }"
                rules="required"
                :name="$t('TEMPLATE')"
              >
                <div class="mb-2 d-flex flex-row">
                  <span class="text-body-2 mr-1">{{ $t("TEMPLATE") }}</span>

                  <FormTooltip>
                    <template v-slot:tooltip-trigger>
                      <v-btn x-small color="primary lighten-1" text icon>
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:tooltip-content
                      >{{ $t("TEMPLATE") }}
                    </template>
                  </FormTooltip>
                </div>
                <v-select
                  color="grey"
                  menu-props="dark"
                  item-color="#272727"
                  @change="setCommand()"
                  v-model="fields.template"
                  :items="templateItems(fields.gateway, fields.object)"
                  :label="$t('TEMPLATE')"
                  item-text="text"
                  item-value="value"
                  hide-details="auto"
                  :error-messages="errors"
                  required
                  solo
                  dense
                  outlined
                ></v-select>
              </validation-provider>
            </v-col>

            <v-col cols="12">
                <div class="mb-2 d-flex flex-row">
                  <span class="text-body-2 mr-1">{{ $t("COMMAND") }}</span>

                  <FormTooltip>
                    <template v-slot:tooltip-trigger>
                      <v-btn x-small color="primary lighten-1" text icon>
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:tooltip-content
                      >{{ $t("COMMAND") }}
                    </template>
                  </FormTooltip>
                </div>
                <v-row>
                  <v-col>

              <validation-provider
                class="w-100"
                v-slot="{ errors }"
                rules="required"
                :name="$t('TYPE')"
              >
                    <v-select color="grey"
                      menu-props="dark"
                      item-color="#272727"
                      v-model="fields.type"
                      :items="['ascii', 'hex']"
                      :label="$t('TYPE')"
                      item-text="text"
                      item-value="value"
                      hide-details="auto"
                      :error-messages="errors"
                      required
                      solo
                      dense
                      outlined
                      :readonly="fields.template !== 'custom' && fields.template !== ''"
                    >
                    <template v-slot:item="{ item }">
                      {{ item.toUpperCase() }}
                    </template>
                    <template v-slot:selection="{ item }">
                      {{ item.toUpperCase() }}
                    </template>
                    </v-select>

              </validation-provider>
                  </v-col>
                  <v-col>
                    <validation-provider
                class="w-100"
                v-slot="{ errors }"
                rules="required"
                :name="$t('COMMAND')"
              >
                    <v-text-field
                      color="grey"
                      :readonly="fields.template !== 'custom' && fields.template !== ''"
                      v-model="fields.command"
                      :label="$t('COMMAND')"
                      hide-details="auto"
                      :error-messages="errors"
                      required
                      solo
                      dense
                      outlined
                    ></v-text-field>
              </validation-provider>
                  </v-col>
                </v-row>

            </v-col>
          </v-row>

          <v-row class="mb-3 justify-center flex-column flex-sm-row d-flex">
            <v-btn
              @click="closeDialog"
              color="primary"
              outlined
              class="mr-sm-2 mb-2"
              max-width="50%"
              >Cancel</v-btn
            >
            <v-btn @click="save" color="primary" max-width="50%">Save</v-btn>
          </v-row>
        </validation-observer>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

import { commandMixin } from '@/mixins/commandMixin'
export default {
  mixins: [commandMixin],

  props: {
    showCommandForm: Boolean,
    id: {
      default: null
    },
    gateway: String
  },

  created () {

  },
  computed: {

  },
  methods: {

    closeDialog () {
      this.$refs.observer.reset()
      this.fields = {
        object: '',
        template: '',
        gateway: '',
        type: '',
        command: ''
      }
      this.$emit('show-command-dialog', false)
    }
  },
  watch: {
    showCommandForm: function () {
      if (this.showCommandForm) {
        if (this.id !== null) {
          this.fields.object = this.id
        }

        this.fields.gateway = this.gateway.toLowerCase()
      } else {
        this.fields = {
          object: '',
          template: '',
          gateway: '',
          type: '',
          command: ''
        }
      }
    }

  }
}
</script>

<style>
</style>
