import { i18n } from '@/plugins/i18n'
import { required, email, max } from 'vee-validate/dist/rules'
import { mapFunctions } from '@/plugins/map'
import moment from 'moment'
import {
  extend
} from 'vee-validate'

extend('required', {
  ...required,
  message: '{_field_} can\'t be empty'
})
extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters'
})
extend('email', {
  ...email,
  message: '{_field_} is not valid'
})

extend('agree', {
  validate (value, { max }) {
    return value === true
  },
  message: 'You must accept the terms of service to use this. Please read them first.'
})

extend('requiredPlaceName', {
  validate (value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    }
  },
  computesRequired: true,
  message: i18n.t('NAME_CANT_BE_EMPTY')
})

extend('requiredPlaceMarkerPoints', {
  validate (value) {
    // this.$log.debug('points changed')
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    }
  },
  computesRequired: true,
  message: 'this is a message'
})

extend('greaterThan', {
  validate (value, { compare }) {
    return value > compare
  },
  params: ['compare'],
  message: (fieldName, placeholders) => {
    if (fieldName === 'Deviation') {
      return i18n.t('DEVIATION_CANT_BE_LESS_THAN_0')
    }
    return `The ${fieldName} field must be greater than ${placeholders.compare}`
  }
})

extend('number', {
  validate: value => {
    return !isNaN(parseFloat(value)) && !isNaN(value - 0)
  },
  message: 'The {_field_} field must be a number'
})

extend('requiredPlacePoints', {
  validate (value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    }
  },
  computesRequired: true,
  message: (fieldName, placeholders) => {
    const msg = fieldName === 'routePoints' ? 'DRAW_ROUTE_ON_MAP_BEFORE_SAVING' : 'DRAW_ZONE_ON_MAP_BEFORE_SAVING'
    return i18n.t(msg)
  }
})

extend('minPoints', {
  validate (value, { min }) {
    return mapFunctions.convertPointStringToLatLng(value).length >= parseInt(min)
  },
  params: ['min'],
  message: (fieldName, placeholders) => {
    const msg = fieldName === 'routePoints' ? 'DRAW_ROUTE_ON_MAP_BEFORE_SAVING' : 'DRAW_ZONE_ON_MAP_BEFORE_SAVING'
    return i18n.t(msg)
  }
})

extend('maxPoints', {
  validate (value, { max }) {
    return mapFunctions.convertPointStringToLatLng(value).length <= parseInt(max)
  },
  params: ['max'],
  message: (fieldName, placeholders) => {
    const msg = fieldName === 'routePoints' ? 'ROUTE_CANT_HAVE_MORE_THAN_NUM_POINTS' : 'ZONE_CANT_HAVE_MORE_THAN_NUM_VERTICES'
    return i18n.t(msg)
  }
})

extend('imei', {
  validate (value, { max }) {
    const regex = /^[a-zA-Z0-9]{1,15}$/
    return value.match(regex) && (value.length === 10 || value.length === 15)
  },
  message: 'IMEI is not valid.'
})

extend('dimensions', {
  ...email,
  message: i18n.t('IMAGE_SIZE_SHOULD_NOT_BE_BIGGER_THAN_640_480')
})

extend('requiredProtocol', {
  validate (value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    }
  },
  computesRequired: true,
  message: 'Select an object to set Protocol'
})

extend('requiredPlacePoints', {
  validate (value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    }
  },
  computesRequired: true,
  message: (fieldName, placeholders) => {
    const msg = fieldName === 'routePoints' ? 'DRAW_ROUTE_ON_MAP_BEFORE_SAVING' : 'DRAW_ZONE_ON_MAP_BEFORE_SAVING'
    return i18n.t(msg)
  }
})

extend('dateGreaterThan', {
  validate (value, { dtf }) {
    return moment(value).isSameOrAfter(dtf)
  },
  params: ['dtf'],
  message: (fieldName, placeholders) => {
    // return 'Please select a later date'
    return i18n.t('DATE_CANT_BE_EMPTY')
  }
})
