import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/store'

import TheLayout from '@/layout/TheLayout.vue'

// Tab Contents
import Login from '@/pages/authentication/Login.vue'
// import Verify from '@/pages/authentication/Verify.vue'
import Register from '@/pages/authentication/Register.vue'
import ForgotPassword from '@/pages/authentication/ForgotPassword.vue'
import AuthLayout from '@/pages/authentication/AuthLayout.vue'
import Tracking from '@/pages/Tracking.vue'
import Devices from '@/pages/Devices.vue'
import Personnel from '@/pages/Personnel.vue'
import Events from '@/pages/Events.vue'
import Places from '@/pages/Places.vue'
import Beacons from '@/pages/Beacons.vue'
import Reports from '@/pages/Reports.vue'
import FollowObject from '@/pages/FollowObject.vue'
import EventDetails from '@/pages/EventDetails.vue'
import Live from '@/pages/Live.vue'

import Objects from '@/pages/mobile/Objects.vue'
import Map from '@/pages/mobile/Map.vue'
import ObjectControl from '@/pages/mobile/ObjectControl.vue'
import History from '@/pages/mobile/History.vue'
import Settings from '@/pages/mobile/Settings.vue'
import MEvents from '@/pages/mobile/Events.vue'
import ObjectDetails from '@/pages/mobile/ObjectDetails.vue'
import Triggers from '@/pages/mobile/Triggers.vue'
import TriggerFullVersionForm from '@/components/triggers/TriggerFullVersionForm.vue'
import EventsList from '@/components/events/EventsList.vue'
import EventsVideoList from '@/components/events/EventsVideoList.vue'

// map tabs

Vue.use(VueRouter)
const routes = [
  {
    path: '/follow/:imei?',
    name: 'follow',
    component: FollowObject,
    props: true,
    beforeEnter: async (to, from, next) => {
      if (typeof to.params.imei === 'undefined') {
        Vue.notify({
          type: 'error',
          title: 'Error',
          text: 'No records to view',
          duration: 5000,
          closeOnClick: false,
        })

        next({ name: 'mobile/map' })
      } else {
        next()
      }
    },
  },
  {
    path: '/mobile',
    name: 'AuthLayout',
    redirect: '/mobile/login',
    component: AuthLayout,
    beforeEnter: async (to, from, next) => {
      try {
        const check = await store.dispatch('auth/checkSession')
        if (check && check.session === 'true') {
          if (store.state.settings.user.length === 0) {
            await store.dispatch('settings/fetchSiteSettings', 'load_user_data')
          }
          const user = store.state.settings.user

          if (user.verified === false) {
            // next({ name: 'verify' })

            window.location.href = '/verify?mobile=yes'
          } else if (user.totp_required) {
            window.location.href = '/2fa?mobile=yes'
          } else {
            if (from.name === 'login') {
              this.$notify({
                duration: 5000,
                closeOnClick: false,
                type: 'success',
                title: 'Info',
                text: 'Login Successful',
              })
            }
            next({ name: 'mobile/map' })
          }
        } else {
          next() // go to /login
        }
      } catch (error) {
        next() // go to /login
      }
    },
    children: [
      {
        path: '/mobile/login',
        name: 'login',
        component: Login,
      },
      {
        path: '/mobile/register',
        name: 'register',
        component: Register,
      },

      {
        path: '/mobile/forgot',
        name: 'forgot',
        component: ForgotPassword,
      },

      {
        path: '/mobile/recover/:token?',
        name: 'recover',
        component: Login,
        props: true,
        beforeEnter: async (to, from, next) => {
          if (typeof to.params.token === 'undefined') {
            next({ name: 'login' })
          } else {
            next()
          }
        },
      },
      // {
      //   path: '/mobile/verify',
      //   name: 'verify',
      //   component: Verify,
      //   beforeEnter: async (to, from, next) => {
      //     try {
      //       const check = await store.dispatch('auth/checkSession')
      //       if (check && check.session === 'true') {
      //         if (store.state.settings.user.length === 0 || from.name === 'verify') {
      //           await store.dispatch('settings/fetchSiteSettings', 'load_user_data')
      //         }
      //         const user = store.state.settings.user

      //         if (user.verified === false) {
      //           next()
      //         } else {
      //           next({ name: 'mobile/map' })
      //         }
      //       } else {
      //         next({ name: 'login' })
      //       }
      //     } catch (error) {
      //     }

      //   }
      // },
    ],
  },

  {
    path: '/',
    redirect: '/mobile/',
    name: 'Home',
    component: TheLayout,
    children: [
      {
        path: '/live/:imei?',
        name: 'live',
        component: Live,
        props: true,
        beforeEnter: async (to, from, next) => {
          if (typeof to.params.imei === 'undefined') {

            Vue.notify({
              duration: 5000,
              closeOnClick: false,
              type: 'error',
              title: 'Error',
              text: 'No records to view',
            })
            next({ name: 'mobile/map' })
          } else {
            next()
          }
        },
      },
      {
        path: 'tracking',
        name: 'tracking',
        component: Tracking,
      },
      {
        path: 'devices',
        name: 'devices',
        component: Devices,
      },
      {
        path: 'personnel',
        name: 'personnel',
        component: Personnel,
      },
      {
        path: 'events',
        name: 'events',
        component: Events,
      },
      {
        path: 'places',
        name: 'places',
        component: Places,
      },
      {
        path: 'beacons',
        name: 'Beacons',
        component: Beacons,
      },
      {
        path: 'reports',
        name: 'reports',
        component: Reports,
      },
    ],
  },
  {
    path: '/mobile', // should match the ASSET_PATH for the deep link to work
    name: 'mobile',
    component: TheLayout,
    beforeEnter: async (to, from, next) => {
      // console.log('mobile')
      try {
        const check = await store.dispatch('auth/checkSession')
        if (check && check.session === 'true') {
          if (store.state.settings.user.length === 0) {
            await store.dispatch('settings/fetchSiteSettings', 'load_user_data')
          }
          const user = store.state.settings.user

          if (user.verified === false) {
            // next({ name: 'verify' })

            window.location.href = '/verify?mobile=yes'
          } else if (user.totp_required) {
            window.location.href = '/2fa?mobile=yes'
          } else {
            if (from.name === 'login') {
              Vue.notify({
                duration: 5000,
                closeOnClick: false,
                type: 'success',
                title: 'Info',
                text: 'Login Successful',
              })
            }
            next() // proceed to /mobile
          }
        } else {
          if (from.name === 'login') {
            Vue.notify({
              duration: 5000,
              closeOnClick: false,
              type: 'error',
              title: 'Error',
              text: 'Session expired. Please login again.',
            })
          } else {
            next({ name: 'login' })
          }
        }
      } catch (error) {
        Vue.notify({
          duration: 5000,
          closeOnClick: false,
          type: 'error',
          title: 'Error',
          text: 'Something went wrong.',
        })
        next({ name: 'login' })
      }
    },
    children: [
      {
        path: '/',
        name: 'mobile/map',
        component: Map,
      },
      {
        path: 'follow/:imei?',
        name: 'mobile/follow',
        component: FollowObject,
        props: true,
        beforeEnter: async (to, from, next) => {
          if (typeof to.params.imei === 'undefined') {
            Vue.notify({
              type: 'error',
              title: 'Error',
              text: 'No records to view',
              duration: 5000,
              closeOnClick: false,
            })
            next({ name: 'mobile/map' })
          } else {
            next()
          }
        },
      },
      {
        path: 'event/details',
        name: 'mobile/event/details',
        component: EventDetails,
        props: true,
        beforeEnter: async (to, from, next) => {
          // console.log(to.query)
          if (
            typeof to.query.type === 'undefined' ||
            !to.query.type ||
            typeof to.query.id === 'undefined' ||
            !to.query.id
          ) {
            Vue.notify({
              duration: 5000,
              closeOnClick: false,
              type: 'error',
              title: 'Error',
              text: 'No records to view',
            })
            next({ name: 'mobile/map' })
          } else {
            next()
          }
        },
      },
      {
        path: 'objects',
        name: 'mobile/objects',
        component: Objects,
        meta: { title: 'Objects' },
      },
      {
        path: 'objects/control/:imei?',
        name: 'mobile/objects/control',
        component: ObjectControl,
        meta: { title: 'Object Control' },
      },

      {
        path: 'objects/:imei?',
        name: 'objects/details',
        component: ObjectDetails,
        props: true,
        meta: { title: 'Object Details' },
        beforeEnter: async (to, from, next) => {
          if (typeof to.params.imei === 'undefined') {
            Vue.notify({
              duration: 5000,
              closeOnClick: false,
              type: 'error',
              title: 'Error',
              text: 'No records to view',
            })
            next({ name: 'mobile/map' })
          } else {
            next()
          }
        },
      },
      {
        path: 'settings',
        name: 'mobile/settings',
        component: Settings,
        meta: { title: 'Settings' },
      },
      {
        path: 'history/:imei?',
        name: 'mobile/history',
        component: History,
        meta: { title: 'History' },
      },
      {
        path: 'events',
        name: 'mobile/events',
        component: MEvents,
        redirect: '/mobile/events/',
        meta: { title: 'Events' },
        children: [
          {
            path: '/',
            name: 'mobile/events/list',
            component: EventsList,
          },
          {
            path: 'video',
            name: 'mobile/events/video',
            component: EventsVideoList,
          },
        ],
      },
      {
        path: 'triggers',
        name: 'mobile/triggers',
        component: Triggers,
        meta: { title: 'Event Triggers' },
      },
      {
        path: 'triggers/edit/:trigger_id?',
        name: 'mobile/triggers/edit',
        component: TriggerFullVersionForm,
        meta: { title: 'Trigger Details' },
        props: true,
        beforeEnter: async (to, from, next) => {
          if (typeof to.params.trigger_id === 'undefined') {
            Vue.notify({
              duration: 5000,
              closeOnClick: false,
              type: 'error',
              title: 'Error',
              text: 'No records to view',
            })
            next({ name: 'mobile/map' })
          } else {
            next()
          }
        },
      },
    ],
  },
  // {
  //   path: '/*',
  //   redirect: '/mobile/'
  // }
]
const router = new VueRouter({
  mode: process.env.NODE_ENV === 'production' ? 'history' : 'hash',
  base: process.env.BASE_URL,
  routes,
})

export default router
