<template>
  <v-container fluid style="padding: 0">
    <v-row no-gutters class="">
      <slot name="dashboard-table-header"></slot>
    </v-row>
    <v-row no-gutters class="">
      <v-col lg="6" md="6" sm="12">
        <slot name="active-tracker-table"></slot>
      </v-col>
      <v-col lg="6" md="6" sm="12">
        <slot name="active-driver-table"></slot>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {}
</script>

<style></style>
