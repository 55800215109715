<template>
  <v-data-table
    :search="search"
    dense
    :headers="headers"
    :items="tableData"
    :expanded.sync="expanded"
    item-key="id"
    class="objects-table"
    fixed-header
    mobile-breakpoint="300"
    :show-expand="$vuetify.breakpoint.xs"
    single-expand
    :height="height"
    :style="'width:' + width + 'px'"
    :footer-props="{ 'items-per-page-options': [100, 200, 300, 400] }"
    :items-per-page="defaultItemsPerPage"
    @pagination="paginated"
  >
    <template v-slot:top>
      <v-toolbar :extended="$vuetify.breakpoint.xs">
        <v-row class="align-center" v-if="!$vuetify.breakpoint.xs">
          <v-col>
            <v-btn
              class=""
              color="primary"
              small
              @click="$emit('show-form', true)"
            >
              <v-icon left> mdi-plus </v-icon>
              Add
            </v-btn>
          </v-col>
          <v-col
            cols="8"
            sm="6"
            md="5"
            lg="4"
            class="d-flex justify-content-end"
          >
            <v-text-field
              solo
              dense
              single-line
              hide-details
              clearable
              outlined
              prepend-inner-icon="mdi-magnify"
              v-model="search"
              label="Search"
              color="grey"
              class=""
            ></v-text-field
          ></v-col>
        </v-row>
        <template v-slot:default v-if="$vuetify.breakpoint.xs">
          <v-btn class="" color="primary" small @click="showForm = true">
            <v-icon left> mdi-plus </v-icon>
            Add
          </v-btn>
        </template>
        <template v-slot:extension v-if="$vuetify.breakpoint.xs">
          <v-text-field
            solo
            dense
            single-line
            hide-details
            clearable
            outlined
            prepend-inner-icon="mdi-magnify"
            v-model="search"
            label="Search"
            color="grey"
            class=""
          ></v-text-field>
        </template>
      </v-toolbar>
    </template>

    <template v-slot:item.icon="{ item }">
      <v-tooltip bottom color="tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-img class="mx-auto"
            max-width="25"
            :src="`${publicPath}assets/` + objectsNew[item.id].settings.icon"
          ></v-img>
        </template>
        <span>Click here to follow {{ item.name }}</span>
      </v-tooltip>
    </template>
    <template v-slot:item.name="{ item }">
      <v-tooltip bottom color="tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dense
            plain
            v-bind="attrs"
            @click="toggleFollow(item.id, true, true)"
            v-on="on"
            class="caption"
          >
            {{
              item.name.length > 25
                ? item.name.substring(0, 25) + "..."
                : item.name
            }}
          </v-btn>
        </template>
        <span>Click here to follow {{ item.name }}</span>
      </v-tooltip>
    </template>
    <template v-slot:item.speed="{ item }">
      <v-chip
        class="ma-2"
        small
        :color="item.speed > 0 ? 'success' : 'error'"
        text-color="white"
      >
        {{ item.speed }} {{ $t("UNIT_SPEED") }}
      </v-chip>
    </template>
    <template v-slot:item.ign="{ item }"
      ><v-img
      class="mx-auto"
        max-width="18"
        :src="
          publicPath +
          'assets/img/icons/' +
          (item.ign == 'On'
            ? 'ignition-indicator-red.svg'
            : 'ignition-indicator.svg')
        "
      ></v-img
    ></template>
    <template v-slot:item.link="{ item }"
      ><TheLinkBar :signal="item.link"
    /></template>
    <template v-slot:item.visible="{ item }">
      <v-tooltip bottom color="tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn outlined
            icon
            dense
            small
            v-bind="attrs"
            @click="toggleVisibility(item.id, !item.visible)"
            v-on="on"
            ><v-icon v-show="item.visible" dense :color="item.visible ? 'primary' : ''"
              >mdi-check</v-icon
            ></v-btn
          >
        </template>
        <span>{{ item.visible ? "Hide" : "Show" }} object</span>
      </v-tooltip>
    </template>
    <template v-slot:item.follow="{ item }">
      <v-tooltip bottom color="tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn outlined

            icon small
            dense
            plain
            v-bind="attrs"
            @click="toggleFollow(item.id, !item.follow, false)"
            v-on="on"
          >
            <v-icon v-show="item.follow" dense :color="item.follow ? 'primary' : ''"
              >mdi-check</v-icon
            ></v-btn
          >
        </template>
        <span>{{ item.follow ? "Unfollow" : "Follow" }} object</span>
      </v-tooltip>
    </template>
    <template v-slot:item.details="{ item }">
      <v-tooltip bottom color="tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            x-small
            icon
            dense
            plain
            v-bind="attrs"
            @click="
              $router.push({
                name: 'objects/details',
                params: { imei: item.id },
              })
            "
            v-on="on"
            ><v-icon dense color="">mdi-format-align-justify</v-icon></v-btn
          >
        </template>
        <span>Click for more details</span>
      </v-tooltip>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn x-small icon dense plain v-bind="attrs" v-on="on">
            <v-icon color="" dense>mdi-settings-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <!-- <v-list-item
            class="hidden-md-and-up"
            dense
            @click="toggleVisibility(item.id, !item.visible)"
          >
            <v-list-item-icon>
              <v-icon dense :color="item.visible ? 'primary' : ''"
                >mdi-check-bold</v-icon
              >
            </v-list-item-icon>
            <v-list-item-title dense>{{
              item.visible ? $t("HIDE") : $t("SHOW")
            }}</v-list-item-title>
          </v-list-item>

          <v-list-item
            class="hidden-md-and-up"
            dense
            @click="toggleFollow(item.id, !item.follow, false)"
          >
            <v-list-item-icon>
              <v-icon
                @click="toggleFollow(item.id, !item.follow, false)"
                dense
                :color="item.follow ? 'primary' : ''"
                >mdi-magnify</v-icon
              ></v-list-item-icon
            ><v-list-item-title dense>
              {{ $t("FOLLOW") }}
            </v-list-item-title></v-list-item
          >
          <v-list-item
            class="hidden-md-and-up"
            dense
            @click="
              $router.push({
                name: 'objects/details',
                params: { imei: item.id },
              })
            "
          >
            <v-list-item-icon
              ><v-icon dense color=""
                >mdi-format-align-justify</v-icon
              ></v-list-item-icon
            ><v-list-item-title dense>
              {{ $t("DETAILS") }}
            </v-list-item-title></v-list-item
          > -->
          <v-list-item
            dense
            @click="
              $router.push({
                name: 'mobile/history',
                params: { imei: item.id },
              })
            "
          >
            <v-list-item-icon
              ><v-icon dense color="">mdi-history</v-icon></v-list-item-icon
            ><v-list-item-title dense>
              {{ $t("SHOW_HISTORY") }}
            </v-list-item-title></v-list-item
          >

          <v-list-item
            dense
            @click="
              $router.push({
                name: 'mobile/objects/control',
                params: { imei: item.id },
              })
            "
          >
            <v-list-item-icon
              ><v-icon dense color=""
                >mdi-send-outline</v-icon
              ></v-list-item-icon
            ><v-list-item-title dense>
              {{ $t("SEND_COMMAND") }}
            </v-list-item-title></v-list-item
          >
        </v-list>
      </v-menu>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <v-row>
          <v-col class="d-flex align-center">
            <v-img
              max-width="50"
              :src="
                publicPath + 'assets/' + getObjectSettingsById(item.id)['icon']
              "
            ></v-img>
          </v-col>
          <v-col class="d-flex align-center">
            <v-chip
              class="ma-2"
              small
              :color="item.speed > 0 ? 'success' : 'error'"
              text-color="white"
            >
              {{ item.speed }} {{ $t("UNIT_SPEED") }}
            </v-chip>
          </v-col>
          <v-col class="d-flex align-center"
            ><v-img
              max-width="25"
              :src="
                publicPath +
                'assets/img/icons/' +
                (item.ign == 'On'
                  ? 'ignition-indicator-red.svg'
                  : 'ignition-indicator.svg')
              "
            ></v-img
          ></v-col>
          <v-col class="d-flex align-center"
            ><TheLinkBar :signal="item.link"
          /></v-col>
        </v-row>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TheLinkBar from '@/components/includes/TheLinkBar.vue'
import { mapMixin } from '@/mixins/mapMixin'

export default {
  props: {
    height: Number,
    width: Number
  },
  mixins: [mapMixin],
  components: {
    TheLinkBar
  },
  data: () => ({
    expanded: [],
    search: '',
    selectedRowId: '',
    singleSelect: false,
    selected: [],
    headers: [
      {
        text: '',
        value: 'icon',
        class: '',
        cellClass: '',
        align: 'center',
        width: 30
      },
      {
        text: 'Object',
        value: 'name',
        class: '',
        cellClass: 'px-1'
      },
      {
        text: 'Speed',
        value: 'speed',
        sortable: false,
        filterable: false,
        class: 'px-1 ',
        cellClass: 'px-1 ',
        align: 'center'
      },
      {
        text: 'Ign',
        value: 'ign',
        sortable: false,
        filterable: false,
        class: 'px-1 ',
        cellClass: 'px-1 ',
        align: 'center'
      },
      {
        text: 'Link',
        value: 'link',
        sortable: false,
        filterable: false,
        class: 'px-1 ',
        cellClass: 'px-1 ',
        align: 'center'
      },
      {
        text: 'Visible',
        value: 'visible',
        sortable: false,
        filterable: false,
        class: 'px-1  ',
        cellClass: 'px-1  ',

        align: 'center'
      },
      {
        text: 'Follow',
        value: 'follow',
        sortable: false,
        filterable: false,
        class: 'px-1  ',
        cellClass: 'px-1  ',
        align: 'center'
      },
      {
        text: 'Details',
        value: 'details',
        sortable: false,
        filterable: false,
        class: 'px-1 ',
        cellClass: 'px-1 ',
        align: 'center'
      },

      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        filterable: false,
        class: ' px-1  ',
        cellClass: '  px-1 ',
        align: 'center'
      }
      // {
      //   text: '',
      //   value: 'data-table-expand',
      //   sortable: false,
      //   filterable: false,
      //   class: ' hidden-sm-and-up px-1 ',
      //   cellClass: ' hidden-sm-and-up  px-1 ',
      //   align: 'center'
      // }
    ]
  }),
  computed: {
    ...mapGetters({
      getGroupName: 'groups/getGroupName',
      getObjectSettingsById: 'objects/getObjectSettingsById',
      // objects: "objects/objectsData",
      objectsNew: 'objects/objectsNew',
      getMarkerIcon: 'objects/getMarkerIcon'
    })
  },
  methods: {
    ...mapActions({
      updateMapFollow: 'map/updateMapFollow',
      updateMapData: 'map/updateMapData',
      setObjectProperty: 'objects/setObjectProperty'
    }),

    toggleVisibility (id, newValue) {
      try {
        this.updateTableData(id, 'visible', newValue)
        this.setObjectProperty({
          id: id,
          property_name: 'visible',
          value: newValue
        })
        // this.updateMapData();
      } catch (error) {
        this.handleError(error)
      }
    },

    toggleFollow (id, newValue, redirect = false) {
      try {
        this.$log.debug(id, newValue, redirect)
        this.updateTableData(id, 'follow', newValue)
        this.setObjectProperty({
          id: id,
          property_name: 'follow',
          value: newValue
        })

        const imeis = []
        // Object.keys(this.objects).forEach(key => {
        //   if (this.objects[key].follow) {
        //     imeis.push(key);
        //   }
        // });
        for (const [imei] of Object.entries(this.objectsNew)) {
          const obj = this.objectsNew[imei]
          if (obj.follow) {
            imeis.push(imei)
          }
        }
        this.updateMapFollow(imeis)
        if (redirect) {
          this.$router.push({ name: 'mobile/follow', params: { imei: id } })
        }
      } catch (error) {
        this.handleError(error)
      }
    },

    updateTableData (id, prop, newValue) {
      try {
        this.tableData.forEach((data, index) => {
          if (data.id === id) {
            this.tableData[index][prop] = newValue
          }
        })
      } catch (error) {
        this.handleError(error)
      }
    }
  },
  created () {
    // this.$log.debug(this.config)
    // this.$log.debug(this.objectsNew)
    // console.log(this.$vuetify.breakpoint)
  },
  watch: {
    '$vuetify.breakpoint.xs': function () {
      // console.log('', this.$vuetify.breakpoint)
    },

    '$vuetify.breakpoint.width': function () {
      // console.log('width changed')
      // console.log(this.$vuetify.breakpoint.xs)
      // console.log(this.$vuetify.breakpoint.width)
    }
  }
}
</script>

<style></style>
