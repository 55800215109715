import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
// import createLogger from 'vuex/dist/logger'

Vue.use(Vuex)
const options = {
  modules,
  state: {
    // general state stuff
    // progress: {
    //   show: false,
    //   value: 0
    // }
  },
  mutations: {
    initialiseStore (state) {
      // Check if the ID exists
      if (localStorage.getItem('toggles')) {
        const localState = state
        localState.map.toggles = JSON.parse(localStorage.getItem('toggles'))
        Object.assign(state, localState)
      } else {
        localStorage.setItem('toggles', JSON.stringify(state.map.toggles))
      }

      if (localStorage.getItem('ui_settings')) {
        const localState = state
        localState.settings.ui_settings = JSON.parse(localStorage.getItem('ui_settings'))
        Object.assign(state, localState)
      } else {
        localStorage.setItem('ui_settings', JSON.stringify(state.settings.ui_settings))
      }

      if (localStorage.getItem('language')) {
        const localState = state
        localState.settings.language = localStorage.getItem('language')
        Object.assign(state, localState)
      } else {
        localStorage.setItem('language', state.settings.language)
      }
    }
  },

  actions: {
    // general stuff
    // setProgress ({ commit }, data) {
    //   Vue.$log.debug(data.value, data.show)
    //   commit('SET_PROGRESS', data)
    // }
  },
  getters: {
    // progress: (state) => state.progress
  },
  strict: process.env.NODE_ENV !== 'production'

  // plugins: [createLogger()]
}

const store = new Vuex.Store(options)

// Automatically run the `init` action for every module,
// if one exists.
for (const moduleName of Object.keys(modules)) {
  // Vue.$log.debug(moduleName);
  if (modules[moduleName].actions.init) {
    store.dispatch(`${moduleName}/init`)
  }
}

store.subscribe((mutation, state) => {
  if ([
    'map/SET_MAP_HISTORY_TOGGLES',
    'map/SET_MAP_TOGGLES'
  ].includes(mutation.type)) {
    localStorage.setItem('toggles', JSON.stringify(state.map.toggles))
  }

  if (mutation.type === 'settings/SET_UI_SETTINGS') {
    localStorage.setItem('ui_settings', JSON.stringify(state.settings.ui_settings))
  }

  if (mutation.type === 'settings/SET_LANGUAGE') {
    localStorage.setItem('language', state.settings.language)
  }
})

export default store
