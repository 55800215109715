<template>
  <v-container
    fluid
    id="scroll-target"
    style="max-height: calc(100vh - 56px)"
    class="overflow-y-auto"
  >
    <validation-observer ref="observer">
      <v-row
        class="ma-1 d-flex flex-column align-content-center"
        style="height: auto"
      >
        <v-col cols="12" md="8" xl="6">
          <v-card class="mx-auto w-100">
            <TriggerHelpInformation :type="fields.type" />
          </v-card>
        </v-col>
        <v-col cols="12" md="8" xl="6">
          <v-card outlined class="w-100 mb-3">
            <v-card-title><h4 class="text-h5">Details</h4></v-card-title>
            <v-container fluid>
              <v-row>
                <v-col cols="12"
                  ><v-switch
                    color="primary"
                    hide-details="auto"
                    dense
                    label="Active"
                    v-model="fields.active"
                  ></v-switch
                ></v-col>
                <v-col cols="12" sm="6">
                  <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t("Trigger Type") }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("Trigger Type") }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                  <v-select
                    menu-props=""
                    item-color="#272727"
                    v-model="fields.type"
                    :items="types"
                    item-value="value"
                    item-text="text"
                    label="Trigger Type"
                    hide-details="auto"
                    dense
                    outlined
                    solo
                    color="grey"
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6">
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    rules="required"
                    :name="$t('NAME')"
                  >
                    <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t("NAME") }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("NAME") }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                    <v-text-field
                      v-model="fields.name"
                      :label="$t('NAME')"
                      solo
                      color="grey"
                      dense
                      hide-details="auto"
                      outlined
                      required
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>

                <v-col cols="12">
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    rules="required"
                    :name="$t('OBJECTS')"
                  >
                    <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t("OBJECTS") }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("OBJECTS") }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                    <v-autocomplete
                      menu-props="dark"
                      item-color="#272727"
                      required
                      v-model="fields.objects"
                      :items="objectItems"
                      :label="$t('OBJECT')"
                      item-text="text"
                      item-value="value"
                      solo
                      color="grey"
                      dense
                      hide-details="auto"
                      outlined
                      :error-messages="errors"
                      multiple

                    >
                    <template v-slot:prepend-item>
                      <v-list-item ripple @mousedown.prevent @click="toggleObjects">
                        <v-list-item-action>
                          <v-icon>
                            {{ checkIcon('objects') }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title> Select All </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>

                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index < 3" close @click:close="fields.objects.splice(index, 1);">
                        <span>{{ item.text }}</span>
                      </v-chip>
                      <span
                        v-if="index === 3 "
                        class="grey--text text-caption"
                      >
                        (+{{ fields.objects.length - 3 }} others)
                      </span>
                    </template>
                    </v-autocomplete>
                  </validation-provider>
                </v-col>

                <v-col
                  class="d-flex"
                  cols="12"
                  sm="6"
                  v-if="fields.type !== 'zone_in' && fields.type !== 'zone_out'"
                >
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    :rules="
                      fields.type !== 'zone_in' && fields.type !== 'zone_out'
                        ? 'required'
                        : ''
                    "
                    :name="$t('DEPENDING_ON_ROUTES')"
                  >
                    <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t("DEPENDING_ON_ROUTES") }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("DEPENDING_ON_ROUTES") }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                    <v-select
                      menu-props="dark"
                      item-color="#272727"
                      v-model="fields.route_trigger"
                      :items="routeTriggerOptions"
                      :label="$t('DEPENDING_ON_ROUTES')"
                      item-text="text"
                      item-value="value"
                      outlined
                      solo
                      color="grey"
                      hide-details="auto"
                      dense
                      :required="
                        fields.type !== 'zone_in' && fields.type !== 'zone_out'
                      "
                      :readonly="
                        fields.type === 'route_in' || fields.type === 'route_out'
                      "
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>

                <v-col
                  class="d-flex"
                  cols="12"
                  sm="6"
                  v-if="fields.type !== 'zone_in' && fields.type !== 'zone_out'"
                >
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    :rules="
                      ['route_in', 'route_out'].includes(fields.type) &&
                      fields.route_trigger === 'off'
                        ? 'required'
                        : ''
                    "
                    :name="$t('ROUTES')"
                  >
                    <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t("ROUTES") }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("ROUTES") }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                    <v-autocomplete
                      menu-props="dark"
                      item-color="#272727"
                      :required="
                        ['route_in', 'route_out'].includes(fields.type) &&
                        fields.route_trigger === 'off'
                      "
                      v-model="fields.routes"
                      :items="placeRoutesItems"
                      :label="$t('ROUTES')"
                      item-text="text"
                      item-value="value"
                      solo
                      color="grey"
                      dense
                      hide-details="auto"
                      outlined
                      :error-messages="errors"
                      multiple
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @mousedown.prevent @click="toggleRoutes">
                          <v-list-item-action>
                            <v-icon>
                              {{ checkIcon('routes') }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Select All </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    </v-autocomplete>
                  </validation-provider>
                </v-col>

                <v-col
                  class="d-flex"
                  cols="12"
                  sm="6"
                  v-if="fields.type !== 'route_in' && fields.type !== 'route_out'"
                >
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    rules="required"
                    :name="$t('DEPENDING_ON_ZONES')"
                  >
                    <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t("DEPENDING_ON_ZONES") }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("DEPENDING_ON_ZONES") }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                    <v-select
                      menu-props="dark"
                      item-color="#272727"
                      v-model="fields.zone_trigger"
                      :items="zoneTriggerOptions"
                      :label="$t('DEPENDING_ON_ZONES')"
                      item-text="text"
                      item-value="value"
                      outlined
                      solo
                      color="grey"
                      hide-details="auto"
                      dense
                      required
                      :error-messages="errors"
                      :readonly="
                        fields.type === 'zone_in' || fields.type === 'zone_out'
                      "
                    ></v-select>
                  </validation-provider>
                </v-col>

                <v-col
                  class="d-flex"
                  cols="12"
                  sm="6"
                  v-if="fields.type !== 'route_in' && fields.type !== 'route_out'"
                >
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    :rules="
                      ['zone_in', 'zone_out'].includes(fields.type) &&
                      fields.zone_trigger === 'off'
                        ? 'required'
                        : ''
                    "
                    :name="$t('ZONES')"
                  >
                    <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t("ZONES") }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("ZONES") }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                    <v-autocomplete
                      menu-props="dark"
                      item-color="#272727"
                      :required="
                        ['zone_in', 'zone_out'].includes(fields.type) &&
                        fields.zone_trigger === 'off'
                      "
                      v-model="fields.zones"
                      :items="placeZonesItems"
                      :label="$t('ZONES')"
                      item-text="text"
                      item-value="value"
                      solo
                      color="grey"
                      dense
                      hide-details="auto"
                      outlined
                      :error-messages="errors"
                      multiple
                    >
                      <template v-slot:prepend-item>
                        <v-list-item ripple @mousedown.prevent @click="toggleZones">
                          <v-list-item-action>
                            <v-icon>
                              {{ checkIcon('zones') }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Select All </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    </v-autocomplete>
                  </validation-provider>
                </v-col>
                <v-col
                  class="d-flex"
                  cols="12"
                  sm="6"
                  v-if="
                    [
                      'connno',
                      'gpsno',
                      'stopped',
                      'moving',
                      'engidle',
                    ].includes(fields.type)
                  "
                >
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    :rules="
                      [
                        'connno',
                        'gpsno',
                        'stopped',
                        'moving',
                        'engidle',
                      ].includes(fields.type)
                        ? 'required'
                        : ''
                    "
                    :name="$t('TIME_PERIOD_MIN')"
                  >
                    <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t("TIME_PERIOD_MIN") }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("TIME_PERIOD_MIN") }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                    <v-text-field
                      v-model="fields.checked_value"
                      :label="$t('TIME_PERIOD_MIN')"
                      solo
                      color="grey"
                      dense
                      hide-details="auto"
                      outlined
                      :required="
                        [
                          'connno',
                          'gpsno',
                          'stopped',
                          'moving',
                          'engidle',
                          'overspeed',
                          'underspeed',
                        ].includes(fields.type)
                      "
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>

                <v-col
                  class="d-flex"
                  cols="12"
                  sm="6"
                  v-if="['overspeed', 'underspeed'].includes(fields.type)"
                >
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    :rules="
                      ['overspeed', 'underspeed'].includes(fields.type)
                        ? 'required'
                        : ''
                    "
                    :name="$t('SPEED_LIMIT') + ' (' + $t('UNIT_SPEED') + ')'"
                  >
                    <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t('SPEED_LIMIT') + ' (' + $t('UNIT_SPEED') + ')' }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t('SPEED_LIMIT') + ' (' + $t('UNIT_SPEED') + ')' }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                    <v-text-field
                      v-model="fields.checked_value"
                      :label="$t('SPEED_LIMIT') + ' (' + $t('UNIT_SPEED') + ')'"
                      solo
                      color="grey"
                      dense
                      hide-details="auto"
                      outlined
                      :required="
                        ['overspeed', 'underspeed'].includes(fields.type)
                      "
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  v-if="['sensor', 'param'].includes(fields.type)"
                >

                  <v-data-table
                  v-if="fields.parameters !== null && fields.parameters.length >= 1"
                    :headers="parameterHeaders"
                    :items="fields.parameters"
                    fixed-header
                    dark
                    disable-filtering
                    disable-pagination
                    disable-sort
                    hide-default-footer
                    max-height="450px"
                  >
                    <template v-slot:item.cn="{ item }">
                      {{
                        (item.cn === "eq" ? "=" : (item.cn === 'gr' ? '&gt;' : '&lt;'))
                      }}
                    </template>
                    <template v-slot:item.remove="{ item }">
                      <v-btn
                        x-small
                        @click="remove(item)"
                        icon
                        plain
                        color="primary"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                  <v-btn x-small color="primary" text @click="showParams = true"
                    ><v-icon left>mdi-plus</v-icon
                    >{{ $t("PARAMETERS_AND_SENSORS") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="12" md="8" xl="6">
          <v-card outlined class="w-100 mb-3">
            <v-card-title
              ><h4 class="text-h5">Notification Settings</h4></v-card-title
            >
            <v-container fluid>
              <v-row
                ><v-col cols="12">
                  <v-switch
                    color="primary"
                    hide-details="auto"
                    dense
                    label="Turn on system notification"
                    v-model="fields.notifications.on_off"
                  ></v-switch>

                  <v-switch
                    color="primary"
                    hide-details="auto"
                    dense
                    label="Auto hide system notification"
                    v-model="fields.notifications.auto_hide"
                  ></v-switch>
                  <v-switch
                    color="primary"
                    hide-details="auto"
                    dense
                    label="Turn on sound alert for system notification"
                    v-model="fields.notifications.sound_on"
                  ></v-switch>
                </v-col>
                <v-col cols="8" sm="6">
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    :rules="fields.notifications.sound_on ? 'required' : ''"
                    name="Sound alert"
                  >
                    <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t("Sound alert") }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("Sound alert") }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                    <v-select
                      menu-props="dark"
                      item-color="#272727"
                      :items="soundList"
                      label="Sound alert"
                      solo
                      color="grey"
                      hide-details="auto"
                      dense
                      outlined
                      v-model="fields.notifications.sound"
                      :required="fields.notifications.sound_on"
                      :error-messages="errors"
                    ></v-select>
                  </validation-provider>
                </v-col>
                <v-col cols="2" class="d-flex align-end ">
                  <!-- {{`/snd/${fields.notifications.sound}`}} -->
                  <audio
                    ref="audio"
                    id="audio"
                    preload="auto"
                    :src="audioSrc"
                  ></audio>
                  <v-btn color="primary" text @click="toggleSound"
                    ><v-icon left>mdi-play</v-icon>Play</v-btn
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-col cols="12"
                    ><v-switch
                      color="primary"
                      hide-details="auto"
                      dense
                      :label="$t('MESSAGE_TO_EMAIL')"
                      v-model="fields.notify_email"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" md="8">
                    <validation-provider
                      class="w-100"
                      v-slot="{ errors }"
                      :rules="fields.notify_email ? 'required' : ''"
                      :name="$t('EMAIL_ADDRESS')"
                    >
                      <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t("EMAIL_ADDRESS") }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("MESSAGE_TO_EMAIL") }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                      <v-text-field
                        :label="$t('EMAIL_ADDRESS')"
                        solo
                        color="grey"
                        dense
                        hide-details="auto"
                        outlined
                        v-model="fields.notify_email_address"
                        :required="fields.notify_email"
                        :error-messages="errors"
                      ></v-text-field></validation-provider
                  ></v-col>
                  <v-col cols="12" md="8">
                    <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t("EMAIL_TEMPLATE") }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("EMAIL_TEMPLATE") }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                    <v-select
                      menu-props="dark"
                      item-color="#272727"
                      :items="emailTemplateList"
                      item-value="value"
                      item-text="text"
                      :label="$t('EMAIL_TEMPLATE')"
                      solo
                      color="grey"
                      hide-details="auto"
                      dense
                      outlined
                      v-model="fields.email_template_id"
                    ></v-select>
                  </v-col>
                </v-col>

                <v-col cols="12">
                  <v-col cols="12"
                    ><v-switch
                      color="primary"
                      hide-details="auto"
                      dense
                      :label="$t('SMS_TO_MOBILE_PHONE')"
                      v-model="fields.notify_sms"
                    ></v-switch>
                  </v-col>

                  <v-col cols="12" md="8"
                    ><validation-provider
                      class="w-100"
                      v-slot="{ errors }"
                      :rules="fields.notify_sms ? 'required' : ''"
                      :name="$t('PHONE_NUMBER_WITH_CODE')"
                      ><div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t("PHONE_NUMBER_WITH_CODE") }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("SMS_TO_MOBILE_PHONE") }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                      <v-text-field
                        :label="$t('PHONE_NUMBER_WITH_CODE')"
                        solo
                        color="grey"
                        dense
                        hide-details="auto"
                        outlined
                        v-model="fields.notify_sms_number"
                        :required="fields.notify_sms"
                        :error-messages="errors"
                      ></v-text-field></validation-provider
                  ></v-col>
                  <v-col cols="12" md="8">
                    <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t("SMS_TEMPLATE") }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("SMS_TEMPLATE") }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                    <v-select
                      menu-props="dark"
                      item-color="#272727"
                      :items="smsTemplateList"
                      item-value="value"
                      item-text="text"
                      :label="$t('SMS_TEMPLATE')"
                      solo
                      color="grey"
                      hide-details="auto"
                      dense
                      outlined
                      v-model="fields.sms_template_id"
                    ></v-select> </v-col
                ></v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="12" md="8" xl="6">
          <v-card outlined class="w-100 mb-3">
            <v-card-title
              ><h4 class="text-h5">{{ $t("COLORS") }}</h4></v-card-title
            >
            <v-container fluid>
              <v-row align="center">
                <v-col cols="12" sm="8">
                  <v-switch
                    color="primary"
                    hide-details="auto"
                    dense
                    v-model="fields.notify_arrow"
                    label="Turn on object arrow color"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="8">
                  <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t("OBJECT_ARROW_COLOR") }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("OBJECT_ARROW_COLOR") }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                  <v-select
                    menu-props="dark"
                    item-color="#272727"
                    v-model="fields.notify_arrow_color"
                    :items="arrowColorList"
                    item-value="value"
                    item-text="text"
                    :label="$t('OBJECT_ARROW_COLOR')"
                    solo
                    color="grey"
                    hide-details="auto"
                    dense
                    outlined
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="8">
                  <v-switch
                    color="primary"
                    hide-details="auto"
                    dense
                    v-model="fields.notify_ohc"
                    label="Turn on object list color"
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="8" class="d-flex flex-column">
                  <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t("OBJECT_LIST_COLOR") }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("OBJECT_LIST_COLOR") }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                    <div class="d-flex">

                  <v-menu class="">
                    <template v-slot:activator="{ on, attrs }">

                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        class="mr-2"
                        v-model="fields.notify_ohc_color"
                        :label="$t('OBJECT_LIST_COLOR')"
                        solo
                        color="grey"
                        dense
                        hide-details="auto"
                        outlined
                        required
                        append-icon="mdi-eyedropper-variant"
                      ></v-text-field>
                    </template>
                    <div>
                      <div class="color-picker-container">
                        <v-color-picker
                          mode="hexa"
                          dark
                          v-model="fields.ohc_color_object"
                          dot-size="15"
                          swatches-max-height="250"
                          hide-inputs
                          hide-mode-switch
                          :value="
                            fields.ohc_color
                              ? fields.ohc_color
                              : fields.notify_ohc_color
                          "
                        ></v-color-picker>
                      </div>
                    </div>
                  </v-menu>

                  <v-sheet
                    width="40"
                    height="40"
                    light
                    class="pa-2"
                    :color="
                      fields.ohc_color
                        ? fields.ohc_color
                        : fields.notify_ohc_color
                    "
                  >
                    <pre>&nbsp;</pre>
                  </v-sheet>
                    </div></v-col
                >
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        class="
          mb-3
          justify-center
          flex-column flex-sm-row
          d-flex
        "
      >
        <v-btn
          @click="$router.push({ name: 'mobile/triggers' })"
          color="primary"
          outlined
          class="mr-sm-2 mb-2"
          min-width="200px"
          max-width="50%"
          >Cancel</v-btn
        >
        <v-btn @click="save()" color="primary" min-width="200px" max-width="50%"
          >Save</v-btn
        >
      </v-row>
    </validation-observer>
    <TriggerParametersModal
      :show="showParams"
      :objects="fields.objects"
      :type="fields.type"
      v-on:set-parameters="setParameters"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import TriggerHelpInformation from '@/components/triggers/TriggerHelpInformation.vue'
import TriggerParametersModal from '@/components/triggers/TriggerParametersModal.vue'
import FormTooltip from '@/components/includes/FormTooltip.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    TriggerHelpInformation,
    TriggerParametersModal,
    FormTooltip
  },
  props: {
  },
  data () {
    return {
      sounds: [],
      trigger_id: this.$route.params.trigger_id,
      showParams: false,
      audioSrc: null,
      fields: {
        active: true,
        type: '',
        name: '',
        objects: [],
        route_trigger: 'off',
        routes: [],
        zone_trigger: 'off',
        zones: [],
        checked_value: '',
        notifications: {
          on_off: false,
          auto_hide: false,
          sound_on: false,
          sound: ''
        },
        notify_email: false,
        notify_email_address: '',
        notify_sms_number: '',
        notify_sms: false,
        email_template_id: 0,
        sms_template_id: 0,
        notify_arrow: false,
        notify_arrow_color: 'arrow_yellow',
        notify_ohc: false,
        notify_ohc_color: '#FFFF00',
        parameters: null
      },
      types: [
        { value: 'sos', text: 'SOS' },
        { value: 'bracon', text: 'Bracelet on' },
        { value: 'bracoff', text: 'Bracelet off' },
        { value: 'mandown', text: 'Man down' },
        { value: 'shock', text: 'Shock' },
        { value: 'tow', text: 'Tow' },
        { value: 'pwrcut', text: 'Power cut' },
        { value: 'gpsantcut', text: 'GPS antenna cut' },
        { value: 'jamming', text: 'Signal jamming' },
        { value: 'lowdc', text: 'Low DC' },
        { value: 'lowbat', text: 'Low battery' },
        { value: 'connyes', text: 'Connection: Yes' },
        { value: 'connno', text: 'Connection: No' },
        { value: 'gpsyes', text: 'GPS: Yes' },
        { value: 'gpsno', text: 'GPS: No' },
        { value: 'stopped', text: 'Stopped' },
        { value: 'moving', text: 'Moving' },
        { value: 'engidle', text: 'Engine idle' },
        { value: 'overspeed', text: 'Overspeed' },
        { value: 'underspeed', text: 'Underspeed' },
        { value: 'haccel', text: 'Harsh acceleration' },
        { value: 'hbrake', text: 'Harsh braking' },
        { value: 'hcorn', text: 'Harsh cornering' },
        { value: 'param', text: 'Parameter' },
        { value: 'sensor', text: 'Sensor' },
        { value: 'service', text: 'Service' },
        { value: 'dtc', text: 'DTC (Diagnostic Trouble Codes)' },
        { value: 'route_in', text: 'Route in' },
        { value: 'route_out', text: 'Route out' },
        { value: 'zone_in', text: 'Zone in' },
        { value: 'zone_out', text: 'Zone out' }
      ],
      parameterHeaders: [
        {
          text: 'Source',
          value: 'src'
        },
        {
          text: '',
          value: 'cn'
        },
        {
          text: 'Value',
          value: 'val'
        },
        {
          text: '',
          value: 'remove'
        }
      ]
    }
  },
  watch: {
    'fields.ohc_color_object': function () {
      try {
        if (
          Object.prototype.hasOwnProperty.call(
            this.fields.ohc_color_object,
            'hex'
          )
        ) {
          this.fields.notify_ohc_color = this.fields.ohc_color_object.hex
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    'fields.type': function (newVal, oldVal) {
      try {
        if (
          this.fields.type === 'route_out' ||
          this.fields.type === 'route_in' ||
          this.fields.type === 'zone_out' ||
          this.fields.type === 'zone_in'
        ) {
          this.fields.route_trigger = 'off'
          this.fields.zone_trigger = 'off'
        }
        if (Object.prototype.hasOwnProperty.call(this.triggers, this.trigger_id)) {
          const trigger = this.triggers[this.trigger_id]
          if (this.fields.type === 'param' || this.fields.type === 'sensor') {
            if (
              trigger.type === this.fields.type &&
            typeof trigger.checked_value === 'object'
            ) {
              this.fields.parameters = trigger.checked_value
            } else {
              this.fields.parameters = null
            }
          } else {
            this.fields.checked_value = trigger.checked_value
          }
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    'fields.notifications.sound': function () {
      if (this.fields.notifications.sound) {
        this.audioSrc = `${this.publicPath}assets/snd/${this.fields.notifications.sound}`
      }
    }
  },
  computed: {
    ...mapGetters({
      objectsNew: 'objects/objectsNew',
      placeRoutes: 'places/placeRoutes',
      placeZones: 'places/placeZones',
      triggers: 'triggers/triggers',
      objectItems: 'objects/objectItems',

      placeZonesItems: 'places/placeZonesItems',
      placeRoutesItems: 'places/placeRoutesItems'
    }),
    routeTriggerOptions: function () {
      return [
        { value: 'off', text: this.$t('OFF') },
        { value: 'in', text: this.$t('IN_SELECTED_ROUTES') },
        { value: 'out', text: this.$t('OUT_OF_SELECTED_ROUTES') }
      ]
    },
    zoneTriggerOptions: function () {
      return [
        { value: 'off', text: this.$t('OFF') },
        { value: 'in', text: this.$t('IN_SELECTED_ZONES') },
        { value: 'out', text: this.$t('OUT_OF_SELECTED_ZONES') }
      ]
    },
    arrowColorList: function () {
      return [
        { text: this.$t('COLOR_BLACK'), value: 'arrow_black' },
        { text: this.$t('COLOR_BLUE'), value: 'arrow_blue' },
        { text: this.$t('COLOR_GREEN'), value: 'arrow_green' },
        { text: this.$t('COLOR_GREY'), value: 'arrow_grey' },
        { text: this.$t('COLOR_ORANGE'), value: 'arrow_orange' },
        { text: this.$t('COLOR_PURPLE'), value: 'arrow_purple' },
        { text: this.$t('COLOR_RED'), value: 'arrow_red' },
        { text: this.$t('COLOR_YELLOW'), value: 'arrow_yellow' }
      ]
    },
    emailTemplateList: function () {
      return [{ text: 'Default', value: 0 }]
    },
    smsTemplateList: function () {
      return [{ text: 'Default', value: 0 }]
    },
    soundList: function () {
      const filenames = []
      try {
        let illustrations = null

        illustrations = require.context('@/assets/snd', true, /^.*\.mp3$/)
        if (illustrations !== null) {
          this.$log.debug(illustrations.keys())
          illustrations.keys().forEach((sound) => {
            filenames.push(sound.substring(2))
          })
        }
      } catch (error) {
        this.handleError(error)
      }
      return filenames
    }

  },

  created () {
    try {
      if (!this.trigger_id) {
        this.$router.push({ name: 'mobile/map' })
      }
      Promise.all([
        this.getTriggers(),
        this.fetchPlacesData({ cmd: 'load_route_data' }),
        this.fetchPlacesData({ cmd: 'load_zone_data' }),
        this.initializeObjectData()
      ])
        .then(() => {
          this.setFields()
        })
        .catch((error) => {
          this.handleError(error)
        })
    } catch (error) {
      this.handleError(error)
    }
  },

  methods: {
    ...mapActions({
      saveSettings: 'settings/saveSettings',
      fetchPlacesData: 'places/fetchPlacesData',
      getTriggers: 'triggers/getTriggers',
      saveTrigger: 'triggers/saveTrigger',
      initializeObjectData: 'objects/initializeObjectData'
    }),
    setFields () {
      try {
        this.$log.debug('set fields', this.$route.params.trigger_id, this.trigger_id)
        this.$log.debug(this.trigger_id)

        const trigger = this.triggers[this.trigger_id]
        if (typeof trigger === 'undefined') {
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'error',
            title: this.$t('ERROR'),
            text: 'No records found'
          })
          this.$router.push({ name: 'mobile/map' })
        }
        const notifications = trigger.notify_system.split(',')
        this.fields = {
          active: trigger.active ? JSON.parse(trigger.active) : false,
          type: trigger.type,
          name: trigger.name,
          objects: trigger.imei ? trigger.imei.split(',') : [],
          route_trigger: trigger.route_trigger,
          routes: trigger.routes ? trigger.routes.split(',') : [],
          zone_trigger: trigger.zone_trigger,
          zones: trigger.zones ? trigger.zones.split(',') : [],
          notifications: {
            on_off: JSON.parse(notifications[0]),
            auto_hide: JSON.parse(notifications[1]),
            sound_on: JSON.parse(notifications[2]),
            sound: notifications[3]
          },
          notify_email: trigger.notify_email ? JSON.parse(trigger.notify_email) : false,
          notify_email_address: trigger.notify_email_address,
          notify_sms: trigger.notify_sms ? JSON.parse(trigger.notify_sms) : false,
          notify_sms_number: trigger.notify_sms_number,
          email_template_id: trigger.email_template_id,
          sms_template_id: trigger.sms_template_id,
          notify_arrow: trigger.notify_arrow ? JSON.parse(trigger.notify_arrow) : false,
          notify_arrow_color: trigger.notify_arrow_color,
          notify_ohc: trigger.notify_ohc ? JSON.parse(trigger.notify_ohc) : false,
          notify_ohc_color: trigger.notify_ohc_color,
          parameters: null
        }
        if (typeof trigger.checked_value === 'object') {
          this.fields.parameters = trigger.checked_value
          this.fields.checked_value = ''
        } else {
          this.fields.checked_value = trigger.checked_value
            ? JSON.parse(trigger.checked_value)
            : ''
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    toggleSound () {
      try {
        if (this.audioSrc !== null) {
          const audio = this.$refs.audio
          audio.play()
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    eventTypeChange () {
    },
    async save () {
      try {
        if (this.checkPrivilege('viewer')) {
          const valid = await this.$refs.observer.validate()
          if (valid) {
            const notifySystem =
              this.fields.notifications.on_off +
              ',' +
              this.fields.notifications.auto_hide +
              ',' +
              this.fields.notifications.sound_on +
              ',' +
              this.fields.notifications.sound

            const params = this.fields

            params.event_id = this.trigger_id
            params.imei = this.fields.objects.join(',')
            params.routes =
              this.fields.routes.length >= 1 ? this.fields.routes.join(',') : ''
            params.zones =
              this.fields.zones.length >= 1 ? this.fields.zones.join(',') : ''
            params.notify_system = notifySystem
            if (this.fields.parameters !== null && this.fields.parameters.length >= 1) {
              params.checked_value = this.fields.parameters
            }
            this.saveTrigger(params)
              .then((response) => {
                if (response.message === 'OK') {
                  this.$notify({
                    duration: 5000,
                    closeOnClick: false,
                    type: 'success',
                    title: this.$t('INFORMATION'),
                    text: this.$t('CHANGES_SAVED_SUCCESSFULLY')
                  })
                  this.$router.push({ name: 'mobile/triggers' })
                }
              })
              .catch((error) => {
                this.handleError(error)
              })
          }
        } else {
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'error',
            title: this.$t('ERROR'),
            text: this.$t('THIS_ACCOUNT_HAS_NO_PRIVILEGES_TO_DO_THAT')
          })
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    setParameters (isSave, fields) {
      try {
        this.showParams = false
        if (isSave) {
          this.fields.parameters.push(fields)
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    remove (item) {
      try {
        const toRemove = this.fields.parameters.indexOf(item)

        this.fields.parameters.splice(toRemove, 1)
        this.fields.parameters.splice(toRemove, 1)
        this.fields.parameters.splice(toRemove, 1)
      } catch (error) {
        this.handleError(error)
      }
    },
    checksAll (type) {
      try {
        switch (type) {
          case 'objects':
            return this.fields.objects.length === this.objectItems.length

          case 'routes':
            return this.fields.routes.length === this.placeRoutesItems.length

          case 'zones':
            return this.fields.zones.length === this.placeZonesItems.length
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    checksSome (type) {
      try {
        return this.fields[type].length > 0 && !this.checksAll(type)
      } catch (error) {
        this.handleError(error)
      }
    },
    checkIcon (type) {
      try {
        if (this.checksAll(type)) return 'mdi-close-box'
        if (this.checksSome(type)) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      } catch (error) {
        this.handleError(error)
      }
    },
    toggleObjects () {
      try {
        this.$nextTick(() => {
          if (this.checksAll('objects')) {
            this.fields.objects = []
          } else {
            this.fields.objects = this.objectItems.slice()
          }
        })
      } catch (error) {
        this.handleError(error)
      }
    },

    toggleZones () {
      try {
        this.$nextTick(() => {
          if (this.checksAll('zones')) {
            this.fields.zones = []
          } else {
            this.fields.zones = this.placeZonesItems.slice()
          }
        })
      } catch (error) {
        this.handleError(error)
      }
    },

    toggleRoutes () {
      try {
        this.$nextTick(() => {
          if (this.checksAll('routes')) {
            this.fields.routes = []
          } else {
            this.fields.routes = this.placeRoutesItems.slice()
          }
        })
      } catch (error) {
        this.handleError(error)
      }
    }
  }
}
</script>

<style></style>
