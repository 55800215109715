<template>
  <v-dialog
    persistent
    v-model="show"
    hide-overlay
    no-click-animation
    max-width="370"
    width="auto"
  >
    <v-card outlined>
      <v-toolbar color="" dense elevation="0">
        <v-toolbar-title class="text-subtitle-2">Send Message</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn plain icon x-small @click="closeDialog">
          <v-icon dense>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container fluid>
        <validation-observer ref="observer">
          <v-row>
            <v-col cols="12">
              <!-- <v-checkbox
                color="primary"
                hide-details="auto"
                label="Send Message to All Drivers"
              ></v-checkbox> -->
              <v-select
                v-model="selectedObjects"
                menu-props=""
                item-color="#272727"
                :items="driverItems"
                item-value="value"
                item-text="text"
                label="Send Message to"
                hide-details="auto"
                dense
                outlined
                solo
                color="grey"
                multiple
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @mousedown.prevent @click="toggle">
                    <v-list-item-action>
                      <v-icon>
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Select All </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12"
              ><validation-provider
                v-slot="{ errors }"
                rules="max:25"
                name="Message"
                ><v-textarea
                  v-model="fields.name"
                  :error-messages="errors"
                  label="Enter message here"
                  placeholder="Enter message here"
                  color="grey"
                  hide-details="auto"
                  solo
                  dense
                  outlined
                ></v-textarea>
              </validation-provider>
            </v-col>4
          </v-row>

          <v-row class="mb-3 justify-center flex-column flex-sm-row d-flex">
            <v-btn
              @click="closeDialog"
              color="primary"
              outlined
              class="mr-sm-2 mb-2"
              max-width="50%"
              >Cancel</v-btn
            >
            <v-btn color="primary" max-width="50%">Save</v-btn>
          </v-row>
        </validation-observer>
      </v-container>
    </v-card>
    <ConfirmDialog
      :info="confirmDetails"
      :show="showConfirm"
      v-on:show-confirm-dialog="confirm"
    />
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ConfirmDialog from '@/components/includes/ConfirmDialog.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    ConfirmDialog
  },
  props: {
    show: Boolean,
    id: String
  },
  data () {
    return {
      fields: {
        name: '',
        imei: ''
      },
      showConfirm: false,
      confirmDetails: {
        title: '',
        message: '',
        action: {
          yes: '',
          no: ''
        }
      },
      selectedObjects: []
    }
  },
  computed: {
    ...mapGetters({
      driverItems: 'person/driverItems'
    }),

    likesAllFruit () {
      return this.selectedObjects.length === this.driverItems.length
    },
    likesSomeFruit () {
      return this.selectedObjects.length > 0 && !this.likesAllFruit
    },
    icon () {
      try {
        if (this.likesAllFruit) return 'mdi-close-box'
        if (this.likesSomeFruit) return 'mdi-minus-box'
      } catch (error) {
        this.handleError(error)
      }

      return 'mdi-checkbox-blank-outline'
    }
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchDrivers: 'person/fetchDrivers'
    }),

    confirm (val) {
      this.showConfirm = false
      if (val) {
        // location.href = '/subscribe/index.php?imei=' + this.fields.imei
      }
    },
    closeDialog () {
      this.$refs.observer.reset()
      this.$emit('show-message-dialog', false)
    },
    toggle () {
      try {
        this.$nextTick(() => {
          if (this.likesAllFruit) {
            this.selectedObjects = []
          } else {
            this.selectedObjects = this.driverItems.slice()
          }
        })
      } catch (error) {
        this.handleError(error)
      }
    }
  },
  created () {
    this.fetchDrivers().catch((error) => {
      this.handleError(error)
    })
  }
}
</script>

<style></style>
