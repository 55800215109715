<template>
  <div style="height: calc(100vh - 56px)" class="open-street-map">
    <l-map
      v-if="object"
      :options="mapOptions"
      style="height: 100%"
      :maxZoom="config.map_max_zoom"
      :minZoom="config.map_min_zoom"
      :zoom="zoom"
      :center="center"
      @ready="follow"
    >

      <TileLayers />
      <v-rotated-marker
        v-if="marker"
        :lat-lng="marker"
        :rotationAngle="rotationAngle"
      >
        <l-icon
          :icon-size="iconSize"
          :icon-url="
            publicPath + 'assets/img/markers/' + getMarkerIcon(imei) + '.png'
          "
        />
        <l-tooltip :options="{ permanent: true }">
          <div>
            {{ settings["name"] }} ({{ object["data"]["speed"] }}
            kph)
          </div>
        </l-tooltip>
        <l-popup>
          <div class="div">
            Object: {{ settings["name"] }} <br />
            Address: {{ object["address"] }} <br />
            Position: {{ object["data"]["lat"] }} °,{{
              object["data"]["lng"]
            }}
            ° <br />
            Altitude: {{ object["data"]["altitude"] }} m <br />
            Angle: {{ object["data"]["angle"] }} ° <br />
            Speed: {{ object["data"]["speed"] }} kph <br />
            Time: {{ object["data"]["dt_tracker"] }} <br />
            Odometer: {{ object["odometer"] }} km
          </div>
        </l-popup>
      </v-rotated-marker>

      <l-control position="bottomleft">
        <v-tooltip right  color="tooltip" v-if="!showHistoryControl">
          <template v-slot:activator="{ on, attrs }">

            <v-fab-transition>
              <v-btn
               v-bind="attrs"
              v-on="on"
                  elevation="20"
                  fab
                  color="secondary"
                  @click="$router.go(-1)"
                  small
                >
                <v-icon >mdi-arrow-left-bold</v-icon>
                </v-btn>
            </v-fab-transition>
          </template>
          <span>Go back?</span>
        </v-tooltip>
      </l-control>
    </l-map>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { latLng } from 'leaflet'
import Vue2LeafletRotatedMarker from 'vue2-leaflet-rotatedmarker'
import {
  LMap,
  LPopup,
  LTooltip,
  LIcon,
  LControl
} from 'vue2-leaflet'
import TileLayers from '@/components/includes/TileLayers.vue'
export default {
  components: {
    LMap,
    LPopup,
    LTooltip,
    LIcon,
    'v-rotated-marker': Vue2LeafletRotatedMarker,
    LControl,
    TileLayers
  },
  data () {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      mapOptions: {
        zoomSnap: 0.5
      },
      iconSize: [28, 28],
      imei: this.$route.params.imei,
      init: true,
      zoom: 15
    }
  },
  computed: {
    ...mapGetters({
      // objects: "objects/objectsData",
      // objectSettings: "objects/objectSettings",
      getMarkerIcon: 'objects/getMarkerIcon'
    }),
    rotationAngle: function () {
      try {
        // if (typeof this.object !== 'undefined') {
        if (this.object) {
          return this.object.data.lat
        }
        return 0
        // object['data']['angle']
      } catch (error) {
        this.handleError(error)
      }
      return 0
    },

    marker: function () {
      try {
        // if (typeof this.object !== 'undefined') {
        if (this.object) {
          const object = this.object
          const lat = object.data.lat
          const lng = object.data.lng

          return latLng(lat, lng)
        }
        return []
      } catch (error) {
        this.handleError(error)
      }
      return []
    },
    center: function () {
      try {
        const object = this.object
        // if (typeof this.object !== 'undefined') {
        if (this.object) {
          // this.$log.debug("center", this.object, typeof this.object);
          const lat = object.data.lat
          const lng = object.data.lng
          return [lat, lng]
        }
        return []
      } catch (error) {
        this.handleError(error)
      }
      return []
    },
    object: function () {
      try {
        return typeof this.objectsNew[this.imei] !== 'undefined'
          ? this.objectsNew[this.imei]
          : null
      } catch (error) {
        this.handleError(error)
      }
      return null
    },
    settings: function () {
      try {
        return this.objectsNew[this.imei].settings
      } catch (error) {
        this.handleError(error)
      }
      return null
    }
  },
  methods: {
    ...mapActions({
      followIndividualObject: 'objects/followIndividualObject',
      initializeObjectData: 'objects/initializeObjectData'
    }),
    follow () {
      try {
        // this.$log.debug("follow yow");
        // const imei = this.imei

        const map = this.$children[0].mapObject
        // this.$log.debug('follow', map, this.imei);

        map.zoomControl.setPosition('topright')
        this.$timer.followIndividualObject = setInterval(() => {
          const ctr = Math.floor(Math.random() * 10) + 1
          // this.$log.debug(ctr)
          this.followIndividualObject({ ctr: ctr, imei: this.imei })
          // this.$log.debug(this.objects, this.imei)
          const object = this.objectsNew[this.imei]
          // this.$log.debug(object)

          map.panTo([object.data.lat, object.data.lng])
        }, this.config.map_refresh * 1000)
      } catch (error) {
        this.handleError(error)
      }
    }
  },
  async created () {
    try {
      // this.$log.debug('follow object', this.imei);
      clearInterval(this.$timer.objectLoad)
      this.initializeObjectData().catch((error) => {
        this.handleError(error)
      })
    } catch (error) {
      this.handleError(error)
    }
  },
  unmounted () {
    this.$log.debug('unmounted')
  },
  updated () {
    this.$log.debug('updated')
  },
  renderTracked () {
    this.$log.debug('renderTracked')
  },
  deactivated () {
    this.$log.debug('deactivated')
  },
  beforeRouteUpdate (to, from, next) {
    // react to route changes...
    // don't forget to call next()
    this.$log.debug('chnaging route')
  },
  beforeRouteLeave (to, from, next) {
    // called when the route that renders this component is about to
    // be navigated away from.
    // has access to `this` component instance.
    try {
      this.$log.debug('beforeRouteLeave')
      clearInterval(this.$timer.followIndividualObject)
      this.$timer.followIndividualObject = 0
      next()
    } catch (error) {
      this.handleError(error)
    }
  }
  // mounted () {
  //   this.follow()
  // }
}
</script>
<style>
</style>
