<template>
  <v-container>
    <validation-observer ref="register">
      <p class="text-body-2 error--text">{{ errorMessage }}</p>
      <v-row>
        <v-col>
          <validation-provider
            v-slot="{ errors }"
            rules="required|email"
            :name="$t('EMAIL')"
            ><v-text-field
              v-model="email"
              :error-messages="errors"
              :placeholder="$t('EMAIL')"
              required
              solo
              color="grey"
              dense
              hide-details="auto"
              outlined
            >
            </v-text-field
          ></validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <validation-provider v-slot="{ errors }" rules="agree" name="agree"
            ><v-checkbox
              v-model="agree"
              :error-messages="errors"
              label="Agree"
              required
              solo
              color="grey"
              dense
              hide-details="auto"
              outlined
            >
              <template v-slot:label>
                <div v-html="$t('TDJ_AGREE_TERMS')"></div>
              </template> </v-checkbox
          ></validation-provider>
        </v-col>
      </v-row>
      <v-row class="">
        <v-col>
          <v-btn @click="register" color="primary" class="w-100"
            >Register</v-btn
          >
        </v-col>
      </v-row>
    </validation-observer>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      email: '',
      agree: false,
      errorMessage: '',
      csrfToken: ''
    }
  },
  methods: {
    ...mapActions({
      fetchCsrfToken: 'auth/fetchCsrfToken',
      registerUser: 'auth/registerUser'
    }),
    async register () {
      const valid = await this.$refs.register.validate()

      if (valid) {
        const response = await this.registerUser({
          email: this.email,
          csrf_token: this.csrfToken
        })
        switch (response) {
          case 'OK':
            this.$notify({
                    duration: 5000,
                    closeOnClick: false,
              type: 'success',
              title: this.$t('REGISTRATION_SUCCESSFUL'),
              text:
                this.$t('REGISTRATION_SUCCESSFUL') +
                ' ' +
                this.$t('PLEASE_CHECK_YOUR_EMAIL')
            })
            break
          case 'CSRF':
            this.errorMessage =
              'There was a problem with your form, you have to reload the page and try again.'
            break
          case 'ERROR_NOT_SENT':
            this.errorMessage =
              this.$t('CANT_SEND_EMAIL') +
              ' ' +
              this.$t('CONTACT_ADMINISTRATOR')
            break
          case 'ERROR_EMAIL_EXISTS':
            this.errorMessage = this.$t('THIS_EMAIL_ALREADY_EXISTS')
            break
          case 'ERROR_USERNAME_EXISTS':
            this.errorMessage = this.$t('THIS_USERNAME_ALREADY_EXISTS')
            break
          default:
            this.errorMessage = response
        }
      }
    }
  },

  created () {
    this.email = ''
    this.agree = false
    this.errorMessage = ''
    this.csrfToken = ''
    this.fetchCsrfToken()
      .then((response) => {
        this.csrfToken = response
      })
      .catch((e) => this.handleError(e))
  },

  mounted () {}
}
</script>

<style>
</style>
