var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "overflow-y-auto",
      staticStyle: { "max-height": "calc(100vh - 56px)" },
      attrs: { fluid: "", id: "scroll-target" }
    },
    [
      _c(
        "validation-observer",
        { ref: "observer" },
        [
          _c(
            "v-row",
            {
              staticClass: "ma-1 d-flex flex-column align-content-center",
              staticStyle: { height: "auto" }
            },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", md: "8", xl: "6" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "mx-auto w-100" },
                    [
                      _c("TriggerHelpInformation", {
                        attrs: { type: _vm.fields.type }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "8", xl: "6" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "w-100 mb-3", attrs: { outlined: "" } },
                    [
                      _c("v-card-title", [
                        _c("h4", { staticClass: "text-h5" }, [
                          _vm._v("Details")
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      color: "primary",
                                      "hide-details": "auto",
                                      dense: "",
                                      label: "Active"
                                    },
                                    model: {
                                      value: _vm.fields.active,
                                      callback: function($$v) {
                                        _vm.$set(_vm.fields, "active", $$v)
                                      },
                                      expression: "fields.active"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "mb-2 d-flex flex-row" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-body-2 mr-1" },
                                        [_vm._v(_vm._s(_vm.$t("Trigger Type")))]
                                      ),
                                      _vm._v(" "),
                                      _c("FormTooltip", {
                                        scopedSlots: _vm._u([
                                          {
                                            key: "tooltip-trigger",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      "x-small": "",
                                                      color:
                                                        "primary lighten-1",
                                                      text: "",
                                                      icon: ""
                                                    }
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        "mdi-information-outline"
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            },
                                            proxy: true
                                          },
                                          {
                                            key: "tooltip-content",
                                            fn: function() {
                                              return [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("Trigger Type")
                                                  ) +
                                                    "\n                        "
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-select", {
                                    attrs: {
                                      "menu-props": "",
                                      "item-color": "#272727",
                                      items: _vm.types,
                                      "item-value": "value",
                                      "item-text": "text",
                                      label: "Trigger Type",
                                      "hide-details": "auto",
                                      dense: "",
                                      outlined: "",
                                      solo: "",
                                      color: "grey"
                                    },
                                    model: {
                                      value: _vm.fields.type,
                                      callback: function($$v) {
                                        _vm.$set(_vm.fields, "type", $$v)
                                      },
                                      expression: "fields.type"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "6" } },
                                [
                                  _c("validation-provider", {
                                    staticClass: "w-100",
                                    attrs: {
                                      rules: "required",
                                      name: _vm.$t("NAME")
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mb-2 d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-body-2 mr-1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("NAME"))
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("FormTooltip", {
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "tooltip-trigger",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                  color:
                                                                    "primary lighten-1",
                                                                  text: "",
                                                                  icon: ""
                                                                }
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-information-outline"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      },
                                                      {
                                                        key: "tooltip-content",
                                                        fn: function() {
                                                          return [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t("NAME")
                                                              ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("v-text-field", {
                                              attrs: {
                                                label: _vm.$t("NAME"),
                                                solo: "",
                                                color: "grey",
                                                dense: "",
                                                "hide-details": "auto",
                                                outlined: "",
                                                required: "",
                                                "error-messages": errors
                                              },
                                              model: {
                                                value: _vm.fields.name,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.fields,
                                                    "name",
                                                    $$v
                                                  )
                                                },
                                                expression: "fields.name"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("validation-provider", {
                                    staticClass: "w-100",
                                    attrs: {
                                      rules: "required",
                                      name: _vm.$t("OBJECTS")
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mb-2 d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-body-2 mr-1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("OBJECTS"))
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("FormTooltip", {
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "tooltip-trigger",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                  color:
                                                                    "primary lighten-1",
                                                                  text: "",
                                                                  icon: ""
                                                                }
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-information-outline"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      },
                                                      {
                                                        key: "tooltip-content",
                                                        fn: function() {
                                                          return [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "OBJECTS"
                                                                )
                                                              ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("v-autocomplete", {
                                              attrs: {
                                                "menu-props": "dark",
                                                "item-color": "#272727",
                                                required: "",
                                                items: _vm.objectItems,
                                                label: _vm.$t("OBJECT"),
                                                "item-text": "text",
                                                "item-value": "value",
                                                solo: "",
                                                color: "grey",
                                                dense: "",
                                                "hide-details": "auto",
                                                outlined: "",
                                                "error-messages": errors,
                                                multiple: ""
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "prepend-item",
                                                    fn: function() {
                                                      return [
                                                        _c(
                                                          "v-list-item",
                                                          {
                                                            attrs: {
                                                              ripple: ""
                                                            },
                                                            on: {
                                                              mousedown: function(
                                                                $event
                                                              ) {
                                                                $event.preventDefault()
                                                              },
                                                              click:
                                                                _vm.toggleObjects
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-list-item-action",
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "\n                          " +
                                                                      _vm._s(
                                                                        _vm.checkIcon(
                                                                          "objects"
                                                                        )
                                                                      ) +
                                                                      "\n                        "
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "v-list-item-content",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  [
                                                                    _vm._v(
                                                                      " Select All "
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c("v-divider", {
                                                          staticClass: "mt-2"
                                                        })
                                                      ]
                                                    },
                                                    proxy: true
                                                  },
                                                  {
                                                    key: "selection",
                                                    fn: function(ref) {
                                                      var item = ref.item
                                                      var index = ref.index
                                                      return [
                                                        index < 3
                                                          ? _c(
                                                              "v-chip",
                                                              {
                                                                attrs: {
                                                                  close: ""
                                                                },
                                                                on: {
                                                                  "click:close": function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.fields.objects.splice(
                                                                      index,
                                                                      1
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.text
                                                                    )
                                                                  )
                                                                ])
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        index === 3
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "grey--text text-caption"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                      (+" +
                                                                    _vm._s(
                                                                      _vm.fields
                                                                        .objects
                                                                        .length -
                                                                        3
                                                                    ) +
                                                                    " others)\n                    "
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: _vm.fields.objects,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.fields,
                                                    "objects",
                                                    $$v
                                                  )
                                                },
                                                expression: "fields.objects"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.fields.type !== "zone_in" &&
                              _vm.fields.type !== "zone_out"
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("validation-provider", {
                                        staticClass: "w-100",
                                        attrs: {
                                          rules:
                                            _vm.fields.type !== "zone_in" &&
                                            _vm.fields.type !== "zone_out"
                                              ? "required"
                                              : "",
                                          name: _vm.$t("DEPENDING_ON_ROUTES")
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "mb-2 d-flex flex-row"
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-body-2 mr-1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "DEPENDING_ON_ROUTES"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("FormTooltip", {
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key:
                                                                "tooltip-trigger",
                                                              fn: function() {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        "x-small":
                                                                          "",
                                                                        color:
                                                                          "primary lighten-1",
                                                                        text:
                                                                          "",
                                                                        icon: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-information-outline"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            },
                                                            {
                                                              key:
                                                                "tooltip-content",
                                                              fn: function() {
                                                                return [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "DEPENDING_ON_ROUTES"
                                                                      )
                                                                    ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-select", {
                                                    attrs: {
                                                      "menu-props": "dark",
                                                      "item-color": "#272727",
                                                      items:
                                                        _vm.routeTriggerOptions,
                                                      label: _vm.$t(
                                                        "DEPENDING_ON_ROUTES"
                                                      ),
                                                      "item-text": "text",
                                                      "item-value": "value",
                                                      outlined: "",
                                                      solo: "",
                                                      color: "grey",
                                                      "hide-details": "auto",
                                                      dense: "",
                                                      required:
                                                        _vm.fields.type !==
                                                          "zone_in" &&
                                                        _vm.fields.type !==
                                                          "zone_out",
                                                      readonly:
                                                        _vm.fields.type ===
                                                          "route_in" ||
                                                        _vm.fields.type ===
                                                          "route_out",
                                                      "error-messages": errors
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.fields
                                                          .route_trigger,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.fields,
                                                          "route_trigger",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "fields.route_trigger"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          1707790968
                                        )
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.fields.type !== "zone_in" &&
                              _vm.fields.type !== "zone_out"
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("validation-provider", {
                                        staticClass: "w-100",
                                        attrs: {
                                          rules:
                                            ["route_in", "route_out"].includes(
                                              _vm.fields.type
                                            ) &&
                                            _vm.fields.route_trigger === "off"
                                              ? "required"
                                              : "",
                                          name: _vm.$t("ROUTES")
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "mb-2 d-flex flex-row"
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-body-2 mr-1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("ROUTES")
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("FormTooltip", {
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key:
                                                                "tooltip-trigger",
                                                              fn: function() {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        "x-small":
                                                                          "",
                                                                        color:
                                                                          "primary lighten-1",
                                                                        text:
                                                                          "",
                                                                        icon: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-information-outline"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            },
                                                            {
                                                              key:
                                                                "tooltip-content",
                                                              fn: function() {
                                                                return [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "ROUTES"
                                                                      )
                                                                    ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-autocomplete", {
                                                    attrs: {
                                                      "menu-props": "dark",
                                                      "item-color": "#272727",
                                                      required:
                                                        [
                                                          "route_in",
                                                          "route_out"
                                                        ].includes(
                                                          _vm.fields.type
                                                        ) &&
                                                        _vm.fields
                                                          .route_trigger ===
                                                          "off",
                                                      items:
                                                        _vm.placeRoutesItems,
                                                      label: _vm.$t("ROUTES"),
                                                      "item-text": "text",
                                                      "item-value": "value",
                                                      solo: "",
                                                      color: "grey",
                                                      dense: "",
                                                      "hide-details": "auto",
                                                      outlined: "",
                                                      "error-messages": errors,
                                                      multiple: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "prepend-item",
                                                          fn: function() {
                                                            return [
                                                              _c(
                                                                "v-list-item",
                                                                {
                                                                  attrs: {
                                                                    ripple: ""
                                                                  },
                                                                  on: {
                                                                    mousedown: function(
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                    },
                                                                    click:
                                                                      _vm.toggleRoutes
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-action",
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                _vm.checkIcon(
                                                                                  "routes"
                                                                                )
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        [
                                                                          _vm._v(
                                                                            " Select All "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c("v-divider", {
                                                                staticClass:
                                                                  "mt-2"
                                                              })
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value: _vm.fields.routes,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.fields,
                                                          "routes",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "fields.routes"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3202540696
                                        )
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.fields.type !== "route_in" &&
                              _vm.fields.type !== "route_out"
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("validation-provider", {
                                        staticClass: "w-100",
                                        attrs: {
                                          rules: "required",
                                          name: _vm.$t("DEPENDING_ON_ZONES")
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "mb-2 d-flex flex-row"
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-body-2 mr-1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "DEPENDING_ON_ZONES"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("FormTooltip", {
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key:
                                                                "tooltip-trigger",
                                                              fn: function() {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        "x-small":
                                                                          "",
                                                                        color:
                                                                          "primary lighten-1",
                                                                        text:
                                                                          "",
                                                                        icon: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-information-outline"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            },
                                                            {
                                                              key:
                                                                "tooltip-content",
                                                              fn: function() {
                                                                return [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "DEPENDING_ON_ZONES"
                                                                      )
                                                                    ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-select", {
                                                    attrs: {
                                                      "menu-props": "dark",
                                                      "item-color": "#272727",
                                                      items:
                                                        _vm.zoneTriggerOptions,
                                                      label: _vm.$t(
                                                        "DEPENDING_ON_ZONES"
                                                      ),
                                                      "item-text": "text",
                                                      "item-value": "value",
                                                      outlined: "",
                                                      solo: "",
                                                      color: "grey",
                                                      "hide-details": "auto",
                                                      dense: "",
                                                      required: "",
                                                      "error-messages": errors,
                                                      readonly:
                                                        _vm.fields.type ===
                                                          "zone_in" ||
                                                        _vm.fields.type ===
                                                          "zone_out"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.fields.zone_trigger,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.fields,
                                                          "zone_trigger",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "fields.zone_trigger"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3869503286
                                        )
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.fields.type !== "route_in" &&
                              _vm.fields.type !== "route_out"
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("validation-provider", {
                                        staticClass: "w-100",
                                        attrs: {
                                          rules:
                                            ["zone_in", "zone_out"].includes(
                                              _vm.fields.type
                                            ) &&
                                            _vm.fields.zone_trigger === "off"
                                              ? "required"
                                              : "",
                                          name: _vm.$t("ZONES")
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "mb-2 d-flex flex-row"
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-body-2 mr-1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("ZONES")
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("FormTooltip", {
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key:
                                                                "tooltip-trigger",
                                                              fn: function() {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        "x-small":
                                                                          "",
                                                                        color:
                                                                          "primary lighten-1",
                                                                        text:
                                                                          "",
                                                                        icon: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-information-outline"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            },
                                                            {
                                                              key:
                                                                "tooltip-content",
                                                              fn: function() {
                                                                return [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "ZONES"
                                                                      )
                                                                    ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-autocomplete", {
                                                    attrs: {
                                                      "menu-props": "dark",
                                                      "item-color": "#272727",
                                                      required:
                                                        [
                                                          "zone_in",
                                                          "zone_out"
                                                        ].includes(
                                                          _vm.fields.type
                                                        ) &&
                                                        _vm.fields
                                                          .zone_trigger ===
                                                          "off",
                                                      items:
                                                        _vm.placeZonesItems,
                                                      label: _vm.$t("ZONES"),
                                                      "item-text": "text",
                                                      "item-value": "value",
                                                      solo: "",
                                                      color: "grey",
                                                      dense: "",
                                                      "hide-details": "auto",
                                                      outlined: "",
                                                      "error-messages": errors,
                                                      multiple: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "prepend-item",
                                                          fn: function() {
                                                            return [
                                                              _c(
                                                                "v-list-item",
                                                                {
                                                                  attrs: {
                                                                    ripple: ""
                                                                  },
                                                                  on: {
                                                                    mousedown: function(
                                                                      $event
                                                                    ) {
                                                                      $event.preventDefault()
                                                                    },
                                                                    click:
                                                                      _vm.toggleZones
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-list-item-action",
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                _vm.checkIcon(
                                                                                  "zones"
                                                                                )
                                                                              ) +
                                                                              "\n                          "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "v-list-item-content",
                                                                    [
                                                                      _c(
                                                                        "v-list-item-title",
                                                                        [
                                                                          _vm._v(
                                                                            " Select All "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c("v-divider", {
                                                                staticClass:
                                                                  "mt-2"
                                                              })
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value: _vm.fields.zones,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.fields,
                                                          "zones",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "fields.zones"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          946634392
                                        )
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              [
                                "connno",
                                "gpsno",
                                "stopped",
                                "moving",
                                "engidle"
                              ].includes(_vm.fields.type)
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("validation-provider", {
                                        staticClass: "w-100",
                                        attrs: {
                                          rules: [
                                            "connno",
                                            "gpsno",
                                            "stopped",
                                            "moving",
                                            "engidle"
                                          ].includes(_vm.fields.type)
                                            ? "required"
                                            : "",
                                          name: _vm.$t("TIME_PERIOD_MIN")
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "mb-2 d-flex flex-row"
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-body-2 mr-1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "TIME_PERIOD_MIN"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("FormTooltip", {
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key:
                                                                "tooltip-trigger",
                                                              fn: function() {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        "x-small":
                                                                          "",
                                                                        color:
                                                                          "primary lighten-1",
                                                                        text:
                                                                          "",
                                                                        icon: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-information-outline"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            },
                                                            {
                                                              key:
                                                                "tooltip-content",
                                                              fn: function() {
                                                                return [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "TIME_PERIOD_MIN"
                                                                      )
                                                                    ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "TIME_PERIOD_MIN"
                                                      ),
                                                      solo: "",
                                                      color: "grey",
                                                      dense: "",
                                                      "hide-details": "auto",
                                                      outlined: "",
                                                      required: [
                                                        "connno",
                                                        "gpsno",
                                                        "stopped",
                                                        "moving",
                                                        "engidle",
                                                        "overspeed",
                                                        "underspeed"
                                                      ].includes(
                                                        _vm.fields.type
                                                      ),
                                                      "error-messages": errors
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.fields
                                                          .checked_value,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.fields,
                                                          "checked_value",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "fields.checked_value"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          2201699240
                                        )
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              ["overspeed", "underspeed"].includes(
                                _vm.fields.type
                              )
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "d-flex",
                                      attrs: { cols: "12", sm: "6" }
                                    },
                                    [
                                      _c("validation-provider", {
                                        staticClass: "w-100",
                                        attrs: {
                                          rules: [
                                            "overspeed",
                                            "underspeed"
                                          ].includes(_vm.fields.type)
                                            ? "required"
                                            : "",
                                          name:
                                            _vm.$t("SPEED_LIMIT") +
                                            " (" +
                                            _vm.$t("UNIT_SPEED") +
                                            ")"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "mb-2 d-flex flex-row"
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-body-2 mr-1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "SPEED_LIMIT"
                                                              ) +
                                                                " (" +
                                                                _vm.$t(
                                                                  "UNIT_SPEED"
                                                                ) +
                                                                ")"
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c("FormTooltip", {
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key:
                                                                "tooltip-trigger",
                                                              fn: function() {
                                                                return [
                                                                  _c(
                                                                    "v-btn",
                                                                    {
                                                                      attrs: {
                                                                        "x-small":
                                                                          "",
                                                                        color:
                                                                          "primary lighten-1",
                                                                        text:
                                                                          "",
                                                                        icon: ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-icon",
                                                                        [
                                                                          _vm._v(
                                                                            "mdi-information-outline"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            },
                                                            {
                                                              key:
                                                                "tooltip-content",
                                                              fn: function() {
                                                                return [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "SPEED_LIMIT"
                                                                      ) +
                                                                        " (" +
                                                                        _vm.$t(
                                                                          "UNIT_SPEED"
                                                                        ) +
                                                                        ")"
                                                                    ) +
                                                                      "\n                        "
                                                                  )
                                                                ]
                                                              },
                                                              proxy: true
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("v-text-field", {
                                                    attrs: {
                                                      label:
                                                        _vm.$t("SPEED_LIMIT") +
                                                        " (" +
                                                        _vm.$t("UNIT_SPEED") +
                                                        ")",
                                                      solo: "",
                                                      color: "grey",
                                                      dense: "",
                                                      "hide-details": "auto",
                                                      outlined: "",
                                                      required: [
                                                        "overspeed",
                                                        "underspeed"
                                                      ].includes(
                                                        _vm.fields.type
                                                      ),
                                                      "error-messages": errors
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.fields
                                                          .checked_value,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.fields,
                                                          "checked_value",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "fields.checked_value"
                                                    }
                                                  })
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          3487215975
                                        )
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              ["sensor", "param"].includes(_vm.fields.type)
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _vm.fields.parameters !== null &&
                                      _vm.fields.parameters.length >= 1
                                        ? _c("v-data-table", {
                                            attrs: {
                                              headers: _vm.parameterHeaders,
                                              items: _vm.fields.parameters,
                                              "fixed-header": "",
                                              dark: "",
                                              "disable-filtering": "",
                                              "disable-pagination": "",
                                              "disable-sort": "",
                                              "hide-default-footer": "",
                                              "max-height": "450px"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "item.cn",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            item.cn === "eq"
                                                              ? "="
                                                              : item.cn === "gr"
                                                              ? ">"
                                                              : "<"
                                                          ) +
                                                          "\n                  "
                                                      )
                                                    ]
                                                  }
                                                },
                                                {
                                                  key: "item.remove",
                                                  fn: function(ref) {
                                                    var item = ref.item
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            "x-small": "",
                                                            icon: "",
                                                            plain: "",
                                                            color: "primary"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.remove(
                                                                item
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v("mdi-close")
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              3286214788
                                            )
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            "x-small": "",
                                            color: "primary",
                                            text: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.showParams = true
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { left: "" } },
                                            [_vm._v("mdi-plus")]
                                          ),
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("PARAMETERS_AND_SENSORS")
                                            )
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "8", xl: "6" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "w-100 mb-3", attrs: { outlined: "" } },
                    [
                      _c("v-card-title", [
                        _c("h4", { staticClass: "text-h5" }, [
                          _vm._v("Notification Settings")
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      color: "primary",
                                      "hide-details": "auto",
                                      dense: "",
                                      label: "Turn on system notification"
                                    },
                                    model: {
                                      value: _vm.fields.notifications.on_off,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.fields.notifications,
                                          "on_off",
                                          $$v
                                        )
                                      },
                                      expression: "fields.notifications.on_off"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-switch", {
                                    attrs: {
                                      color: "primary",
                                      "hide-details": "auto",
                                      dense: "",
                                      label: "Auto hide system notification"
                                    },
                                    model: {
                                      value: _vm.fields.notifications.auto_hide,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.fields.notifications,
                                          "auto_hide",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "fields.notifications.auto_hide"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-switch", {
                                    attrs: {
                                      color: "primary",
                                      "hide-details": "auto",
                                      dense: "",
                                      label:
                                        "Turn on sound alert for system notification"
                                    },
                                    model: {
                                      value: _vm.fields.notifications.sound_on,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.fields.notifications,
                                          "sound_on",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "fields.notifications.sound_on"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "8", sm: "6" } },
                                [
                                  _c("validation-provider", {
                                    staticClass: "w-100",
                                    attrs: {
                                      rules: _vm.fields.notifications.sound_on
                                        ? "required"
                                        : "",
                                      name: "Sound alert"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mb-2 d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-body-2 mr-1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("Sound alert")
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("FormTooltip", {
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "tooltip-trigger",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                  color:
                                                                    "primary lighten-1",
                                                                  text: "",
                                                                  icon: ""
                                                                }
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-information-outline"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      },
                                                      {
                                                        key: "tooltip-content",
                                                        fn: function() {
                                                          return [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "Sound alert"
                                                                )
                                                              ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("v-select", {
                                              attrs: {
                                                "menu-props": "dark",
                                                "item-color": "#272727",
                                                items: _vm.soundList,
                                                label: "Sound alert",
                                                solo: "",
                                                color: "grey",
                                                "hide-details": "auto",
                                                dense: "",
                                                outlined: "",
                                                required:
                                                  _vm.fields.notifications
                                                    .sound_on,
                                                "error-messages": errors
                                              },
                                              model: {
                                                value:
                                                  _vm.fields.notifications
                                                    .sound,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.fields.notifications,
                                                    "sound",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "fields.notifications.sound"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex align-end ",
                                  attrs: { cols: "2" }
                                },
                                [
                                  _c("audio", {
                                    ref: "audio",
                                    attrs: {
                                      id: "audio",
                                      preload: "auto",
                                      src: _vm.audioSrc
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary", text: "" },
                                      on: { click: _vm.toggleSound }
                                    },
                                    [
                                      _c("v-icon", { attrs: { left: "" } }, [
                                        _vm._v("mdi-play")
                                      ]),
                                      _vm._v("Play")
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          color: "primary",
                                          "hide-details": "auto",
                                          dense: "",
                                          label: _vm.$t("MESSAGE_TO_EMAIL")
                                        },
                                        model: {
                                          value: _vm.fields.notify_email,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.fields,
                                              "notify_email",
                                              $$v
                                            )
                                          },
                                          expression: "fields.notify_email"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "8" } },
                                    [
                                      _c("validation-provider", {
                                        staticClass: "w-100",
                                        attrs: {
                                          rules: _vm.fields.notify_email
                                            ? "required"
                                            : "",
                                          name: _vm.$t("EMAIL_ADDRESS")
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "mb-2 d-flex flex-row"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-body-2 mr-1"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "EMAIL_ADDRESS"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("FormTooltip", {
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key:
                                                              "tooltip-trigger",
                                                            fn: function() {
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      "x-small":
                                                                        "",
                                                                      color:
                                                                        "primary lighten-1",
                                                                      text: "",
                                                                      icon: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-information-outline"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            },
                                                            proxy: true
                                                          },
                                                          {
                                                            key:
                                                              "tooltip-content",
                                                            fn: function() {
                                                              return [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "MESSAGE_TO_EMAIL"
                                                                    )
                                                                  ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            },
                                                            proxy: true
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "EMAIL_ADDRESS"
                                                    ),
                                                    solo: "",
                                                    color: "grey",
                                                    dense: "",
                                                    "hide-details": "auto",
                                                    outlined: "",
                                                    required:
                                                      _vm.fields.notify_email,
                                                    "error-messages": errors
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.fields
                                                        .notify_email_address,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.fields,
                                                        "notify_email_address",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "fields.notify_email_address"
                                                  }
                                                })
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "8" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "mb-2 d-flex flex-row" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "text-body-2 mr-1" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("EMAIL_TEMPLATE"))
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("FormTooltip", {
                                            scopedSlots: _vm._u([
                                              {
                                                key: "tooltip-trigger",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          "x-small": "",
                                                          color:
                                                            "primary lighten-1",
                                                          text: "",
                                                          icon: ""
                                                        }
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "mdi-information-outline"
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              },
                                              {
                                                key: "tooltip-content",
                                                fn: function() {
                                                  return [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("EMAIL_TEMPLATE")
                                                      ) +
                                                        "\n                        "
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-select", {
                                        attrs: {
                                          "menu-props": "dark",
                                          "item-color": "#272727",
                                          items: _vm.emailTemplateList,
                                          "item-value": "value",
                                          "item-text": "text",
                                          label: _vm.$t("EMAIL_TEMPLATE"),
                                          solo: "",
                                          color: "grey",
                                          "hide-details": "auto",
                                          dense: "",
                                          outlined: ""
                                        },
                                        model: {
                                          value: _vm.fields.email_template_id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.fields,
                                              "email_template_id",
                                              $$v
                                            )
                                          },
                                          expression: "fields.email_template_id"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("v-switch", {
                                        attrs: {
                                          color: "primary",
                                          "hide-details": "auto",
                                          dense: "",
                                          label: _vm.$t("SMS_TO_MOBILE_PHONE")
                                        },
                                        model: {
                                          value: _vm.fields.notify_sms,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.fields,
                                              "notify_sms",
                                              $$v
                                            )
                                          },
                                          expression: "fields.notify_sms"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "8" } },
                                    [
                                      _c("validation-provider", {
                                        staticClass: "w-100",
                                        attrs: {
                                          rules: _vm.fields.notify_sms
                                            ? "required"
                                            : "",
                                          name: _vm.$t("PHONE_NUMBER_WITH_CODE")
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "mb-2 d-flex flex-row"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-body-2 mr-1"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "PHONE_NUMBER_WITH_CODE"
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("FormTooltip", {
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key:
                                                              "tooltip-trigger",
                                                            fn: function() {
                                                              return [
                                                                _c(
                                                                  "v-btn",
                                                                  {
                                                                    attrs: {
                                                                      "x-small":
                                                                        "",
                                                                      color:
                                                                        "primary lighten-1",
                                                                      text: "",
                                                                      icon: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-information-outline"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            },
                                                            proxy: true
                                                          },
                                                          {
                                                            key:
                                                              "tooltip-content",
                                                            fn: function() {
                                                              return [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "SMS_TO_MOBILE_PHONE"
                                                                    )
                                                                  ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            },
                                                            proxy: true
                                                          }
                                                        ],
                                                        null,
                                                        true
                                                      )
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("v-text-field", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "PHONE_NUMBER_WITH_CODE"
                                                    ),
                                                    solo: "",
                                                    color: "grey",
                                                    dense: "",
                                                    "hide-details": "auto",
                                                    outlined: "",
                                                    required:
                                                      _vm.fields.notify_sms,
                                                    "error-messages": errors
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.fields
                                                        .notify_sms_number,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.fields,
                                                        "notify_sms_number",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "fields.notify_sms_number"
                                                  }
                                                })
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "8" } },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "mb-2 d-flex flex-row" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "text-body-2 mr-1" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t("SMS_TEMPLATE"))
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("FormTooltip", {
                                            scopedSlots: _vm._u([
                                              {
                                                key: "tooltip-trigger",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          "x-small": "",
                                                          color:
                                                            "primary lighten-1",
                                                          text: "",
                                                          icon: ""
                                                        }
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "mdi-information-outline"
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              },
                                              {
                                                key: "tooltip-content",
                                                fn: function() {
                                                  return [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("SMS_TEMPLATE")
                                                      ) +
                                                        "\n                        "
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("v-select", {
                                        attrs: {
                                          "menu-props": "dark",
                                          "item-color": "#272727",
                                          items: _vm.smsTemplateList,
                                          "item-value": "value",
                                          "item-text": "text",
                                          label: _vm.$t("SMS_TEMPLATE"),
                                          solo: "",
                                          color: "grey",
                                          "hide-details": "auto",
                                          dense: "",
                                          outlined: ""
                                        },
                                        model: {
                                          value: _vm.fields.sms_template_id,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.fields,
                                              "sms_template_id",
                                              $$v
                                            )
                                          },
                                          expression: "fields.sms_template_id"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "12", md: "8", xl: "6" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "w-100 mb-3", attrs: { outlined: "" } },
                    [
                      _c("v-card-title", [
                        _c("h4", { staticClass: "text-h5" }, [
                          _vm._v(_vm._s(_vm.$t("COLORS")))
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "8" } },
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      color: "primary",
                                      "hide-details": "auto",
                                      dense: "",
                                      label: "Turn on object arrow color"
                                    },
                                    model: {
                                      value: _vm.fields.notify_arrow,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.fields,
                                          "notify_arrow",
                                          $$v
                                        )
                                      },
                                      expression: "fields.notify_arrow"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "8" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "mb-2 d-flex flex-row" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-body-2 mr-1" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("OBJECT_ARROW_COLOR"))
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("FormTooltip", {
                                        scopedSlots: _vm._u([
                                          {
                                            key: "tooltip-trigger",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      "x-small": "",
                                                      color:
                                                        "primary lighten-1",
                                                      text: "",
                                                      icon: ""
                                                    }
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        "mdi-information-outline"
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            },
                                            proxy: true
                                          },
                                          {
                                            key: "tooltip-content",
                                            fn: function() {
                                              return [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("OBJECT_ARROW_COLOR")
                                                  ) +
                                                    "\n                        "
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-select", {
                                    attrs: {
                                      "menu-props": "dark",
                                      "item-color": "#272727",
                                      items: _vm.arrowColorList,
                                      "item-value": "value",
                                      "item-text": "text",
                                      label: _vm.$t("OBJECT_ARROW_COLOR"),
                                      solo: "",
                                      color: "grey",
                                      "hide-details": "auto",
                                      dense: "",
                                      outlined: ""
                                    },
                                    model: {
                                      value: _vm.fields.notify_arrow_color,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.fields,
                                          "notify_arrow_color",
                                          $$v
                                        )
                                      },
                                      expression: "fields.notify_arrow_color"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", sm: "8" } },
                                [
                                  _c("v-switch", {
                                    attrs: {
                                      color: "primary",
                                      "hide-details": "auto",
                                      dense: "",
                                      label: "Turn on object list color"
                                    },
                                    model: {
                                      value: _vm.fields.notify_ohc,
                                      callback: function($$v) {
                                        _vm.$set(_vm.fields, "notify_ohc", $$v)
                                      },
                                      expression: "fields.notify_ohc"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex flex-column",
                                  attrs: { cols: "12", sm: "8" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "mb-2 d-flex flex-row" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text-body-2 mr-1" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("OBJECT_LIST_COLOR"))
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("FormTooltip", {
                                        scopedSlots: _vm._u([
                                          {
                                            key: "tooltip-trigger",
                                            fn: function() {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    attrs: {
                                                      "x-small": "",
                                                      color:
                                                        "primary lighten-1",
                                                      text: "",
                                                      icon: ""
                                                    }
                                                  },
                                                  [
                                                    _c("v-icon", [
                                                      _vm._v(
                                                        "mdi-information-outline"
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            },
                                            proxy: true
                                          },
                                          {
                                            key: "tooltip-content",
                                            fn: function() {
                                              return [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("OBJECT_LIST_COLOR")
                                                  ) +
                                                    "\n                        "
                                                )
                                              ]
                                            },
                                            proxy: true
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex" },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-text-field",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: {
                                                            label: _vm.$t(
                                                              "OBJECT_LIST_COLOR"
                                                            ),
                                                            solo: "",
                                                            color: "grey",
                                                            dense: "",
                                                            "hide-details":
                                                              "auto",
                                                            outlined: "",
                                                            required: "",
                                                            "append-icon":
                                                              "mdi-eyedropper-variant"
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.fields
                                                                .notify_ohc_color,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.fields,
                                                                "notify_ohc_color",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "fields.notify_ohc_color"
                                                          }
                                                        },
                                                        "v-text-field",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    )
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "color-picker-container"
                                              },
                                              [
                                                _c("v-color-picker", {
                                                  attrs: {
                                                    mode: "hexa",
                                                    dark: "",
                                                    "dot-size": "15",
                                                    "swatches-max-height":
                                                      "250",
                                                    "hide-inputs": "",
                                                    "hide-mode-switch": "",
                                                    value: _vm.fields.ohc_color
                                                      ? _vm.fields.ohc_color
                                                      : _vm.fields
                                                          .notify_ohc_color
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.fields
                                                        .ohc_color_object,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.fields,
                                                        "ohc_color_object",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "fields.ohc_color_object"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ])
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-sheet",
                                        {
                                          staticClass: "pa-2",
                                          attrs: {
                                            width: "40",
                                            height: "40",
                                            light: "",
                                            color: _vm.fields.ohc_color
                                              ? _vm.fields.ohc_color
                                              : _vm.fields.notify_ohc_color
                                          }
                                        },
                                        [_c("pre", [_vm._v(" ")])]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            {
              staticClass:
                "\n        mb-3\n        justify-center\n        flex-column flex-sm-row\n        d-flex\n      "
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-sm-2 mb-2",
                  attrs: {
                    color: "primary",
                    outlined: "",
                    "min-width": "200px",
                    "max-width": "50%"
                  },
                  on: {
                    click: function($event) {
                      return _vm.$router.push({ name: "mobile/triggers" })
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    "min-width": "200px",
                    "max-width": "50%"
                  },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("Save")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("TriggerParametersModal", {
        attrs: {
          show: _vm.showParams,
          objects: _vm.fields.objects,
          type: _vm.fields.type
        },
        on: { "set-parameters": _vm.setParameters }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }