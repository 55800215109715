<template>
  <v-container v-if="$router.currentRoute.name === 'forgot'">
    <validation-observer ref="forget">
      <p class="text-body-2 error--text">{{ errorMessage }}</p>
      <v-row>
        <v-col>
          <validation-provider
            v-slot="{ errors }"
            rules="required|email"
            :name="$t('EMAIL')"
            ><v-text-field
              v-model="forgetEmail"
              :error-messages="errors"
              :placeholder="$t('EMAIL')"
              required
              solo
              color="grey"
              dense
              hide-details="auto"
              outlined
            >
            </v-text-field
          ></validation-provider>
        </v-col>
      </v-row>

      <v-row class="">
        <v-col>
          <v-btn @click="recover" color="primary" class="w-100">Recover</v-btn>
          <v-btn class="w-100" plain exact to="/mobile/login"
            >Go back to login?</v-btn
          >
        </v-col>
      </v-row>
    </validation-observer>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      forgetEmail: '',
      errorMessage: '',
      csrfToken: ''
    }
  },
  methods: {
    ...mapActions({
      fetchCsrfToken: 'auth/fetchCsrfToken',
      recoverUrl: 'auth/recoverUrl'
    }),
    async recover () {
      const valid = await this.$refs.forget.validate()
      if (valid) {
        const response = await this.recoverUrl({
          email: this.forgetEmail,
          csrf_token: this.csrfToken,
          is_mobile: true
        })
        switch (response) {
          case 'OK':
            this.errorMessage = ''
            this.$notify({
                    duration: 5000,
                    closeOnClick: false,
              type: 'success',
              title: '',
              text:
                this.$t('RECOVERY_LINK_SENT') +
                ' ' +
                this.$t('PLEASE_CHECK_YOUR_EMAIL')
            })
            this.$router.push({ name: 'login' })
            break
          case 'ERROR_NOT_SENT':
            this.errorMessage =
              this.$t('CANT_SEND_EMAIL') +
              ' ' +
              this.$t('CONTACT_ADMINISTRATOR')
            break
          case 'CSRF':
            this.errorMessage =
              'There was a problem with your form, you have to reload the page and try again.'
            break
          case 'ERROR_EMAIL_NOT_FOUND':
            this.errorMessage = this.$t('THIS_EMAIL_IS_NOT_REGISTERED')
            break
          default:
            this.errorMessage = response
        }
      }
    }
  },
  created () {
    this.forgetEmail = ''
    this.errorMessage = ''
    this.csrfToken = ''

    this.fetchCsrfToken()
      .then((response) => {
        this.csrfToken = response
      })
      .catch((e) => this.handleError(e))
  }
}
</script>

<style>
</style>
