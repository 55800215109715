import axios from 'axios'
import { globals } from '@/plugins/global'

export const trailersService = {
  getTrailers, saveTrailer, removeTrailer, importTrailers
}

const requestUrl = process.env.BASE_URL + 'src/assets/json/settings'

function getTrailers () {
  if (process.env.NODE_ENV === 'development') {
    return axios.get(`${requestUrl}/trailers_data.json`)
  }
  return axios.post('/trailers/data', globals.convertJsonToFormData({ cmd: 'load_object_trailer_data' }))
}

function saveTrailer (params) {
  return axios.post('/trailers/save', globals.convertJsonToFormData(params))
}

function removeTrailer (ids) {
  const formData = new FormData()
  ids.forEach(id => {
    formData.append('items[]', id)
  })
  return axios.post('/trailers/delete', formData)
}

function importTrailers (params) {
  return axios.post('/trailers/import', globals.convertJsonToFormData(params))
}
