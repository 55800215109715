<template>
  <v-dialog
    persistent
    v-model="show"
    hide-overlay
    no-click-animation
    max-width="370"
    width="auto"
  >
    <v-card outlined>
      <v-toolbar color="" dense elevation="0">
        <v-toolbar-title class="text-subtitle-2">{{
          $t("OBJECT_DRIVER_PROPERTIES")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn plain icon x-small @click="closeDialog">
          <v-icon dense>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container fluid>
        <validation-observer ref="observer">
          <v-row>
            <v-col>
              <div class="mb-2 d-flex flex-row">
                <span class="text-body-2 mr-1">{{ $t("NAME") }}</span>

                <FormTooltip>
                  <template v-slot:tooltip-trigger>
                    <v-btn x-small color="primary lighten-1" text icon>
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:tooltip-content>{{ $t("NAME") }} </template>
                </FormTooltip>
              </div>
              <validation-provider
                v-slot="{ errors }"
                rules="required|max:30"
                :name="$t('NAME')"
                ><v-text-field
                  v-model="fields.name"
                  :error-messages="errors"
                  :label="$t('NAME')"
                  required
                  solo
                  color="grey"
                  dense
                  hide-details="auto"
                  outlined
                ></v-text-field
              ></validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <div class="mb-2 d-flex flex-row">
                <span class="text-body-2 mr-1">{{ $t("PHONE") }}</span>

                <FormTooltip>
                  <template v-slot:tooltip-trigger>
                    <v-btn x-small color="primary lighten-1" text icon>
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:tooltip-content>{{ $t("PHONE") }} </template>
                </FormTooltip>
              </div>
              <validation-provider
                v-slot="{ errors }"
                rules="max:50"
                :name="$t('PHONE')"
                ><v-text-field
                  v-model="fields.phone_number"
                  :label="$t('PHONE')"
                  :error-messages="errors"
                  solo
                  color="grey"
                  dense
                  hide-details="auto"
                  outlined
                ></v-text-field
              ></validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="mb-2 d-flex flex-row">
                <span class="text-body-2 mr-1">{{ $t("EMAIL") }}</span>

                <FormTooltip>
                  <template v-slot:tooltip-trigger>
                    <v-btn x-small color="primary lighten-1" text icon>
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:tooltip-content>{{ $t("EMAIL") }} </template>
                </FormTooltip>
              </div>
              <validation-provider
                v-slot="{ errors }"
                rules="email|max:50"
                :name="$t('EMAIL')"
                ><v-text-field
                  v-model="fields.email"
                  :label="$t('EMAIL')"
                  :error-messages="errors"
                  solo
                  color="grey"
                  dense
                  hide-details="auto"
                  outlined
                ></v-text-field
              ></validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <div class="mb-2 d-flex flex-row">
                <span class="text-body-2 mr-1">Role</span>

                <FormTooltip>
                  <template v-slot:tooltip-trigger>
                    <v-btn x-small color="primary lighten-1" text icon>
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:tooltip-content>Role</template>
                </FormTooltip>
              </div>
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="Role"
              >
                <v-select
                  menu-props="dark"
                  item-color="#272727"
                  required
                  v-model="fields.access"
                  label="Role"
                  :items="['Admin', 'Supervisor', 'Driver']"
                  solo
                  color="grey"
                  dense
                  hide-details="auto"
                  outlined
                  :error-messages="errors"
                ></v-select>
              </validation-provider>
            </v-col>
          </v-row>

          <v-row class="mb-3 justify-center flex-column flex-sm-row d-flex">
            <v-btn
              @click="closeDialog"
              color="primary"
              outlined
              class="mr-sm-2 mb-2"
              max-width="50%"
              >Cancel</v-btn
            >
            <v-btn @click="save" color="primary" max-width="50%">Save</v-btn>
          </v-row>
        </validation-observer>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import FormTooltip from '@/components/includes/FormTooltip.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormTooltip
  },
  props: {
    show: Boolean
    // id: String,
    // type: String
  },
  data () {
    return {
      fields: {
        name: '',
        phone_number: '',
        email: '',
        access: ''
      },
      showConfirm: false,
      confirmDetails: {
        title: '',
        message: '',
        action: {
          yes: '',
          no: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      // message: 'messages/message',
      // drivers: 'person/drivers',
      // passengers: 'person/passengers',
      // getPassengerById: 'person/getPassengerById'
    })
  },
  watch: {},
  methods: {
    ...mapActions({
      inviteDriver: 'drivers/inviteDriver'
    }),
    async save () {
      try {
        const valid = await this.$refs.observer.validate()

        let response

        if (valid) {
          this.fields.access = this.fields.access.toLowerCase()
          response = await this.inviteDriver(this.fields)

          if (response.message === 'OK') {
            this.$notify({
                    duration: 5000,
                    closeOnClick: false,
              type: 'success',
              title: this.$t('INFORMATION'),
              text: this.$t('CHANGES_SAVED_SUCCESSFULLY')
            })
            if (process.env.NODE_ENV === 'production') {
              // if (this.type === 'passenger') {
              //   this.fetchPassengers()
              // } else {
              //   this.fetchDrivers()
              // }
            }
            this.closeDialog()
          } else {
            this.$notify({
                    duration: 5000,
                    closeOnClick: false,
              type: 'error',
              title: this.$t('ERROR'),
              text:
                'Something went wrong! Please contact system administrator.'
            })
            this.closeDialog()
          }
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    closeDialog () {
      this.$refs.observer.reset()
      this.$emit('show-driver-dialog', false)
    }
  }
}
</script>

<style></style>
