var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-alert",
    {
      staticClass: "text-caption",
      attrs: { color: "info", border: "left", "colored-border": "", dense: "" }
    },
    [
      _vm.triggerText === "Overspeed"
        ? _c("p", [
            _vm._v(
              "\n    An overspeed trigger will create an Event when the selected object or\n    objects start to travel faster than the defined Speed Limit.\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.triggerText === "Ignition"
        ? _c("p", [
            _vm._v(
              "\n    An Ignition On trigger will create an Event whenever the selected object\n    or object's ignition has changed from Off to On. This requires a correctly\n    wired ignition circuit.\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.triggerText === "Geofence"
        ? _c("p", [
            _vm._v(
              "\n    A geofence trigger will create an Event whenever the selected object or\n    objects enter or leave the selected zone\n  "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "\n    This Event can be viewed on the map, in the history, and can be used to\n    create Notifications that you can receive in the Gator App, Email or via\n    SMS.\n  "
        )
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          attrs: {
            href: "https://gatortracking.com.au/tracker/guide/events",
            target: "_blank"
          }
        },
        [_vm._v("Documentation & Help")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }