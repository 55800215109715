
const mutations = {
  // setPlaces: (state, data) => (state.places = data),
  SET_FILES_PLACE_MARKER: (state, data) => {
    state.places.markers = data
    // Vue.$log.debug('SET_FILES_PLACE_MARKER', state.places.markers);
  },
  SET_FILES_PLACE_ICONS_CUSTOM: (state, data) => (state.places.custom_markers = data)

}

export default mutations
