<template>
  <div class="container--fluid lower-content">
    <ObjectsForm :show="showForm" v-on:show-objects-dialog="setShowModal" />
    <TheLowerTabs>
      <template v-slot:lower-tab-items>
        <v-tab-item dark>
          <TheLowerTabsDashboard>
            <template v-slot:dashboard-table-layout>
              <TheLowerTabsDashboardTableLayout>
                <template v-slot:dashboard-table-header>
                  <v-col lg="6" md="6" sm="12">Active Tracker Name</v-col>
                  <v-col lg="6" md="6" sm="12"></v-col>
                </template>
                <template v-slot:active-tracker-table>
                  <TheParameterTable
                    v-bind:parameter-data="activeObjectData"
                  ></TheParameterTable>
                </template>
                <template v-slot:active-driver-table>
                  <TheParameterTable
                    v-bind:parameter-data="parameterData2"
                  ></TheParameterTable>
                </template>
              </TheLowerTabsDashboardTableLayout>
            </template>
          </TheLowerTabsDashboard>
        </v-tab-item>
        <v-tab-item>
          <TheLowerTabsStreetView
            v-bind:street-view-data="streetViewData"
          ></TheLowerTabsStreetView>
        </v-tab-item>
        <v-tab-item>
          <TheLowerTabsVideo v-bind:video-data="videoData"></TheLowerTabsVideo>
        </v-tab-item>
      </template>
    </TheLowerTabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TheLowerTabs from '@/layout/TheLowerTabs.vue'
import TheLowerTabsDashboard from '@/layout/TheLowerTabsDashboard.vue'
import TheLowerTabsDashboardTableLayout from '@/layout/TheLowerTabsDashboardTableLayout.vue'
import TheLowerTabsVideo from '@/layout/TheLowerTabsVideo.vue'
import TheLowerTabsStreetView from '@/layout/TheLowerTabsStreetView.vue'
import TheParameterTable from '@/layout/TheParameterTable.vue'
import ObjectsForm from '@/components/forms/ObjectsForm.vue'

export default {
  components: {
    TheLowerTabs,
    TheLowerTabsDashboard,
    TheLowerTabsDashboardTableLayout,
    TheParameterTable,
    TheLowerTabsVideo,
    TheLowerTabsStreetView,
    ObjectsForm
  },
  props: {
    selectedObject: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showForm: false,
      parameterData2: {
        singleSelect: false,
        selected: [],
        headers: [
          { text: '', value: 'icon', sortable: false },
          { text: 'Parameter', value: 'parameter', sortable: false },
          { text: 'Status', value: 'status', sortable: false }
        ],
        parameters: [
          {
            locate: '',
            parameter: 'License Types',
            status: ''
          },
          {
            locate: '',
            parameter: 'Fatigue Management Training',
            status: ''
          },
          {
            locate: '',
            parameter: 'Something else',
            status: ''
          },
          {
            locate: '',
            parameter: '',
            status: ''
          }
        ]
      },
      streetViewData: 'Devices Street View Data',
      videoData: 'Devices Video Data'
    }
  },
  computed: {
    ...mapGetters({
      // objects: 'objects/objectsData',
      getObjectSensorByName: 'objects/getObjectSensorByName',
      getSensorValue: 'objects/getSensorValue'
    }),
    activeObjectData: function () {
      try {
        const id = this.selectedObject

        const sensors = {
          'Accident Detection': '',
          'SOS Button': '',
          'Tow Detection': '',
          'Door Status': '',
          'Vehicle Battery': '',
          'Driver Behaviour': ''
        }

        for (const [key] of Object.entries(sensors)) {
          const sen = this.getObjectSensorByName(id, key)
          // this.$log.debug(sen, id, key)
          if (sen.length >= 1) {
            // const param = sen[0].param
            // this.$log.debug(id, key, param)
            const sensorValue = this.getSensorValue(
              id,
              key
              // this.objects[id].data.params[param]
            )
            sensors[key] = sensorValue ? sensorValue.value_full : ''
          }
        }
        const parameters = [
          {
            icon: '',
            parameter: 'Accident Detection',
            status: sensors['Accident Detection']
          },
          {
            icon: '',
            parameter: 'SOS Button',
            status: sensors['SOS Button']
          },
          {
            icon: '',
            parameter: 'Tow Detection',
            status: sensors['Tow Detection']
          },
          {
            icon: '',
            parameter: 'Next Service In',
            status: '17,976 km'
          },
          {
            icon: '',
            parameter: 'Door Status',
            status: sensors['Door Status']
          },
          {
            icon: '',
            parameter: 'Vehicle Battery',
            status: sensors['Vehicle Battery']
          },
          {
            icon: '',
            parameter: 'Last/Current Speed',
            status: this.objectsNew[id].data.speed
          },
          {
            icon: '',
            parameter: 'Eco Driving Events (24hr)',
            status: sensors['Driver Behaviour']
          }
        ]

        const data = {
          singleSelect: false,
          selected: [],
          headers: [
            { text: '', value: 'icon', sortable: false },
            { text: 'Parameter', value: 'parameter', sortable: false },
            { text: 'Status', value: 'status', sortable: false }
          ],
          parameters: parameters
        }
        return data
      } catch (error) {
        this.handleError(error)
      }
      return null
    }
  },
  methods: {
    setShowModal: function (isShow) {
      this.showForm = isShow
    },
    openObjectsForm: function () {
      if (this.checkPrivilege('subuser') && this.checkPrivilege('obj_add')) {
        this.showForm = true
      } else {
        this.$notify({
                    duration: 5000,
                    closeOnClick: false,
          type: 'error',
          title: this.$t('ERROR'),
          text: this.$t('THIS_ACCOUNT_HAS_NO_PRIVILEGES_TO_DO_THAT')
        })
      }
    }
  }
}
</script>

<style></style>
