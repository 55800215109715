import { render, staticRenderFns } from "./BeaconsTable.vue?vue&type=template&id=423f926f&"
import script from "./BeaconsTable.vue?vue&type=script&lang=js&"
export * from "./BeaconsTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Applications/XAMPP/xamppfiles/htdocs/gator-spa/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('423f926f')) {
      api.createRecord('423f926f', component.options)
    } else {
      api.reload('423f926f', component.options)
    }
    module.hot.accept("./BeaconsTable.vue?vue&type=template&id=423f926f&", function () {
      api.rerender('423f926f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/beacons/BeaconsTable.vue"
export default component.exports