<template>
  <div class="tab-content">
      <div class="container--fluid upper-content">
    <div class="upper-content__table">
      <PlacesTable v-if="placesData" :table-data="placesData" />
    </div>

    <!-- <PlaceMarkersForm
      :show="showForm && type === 'markers'"
      v-on:show-place-dialog="setShowModal"
    /> -->
    <!-- <PlaceRoutesForm
      :show="showForm && type === 'routes'"
      v-on:show-place-dialog="setShowModal"
    />
    <PlaceZonesForm
      :show="showForm && type === 'zones'"
      v-on:show-place-dialog="setShowModal"
    /> -->
  </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { placeMixin } from '@/mixins/placeMixin'
import PlacesTable from '@/components/places/PlacesTable.vue'

export default {
  mixins: [placeMixin],
  components: {
    PlacesTable
  },
  methods: {
    ...mapActions({
      fetchPlacesData: 'places/fetchPlacesData',
      initializeObjectData: 'objects/initializeObjectData',
      fetchSiteSettings: 'settings/fetchSiteSettings',
      initializePlacesData: 'places/initializePlacesData'
    })
  },

  computed: {
    ...mapGetters({
      placeMarkers: 'places/placeMarkers',
      placeRoutes: 'places/placeRoutes',
      placeZones: 'places/placeZones',
      // objects: 'objects/objectsData',
      getObjectSettingsById: 'objects/getObjectSettingsById',
      user: 'settings/user',
      getDriverName: 'person/getDriverName'
    }),
    placesData: function () {
      const tableData = []
      try {
        // this.objectsData = this.objects;
        // this.user = this.user;
        const markers = this.placeMarkers
        const zones = this.placeZones
        const routes = this.placeRoutes

        if (
          this.user.length === 0 &&
          markers.length === 0 &&
          zones.length === 0 &&
          routes.length === 0
        ) {
          return tableData
        }

        // this.getClosestAsset()
        for (const [key, marker] of Object.entries(markers)) {
          const obj = this.getObjectSettingsById(marker.closest_asset.object)
          tableData.push({
            id: key,
            group: 'Markers',
            locate: marker.locate,
            visible: marker.visible,
            place_name: marker.data.name,
            device_name: obj.name,
            driver_name: this.getDriverName(obj.driver_id),
            distance: marker.closest_asset.distance
          })
        }
        for (const [key, route] of Object.entries(routes)) {
          const obj = this.getObjectSettingsById(route.closest_asset.object)
          tableData.push({
            id: key,
            group: 'Routes',
            locate: route.locate,
            visible: route.visible,
            place_name: route.data.name,
            device_name: obj.name,
            driver_name: this.getDriverName(obj.driver_id),
            distance: route.closest_asset.distance
          })
        }
        for (const [key, zone] of Object.entries(zones)) {
          const obj = this.getObjectSettingsById(zone.closest_asset.object)
          tableData.push({
            id: key,
            group: 'Zones',
            locate: zone.locate,
            visible: zone.visible,
            place_name: zone.data.name,
            device_name: obj.name,
            driver_name: this.getDriverName(obj.driver_id),
            distance: zone.closest_asset.distance
          })
        }
      } catch (error) {
        this.handleError(error)
      }

      return tableData
    }
  },
  created () {
    try {
      this.fetchSiteSettings('load_user_data')
      this.initializeObjectData()
      this.initializePlacesData()
    } catch (error) {
      this.handleError(error)
    }

    // var params = ["load_marker_data", "load_route_data", "load_zone_data"];
    // let fetchPlacesData = this.fetchPlacesData;
    // params.forEach(function(cmd) {
    //   fetchPlacesData({ cmd: cmd });
    // });
  }
}
</script>

<style></style>
