import axios from 'axios'
import { globals } from '@/plugins/global'

export const eventsService = {
  getEvents, //
  getEvent,
  getEventsByBatch,
  getEventsNames,
  getEventsType,
  getEventVideos,
  filterEventVideos,
  requestVideoFootage,
  getVideo,
  getVideoRequestQueue
}

const requestUrl = process.env.BASE_URL + 'src/assets/json/'

function getEvents () {
  if (process.env.NODE_ENV === 'development') {
    return axios.get(`${requestUrl}/events.json`)
  }
  return axios.post('/events/data')
}

function getEvent (params) {
  if (process.env.NODE_ENV === 'development') {
    return axios.get(`${requestUrl}/event.json`)
  }
  return axios.post('/events/data', globals.convertJsonToFormData(params))
}

function getEventsByBatch (params) {
  if (process.env.NODE_ENV === 'development') {
    return axios.get(`${requestUrl}/events.json`)
  }

  return axios.get('/events/data/batch', {params: params})
}

function getEventsNames () {
  return axios.post('/events/data/names')
}

function getEventsType () {
  return axios.post('/events/data/type')
}

function getEventVideos (params) {
  if (process.env.NODE_ENV === 'development') {
    return axios.get(`${requestUrl}/event_videos.json`)
  }
  return axios.get('/images/data', {params: params})
}

function filterEventVideos (params) {
  // console.log(params)
  if (process.env.NODE_ENV === 'development') {
    return axios.get(`${requestUrl}/event_videos.json`)
  }
  return axios.post('/images/data/search', globals.convertJsonToFormData(params))
}


function requestVideoFootage (params) {
  return axios.get('/images/pick', {params: params})
}


function getVideo (params) {
  return axios.get('/images/web_one', {params: params})
}

function getVideoRequestQueue () {
  return axios.get('/images/queue')
}