var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c("TheHeader", { on: { "selected-menu": _vm.getSelectedMenu } }),
      _vm._v(" "),
      _c("AlertMessage"),
      _vm._v(" "),
      _c(
        "v-main",
        { attrs: { id: "content" } },
        [
          !_vm.isMobileView &&
          _vm.$router.currentRoute.name !== "live" &&
          _vm.$router.currentRoute.name !== "objects/details"
            ? _c(
                "v-container",
                {
                  staticClass: "main-content tracking-page d-flex",
                  attrs: { fluid: "" }
                },
                [_c("TheLeftContent"), _vm._v(" "), _c("TheRightContent")],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          (_vm.isMobileView ||
            _vm.$router.currentRoute.name === "objects/details") &&
          _vm.$router.currentRoute.name !== "live"
            ? _c(
                "div",
                { staticClass: "main-content tracking-page d-block" },
                [_c("router-view")],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.$router.currentRoute.name === "live"
            ? _c("div", { staticClass: "pa-0" }, [_c("Live")], 1)
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }