var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "hide-overlay": "",
        "no-click-animation": "",
        "max-width": "340"
      },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-toolbar",
            { attrs: { dense: "", elevation: "0" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { plain: "", icon: "", "x-small": "" },
                  on: {
                    click: function($event) {
                      return _vm.closeDialog(false)
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { dark: "", dense: "" } }, [
                    _vm._v("mdi-close")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function($event) {
                      return _vm.closeDialog(true)
                    }
                  }
                },
                [_vm._v(" Save ")]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("TriggerMapModal", {
            attrs: { show: _vm.showMapModal },
            on: { "set-geofence": _vm.setGeofence }
          }),
          _vm._v(" "),
          _c(
            "validation-observer",
            { ref: "observer" },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("TriggerHelpInformation", {
                            attrs: { type: _vm.type }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.type === "zone_out"
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-select", {
                                attrs: {
                                  "menu-props": "dark",
                                  "item-color": "#272727",
                                  color: "grey",
                                  items: ["Both", "In", "Out"],
                                  label: "Geofensing Type",
                                  "hide-details": "auto",
                                  solo: "",
                                  dense: "",
                                  outlined: ""
                                },
                                model: {
                                  value: _vm.fields.inout,
                                  callback: function($$v) {
                                    _vm.$set(_vm.fields, "inout", $$v)
                                  },
                                  expression: "fields.inout"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type === "zone_out"
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("validation-provider", {
                                attrs: {
                                  rules:
                                    "" +
                                    (_vm.type === "zone_out" &&
                                    !_vm.fields.polygon
                                      ? "required"
                                      : ""),
                                  name: "Zones"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-select", {
                                            attrs: {
                                              "menu-props": "dark",
                                              "item-color": "#272727",
                                              color: "grey",
                                              items: _vm.placeZonesItems,
                                              "item-text": "text",
                                              "item-value": "value",
                                              label: "Zones",
                                              "hide-details": "auto",
                                              required:
                                                _vm.type === "zone_out" &&
                                                !_vm.fields.polygon,
                                              "error-messages": errors,
                                              solo: "",
                                              dense: "",
                                              outlined: ""
                                            },
                                            on: { change: _vm.zoneChange },
                                            model: {
                                              value: _vm.fields.zone,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.fields,
                                                  "zone",
                                                  $$v
                                                )
                                              },
                                              expression: "fields.zone"
                                            }
                                          })
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  1014480162
                                )
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "ml-3",
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.showMapModal = true
                                }
                              }
                            },
                            [_vm._v("Create a new zone")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type === "zone_out"
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-sheet",
                                {
                                  attrs: {
                                    color: "white",
                                    elevation: "1",
                                    height: "200px",
                                    width: "100%"
                                  }
                                },
                                [
                                  _c(
                                    "editable-map",
                                    {
                                      ref: "map",
                                      attrs: {
                                        editable: "",
                                        options: _vm.mapOptions,
                                        maxZoom: _vm.config.map_max_zoom,
                                        minZoom: _vm.config.map_min_zoom,
                                        center: _vm.defaultCenter,
                                        zoom: parseInt(_vm.server.map_zoom)
                                      },
                                      on: { ready: _vm.ready }
                                    },
                                    [
                                      _c("l-tile-layer", {
                                        attrs: {
                                          url: _vm.url,
                                          attribution: _vm.attribution
                                        }
                                      }),
                                      _vm._v(" "),
                                      _vm.fields.polygon
                                        ? _c("l-polygon", {
                                            attrs: {
                                              "lat-lngs": _vm.polygonLatLngs,
                                              color: _vm.polygon.color
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type === "sensor"
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-switch", {
                                attrs: {
                                  color: "primary",
                                  "hide-details": "auto",
                                  dense: "",
                                  label: _vm.fields.trigger_on
                                    ? "Trigger when Ignition starts"
                                    : "Trigger when Ignition ends"
                                },
                                model: {
                                  value: _vm.fields.trigger_on,
                                  callback: function($$v) {
                                    _vm.$set(_vm.fields, "trigger_on", $$v)
                                  },
                                  expression: "fields.trigger_on"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type === "overspeed"
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-radio-group",
                                {
                                  staticClass: "ma-0",
                                  attrs: {
                                    label: "Speed Type",
                                    dense: "",
                                    "hide-details": "auto"
                                  },
                                  model: {
                                    value: _vm.fields.speed_type,
                                    callback: function($$v) {
                                      _vm.$set(_vm.fields, "speed_type", $$v)
                                    },
                                    expression: "fields.speed_type"
                                  }
                                },
                                [
                                  _c("v-radio", {
                                    attrs: {
                                      color: "primary",
                                      label: "Over limit",
                                      value: "overspeed"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("v-radio", {
                                    attrs: {
                                      color: "primary",
                                      label: "Under limit",
                                      value: "underspeed"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type === "overspeed"
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("validation-provider", {
                                attrs: {
                                  rules:
                                    (_vm.type === "overspeed"
                                      ? "required|"
                                      : "") + "number",
                                  name:
                                    _vm.$t("SPEED_LIMIT") +
                                    " (" +
                                    _vm.$t("UNIT_SPEED") +
                                    ")"
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        return [
                                          _c("v-text-field", {
                                            attrs: {
                                              required:
                                                _vm.type === "overspeed",
                                              label:
                                                "Set " +
                                                _vm.$t("SPEED_LIMIT") +
                                                " (" +
                                                _vm.$t("UNIT_SPEED") +
                                                ")",
                                              "error-messages": errors,
                                              filled: "",
                                              flat: "",
                                              dense: "",
                                              outlined: "",
                                              color: "grey"
                                            },
                                            model: {
                                              value: _vm.fields.speed,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.fields,
                                                  "speed",
                                                  $$v
                                                )
                                              },
                                              expression: "fields.speed"
                                            }
                                          })
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3846887196
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }