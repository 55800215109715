var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    staticClass: "objects-table",
    style: "width:" + _vm.width + "px",
    attrs: {
      search: _vm.search,
      dense: "",
      headers: _vm.headers,
      items: _vm.tableData,
      expanded: _vm.expanded,
      "item-key": "id",
      "fixed-header": "",
      "mobile-breakpoint": "300",
      "show-expand": _vm.$vuetify.breakpoint.xs,
      "single-expand": "",
      height: _vm.height,
      "footer-props": { "items-per-page-options": [100, 200, 300, 400] },
      "items-per-page": _vm.defaultItemsPerPage
    },
    on: {
      "update:expanded": function($event) {
        _vm.expanded = $event
      },
      pagination: _vm.paginated
    },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function() {
          return [
            _c(
              "v-toolbar",
              {
                attrs: { extended: _vm.$vuetify.breakpoint.xs },
                scopedSlots: _vm._u(
                  [
                    _vm.$vuetify.breakpoint.xs
                      ? {
                          key: "default",
                          fn: function() {
                            return [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", small: "" },
                                  on: {
                                    click: function($event) {
                                      _vm.showForm = true
                                    }
                                  }
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v(" mdi-plus ")
                                  ]),
                                  _vm._v("\n          Add\n        ")
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      : null,
                    _vm.$vuetify.breakpoint.xs
                      ? {
                          key: "extension",
                          fn: function() {
                            return [
                              _c("v-text-field", {
                                attrs: {
                                  solo: "",
                                  dense: "",
                                  "single-line": "",
                                  "hide-details": "",
                                  clearable: "",
                                  outlined: "",
                                  "prepend-inner-icon": "mdi-magnify",
                                  label: "Search",
                                  color: "grey"
                                },
                                model: {
                                  value: _vm.search,
                                  callback: function($$v) {
                                    _vm.search = $$v
                                  },
                                  expression: "search"
                                }
                              })
                            ]
                          },
                          proxy: true
                        }
                      : null
                  ],
                  null,
                  true
                )
              },
              [
                !_vm.$vuetify.breakpoint.xs
                  ? _c(
                      "v-row",
                      { staticClass: "align-center" },
                      [
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: { color: "primary", small: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("show-form", true)
                                  }
                                }
                              },
                              [
                                _c("v-icon", { attrs: { left: "" } }, [
                                  _vm._v(" mdi-plus ")
                                ]),
                                _vm._v("\n            Add\n          ")
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          {
                            staticClass: "d-flex justify-content-end",
                            attrs: { cols: "8", sm: "6", md: "5", lg: "4" }
                          },
                          [
                            _c("v-text-field", {
                              attrs: {
                                solo: "",
                                dense: "",
                                "single-line": "",
                                "hide-details": "",
                                clearable: "",
                                outlined: "",
                                "prepend-inner-icon": "mdi-magnify",
                                label: "Search",
                                color: "grey"
                              },
                              model: {
                                value: _vm.search,
                                callback: function($$v) {
                                  _vm.search = $$v
                                },
                                expression: "search"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "item.icon",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "", color: "tooltip" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c("v-img", {
                            staticClass: "mx-auto",
                            attrs: {
                              "max-width": "25",
                              src:
                                _vm.publicPath +
                                "assets/" +
                                _vm.objectsNew[item.id].settings.icon
                            }
                          })
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              },
              [
                _vm._v(" "),
                _c("span", [
                  _vm._v("Click here to follow " + _vm._s(item.name))
                ])
              ]
            )
          ]
        }
      },
      {
        key: "item.name",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "", color: "tooltip" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "caption",
                                  attrs: { dense: "", plain: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.toggleFollow(
                                        item.id,
                                        true,
                                        true
                                      )
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    item.name.length > 25
                                      ? item.name.substring(0, 25) + "..."
                                      : item.name
                                  ) +
                                  "\n        "
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              },
              [
                _vm._v(" "),
                _c("span", [
                  _vm._v("Click here to follow " + _vm._s(item.name))
                ])
              ]
            )
          ]
        }
      },
      {
        key: "item.speed",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "v-chip",
              {
                staticClass: "ma-2",
                attrs: {
                  small: "",
                  color: item.speed > 0 ? "success" : "error",
                  "text-color": "white"
                }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(item.speed) +
                    " " +
                    _vm._s(_vm.$t("UNIT_SPEED")) +
                    "\n    "
                )
              ]
            )
          ]
        }
      },
      {
        key: "item.ign",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("v-img", {
              staticClass: "mx-auto",
              attrs: {
                "max-width": "18",
                src:
                  _vm.publicPath +
                  "assets/img/icons/" +
                  (item.ign == "On"
                    ? "ignition-indicator-red.svg"
                    : "ignition-indicator.svg")
              }
            })
          ]
        }
      },
      {
        key: "item.link",
        fn: function(ref) {
          var item = ref.item
          return [_c("TheLinkBar", { attrs: { signal: item.link } })]
        }
      },
      {
        key: "item.visible",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "", color: "tooltip" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    outlined: "",
                                    icon: "",
                                    dense: "",
                                    small: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.toggleVisibility(
                                        item.id,
                                        !item.visible
                                      )
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "v-icon",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.visible,
                                      expression: "item.visible"
                                    }
                                  ],
                                  attrs: {
                                    dense: "",
                                    color: item.visible ? "primary" : ""
                                  }
                                },
                                [_vm._v("mdi-check")]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              },
              [
                _vm._v(" "),
                _c("span", [
                  _vm._v(_vm._s(item.visible ? "Hide" : "Show") + " object")
                ])
              ]
            )
          ]
        }
      },
      {
        key: "item.follow",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "", color: "tooltip" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    outlined: "",
                                    icon: "",
                                    small: "",
                                    dense: "",
                                    plain: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.toggleFollow(
                                        item.id,
                                        !item.follow,
                                        false
                                      )
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "v-icon",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.follow,
                                      expression: "item.follow"
                                    }
                                  ],
                                  attrs: {
                                    dense: "",
                                    color: item.follow ? "primary" : ""
                                  }
                                },
                                [_vm._v("mdi-check")]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              },
              [
                _vm._v(" "),
                _c("span", [
                  _vm._v(
                    _vm._s(item.follow ? "Unfollow" : "Follow") + " object"
                  )
                ])
              ]
            )
          ]
        }
      },
      {
        key: "item.details",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "", color: "tooltip" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    "x-small": "",
                                    icon: "",
                                    dense: "",
                                    plain: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.$router.push({
                                        name: "objects/details",
                                        params: { imei: item.id }
                                      })
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "v-icon",
                                { attrs: { dense: "", color: "" } },
                                [_vm._v("mdi-format-align-justify")]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              },
              [_vm._v(" "), _c("span", [_vm._v("Click for more details")])]
            )
          ]
        }
      },
      {
        key: "item.actions",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "v-menu",
              {
                attrs: { "offset-y": "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    "x-small": "",
                                    icon: "",
                                    dense: "",
                                    plain: ""
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "v-icon",
                                { attrs: { color: "", dense: "" } },
                                [_vm._v("mdi-settings-outline")]
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              },
              [
                _vm._v(" "),
                _c(
                  "v-list",
                  { attrs: { dense: "" } },
                  [
                    _c(
                      "v-list-item",
                      {
                        attrs: { dense: "" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              name: "mobile/history",
                              params: { imei: item.id }
                            })
                          }
                        }
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          [
                            _c("v-icon", { attrs: { dense: "", color: "" } }, [
                              _vm._v("mdi-history")
                            ])
                          ],
                          1
                        ),
                        _c("v-list-item-title", { attrs: { dense: "" } }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("SHOW_HISTORY")) +
                              "\n          "
                          )
                        ])
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-list-item",
                      {
                        attrs: { dense: "" },
                        on: {
                          click: function($event) {
                            return _vm.$router.push({
                              name: "mobile/objects/control",
                              params: { imei: item.id }
                            })
                          }
                        }
                      },
                      [
                        _c(
                          "v-list-item-icon",
                          [
                            _c("v-icon", { attrs: { dense: "", color: "" } }, [
                              _vm._v("mdi-send-outline")
                            ])
                          ],
                          1
                        ),
                        _c("v-list-item-title", { attrs: { dense: "" } }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("SEND_COMMAND")) +
                              "\n          "
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      },
      {
        key: "expanded-item",
        fn: function(ref) {
          var headers = ref.headers
          var item = ref.item
          return [
            _c(
              "td",
              { attrs: { colspan: headers.length } },
              [
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { staticClass: "d-flex align-center" },
                      [
                        _c("v-img", {
                          attrs: {
                            "max-width": "50",
                            src:
                              _vm.publicPath +
                              "assets/" +
                              _vm.getObjectSettingsById(item.id)["icon"]
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { staticClass: "d-flex align-center" },
                      [
                        _c(
                          "v-chip",
                          {
                            staticClass: "ma-2",
                            attrs: {
                              small: "",
                              color: item.speed > 0 ? "success" : "error",
                              "text-color": "white"
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(item.speed) +
                                " " +
                                _vm._s(_vm.$t("UNIT_SPEED")) +
                                "\n          "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { staticClass: "d-flex align-center" },
                      [
                        _c("v-img", {
                          attrs: {
                            "max-width": "25",
                            src:
                              _vm.publicPath +
                              "assets/img/icons/" +
                              (item.ign == "On"
                                ? "ignition-indicator-red.svg"
                                : "ignition-indicator.svg")
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      { staticClass: "d-flex align-center" },
                      [_c("TheLinkBar", { attrs: { signal: item.link } })],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }