import { settingsService } from '@/services/settings'
import Vue from 'vue'
const actions = {

  async fetchLanguageTranslation ({ commit }, lang) {
    return await settingsService
      .getLanguageTranslation(lang)
      .then(function (response) {
        Vue.$log.info('Loaded: Language')
        commit('SET_LANG_SETTINGS', response.data)
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },

  // async fetchLanguageList({ commit }) {
  //   return await settingsService
  //     .getLanguageList()
  //     .then(function (response) {
  //       Vue.$log.info('Loaded: Language')
  //       commit('SET_LANG_LIST', response.data)
  //       return response.data
  //     })
  //     .catch(function (error) {
  //       throw error
  //     })
  // },
  async fetchSiteSettings ({ commit }, cmd) {
    return await settingsService
      .getSiteSettings(cmd)
      .then(function (response) {
        if (cmd === 'load_user_data') {
          Vue.$log.info('Loaded: User')
          commit('SET_USER', response.data)
        } else if (cmd === 'load_server_data') {
          Vue.$log.info('Loaded: Server')
          commit('SET_SERVER', response.data)
        }
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },

  // async fetchCustomFieldSettings ({ commit }, params) {
  //   return await settingsService.getCustomFieldSettings(params)
  //     .then(function (response) {
  //       commit('setCustomField', response.data)
  //       return response.data
  //     })
  //     .catch(function (error) {
  //       throw error
  //     })
  // },

  // async fetchSensorListSettings ({ commit }, params) {
  //   return await settingsService.getSensorListSettings(params)
  //     .then(function (response) {
  //       commit('setSensorList', response.data)
  //       return response.data
  //     })
  //     .catch(function (error) {
  //       throw error
  //     })
  // },

  // async fetchSubaccountSettings ({ commit }) {
  //   return await settingsService.getSubaccountSettings()
  //     .then(function (response) {
  //       commit('setSubaccounts', response.data)
  //       return response.data
  //     })
  //     .catch(function (error) {
  //       throw error
  //     })
  // },

  // async fetchSubaccountListSettings ({ commit }, params) {
  //   return await settingsService.getSubaccountListSettings(params)
  //     .then(function (response) {
  //       commit('setSubaccountList', response.data)
  //       return response.data
  //     })
  //     .catch(function (error) {
  //       throw error
  //     })
  // },

  // async fetchTemplateSettings ({ commit }) {
  //   return await settingsService.getTemplateSettings()
  //     .then(function (response) {
  //       commit('setTemplates', response.data)
  //       return response.data
  //     })
  //     .catch(function (error) {
  //       throw error
  //     })
  // },

  // async fetchTemplateListSettings ({ commit }, params) {
  //   return await settingsService.getTemplateListSettings(params)
  //     .then(function (response) {
  //       commit('setTemplateList', response.data)
  //       return response.data
  //     })
  //     .catch(function (error) {
  //       throw error
  //     })
  // },

  async saveSettings ({ commit }, params) {
    if (process.env.NODE_ENV === 'development') {
      commit('SAVE_MAP_TYPE', params.map)
      commit('SAVE_USER_SETTINGS', params.settings)
      commit('SET_LANGUAGE', params.settings.language)
      return true
    }

    const mapReq = settingsService.saveSettings(params.map)
    const settingsReq = settingsService.saveSettings(params.settings)

    return Promise.all([mapReq, settingsReq])
      .then(([map, settings]) => {
        commit('SAVE_MAP_TYPE', params.map)
        commit('SAVE_USER_SETTINGS', params.settings)
        commit('SET_LANGUAGE', params.settings.language)

        Vue.$log.info('Saved: Settings')
        return !!(map.data.message === 'OK' && settings.data.message === 'OK')
      })
      .catch(error => {
        throw error
      })
  },

  async saveLanguageSettings ({ commit }, lang) {
    if (process.env.NODE_ENV === 'development') {
      commit('SAVE_USER_LANGUAGE', lang)
      return true
    }

    // const mapReq = settingsService.saveSettings(params.map)
    // const settingsReq = settingsService.saveSettings(params.settings)

    // return Promise.all([mapReq, settingsReq])
    //   .then(([map, settings]) => {
    //     commit('SAVE_MAP_TYPE', params.map)
    //     commit('SAVE_USER_SETTINGS', params.settings)

    //     Vue.$log.info('Saved: Settings')
    //     return !!(map.data === 'OK' && settings.data === 'OK')
    //   })
    //   .catch(error => {
    //     throw error
    //   })

    settingsService.saveSettings(params).then(() => {
      return true
    })
      .catch((e) => { throw e })
  },

  async saveUserSettings ({ commit }, params) {
    if (process.env.NODE_ENV === 'development') {
      commit('SAVE_USER_SETTINGS', params.settings)
      return true
    }

    return await settingsService
      .saveSettings(params)
      .then((response) => {
        Vue.$log.debug(response)
        commit('SAVE_USER_SETTINGS', params.settings)
        commit('SET_LANGUAGE', params.settings.language)

        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },

  async fetchFirebaseToken ({ commit }) {
    return await settingsService
      .getFirebaseToken()
      .then(function (response) {
        Vue.$log.info('getFirebaseToken')
        // commit('SET_LANG_SETTINGS', response.data)
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },
  // saveSearchAddressState ({ commit }, data) {
  //   commit('SET_ADDRESS_STATE', data)

  //   return data
  // },

  saveUISettings ({ commit }, data) {
    commit('SET_UI_SETTINGS', data)
    return data
  }
}

export default actions
