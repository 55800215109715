import { mapGetters, mapActions } from 'vuex'

import L, { latLng } from 'leaflet'
import moment from "moment";

import { globals } from "@/plugins/global";

import { mapFunctions } from "@/plugins/map";

export const eventMixin = {
  data: function () {
    return {
      
    }
  },
  methods: {
    ...mapActions({
      setMessage: 'messages/setMessage',
      setProgress: 'setProgress',
      checkSession: 'auth/checkSession',
      searchAddress: 'places/searchAddress',
      saveUISettings: 'settings/saveUISettings',
    }),
    ready() {
        this.map = this.$refs.map.mapObject;
        this.map.zoomControl.setPosition("topright");
        // this.mapLayers.events.markers = L.layerGroup();
        // this.mapLayers.events.markers.addTo(this.map);
        this.mapLayers.events.markers = mapFunctions.createCluster(
            "events",
            this.user.map_is,
            this.config.map_min_zoom,
            this.config.map_max_zoom
        );
        this.mapLayers.events.markers.addTo(this.map);
    },
    loadEventMarkers() {
        if (this.map !== null) {
          this.mapLayers.events.markers.clearLayers();
          this.map.closePopup();
          let bounds = [];
          Object.values(this.currentEvents).forEach((ev, index) => {
            let currentEvent = ev;
            const details = {
              name: currentEvent.name,
              icon: "img/markers/route-event.svg",
              lat: currentEvent.lat,
              lng: currentEvent.lng,
            };
  
            let marker = mapFunctions.generateMarker(details, this.user.map_is);
            let eventDescription = this.generateEventDescription(ev);
  
            marker.bindPopup(eventDescription, {
              offset: [0, -28 * this.user.map_is],
            });
  
            bounds.push([currentEvent.lat, currentEvent.lng]);
            this.mapLayers.events.markers.addLayer(marker);
          });
          this.map.fitBounds(bounds);
        }
      },
    generateEventMarker(ev) {

    },

    generateEventDescription(event) {
      try {
        if (event) {
          const data = {};

          let sensorArray = Object.keys(
            this.objectsNew[event.imei].settings.sensors
          ).map((k) => this.objectsNew[event.imei].settings.sensors[k]);
          sensorArray = globals.sortArrayByElement(sensorArray, "name");
          let sensorString = "";
          sensorArray.forEach((sensor) => {
            if (sensor.popup === "true") {
              let sensorValue = [];
              if (sensor.type === "fuelsumup") {
                sensorValue = globals.getSensorValueFuelLevelSumUp(
                  event.imei,
                  event.params,
                  sensor
                );
              } else {
                sensorValue = globals.getSensorValue(event.params, sensor);
              }

              sensorString +=
                "<tr><td><strong>" +
                sensor.name +
                ":</strong></td><td>" +
                sensorValue.value_full +
                "</td></tr>";
            }
          });

          const position = globals.urlPosition(event.lat, event.lng);

          var tableString =
            "<table><tr><td><strong>" +
            this.$t("OBJECT") +
            ":</strong></td><td>" +
            this.objectsNew[event.imei].settings.name +
            "</td></tr><tr><td><strong>" +
            this.$t("ADDRESS") +
            ":</strong></td><td>" +
            event.location +
            "</td></tr><tr><td><strong>" +
            this.$t("POSITION") +
            ":</strong></td><td>" +
            position +
            "</td></tr><tr><td><strong>" +
            this.$t("ANGLE") +
            ":</strong></td><td>" +
            event.angle +
            " &deg;</td></tr><tr><td><strong>" +
            this.$t("SPEED") +
            ":</strong></td><td>" +
            event.speed +
            " " +
            this.$t("UNIT_SPEED") +
            "</td></tr><tr><td><strong>" +
            this.$t("TIME") +
            ":</strong></td><td>" +
            this.convertUTCToLocalTimezone(event.dt_server) +
            "</td></tr>";
          const odometer = this.getObjectOdometer(event.imei, event.params);

          if (odometer !== -1) {
            tableString +=
              "<tr><td><strong>" +
              this.$t("ODOMETER") +
              ":</strong></td><td>" +
              odometer +
              " " +
              this.$t("UNIT_DISTANCE") +
              "</td></tr>";
          }
          const engineHours = this.getObjectEngineHours(
            event.imei,
            event.params
          );

          if (engineHours !== -1) {
            tableString +=
              "<tr><td><strong>" +
              this.$t("ENGINE_HOURS") +
              ":</strong></td><td>" +
              engineHours +
              "</td></tr>";
          }
          tableString += sensorString += "</table>";

          const div = document.createElement("div");
          div.innerHTML = `<br>${tableString}<br>`;

          const button = document.createElement("button");
          button.className = "v-btn v-btn--outlined theme--dark v-size--default primary--text"
          
          const span = document.createElement("span");
          span.className = "v-btn__content";
          span.innerHTML = "Request Footage";
          
          button.appendChild(span)

          button.onclick = () => {
            this.requestVideo(event.imei, event.dt_server, event.event_id);
          }

          div.appendChild(button);

          return div;
        } else {
        }
      } catch (error) {
        this.handleError(error);
      }
    },

    loadEventDetails(e, index) {
        let marker = this.mapLayers.events.markers.getLayers()[index];
        this.mapLayers.events.markers.zoomToShowLayer(marker, () => {
          marker.openPopup();
        });
      },

    requestVideo(imei, dt_server, event_id=null) {
        console.log(imei, dt_server, event_id)
        this.requestVideoFootage({
          imei: imei,
          dtf: this.convertToISOFormat(dt_server, true),
          event_id: event_id,
        }).then((response) => {
        });
      },

  },
  computed: {
    ...mapGetters({
      checkPrivilege: 'settings/checkPrivilege',
      uiSettings: 'settings/uiSettings',
      objectsNew: 'objects/objectsNew',
      getObjectByName: "objects/getObjectByName",
      server: 'settings/server',
      user: 'settings/user',

      getObjectOdometer: "objects/getObjectOdometer",
      getObjectEngineHours: "objects/getObjectEngineHours",
      // objectsData: 'objects/objectsData',
      // progress: 'progress'
    }),
    
  },
  watch: {
  }
}
