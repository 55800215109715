<template>
  <div class="fill-height">
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="objects"
      item-key="name"
      show-select
      class="elevation-1"
      :search="search"
      dense
      fixed-header
      height="calc(100vh - 172px)"
    >
      <template v-slot:top>
        <v-toolbar>
          <v-row class="align-center">
            <v-col>
              <v-tooltip bottom color="tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="border-1--yellow"
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="openForm"
                  >
                    <v-icon left> mdi-plus </v-icon> Add
                  </v-btn>
                </template>
                <span>Add</span>
              </v-tooltip>
              <v-menu offset-y tile>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="border-1--yellow"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left> mdi-settings-outline </v-icon> Options
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item dense>
                    <v-list-item-title>Clear History</v-list-item-title>
                  </v-list-item>
                  <v-list-item dense>
                    <v-list-item-title>Help</v-list-item-title>
                  </v-list-item>
                  <v-list-item dense>
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
            <v-col
              cols="8"
              sm="6"
              md="5"
              lg="4"
              class="d-flex justify-content-end"
            >
              <v-text-field
                solo
                dense
                single-line
                hide-details
                clearable
                outlined
                prepend-inner-icon="mdi-magnify"
                v-model="search"
                label="Search"
                color="grey"
                class=""
              ></v-text-field
            ></v-col>
          </v-row>
        </v-toolbar>
      </template>

      <template v-slot:item.active="{ item }">
        <v-img
          max-width="18"
          :src="
            `${publicPath}assets/img/images/` +
            (item.active ? 'tick-green.svg' : 'remove-red.svg')
          "
          class="mr-1"
        ></v-img>
      </template>
      <template v-slot:item.subscribed="{ item }">
        <v-tooltip bottom color="tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-img
              v-bind="attrs"
              v-on="on"
              max-width="18"
              :src="
                `${publicPath}assets/img/images/` +
                (item.subscribed ? 'tick-green.svg' : 'remove-red.svg')
              "
              class="mr-1"
            ></v-img>
          </template>
          <span>{{
            item.subscribed ? "Manage Subscription" : "Add subscription?"
          }}</span>
        </v-tooltip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom color="tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-2"
              x-small
              icon
              dense
              plain
              v-bind="attrs"
              v-on="on"
              @click="edit(item.imei)"
            >
              <v-icon dense>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
        <v-tooltip bottom color="tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-2"
              x-small
              icon
              dense
              plain
              v-bind="attrs"
              v-on="on"
              @click="copy(item.imei)"
            >
              <v-icon dense>mdi-content-copy</v-icon>
            </v-btn>
          </template>
          <span>Duplicate</span>
        </v-tooltip>
        <v-tooltip bottom color="tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mr-2"
              x-small
              icon
              dense
              plain
              v-bind="attrs"
              v-on="on"
              @click="clearHistory(item.imei)"
            >
              <v-icon dense>mdi-eraser</v-icon>
            </v-btn>
          </template>
          <span>Clear History</span>
        </v-tooltip>
        <v-tooltip bottom color="tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              x-small
              icon
              dense
              plain
              v-bind="attrs"
              v-on="on"
              @click="remove(item.imei)"
            >
              <v-icon dense>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <ObjectsForm
      :show="showForm"
      :id="id"
      v-on:show-objects-dialog="setShowModal"
    />

    <ConfirmDialog
      :info="confirmDetails"
      :show="showConfirm"
      v-on:show-confirm-dialog="confirm"
    />
  </div>
  <!-- </v-card> -->
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ObjectsForm from '@/components/forms/ObjectsForm.vue'
import ConfirmDialog from '@/components/includes/ConfirmDialog.vue'
export default {
  components: {
    ObjectsForm,
    ConfirmDialog
  },
  props: {},
  data () {
    return {
      showForm: false,
      id: '',
      showConfirm: false,
      confirmDetails: {
        title: '',
        message: '',
        action: {
          yes: '',
          no: ''
        }
      },
      toDelete: '',
      search: '',
      selected: [],
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'IMEI', value: 'imei' },
        { text: 'Active', value: 'active' },
        { text: 'Sub', value: 'subscribed' },
        { text: 'Expires on', value: 'expiry' },
        { text: 'Actions', value: 'actions', sortable: false }
      ]
    }
  },
  computed: {
    ...mapGetters({ }),
    objects: function () {
      const data = []
      try {
        Object.keys(this.objectsNew).forEach((key) => {
          const obj = this.objectsNew[key].settings
          data.push({
            name: obj.name,
            imei: key,
            active: JSON.parse(obj.active),
            subscribed: obj.subscribed,
            expiry:
              JSON.parse(obj.object_expire) === false ? '' : obj.object_expire_dt
          })
        })
      } catch (error) {
        this.handleError(error)
      }

      return data
    }
  },
  watch: {},
  created () {},
  methods: {
    ...mapActions({
      removeObject: 'objects/removeObject',
      updateMapData: 'map/updateMapData'
    }),
    openForm: function () {
      try {
        if (this.checkPrivilege('subuser') && this.checkPrivilege('obj_add')) {
          this.showForm = true
        } else {
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'error',
            title: this.$t('ERROR'),
            text: this.$t('THIS_ACCOUNT_HAS_NO_PRIVILEGES_TO_DO_THAT')
          })
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    setShowModal: function (isShow) {
      this.showForm = isShow
      this.id = ''
    },
    edit (imei) {
      this.id = imei
      this.openForm()
    },
    copy (imei) {},
    clearHistory (imei) {},
    remove (id) {
      try {
        if (
          this.checkPrivilege('viewer') &&
          this.checkPrivilege('subuser') &&
          this.checkPrivilege('obj_edit')
        ) {
          this.toDelete = id
          this.confirmDetails = {
            title: 'Confirm',
            message: this.$t('ARE_YOU_SURE_YOU_WANT_TO_DELETE')
          }
          this.showConfirm = true
        } else {
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'error',
            title: this.$t('ERROR'),
            text: this.$t('THIS_ACCOUNT_HAS_NO_PRIVILEGES_TO_DO_THAT')
          })
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    confirm (val) {
      try {
        this.showConfirm = false
        if (val) {
          const params = {
            cmd: 'delete_object',
            imei: this.toDelete
          }
          this.removeObject(params)
          this.updateMapData()
        }
        this.toDelete = ''
      } catch (error) {
        this.handleError(error)
      }
    }
  }
}
</script>

<style>
</style>
