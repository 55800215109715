var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { class: _vm.contentHeight },
    [
      _c(
        "v-col",
        { staticClass: "d-flex pr-0", attrs: { cols: "12", md: "6" } },
        [
          _c("v-data-table", {
            staticClass: "events-table overflow-y-auto",
            style: "width:" + _vm.windowSize.x + "px",
            attrs: {
              dense: "",
              headers: _vm.headers,
              items: _vm.tableData,
              options: _vm.options,
              "server-items-length": _vm.totalEvents,
              loading: _vm.loading,
              "loading-text": "Loading... Please wait",
              "item-key": "name",
              "fixed-header": "",
              "mobile-breakpoint": "300",
              height: _vm.contentHeight,
              "footer-props": {
                "items-per-page-options": [100, 200, 300, 400]
              },
              "sort-by": _vm.sortBy,
              "sort-desc": _vm.sortDesc,
              "must-sort": "",
              "single-select": ""
            },
            on: {
              "update:options": function($event) {
                _vm.options = $event
              },
              "update:sortBy": function($event) {
                _vm.sortBy = $event
              },
              "update:sort-by": function($event) {
                _vm.sortBy = $event
              },
              "update:sortDesc": function($event) {
                _vm.sortDesc = $event
              },
              "update:sort-desc": function($event) {
                _vm.sortDesc = $event
              },
              "click:row": _vm.selectedRow,
              pagination: _vm.paginated
            },
            scopedSlots: _vm._u([
              {
                key: "top",
                fn: function() {
                  return [
                    _c("v-toolbar", {
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function() {
                            return [
                              _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      color: "grey",
                                      "menu-props": "dark",
                                      "item-color": "#272727",
                                      items: _vm.objectItems,
                                      label: "Filter by Object",
                                      "item-text": "text",
                                      "item-value": "value",
                                      "hide-details": "auto",
                                      required: "",
                                      solo: "",
                                      dense: "",
                                      outlined: "",
                                      clearable: ""
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.getDataFromApi()
                                      }
                                    },
                                    model: {
                                      value: _vm.filter.imei,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filter, "imei", $$v)
                                      },
                                      expression: "filter.imei"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                [
                                  _c("v-select", {
                                    attrs: {
                                      color: "grey",
                                      "menu-props": "dark",
                                      "item-color": "#272727",
                                      items: _vm.eventNames,
                                      label: "Filter by Trigger Name",
                                      "hide-details": "auto",
                                      required: "",
                                      solo: "",
                                      dense: "",
                                      outlined: "",
                                      clearable: ""
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.getDataFromApi()
                                      }
                                    },
                                    model: {
                                      value: _vm.filter.trigger,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filter, "trigger", $$v)
                                      },
                                      expression: "filter.trigger"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { staticClass: "flex-shrink-1" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { plain: "", outlined: "" },
                                      on: {
                                        click: function($event) {
                                          _vm.trigger = ""
                                          _vm.imei = ""
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                Clear Filters\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ])
                    })
                  ]
                },
                proxy: true
              },
              {
                key: "header",
                fn: function() {
                  return [
                    _vm.$vuetify.breakpoint.width >= 300
                      ? _c("thead", [
                          _c("tr", [
                            _c(
                              "th",
                              {
                                staticClass: "text-align-center",
                                attrs: { colspan: "2" }
                              },
                              [_vm._v(_vm._s(_vm.$t("MAP")))]
                            ),
                            _vm._v(" "),
                            _c(
                              "th",
                              {
                                staticClass: "text-align-center",
                                attrs: { colspan: "2" }
                              },
                              [_vm._v("Event Details")]
                            )
                          ])
                        ])
                      : _vm._e()
                  ]
                },
                proxy: true
              },
              {
                key: "header.time",
                fn: function(ref) {
                  return [
                    _vm._v("\n        " + _vm._s(_vm.$t("TIME")) + "\n      ")
                  ]
                }
              },
              {
                key: "item.locate",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "", color: "tooltip" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            "x-small": "",
                                            icon: "",
                                            dense: "",
                                            plain: ""
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.viewOnMap(item.id)
                                            }
                                          }
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c("v-img", {
                                        attrs: {
                                          "max-width": "18",
                                          src:
                                            _vm.publicPath +
                                            "assets/img/icons/" +
                                            (item.locate === true
                                              ? "locate-green.svg"
                                              : "locate.svg")
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _vm._v(" "),
                        _c("span", [_vm._v("Click here to view event on map")])
                      ]
                    )
                  ]
                }
              },
              {
                key: "item.device_name",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "", color: "tooltip" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "caption",
                                          attrs: { dense: "", plain: "" },
                                          on: {
                                            click: function($event) {
                                              return _vm.viewOnMap(item.id)
                                            }
                                          }
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(item.device_name) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _vm._v(" "),
                        _c("span", [_vm._v("Click here to view event on map")])
                      ]
                    )
                  ]
                }
              },
              {
                key: "item.dt_server",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("moment")(
                            [item.dt_server, "YYYY-MM-DD HH:mm:ss"],
                            "D/M/YYYY HH:mm:ss"
                          )
                        ) +
                        "\n      "
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { staticClass: "d-none d-md-flex pl-0", attrs: { md: "6" } },
        [
          _c(
            "v-sheet",
            {
              directives: [
                {
                  name: "resize",
                  rawName: "v-resize",
                  value: _vm.onResize,
                  expression: "onResize"
                }
              ],
              staticClass: "w-100 pl-2",
              staticStyle: { overflow: "auto" },
              attrs: { dark: "" }
            },
            [
              _c(
                "l-map",
                {
                  ref: "map",
                  staticStyle: { height: "100%" },
                  attrs: {
                    options: _vm.mapOptions,
                    maxZoom: _vm.config.map_max_zoom,
                    minZoom: _vm.config.map_min_zoom,
                    center: _vm.defaultCenter,
                    zoom: _vm.defaultZoom
                  },
                  on: { ready: _vm.ready }
                },
                [_c("TileLayers")],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }