import { eventsService } from '@/services/events'
import Vue from 'vue'

const actions = {
  // async fetchEvents ({ commit }) {
  //   return await eventsService
  //     .getEvents()
  //     .then(function (response) {
  //       Vue.$log.debug('fetchEvents', 'success')
  //       commit('SET_EVENTS', response.data)
  //       return response.data
  //     })
  //     .catch(function (error) {
  //       throw error
  //     })
  // },
  async fetchEventsByBatch ({ commit }, params) {
    // if (process.env.NODE_ENV === 'development') {
    //   return {
    //     events: {},
    //     total: 0
    //   }
    // }
    return await eventsService
      .getEventsByBatch(params)
      .then(function (response) {
        Vue.$log.debug('fetchEventsByBatch', 'success')
        if (params.page === 1 && params.rows === 100 && params.sord === '' && params.sidx === 'dt_server' && params.imei === '' && params.event_desc === 'DESC') {
          // console.log('go and set');
          commit('SET_EVENTS', response.data)
        }
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },

  async fetchEvent ({ commit }, params) {
    if (process.env.NODE_ENV === 'development') {
      return {"name":"1231","imei":"123","event_desc":"overspeed 60","dt_server":"2023-05-13 08:51:58","dt_tracker":"1970-01-01 00:00:00","lat":-34.81105,"lng":148.45324,"altitude":13,"angle":0,"speed":90,"params":{"acc":"1","arm":"0","batl":"6","cellid":1773,"gpslev":"7","gsmlev":"4","lac":"0000","mcc":"01F9","mnc":"03","odo":32148},"location":"Hume Hwy, Berremangra NSW 2582, Australia","file_id":null}
    }

    return await eventsService
      .getEvent(params)
      .then(function (response) {
        Vue.$log.debug('fetchEvent', 'success')
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },

  async fetchEventNames ({ commit }) {
    if (process.env.NODE_ENV === 'development') {
      commit('SET_EVENT_NAMES', [
        'Trigger 11',
        'Trigger 11 - test update',
        'Trigger 11 - test update from spa',
        '75',
        '70',
        'Geofence ()'
      ])
      return [
        'Trigger 11',
        'Trigger 11 - test update',
        'Trigger 11 - test update from spa',
        '75',
        '70',
        'Geofence ()'
      ]
    }
    return await eventsService
      .getEventsNames()
      .then(function (response) {
        Vue.$log.debug('fetchEventNames', 'success')

        commit('SET_EVENT_NAMES', response.data)
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },

  async fetchEventTypes ({ commit }) {
    if (process.env.NODE_ENV === 'development') {
      commit('SET_EVENT_TYPES', [
        'Trigger 11',
        'Trigger 11 - test update',
        'Trigger 11 - test update from spa',
        '75',
        '70',
        'Geofence ()'
      ])
      return [
        'Trigger 11',
        'Trigger 11 - test update',
        'Trigger 11 - test update from spa',
        '75',
        '70',
        'Geofence ()'
      ]
    }
    return await eventsService
      .getEventsType()
      .then(function (response) {
        Vue.$log.debug('fetchEventTypes', 'success')

        commit('SET_EVENT_TYPES', response.data)
        return response.data
      })
      .catch(function (error) {
        throw error
      })
  },

}

export default actions
