<template>
  <v-dialog
    persistent
    v-model="showTemplateForm"
    hide-overlay
    no-click-animation
    max-width="370"
    width="auto"
  >
    <v-card>
      <v-toolbar color="" dense elevation="2">
        <v-toolbar-title class="text-subtitle-2">
           {{ $t('COMMAND_PROPERTIES') }} </v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn plain icon x-small @click="closeDialog">
          <v-icon dense>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container fluid>
        <validation-observer ref="observer">
          <v-row>
            <v-col cols="12">
              <validation-provider
                class="w-100"
                v-slot="{ errors }"
                :name="$t('NAME')"
                rules="required"
              >
                <div class="mb-2 d-flex flex-row">
                  <span class="text-body-2 mr-1">{{ $t("NAME") }}</span>

                  <FormTooltip>
                    <template v-slot:tooltip-trigger>
                      <v-btn x-small color="primary lighten-1" text icon>
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:tooltip-content
                      >{{ $t("NAME") }}
                    </template>
                  </FormTooltip>
                </div>
                <v-text-field
                  v-model="fields.name"
                  :label="$t('NAME')"
                  solo
                  color="grey"
                  dense
                  hide-details="auto"
                  outlined
                  required
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12"
              ><v-switch
                color="primary"
                hide-details="auto"
                dense
                label="Show unused protocols"
                v-model="fields.show"
              ></v-switch
            ></v-col>
            <v-col cols="12">
              <validation-provider
                class="w-100"
                v-slot="{ errors }"
                :name="$t('PROTOCOL')"
                rules="required"
              >
                <div class="mb-2 d-flex flex-row">
                  <span class="text-body-2 mr-1">{{ $t("PROTOCOL") }}</span>

                  <FormTooltip>
                    <template v-slot:tooltip-trigger>
                      <v-btn x-small color="primary lighten-1" text icon>
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:tooltip-content
                      >{{ $t("PROTOCOL") }}
                    </template>
                  </FormTooltip>
                </div>
                <v-select
                  menu-props="dark"
                  item-color="#272727"
                  v-model="fields.protocol"
                  :items="protocolItems"
                  :label="$t('PROTOCOL')"
                  solo
                  color="grey"
                  dense
                  hide-details="auto"
                  outlined
                  :error-messages="errors"
                  required
                >
                </v-select>
              </validation-provider>
            </v-col>

            <v-col class="d-flex" cols="12" >
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    rules="required"
                    :name="$t('GATEWAY')"
                  >
                  <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t("GATEWAY") }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("GATEWAY") }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                    <v-select color="grey"
                      menu-props="dark"
                      item-color="#272727"
                      v-model="fields.gateway"
                      :items="['gprs', 'sms']"
                      :label="$t('GATEWAY')"
                      item-text="text"
                      item-value="value"
                      hide-details="auto"
                      :error-messages="errors"
                      required
                      solo
                      dense
                      outlined
                    >
                      <template v-slot:item="{ item }">
                        {{ item.toUpperCase() }}
                      </template>
                      <template v-slot:selection="{ item }">
                        {{ item.toUpperCase() }}
                      </template>
                    </v-select>
                  </validation-provider>
                </v-col>

                <v-col class="d-flex" cols="12" >
                  <validation-provider
                    class="w-100"
                    v-slot="{ errors }"
                    rules="required"
                    :name="$t('TYPE')"
                  >
                  <div class="mb-2 d-flex flex-row">
                      <span class="text-body-2 mr-1">{{ $t("TYPE") }}</span>

                      <FormTooltip>
                        <template v-slot:tooltip-trigger>
                          <v-btn x-small color="primary lighten-1" text icon>
                            <v-icon>mdi-information-outline</v-icon>
                          </v-btn>
                        </template>
                        <template v-slot:tooltip-content
                          >{{ $t("TYPE") }}
                          </template
                        >
                      </FormTooltip>
                    </div>
                    <v-select color="grey"
                      menu-props="dark"
                      item-color="#272727"
                      v-model="fields.type"
                      :items="['ascii', 'hex']"
                      :label="$t('TYPE')"
                      item-text="text"
                      item-value="value"
                      hide-details="auto"
                      :error-messages="errors"
                      required
                      solo
                      dense
                      outlined
                    >
                      <template v-slot:item="{ item }">
                        {{ item.toUpperCase() }}
                      </template>
                      <template v-slot:selection="{ item }">
                        {{ item.toUpperCase() }}
                      </template>
                    </v-select>
                  </validation-provider>
                </v-col>

            <v-col cols="12">
              <validation-provider
                class="w-100"
                v-slot="{ errors }"
                :name="$t('COMMAND')"
                rules="required"
              >
                <div class="mb-2 d-flex flex-row">
                  <span class="text-body-2 mr-1">{{ $t("COMMAND") }}</span>

                  <FormTooltip>
                    <template v-slot:tooltip-trigger>
                      <v-btn x-small color="primary lighten-1" text icon>
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:tooltip-content
                      >{{ $t("COMMAND") }}
                    </template>
                  </FormTooltip>
                </div>
                <v-text-field
                  v-model="fields.command"
                  :label="$t('COMMAND')"
                  solo
                  color="grey"
                  dense
                  hide-details="auto"
                  outlined
                  required
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>

             <v-col cols="12">
             </v-col>
            <v-col cols="12">
               <p class="text-body-1">Variables</p>
               <p class="text-body-2">{{$t('VAR_TEMPLATE_IMEI')}}</p>
            </v-col>
          </v-row>

          <v-row class="mb-3 justify-center flex-column flex-sm-row d-flex">
            <v-btn
              @click="closeDialog"
              color="primary"
              outlined
              class="mr-sm-2 mb-2"
              max-width="50%"
              >Cancel</v-btn
            >
            <v-btn @click="save" color="primary" max-width="50%">Save</v-btn>
          </v-row>
        </validation-observer>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import FormTooltip from '@/components/includes/FormTooltip.vue'
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormTooltip
  },
  props: {
    showTemplateForm: Boolean,
    id: {
      default: null
    }
  },
  data () {
    return {
      fields: {
        name: '',
        show: true,
        protocol: '',
        gateway: 'gprs',
        type: 'ascii',
        command: ''
      }
    }
  },
  created () {
    this.initFields()
  },
  computed: {
    ...mapGetters({
      protocolItems: 'objects/protocolItems',
      objectsNew: 'objects/objectsNew',
      cmdTemplateData: 'cmd/cmdTemplateData'
    })
  },
  methods: {
    ...mapActions({
      saveCmdTemplate: 'cmd/saveCmdTemplate',
      getCmdTemplateUnfilteredData: 'cmd/getCmdTemplateUnfilteredData'
    }),
    closeDialog () {
      this.$refs.observer.reset()

      this.$emit('show-template-dialog', false)
    },
    async save () {
      if (this.checkPrivilege('viewer')) {
        const valid = await this.$refs.observer.validate()

        if (valid) {
          const params = {
            cmd_id: this.id !== null ? this.id : false,
            name: this.fields.name,
            protocol: this.fields.protocol,
            gateway: this.fields.gateway,
            type: this.fields.type,
            cmd_: this.fields.command
          }
          // console.log(params)
          this.saveCmdTemplate(params)
            .then((response) => {
              if (response.message === 'OK') {
                this.$notify({
                    duration: 5000,
                    closeOnClick: false,
                  type: 'success',
                  title: this.$t('INFORMATION'),
                  text: this.$t('CHANGES_SAVED_SUCCESSFULLY')
                })
                if (process.env.NODE_ENV === 'production') {
                  // this.getCmdScheduleData()

                  this.getCmdTemplateUnfilteredData().catch(error => this.handleError(error))
                }
                this.closeDialog()
              }
            })
            .catch((error) => {
              this.handleError(error)
            })
        }
      } else {
        this.$notify({
                    duration: 5000,
                    closeOnClick: false,
          type: 'error',
          title: this.$t('ERROR'),
          text: this.$t('THIS_ACCOUNT_HAS_NO_PRIVILEGES_TO_DO_THAT')
        })
      }
    },
    initFields () {
      this.fields = {
        name: '',
        show: true,
        protocol: '',
        gateway: 'gprs',
        type: 'ascii',
        command: ''
      }
      if (this.id !== null && Object.prototype.hasOwnProperty.call(this.cmdTemplateData, this.id)) {
        // console.log(this.cmdTemplateData, this.id)
        this.fields = {
          name: this.cmdTemplateData[this.id].name,
          show: true,
          protocol: this.cmdTemplateData[this.id].protocol,
          gateway: this.cmdTemplateData[this.id].gateway,
          type: this.cmdTemplateData[this.id].type.toLowerCase(),
          command: this.cmdTemplateData[this.id].cmd
        }
      }
    }
  },
  watch: {
    showTemplateForm: function () {
      this.initFields()
    }
  }
}
</script>

<style>

</style>
