<template>
  <v-data-table
    dense
    :headers="parameterData.headers"
    :items="parameterData.parameters"
    item-key="name"
    class="dashboard-table"
    hide-default-footer dark
    :items-per-page="-1"
  >
    <template v-slot:header.parameter="{}">
      {{ $t("PARAMETER") }}
    </template>
    <template v-slot:header.status="{}">
      {{ $t("STATUS") }}
    </template>
  </v-data-table>
</template>
<script>
export default {
  props: ['parameterData']
}
</script>

<style></style>
