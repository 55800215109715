export const closestAsset = {
  getDistanceFromMarker,
  getDistanceFromZone
}

function getDistanceFromMarker (
  lat,
  lng,
  markerLat,
  markerLng,
  unitDistance
) {
  let distance = 0

  distance = getLengthBetweenCoordinates(lat, lng, markerLat, markerLng)

  distance = convDistanceUnits(distance, 'km', unitDistance)

  distance = distance.toFixed(2)

  return distance
}

function getDistanceFromZone (lat, lng, zones, unitDistance) {
  var inZoneVertices = []
  var distance = 0

  var zoneVertices = zones.split(',')
  inZoneVertices = zoneVertices

  for (let j = 0; j < zoneVertices.length; j += 2) {
    const zoneLat = zoneVertices[j]
    const zoneLng = zoneVertices[j + 1]

    distance = getLengthBetweenCoordinates(lat, lng, zoneLat, zoneLng)
  }

  // prepare polygon array to check if it is in zone
  var polygon = []
  for (let j = 0; j < inZoneVertices.length; j += 2) {
    const zoneLat = parseFloat(inZoneVertices[j])
    const zoneLng = parseFloat(inZoneVertices[j + 1])
    polygon.push({ x: zoneLat, y: zoneLng })
  }

  // if in zone, reset distance
  if (isPointInPolygon(polygon, { x: lat, y: lng }) === true) {
    distance = 0
  }

  distance = convDistanceUnits(distance, 'km', unitDistance)

  distance = distance.toFixed(2)
  return distance
}

function getLengthBetweenCoordinates (lat1, lng1, lat2, lng2) {
  var R = 6371 // Radius of the earth in km
  var dLat = toRad(lat2 - lat1) // deg2rad below
  var dLon = toRad(lng2 - lng1)
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
    Math.cos(toRad(lat2)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c // Distance in km

  return d
}

function toRad (Value) {
  return (Value * Math.PI) / 180
}

function convDistanceUnits (val, from, to) {
  if (from === 'km') {
    if (to === 'mi') {
      val = val * 0.621371
    } else if (to === 'nm') {
      val = val * 0.539957
    }
  } else if (from === 'mi') {
    if (to === 'km') {
      val = val * 1.60934
    } else if (to === 'nm') {
      val = val * 0.868976
    }
  } else if (from === 'nm') {
    if (to === 'km') {
      val = val * 1.852
    } else if (to === 'nm') {
      val = val * 1.15078
    }
  }

  return val
}

function isPointInPolygon (poly, pt) {
  for (var c = false, i = -1, l = poly.length, j = l - 1; ++i < l; j = i) {
    ((poly[i].y <= pt.y && pt.y < poly[j].y) ||
      (poly[j].y <= pt.y && pt.y < poly[i].y)) &&
      pt.x <
      ((poly[j].x - poly[i].x) * (pt.y - poly[i].y)) /
      (poly[j].y - poly[i].y) +
      poly[i].x &&
      (c = !c)
  }
  return c
}
