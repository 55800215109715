import axios from 'axios'
import { globals } from '@/plugins/global'

export const imagesService = {
  getEventVideos,
  filterEventVideos,
  requestVideoFootage,
  getVideo,
  getVideoRequestQueue
}

const requestUrl = process.env.BASE_URL + 'src/assets/json/'


function getEventVideos (params) {
  if (process.env.NODE_ENV === 'development') {
    return axios.get(`${requestUrl}/event_videos.json`)
  }
  return axios.get('/images/data', {params: params})
}

function filterEventVideos (params) {
  // console.log(params)
  if (process.env.NODE_ENV === 'development') {
    return axios.get(`${requestUrl}/event_videos.json`)
  }
  return axios.post('/images/data/search', globals.convertJsonToFormData(params))
}


function requestVideoFootage (params) {
  return axios.get('/images/pick', {params: params})
}


function getVideo (params) {
  return axios.get('/images/web_one', {params: params})
}

function getVideoRequestQueue (params) {
  return axios.get('/images/queue',{params: params})
}