import Vue from 'vue'

const mutations = {
  // SET_MARKERS: (state, objects) => {
  //   const markers = []
  //   Object.keys(objects).forEach(function (key) {
  //     const lat = objects[key].data.lat
  //     const lng = objects[key].data.lng
  //     const marker = latLng(lat, lng)
  //     if (objects[key].visible === true) {
  //       markers.push({
  //         imei: key,
  //         marker: marker
  //       })
  //     }
  //   })
  //   state.markers = markers
  // },
  SET_POLYLINES: (state, data) => {
    const objects = data.objectsNew
    // const objectSettings = data.objectSettings

    const polylines = state.polylines
    state.polylines = []
    Object.keys(objects).forEach(function (key) {
      const lat = objects[key].data.lat
      const lng = objects[key].data.lng
      const tailPoints = objects[key].settings.tail_points
      if (Object.prototype.hasOwnProperty.call(polylines, key) === false) {
        polylines[key] = []
      }

      if (polylines[key].length >= tailPoints) {
        if (!objects[key].follow) {
          polylines[key].pop()
        }
      }
      polylines[key].unshift([lat, lng])
    })
    state.polylines = polylines
  },

  SET_CENTER: (state, data) => (state.center = data),
  SET_VISIBLE_OBJECTS: (state, data) => (state.visible_objects = data),
  SET_BOUNDS: (state, data) => (state.bounds = data),
  // SET_ZOOM: (state, data) => (state.zoom = data),
  SET_FOLLOW: (state, data) => {
    // (state.follow = data)
    state.follow = []
    state.follow = data
    Vue.$log.debug('set follow', state.follow)
  },
  // SET_PLACE_MARKERS: (state, data) => {
  //   state.place.markers = []
  //   state.place.markers = data
  // },
  // SET_PLACE_ROUTES: (state, data) => {
  //   state.place.routes = []
  //   state.place.routes = data
  // },
  // SET_PLACE_ZONES: (state, data) => {
  //   state.place.zones = []
  //   state.place.zones = data
  // },
  SET_MAP_TOGGLES: (state, prop) => {
    state.toggles[prop] = !state.toggles[prop]
  },
  SET_MAP_HISTORY_TOGGLES: (state, prop) => {
    state.toggles.history[prop] = !state.toggles.history[prop]
  },
  SET_RAW_HISTORY: (state, data) => {
    // Vue.$log.debug('set mutation history')
    // state.history = []
    state.raw_history = data
    Vue.$log.debug(state.raw_history)
  },
  SET_HISTORY: (state, data) => {
    // Vue.$log.debug('set mutation history')
    // state.history = []
    state.history = data
    Vue.$log.debug(state.history)
  },
  SET_HISTORY_ROUTE_DATA: (state, data) => {
    // Vue.$log.debug('set mutation SET_HISTORY_ROUTE_DATA')
    // state.history_route_data = []
    state.history_route_data = data
    Vue.$log.debug(state.history_route_data)
  },
  SET_HISTORY_ROUTE_DATA_LAYER: (state, data) => {
    Vue.$log.debug(data.prop)
    // state.history_route_data = []
    state.history_route_data.layers[data.prop] = data.val
    // Vue.$log.debug(state.history_route_data.layers[data.prop])
  },
  SET_HISTORY_ROUTE_DATA_PLAY: (state, data) => {
    // Vue.$log.debug('set mutation SET_HISTORY_ROUTE_DATA')
    // state.history_route_data = []
    state.history_route_data.play[data.prop] = data.val
    // Vue.$log.debug(state.history_route_data.play[data.prop])
  },
  SET_EVENT_ID: (state, id) => {
    state.event_id = id
    Vue.$log.debug(state.event_id)
  },

  SET_PLACE_MARKER_LAYER: (state, data) => {
    // state.place_marker_layer[data.id] = data.marker
  },
  // SET_PLACE_DRAFT: (state, params) => {
  //   const draft = state.place.draft
  //   Object.keys(params).forEach(prop => {
  //     if (prop === 'fields') {
  //       const fields = draft[prop] // fields
  //       Object.keys(params[prop]).forEach((fieldProp) => {
  //         fields[fieldProp] = params[prop][fieldProp]
  //       })
  //       draft[prop] = fields
  //     } else {
  //       draft[prop] = []
  //       draft[prop] = params[prop]
  //     }
  //   })

  //   state.place.draft = []
  //   state.place.draft = draft
  // },
  // CLEAR_PLACE_DRAFT: (state, type) => {
  //   state.place.draft = {
  //     action: '',
  //     type: '',
  //     fields: {
  //       // image: 'pin-1.svg'
  //     },
  //     id: ''
  //   }
  // }
  SET_HISTORY_PROGRESS: (state, progress) => {
    state.history_progress = 0
    state.history_progress = progress
    Vue.$log.debug('SET_HISTORY_PROGRESS', progress, state.history_progress)
    // Vue.$log.debug(state.event_id)
  }
}
export default mutations
