<template>
  <v-dialog
    persistent
    v-model="show"
    hide-overlay
    no-click-animation
    max-width="370"
    width="auto"
  >
    <v-card>
      <v-toolbar dense elevation="0">
        <v-btn plain icon x-small @click="closeDialog(false)">
          <v-icon dark dense>mdi-close</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" outlined @click="saveSched()"> Add Schedule </v-btn>
      </v-toolbar>
      <v-container fluid>
        <validation-observer ref="observer">
          <v-row>
            <!-- var d = {
                mon: document.getElementById('dialog_cmd_schedule_daily_mon').checked,
                mon_time: document.getElementById('dialog_cmd_schedule_daily_mon_time').value,
                tue: document.getElementById('dialog_cmd_schedule_daily_tue').checked,
                tue_time: document.getElementById('dialog_cmd_schedule_daily_tue_time').value,
                wed: document.getElementById('dialog_cmd_schedule_daily_wed').checked,
                wed_time: document.getElementById('dialog_cmd_schedule_daily_wed_time').value,
                thu: document.getElementById('dialog_cmd_schedule_daily_thu').checked,
                thu_time: document.getElementById('dialog_cmd_schedule_daily_thu_time').value,
                fri: document.getElementById('dialog_cmd_schedule_daily_fri').checked,
                fri_time: document.getElementById('dialog_cmd_schedule_daily_fri_time').value,
                sat: document.getElementById('dialog_cmd_schedule_daily_sat').checked,
                sat_time: document.getElementById('dialog_cmd_schedule_daily_sat_time').value,
                sun: document.getElementById('dialog_cmd_schedule_daily_sun').checked,
                sun_time: document.getElementById('dialog_cmd_schedule_daily_sun_time').value
            };
            d = JSON.stringify(d); -->
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items="days"
              item-key="name"
              show-select
              class="elevation-1"
              hide-default-header
              hide-default-footer
              disable-pagination
            >
              <template v-slot:item.time="props">
                <v-edit-dialog large :return-value.sync="time[props.item.day]" @save="days[props.index].time = time[props.item.day]; setSelected(props.item)">
                  {{ time[props.item.day] }}
                  <template v-slot:input>
                    <v-menu
                      ref="menu"
                      v-model="menu[props.item.day]"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="time[props.item.day]"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="time[props.item.day]"
                          label="Picker in menu"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menu[props.item.day]"
                        v-model="time[props.item.day]"
                        full-width
                        @click:minute="$refs.menu.save(time[props.item.day]); menu[props.item.day] = false;"
                      ></v-time-picker>
                    </v-menu>
                  </template>
                </v-edit-dialog>
              </template>
            </v-data-table>
          </v-row>
        </validation-observer>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
// import { ValidationObserver, ValidationProvider } from 'vee-validate'
export default {
  components: {
    // ValidationProvider,
    // ValidationObserver
  },
  data () {
    return {
      selected: [],
      menu: {
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
        sun: false
      },
      time: {
        mon: '00:00',
        tue: '00:00',
        wed: '00:00',
        thu: '00:00',
        fri: '00:00',
        sat: '00:00',
        sun: '00:00'
      },
      headers: [
        {
          text: 'Day Key',
          sortable: false,
          value: 'day',
          class: 'd-none',
          cellClass: 'd-none'
        },
        {
          text: 'Day',
          sortable: false,
          value: 'name'
        },
        { text: 'Time', value: 'time', sortable: false }
      ],
      days: [
        {
          day: 'mon',
          name: 'Monday',
          time: '00:00'
        },
        {
          day: 'tue',
          name: 'Tuesday',
          time: '00:00'
        },
        {
          day: 'wed',
          name: 'Wednesday',
          time: '00:00'
        },
        {
          day: 'thu',
          name: 'Thursday',
          time: '00:00'
        },
        {
          day: 'fri',
          name: 'Friday',
          time: '00:00'
        },
        {
          day: 'sat',
          name: 'Saturday',
          time: '00:00'
        },
        {
          day: 'sun',
          name: 'Sunday',
          time: '00:00'
        }
      ]
    }
  },
  props: {
    show: Boolean,
    selectedSchedules: Array
  },
  methods: {
    async saveSched () {
      // console.log(this.selected, this.days)
      this.$emit('selected-sched', this.selected)
      this.$emit('show-picker-dialog', false)
    },
    closeDialog () {
      this.$emit('show-picker-dialog', false)
    },
    setSelected (schedule) {
      let found = false
      this.selected.forEach((sched, i) => {
        if (sched.day === schedule.day) {
          this.selected[i] = schedule
          found = true
        }
      })

      if (found === false) {
        this.selected.push(schedule)
      }
    }
  },

  watch: {
    show: function () {
      this.selected = this.selectedSchedules

      this.selected.forEach(d => {
        // console.log(d)

        this.time[d.day] = d.time
      })
      // console.log('from picker', this.selected)

      this.days = [
        {
          day: 'mon',
          name: 'Monday',
          time: '00:00'
        },
        {
          day: 'tue',
          name: 'Tuesday',
          time: '00:00'
        },
        {
          day: 'wed',
          name: 'Wednesday',
          time: '00:00'
        },
        {
          day: 'thu',
          name: 'Thursday',
          time: '00:00'
        },
        {
          day: 'fri',
          name: 'Friday',
          time: '00:00'
        },
        {
          day: 'sat',
          name: 'Saturday',
          time: '00:00'
        },
        {
          day: 'sun',
          name: 'Sunday',
          time: '00:00'
        }
      ]
    }
    //   // menu2: function() {
    //   //   if (this.menu2 === true) {
    //   //     this.time = null
    //   //   }
    //   // }
    //   time: {
    //     deep: true,
    //     handler() {

  //     // console.log('time values changed');
  //     }
  //   }
  }
}
</script>

<style></style>
