import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
  // customVariables: ["~/styles/variables.scss"],
  // treeShake: true,
  options: {
    customProperties: true
  },
  breakpoint: {
    mobileBreakpoint: 'sm', // This is equivalent to a value of 960,
    thresholds: {
      xs: 340,
      sm: 540,
      md: 800,
      lg: 1280
    },
    scrollBarWidth: 24
  },
  theme: {
    dark: true,
    options: {
      cspNonce: undefined,
      // customProperties: true,
      minifyTheme: undefined,
      themeCache: undefined
    },
    themes: {
      dark: {
        primary: '#ff5100',
        tooltip: '#1e1e1e'
        // error: '#ff002e',
        // secondary: '#424242',
        // accent: '#FF4081',
        // error: '#FF5252',
        // info: '#2196F3',
        // success: '#4CAF50',
        // warning: '#FB8C00'
      }
    }
  }
}

export default new Vuetify(opts)
