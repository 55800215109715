var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "hide-overlay": "",
        "no-click-animation": "",
        fullscreen: ""
      },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        {},
        [
          _c(
            "v-toolbar",
            { attrs: { dense: "", elevation: "0" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", "x-small": "" },
                  on: {
                    click: function($event) {
                      return _vm.setSelectedObjects(false)
                    }
                  }
                },
                [_c("v-icon", { attrs: { dense: "" } }, [_vm._v("mdi-close")])],
                1
              ),
              _vm._v(" "),
              _c("v-toolbar-title", { staticClass: "text-subtitle-2" }, [
                _vm._v("Select Vehicle(s)")
              ]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-toolbar-items",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.setSelectedObjects(true)
                        }
                      }
                    },
                    [_vm._v("\n          Save selected\n        ")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-container",
            {
              staticStyle: { height: "calc(100vh - 48px)" },
              attrs: { fluid: "" }
            },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-subheader",
                    [
                      _c("v-checkbox", {
                        attrs: {
                          label: _vm.selectAll ? "De-select All" : "Select All"
                        },
                        on: { click: _vm.toggleMassSelect }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-divider"),
                  _vm._v(" "),
                  _c(
                    "v-list-item-group",
                    {
                      attrs: { multiple: "" },
                      model: {
                        value: _vm.selectedObjects,
                        callback: function($$v) {
                          _vm.selectedObjects = $$v
                        },
                        expression: "selectedObjects"
                      }
                    },
                    _vm._l(Object.keys(_vm.objectsNew), function(imei) {
                      return _c("v-list-item", {
                        key: imei,
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var active = ref.active
                                return [
                                  _c(
                                    "v-list-item-action",
                                    [
                                      _c("v-checkbox", {
                                        attrs: { "input-value": active }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-img", {
                                        attrs: {
                                          "min-width": "25",
                                          "max-width": "50",
                                          height: "auto",
                                          src:
                                            _vm.publicPath +
                                            "assets/" +
                                            _vm.objectsNew[imei].settings.icon
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "ml-5" },
                                    [
                                      _c("v-list-item-title", {
                                        domProps: {
                                          textContent: _vm._s(
                                            _vm.objectsNew[imei].settings.name
                                          )
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }