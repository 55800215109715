var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container--fluid lower-content" },
    [
      _c("TheLowerTabs", {
        scopedSlots: _vm._u([
          {
            key: "lower-tab-items",
            fn: function() {
              return [
                _c(
                  "v-tab-item",
                  [
                    _c("TheLowerTabsDashboard", {
                      scopedSlots: _vm._u([
                        {
                          key: "dashboard-table-layout",
                          fn: function() {
                            return [
                              _c("TheLowerTabsDashboardTableLayout", {
                                scopedSlots: _vm._u([
                                  {
                                    key: "dashboard-table-layout",
                                    fn: function() {
                                      return [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              lg: "6",
                                              md: "6",
                                              sm: "12"
                                            }
                                          },
                                          [_vm._v("Active Tracker Name")]
                                        ),
                                        _vm._v(" "),
                                        _c("v-col", {
                                          attrs: { lg: "6", md: "6", sm: "12" }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "active-tracker-table",
                                    fn: function() {
                                      return [
                                        _c("TheParameterTable", {
                                          attrs: {
                                            "parameter-data":
                                              _vm.activeObjectData
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  },
                                  {
                                    key: "active-driver-table",
                                    fn: function() {
                                      return [
                                        _c("TheParameterTable", {
                                          attrs: {
                                            "parameter-data": _vm.parameterData2
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ]
                          },
                          proxy: true
                        }
                      ])
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-tab-item",
                  [
                    _c("TheLowerTabsStreetView", {
                      attrs: { "street-view-data": _vm.streetViewData }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-tab-item",
                  [
                    _c("TheLowerTabsVideo", {
                      attrs: { "video-data": _vm.videoData }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }