<template>
  <div>
  <v-data-table
    :headers="headers"
    :items="filteredTableData"
    item-key="id"
    class="devices-table"
    group-by="group"
    :item-class="selectedRow"
    fixed-header
    dark
    height="100%"
    :items-per-page="-1"
    hide-default-footer
    :expanded.sync="expanded"
    show-expand
  >
    <template v-slot:top>
      <v-toolbar extended>
        <template v-slot:default>
          <!-- <v-btn class="" color="primary" small @click="showForm = true">
            <v-icon left> mdi-plus </v-icon>
            Add
          </v-btn> -->
          <v-btn
            small
            width="100"
            @click="$emit('show-form', true)"
            class="border-1--yellow"
          >
            <v-icon left> mdi-plus </v-icon>
            Add
          </v-btn>
        </template>
        <template v-slot:extension>
          <v-col class="pl-0"
            ><v-text-field
              solo
              dense
              single-line
              hide-details
              clearable
              outlined
              prepend-inner-icon="mdi-magnify"
              v-model="filter1"
              label="Filter"
              color="grey"
              class=""
            ></v-text-field
          ></v-col>
          <v-col class="pr-0"
            ><v-text-field
              solo
              dense
              single-line
              hide-details
              clearable
              outlined
              prepend-inner-icon="mdi-magnify"
              v-model="filter2"
              label="2nd Filter"
              color="grey"
              class=""
            ></v-text-field
          ></v-col>
        </template>
      </v-toolbar>
    </template>
    <template v-slot:header="">
      <thead>
        <tr>
          <th colspan="3" class="text-align-center">{{ $t("MAP") }}</th>
          <th colspan="2" class="text-align-center">Tracker</th>
          <th colspan="6" class="text-align-center hidden-md-and-down">
            Metrics
          </th>
        </tr>
      </thead>
    </template>

    <template v-slot:group.header="{ items, isOpen, toggle }">
      <th colspan="11" class="group-header" @click="toggle">
        {{ getGroupName(items[0].group) }}
      </th>
    </template>

    <template v-slot:header.follow="{}">
      {{ $t("FOLLOW") }}
    </template>
    <template v-slot:header.speed="{}">
      {{ $t("SPEED") }}
    </template>
    <template v-slot:header.odometer="{}">
      {{ $t("ODOMETER") }}
    </template>

    <template v-slot:item.locate="{ item }">
      <!-- {{ item.id }} {{ item.locate }} -->
      <v-tooltip bottom color="tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            dense
            plain
            @click="locateAnObject(item.id, item.locate)"
            v-bind="attrs"
            v-on="on"
            x-small
            icon
          >
            <v-img
              max-width="18"
              :src="
                publicPath +
                'assets/img/icons/' +
                (item.locate === true ? 'locate-green.svg' : 'locate.svg')
              "
              class="mr-1"
            ></v-img>
          </v-btn>
        </template>
        <span>Click here to locate object</span>
      </v-tooltip>
    </template>

    <template v-slot:item.visible="{ item }">
      <v-checkbox
        dense
        v-model="item.visible"
        @change="setObjectVisibility(item.id, item.visible)"
      >
      </v-checkbox>
    </template>

    <template v-slot:item.follow="{ item }">
      <v-checkbox
        dense
        v-model="item.follow"
        @change="followCheckboxChanged(item.id, item.follow)"
      ></v-checkbox>
    </template>

    <template v-slot:item.device_name="{ item }">
      <v-tooltip bottom color="tooltip">
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            class="cursor-pointer"
            @click="handleRowClick(item)"
          >
            {{ item.device_name }}
          </div>
        </template>
        <span>Click here to locate object</span>
      </v-tooltip>
    </template>

    <template v-slot:item.ign="{ item }">
      <v-tooltip bottom color="tooltip">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" @click="handleRowClick(item)">
            <v-img
              max-width="18"
              :src="
                publicPath +
                'assets/img/icons/' +
                (item.ign === 'On'
                  ? 'ignition-indicator-red.svg'
                  : 'ignition-indicator.svg')
              "
              class="mr-1"
            ></v-img>
          </div>
        </template>
        <span>Click here to locate object</span>
      </v-tooltip>
    </template>

    <template v-slot:item.link="{ item }">
      <v-tooltip bottom color="tooltip">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" @click="handleRowClick(item)">
            <TheLinkBar :signal="item.link" />
          </div>
        </template>
        <span>Click here to locate object</span>
      </v-tooltip>
    </template>

    <template v-slot:item.actions="{ item }">
      <!-- <v-menu>
        <template v-slot:activator="{ on, attrs }">
          <v-btn x-small icon dense plain v-bind="attrs" v-on="on">
            <v-icon color="" dense>mdi-settings-outline</v-icon>
          </v-btn>
        </template>
        <v-list> </v-list>
        <v-list dense>
          <v-list-item
            dense
            @click="followCheckboxChanged(item.id, !item.follow)"
          >
            Follow
          </v-list-item>

          <v-list-item dense>
            <router-link
              :to="{ name: 'follow', params: { imei: item.id } }"
              target="_blank"
              >Follow (New Window)</router-link
            >
          </v-list-item>
        </v-list>
      </v-menu> -->
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn x-small icon dense plain v-bind="attrs" v-on="on">
            <v-icon color="" dense>mdi-settings-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense>

          <v-list-item
            dense @click="followCheckboxChanged(item.id, !item.follow)"
          >
            <v-list-item-title dense>
              Follow
            </v-list-item-title>
            </v-list-item >

          <v-list-item
            dense
          >
            <v-list-item-title dense>
              <router-link
              :to="{ name: 'follow', params: { imei: item.id } }"
              target="_blank"
              >Follow (New Window)</router-link
            >
            </v-list-item-title></v-list-item
          >

          <v-list-item
            dense
          >
            <v-list-item-title dense>
              <router-link
              :to="{ name: 'live', params: { imei: item.id } }"

              >Live View</router-link
            >
            </v-list-item-title></v-list-item
          >

          <v-list-item
            dense @click="showObjectControlDialog(item.id)"
          >
            <v-list-item-title dense>
              {{ $t("SEND_COMMAND") }}
            </v-list-item-title>
            </v-list-item >

        </v-list>
      </v-menu>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td colspan="8">
        <div class="d-flex flex-row py-2">
          <div class="d-flex align-center mr-3">
            <div class="">
              <v-img
                max-width="50"
                :src="
                  publicPath +
                  'assets/' +
                  getObjectSettingsById(item.id)['icon']
                "
              ></v-img>
            </div>
          </div>
          <div class="">
            <table>
              <tr>
                <th align="left">Group Name:</th>
                <td>{{ item.group_name }}</td>
              </tr>

              <tr v-if="$vuetify.breakpoint.lgAndDown">
                <th align="left">Ign:</th>
                <td>
                  <v-img
                    max-width="18"
                    :src="
                      publicPath +
                      'assets/img/icons/' +
                      (item.ign == 'On'
                        ? 'ignition-indicator-red.svg'
                        : 'ignition-indicator.svg')
                    "
                  ></v-img>
                </td>
              </tr>

              <tr v-if="$vuetify.breakpoint.lgAndDown">
                <th align="left">Link:</th>
                <td>
                  <TheLinkBar :signal="item.link" />
                </td>
              </tr>
              <tr>
                <th align="left">Sat:</th>
                <td>{{ item.sat }}</td>
              </tr>
              <tr>
                <th align="left">Speed:</th>
                <td>{{ item.speed }}</td>
              </tr>
              <tr>
                <th align="left">Odometer:</th>
                <td>{{ item.odometer }}</td>
              </tr>
            </table>
          </div>
        </div>
      </td>
    </template>
  </v-data-table>
  <ObjectControl
    :show="showObjectControl"
    :id="id"
    v-on:show-object-control-dialog="setShowObjectControl"
  />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TheLinkBar from '@/components/includes/TheLinkBar.vue'
import ObjectControl from '@/components/control/ObjectControl.vue'
import { mapMixin } from '@/mixins/mapMixin'

export default {
  mixins: [mapMixin],
  components: {
    TheLinkBar, ObjectControl
  },
  data: () => ({
    filter1: '',
    filter2: '',
    expanded: [],
    selectedRowId: '',
    showObjectControl: false,
    id: null,
    singleSelect: false,
    selected: [],
    headers: [
      { text: 'Locate', value: 'locate', sortable: false },
      {
        text: 'Visible',
        value: 'visible',
        sortable: false
      },
      {
        text: 'Follow',
        value: 'follow',
        sortable: false
      },
      { text: 'Device', value: 'device_name' },
      {
        text: 'Group ID',
        value: 'group',
        class: 'hidden-lg-and-down',
        cellClass: 'hidden-lg-and-down',
        sortable: false
      },
      {
        text: 'Group Name',
        value: 'group_name',
        class: 'hidden-lg-and-down',
        cellClass: 'hidden-lg-and-down',
        sortable: false
      },
      {
        text: 'Ign',
        value: 'ign',
        class: 'hidden-lg-and-down',
        cellClass: 'hidden-lg-and-down',
        sortable: false
      },
      {
        text: 'Link',
        value: 'link',
        sortable: false,
        class: 'hidden-lg-and-down',
        cellClass: 'hidden-lg-and-down'
      },
      // {
      //   text: 'Sat',
      //   value: 'sat',
      //   class: 'hidden',
      //   cellClass: 'hidden'
      // },
      // {
      //   text: 'Speed',
      //   value: 'speed',
      //   class: 'hidden',
      //   cellClass: 'hidden'
      // },
      // {
      //   text: 'Odometer',
      //   value: 'odometer',
      //   class: 'hidden',
      //   cellClass: 'hidden'
      // },
      { text: '', value: 'actions', sortable: false },

      { text: '', value: 'data-table-expand', sortable: false }
    ]
  }),
  computed: {
    ...mapGetters({
      getGroupName: 'groups/getGroupName',
      getObjectSettingsById: 'objects/getObjectSettingsById'
    }),
    filteredTableData: function () {
      let data = this.tableData
      try {
        if (this.filter1 || this.filter2) {
          data = this.tableData.filter((data) => {
            if (
              (this.filter1 &&
                data.device_name
                  .toLowerCase()
                  .includes(this.filter1.toLowerCase())) ||
              (this.filter1 &&
                data.group_name
                  .toLowerCase()
                  .includes(this.filter1.toLowerCase())) ||
              (this.filter1 && data.sat.toString().includes(this.filter1)) ||
              (this.filter1 && data.speed.toString().includes(this.filter1)) ||
              (this.filter1 &&
                data.odometer.toString().includes(this.filter1)) ||
              (this.filter2 &&
                data.device_name
                  .toLowerCase()
                  .includes(this.filter2.toLowerCase())) ||
              (this.filter2 &&
                data.group_name
                  .toLowerCase()
                  .includes(this.filter2.toLowerCase())) ||
              (this.filter2 && data.sat.toString().includes(this.filter2)) ||
              (this.filter2 && data.speed.toString().includes(this.filter2)) ||
              (this.filter2 && data.odometer.toString().includes(this.filter2))
            ) {
              return true
            }
          })
        }
      } catch (error) {
        this.handleError(error)
      }

      return data
    }
  },
  methods: {
    selectedRow (item) {
      return this.selectedRowId && item.id === this.selectedRowId
        ? 'active-row'
        : !this.selectedRowId && item.id === this.selectedObject
          ? 'active-row'
          : ''
    },
    filterDevices (value, filter2, item) {
      this.$log.debug('custom filter', filter2)
    },
    setShowObjectControl: function (isShow) {
      this.showObjectControl = false
      this.id = null
    },
    showObjectControlDialog (id) {
      if (this.checkPrivilege('viewer')) {
        this.showObjectControl = true
        this.id = id
      } else {
        this.$notify({
                    duration: 5000,
                    closeOnClick: false,
          type: 'error',
          title: this.$t('ERROR'),
          text: this.$t('THIS_ACCOUNT_HAS_NO_PRIVILEGES_TO_DO_THAT')
        })
      }
    }
  }
}
</script>

<style></style>
