var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    staticClass: "tracking-table",
    attrs: {
      headers: _vm.headers,
      items: _vm.tableData,
      "item-key": "id",
      "group-by": "group",
      "item-class": _vm.selectedRow,
      "fixed-header": "",
      "items-per-page": -1,
      "hide-default-footer": "",
      search: _vm.search,
      height: "100%",
      expanded: _vm.expanded,
      "show-expand": ""
    },
    on: {
      "update:expanded": function($event) {
        _vm.expanded = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function() {
          return [
            _c(
              "v-toolbar",
              [
                _c(
                  "v-row",
                  { staticClass: "align-center" },
                  [
                    _c(
                      "v-col",
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "border-1--yellow",
                            attrs: { small: "" },
                            on: {
                              click: function($event) {
                                return _vm.$emit("show-form", true)
                              }
                            }
                          },
                          [
                            _c("v-icon", { attrs: { left: "" } }, [
                              _vm._v(" mdi-message ")
                            ]),
                            _vm._v("\n            Send Message\n          ")
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-col",
                      {
                        staticClass: "d-flex justify-content-end",
                        attrs: { cols: "8", md: "7" }
                      },
                      [
                        _c("v-text-field", {
                          attrs: {
                            solo: "",
                            dense: "",
                            "single-line": "",
                            "hide-details": "",
                            clearable: "",
                            outlined: "",
                            "prepend-inner-icon": "mdi-magnify",
                            label: "Search",
                            color: "grey"
                          },
                          model: {
                            value: _vm.search,
                            callback: function($$v) {
                              _vm.search = $$v
                            },
                            expression: "search"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "header",
        fn: function() {
          return [
            _c("thead", [
              _c("tr", [
                _c(
                  "th",
                  { staticClass: "text-align-center", attrs: { colspan: "2" } },
                  [_vm._v(_vm._s(_vm.$t("MAP")))]
                ),
                _vm._v(" "),
                _c(
                  "th",
                  { staticClass: "text-align-center", attrs: { colspan: "3" } },
                  [_vm._v("Tracking Pair")]
                ),
                _vm._v(" "),
                _c(
                  "th",
                  {
                    staticClass: "text-align-center hidden-md-and-down",
                    attrs: { colspan: "4" }
                  },
                  [_vm._v("\n          Metrics\n        ")]
                )
              ])
            ])
          ]
        },
        proxy: true
      },
      {
        key: "group.header",
        fn: function(ref) {
          var items = ref.items
          var isOpen = ref.isOpen
          var toggle = ref.toggle
          return [
            _c(
              "th",
              {
                staticClass: "group-header",
                attrs: { colspan: "9" },
                on: { click: toggle }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.getGroupName(items[0].group)) +
                    "\n    "
                )
              ]
            )
          ]
        }
      },
      {
        key: "header.follow",
        fn: function(ref) {
          return [_vm._v("\n    " + _vm._s(_vm.$t("FOLLOW")) + "\n  ")]
        }
      },
      {
        key: "item.locate",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "", color: "tooltip" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    dense: "",
                                    plain: "",
                                    "x-small": "",
                                    icon: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.locateAnObject(
                                        item.id,
                                        item.locate
                                      )
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("v-img", {
                                staticClass: "mr-1",
                                attrs: {
                                  "max-width": "18",
                                  src:
                                    _vm.publicPath +
                                    "assets/img/icons/" +
                                    (item.locate === true
                                      ? "locate-green.svg"
                                      : "locate.svg")
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              },
              [_vm._v(" "), _c("span", [_vm._v("Click here to locate object")])]
            )
          ]
        }
      },
      {
        key: "item.follow",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("v-checkbox", {
              attrs: { dense: "" },
              on: {
                change: function($event) {
                  return _vm.followCheckboxChanged(item.id, item.follow)
                }
              },
              model: {
                value: item.follow,
                callback: function($$v) {
                  _vm.$set(item, "follow", $$v)
                },
                expression: "item.follow"
              }
            })
          ]
        }
      },
      {
        key: "item.device_name",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "", color: "tooltip" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "div",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "cursor-pointer",
                                  on: {
                                    click: function($event) {
                                      _vm.handleRowClick(item)
                                      _vm.subscribe(item.id)
                                    }
                                  }
                                },
                                "div",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(item.device_name) +
                                  "\n        "
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              },
              [_vm._v(" "), _c("span", [_vm._v("Click here to locate object")])]
            )
          ]
        }
      },
      {
        key: "item.driver_name",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "", color: "tooltip" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "div",
                            _vm._g(
                              _vm._b(
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.handleRowClick(item)
                                    }
                                  }
                                },
                                "div",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(item.driver_name) +
                                  "\n        "
                              )
                            ]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              },
              [_vm._v(" "), _c("span", [_vm._v("Click here to locate object")])]
            )
          ]
        }
      },
      {
        key: "item.ign",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "", color: "tooltip" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "div",
                            _vm._g(
                              _vm._b(
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.handleRowClick(item)
                                    }
                                  }
                                },
                                "div",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("v-img", {
                                staticClass: "mr-1",
                                attrs: {
                                  "max-width": "18",
                                  src:
                                    _vm.publicPath +
                                    "assets/img/icons/" +
                                    (item.ign === "On"
                                      ? "ignition-indicator-red.svg"
                                      : "ignition-indicator.svg")
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              },
              [_vm._v(" "), _c("span", [_vm._v("Click here to locate object")])]
            )
          ]
        }
      },
      {
        key: "item.link",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "v-tooltip",
              {
                attrs: { bottom: "", color: "tooltip" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "div",
                            _vm._g(
                              _vm._b(
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.handleRowClick(item)
                                    }
                                  }
                                },
                                "div",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("TheLinkBar", { attrs: { signal: item.link } })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              },
              [_vm._v(" "), _c("span", [_vm._v("Click here to locate object")])]
            )
          ]
        }
      },
      {
        key: "expanded-item",
        fn: function(ref) {
          var headers = ref.headers
          var item = ref.item
          return [
            _c("td", { attrs: { colspan: "8" } }, [
              _c("div", { staticClass: "d-flex flex-row py-2" }, [
                _c("div", { staticClass: " d-flex align-center mr-3" }, [
                  _c(
                    "div",
                    {},
                    [
                      _c("v-img", {
                        attrs: {
                          "max-width": "50",
                          src:
                            _vm.publicPath +
                            "assets/" +
                            _vm.getObjectSettingsById(item.id)["icon"]
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", {}, [
                  _c("table", {}, [
                    _c("tr", [
                      _c("th", { attrs: { align: "left" } }, [_vm._v("Sat:")]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(item.sat))])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", { attrs: { align: "left" } }, [
                        _vm._v("Trip Hrs:")
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(item.trip_hrs))])
                    ])
                  ])
                ])
              ])
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }