var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tabs",
        [
          _c("v-tab", { attrs: { exact: "", to: "/mobile/events" } }, [
            _vm._v("Events")
          ]),
          _vm._v(" "),
          _c("v-tab", { attrs: { exact: "", to: "/mobile/events/video" } }, [
            _vm._v("Video")
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }