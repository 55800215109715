<template>
  <div class="container--fluid lower-content">
    <!--

        <PersonForm :show="showForm" v-on:show-person-dialog="setShowModal" /> -->
        <TheLowerTabs>
          <template v-slot:lower-tab-items>
            <v-tab-item>
              <TheLowerTabsDashboard>
                <template v-slot:dashboard-table-layout>
                  <TheLowerTabsDashboardTableLayout>
                    <template v-slot:dashboard-table-header>
                      <v-col lg="6" md="6" sm="12">Active Tracker Name</v-col>
                      <v-col lg="6" md="6" sm="12"></v-col>
                    </template>
                    <template v-slot:active-tracker-table>
                      <!-- pass table data in parameter table -->
                      <TheParameterTable
                        v-bind:parameter-data="parameterData"
                      ></TheParameterTable>
                    </template>
                    <template v-slot:active-driver-table>
                      <TheParameterTable
                        v-bind:parameter-data="parameterData2"
                      ></TheParameterTable>
                    </template>
                  </TheLowerTabsDashboardTableLayout>
                </template>
              </TheLowerTabsDashboard>
            </v-tab-item>
            <v-tab-item>
              <TheLowerTabsStreetView
                v-bind:street-view-data="streetViewData"
              ></TheLowerTabsStreetView>
            </v-tab-item>
            <v-tab-item>
              <TheLowerTabsVideo v-bind:video-data="videoData"></TheLowerTabsVideo>
            </v-tab-item>
          </template>
        </TheLowerTabs>

  </div>
</template>

<script>
import TheLowerTabs from '@/layout/TheLowerTabs.vue'
import TheLowerTabsDashboard from '@/layout/TheLowerTabsDashboard.vue'
import TheLowerTabsDashboardTableLayout from '@/layout/TheLowerTabsDashboardTableLayout.vue'
import TheLowerTabsVideo from '@/layout/TheLowerTabsVideo.vue'
import TheLowerTabsStreetView from '@/layout/TheLowerTabsStreetView.vue'
import TheParameterTable from '@/layout/TheParameterTable.vue'

export default {
  components: {
    TheLowerTabs,
    TheLowerTabsDashboard,
    TheLowerTabsDashboardTableLayout,
    TheParameterTable,
    TheLowerTabsVideo,
    TheLowerTabsStreetView
  },
  data () {
    return {
      showForm: false,
      parameterData: {
        singleSelect: false,
        selected: [],
        headers: [
          { text: '', value: 'icon', sortable: false },
          { text: 'Parameter', value: 'parameter', sortable: false },
          { text: 'Status', value: 'status', sortable: false }
        ],
        parameters: [
          {
            locate: '',
            parameter: 'SOS, Help!',
            status: 'Help'
          },
          {
            locate: '',
            parameter: 'Min Rest Requirement',
            status: '60 min'
          },
          {
            locate: '',
            parameter: 'Driver Rest Taken',
            status: '75 min'
          },
          {
            locate: '',
            parameter: 'Driver Breaks Completed',
            status: '3'
          }
        ]
      },

      parameterData2: {
        singleSelect: false,
        selected: [],
        headers: [
          { text: '', value: 'icon', sortable: false },
          { text: 'Parameter', value: 'parameter', sortable: false },
          { text: 'Status', value: 'status', sortable: false }
        ],
        parameters: [
          {
            locate: '',
            parameter: 'License Types',
            status: ''
          },
          {
            locate: '',
            parameter: 'Fatigue Management Training',
            status: ''
          },
          {
            locate: '',
            parameter: 'Something else',
            status: ''
          },
          {
            locate: '',
            parameter: '',
            status: ''
          }
        ]
      },
      streetViewData: 'Personnel Street View Data',
      videoData: 'Personnel Video Data'
    }
  },
  methods: {
    setShowModal: function (isShow) {
      this.showForm = isShow
    }
  }
}
</script>

<style></style>
