var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "hide-overlay": "",
        "no-click-animation": "",
        "max-width": "370",
        width: "auto"
      },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-toolbar",
            { attrs: { color: "", dense: "", elevation: "0" } },
            [
              _c("v-toolbar-title", { staticClass: "text-subtitle-2" }, [
                _vm._v(_vm._s(_vm.$t("MARKER_PROPERTIES")))
              ]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { plain: "", icon: "", "x-small": "" },
                  on: { click: _vm.closeDialog }
                },
                [_c("v-icon", { attrs: { dense: "" } }, [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "validation-observer",
                { ref: "observer" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("validation-provider", {
                            attrs: { rules: "requiredPlaceName" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "mb-2 d-flex flex-row" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-body-2 mr-1" },
                                          [_vm._v(_vm._s(_vm.$t("NAME")))]
                                        ),
                                        _vm._v(" "),
                                        _c("FormTooltip", {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "tooltip-trigger",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          "x-small": "",
                                                          color:
                                                            "primary lighten-1",
                                                          text: "",
                                                          icon: ""
                                                        }
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "mdi-information-outline"
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              },
                                              {
                                                key: "tooltip-content",
                                                fn: function() {
                                                  return [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("NAME")) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-text-field", {
                                      attrs: {
                                        "error-messages": errors,
                                        label: _vm.$t("NAME"),
                                        color: "grey",
                                        "hide-details": "auto",
                                        solo: "",
                                        dense: "",
                                        outlined: "",
                                        required: ""
                                      },
                                      model: {
                                        value: _vm.fields.name,
                                        callback: function($$v) {
                                          _vm.$set(_vm.fields, "name", $$v)
                                        },
                                        expression: "fields.name"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("validation-provider", {
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "mb-2 d-flex flex-row" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-body-2 mr-1" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t("DESCRIPTION"))
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("FormTooltip", {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "tooltip-trigger",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          "x-small": "",
                                                          color:
                                                            "primary lighten-1",
                                                          text: "",
                                                          icon: ""
                                                        }
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "mdi-information-outline"
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              },
                                              {
                                                key: "tooltip-content",
                                                fn: function() {
                                                  return [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("DESCRIPTION")
                                                      ) + "\n                  "
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-textarea", {
                                      attrs: {
                                        label: _vm.$t("DESCRIPTION"),
                                        "error-messages": errors,
                                        required: "",
                                        rows: "3",
                                        color: "grey",
                                        "hide-details": "auto",
                                        solo: "",
                                        dense: "",
                                        outlined: ""
                                      },
                                      model: {
                                        value: _vm.fields.description,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.fields,
                                            "description",
                                            $$v
                                          )
                                        },
                                        expression: "fields.description"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("validation-provider", {
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "mb-2 d-flex flex-row" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-body-2 mr-1" },
                                          [_vm._v(_vm._s(_vm.$t("GROUP")))]
                                        ),
                                        _vm._v(" "),
                                        _c("FormTooltip", {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "tooltip-trigger",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          "x-small": "",
                                                          color:
                                                            "primary lighten-1",
                                                          text: "",
                                                          icon: ""
                                                        }
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "mdi-information-outline"
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              },
                                              {
                                                key: "tooltip-content",
                                                fn: function() {
                                                  return [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("GROUP")) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-autocomplete", {
                                      attrs: {
                                        "menu-props": "dark",
                                        "item-color": "#272727",
                                        required: "",
                                        label: _vm.$t("GROUP"),
                                        items: _vm.placeGroupItems,
                                        "item-text": "text",
                                        "item-value": "value",
                                        solo: "",
                                        color: "grey",
                                        dense: "",
                                        "hide-details": "auto",
                                        outlined: "",
                                        "error-messages": errors
                                      },
                                      model: {
                                        value: _vm.fields.group,
                                        callback: function($$v) {
                                          _vm.$set(_vm.fields, "group", $$v)
                                        },
                                        expression: "fields.group"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              color: "primary",
                              "hide-details": "auto",
                              label: _vm.$t("MARKER_VISIBLE"),
                              required: ""
                            },
                            model: {
                              value: _vm.fields.visible,
                              callback: function($$v) {
                                _vm.$set(_vm.fields, "visible", $$v)
                              },
                              expression: "fields.visible"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "2" } },
                        [
                          _c("v-img", {
                            attrs: {
                              width: "32",
                              height: "32",
                              src:
                                (this.placeDraft.action === "edit"
                                  ? _vm.publicPath + "assets/"
                                  : _vm.publicPath +
                                    "assets/img/markers/places/") +
                                _vm.fields.image
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "10" } },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: { "offset-y": "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                color: "#5A5A5A",
                                                small: ""
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n                  Choose marker\n                "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-sheet",
                                {
                                  attrs: {
                                    dark: "",
                                    "max-width": "300px",
                                    id: "scroll-marker-images"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "\n                    d-flex\n                    flex-row flex-wrap\n                    justify-center\n                    marker-images-container\n                  "
                                    },
                                    _vm._l(
                                      _vm.filesPlaceMarkers.markers,
                                      function(m, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            class:
                                              _vm.publicPath +
                                                "assets/" +
                                                _vm.fields.image ==
                                              _vm.publicPath +
                                                "assets/img/markers/places/" +
                                                m
                                                ? "selected"
                                                : ""
                                          },
                                          [
                                            _c("v-img", {
                                              staticClass: "mx-auto",
                                              attrs: {
                                                width: "32",
                                                height: "32",
                                                src:
                                                  _vm.publicPath +
                                                  "assets/img/markers/places/" +
                                                  m
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.selectPlaceMarker(
                                                    m
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {},
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("validation-provider", {
                            ref: "latitude",
                            attrs: {
                              rules: "requiredPlaceMarkerPoints",
                              name: "latitude"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var validate = ref.validate
                                  var errors = ref.errors
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "mb-2 d-flex flex-row" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-body-2 mr-1" },
                                          [_vm._v(_vm._s(_vm.$t("LATITUDE")))]
                                        ),
                                        _vm._v(" "),
                                        _c("FormTooltip", {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "tooltip-trigger",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          "x-small": "",
                                                          color:
                                                            "primary lighten-1",
                                                          text: "",
                                                          icon: ""
                                                        }
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "mdi-information-outline"
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              },
                                              {
                                                key: "tooltip-content",
                                                fn: function() {
                                                  return [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("LATITUDE")
                                                      ) + "\n                  "
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-text-field", {
                                      attrs: {
                                        solo: "",
                                        dense: "",
                                        outlined: "",
                                        required: "",
                                        readonly: "",
                                        value: _vm.placeDraft.fields.hasOwnProperty(
                                          "lat"
                                        )
                                          ? _vm.placeDraft.fields.lat.toFixed(6)
                                          : "",
                                        color: "#3C3C3C",
                                        label: _vm.$t("LATITUDE"),
                                        error: errors.length >= 1
                                      },
                                      on: { change: validate }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { cols: "6" } },
                        [
                          _c("validation-provider", {
                            ref: "longitude",
                            attrs: {
                              rules: "requiredPlaceMarkerPoints",
                              name: "longitude"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var validate = ref.validate
                                  var errors = ref.errors
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "mb-2 d-flex flex-row" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-body-2 mr-1" },
                                          [_vm._v(_vm._s(_vm.$t("LONGITUDE")))]
                                        ),
                                        _vm._v(" "),
                                        _c("FormTooltip", {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "tooltip-trigger",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          "x-small": "",
                                                          color:
                                                            "primary lighten-1",
                                                          text: "",
                                                          icon: ""
                                                        }
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "mdi-information-outline"
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              },
                                              {
                                                key: "tooltip-content",
                                                fn: function() {
                                                  return [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("LONGITUDE")
                                                      ) + "\n                  "
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-text-field", {
                                      attrs: {
                                        solo: "",
                                        dense: "",
                                        outlined: "",
                                        required: "",
                                        readonly: "",
                                        value: _vm.placeDraft.fields.hasOwnProperty(
                                          "lng"
                                        )
                                          ? _vm.placeDraft.fields.lng.toFixed(6)
                                          : "",
                                        color: "#3C3C3C",
                                        label: _vm.$t("LONGITUDE"),
                                        error: errors.length >= 1
                                      },
                                      on: { change: validate }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "mb-3 justify-center flex-column flex-sm-row d-flex"
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-sm-2 mb-2",
                          attrs: {
                            color: "primary",
                            outlined: "",
                            "max-width": "50%"
                          },
                          on: { click: _vm.closeDialog }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", "max-width": "50%" },
                          on: { click: _vm.save }
                        },
                        [_vm._v("Save")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }