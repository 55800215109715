import Vue from 'vue'

const actions = {
  updateObjectPolylines ({ commit, rootState, rootGetters }) {
    const objectsNew = rootGetters['objects/objectsNew']
    commit('SET_POLYLINES', { objectsNew: objectsNew })
  },
  updateMapCenter ({ commit, rootState, rootGetters }, center = null) {
    if (center !== null) {
      commit('SET_CENTER', center)
    }
  },
  updateMapBounds ({ commit, rootState, rootGetters }, bounds) {
    commit('SET_BOUNDS', bounds)
  },

  updateMapFollow ({ commit, rootState, rootGetters }, follow) {
    commit('SET_FOLLOW', follow)
  },

  updateMapVisibleObjects ({ commit, rootState, rootGetters }, follow) {
    commit('SET_VISIBLE_OBJECTS', follow)
  },

  updateMapToggles ({ commit, rootState, rootGetters }, prop) {
    commit('SET_MAP_TOGGLES', prop)
  },

  updateMapHistoryToggles ({ commit, rootState, rootGetters }, data) {
    commit('SET_MAP_HISTORY_TOGGLES', data)
  },

  setRawHistory ({ commit }, data) {
    Vue.$log.debug('action', 'setHistory')
    commit('SET_RAW_HISTORY', data)
  },

  setHistory ({ commit }, data) {
    Vue.$log.debug('action', 'setHistory')
    commit('SET_HISTORY', data)

    if (data === null) {
      commit('SET_HISTORY_ROUTE_DATA', data)
    }
  },
  setHistoryRouteData ({ commit }, data) {
    Vue.$log.debug('action', 'setHistoryRouteData')
    commit('SET_HISTORY_ROUTE_DATA', data)
  },
  setHistoryRouteDataLayer ({ commit }, data) {
    // Vue.$log.debug('action', 'setHistory')
    commit('SET_HISTORY_ROUTE_DATA_LAYER', data)
  },
  setHistoryRouteDataPlay ({ commit }, data) {
    // Vue.$log.debug('action', 'setHistory')
    commit('SET_HISTORY_ROUTE_DATA_PLAY', data)
  },

  setPlaceMarkerLayer ({ commit }, data) {
    commit('SET_PLACE_MARKER_LAYER', data)
  },

  // clearHistory ({ commit }) {
  //   Vue.$log.debug('action', 'clearHistory')
  //   commit('SET_HISTORY', [])
  // },

  updateEventId ({ commit }, id) {
    Vue.$log.debug('action', 'updateEventId')
    commit('SET_EVENT_ID', id)
  },
  setHistoryProgress ({ commit }, data) {
    commit('SET_HISTORY_PROGRESS', data)
  }

}

export default actions
