var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tabs",
    {
      staticClass: "quaternary-tabs lower-tabs",
      attrs: {
        "slider-color": "#FF5100",
        "background-color": "#3c3c3c",
        "show-arrows": "",
        height: "30px",
        dark: ""
      }
    },
    [
      _c(
        "v-tab",
        { attrs: { dark: "" } },
        [
          _c("v-img", {
            staticClass: "mr-1",
            attrs: {
              "max-width": "13",
              src: _vm.publicPath + "assets/img/icons/tabs/dashboard.svg"
            }
          }),
          _vm._v("\n    Dashboard\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-tab",
        [
          _c("v-img", {
            staticClass: "mr-1",
            attrs: {
              "max-width": "12",
              "max-height": "17",
              src: _vm.publicPath + "assets/img/icons/tabs/street-view.svg"
            }
          }),
          _vm._v("\n    " + _vm._s(_vm.$t("STREET_VIEW")) + "\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-tab",
        [
          _c("v-img", {
            staticClass: "mr-1",
            attrs: {
              "max-width": "17",
              src: _vm.publicPath + "assets/img/icons/tabs/video.svg"
            }
          }),
          _vm._v("\n    Video\n  ")
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("lower-tab-items")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }