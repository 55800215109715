<template>
  <div class="tab-content">
    <DevicesUpperSection
      v-on:selected-object="getSelectedObject"
      v-if="selectedObject || defaultObjectId"
      :selected-object="selectedObject ? selectedObject : defaultObjectId"
    />
    <DevicesLowerSection
      v-if="selectedObject || defaultObjectId"
      :selected-object="selectedObject ? selectedObject : defaultObjectId"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DevicesUpperSection from '@/components/devices/DevicesUpperSection.vue'
import DevicesLowerSection from '@/components/devices/DevicesLowerSection.vue'

export default {
  components: {
    DevicesUpperSection,
    DevicesLowerSection
  },
  data: function () {
    return {
      selectedObject: ''
    }
  },
  computed: {
    ...mapGetters({ getGroupedObjects: 'objects/getGroupedObjects' }),
    defaultObjectId: function () {
      try {
        const groupedObj = this.getGroupedObjects
        if (Object.keys(groupedObj).length >= 1) {
          const key = Object.keys(groupedObj)[0]
          return groupedObj[key][0].object_id
        }
      } catch (error) {
        this.handleError(error)
      }

      return null
    }
  },
  methods: {
    ...mapActions(['fetchGroupSettings', 'fetchObjectData']),
    getSelectedObject: function (id) {
      this.selectedObject = id
    }
  }
}
</script>

<style></style>
