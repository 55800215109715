<template>
  <v-alert color="info" border="left" colored-border dense class="text-caption"
    ><p v-if="triggerText === 'Overspeed'">
      An overspeed trigger will create an Event when the selected object or
      objects start to travel faster than the defined Speed Limit.
    </p>
    <p v-if="triggerText === 'Ignition'">
      An Ignition On trigger will create an Event whenever the selected object
      or object's ignition has changed from Off to On. This requires a correctly
      wired ignition circuit.
    </p>
    <p v-if="triggerText === 'Geofence'">
      A geofence trigger will create an Event whenever the selected object or
      objects enter or leave the selected zone
    </p>
    <p>
      This Event can be viewed on the map, in the history, and can be used to
      create Notifications that you can receive in the Gator App, Email or via
      SMS.
    </p>

    <a href="https://gatortracking.com.au/tracker/guide/events" target="_blank"
      >Documentation & Help</a
    >
  </v-alert>
</template>

<script>
export default {
  props: {
    type: String
  },
  computed: {
    triggerText: function () {
      let text = this.type
      try {
        if (this.type === 'sensor') {
          text = 'Ignition'
        } else if (this.type === 'overspeed' || this.type === 'underspeed') {
          text = 'Overspeed'
        } else if (this.type === 'zone_in' || this.type === 'zone_out') {
          text = 'Geofence'
        }
      } catch (error) {
        this.handleError(error)
      }
      return text
    }
  },
  methods: {}
}
</script>

<style></style>
