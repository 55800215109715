var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    staticClass: "dashboard-table",
    attrs: {
      dense: "",
      headers: _vm.parameterData.headers,
      items: _vm.parameterData.parameters,
      "item-key": "name",
      "hide-default-footer": "",
      dark: "",
      "items-per-page": -1
    },
    scopedSlots: _vm._u([
      {
        key: "header.parameter",
        fn: function(ref) {
          return [_vm._v("\n    " + _vm._s(_vm.$t("PARAMETER")) + "\n  ")]
        }
      },
      {
        key: "header.status",
        fn: function(ref) {
          return [_vm._v("\n    " + _vm._s(_vm.$t("STATUS")) + "\n  ")]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }