<template>
<v-layout fluid v-resize="onResize">
    <TriggersTable
      :table-data="triggersData"
      v-on:show-info-modal="
        showInfoModal = $event[0];
        fields.type = $event[1];
      "
      :height="contentHeight" :width="windowSize.x"
    />

    <TriggerDetailsModal
      v-if="event_id !== false"
      :show="showDetailsModal"
      :type="triggers[event_id].type"
      v-on:set-details="setDetails"
    />
    <v-bottom-sheet
      v-model="showSuccessSheet"
      v-if="event_id !== false && showSuccessSheet"
    >
      <v-sheet class="text-center" min-height="200px">
        <v-btn
          x-small
          plain
          icon
          class="float-right ma-5"
          @click="showSuccessSheet = !showSuccessSheet"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <div class="text-h5 pt-9">
          {{ success_msg }}
        </div>

        <v-btn
          :to="{
            name: 'mobile/triggers/edit',
            params: { trigger_id: event_id },
          }"
          class="mt-6"
          color="primary"
        >
          {{
            ["overspeed", "underspeed"].includes(triggers[event_id].type)
              ? "Change speed limit"
              : "Change " + triggers[event_id].name.toLowerCase() + " settings"
          }}
        </v-btn>
      </v-sheet>
    </v-bottom-sheet>

    <TriggerObjectModal :show="showObjectModal" v-on:set-objects="setObjects" />
    <TriggerInformationModal
      :show="showInfoModal"
      v-on:set-next-step="setNextStep"
      :type="fields.type"
    />
</v-layout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TriggersTable from '@/components/triggers/TriggersTable.vue'
import TriggerObjectModal from '@/components/triggers/TriggerObjectModal.vue'
import TriggerDetailsModal from '@/components/triggers/TriggerDetailsModal.vue'
import TriggerInformationModal from '@/components/triggers/TriggerInformationModal.vue'
export default {
  components: {
    TriggersTable,
    TriggerObjectModal,
    TriggerDetailsModal,
    TriggerInformationModal
  },
  data: () => ({
    success_msg: '',
    showObjectModal: false,
    showDetailsModal: false,
    showSuccessSheet: false,
    showInfoModal: false,
    showFullForm: false,
    event_id: false,
    toUpdate: false,
    fields: {
      type: '',
      objects: [],
      polygon: '',
      zones: '',
      trigger_on: true,
      speed: '70',
      inout: 'out',
      success_msg: '',
      speed_type: 'overspeed'
    }

  }),
  computed: {
    ...mapGetters({
      triggers: 'triggers/triggers'
    }),
    triggersData: function () {
      const data = []
      try {
        for (const [triggerId, trigger] of Object.entries(this.triggers)) {
          // const notification = trigger.notify_system.split(',')

          data.push({
            key: triggerId,
            id: triggerId,
            name: trigger.name,
            active: trigger.active
          })
        }
        return data
      } catch (error) {
        this.handleError(error)
      }
      return data
    }
  },
  watch: {
    event_id: function () {
      if (this.event_id === false) {
        this.fields = {
          type: '',
          objects: [],
          polygon: '',
          trigger_on: true,
          speed: '70',
          inout: 'out',
          speed_type: 'overspeed',
          zones: ''
        }
      }
    }
  },
  async created () {
    this.onResize()
    this.getTriggers().catch(error => {
      this.handleError(error)
    })
  },
  methods: {
    ...mapActions({
      getTriggers: 'triggers/getTriggers',
      saveTrigger: 'triggers/saveTrigger',
      deleteTrigger: 'triggers/deleteTrigger',
      muteTrigger: 'triggers/muteTrigger'
    }),

    setNextStep (proceed) {
      this.showInfoModal = false
      this.showObjectModal = proceed
    },
    setDetails (details, isSave) {
      try {
        this.showDetailsModal = false

        if (isSave) {
          this.fields.speed = details.speed
          this.fields.trigger_on = details.trigger_on
          this.fields.zones = details.zone
          this.fields.inout = details.inout
          this.fields.speed_type = details.speed_type
          this.save() // change default values based on user input
        } else {
          this.event_id = false
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    setObjects (objects, isSave) {
      try {
        this.showObjectModal = false
        if (isSave) {
          this.fields.objects = objects
          this.save() // add with default values
        } else {
          this.event_id = false
          this.fields = {
            type: '',
            objects: [],
            polygon: '',
            trigger_on: true,
            speed: '70',
            inout: 'out',
            speed_type: 'overspeed',
            zones: ''
          }
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    async save () {
      try {
        const params = {
          event_id: this.event_id,
          name: this.fields.type,
          trigger_type: this.fields.type,
          spec: [],
          objects: this.fields.objects
        }
        const setParams = {
          event_id: this.event_id,
          imei: this.event_id
            ? this.triggers[this.event_id].imei
            : this.fields.objects.join(','),
          zones: ''
        }

        switch (this.fields.type.toLowerCase()) {
          case 'ignition':
            params.spec.trigger_on = this.fields.trigger_on
              ? 'on'
              : 'off'

            setParams.type = 'sensor'
            setParams.name = this.fields.type
            setParams.checked_value = [
              {
                src: 'Ignition',
                cn: 'eq',
                val: this.fields.trigger_on ? '1' : '0'
              }
            ]
            setParams.notify_system = this.event_id
              ? this.triggers[this.event_id].notify_system
              : 'false, false, false, alarm1.mp3'
            break
          case 'overspeed':
            params.spec.speed = this.fields.speed
            params.name = this.fields.speed
            params.trigger_type = this.fields.speed_type

            setParams.type = this.fields.speed_type
            setParams.name = this.fields.speed
            setParams.checked_value = this.fields.speed
            setParams.notify_system = this.event_id
              ? this.triggers[this.event_id].notify_system
              : 'false, false, false,.'
            break
          case 'geofence':
            params.spec.inout = this.fields.inout
            params.spec.polygon = this.fields.polygon

            setParams.type = 'zone_' + this.fields.inout.toLowerCase()
            if (this.fields.inout.toLowerCase() === 'both') {
              setParams.type = 'zone_out'
            }
            setParams.name = this.fields.type
            setParams.checked_value = ''
            setParams.zones = this.fields.zones ? this.fields.zones : ''
            setParams.notify_system = this.event_id
              ? this.triggers[this.event_id].notify_system
              : 'false, false, false, alarm1.mp3'

            break
        }
        this.saveTrigger(setParams)
          .then(response => {
            if (response.message === 'OK') {
              if (!this.event_id) {
                let successMsg = ''
                switch (this.fields.type.toLowerCase()) {
                  case 'ignition':
                    successMsg =
                      'Ignition Trigger has been created and will create an Event whenever the car starts'
                    break
                  case 'overspeed':
                    successMsg =
                      'Overspeed Trigger created with speed limit set to 70 ' +
                      this.$t('UNIT_SPEED') +
                      '.'
                    break
                  case 'geofence':
                    successMsg =
                      'Geofence Trigger created with geofence type set to OUT.'
                    break
                }
                this.success_msg = successMsg
                this.showSuccessSheet = true
                if (process.env.NODE_ENV === 'production') {
                  this.getTriggers().then(data => {
                    this.event_id = Object.keys(data).pop()
                  })
                } else {
                  this.event_id = Object.keys(this.triggers).pop()
                }
              } else {
                this.event_id = false
                this.$notify({
                    duration: 5000,
                    closeOnClick: false,
                  type: 'success',
                  title: this.$t('INFORMATION'),
                  text: this.$t('CHANGES_SAVED_SUCCESSFULLY')
                })
              }
            } else {
              this.$notify({
                    duration: 5000,
                    closeOnClick: false,
                type: 'error',
                title: this.$t('ERROR'),
                text: 'Sorry. Something went wrong!'
              })
            }
          })
          .catch(error => {
            this.handleError(error)
          })
      } catch (error) {
        this.handleError(error)
      }
    }

  }
}
</script>

<style></style>
