var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container--fluid upper-content" },
    [
      _c(
        "div",
        { staticClass: "upper-content__table" },
        [
          _vm.devicesData
            ? _c("DevicesTable", {
                attrs: {
                  "table-data": _vm.devicesData,
                  "selected-object": _vm.selectedObject
                },
                on: {
                  "selected-object": _vm.getSelectedObject,
                  "show-form": function($event) {
                    _vm.showForm = $event
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("ObjectsForm", {
        attrs: { show: _vm.showForm },
        on: { "show-objects-dialog": _vm.setShowModal }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }