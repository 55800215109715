import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  events: {},
  event_names: [],
  event_types: [],
}

export default {
  state,
  getters,
  actions,
  mutations
}
