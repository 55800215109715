var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _vm.isMobileView
        ? _c(
            "v-app-bar",
            {
              attrs: {
                app: "",
                height: "56px",
                "elevate-on-scroll": "",
                absolute: "",
                "scroll-target": "#content",
                elevation: "0"
              }
            },
            [
              _c("v-app-bar-nav-icon", {
                on: {
                  click: function($event) {
                    _vm.drawer = true
                  }
                }
              }),
              _vm._v(" "),
              _c("v-img", {
                staticClass: "ml-3 mr-1 cursor-pointer",
                attrs: {
                  "max-width": "150",
                  src: _vm.publicPath + "assets/img/icons/gator-logo-orange.png"
                },
                on: {
                  click: function($event) {
                    return _vm.$router.push({ name: "mobile/map" })
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isMobileView
        ? _c(
            "v-app-bar",
            {
              attrs: {
                app: "",
                height: "56px",
                "elevate-on-scroll": "",
                absolute: "",
                "scroll-target": "#content",
                elevation: "0"
              }
            },
            [
              _c("v-img", {
                staticClass: "mr-1 cursor-pointer",
                attrs: {
                  "max-width": "150",
                  src: _vm.publicPath + "assets/img/icons/gator-logo-orange.png"
                },
                on: {
                  click: function($event) {
                    return _vm.$router.push({ name: "Home" })
                  }
                }
              }),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "", color: "tooltip" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: { plain: "" },
                                    on: {
                                      click: function($event) {
                                        _vm.showSettings = true
                                      }
                                    }
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("v-icon", { attrs: { left: "" } }, [
                                  _vm._v("mdi-account-cog-outline")
                                ]),
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.user.email) +
                                    "\n        "
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3832952182
                  )
                },
                [_vm._v(" "), _c("span", [_vm._v("Account Settings")])]
              ),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "", color: "tooltip" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: { icon: "", plain: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.$router.push({
                                          name: "mobile/map"
                                        })
                                      }
                                    }
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_c("v-icon", [_vm._v("mdi-cellphone")])],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3552256551
                  )
                },
                [_vm._v(" "), _c("span", [_vm._v("Switch to Mobile Version")])]
              ),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "", color: "tooltip" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: {
                                      id: "btn-logout",
                                      icon: "",
                                      plain: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        _vm.showConfirm = true
                                      }
                                    }
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_c("v-icon", [_vm._v("mdi-logout")])],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3703696821
                  )
                },
                [_vm._v(" "), _c("span", [_vm._v("Logout")])]
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "sidebar-menu",
          attrs: { app: "", absolute: "", temporary: "" },
          scopedSlots: _vm._u([
            {
              key: "prepend",
              fn: function() {
                return [
                  _c(
                    "v-list-item",
                    {
                      on: {
                        click: function($event) {
                          _vm.drawer = false
                          _vm.$router.currentRoute.name !== "mobile/map"
                            ? _vm.$router.push({ name: "mobile/map" })
                            : ""
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-avatar",
                        {
                          attrs: {
                            rounded: "false",
                            "max-width": "100%",
                            width: "100%",
                            tile: "",
                            "min-width": "100%"
                          }
                        },
                        [
                          _c("v-img", {
                            staticClass: "mx-auto",
                            attrs: {
                              contain: "",
                              width: "180",
                              src:
                                _vm.publicPath +
                                "assets/img/icons/gator-logo-orange.png"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            },
            {
              key: "append",
              fn: function() {
                return [
                  _c(
                    "div",
                    { staticClass: "pa-2" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            block: "",
                            plain: "",
                            outlined: "",
                            text: "",
                            href: "/tracking"
                          }
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-desktop-mac-dashboard")
                          ]),
                          _vm._v("\n          Switch to Pro Mode")
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pa-2" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-logout",
                          attrs: {
                            block: "",
                            color: "primary",
                            id: "btn-logout"
                          },
                          nativeOn: {
                            click: function($event) {
                              _vm.drawer = false
                              _vm.showConfirm = true
                            }
                          }
                        },
                        [_vm._v("Logout")]
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.drawer,
            callback: function($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer"
          }
        },
        [
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-list",
            { attrs: { dense: "" } },
            [
              _c(
                "v-list-item-group",
                { attrs: { color: "primary" } },
                [
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        value: "Map",
                        exact: "",
                        to: { name: "mobile/map" },
                        "active-class": ""
                      },
                      on: {
                        click: function($event) {
                          _vm.drawer = false
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v(" mdi-earth ")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.$t("MAP")))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        value: "Objects",
                        exact: "",
                        to: { name: "mobile/objects" },
                        "active-class": ""
                      },
                      on: {
                        click: function($event) {
                          _vm.drawer = false
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-map-marker-outline")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.$t("OBJECTS")))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        value: "Events",
                        exact: "",
                        to: { name: "mobile/events" },
                        "active-class": ""
                      },
                      on: {
                        click: function($event) {
                          _vm.drawer = false
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-alert-outline")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.$t("EVENTS")))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        value: "Triggers",
                        exact: "",
                        to: { name: "mobile/triggers" },
                        "active-class": ""
                      },
                      on: {
                        click: function($event) {
                          _vm.drawer = false
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-alert-outline")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.$t("TDJ_EVENT_TRIGGERS")))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        value: "History",
                        exact: "",
                        to: { name: "mobile/history" },
                        "active-class": ""
                      },
                      on: {
                        click: function($event) {
                          _vm.drawer = false
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-history")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.$t("HISTORY")))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        value: "Object Control",
                        exact: "",
                        to: { name: "mobile/objects/control" },
                        "active-class": ""
                      },
                      on: {
                        click: function($event) {
                          _vm.drawer = false
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-send-outline")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.$t("OBJECT_CONTROL")))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    {
                      attrs: {
                        value: "Settings",
                        exact: "",
                        to: { name: "mobile/settings" },
                        "active-class": ""
                      },
                      on: {
                        click: function($event) {
                          _vm.drawer = false
                        }
                      }
                    },
                    [
                      _c(
                        "v-list-item-icon",
                        [_c("v-icon", [_vm._v("mdi-settings-outline")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.$t("SETTINGS")))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item",
                    { attrs: { value: "Settings" } },
                    [_c("v-list-item-content")],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("ConfirmDialog", {
        attrs: { info: _vm.confirmDetails, show: _vm.showConfirm },
        on: { "show-confirm-dialog": _vm.confirm }
      }),
      _vm._v(" "),
      _c("Settings", {
        attrs: { show: _vm.showSettings },
        on: { "show-settings-dialog": _vm.setShowSettings }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }