var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "open-street-map",
      staticStyle: { height: "calc(100vh - 56px)" }
    },
    [
      _c(
        "l-map",
        {
          ref: "map",
          staticStyle: { height: "100%" },
          attrs: {
            options: _vm.mapOptions,
            maxZoom: _vm.config.map_max_zoom,
            minZoom: _vm.config.map_min_zoom,
            center: _vm.defaultCenter,
            zoom: _vm.defaultZoom
          },
          on: { ready: _vm.ready }
        },
        [
          _c("TileLayers"),
          _vm._v(" "),
          _vm.event !== null
            ? _c(
                "l-marker",
                {
                  ref: "marker",
                  attrs: { "lat-lng": [_vm.event.lat, _vm.event.lng] },
                  on: { ready: _vm.markerReady }
                },
                [
                  _c("l-icon", {
                    attrs: {
                      "icon-size": [28, 28],
                      "icon-url":
                        _vm.publicPath + "assets/img/markers/route-event.svg"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "l-popup",
                    {
                      attrs: {
                        options: {
                          closeOnClick: false,
                          autoClose: false
                        }
                      }
                    },
                    [
                      _c("div", {
                        staticClass: "div",
                        domProps: { innerHTML: _vm._s(_vm.eventTableString) }
                      }),
                      _vm._v(" "),
                      _vm.objectsNew[_vm.event.imei].settings.is_video_enabled
                        ? _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", outlined: "" },
                              on: { click: _vm.requestVideo }
                            },
                            [_vm._v("Request Footage")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "l-control",
            { attrs: { position: "bottomleft" } },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { right: "", color: "tooltip" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-fab-transition",
                            [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        elevation: "20",
                                        fab: "",
                                        color: "secondary",
                                        small: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.$router.go(-1)
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c("v-icon", [_vm._v("mdi-arrow-left-bold")])],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_vm._v(" "), _c("span", [_vm._v("Go back?")])]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }