var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "overflow-y-auto",
      staticStyle: { "max-height": "calc(100vh - 56px)" },
      attrs: { fluid: "", id: "scroll-target" }
    },
    [
      _c(
        "v-row",
        { staticClass: "ma-1 d-flex flex-column align-content-center" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "8", xl: "6" } },
            [
              _c(
                "v-card",
                { staticClass: "w-100 mb-3", attrs: { outlined: "" } },
                [
                  _c(
                    "validation-observer",
                    { ref: "observer" },
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "v-row",
                            { attrs: { align: "center" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex",
                                  attrs: { cols: "12", sm: "6" }
                                },
                                [
                                  _c("validation-provider", {
                                    staticClass: "w-100",
                                    attrs: {
                                      rules: "required",
                                      name: _vm.$t("OBJECT")
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mb-2 d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-body-2 mr-1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("OBJECT"))
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("FormTooltip", {
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "tooltip-trigger",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                  color:
                                                                    "primary lighten-1",
                                                                  text: "",
                                                                  icon: ""
                                                                }
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-information-outline"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      },
                                                      {
                                                        key: "tooltip-content",
                                                        fn: function() {
                                                          return [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t("OBJECT")
                                                              ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("v-select", {
                                              attrs: {
                                                "menu-props": "dark",
                                                "item-color": "#272727",
                                                color: "grey",
                                                items: _vm.objectItems,
                                                label: _vm.$t("OBJECT"),
                                                "item-text": "text",
                                                "item-value": "value",
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                required: "",
                                                solo: "",
                                                dense: "",
                                                outlined: ""
                                              },
                                              model: {
                                                value: _vm.fields.object,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.fields,
                                                    "object",
                                                    $$v
                                                  )
                                                },
                                                expression: "fields.object"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex",
                                  attrs: { cols: "12", sm: "6" }
                                },
                                [
                                  _c("validation-provider", {
                                    staticClass: "w-100",
                                    attrs: {
                                      rules: "required",
                                      name: _vm.$t("FILTER")
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mb-2 d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-body-2 mr-1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("FILTER"))
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("FormTooltip", {
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "tooltip-trigger",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                  color:
                                                                    "primary lighten-1",
                                                                  text: "",
                                                                  icon: ""
                                                                }
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-information-outline"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      },
                                                      {
                                                        key: "tooltip-content",
                                                        fn: function() {
                                                          return [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t("FILTER")
                                                              ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("v-select", {
                                              attrs: {
                                                "menu-props": "dark",
                                                "item-color": "#272727",
                                                color: "grey",
                                                items: _vm.options.filter,
                                                label: _vm.$t("FILTER"),
                                                "item-text": "text",
                                                "item-value": "value",
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                required: "",
                                                solo: "",
                                                dense: "",
                                                outlined: ""
                                              },
                                              on: {
                                                change: function($event) {
                                                  return _vm.setFieldDates()
                                                }
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "item",
                                                    fn: function(ref) {
                                                      var item = ref.item
                                                      return [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                item.trans
                                                              ) == item.trans
                                                                ? item.text
                                                                : _vm.$t(
                                                                    item.trans
                                                                  )
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: _vm.fields.filter,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.fields,
                                                    "filter",
                                                    $$v
                                                  )
                                                },
                                                expression: "fields.filter"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex flex-column",
                                  attrs: { cols: "12", sm: "6", md: "3" }
                                },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "close-on-content-click": false,
                                        "nudge-right": 40,
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "min-width": "auto"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c("validation-provider", {
                                                staticClass: "w-100",
                                                attrs: {
                                                  rules:
                                                    _vm.fields.exact_time ===
                                                    true
                                                      ? "required"
                                                      : "",
                                                  name: _vm.$t("TIME_FROM")
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mb-2 d-flex flex-row"
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text-body-2 mr-1"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "TIME_FROM"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "FormTooltip",
                                                                {
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "tooltip-trigger",
                                                                        fn: function() {
                                                                          return [
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                attrs: {
                                                                                  "x-small":
                                                                                    "",
                                                                                  color:
                                                                                    "primary lighten-1",
                                                                                  text:
                                                                                    "",
                                                                                  icon:
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "mdi-information-outline"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ]
                                                                        },
                                                                        proxy: true
                                                                      },
                                                                      {
                                                                        key:
                                                                          "tooltip-content",
                                                                        fn: function() {
                                                                          return [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "TIME_FROM"
                                                                                )
                                                                              ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        },
                                                                        proxy: true
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-text-field",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  attrs: {
                                                                    label: _vm.$t(
                                                                      "TIME_FROM"
                                                                    ),
                                                                    "prepend-inner-icon":
                                                                      "mdi-calendar",
                                                                    readonly:
                                                                      "",
                                                                    required:
                                                                      "",
                                                                    "error-messages": errors,
                                                                    hint:
                                                                      "YYYY-MM-DD format",
                                                                    solo: "",
                                                                    dense: "",
                                                                    outlined:
                                                                      "",
                                                                    "hide-details":
                                                                      "auto"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.fields
                                                                        .time_from
                                                                        .date,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .fields
                                                                          .time_from,
                                                                        "date",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "fields.time_from.date"
                                                                  }
                                                                },
                                                                "v-text-field",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            )
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ]
                                          }
                                        }
                                      ]),
                                      model: {
                                        value: _vm.timeFromPicker.date,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.timeFromPicker,
                                            "date",
                                            $$v
                                          )
                                        },
                                        expression: "timeFromPicker.date"
                                      }
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("v-date-picker", {
                                        attrs: { max: _vm.max, min: _vm.min },
                                        on: {
                                          input: function($event) {
                                            _vm.timeFromPicker.date = false
                                          }
                                        },
                                        model: {
                                          value: _vm.fields.time_from.date,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.fields.time_from,
                                              "date",
                                              $$v
                                            )
                                          },
                                          expression: "fields.time_from.date"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex flex-column",
                                  attrs: { cols: "12", sm: "6", md: "3" }
                                },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "close-on-content-click": false,
                                        "nudge-right": 40,
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "min-width": "auto"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c("validation-provider", {
                                                staticClass: "w-100",
                                                attrs: {
                                                  rules:
                                                    _vm.fields.exact_time ===
                                                    true
                                                      ? "required"
                                                      : "",
                                                  name: _vm.$t("TIME_FROM")
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("div", {
                                                            staticClass:
                                                              "mt-0 mt-sm-5 mb-2 d-flex flex-row"
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-text-field",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  attrs: {
                                                                    label: _vm.$t(
                                                                      "TIME_FROM"
                                                                    ),
                                                                    "prepend-inner-icon":
                                                                      "mdi-clock-outline",
                                                                    readonly:
                                                                      "",
                                                                    required:
                                                                      "",
                                                                    "error-messages": errors,
                                                                    solo: "",
                                                                    dense: "",
                                                                    outlined:
                                                                      "",
                                                                    "hide-details":
                                                                      "auto"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.fields
                                                                        .time_from
                                                                        .time,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .fields
                                                                          .time_from,
                                                                        "time",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "fields.time_from.time"
                                                                  }
                                                                },
                                                                "v-text-field",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            )
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ]
                                          }
                                        }
                                      ]),
                                      model: {
                                        value: _vm.timeFromPicker.time,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.timeFromPicker,
                                            "time",
                                            $$v
                                          )
                                        },
                                        expression: "timeFromPicker.time"
                                      }
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("v-time-picker", {
                                        attrs: { width: "275" },
                                        on: {
                                          "click:minute": function($event) {
                                            _vm.timeFromPicker.time = false
                                          }
                                        },
                                        model: {
                                          value: _vm.fields.time_from.time,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.fields.time_from,
                                              "time",
                                              $$v
                                            )
                                          },
                                          expression: "fields.time_from.time"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex flex-column",
                                  attrs: { cols: "12", sm: "6", md: "3" }
                                },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "close-on-content-click": false,
                                        "nudge-right": 40,
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "min-width": "auto"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c("validation-provider", {
                                                staticClass: "w-100",
                                                attrs: {
                                                  rules:
                                                    _vm.fields.exact_time ===
                                                    true
                                                      ? "required"
                                                      : "",
                                                  name: _vm.$t("TIME_TO")
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "mb-2 d-flex flex-row"
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text-body-2 mr-1"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "TIME_TO"
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "FormTooltip",
                                                                {
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "tooltip-trigger",
                                                                        fn: function() {
                                                                          return [
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                attrs: {
                                                                                  "x-small":
                                                                                    "",
                                                                                  color:
                                                                                    "primary lighten-1",
                                                                                  text:
                                                                                    "",
                                                                                  icon:
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "v-icon",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "mdi-information-outline"
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ]
                                                                        },
                                                                        proxy: true
                                                                      },
                                                                      {
                                                                        key:
                                                                          "tooltip-content",
                                                                        fn: function() {
                                                                          return [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "TIME_TO"
                                                                                )
                                                                              ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        },
                                                                        proxy: true
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-text-field",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  attrs: {
                                                                    label: _vm.$t(
                                                                      "TIME_TO"
                                                                    ),
                                                                    "prepend-inner-icon":
                                                                      "mdi-calendar",
                                                                    readonly:
                                                                      "",
                                                                    required:
                                                                      "",
                                                                    "error-messages": errors,
                                                                    hint:
                                                                      "YYYY-MM-DD format",
                                                                    solo: "",
                                                                    dense: "",
                                                                    outlined:
                                                                      "",
                                                                    "hide-details":
                                                                      "auto"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.fields
                                                                        .time_to
                                                                        .date,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .fields
                                                                          .time_to,
                                                                        "date",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "fields.time_to.date"
                                                                  }
                                                                },
                                                                "v-text-field",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            )
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ]
                                          }
                                        }
                                      ]),
                                      model: {
                                        value: _vm.timeToPicker.date,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.timeToPicker,
                                            "date",
                                            $$v
                                          )
                                        },
                                        expression: "timeToPicker.date"
                                      }
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("v-date-picker", {
                                        attrs: {
                                          max: _vm.max,
                                          min: _vm.min,
                                          "show-current": ""
                                        },
                                        on: {
                                          input: function($event) {
                                            _vm.timeToPicker.date = false
                                          }
                                        },
                                        model: {
                                          value: _vm.fields.time_to.date,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.fields.time_to,
                                              "date",
                                              $$v
                                            )
                                          },
                                          expression: "fields.time_to.date"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex flex-column",
                                  attrs: { cols: "12", sm: "6", md: "3" }
                                },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      attrs: {
                                        "close-on-content-click": false,
                                        "nudge-right": 40,
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "min-width": "auto"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c("validation-provider", {
                                                staticClass: "w-100",
                                                attrs: {
                                                  rules:
                                                    _vm.fields.exact_time ===
                                                    true
                                                      ? "required"
                                                      : "",
                                                  name: _vm.$t("TIME_TO")
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("div", {
                                                            staticClass:
                                                              "mt-0 mt-sm-5 mb-2 d-flex flex-row"
                                                          }),
                                                          _vm._v(" "),
                                                          _c(
                                                            "v-text-field",
                                                            _vm._g(
                                                              _vm._b(
                                                                {
                                                                  attrs: {
                                                                    label: _vm.$t(
                                                                      "TIME_TO"
                                                                    ),
                                                                    "prepend-inner-icon":
                                                                      "mdi-clock-outline",
                                                                    readonly:
                                                                      "",
                                                                    required:
                                                                      "",
                                                                    "error-messages": errors,
                                                                    solo: "",
                                                                    dense: "",
                                                                    outlined:
                                                                      "",
                                                                    "hide-details":
                                                                      "auto"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.fields
                                                                        .time_to
                                                                        .time,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm
                                                                          .fields
                                                                          .time_to,
                                                                        "time",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "fields.time_to.time"
                                                                  }
                                                                },
                                                                "v-text-field",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            )
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ]
                                          }
                                        }
                                      ]),
                                      model: {
                                        value: _vm.timeToPicker.time,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.timeToPicker,
                                            "time",
                                            $$v
                                          )
                                        },
                                        expression: "timeToPicker.time"
                                      }
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("v-time-picker", {
                                        attrs: { width: "275" },
                                        on: {
                                          "click:minute": function($event) {
                                            _vm.timeToPicker.time = false
                                          }
                                        },
                                        model: {
                                          value: _vm.fields.time_to.time,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.fields.time_to,
                                              "time",
                                              $$v
                                            )
                                          },
                                          expression: "fields.time_to.time"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex",
                                  attrs: { cols: "12", sm: "6" }
                                },
                                [
                                  _c("validation-provider", {
                                    staticClass: "w-100",
                                    attrs: {
                                      rules: "required",
                                      name: _vm.$t("STOP_DURATION")
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "mb-2 d-flex flex-row"
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-body-2 mr-1"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("STOP_DURATION")
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("FormTooltip", {
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "tooltip-trigger",
                                                        fn: function() {
                                                          return [
                                                            _c(
                                                              "v-btn",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                  color:
                                                                    "primary lighten-1",
                                                                  text: "",
                                                                  icon: ""
                                                                }
                                                              },
                                                              [
                                                                _c("v-icon", [
                                                                  _vm._v(
                                                                    "mdi-information-outline"
                                                                  )
                                                                ])
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      },
                                                      {
                                                        key: "tooltip-content",
                                                        fn: function() {
                                                          return [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "STOP_DURATION"
                                                                )
                                                              ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        },
                                                        proxy: true
                                                      }
                                                    ],
                                                    null,
                                                    true
                                                  )
                                                })
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("v-select", {
                                              attrs: {
                                                "menu-props": "dark",
                                                "item-color": "#272727",
                                                color: "grey",
                                                items: _vm.options.duration,
                                                label: _vm.$t("STOP_DURATION"),
                                                "item-text": "text",
                                                "item-value": "value",
                                                "hide-details": "auto",
                                                "error-messages": errors,
                                                required: "",
                                                solo: "",
                                                dense: "",
                                                outlined: ""
                                              },
                                              model: {
                                                value: _vm.fields.duration,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.fields,
                                                    "duration",
                                                    $$v
                                                  )
                                                },
                                                expression: "fields.duration"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex",
                                  attrs: { cols: "6", sm: "3" }
                                },
                                [
                                  _c("validation-provider", {
                                    staticClass: "w-100",
                                    attrs: {
                                      rules: "required",
                                      name: _vm.$t("STOPS")
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("v-checkbox", {
                                              attrs: {
                                                color: "primary",
                                                "hide-details": "auto",
                                                label: _vm.$t("STOPS"),
                                                required: "",
                                                "error-messages": errors
                                              },
                                              model: {
                                                value: _vm.fields.stops,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.fields,
                                                    "stops",
                                                    $$v
                                                  )
                                                },
                                                expression: "fields.stops"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "d-flex",
                                  attrs: { cols: "6", sm: "3" }
                                },
                                [
                                  _c("validation-provider", {
                                    staticClass: "w-100",
                                    attrs: {
                                      rules: "required",
                                      name: _vm.$t("EVENTS")
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("v-checkbox", {
                                              attrs: {
                                                color: "primary",
                                                "hide-details": "auto",
                                                label: _vm.$t("EVENTS"),
                                                required: "",
                                                "error-messages": errors
                                              },
                                              model: {
                                                value: _vm.fields.events,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.fields,
                                                    "events",
                                                    $$v
                                                  )
                                                },
                                                expression: "fields.events"
                                              }
                                            })
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "8", xl: "6" } },
            [
              _c(
                "v-row",
                {
                  staticClass:
                    "\n          mb-3\n          justify-center\n          align-content-center\n          flex-column flex-sm-row\n          d-flex\n        "
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        "min-width": "200px",
                        "max-width": "50%"
                      },
                      on: {
                        click: function($event) {
                          return _vm.show()
                        }
                      }
                    },
                    [_vm._v("Show")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }