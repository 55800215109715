<template>
  <div class="">
    <!-- mobile header -->
    <v-app-bar
      app
      height="56px"
      elevate-on-scroll
      absolute
      scroll-target="#content"
      elevation="0"
      v-if="isMobileView"
    >
     <!-- v-if="
          $route.name !== 'mobile/follow' &&
          $route.name !== 'objects/details' &&
          $route.name !== 'mobile/event/details' &&
          $route.name !== 'mobile/triggers/edit'
        " -->
      <v-app-bar-nav-icon

        @click="drawer = true"
      ></v-app-bar-nav-icon>

        <!-- v-if="
          $route.name !== 'mobile/follow' &&
          $route.name !== 'objects/details' &&
          $route.name !== 'mobile/event/details' &&
          $route.name !== 'mobile/triggers/edit'
        " -->

      <v-img
        @click="$router.push({ name: 'mobile/map' })"
        max-width="150"
        :src="`${publicPath}assets/img/icons/gator-logo-orange.png`"
        class="ml-3 mr-1 cursor-pointer"
      ></v-img>

      <!-- <v-btn
        plain
        @click="$router.go(-1)"
        v-if="
          $route.name === 'mobile/follow' ||
          $route.name === 'objects/details' ||
          $route.name === 'mobile/event/details' ||
          $route.name === 'mobile/triggers/edit'
        "
      >
        <v-icon left>mdi-chevron-left</v-icon> Back
      </v-btn> -->

    </v-app-bar>

    <!-- desktop header -->
    <v-app-bar
      app
      height="56px"
      elevate-on-scroll
      absolute
      scroll-target="#content"
      elevation="0"
      v-if="!isMobileView"
    >
      <v-img
        @click="$router.push({ name: 'Home' })"
        max-width="150"
        :src="`${publicPath}assets/img/icons/gator-logo-orange.png`"
        class="mr-1 cursor-pointer"
      ></v-img>

      <v-spacer></v-spacer>

      <v-tooltip bottom color="tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn plain @click="showSettings = true" v-bind="attrs" v-on="on">
            <v-icon left>mdi-account-cog-outline</v-icon>
            {{ user.email }}
          </v-btn>
        </template>
        <span>Account Settings</span>
      </v-tooltip>

      <v-tooltip bottom color="tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            plain
            @click="$router.push({ name: 'mobile/map' })"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-cellphone</v-icon>
          </v-btn>
        </template>
        <span>Switch to Mobile Version</span>
      </v-tooltip>

      <v-tooltip bottom color="tooltip">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            id="btn-logout"
            @click="showConfirm = true"
            icon
            plain
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-logout</v-icon>
          </v-btn>
        </template>
        <span>Logout</span>
      </v-tooltip>
    </v-app-bar>

    <!-- sidebar -->
    <v-navigation-drawer
      app
      v-model="drawer"
      absolute
      temporary
      class="sidebar-menu"
    >
      <template v-slot:prepend>
        <v-list-item
          @click="
            drawer = false;
            $router.currentRoute.name !== 'mobile/map'
              ? $router.push({ name: 'mobile/map' })
              : '';
          "
        >
          <v-list-item-avatar
            rounded="false"
            max-width="100%"
            width="100%"
            tile
            min-width="100%"
            ><v-img
              contain
              width="180"
              :src="`${publicPath}assets/img/icons/gator-logo-orange.png`"
              class="mx-auto"
            ></v-img>
          </v-list-item-avatar>
        </v-list-item>
      </template>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item-group color="primary">
          <v-list-item
            value="Map"
            exact
            :to="{ name: 'mobile/map' }"
            active-class=""
            @click="drawer = false"
          >
            <v-list-item-icon>
              <v-icon> mdi-earth </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("MAP") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            value="Objects"
            exact
            :to="{ name: 'mobile/objects' }"
            active-class=""
            @click="drawer = false"
          >
            <v-list-item-icon>
              <v-icon>mdi-map-marker-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("OBJECTS") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            value="Events"
            exact
            :to="{ name: 'mobile/events' }"
            active-class=""
            @click="drawer = false"
          >
            <v-list-item-icon>
              <v-icon>mdi-alert-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("EVENTS") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            value="Triggers"
            exact
            :to="{ name: 'mobile/triggers' }"
            active-class=""
            @click="drawer = false"
          >
            <v-list-item-icon>
              <v-icon>mdi-alert-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $t("TDJ_EVENT_TRIGGERS")
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            value="History"
            exact
            :to="{ name: 'mobile/history' }"
            active-class=""
            @click="drawer = false"
          >
            <v-list-item-icon>
              <v-icon>mdi-history</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("HISTORY") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            value="Object Control"
            exact
            :to="{ name: 'mobile/objects/control' }"
            active-class=""
            @click="drawer = false"
          >
            <v-list-item-icon>
              <v-icon>mdi-send-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("OBJECT_CONTROL") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            value="Settings"
            exact
            :to="{ name: 'mobile/settings' }"
            active-class=""
            @click="drawer = false"
          >
            <v-list-item-icon>
              <v-icon>mdi-settings-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("SETTINGS") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            value="Settings"
          >
            <!-- <v-list-item-icon>
              <v-icon>mdi-settings-outline</v-icon>
            </v-list-item-icon> -->
            <v-list-item-content>
              <!-- <v-list-item-title>{{ $t("SETTINGS") }}</v-list-item-title> -->

            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <!-- <v-divider></v-divider> -->
      <!-- <v-switch class="pl-5"
                color="primary"
                dense
                label="Search Address"
                v-model="showAddressBar"
                @change="saveUISettings({prop: 'search_address_state', value: showAddressBar}); drawer = false"
              ></v-switch
            > -->

      <template v-slot:append>
        <div class="pa-2">
          <v-btn
            block
            plain
            outlined
            text
            href="/tracking"
          >
            <v-icon left>mdi-desktop-mac-dashboard</v-icon>
            Switch to Pro Mode</v-btn
          >
        </div>
        <div class="pa-2">
          <v-btn
            @click.native="
              drawer = false;
              showConfirm = true;
            "
            block
            color="primary"
            class="btn-logout"
            id="btn-logout"
            >Logout</v-btn
          >
        </div>
      </template>
    </v-navigation-drawer>
    <ConfirmDialog
      :info="confirmDetails"
      :show="showConfirm"
      v-on:show-confirm-dialog="confirm"
    />

    <Settings
      :show="showSettings"
      v-on:show-settings-dialog="setShowSettings"
    />
  </div>
</template>

<script>
// import {RotateSquare2} from 'vue-loading-spinner'
import ConfirmDialog from '@/components/includes/ConfirmDialog.vue'
import { mapGetters, mapActions } from 'vuex'
import Settings from '@/components/settings/Settings.vue'

export default {
  components: {
    ConfirmDialog,
    Settings
    //  RotateSquare2
  },
  props: {},
  data: () => ({
    showConfirm: false,
    showSettings: false,
    confirmDetails: {
      title: 'Confirm',
      message: 'Are you sure you want to logout?',
      action: {
        yes: 'Yes',
        no: 'No'
      }
    },
    drawer: false,
    currentLang: 'English',
    value: 10,
    bufferValue: 20,
    interval: 0,
    showAddressBar: false
  }),
  computed: {
    ...mapGetters({
      user: 'settings/user',
      searchAddressState: 'settings/searchAddressState'
    })
  },
  watch: {
    // progress: {
    //   deep: true,
    //   handler () {
    //     this.$log.debug('progress updated', this.progress)
    //   }
    // },
    // 'progress.show': function () {
    //   this.$log.debug('progress show updated', this.progress.show)
    // },
    'user.language': function () {
      if (this.user.language) {
        this.currentLang =
          this.user.language[0].toUpperCase() + this.user.language.slice(1)
      }
    },
    searchAddressState: function () {
      this.showAddressBar = this.searchAddressState
    }
  },
  created () {
    // console.log('this is the header')
    // this.fetchLanguageList().then((res) => {
    //   this.$log.debug(res)
    // }).catch(err => {
    //   this.$log.debug(err)
    // })
    this.showAddressBar = this.searchAddressState
  },
  methods: {
    testMethod () {
      this.$log.debug('test method')
    },
    ...mapActions({
      saveSearchAddressState: 'settings/saveSearchAddressState'
      // fetchLanguageList: 'settings/fetchLanguageList'
    }),
    setSelectedMenu: function () {
      this.drawer = false
      this.$emit('selected-menu', this.selected)
    },
    confirm (val) {
      this.showConfirm = false
      if (val) {
        window.location.href = '/logout?mobile=yes'
      }
    },
    setShowSettings: function (isShow) {
      this.showSettings = isShow
    }
  }
}
</script>

<style></style>
