import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  session_check: [],
  gator_devices: [],
  history_list: [],
  img_list: []

}

export default {
  state,
  getters,
  actions,
  mutations
}
