<template>
  <v-dialog
    persistent
    v-model="show"
    hide-overlay
    no-click-animation
    max-width="340"
    height="auto"
  >
    <v-card outlined>
      <v-toolbar dense elevation="0">
        <v-btn icon x-small plain @click="setParameters(false)">
          <v-icon dense>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn color="primary" text @click="setParameters(true)">
            Save
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <validation-observer ref="observer">
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <validation-provider
                class="w-100"
                v-slot="{ errors }"
                rules="required"
                name="Source"
              >
                <v-select
                  menu-props="dark"
                  item-color="#272727"
                  filled
                  :items="type == 'sensor' ? sensorList : parameterList"
                  label="Source"
                  solo
                  color="grey"
                  hide-details="auto"
                  dense
                  outlined
                  v-model="src"
                  required
                  :error-messages="errors"
                ></v-select></validation-provider
            ></v-col>
            <v-col cols="12">
              <validation-provider
                class="w-100"
                v-slot="{ errors }"
                rules="required"
                name="Comparator"
              >
                <v-select
                  menu-props="dark"
                  item-color="#272727"
                  filled
                  :items="comparatorList"
                  item-value="value"
                  item-text="text"
                  label="Comparator"
                  solo
                  color="grey"
                  hide-details="auto"
                  dense
                  outlined
                  v-model="cn"
                  required
                  :error-messages="errors"
                ></v-select
              ></validation-provider>
            </v-col>

            <v-col cols="12"
              ><validation-provider
                class="w-100"
                v-slot="{ errors }"
                rules="required"
                name="Value"
                ><v-text-field
                  filled
                  v-model="val"
                  label="Value"
                  solo
                  color="grey"
                  dense
                  hide-details="auto"
                  required
                  outlined
                  :error-messages="errors"
                ></v-text-field></validation-provider
            ></v-col>
          </v-row>
        </v-container>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  props: {
    objects: Array,
    type: String,
    show: Boolean
  },
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    cn: '',
    val: '',
    src: ''
  }),
  computed: {
    ...mapGetters({
      objectsNew: 'objects/objectsNew'
    }),
    parameterList: function () {
      try {
        this.$log.debug('type', this.type)
        let list = []
        this.objects.forEach((imei) => {
          const params = this.objectsNew[imei].settings.params
          list = list.concat(params)
          // Object.entries(sensors).forEach(([key, sen]) => {
          //   list.push(sen.name)
          // })
        })

        list.sort()

        return [...new Set(list)]
      } catch (error) {
        this.handleError(error)
      }
      return []
    },
    sensorList: function () {
      try {
        this.$log.debug('type', this.type)
        const list = []
        this.$log.debug(this.objects)
        this.objects.forEach((imei) => {
          // this.$log.debug(imei, this.objectSettings[imei]);

          const sensors = this.objectsNew[imei].settings.sensors
          Object.entries(sensors).forEach(([key, sen]) => {
            this.$log.debug(sen)
            list.push(sen.name)
          })
        })

        list.sort()
        this.$log.debug(list)

        return [...new Set(list)]
      } catch (error) {
        this.handleError(error)
      }
      return []
    },
    comparatorList: function () {
      return [
        { value: 'gr', text: '>' },
        { value: 'eq', text: '=' },
        { value: 'lw', text: '<' }
      ]
    }
  },
  methods: {
    async setParameters (isSave) {
      try {
        const valid = await this.$refs.observer.validate()
        if ((isSave && valid) || !isSave) {
          this.$refs.observer.reset()
          this.$emit('set-parameters', isSave, {
            cn: this.cn,
            val: this.val,
            src: this.src
          })
        }
      } catch (error) {
        this.handleError(error)
      }
    }
  },
  created () {
    this.$log.debug('params', this.parameters, this.objectSettings)
  },
  watch: {
    show: function () {
      this.cn = ''
      this.val = ''
      this.src = ''
    }
  }
}
</script>

<style></style>
