import { globals } from '@/plugins/global'
const getters = {
  // mapData: state => state.map_data,
  // followedObjects: state => state.map_data.followed_objects,
  // objectsData: state => state.objects,
  // objectSettings: state => state.object_settings,
  // objectList: state => state.object_list,
  historyFilter: state => state.history.filter,
  objectsNew: state => state.objects_new,

  objectLayers: state => state.object_layers,
  objectItems: state => {
    let data = []
    for (const [imei] of Object.entries(state.objects_new)) {
      const obj = state.objects_new[imei]
      data.push({
        text: obj.settings.name,
        value: imei
      })
    }

    data = data.sort((a, b) => (a.text > b.text) ? 1 : ((b.text > a.text) ? -1 : 0))

    return data
  },
  protocolItems: state => {
    const data = []
    for (const [imei] of Object.entries(state.objects_new)) {
      const obj = state.objects_new[imei]
      data.push(obj.settings.protocol)
    }

    return data
  },
  // objectsData: state => state.objects_data,
  getObjectById: state => key => {
    return state.objects_new[key]
  },
  getObjectSettingsById: state => key => {
    return state.objects_new[key].settings
  },
  getGroupedObjects: state => {
    // return all objects but group them using the group_id
    const grouped = []
    Object.keys(state.objects_new).forEach(function (key) {
      const obj = state.objects_new[key].settings
      if (!grouped[obj.group_id]) {
        grouped[obj.group_id] = []
      }
      obj.object_id = key
      grouped[obj.group_id].push(obj)
    })

    return grouped
  },
  /*
  * check if sensor is existing
  */
  getObjectSensorByName: state => (key, sensorName) => {
    if (typeof state.objects_new[key].settings === 'undefined') {
      return []
    }
    const sensor = Object.values(state.objects_new[key].settings.sensors).filter(
      function (sensor) {
        return sensor.name.trim() === sensorName
      }
    )
    return sensor
  },

  /*
  * get sensor value
  */
  getSensorValue: state => (imei, sensorName) => {
    // check if sensor exists from object settings
    let sensor = Object.values(state.objects_new[imei].settings.sensors).filter(
      function (sensor) {
        return sensor.name.trim() === sensorName
      }
    )

    let sensorValue = []
    sensorValue.value = '0'
    sensorValue.value_full = ''
    // if found, get value
    if (sensor.length >= 1) {
      sensor = sensor.shift()
      var params = Object.prototype.hasOwnProperty.call(state.objects_new[imei].data, 'params') ? state.objects_new[imei].data.params : false
      sensorValue = globals.getSensorValue(params, sensor)
    }

    return sensorValue
  },
  getObjectByName: state => name => {
    const object = Object.values(state.objects_new).filter(function (object) {
      return object.settings.name === name
    })
    return object.shift()
  },
  getMarkerIcon: state => (key) => {
    const obj = state.objects_new[key]
    const objSettings = state.objects_new[key].settings

    if (objSettings.map_icon === 'arrow') {
      var arrow = objSettings.map_arrows.arrow_stopped
      if (obj.status === 'off') {
        arrow = objSettings.map_arrows.arrow_no_connection
      } else if (obj.status === 'm') {
        arrow = objSettings.map_arrows.arrow_moving
      } else if (obj.status === 'i') {
        if (objSettings.map_arrows.arrow_engine_idle !== 'off') {
          arrow = objSettings.map_arrows.arrow_engine_idle
        }
      }

      return arrow
    } else {
      const icon = objSettings.icon
      return icon
    }
  },
  getObjectEngineHours: (state, getters, rootState, rootGetters) => (imei, params) => {
    var result = -1
    params = false

    if (state.objects_new[imei].settings.engine_hours_type === 'off') {
      return result
    } else if (state.objects_new[imei].settings.engine_hours_type === 'acc') {
      if (params === false) {
        result = state.objects_new[imei].engine_hours
      }
    } else if (state.objects_new[imei].settings.engine_hours_type === 'sen') {
      if (params === false) {
        result = state.objects_new[imei].engine_hours
      } else {
        // var sensor = globals.getSensorFromType(imei, 'engh')

        const getSensorFromType = rootGetters['objects/getSensorFromType']
        var sensor = getSensorFromType(imei, 'engh')
        if (sensor !== false) {
          var sensor_ = sensor[0]

          if (sensor_.result_type === 'abs') {
            var sensorData = globals.getSensorValue(params, sensor_)
            result = sensorData.value
          }
        }
      }
    }

    if (result > -1) {
      result = globals.getTimeDetails(result, false)
    }

    return result
  },

  getObjectOdometer: (state, getters, rootState, rootGetters) => (imei, params) => {
    var result = -1

    if (state.objects_new[imei].settings.odometer_type === 'off') {
      return result
    } else if (state.objects_new[imei].settings.odometer_type === 'gps') {
      if (params === false) {
        result = state.objects_new[imei].odometer
      } else {
      }
    } else if (state.objects_new[imei].settings.odometer_type === 'sen') {
      if (params === false) {
        result = state.objects_new[imei].odometer
      } else {
        // var sensor = this.getSensorFromType(imei, 'odo') //call getters
        const getSensorFromType = rootGetters['objects/getSensorFromType']
        var sensor = getSensorFromType(imei, 'odo')

        if (sensor !== false) {
          var sensor_ = sensor[0]

          if (sensor_.result_type === 'abs') {
            var sensorData = globals.getSensorValue(params, sensor_)
            result = sensorData.value
          }
        }
      }
    }
    return result
  },

  getSensorFromType: state => (imei, type) => {
    var result = []

    var sensors = state.objects_new[imei].settings.sensors

    for (var key in sensors) {
      var sensor = sensors[key]
      if (sensor.type === type) {
        result.push(sensor)
      }
    }

    if (result.length > 0) {
      return result
    } else {
      return false
    }
  }

}

export default getters
