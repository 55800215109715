var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "player h-100 fill-height" },
    [
      _c("video-player", {
        ref: "videoPlayer",
        staticClass: "vjs-custom-skin",
        attrs: { options: _vm.playerOptions },
        on: {
          play: function($event) {
            return _vm.onPlayerPlay($event)
          },
          ready: function($event) {
            return _vm.onPlayerReady($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }