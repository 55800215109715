<template>
  <v-data-table
    dense
    :headers="headers"
    :items="devices"
    item-key="name"
    class="beacons-table"
    :item-class="selectedRow"
    @click:row="handleRowClick"
    fixed-header
    dark
    height="100%"
    :items-per-page="-1"
    hide-default-footer
  >
    <template v-slot:top>
      <v-toolbar>
        <template v-slot:default>
          <v-col class="pl-0"
            ><v-text-field
              solo
              dense
              single-line
              hide-details
              clearable
              outlined
              prepend-inner-icon="mdi-magnify"
              v-model="filter1"
              label="Filter"
              color="grey"
              class=""
            ></v-text-field
          ></v-col>
          <v-col class="pr-0"
            ><v-text-field
              solo
              dense
              single-line
              hide-details
              clearable
              outlined
              prepend-inner-icon="mdi-magnify"
              v-model="filter2"
              label="2nd Filter"
              color="grey"
              class=""
            ></v-text-field
          ></v-col>
        </template>
      </v-toolbar>
    </template>
    <template v-slot:header="">
      <thead>
        <tr>
          <th colspan="2" class="text-align-center">{{ $t("MAP") }}</th>
          <th colspan="3" class="text-align-center">Tracking Pair</th>
          <th colspan="4" class="text-align-center hidden-md-and-down">
            Metrics
          </th>
        </tr>
      </thead>
    </template>

    <template v-slot:header.follow="{}">
      {{ $t("FOLLOW") }}
    </template>
    <template v-slot:item.follow="{ item }">
      <v-simple-checkbox v-model="item.follow" disabled></v-simple-checkbox>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data: () => ({
    filter1: '',
    filter2: '',
    selectedRowId: '',
    singleSelect: false,
    selected: [],
    headers: [
      { text: 'Locate', value: 'locate', sortable: false },
      {
        text: 'Follow',
        value: 'follow',
        sortable: false,
        class: 'hidden-md-and-down',
        cellClass: 'hidden-md-and-down'
      },
      { text: 'Device', value: 'name' },
      { text: 'Driver Name', value: 'driver_name' },
      {
        text: 'Group',
        value: 'group',
        class: 'hidden-md-and-down',
        cellClass: 'hidden-md-and-down'
      },
      {
        text: 'Ign',
        value: 'ign',
        class: 'hidden-md-and-down',
        cellClass: 'hidden-md-and-down'
      },
      {
        text: 'Link',
        value: 'link',
        sortable: false,
        class: 'hidden-md-and-down',
        cellClass: 'hidden-md-and-down'
      },
      {
        text: 'Sat',
        value: 'sat',
        sortable: false,
        class: 'hidden-md-and-down',
        cellClass: 'hidden-md-and-down'
      },
      {
        text: 'Trip Hrs)',
        value: 'trip_hrs',
        sortable: false,
        class: 'hidden-md-and-down',
        cellClass: 'hidden-md-and-down'
      }
    ],
    devices: [
      {
        locate: '',
        follow: true,
        device_name: 'Device name 1',
        driver_name: 'Driver name 1',
        group: '',
        ign: '',
        link: '',
        sat: 25,
        trip_hrs: '10:25'
      },
      {
        locate: '',
        follow: false,
        device_name: 'Device name 2',
        driver_name: 'Driver name 2',
        group: '',
        ign: '',
        link: '',
        sat: 26,
        trip_hrs: '10:26'
      },
      {
        locate: '',
        follow: true,
        device_name: 'Device name 1',
        driver_name: 'Driver name 1',
        group: '',
        ign: '',
        link: '',
        sat: 25,
        trip_hrs: '10:25'
      },
      {
        locate: '',
        follow: false,
        device_name: 'Device name 2',
        driver_name: 'Driver name 2',
        group: '',
        ign: '',
        link: '',
        sat: 26,
        trip_hrs: '10:26'
      },
      {
        locate: '',
        follow: true,
        device_name: 'Device name 1',
        driver_name: 'Driver name 1',
        group: '',
        ign: '',
        link: '',
        sat: 25,
        trip_hrs: '10:25'
      },
      {
        locate: '',
        follow: false,
        device_name: 'Device name 2',
        driver_name: 'Driver name 2',
        group: '',
        ign: '',
        link: '',
        sat: 26,
        trip_hrs: '10:26'
      },
      {
        locate: '',
        follow: true,
        device_name: 'Device name 1',
        driver_name: 'Driver name 1',
        group: '',
        ign: '',
        link: '',
        sat: 25,
        trip_hrs: '10:25'
      },
      {
        locate: '',
        follow: false,
        device_name: 'Device name 2',
        driver_name: 'Driver name 2',
        group: '',
        ign: '',
        link: '',
        sat: 26,
        trip_hrs: '10:26'
      },
      {
        locate: '',
        follow: true,
        device_name: 'Device name 1',
        driver_name: 'Driver name 1',
        group: '',
        ign: '',
        link: '',
        sat: 25,
        trip_hrs: '10:25'
      },
      {
        locate: '',
        follow: false,
        device_name: 'Device name 2',
        driver_name: 'Driver name 2',
        group: '',
        ign: '',
        link: '',
        sat: 26,
        trip_hrs: '10:26'
      },
      {
        locate: '',
        follow: true,
        device_name: 'Device name 1',
        driver_name: 'Driver name 1',
        group: '',
        ign: '',
        link: '',
        sat: 25,
        trip_hrs: '10:25'
      },
      {
        locate: '',
        follow: false,
        device_name: 'Device name 2',
        driver_name: 'Driver name 2',
        group: '',
        ign: '',
        link: '',
        sat: 26,
        trip_hrs: '10:26'
      },
      {
        locate: '',
        follow: true,
        device_name: 'Device name 1',
        driver_name: 'Driver name 1',
        group: '',
        ign: '',
        link: '',
        sat: 25,
        trip_hrs: '10:25'
      },
      {
        locate: '',
        follow: false,
        device_name: 'Device name 2',
        driver_name: 'Driver name 2',
        group: '',
        ign: '',
        link: '',
        sat: 26,
        trip_hrs: '10:26'
      },
      {
        locate: '',
        follow: true,
        device_name: 'Device name 1',
        driver_name: 'Driver name 1',
        group: '',
        ign: '',
        link: '',
        sat: 25,
        trip_hrs: '10:25'
      },
      {
        locate: '',
        follow: false,
        device_name: 'Device name 2',
        driver_name: 'Driver name 2',
        group: '',
        ign: '',
        link: '',
        sat: 26,
        trip_hrs: '10:26'
      },
      {
        locate: '',
        follow: false,
        device_name: 'Device name 2',
        driver_name: 'Driver name 2',
        group: '',
        ign: '',
        link: '',
        sat: 26,
        trip_hrs: '10:26'
      },
      {
        locate: '',
        follow: true,
        device_name: 'Device name 1',
        driver_name: 'Driver name 1',
        group: '',
        ign: '',
        link: '',
        sat: 25,
        trip_hrs: '10:25'
      },
      {
        locate: '',
        follow: false,
        device_name: 'Device name 2',
        driver_name: 'Driver name 2',
        group: '',
        ign: '',
        link: '',
        sat: 26,
        trip_hrs: '10:26'
      }
    ]
  }),
  methods: {
    handleRowClick: function (item) {
      this.selectedRowId = item.id
      this.$log.debug(this.selectedObj)
      this.$emit('selected-object', item.id)
    },
    selectedRow (item) {
      return this.selectedRowId && item.id === this.selectedRowId
        ? 'active-row'
        : ''
    }
  }
}
</script>

<style></style>
