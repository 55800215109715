import axios from 'axios'
import { globals } from '@/plugins/global'

export const triggersService = {
  saveTrigger,
  getTriggers,
  deleteTrigger
  // muteTrigger
}

const requestUrl = process.env.BASE_URL + 'src/assets/json/'

function getTriggers () {
  if (process.env.NODE_ENV === 'development') {
    return axios.get(`${requestUrl}/settings/event_triggers_data.json`)
  }
  return axios.post('/triggers/data', globals.convertJsonToFormData({ cmd: 'load_event_data' }))
}

// function getEventListSettings (params) {
//   if (process.env.NODE_ENV === 'development') {
//     return axios.get(`${requestUrl}/settings/event_list.json`)
//   }
//   return axios.get( '/triggers', params)
// }

function saveTrigger (params) {
  // this.$log.debug(params, params.event_id)
  // NO API ENDPOINT YET
  return axios.post('/triggers/save', globals.convertJsonToFormData(params))
}

function deleteTrigger (ids) {
  // params.cmd = "delete_selected_events"

  // NO API ENDPOINT YET
  const formData = new FormData()
  formData.append('cmd', 'delete_selected_events')
  ids.forEach(id => {
    formData.append('items[]', id)
  })
  return axios.post('/triggers/delete', formData)
}

// function muteTrigger (params) {
//   // NO API ENDPOINT YET
//   return axios.post('/func/fn_settings.events.php', globals.convertJsonToFormData(params))
// }
