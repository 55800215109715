<template>
  <div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="gprsData"
      item-key="id"
      show-select
      class="elevation-1"
      :search="search"
      dense
      fixed-header
      height="calc(100vh - 220px)"
    >
    <template v-slot:top>
        <v-toolbar>
          <v-row class="align-center">
            <v-col>
              <v-tooltip bottom color="tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="border-1--yellow"
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="openForm"
                  >
                    <v-icon left> mdi-send-outline </v-icon> {{ $t("SEND_COMMAND") }}
                  </v-btn>
                </template>
                <span>Add</span>
              </v-tooltip>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="border-1--yellow"
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon left> mdi-settings-outline </v-icon> Options
                  </v-btn>
                </template>
                <v-list dense tile>
                  <v-list-item @click="deleteGPRS(selected)">
                    <v-list-item-title>Delete</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-tooltip bottom color="tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="border-1--yellow"
                    small
                    v-bind="attrs"
                    v-on="on"
                    @click="getCmdData({gateway: 'gprs'})"
                  >
                    <v-icon > mdi-refresh </v-icon>
                  </v-btn>
                </template>
                <span>Refresh</span>
              </v-tooltip>
            </v-col>
            <v-col
              cols="8"
              sm="6"
              md="5"
              lg="4"
              class="d-flex justify-content-end"
            >
              <v-text-field
                solo
                dense
                single-line
                hide-details
                clearable
                outlined
                prepend-inner-icon="mdi-magnify"
                v-model="search"
                label="Search"
                color="grey"
                class=""
              ></v-text-field
            ></v-col>
          </v-row>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-tooltip bottom color="tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              x-small
              icon
              dense
              plain
              v-bind="attrs"
              v-on="on"
              @click="deleteGPRS([item])"
            >
              <v-icon dense>mdi-trash-can-outline</v-icon>
            </v-btn>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>

    </v-data-table>
    <CommandForm
      :showCommandForm="showCommandForm"
      :id="id"
      gateway="GPRS"
      v-on:show-command-dialog="setShowModal"
    />

    <ConfirmDialog
      :info="confirmDetails"
      :show="showConfirm"
      v-on:show-confirm-dialog="confirmDetails.func"
    />

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CommandForm from '@/components/control/CommandForm.vue'
import ConfirmDialog from '@/components/includes/ConfirmDialog.vue'
// import moment from 'moment'
export default {
  components: {
    CommandForm,
    ConfirmDialog
  },
  props: {
    id: {
      default: null
    }
  },
  data () {
    return {
      showCommandForm: false,
      search: '',
      selected: [],
      headers: [
        { text: 'Time', value: 'time' },
        { text: 'Object', value: 'object' },
        { text: 'Name', value: 'name' },
        { text: 'Command', value: 'cmd' },
        { text: 'Status', value: 'status' },
        { text: '', value: 'actions', sortable: false }
      ],
      showConfirm: false,
      confirmDetails: {
        title: '',
        message: '',
        action: {
          yes: '',
          no: ''
        },
        func: () => {}
      },
      toDelete: ''
    }
  },
  computed: {
    ...mapGetters({
      cmdGprsData: 'cmd/cmdGprsData',
      getObjectSettingsById: 'objects/getObjectSettingsById'
    }),
    gprsData: function () {
      const data = []

      for (const [id, cmd] of Object.entries(this.cmdGprsData)) {
        const obj = this.getObjectSettingsById(cmd.object)
        data.push({
          time: cmd.time,
          imei: cmd.object,
          object: obj.name,
          name: cmd.name,
          cmd: cmd.cmd,
          status: cmd.status,
          id: id
        })
      }

      return data
    }
  },
  watch: {},
  created () {
    this.getCmdData({ gateway: 'gprs' }).catch(error => this.handleError(error))
  },
  methods: {
    ...mapActions({
      deleteCmd: 'cmd/deleteCmd',
      getCmdData: 'cmd/getCmdData'
    }),
    openForm () {
      try {
        if (this.checkPrivilege('viewer')) {
          this.showCommandForm = true
        } else {
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'error',
            title: this.$t('ERROR'),
            text: this.$t('THIS_ACCOUNT_HAS_NO_PRIVILEGES_TO_DO_THAT')
          })
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    setShowModal: function (isShow) {
      this.showCommandForm = false
    },

    remove (id) {
      try {
        if (this.checkPrivilege('viewer')) {
          this.toDelete = id
          this.confirmDetails = {
            title: 'Confirm',
            message: this.$t('ARE_YOU_SURE_YOU_WANT_TO_DELETE'),
            func: this.confirm
          }
          this.showConfirm = true
        } else {
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'error',
            title: this.$t('ERROR'),
            text: this.$t('THIS_ACCOUNT_HAS_NO_PRIVILEGES_TO_DO_THAT')
          })
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    async confirm (val) {
      try {
        this.showConfirm = false
        if (val) {
          const items = []
          // if (this.toDelete) {
          //   items.push(this.toDelete)
          // } else if (this.selected.length) {
          //   this.selected.forEach(group => {
          //     items.push(group.id)
          //   })
          // }

          this.selected.forEach(item => {
            items.push(item.id)
          })
          // console.log('go and delete', items)
          const response = await this.deleteCmd(items)
          if (response.message === 'OK') {
            this.$notify({
                    duration: 5000,
                    closeOnClick: false,
              type: 'success',
              title: this.$t('INFORMATION'),
              text: this.$t('CHANGES_SAVED_SUCCESSFULLY')
            })
            if (process.env.NODE_ENV === 'production') {
              this.getCmdData({ gateway: 'gprs' })
            }
          } else {
            this.$notify({
                    duration: 5000,
                    closeOnClick: false,
              type: 'error',
              title: this.$t('ERROR'),
              text:
                'Something went wrong! Please contact system administrator.'
            })
          }
          this.selected = []
        } else {
          // console.log('dont delete')
        }
      } catch (error) {
        this.handleError(error)
      }
    },

    // deleteSelected () {
    //   this.$log.debug('delete selected', this.selected)
    //   if (this.checkPrivilege('viewer')) {
    //     // this.toDelete = id;
    //     this.confirmDetails = {
    //       title: 'Confirm',
    //       message: this.$t('ARE_YOU_SURE_YOU_WANT_TO_DELETE'),
    //       func: this.confirm
    //     }
    //     this.showConfirm = true
    //   } else {
    //     this.$notify({
                    duration: 5000,
                    closeOnClick: false,
    //       type: 'error',
    //       title: this.$t('ERROR'),
    //       text: this.$t('THIS_ACCOUNT_HAS_NO_PRIVILEGES_TO_DO_THAT')
    //     })
    //   }
    // },

    deleteGPRS (items) {
      // console.log(items)
      if (this.checkPrivilege('viewer')) {
        this.selected = items
        this.confirmDetails = {
          title: 'Confirm',
          message: this.$t('ARE_YOU_SURE_YOU_WANT_TO_DELETE'),
          func: this.confirm
        }
        this.showConfirm = true
      } else {
        this.$notify({
                    duration: 5000,
                    closeOnClick: false,
          type: 'error',
          title: this.$t('ERROR'),
          text: this.$t('THIS_ACCOUNT_HAS_NO_PRIVILEGES_TO_DO_THAT')
        })
      }
    }
  }
}
</script>

<style>
</style>
