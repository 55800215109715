var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.passengersData,
          "item-key": "id",
          "show-select": "",
          search: _vm.search,
          dense: "",
          "fixed-header": "",
          height: "calc(100vh - 172px)"
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c(
                  "v-toolbar",
                  [
                    _c(
                      "v-row",
                      { staticClass: "align-center" },
                      [
                        _c(
                          "v-col",
                          [
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "", color: "tooltip" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "border-1--yellow",
                                                attrs: { small: "" },
                                                on: { click: _vm.openForm }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { left: "" } },
                                              [_vm._v(" mdi-plus ")]
                                            ),
                                            _vm._v(" Add\n                ")
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ])
                              },
                              [_vm._v(" "), _c("span", [_vm._v("Add")])]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-menu",
                              {
                                attrs: { "offset-y": "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "border-1--yellow",
                                                attrs: { small: "" }
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { left: "" } },
                                              [_vm._v(" mdi-settings-outline ")]
                                            ),
                                            _vm._v(" Options\n                ")
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ])
                              },
                              [
                                _vm._v(" "),
                                _c(
                                  "v-list",
                                  { attrs: { dense: "", tile: "" } },
                                  [
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.uploadFile()
                                          }
                                        }
                                      },
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v("Import")
                                        ])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.exportData()
                                          }
                                        }
                                      },
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v("Export")
                                        ])
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteSelected()
                                          }
                                        }
                                      },
                                      [
                                        _c("v-list-item-title", [
                                          _vm._v("Delete")
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          {
                            staticClass: "d-flex justify-content-end",
                            attrs: { cols: "8", sm: "6", md: "5", lg: "4" }
                          },
                          [
                            _c("v-text-field", {
                              attrs: {
                                solo: "",
                                dense: "",
                                "single-line": "",
                                "hide-details": "",
                                clearable: "",
                                outlined: "",
                                "prepend-inner-icon": "mdi-magnify",
                                label: "Search",
                                color: "grey"
                              },
                              model: {
                                value: _vm.search,
                                callback: function($$v) {
                                  _vm.search = $$v
                                },
                                expression: "search"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "item.actions",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "", color: "tooltip" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "mr-2",
                                      attrs: {
                                        "x-small": "",
                                        icon: "",
                                        dense: "",
                                        plain: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.edit(item.id)
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { dense: "" } }, [
                                    _vm._v("mdi-pencil")
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [_vm._v(" "), _c("span", [_vm._v("Edit")])]
                ),
                _vm._v(" "),
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "", color: "tooltip" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        "x-small": "",
                                        icon: "",
                                        dense: "",
                                        plain: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.remove(item.id)
                                        }
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("v-icon", { attrs: { dense: "" } }, [
                                    _vm._v("mdi-trash-can-outline")
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [_vm._v(" "), _c("span", [_vm._v("Delete")])]
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.selected,
          callback: function($$v) {
            _vm.selected = $$v
          },
          expression: "selected"
        }
      }),
      _vm._v(" "),
      _c("input", {
        ref: "uploader",
        staticClass: "d-none",
        attrs: { type: "file" },
        on: { change: _vm.onFileChanged }
      }),
      _vm._v(" "),
      _c("PersonForm", {
        attrs: { show: _vm.showForm, id: _vm.id, type: _vm.type },
        on: { "show-person-dialog": _vm.setShowModal }
      }),
      _vm._v(" "),
      _c("ConfirmDialog", {
        attrs: { info: _vm.confirmDetails, show: _vm.showConfirm },
        on: { "show-confirm-dialog": _vm.confirmDetails.func }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }