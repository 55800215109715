const getters = {
  eventVideos: state => state.event_videos,
  hasVideoRequest: state => state.has_video_request,
  videoRequestsStatus: state => state.video_requests_status,
  videoRequests: state => state.video_requests,
  pendingVideoRequestIds: state => {
    // console.log(state.video_requests, typeof state.video_requests)

    if (state.video_requests.hasOwnProperty('pending')) {
      return state.video_requests.pending.map(r => {
        return r.msg_id
      })
    }
    return []
    
  },
}

export default getters
