import axios from 'axios'
import { globals } from '@/plugins/global'
// import { resolve } from 'core-js/fn/promise'

export const personService = {
  getDrivers,
  getPassengers,
  savePassenger,
  removePassenger,
  removeDriver,
  importPassengers
}

const requestUrl = process.env.BASE_URL + 'src/assets/json/settings'

function getDrivers () {
  if (process.env.NODE_ENV === 'development') {
    return axios.get(`${requestUrl}/drivers_data.json`)
  }
  // return axios.post('/func/fn_settings.drivers.php', globals.convertJsonToFormData({ cmd: 'load_object_driver_data' }))
  return Promise.resolve({})
}

function getPassengers () {
  if (process.env.NODE_ENV === 'development') {
    return axios.get(`${requestUrl}/passengers_data.json`)
  }
  return axios.get('/passengers/data', { params: { cmd: 'fetch_data' } })
}

function savePassenger (params) {
  return axios.post('/passengers/save', globals.convertJsonToFormData(params))
}

function removePassenger (ids) {
  const formData = new FormData()
  // formData.append('cmd', 'delete_selected_events')
  ids.forEach(id => {
    formData.append('items[]', id)
  })
  return axios.post('/passengers/delete', formData)
}

function removeDriver (params) {
  return axios.post('/func/fn_settings.drivers.php', globals.convertJsonToFormData(params),
    { baseURL: '' })
}

function importPassengers (params) {
  return axios.post('/passengers/import', globals.convertJsonToFormData(params))
}
