<template>
  <v-dialog
    persistent
    v-model="show"
    hide-overlay
    no-click-animation
    max-width="370"
    width="auto"
  >
    <v-card>
      <v-toolbar color="" dense elevation="2">
        <v-toolbar-title class="text-subtitle-2"> Filter</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn plain icon x-small @click="$emit('show-request-dialog')">
          <v-icon dense>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container fluid>
        <validation-observer ref="observer">
          <v-row>
            <v-col cols="12">
              <v-select
                color="grey"
                menu-props="dark"
                item-color="#272727"
                v-model="fields.imei"
                :items="objectItems"
                label="Filter by Object"
                item-text="text"
                item-value="value"
                hide-details="auto"
                required
                solo
                dense
                outlined
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu
                ref="dateMenu"
                v-model="dateMenu"
                :close-on-content-click="false"
                :return-value.sync="fields.date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fields.date"
                    label="Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-calendar"
                    required
                    hint="YYYY-MM-DD format"
                    solo
                    dense
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fields.date"
                  no-title
                  scrollable
                  :max="max"
                  @input="$refs.dateMenu.save(fields.date)"
                >
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6">
              <v-menu
                ref="timeMenu"
                v-model="timeMenu"
                :close-on-content-click="false"
                :return-value.sync="fields.time"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fields.time"
                    label="Time"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    append-icon="mdi-calendar"
                    required
                    hint="YYYY-MM-DD format"
                    solo
                    dense
                    outlined
                    hide-details="auto"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="fields.time"
                  no-title
                  scrollable
                  @input="$refs.timeMenu.save(fields.time)"
                >
                  <!-- <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="dateFromMenu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dateFromMenu.save(fields.dtf)"
                  >
                    OK
                  </v-btn> -->
                </v-time-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-row class="mb-3 justify-center flex-column flex-sm-row d-flex">
            <v-btn
              @click="$emit('show-request-dialog')"
              color="primary"
              outlined
              class="mr-sm-2 mb-2"
              max-width="50%"
              >Cancel</v-btn
            >
            <v-btn @click="requestVideo" color="primary" max-width="50%"
              >Request Footage</v-btn
            >
          </v-row>
        </validation-observer>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import moment from 'moment'
export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    dateMenu: false,
    timeMenu: false,
    fields: {
      imei: '',
      date: '',
      time: ''
    },
    max: moment().format('YYYY-MM-DD')
  }),
  props: {
    show: Boolean
  },
  watch: {
    show: function () {
      if (!this.fields.imei) {
        if (this.objectItems.length) {
          this.fields.imei = this.objectItems[0].value
        }
      }
    },

    objectItems: function () {
      if (!this.fields.imei) {
        if (this.objectItems.length) {
          this.fields.imei = this.objectItems[0].value
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      objectItems: 'objects/objectItems',
      // eventNames: 'events/eventNames',
      objectsNew: 'objects/objectsNew'
      // eventTypes: 'events/eventTypes'
    })
  },
  methods: {
    ...mapActions({
      requestVideoFootage: 'images/requestVideoFootage'
    }),
    requestVideo () {
      if (this.objectsNew[this.fields.imei].settings.is_video_enabled === false) {
        this.$notify({
                    duration: 5000,
                    closeOnClick: false,
          type: 'error',
          title: this.$t('ERROR'),
          text: 'Sorry. This device is no video capability.'
        })
      } else {
        this.requestVideoFootage({
          imei: this.fields.imei,
          dtf: this.convertLocalTimezoneToUTC(this.fields.date + ' ' + this.fields.time + ':00', true)
        }).then(response => {
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'info',
            title: this.$t('INFO'),
            text: response.message
          })
        })
      }
      this.$emit('show-request-dialog')
    }
  }
}
</script>

<style></style>
