<template>
  <div class="tab-content">
  <div class="container--fluid upper-content">
    <div class="upper-content__table">
      <BeaconsTable />
    </div>
  </div>
  </div>
</template>

<script>
import BeaconsTable from '@/components/beacons/BeaconsTable.vue'

export default {
  components: {
    BeaconsTable
  }
}
</script>

<style></style>
