var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-tabs",
    {
      staticClass: "map-tabs h-100",
      attrs: {
        "slider-color": "#FF5100",
        "background-color": "#3c3c3c",
        "show-arrows": "",
        height: "30px"
      }
    },
    [
      _c(
        "v-tab",
        [
          _c("v-img", {
            staticClass: "mr-1",
            attrs: {
              "max-width": "13",
              src: _vm.publicPath + "assets/img/icons/tabs/open-street-map.svg"
            }
          }),
          _vm._v("\n      Open Street Map\n    ")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-tab",
        [
          _c("v-img", {
            staticClass: "mr-1",
            attrs: {
              "max-width": "13",
              src: _vm.publicPath + "assets/img/icons/tabs/google-map.svg"
            }
          }),
          _vm._v("\n      Google Map\n    ")
        ],
        1
      ),
      _vm._v(" "),
      _c("v-tab-item", [_c("OpenStreetMap")], 1),
      _vm._v(" "),
      _c("v-tab-item", [_c("GoogleMaps")], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }