var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    staticClass: "beacons-table",
    attrs: {
      dense: "",
      headers: _vm.headers,
      items: _vm.devices,
      "item-key": "name",
      "item-class": _vm.selectedRow,
      "fixed-header": "",
      dark: "",
      height: "100%",
      "items-per-page": -1,
      "hide-default-footer": ""
    },
    on: { "click:row": _vm.handleRowClick },
    scopedSlots: _vm._u([
      {
        key: "top",
        fn: function() {
          return [
            _c("v-toolbar", {
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function() {
                    return [
                      _c(
                        "v-col",
                        { staticClass: "pl-0" },
                        [
                          _c("v-text-field", {
                            attrs: {
                              solo: "",
                              dense: "",
                              "single-line": "",
                              "hide-details": "",
                              clearable: "",
                              outlined: "",
                              "prepend-inner-icon": "mdi-magnify",
                              label: "Filter",
                              color: "grey"
                            },
                            model: {
                              value: _vm.filter1,
                              callback: function($$v) {
                                _vm.filter1 = $$v
                              },
                              expression: "filter1"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "pr-0" },
                        [
                          _c("v-text-field", {
                            attrs: {
                              solo: "",
                              dense: "",
                              "single-line": "",
                              "hide-details": "",
                              clearable: "",
                              outlined: "",
                              "prepend-inner-icon": "mdi-magnify",
                              label: "2nd Filter",
                              color: "grey"
                            },
                            model: {
                              value: _vm.filter2,
                              callback: function($$v) {
                                _vm.filter2 = $$v
                              },
                              expression: "filter2"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ]
        },
        proxy: true
      },
      {
        key: "header",
        fn: function() {
          return [
            _c("thead", [
              _c("tr", [
                _c(
                  "th",
                  { staticClass: "text-align-center", attrs: { colspan: "2" } },
                  [_vm._v(_vm._s(_vm.$t("MAP")))]
                ),
                _vm._v(" "),
                _c(
                  "th",
                  { staticClass: "text-align-center", attrs: { colspan: "3" } },
                  [_vm._v("Tracking Pair")]
                ),
                _vm._v(" "),
                _c(
                  "th",
                  {
                    staticClass: "text-align-center hidden-md-and-down",
                    attrs: { colspan: "4" }
                  },
                  [_vm._v("\n          Metrics\n        ")]
                )
              ])
            ])
          ]
        },
        proxy: true
      },
      {
        key: "header.follow",
        fn: function(ref) {
          return [_vm._v("\n    " + _vm._s(_vm.$t("FOLLOW")) + "\n  ")]
        }
      },
      {
        key: "item.follow",
        fn: function(ref) {
          var item = ref.item
          return [
            _c("v-simple-checkbox", {
              attrs: { disabled: "" },
              model: {
                value: item.follow,
                callback: function($$v) {
                  _vm.$set(item, "follow", $$v)
                },
                expression: "item.follow"
              }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }