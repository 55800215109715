<template>
  <v-dialog
    persistent
    v-model="show"
    hide-overlay
    no-click-animation
    fullscreen
    v-if="show"
  >
    <v-card class="">
      <v-toolbar dense>
        <v-btn icon x-small @click="closeDialog">
          <v-icon plain dense>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="text-subtitle-2"
          >Create geofence</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn color="primary" text @click="save"> Save </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container fluid style="height: calc(100vh - 48px)" class="pa-0">
        <editable-map
          ref="map"
          editable
          :options="mapOptions"
          :maxZoom="config.map_max_zoom"
          :minZoom="config.map_min_zoom"
          @ready="ready"
          :center="defaultCenter"
        >
          <l-tile-layer :url="url" :attribution="attribution" /> </editable-map
      ></v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
// import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapFunctions } from '@/plugins/map'
import L from 'leaflet'
import {
  LTileLayer
// LControl
} from 'vue2-leaflet'
import { EditableMap } from 'vue2-leaflet-editable'

export default {
  components: {
    // ValidationProvider,
    // ValidationObserver,
    LTileLayer,
    EditableMap
    // LControl
  },
  props: {
    show: Boolean
  },
  data () {
    return {
      mapLayers: {
        zone: {}
      },
      visible: false,
      polygon: '',
      points: [],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 0,
      mapOptions: {
        zoomSnap: 0.5
      },
      map: null,
      iconSize: [28, 28],
      init: true,
      mapTypes: {
        openstreetmap: {
          osm: {
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution:
              '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      message: 'messages/message',
      groups: 'groups/groups',
      server: 'settings/server'
    }),
    defaultCenter: function () {
      try {
        let lat = 0
        let lng = 0
        if (this.server.map_lat && this.server.map_zoom) {
          lat = this.server.map_lat
          lng = this.server.map_lng
        }
        return [lat, lng]
      } catch (error) {
        this.handleError(error)
      }
      return [0, 0]
    }
  },
  watch: {
    show: function () {
      try {
        if (!this.show) {
          this.map.editTools.stopDrawing()
          this.map.removeLayer(this.mapLayers.zones)
          this.map.doubleClickZoom.enable()
        }
      } catch (error) {
        this.handleError(error)
      }
    }
  },
  methods: {
    ...mapActions({}),
    closeDialog () {
      this.$emit('set-geofence', this.polygon, false)
    },
    ready () {
      try {
        this.map = this.$refs.map.mapObject
        this.mapLayers.zones = L.layerGroup()
        this.mapLayers.zones.addTo(this.map)

        this.map.on('editable:editing editable:drag', this.drawObject)
        this.map.on('editable:drawing:end', this.drawObject)

        this.map.doubleClickZoom.disable()
        this.map.editTools.startPolygon()
        this.$refs.map.mapObject._onResize()
      } catch (error) {
        this.handleError(error)
      }
    },
    drawObject (e) {
      try {
        const layer = e.layer

        this.points = layer.getLatLngs()[0]

        if (this.points.length > 40) {
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'error',
            title: this.$t('ERROR'),
            text: this.$t('ZONE_CANT_HAVE_MORE_THAN_NUM_VERTICES')
          })
        } else if (this.points.length >= 3) {
          const zone = {
            points: this.points
          }
          this.mapLayers.zone = mapFunctions.generatePolygon(zone)
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    save () {
      try {
        if (this.points.length > 40) {
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'error',
            title: this.$t('ERROR'),
            text: this.$t('ZONE_CANT_HAVE_MORE_THAN_NUM_VERTICES')
          })
        } else if (this.points.length < 3) {
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'error',
            title: this.$t('ERROR'),
            text: 'Zones should have at least 3 points'
          })
        } else {
          this.polygon = mapFunctions.convertLatLngToString(this.points)
          this.$emit('set-geofence', this.polygon, true)
        }
      } catch (error) {
        this.handleError(error)
      }
    }
  },
  created () {}
}
</script>

<style></style>
