var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-data-table", {
        staticClass: "places-table",
        attrs: {
          dense: "",
          headers: _vm.headers,
          items: _vm.filteredTableData,
          "item-key": "name",
          "group-by": "group",
          "item-class": _vm.selectedRow,
          "fixed-header": "",
          dark: "",
          height: "calc(100vh - 350px)",
          "items-per-page": -1,
          "hide-default-footer": ""
        },
        on: { "click:row": _vm.handleRowClick },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c("v-toolbar", {
                  attrs: { extended: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function() {
                        return [
                          _c(
                            "v-menu",
                            {
                              attrs: { "offset-x": "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass:
                                                "border-1--yellow v-btn--parallelogram ml-1",
                                              attrs: { small: "" }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "\n                Add Place\n              "
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-list",
                                { attrs: { dense: "", tile: "" } },
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.addPlaceObject("markers")
                                        }
                                      }
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Markers")
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.addPlaceObject("routes")
                                        }
                                      }
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Routes")
                                      ])
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.addPlaceObject("zones")
                                        }
                                      }
                                    },
                                    [
                                      _c("v-list-item-title", [_vm._v("Zones")])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "border-1--yellow v-btn--parallelogram mx-2",
                              attrs: { small: "" }
                            },
                            [_vm._v("\n            Clear Filters\n          ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "border-1--yellow v-btn--parallelogram mr-1",
                              attrs: { small: "" },
                              on: {
                                click: function($event) {
                                  return _vm.exportData()
                                }
                              }
                            },
                            [_vm._v("\n            Export\n          ")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "border-1--yellow v-btn--parallelogram mr-1",
                              attrs: { small: "" },
                              on: {
                                click: function($event) {
                                  return _vm.uploadFile()
                                }
                              }
                            },
                            [_vm._v("\n            Import\n          ")]
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "extension",
                      fn: function() {
                        return [
                          _c(
                            "v-col",
                            { staticClass: "pl-0" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  solo: "",
                                  dense: "",
                                  "single-line": "",
                                  "hide-details": "",
                                  clearable: "",
                                  outlined: "",
                                  "prepend-inner-icon": "mdi-magnify",
                                  label: "Filter",
                                  color: "grey"
                                },
                                model: {
                                  value: _vm.filter1,
                                  callback: function($$v) {
                                    _vm.filter1 = $$v
                                  },
                                  expression: "filter1"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "pr-0" },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  solo: "",
                                  dense: "",
                                  "single-line": "",
                                  "hide-details": "",
                                  clearable: "",
                                  outlined: "",
                                  "prepend-inner-icon": "mdi-magnify",
                                  label: "2nd Filter",
                                  color: "grey"
                                },
                                model: {
                                  value: _vm.filter2,
                                  callback: function($$v) {
                                    _vm.filter2 = $$v
                                  },
                                  expression: "filter2"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ]
            },
            proxy: true
          },
          {
            key: "header",
            fn: function() {
              return [
                _c("thead", [
                  _c("tr", [
                    _c(
                      "th",
                      {
                        staticClass: "text-align-center",
                        attrs: { colspan: "2" }
                      },
                      [_vm._v(_vm._s(_vm.$t("MAP")))]
                    ),
                    _vm._v(" "),
                    _c("th", { staticClass: "text-align-center" }, [
                      _vm._v(_vm._s(_vm.$t("PLACE")))
                    ]),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass: "text-align-center",
                        attrs: { colspan: "5" }
                      },
                      [_vm._v("Closest Asset")]
                    )
                  ])
                ])
              ]
            },
            proxy: true
          },
          {
            key: "group.header",
            fn: function(ref) {
              var items = ref.items
              var isOpen = ref.isOpen
              var toggle = ref.toggle
              return [
                _c(
                  "th",
                  {
                    staticClass: "group-header",
                    attrs: { colspan: "7" },
                    on: { click: toggle }
                  },
                  [_vm._v("\n        " + _vm._s(items[0].group) + "\n      ")]
                )
              ]
            }
          },
          {
            key: "header.driver_name",
            fn: function(ref) {
              return [_vm._v("\n      " + _vm._s(_vm.$t("DRIVER")) + "\n    ")]
            }
          },
          {
            key: "item.locate",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("v-img", {
                  staticClass: "mr-1",
                  attrs: {
                    "max-width": "18",
                    src:
                      _vm.publicPath +
                      "assets/img/icons/" +
                      (item.locate === true ? "locate-green.svg" : "locate.svg")
                  },
                  on: {
                    click: function($event) {
                      return _vm.locatePlaceObject(
                        item.id,
                        item.locate,
                        item.group
                      )
                    }
                  }
                })
              ]
            }
          },
          {
            key: "item.distance",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(item.distance + _vm.$t("UNIT_DISTANCE")) +
                    "\n    "
                )
              ]
            }
          },
          {
            key: "item.visible",
            fn: function(ref) {
              var item = ref.item
              return [
                _c("v-checkbox", {
                  attrs: { dense: "" },
                  on: {
                    change: function($event) {
                      return _vm.setPlaceVisibility(
                        item.id,
                        item.visible,
                        item.group
                      )
                    }
                  },
                  model: {
                    value: item.visible,
                    callback: function($$v) {
                      _vm.$set(item, "visible", $$v)
                    },
                    expression: "item.visible"
                  }
                })
              ]
            }
          },
          {
            key: "item.actions",
            fn: function(ref) {
              var item = ref.item
              return [
                _c(
                  "v-menu",
                  {
                    attrs: { "offset-y": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var on = ref.on
                            var attrs = ref.attrs
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        "x-small": "",
                                        icon: "",
                                        dense: "",
                                        plain: ""
                                      }
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "", dense: "" } },
                                    [_vm._v("mdi-settings-outline")]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _vm._v(" "),
                    _c(
                      "v-list",
                      { attrs: { dense: "", tile: "" } },
                      [
                        _c(
                          "v-list-item",
                          {
                            on: {
                              click: function($event) {
                                return _vm.editPlaceObject(item)
                              }
                            }
                          },
                          [_c("v-list-item-title", [_vm._v("Edit")])],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-list-item",
                          {
                            on: {
                              click: function($event) {
                                return _vm.showConfirmDialog(item)
                              }
                            }
                          },
                          [_c("v-list-item-title", [_vm._v("Delete")])],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("PlaceMarkersForm", {
        attrs: { show: _vm.showForm && _vm.type === "markers" },
        on: { "show-place-dialog": _vm.setShowModal }
      }),
      _vm._v(" "),
      _c("PlaceRoutesForm", {
        attrs: { show: _vm.showForm && _vm.type === "routes" },
        on: { "show-place-dialog": _vm.setShowModal }
      }),
      _vm._v(" "),
      _c("PlaceZonesForm", {
        attrs: { show: _vm.showForm && _vm.type === "zones" },
        on: { "show-place-dialog": _vm.setShowModal }
      }),
      _vm._v(" "),
      _c("ConfirmDialog", {
        attrs: { info: _vm.confirmDetails, show: _vm.showConfirm },
        on: { "show-confirm-dialog": _vm.confirmDetails.func }
      }),
      _vm._v(" "),
      _c("input", {
        ref: "uploader",
        staticClass: "d-none",
        attrs: { type: "file" },
        on: { change: _vm.onFileChanged }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }