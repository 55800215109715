import axios from 'axios'
import { globals } from '@/plugins/global'

export const filesService = {
  getFilesData
}

const requestUrl = process.env.BASE_URL + 'src/assets/json/'

function getFilesData (params) {
  if (process.env.NODE_ENV === 'development') {
    var request
    switch (params.path) {
      case 'img/markers/places':
        request = axios.get(`${requestUrl}/files_place_markers_data.json`)
        break
      case 'data/user/places':
        request = axios.get(`${requestUrl}/files_place_markers_data.json`)
        break
    }
    return request
  }
  return axios.post('/func/fn_files.php', globals.convertJsonToFormData(params),
    { baseURL: '' })
}
