<template>
  <v-dialog
    persistent
    v-model="show"
    hide-overlay
    no-click-animation
    max-width="370"
    width="auto"
  >
    <!--  -->
    <v-card outlined>
      <v-toolbar color="" dense elevation="0">
        <v-toolbar-title class="text-subtitle-2">{{
          $t("MARKER_PROPERTIES")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn plain icon x-small @click="closeDialog">
          <v-icon dense>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container fluid>
        <validation-observer ref="observer">
          <v-row>
            <v-col cols="12"
              ><validation-provider
                v-slot="{ errors }"
                rules="requiredPlaceName"
              >
                <div class="mb-2 d-flex flex-row">
                  <span class="text-body-2 mr-1">{{ $t("NAME") }}</span>

                  <FormTooltip>
                    <template v-slot:tooltip-trigger>
                      <v-btn x-small color="primary lighten-1" text icon>
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:tooltip-content
                      >{{ $t("NAME") }}
                    </template>
                  </FormTooltip>
                </div>
                <v-text-field
                  v-model="fields.name"
                  :error-messages="errors"
                  :label="$t('NAME')"
                  color="grey"
                  hide-details="auto"
                  solo
                  dense
                  outlined
                  required
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
              ><validation-provider v-slot="{ errors }">
                <div class="mb-2 d-flex flex-row">
                  <span class="text-body-2 mr-1">{{ $t("DESCRIPTION") }}</span>

                  <FormTooltip>
                    <template v-slot:tooltip-trigger>
                      <v-btn x-small color="primary lighten-1" text icon>
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:tooltip-content
                      >{{ $t("DESCRIPTION") }}
                    </template>
                  </FormTooltip>
                </div>
                <v-textarea
                  v-model="fields.description"
                  :label="$t('DESCRIPTION')"
                  :error-messages="errors"
                  required
                  rows="3"
                  color="grey"
                  hide-details="auto"
                  solo
                  dense
                  outlined
                ></v-textarea
              ></validation-provider>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12"
              ><validation-provider v-slot="{ errors }">
                <div class="mb-2 d-flex flex-row">
                  <span class="text-body-2 mr-1">{{ $t("GROUP") }}</span>

                  <FormTooltip>
                    <template v-slot:tooltip-trigger>
                      <v-btn x-small color="primary lighten-1" text icon>
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:tooltip-content
                      >{{ $t("GROUP") }}
                    </template>
                  </FormTooltip>
                </div>
                <v-autocomplete
                  menu-props="dark"
                  item-color="#272727"
                  required
                  v-model="fields.group"
                  :label="$t('GROUP')"
                  :items="placeGroupItems"
                  item-text="text"
                  item-value="value"
                  solo
                  color="grey"
                  dense
                  hide-details="auto"
                  outlined
                  :error-messages="errors"
                ></v-autocomplete>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12"
              ><v-checkbox
                color="primary"
                hide-details="auto"
                v-model="fields.visible"
                :label="$t('MARKER_VISIBLE')"
                required
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="" cols="2">
              <v-img
                class=""
                width="32"
                height="32"
                :src="((this.placeDraft.action === 'edit') ? `${publicPath}assets/`  : `${publicPath}assets/img/markers/places/`) + fields.image "
              ></v-img>
            </v-col>

            <v-col class="" cols="10">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="#5A5A5A" small v-bind="attrs" v-on="on">
                    Choose marker
                  </v-btn>
                </template>
                <v-sheet dark max-width="300px" id="scroll-marker-images">
                  <div
                    class="
                      d-flex
                      flex-row flex-wrap
                      justify-center
                      marker-images-container
                    "
                  >
                    <div
                      v-for="(m, index) in filesPlaceMarkers.markers"
                      :key="index"
                      :class="
                        `${publicPath}assets/` + fields.image ==
                        `${publicPath}assets/img/markers/places/` + m
                          ? 'selected'
                          : ''
                      "
                    >
                      <v-img
                        @click="selectPlaceMarker(m)"
                        class="mx-auto"
                        width="32"
                        height="32"
                        :src="`${publicPath}assets/img/markers/places/` + m"
                      ></v-img>
                    </div>
                  </div>
                </v-sheet>
              </v-menu>

            </v-col>
          </v-row>
          <v-row class="">
            <v-col class="" cols="6">
              <validation-provider
                rules="requiredPlaceMarkerPoints"
                ref="latitude"
                v-slot="{ validate, errors }"
                name="latitude"
              >
                <div class="mb-2 d-flex flex-row">
                  <span class="text-body-2 mr-1">{{ $t("LATITUDE") }}</span>

                  <FormTooltip>
                    <template v-slot:tooltip-trigger>
                      <v-btn x-small color="primary lighten-1" text icon>
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:tooltip-content
                      >{{ $t("LATITUDE") }}
                    </template>
                  </FormTooltip>
                </div>
                <v-text-field
                  solo
                  dense
                  outlined
                  required
                  readonly
                  :value="
                    placeDraft.fields.hasOwnProperty('lat')
                      ? placeDraft.fields.lat.toFixed(6)
                      : ''
                  "
                  color="#3C3C3C"
                  :label="$t('LATITUDE')"
                  :error="errors.length >= 1"
                  @change="validate"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col class="" cols="6">
              <validation-provider
                rules="requiredPlaceMarkerPoints"
                ref="longitude"
                v-slot="{ validate, errors }"
                name="longitude"
              >
                <div class="mb-2 d-flex flex-row">
                  <span class="text-body-2 mr-1">{{ $t("LONGITUDE") }}</span>

                  <FormTooltip>
                    <template v-slot:tooltip-trigger>
                      <v-btn x-small color="primary lighten-1" text icon>
                        <v-icon>mdi-information-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:tooltip-content
                      >{{ $t("LONGITUDE") }}
                    </template>
                  </FormTooltip>
                </div>
                <v-text-field
                  solo
                  dense
                  outlined
                  required
                  readonly
                  :value="
                    placeDraft.fields.hasOwnProperty('lng')
                      ? placeDraft.fields.lng.toFixed(6)
                      : ''
                  "
                  color="#3C3C3C"
                  :label="$t('LONGITUDE')"
                  :error="errors.length >= 1"
                  @change="validate"
                ></v-text-field>
              </validation-provider>
            </v-col>
          </v-row>

          <v-row class="mb-3 justify-center flex-column flex-sm-row d-flex">
            <v-btn
              @click="closeDialog"
              color="primary"
              outlined
              class="mr-sm-2 mb-2"
              max-width="50%"
              >Cancel</v-btn
            >
            <v-btn @click="save" color="primary" max-width="50%">Save</v-btn>
          </v-row>
        </validation-observer>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import FormTooltip from '@/components/includes/FormTooltip.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormTooltip
  },
  props: {
    show: Boolean
  },
  data () {
    return {
      fields: {
        image: 'pin-1.svg',
        name: '',
        description: '',
        group: '',
        visible: true
      }
    }
  },
  computed: {
    ...mapGetters({
      message: 'messages/message',
      // placeGroups: "places/placeGroups",
      placeGroupItems: 'places/placeGroupItems',
      filesPlaceMarkers: 'files/filesPlaceMarkers',
      placeDraft: 'places/placeDraft'
    })
    // groups: function () {
    //   const result = [];
    //   Object.keys(this.placeGroups).forEach((key) => {
    //     result.push({
    //       id: key,
    //       name: this.placeGroups[key].name,
    //     });
    //   });
    //   return result;
    // },
  },
  watch: {
    show: function () {
      try {
        if (this.show) {
          if (this.placeDraft.action === 'edit') {
            this.fields.name = this.placeDraft.fields.name
            this.fields.image = this.placeDraft.fields.image
            this.fields.description = this.placeDraft.fields.description
            this.fields.group = this.placeDraft.fields.group
            this.fields.visible = this.placeDraft.fields.visible
          }
        } else {
          this.fields.name = ''
          this.fields.image = 'pin-1.svg'
          this.fields.description = ''
          this.fields.group = ''
          this.fields.visible = true
        }
      } catch (error) {
        this.handleError(error)
      }
    }
  },
  created () {
    try {
      this.initializeFilesData()
      this.fetchPlacesData({ cmd: 'load_marker_data' })
      this.fetchPlacesData({ cmd: 'load_place_group_data' })
    } catch (error) {
      this.handleError(error)
    }
  },
  methods: {
    ...mapActions({
      initializeFilesData: 'files/initializeFilesData',
      updateMapPlacesDraft: 'places/updateMapPlacesDraft',
      clearMapPlacesDraft: 'places/clearMapPlacesDraft',
      savePlaceObject: 'places/savePlaceObject',
      fetchPlacesData: 'places/fetchPlacesData'
      // mockPlaceObjectAddition: "places/mockPlaceObjectAddition",
      // mockPlaceObjectUpdate: "places/mockPlaceObjectUpdate",
    }),
    selectPlaceMarker (src) {
      try {
        this.fields.image = (this.placeDraft.action === 'edit' ? 'img/markers/places/' : '') + src
        this.$log.debug('select place marker', src)
        this.updateMapPlacesDraft({ fields: this.fields })
      } catch (error) {
        this.handleError(error)
      }
    },
    async save () {
      try {
        const isValidLat = await this.$refs.latitude.validate()
        const isValidLng = await this.$refs.longitude.validate()
        this.$log.debug('validate lat lng', isValidLat.valid)
        let valid = await this.$refs.observer.validate()

        if (
          typeof this.placeDraft.fields.lat === 'undefined' ||
          !this.placeDraft.fields.lat
        ) {
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'error',
            title: this.$t('ERROR'),
            text: this.$t('PLACE_MARKER_ON_MAP_BEFORE_SAVING')
          })
          valid = false
        }

        if (valid && isValidLat.valid && isValidLng.valid) {
          this.updateMapPlacesDraft({ fields: this.fields })
          this.$log.debug(this.placeDraft)
          const response = await this.savePlaceObject(this.placeDraft)
          this.$log.debug(response)
          if (response.message === 'OK') {
            if (process.env.NODE_ENV === 'production') {
              this.fetchPlacesData({ cmd: 'load_marker_data' })
              this.$notify({
                    duration: 5000,
                    closeOnClick: false,
                type: 'success',
                title: this.$t('INFORMATION'),
                text: this.$t('CHANGES_SAVED_SUCCESSFULLY')
              })
            }
            this.closeDialog()
          } else if (response.message === 'ERROR_MARKER_LIMIT') {
            this.$notify({
                    duration: 5000,
                    closeOnClick: false,
              type: 'error',
              title: this.$t('ERROR'),
              text: this.$t('MARKER_LIMIT_IS_REACHED')
            })
          }
          // if (process.env.NODE_ENV === "development") {
          //   this.$log.debug("save marker form");
          //   // mock adding and updating of a place marker
          //   if (this.action === "add") {
          //     this.mockPlaceObjectAddition();
          //   } else {
          //     this.mockPlaceObjectUpdate();
          //   }
          // }
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    closeDialog () {
      try {
        this.clearMapPlacesDraft()
        this.$refs.observer.reset()
        this.$emit('show-place-dialog', false)
      } catch (error) {
        this.handleError(error)
      }
    }
  }
}
</script>

<style></style>
