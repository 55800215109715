import Vue from 'vue'
import { Icon } from 'leaflet'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'

import 'leaflet/dist/leaflet.css'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png').default,
  iconUrl: require('leaflet/dist/images/marker-icon.png').default,
  shadowUrl: require('leaflet/dist/images/marker-shadow.png').default
})

Vue.component('l-map', LMap)
Vue.component('l-tile-layer', LTileLayer)
Vue.component('l-marker', LMarker)

/*
 * L.TileLayer is used for standard xyz-numbered tile layers.
 */
