var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tippy",
    {
      attrs: {
        interactive: "",
        "animate-fill": false,
        placement: "bottom",
        distant: "7",
        theme: "dark",
        animation: "scale",
        arrow: ""
      },
      scopedSlots: _vm._u(
        [
          {
            key: "trigger",
            fn: function() {
              return [_vm._t("tooltip-trigger")]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [_vm._v(" "), _c("v-container", [_vm._t("tooltip-content")], 2)],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }