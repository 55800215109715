import Vue from 'vue'

import { mapFunctions } from '@/plugins/map'
import { config } from '@/plugins/global'

const mutations = {
  SET_OBJECTS: (state, objData) => {
    // const data = state.objects_data // has the updated values for settings, follow, visible, locate, selected, layers
    const newObjs = {}
    for (const [imei] of Object.entries(state.objects_new)) {
      const obj = state.objects_new[imei]
      if (Object.prototype.hasOwnProperty.call(objData, imei)) {
        const formattedObj = formatObject(objData[imei], true)
        const updatedData = (({ follow, visible, locate, selected, layers, ...o }) => o)(formattedObj) // remove b and c
        const newObj = {
          ...obj,
          ...updatedData
        }
        newObjs[imei] = newObj
      } else {
        newObjs[imei] = obj
      }
    }
    state.objects_new = {}
    state.objects_new = newObjs
  },

  SET_OBJECT_PROPERTY: (state, params) => {
    const id = params.id
    const prop = params.property_name
    const val = params.value

    // Object.keys(state.objects_new).forEach((key) => {
    //   if (key === id) {
    state.objects_new[id][prop] = val
    //   }
    // })
  },

  // initialize objects
  INITIALIZE_OBJECTS: (state, data) => {
    try {
      const objects = data.objects
      const settings = data.settings
      const toggles = data.toggles
      const user = data.user

      const newObj = {}
      const newObjLayers = state.object_layers

      // if (!Object.keys(newObjLayers['markers']).length) {

      newObjLayers.markers = []
      // newObjLayers['markers'] = L.markerClusterGroup();
      newObjLayers.markers = mapFunctions.createCluster(
        'objects',
        user.map_is,
        config.map_min_zoom,
        config.map_max_zoom,
        toggles.clusters
      )
      // }

      Object.keys(objects).forEach(function (imei) {
        if (Object.prototype.hasOwnProperty.call(state.objects_new, imei)) {
          Vue.$log.debug('existing')
          const formattedObj = formatObject(objects[imei], true)
          const updatedData = (({ settings, follow, visible, locate, selected, layers, ...o }) => o)(formattedObj) // remove b and c

          const newData = {
            ...state.objects_new[imei],
            ...updatedData
          }
          newObj[imei] = newData
        } else {
          Vue.$log.debug('new', config)
          newObj[imei] = formatObject(objects[imei], true)
        }
        newObj[imei].settings = settings[imei]
        const obj = newObj[imei]

        const details = {
          name: obj.name,
          icon: 'img/markers/' + obj.settings.icon + '.png',
          lat: obj.data.lat,
          lng: obj.data.lng,
          angle: obj.data.angle
        }

        let marker = mapFunctions.generateMarker(details, user.map_is)

        const options = {
          permanent: true,
          offset: [20 * user.map_is, 0],
          direction: 'right'
        }
        const content =
      imei +
      ' - ' +
      obj.name +
      ' (' +
      obj.data.speed +
      ' kph)'

        marker = mapFunctions.bindTooltip(marker, content, options)

        newObjLayers.markers.addLayer(marker)
      })
      Vue.$log.debug(newObjLayers)

      state.objects_new = {}
      state.objects_new = newObj

      state.object_layers = {}
      state.object_layers = newObjLayers

      Vue.$log.debug('objects_new', state.objects_new)
    } catch (error) {
      // console.log(error)
    }
  },

  SET_HISTORY_FILTER: (state, data) => {
    state.history.filter = data
  },

  MOCK_OBJECT_ADDITION: (state, data) => {
    Vue.$log.debug('add obj mock', data)
    // Vue.$log.debug(state.objects['123']);
    const newObj = state.objects['123']
    const objects = state.objects
    newObj.name = data.name
    objects[data.imei] = newObj
    state.objects = []
    state.objects = objects
    Vue.$log.debug(state.objects)

    const newObjSettings = state.object_settings['123']
    const objectSettings = state.object_settings
    objectSettings[data.imei] = newObjSettings
    state.object_settings = []
    state.object_settings = objectSettings
    Vue.$log.debug(state.object_settings)

    // let key = 0
    // const objNew = state.objects_new
    // let objKeys = keys(objNew)

    // if (objKeys.length >= 1) {
    //   key = objKeys.reduce((a, b) => { return Math.max(a, b) })
    // }
    // key = parseInt(key) + 1

    // objNew[key] =

    // objects_new[]
    // update content of json file

    const objNew = state.objects_new
    objNew[data.imei] = state.objects_new['123']
    objNew[data.imei].settings.name = data.name
    state.objects_new = []
    state.objects_new = objNew

    // Vue.$log.debug('objects_new',state.objects_new)
  },

  MOCK_OBJECT_UPDATE: (state, data) => {
    // Vue.$log.debug(state.objects['123']);
    Vue.$log.debug('update obj mock')
    // update content of json file
  },
  MOCK_OBJECT_DELETION: (state, data) => {
    const id = data.imei
    const objects = state.objects
    const objectSettings = state.object_settings

    delete objects[id]
    delete objectSettings[id]

    state.objects = []
    state.object_settings = []

    state.objects = objects
    state.object_settings = objectSettings

    const objectsNew = state.objects_new
    delete objectsNew[id]

    state.objects_new = objectsNew

    Vue.$log.debug('objects_new', state.objects_new)
  }

}

function formatObject (obj, initialize) {
  var lookup = {
    v: 'visible',
    f: 'follow',
    s: 'selected',
    evt: 'event',
    evtac: 'event_arrow_color',
    evtohc: 'event_ohc_color',
    a: 'address', // geocoded
    l: 'layers',
    st: 'status',
    ststr: 'status_string',
    p: 'protocol', // 2019 etc
    cn: 'connection',
    o: 'odometer',
    eh: 'engine_hours',
    sr: 'service'
  }

  const formattedObj = []

  for (var k in lookup) {
    var v = lookup[k]
    if (typeof v === 'undefined') {
      // skip it.
      continue
    }

    // if (initialize === false && (v === 'visible' || v === 'follow' || v === 'selected')) {
    //   // skip
    // } else {
    //   formattedObj[v] = obj[k]
    // }

    formattedObj[v] = obj[k]
  }
  formattedObj.data = {}
  if (initialize === true) {
    formattedObj.locate = false
  }

  if (
    typeof obj.d !== 'undefined' &&
    Array.isArray(obj.d) &&
    typeof obj.d[0] !== 'undefined' &&
    typeof obj.d[0][0] !== 'undefined'
  ) {
    var i = obj.d[0]
    formattedObj.data = {
      dt_server: i[0],
      dt_tracker: i[1],
      lat: i[2],
      lng: i[3],
      altitude: i[4],
      angle: i[5],
      speed: i[6],
      params: i[7]
    }
  }

  return formattedObj
}

export default mutations
