import axios from 'axios'
import { globals } from '@/plugins/global'

export const driversService = {
  inviteDriver
}

// const requestUrl = process.env.BASE_URL + 'src/assets/json/settings'

function inviteDriver (params) {
  return axios.post('/driver/v1/admin/invite_driver', globals.convertJsonToFormData(params))
}
