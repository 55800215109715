var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "link-bar mx-auto" }, [
    _c("span", {
      staticClass: "link-bar--100",
      style: _vm.signal <= 80 ? "background:none" : ""
    }),
    _vm._v(" "),
    _c("span", {
      staticClass: "link-bar--80",
      style: _vm.signal <= 60 ? "background:none" : ""
    }),
    _vm._v(" "),
    _c("span", {
      staticClass: "link-bar--60",
      style: _vm.signal <= 40 ? "background:none" : ""
    }),
    _vm._v(" "),
    _c("span", {
      staticClass: "link-bar--40",
      style: _vm.signal <= 20 ? "background:none" : ""
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }