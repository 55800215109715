import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  // markers: [],
  polylines: [],
  center: [0, 0], // for selected property
  bounds: [], // for the locate property
  // zoom: 0,
  follow: [], // for the follow property
  visible_objects: [],
  place_marker_layer: [],
  place_route_layer: [],
  place_zone_layer: [],
  toggles: {
    zones: true, // place zones
    markers: true, // place markers
    routes: true, // place routes
    trackers: true, // objects
    clusters: true,
    object_labels: true,
    fit_all: false,
    traffic: false,
    objects_visible: true, // show all objects
    object_follow: false, // follow all objects
    history: {
      route: true,
      snap: false,
      arrows: false,
      data_points: true,
      stops: true,
      events: false
    }
  },
  // polygons: [],
  raw_history: null,
  history: null,
  event_id: null,
  history_route_data: null,
  history_progress: 0

  // this.historyRouteData = this.history; // history data
  // (this.historyRouteData.layers = []),
  //   (this.historyRouteData.layers.route = null),
  //   (this.historyRouteData.layers.route_snap = null),
  //   (this.historyRouteData.layers.arrows = null),
  //   (this.historyRouteData.layers.arrows_snap = null),
  //   (this.historyRouteData.layers.stops = []),
  //   (this.historyRouteData.layers.events = []),
  //   (this.historyRouteData.layers.data_points = []),
  //   (this.historyRouteData.play = []),
  //   (this.historyRouteData.play.status = false),
  //   (this.historyRouteData.play.position = 0);
}

export default {
  state,
  getters,
  actions,
  mutations
}
