<template>
  <div class="dashboard">
    <div class="dashboard__table">
       <slot name="dashboard-table-layout"></slot>
    </div>
    <div class="dashboard__actions">

      <v-btn small class="btn-vehicle">
        Enable/Disable Vehicle
        <div>ON</div>
      </v-btn>
      <v-btn small class="btn-light-bar">
        Light Bar
        <div>OFF</div></v-btn
      >
      <v-btn small class="btn-aux-relay">
        Aux Relay 2
        <div>OFF</div></v-btn
      >
      <v-btn small class="btn-relay-settings">
        Relay Settings
        <v-img
          max-width="23"
          max-height="23"
          :src="`${publicPath}assets/img/icons/account-settings.svg`"
          class="ml-2"
        ></v-img>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tab: null
    }
  }
}
</script>

<style></style>
