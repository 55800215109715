var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        persistent: "",
        "hide-overlay": "",
        "no-click-animation": "",
        "max-width": "370",
        width: "auto"
      },
      model: {
        value: _vm.show,
        callback: function($$v) {
          _vm.show = $$v
        },
        expression: "show"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-toolbar",
            { attrs: { color: "", dense: "", elevation: "0" } },
            [
              _c("v-toolbar-title", { staticClass: "text-subtitle-2" }, [
                _vm._v(_vm._s(_vm.$t("ROUTE_PROPERTIES")))
              ]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  attrs: { plain: "", icon: "", "x-small": "" },
                  on: { click: _vm.closeDialog }
                },
                [_c("v-icon", { attrs: { dense: "" } }, [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-container",
            { attrs: { fluid: "" } },
            [
              _c(
                "validation-observer",
                { ref: "observer" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("validation-provider", {
                            attrs: { rules: "requiredPlaceName" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "mb-2 d-flex flex-row" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-body-2 mr-1" },
                                          [_vm._v(_vm._s(_vm.$t("NAME")))]
                                        ),
                                        _vm._v(" "),
                                        _c("FormTooltip", {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "tooltip-trigger",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          "x-small": "",
                                                          color:
                                                            "primary lighten-1",
                                                          text: "",
                                                          icon: ""
                                                        }
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "mdi-information-outline"
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              },
                                              {
                                                key: "tooltip-content",
                                                fn: function() {
                                                  return [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("NAME")) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-text-field", {
                                      attrs: {
                                        "error-messages": errors,
                                        label: _vm.$t("NAME"),
                                        color: "grey",
                                        "hide-details": "auto",
                                        solo: "",
                                        dense: "",
                                        outlined: "",
                                        required: ""
                                      },
                                      model: {
                                        value: _vm.fields.route_name,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.fields,
                                            "route_name",
                                            $$v
                                          )
                                        },
                                        expression: "fields.route_name"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("validation-provider", {
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "mb-2 d-flex flex-row" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-body-2 mr-1" },
                                          [_vm._v(_vm._s(_vm.$t("GROUP")))]
                                        ),
                                        _vm._v(" "),
                                        _c("FormTooltip", {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "tooltip-trigger",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          "x-small": "",
                                                          color:
                                                            "primary lighten-1",
                                                          text: "",
                                                          icon: ""
                                                        }
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "mdi-information-outline"
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              },
                                              {
                                                key: "tooltip-content",
                                                fn: function() {
                                                  return [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("GROUP")) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-autocomplete", {
                                      attrs: {
                                        "menu-props": "dark",
                                        "item-color": "#272727",
                                        required: "",
                                        label: _vm.$t("GROUP"),
                                        items: _vm.placeGroupItems,
                                        "item-text": "text",
                                        "item-value": "value",
                                        solo: "",
                                        color: "grey",
                                        dense: "",
                                        "hide-details": "auto",
                                        outlined: "",
                                        "error-messages": errors
                                      },
                                      model: {
                                        value: _vm.fields.group,
                                        callback: function($$v) {
                                          _vm.$set(_vm.fields, "group", $$v)
                                        },
                                        expression: "fields.group"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c("v-col", { attrs: { cols: "12" } }, [
                        _c(
                          "div",
                          { staticClass: "mb-2 d-flex flex-row" },
                          [
                            _c("span", { staticClass: "text-body-2 mr-1" }, [
                              _vm._v("Route Color")
                            ]),
                            _vm._v(" "),
                            _c("FormTooltip", {
                              scopedSlots: _vm._u([
                                {
                                  key: "tooltip-trigger",
                                  fn: function() {
                                    return [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            "x-small": "",
                                            color: "primary lighten-1",
                                            text: "",
                                            icon: ""
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-information-outline")
                                          ])
                                        ],
                                        1
                                      )
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "tooltip-content",
                                  fn: function() {
                                    return [_vm._v("Route Color ")]
                                  },
                                  proxy: true
                                }
                              ])
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex" },
                          [
                            _c(
                              "v-menu",
                              {
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mr-2",
                                                attrs: {
                                                  label: "Route Color",
                                                  solo: "",
                                                  color: "grey",
                                                  dense: "",
                                                  "hide-details": "auto",
                                                  outlined: "",
                                                  required: "",
                                                  "append-icon":
                                                    "mdi-eyedropper-variant"
                                                },
                                                model: {
                                                  value: _vm.fields.route_color,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.fields,
                                                      "route_color",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "fields.route_color"
                                                }
                                              },
                                              "v-text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        )
                                      ]
                                    }
                                  }
                                ])
                              },
                              [
                                _vm._v(" "),
                                _c("div", [
                                  _c(
                                    "div",
                                    { staticClass: "color-picker-container" },
                                    [
                                      _c("v-color-picker", {
                                        attrs: {
                                          dark: "",
                                          "dot-size": "15",
                                          "swatches-max-height": "250",
                                          "hide-inputs": "",
                                          "hide-mode-switch": ""
                                        },
                                        model: {
                                          value: _vm.fields.route_color,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.fields,
                                              "route_color",
                                              $$v
                                            )
                                          },
                                          expression: "fields.route_color"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ])
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "v-sheet",
                              {
                                staticClass: "pa-2",
                                attrs: {
                                  width: "40",
                                  height: "40",
                                  light: "",
                                  color: _vm.fields.route_color
                                }
                              },
                              [_c("pre", [_vm._v(" ")])]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              color: "primary",
                              "hide-details": "auto",
                              label: _vm.$t("ROUTE_VISIBLE")
                            },
                            model: {
                              value: _vm.fields.route_visible,
                              callback: function($$v) {
                                _vm.$set(_vm.fields, "route_visible", $$v)
                              },
                              expression: "fields.route_visible"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              color: "primary",
                              "hide-details": "auto",
                              label: _vm.$t("NAME_VISIBLE")
                            },
                            model: {
                              value: _vm.fields.route_name_visible,
                              callback: function($$v) {
                                _vm.$set(_vm.fields, "route_name_visible", $$v)
                              },
                              expression: "fields.route_name_visible"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("validation-provider", {
                            attrs: {
                              rules: "number|greaterThan:0",
                              name: _vm.$t("DEVIATION")
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "mb-2 d-flex flex-row" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-body-2 mr-1" },
                                          [_vm._v(_vm._s(_vm.$t("DEVIATION")))]
                                        ),
                                        _vm._v(" "),
                                        _c("FormTooltip", {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "tooltip-trigger",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          "x-small": "",
                                                          color:
                                                            "primary lighten-1",
                                                          text: "",
                                                          icon: ""
                                                        }
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "mdi-information-outline"
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              },
                                              {
                                                key: "tooltip-content",
                                                fn: function() {
                                                  return [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("DEVIATION")
                                                      ) + "\n                  "
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-text-field", {
                                      attrs: {
                                        "error-messages": errors,
                                        label: _vm.$t("DEVIATION"),
                                        color: "grey",
                                        "hide-details": "auto",
                                        solo: "",
                                        dense: "",
                                        outlined: "",
                                        required: ""
                                      },
                                      model: {
                                        value: _vm.fields.route_deviation,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.fields,
                                            "route_deviation",
                                            $$v
                                          )
                                        },
                                        expression: "fields.route_deviation"
                                      }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("validation-provider", {
                            ref: "routePoints",
                            attrs: {
                              rules:
                                "requiredPlacePoints|minPoints:2|maxPoints:200",
                              name: "Route Points"
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "mb-2 d-flex flex-row" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-body-2 mr-1" },
                                          [_vm._v("Route Points")]
                                        ),
                                        _vm._v(" "),
                                        _c("FormTooltip", {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "tooltip-trigger",
                                                fn: function() {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          "x-small": "",
                                                          color:
                                                            "primary lighten-1",
                                                          text: "",
                                                          icon: ""
                                                        }
                                                      },
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "mdi-information-outline"
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                },
                                                proxy: true
                                              },
                                              {
                                                key: "tooltip-content",
                                                fn: function() {
                                                  return [
                                                    _vm._v("Route Points ")
                                                  ]
                                                },
                                                proxy: true
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        })
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("v-text-field", {
                                      attrs: {
                                        value: _vm.placeDraft.fields.hasOwnProperty(
                                          "route_points"
                                        )
                                          ? _vm.placeDraft.fields.route_points
                                          : "",
                                        "error-messages": errors,
                                        label: "Route Points",
                                        color: "grey",
                                        "hide-details": "auto",
                                        solo: "",
                                        dense: "",
                                        outlined: "",
                                        required: "",
                                        readonly: ""
                                      },
                                      on: { change: _vm.validateRoutePoints }
                                    })
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass:
                        "mb-3 justify-center flex-column flex-sm-row d-flex"
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-sm-2 mb-2",
                          attrs: {
                            color: "primary",
                            outlined: "",
                            "max-width": "50%"
                          },
                          on: { click: _vm.closeDialog }
                        },
                        [_vm._v("Cancel")]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", "max-width": "50%" },
                          on: { click: _vm.save }
                        },
                        [_vm._v("Save")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }