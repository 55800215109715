import { mapGetters, mapActions } from 'vuex'
import StackdriverErrorReporter from 'stackdriver-errors-js'
import Vue from 'vue'
import L, { latLng } from 'leaflet'
import moment from "moment";

import { globals } from "@/plugins/global";
let errorHandler
if (process.env.NODE_ENV === 'production') {
  errorHandler = new StackdriverErrorReporter()
  errorHandler.start({
    key: 'AIzaSyBhegMq_CFr9dZ1g0Eam0vmhgabIVQyfc0',
    projectId: 'gps-tracking-219523'
    // Other optional arguments can be supplied, see above.
  })
} else {
  errorHandler = { report: Vue.$log.error }
}

export const globalMixin = {
  data: function () {
    return {
      publicPath: process.env.ASSET_PATH,
      config: {
        map_refresh: 3.7,
        map_min_zoom: 3,
        map_max_zoom: 18,
        event_refresh: 30,
        cmd_status_refresh: 60,
        img_refresh: 60,
        chat_refresh: 10,
        billing_refresh: 60,
        session_check: 30
      },
      mapLayers: {
        realtime: {}
      },

      windowSize: {
        x: 0,
        y: 0
      },
      contentHeight: 0
      // objectsData: []
    }
  },
  methods: {
    ...mapActions({
      setMessage: 'messages/setMessage',
      setProgress: 'setProgress',
      checkSession: 'auth/checkSession',
      searchAddress: 'places/searchAddress',
      saveUISettings: 'settings/saveUISettings',
    }),
    handleError (e, message = '') {
      try {
        if (!message) {
          if (typeof e.response !== 'undefined') {
            if (
              typeof e.response.data === 'object' &&
              Object.prototype.hasOwnProperty.call(e.response.data, 'error')
            ) {
              message = e.response.data.error
            }
          }
        }

        if (!message) {
          message = 'Sorry. Something went wrong!'
        }

        this.$notify({
          type: 'error',
          title: this.$t('ERROR'),
          text: message,
          duration: 5000,
          closeOnClick: false
        });
        errorHandler.report(e)
      } catch (error) {
        this.$log.debug(error)
        this.$log.error(e)
        this.$log.error(error)
      }
    },
    onResize () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
      const footer = document.querySelector('.v-data-table .v-data-footer')

      const toolbar = document.querySelector('.v-data-table .v-toolbar')

      const appBar = document.querySelector('.v-app-bar')

      const tabs = document.querySelector('.v-tabs')

      let height = 0

      if (footer != null) {
        height += footer.offsetHeight
      }
      if (appBar != null) {
        height += appBar.offsetHeight
      }
      if (toolbar != null) {
        height += toolbar.offsetHeight
      }
      if (tabs != null) {
        height += tabs.offsetHeight
      }

      this.contentHeight = this.windowSize.y - height
    },

    async getAddress (params) {
      try {
        let addr = null
        addr = await this.searchAddress(params).catch((error) => {
          if (error.response.status === 404 && typeof error.response !== 'undefined') {
            if (
              typeof error.response.data === 'object' && JSON.stringify(error.response.data) === '[]' &&
              !error.response.data.length
            ) {
              if (params.by === 'latlng') {
                addr = ['']
              } else {
                addr = {
                  address: '',
                  lat: params.lat,
                  lng: params.lng
                }
              }
            }
          } else {
            this.handleError(error)
          }
        })
        // this.$log.debug(addr, 'address', {
        //   by: 'latlng',
        //   lat: params.lat,
        //   lng: params.lng
        // })
        if (params.by === 'latlng') {
          return addr === null ? '' : addr.shift()
        } else {
          return addr
        }
      } catch (error) {
        this.handleError(error)
        return ''
      }
    },

    paginated (paginationDetails) {
      this.saveUISettings({ prop: 'table_default_rows', value: paginationDetails.itemsPerPage })
    },

    convertToISOFormat(date) {
      return moment(date).utc(true).toISOString();
    },

    convertUTCToLocalTimezone(date, format = "") {
      if (!format) {
        format = "YYYY-MM-DD HH:mm:ss"
      }
      return moment(date).utc(true).local().format(format);
    },

    convertLocalTimezoneToUTC(date, iso = false, format = "") {
      if (!format) {
        format = "YYYY-MM-DD HH:mm:ss"
      }
      
      if (iso) {
        return moment(date).utc().toISOString();
      }
      return moment(date).utc().format(format);
    },

    // generateEventDescription(event) {
    //   try {
    //     if (event) {
    //       const data = {};

    //       let sensorArray = Object.keys(
    //         this.objectsNew[event.imei].settings.sensors
    //       ).map((k) => this.objectsNew[event.imei].settings.sensors[k]);
    //       sensorArray = globals.sortArrayByElement(sensorArray, "name");
    //       let sensorString = "";
    //       sensorArray.forEach((sensor) => {
    //         if (sensor.popup === "true") {
    //           let sensorValue = [];
    //           if (sensor.type === "fuelsumup") {
    //             sensorValue = globals.getSensorValueFuelLevelSumUp(
    //               event.imei,
    //               event.params,
    //               sensor
    //             );
    //           } else {
    //             sensorValue = globals.getSensorValue(event.params, sensor);
    //           }

    //           sensorString +=
    //             "<tr><td><strong>" +
    //             sensor.name +
    //             ":</strong></td><td>" +
    //             sensorValue.value_full +
    //             "</td></tr>";
    //         }
    //       });

    //       const position = globals.urlPosition(event.lat, event.lng);

    //       var tableString =
    //         "<table><tr><td><strong>" +
    //         this.$t("OBJECT") +
    //         ":</strong></td><td>" +
    //         this.objectsNew[event.imei].settings.name +
    //         "</td></tr><tr><td><strong>" +
    //         this.$t("ADDRESS") +
    //         ":</strong></td><td>" +
    //         event.location +
    //         "</td></tr><tr><td><strong>" +
    //         this.$t("POSITION") +
    //         ":</strong></td><td>" +
    //         position +
    //         "</td></tr><tr><td><strong>" +
    //         this.$t("ANGLE") +
    //         ":</strong></td><td>" +
    //         event.angle +
    //         " &deg;</td></tr><tr><td><strong>" +
    //         this.$t("SPEED") +
    //         ":</strong></td><td>" +
    //         event.speed +
    //         " " +
    //         this.$t("UNIT_SPEED") +
    //         "</td></tr><tr><td><strong>" +
    //         this.$t("TIME") +
    //         ":</strong></td><td>" +
    //         this.convertUTCToLocalTimezone(event.dt_server) +
    //         "</td></tr>";
    //       const odometer = this.getObjectOdometer(event.imei, event.params);

    //       if (odometer !== -1) {
    //         tableString +=
    //           "<tr><td><strong>" +
    //           this.$t("ODOMETER") +
    //           ":</strong></td><td>" +
    //           odometer +
    //           " " +
    //           this.$t("UNIT_DISTANCE") +
    //           "</td></tr>";
    //       }
    //       const engineHours = this.getObjectEngineHours(
    //         event.imei,
    //         event.params
    //       );

    //       if (engineHours !== -1) {
    //         tableString +=
    //           "<tr><td><strong>" +
    //           this.$t("ENGINE_HOURS") +
    //           ":</strong></td><td>" +
    //           engineHours +
    //           "</td></tr>";
    //       }
    //       tableString += sensorString += "</table>";

    //       const div = document.createElement("div");
    //       div.innerHTML = `<br>${tableString}<br>`;

    //       const button = document.createElement("button");
    //       button.className = "v-btn v-btn--outlined theme--dark v-size--default primary--text"
          
    //       const span = document.createElement("span");
    //       span.className = "v-btn__content";
    //       span.innerHTML = "Request Footage";
          
    //       button.appendChild(span)

    //       button.onclick = () => {
    //         this.requestVideo(event.imei, event.dt_server, event.event_id);
    //       }

    //       div.appendChild(button);

    //       return div;
    //     } else {
    //     }
    //   } catch (error) {
    //     this.handleError(error);
    //   }
    // },

  },
  computed: {
    ...mapGetters({
      checkPrivilege: 'settings/checkPrivilege',
      uiSettings: 'settings/uiSettings',
      objectsNew: 'objects/objectsNew',
      getObjectByName: "objects/getObjectByName",
      server: 'settings/server',
      user: 'settings/user',

      getObjectOdometer: "objects/getObjectOdometer",
      getObjectEngineHours: "objects/getObjectEngineHours",
      // objectsData: 'objects/objectsData',
      // progress: 'progress'
    }),
    isMobileView: function () {
      let isMobile = false
      if (
        this.$vuetify.breakpoint.mobile ||
        this.$router.currentRoute.name.indexOf('mobile') >= 0
      ) {
        isMobile = true
      }
      return isMobile
    },
    defaultItemsPerPage: function () {
      return this.uiSettings.table_default_rows
    },
    videoRequestPollingState: function () {
      return this.testGet
    },
    defaultCenter: function () {
      let lat = 0
      let lng = 0
      try {
        if (this.server.map_lat && this.server.map_zoom) {
          lat = this.server.map_lat
          lng = this.server.map_lng
        }
      } catch (error) {
        this.handleError(error)
      }
      return latLng(lat, lng)
    },
    defaultZoom: function () {
      try {
        if (this.server.map_zoom) {
          return parseInt(this.server.map_zoom)
        }
      } catch (error) {
        this.handleError(error)
      }
      return 0
    },
  },
  watch: {
  }
}
