import axios from 'axios'
import { globals } from '@/plugins/global'

export const settingsService = {
  getLanguageTranslation,
  // getLanguageList,
  getSiteSettings,
  saveSettings,
  checkSession,
  getFirebaseToken,
  authenticate,
  fetchCsrfToken,
  verifyUser,
  verifyUserCode,
  register
}

const requestUrl = process.env.BASE_URL + 'src/assets/json/settings'

function getFirebaseToken (params) {
  return axios.post('/driver/v1/admin/firebase_id')
}

function getLanguageTranslation (lang) {
  if (process.env.NODE_ENV === 'development') {
    const lngRequestUrl = process.env.BASE_URL + 'src/assets/lang'
    return axios.get(`${lngRequestUrl}/${lang}.json`)
  }

  return axios.get('/language', {params: { cmd: 'load_language', language: lang }})
}

// function getLanguageList() {
//   if (process.env.NODE_ENV === 'development') {
//     return axios.get(`${requestUrl}/lang.json`)
//   }
//   return axios.get('/func/fn_lng.php', {params: { cmd: 'load_language_list' }})
// }

function getSiteSettings (cmd) {
  if (process.env.NODE_ENV === 'development') {
    if (cmd === 'load_user_data') {
      return axios.get(`${requestUrl}/user_data.json`)
    } else if (cmd === 'load_server_data') {
      return axios.get(`${requestUrl}/server_data.json`)
    }
  }
  if (cmd === 'load_user_data') {
    return axios.post('/settings/user', globals.convertJsonToFormData({ cmd: cmd }))
  } else if (cmd === 'load_server_data') {
    return axios.post('/settings/server', globals.convertJsonToFormData({ cmd: cmd }))
  }
}

function saveSettings (params) {
  let request
  switch (params.cmd) {
    case 'save_map':
      request = axios.post('/settings/map/save', globals.convertJsonToFormData(params))
      break
    case 'save_user_language':
      request = axios.post('/settings/language/save', globals.convertJsonToFormData(params))
      break
    case 'save_user_settings':
      request = axios.post('/settings/user/save', globals.convertJsonToFormData(params))
  }
  return request
}

function checkSession () {
  return axios.post('/session', globals.convertJsonToFormData({ cmd: 'session_check' }))
}

function authenticate (params) {
  return axios.post('/login', globals.convertJsonToFormData(params))
}
function fetchCsrfToken () {
  return axios.post('/csrf')
}

function verifyUser (params) {
  return axios.post('/user/verify', globals.convertJsonToFormData(params))
}

function verifyUserCode (params) {
  return axios.post('/user/verify_code', globals.convertJsonToFormData(params))
}

function register (params) {
  return axios.post('/register', globals.convertJsonToFormData(params))
}
