import Vue from 'vue'
import App from './App.vue'

import Notifications from 'vue-notification'

Vue.use(Notifications)
import router from './router'
import store from './store/store'
import vuetify from '@/plugins/vuetify'
import { i18n, loadLanguageAsync } from '@/plugins/i18n'
import { Datetime } from 'vue-datetime'
import VueTippy, { TippyComponent } from 'vue-tippy'

import videoPlayer from 'vue-videojs7'
import axios from 'axios'

// plugins
import '@/plugins/leaflet'
import '@/plugins/logger'
import '@/plugins/vee-validate'
import '@/plugins/firebase'
import 'leaflet-plugins/layer/tile/Bing.js'
import 'leaflet-plugins/layer/tile/Bing.addon.applyMaxNativeZoom.js'
import 'leaflet-plugins/layer/tile/Yandex.js'
import 'leaflet-polylinedecorator/dist/leaflet.polylineDecorator.js'
import 'leaflet-routing-machine/dist/leaflet-routing-machine.js'
// css
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css'
import 'vue-datetime/dist/vue-datetime.css'
import '@/assets/scss/app.scss'
import VueTelInputVuetify from 'vue-tel-input-vuetify'

import { globalMixin } from '@/mixins/globalMixin'
if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = process.env.API_URL
} else {
  axios.defaults.baseURL = '/'
}

// axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8';
// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'
axios.defaults.headers['Access-Control-Allow-Origin'] = '*'

// axios.defaults.headers['Accept'] = 'application/json';
Vue.use(require('vue-moment'))
Vue.use(VueTippy)
Vue.use(videoPlayer /* {
  options: global default videojs options,
  events: global videojs videojs events
} */)
Vue.use(VueTelInputVuetify, {
  vuetify
})
Vue.component('tippy', TippyComponent)
Vue.component('datetime', Datetime)
Vue.config.productionTip = false
Vue.prototype.$loadLanguageAsync = loadLanguageAsync
const lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'english'
Vue.prototype.$loadLanguageAsync(lang) // load default language
Vue.prototype.$map = null
Vue.prototype.$timer = {
  followMapObject: 0,
  followIndividualObject: 0,
  objectLoad: 0,
  checkVideoRequest: 0
}

Vue.prototype.$progress = {
  value: 0
}

Vue.mixin(globalMixin)

new Vue({
  store,
  router,
  vuetify,
  i18n,
  beforeCreate () {
    this.$store.commit('initialiseStore')
  },
  render: h => h(App)
}).$mount('#app')
