<template>
  <router-view></router-view>
</template>

<script>
// import { userService } from '@/services/firebase/user'
import { mapActions } from 'vuex'
export default {
  methods: {
    ...mapActions({
      fetchFirebaseToken: 'settings/fetchFirebaseToken'
    })
  },
  computed: {},
  created () {
    // this.$loadLanguageAsync("estonian"); //dont remove this
    // if (process.env.NODE_ENV === 'production') {
    //   this.fetchFirebaseToken()
    //     .then((response) => {
    //       if (response.id) {
    //         userService
    //           .signIn(response.id)
    //           .then((response) => {
    //             const user = response
    //             setInterval(() => {
    //               user
    //                 .getIdToken()
    //                 .then((response) => {})
    //                 .catch((error) => {
    //                   this.handleError(
    //                     error,
    //                     'Error refreshing firebase token'
    //                   )
    //                 })
    //             }, 3600000)
    //           })
    //           .catch((error) => {
    //             this.handleError(error)
    //           })
    //       }
    //     })
    //     .catch((error) => {
    //       this.handleError(error, 'You are not logged in. Redirecting...')
    //     })
    // }
  }
}
</script>
