<template>
  <v-app>
    <AlertMessage />
    <div
      class="d-flex flex-row fill-height justify-center align-center"
      id="login"
      :style="`background-image:url(${publicPath}assets/img/Gator_dot_net_Back_drop_ds_2x_uazvwp_c_scale,w_3198.png)`"
    >
      <v-card height="70%" width="80%">
        <v-container class="fill-height" style="max-width: 100%">
          <v-row class="fill-height pa-0">
            <v-col
              id="login-layout"
              class="d-none d-md-block"
              :style="`background-image:url(${publicPath}assets/img/Gator_dot_net_Back_drop_ds_2x_uazvwp_c_scale,w_3198.png)`"
            >
              <v-carousel
                :show-arrows="false"
                class="fill-height"
                cycle
                height="100%"
              >
                <v-carousel-item>
                  <v-row class="fill-height" align="center" justify="center">
                    <v-card dark>
                      <v-card-title class="text-h5" color="primary">
                        Gator Tracking Updates
                      </v-card-title>

                      <v-card-subtitle
                        >New Gator GKTPRO2 4G LTE GPS Tracker is now
                        available</v-card-subtitle
                      >

                      <v-card-actions>
                        <v-btn text color="primary"> Check it out here </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-row>
                </v-carousel-item>
                <v-carousel-item>
                  <v-row class="fill-height" align="center" justify="center">
                    <iframe
                      width="100%"
                      height="300px"
                      src="https://www.youtube.com/embed/wkK6e_VilKY"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </v-row>
                </v-carousel-item>
              </v-carousel>
            </v-col>
            <v-col>
              <v-container class="form-container" style="display: contents">
                <v-row class="fill-height" align="center" justify="center">
                  <v-card flat class="mx-auto" width="auto">
                    <v-img
                      class="mx-auto my-3"
                      max-width="150"
                      :src="`${publicPath}assets/img/icons/gator-logo-orange.png`"
                    ></v-img>
                    <v-tabs v-if="$router.currentRoute.name === 'login' || $router.currentRoute.name === 'register'">
                      <v-tab exact to="/mobile/login">{{ $t('LOGIN') }}</v-tab>
                      <v-tab exact to="/mobile/register">{{ $t('REGISTER') }}</v-tab>
                    </v-tabs>
                    <router-view></router-view>
                  </v-card>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
  </v-app>
</template>

<script>
import AlertMessage from '@/components/includes/AlertMessage.vue'
export default {
  components: {
    AlertMessage
  },
  data () {
    return {}
  },
  methods: {},
  created () {}
}
</script>

<style></style>
