<template>
  <v-dialog
    persistent
    v-model="show"
    hide-overlay
    no-click-animation
    max-width="370"
    width="auto"
  >
    <v-card outlined>
      <v-toolbar color="" dense elevation="0">
        <v-toolbar-title class="text-subtitle-2">{{
          info.title
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon x-small @click="closeDialog(false)">
          <v-icon dense>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container fluid>
        <v-row class="justify-center align-items-center">
          <v-col class="flex-grow-0">
            <v-icon color="error darken-1" x-large>mdi-alert-circle</v-icon>
          </v-col>
          <v-col cols="8" class="subtitle-1 d-flex align-center"
            >{{ info.message }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn class="ml-1" text @click="closeDialog(false)">
              {{ info.action ? info.action.no : "Cancel" }}
            </v-btn>
            <v-btn
              class="ml-1"
              color="error darken-1"
              @click="closeDialog(true)"
            >
              {{ info.action ? info.action.yes : "Ok" }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    info: Object,
    show: Boolean
  },
  data () {
    return {}
  },
  methods: {
    closeDialog (val) {
      this.$emit('show-confirm-dialog', val)
    }
  }
}
</script>

<style></style>
