import { objectsService } from '@/services/objects'
import Vue from 'vue'

const actions = {

  async initializeObjectData ({ commit, rootState, rootGetters }) {
    const settingsReq = objectsService.getObjectSettings()
    const objectsReq = objectsService.getObjects()
    return await Promise.all([settingsReq, objectsReq])
      .then(([settings, obj]) => {
        Vue.$log.debug('initializeObjectData', 'success')
        const toggles = rootGetters['map/toggles']
        const user = rootGetters['settings/user']

        commit('INITIALIZE_OBJECTS', {
          objects: obj.data,
          settings: settings.data,
          toggles: toggles,
          user: user
        })
        return obj.data
      })
      .catch(error => {
        Vue.$log.debug('initializeObjectData', error)
        throw error
      })
  },
  async fetchObjectData ({ commit }, params) {
    return await objectsService
      .getObjects(params)
      .then(function (response) {
        // Vue.$log.debug('fetchObjectData', 'success')

        commit('SET_OBJECTS', response.data)

        return response.data
      })
      .catch(function (error) {
        Vue.$log.debug('fetchObjectData', error)
        throw error
      })
  },

  async followIndividualObject ({ commit }, params) {
    return await objectsService
      .getObjects(params)
      .then(function (response) {
        // Vue.$log.debug('followIndividualObject', 'success')
        commit('SET_OBJECTS', response.data)
        return response.data
      })
      .catch(function (error) {
        Vue.$log.debug('followIndividualObject', error)
        throw error
      })
  },

  setObjectProperty ({ commit }, params) {
    Vue.$log.debug('setObjectProperty', params)
    commit('SET_OBJECT_PROPERTY', params)
  },

  async saveObject ({ commit }, params) {
    if (process.env.NODE_ENV === 'production') {
      return await objectsService.saveObject(params)
        .then(function (response) {
          Vue.$log.debug('saveObject', 'success', response)
          return response.data
        })
        .catch(function (error) {
          Vue.$log.debug('saveObject' + error)
          throw error
        })
    } else {
      const mockResponses = [
        'OK',
        'ERROR_IMEI_EXISTS',
        'ERROR_OBJECT_LIMIT',
        'TDJ_RESTRICTED_IMEI'
      ]
      const response = mockResponses[Math.floor((Math.random() * mockResponses.length))]
      if (params.cmd === 'add_object') {
        if (response === 'OK') {
          commit('MOCK_OBJECT_ADDITION', params)
        }
      } else {
        commit('MOCK_OBJECT_UPDATE', params)
      }

      return response

      // update content of json file
      // return
    }
  },

  async removeObject ({ commit }, params) {
    if (process.env.NODE_ENV === 'production') {
      return await objectsService.removeObject(params)
        .then(function (response) {
          Vue.$log.debug('removeObject', 'success', response)
          return response.data
        })
        .catch(function (error) {
          Vue.$log.debug('removeObject' + error)
          throw error
        })
    } else {
      if (params.cmd === 'delete_object') {
        commit('MOCK_OBJECT_DELETION', params)
      }
      return 'OK'
    }
  },
  setHistoryFilter ({ commit }, params) {
    commit('SET_HISTORY_FILTER', params)
    return true
  },
  async fetchObjectRouteHistory ({ commit }, params) {
    // if (process.env.NODE_ENV === 'development') {
    //   if (params.imei !== '123' && params.imei !== '864431044831123') {
    //     return {};
    //   }
    // }
    Vue.$log.debug('fetchObjectRouteHistory action', params)
    return await objectsService
      .getObjectRouteHistory(params)
      .then(function (response) {
        Vue.$log.debug('getObjectRouteHistory action', 'success')
        return response.data
        // commit('FOLLOW_INDIVIDUAL_OBJECT', response.data)
      })
      .catch(function (error) {
        Vue.$log.debug('getObjectRouteHistory', error)
        throw error
      })
  },

  // async searchAddress ({ commit }, params) {
  //   if (process.env.NODE_ENV === 'development') {
  //     return 'Dummy address'
  //   }

  //   return await objectsService.searchAddress(params)
  //     .then(function (response) {
  //       Vue.$log.debug('searchAddress', 'success', response)
  //       return response.data
  //     })
  //     .catch(function (error) {
  //       Vue.$log.debug('searchAddress: ' + error)
  //       throw error
  //     })
  // },

  async forceObjectSubscription ({ commit }, params) {
    Vue.$log.debug(params)
    return await objectsService.forceObjectSubscription(params)
      .then(function (response) {
        Vue.$log.debug('forceObjectSubscription', 'success', response)
        return response.data
      })
      .catch(function (error) {
        Vue.$log.debug('forceObjectSubscription' + error)
        throw error
      })
  },

  async getLiveCam ({ commit }, params) {
    if (process.env.NODE_ENV === 'development') {
      return 'assets/T700_hls_sample/m3u8_history/playlist.m3u8'
    }

    Vue.$log.debug(params)
    return await objectsService.getLiveCam(params)
      .then(function (response) {
        Vue.$log.debug('getLiveCam', 'success', response)
        return response.data
      })
      .catch(function (error) {
        Vue.$log.debug('getLiveCam' + error)
        throw error
      })
  }
}

export default actions
