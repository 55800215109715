var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "v-dialog",
        {
          attrs: {
            persistent: "",
            "hide-overlay": "",
            "no-click-animation": "",
            fullscreen: ""
          },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "v-card",
            {},
            [
              _c(
                "v-toolbar",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", "x-small": "" },
                      on: { click: _vm.closeDialog }
                    },
                    [
                      _c("v-icon", { attrs: { plain: "", dense: "" } }, [
                        _vm._v("mdi-close")
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-toolbar-title", { staticClass: "text-subtitle-2" }, [
                    _vm._v("Create geofence")
                  ]),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-toolbar-items",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", text: "" },
                          on: { click: _vm.save }
                        },
                        [_vm._v(" Save ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-container",
                {
                  staticClass: "pa-0",
                  staticStyle: { height: "calc(100vh - 48px)" },
                  attrs: { fluid: "" }
                },
                [
                  _c(
                    "editable-map",
                    {
                      ref: "map",
                      attrs: {
                        editable: "",
                        options: _vm.mapOptions,
                        maxZoom: _vm.config.map_max_zoom,
                        minZoom: _vm.config.map_min_zoom,
                        center: _vm.defaultCenter
                      },
                      on: { ready: _vm.ready }
                    },
                    [
                      _c("l-tile-layer", {
                        attrs: { url: _vm.url, attribution: _vm.attribution }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }