import axios from 'axios'
import { globals } from '@/plugins/global'

export const placesService = {
  getPlacesData,
  // getPlacesList,
  savePlaceObject,
  removePlaceObject,
  importMarkers,
  importRoutes,
  importZones,
  searchAddress
}

const requestUrl = process.env.BASE_URL + 'src/assets/json/'

function getPlacesData (params) {
  // if (process.env.NODE_ENV === 'development') {
  var request
  switch (params.cmd) {
    case 'load_marker_data':
      if (process.env.NODE_ENV === 'production') {
        request = axios.post('/places/markers/data', globals.convertJsonToFormData(params))
      } else {
        request = axios.get(`${requestUrl}/places_marker_data.json`)
      }
      break
    case 'load_place_group_data':
      if (process.env.NODE_ENV === 'production') {
        request = axios.post('/places/groups/data', globals.convertJsonToFormData(params))
      } else {
        request = axios.get(`${requestUrl}/places_group_data.json`)
      }
      break
    case 'load_route_data':
      if (process.env.NODE_ENV === 'production') {
        request = axios.post('/places/routes/data', globals.convertJsonToFormData(params))
      } else {
        request = axios.get(`${requestUrl}/places_route_data.json`)
      }
      break
    case 'load_zone_data':
      if (process.env.NODE_ENV === 'production') {
        request = axios.post('/places/zones/data', globals.convertJsonToFormData(params))
      } else {
        request = axios.get(`${requestUrl}/places_zone_data.json`)
      }
      break
    default:
      break
  }
  return request
  // }
}

// function getPlacesList (params) {
//   if (process.env.NODE_ENV === 'development') {
//     var request
//     switch (params.cmd) {
//       case 'load_marker_list':
//         request = axios.get(`${requestUrl}/places_marker_list.json`)
//         break
//       case 'load_places_group_list':
//         request = axios.get(`${requestUrl}/places_group_list.json`)
//         break
//       case 'load_route_list':
//         request = axios.get(`${requestUrl}/places_route_list.json`)
//         break
//       case 'load_zone_list':
//         request = axios.get(`${requestUrl}/places_zone_list.json`)
//         break

//       default:
//         break
//     }
//     return request
//   }
//   return axios.get('/func/fn_places.php', params)
// }

function savePlaceObject (params) {
  // return axios.post('/func/fn_places.php', globals.convertJsonToFormData(params))
  // return axios.post('/func/fn_places.php', globals.convertJsonToFormData(params))

  var request
  switch (params.cmd) {
    case 'save_marker':

      request = axios.post('/places/markers/save', globals.convertJsonToFormData(params))

      break
    case 'save_place_group':

      request = axios.post('/places/groups/save', globals.convertJsonToFormData(params))

      break
    case 'save_route':

      request = axios.post('/places/routes/save', globals.convertJsonToFormData(params))

      break
    case 'save_zone':

      request = axios.post('/places/zones/save', globals.convertJsonToFormData(params))

      break
    default:
      break
  }
  return request
}

function removePlaceObject (params) {
  // return axios.post('/func/fn_places.php', globals.convertJsonToFormData(params))

  var request
  switch (params.cmd) {
    case 'delete_marker':

      request = axios.post('/places/markers/delete', globals.convertJsonToFormData(params))

      break
    case 'delete_place_group':

      request = axios.post('/places/groups/delete', globals.convertJsonToFormData(params))

      break
    case 'delete_route':

      request = axios.post('/places/routes/delete', globals.convertJsonToFormData(params))

      break
    case 'delete_zone':

      request = axios.post('/places/zones/delete', globals.convertJsonToFormData(params))

      break
    default:
      break
  }
  return request
}

function importMarkers (params) {
  return axios.post('/places/markers/import', globals.convertJsonToFormData(params))
}

function importRoutes (params) {
  return axios.post('/places/routes/import', globals.convertJsonToFormData(params))
}

function importZones (params) {
  return axios.post('/places/zones/import', globals.convertJsonToFormData(params))
}

function searchAddress (params) {
  return axios.post('/places/search', globals.convertJsonToFormData(params))
}
