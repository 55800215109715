
const getters = {
  groups: (state) => state.groups,
  groupList: (state) => state.group_list,
  getGroupById: (state) => (id) => {
    return state.groups[id]
  },
  getGroupName: (state) => (id) => {
    if (state.groups[id]) {
      return state.groups[id].name
    }
    return ''
  }
}

export default getters
