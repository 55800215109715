var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tab-content" },
    [
      _vm.selectedObject || _vm.defaultObjectId
        ? _c("DevicesUpperSection", {
            attrs: {
              "selected-object": _vm.selectedObject
                ? _vm.selectedObject
                : _vm.defaultObjectId
            },
            on: { "selected-object": _vm.getSelectedObject }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedObject || _vm.defaultObjectId
        ? _c("DevicesLowerSection", {
            attrs: {
              "selected-object": _vm.selectedObject
                ? _vm.selectedObject
                : _vm.defaultObjectId
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }