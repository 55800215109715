<template>
  <div v-if="!isMobileView && $router.currentRoute.name !== 'live'" class="left-content left__container left-content__container">
    <!-- <div> -->
    <v-tabs
      class="secondary-tabs tracking-page-tabs"
      slider-color="#FF5100"
      background-color="#3c3c3c"
      show-arrows
      height="30px"
    >
      <v-tabs-slider color="#FF5100"></v-tabs-slider>
      <v-tab @change="handleTabChange" exact to="/tracking">
        <v-img
          max-width="9"
          :src="`${publicPath}assets/img/icons/tabs/tracking.svg`"
          class="mr-1"
        ></v-img>
        Tracking
      </v-tab>
      <v-tab @change="handleTabChange" exact to="/devices">
        <v-img
          max-width="17"
          :src="`${publicPath}assets/img/icons/tabs/devices.svg`"
          class="mr-1"
        ></v-img>
        Devices
      </v-tab>
      <v-tab @change="handleTabChange" exact to="/personnel">
        <v-img
          max-width="12"
          :src="`${publicPath}assets/img/icons/tabs/personnel.svg`"
          class="mr-1"
        ></v-img>
        Personnel
      </v-tab>
      <v-tab @change="handleTabChange" exact to="/events">
        <v-img
          max-width="12"
          :src="`${publicPath}assets/img/icons/tabs/events.svg`"
          class="mr-1"
        ></v-img>
        {{ $t("EVENTS") }}
      </v-tab>
      <v-tab @change="handleTabChange" exact to="/places">
        <v-img
          max-width="14"
          :src="`${publicPath}assets/img/icons/tabs/places.svg`"
          class="mr-1"
        ></v-img>
        {{ $t("PLACES") }}
      </v-tab>
      <v-tab @change="handleTabChange" exact to="/beacons">
        <v-img
          max-width="10"
          :src="`${publicPath}assets/img/icons/tabs/beacons.svg`"
          class="mr-1"
        ></v-img>
        Beacons
      </v-tab>
      <v-tab @change="handleTabChange" exact to="/reports">
        <v-img
          max-width="12"
          :src="`${publicPath}assets/img/icons/tabs/reports.svg`"
          class="mr-1"
        ></v-img>
        {{ $t("REPORTS") }}
      </v-tab>
    </v-tabs>
    <router-view></router-view>
    <!-- </div> -->
  </div>
</template>

<script>
// import { mapGetters, mapActions } from 'vuex'
import { mapMixin } from '@/mixins/mapMixin'

export default {
  mixins: [mapMixin],
  components: {},
  props: {},
  data () {
    return {}
  },
  computed: {},
  watch: {},
  created () {
    try {
      if (this.$timer.followIndividualObject) {
        clearInterval(this.$timer.followIndividualObject)
        this.$timer.followIndividualObject = 0
      }
    } catch (error) {
      this.handleError(error)
    }
  },
  methods: {
    handleTabChange: function () {
      try {
        this.$timer.followMapObject = 1234
        if (this.$timer.followMapObject) {
          clearInterval(this.$timer.followMapObject)
          this.$timer.followMapObject = 0
        }
      } catch (error) {
        this.handleError(error)
      }
    }
  }
}
</script>

<style></style>
