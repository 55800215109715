var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard" }, [
    _c(
      "div",
      { staticClass: "dashboard__table" },
      [_vm._t("dashboard-table-layout")],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "dashboard__actions" },
      [
        _c("v-btn", { staticClass: "btn-vehicle", attrs: { small: "" } }, [
          _vm._v("\n      Enable/Disable Vehicle\n      "),
          _c("div", [_vm._v("ON")])
        ]),
        _vm._v(" "),
        _c("v-btn", { staticClass: "btn-light-bar", attrs: { small: "" } }, [
          _vm._v("\n      Light Bar\n      "),
          _c("div", [_vm._v("OFF")])
        ]),
        _vm._v(" "),
        _c("v-btn", { staticClass: "btn-aux-relay", attrs: { small: "" } }, [
          _vm._v("\n      Aux Relay 2\n      "),
          _c("div", [_vm._v("OFF")])
        ]),
        _vm._v(" "),
        _c(
          "v-btn",
          { staticClass: "btn-relay-settings", attrs: { small: "" } },
          [
            _vm._v("\n      Relay Settings\n      "),
            _c("v-img", {
              staticClass: "ml-2",
              attrs: {
                "max-width": "23",
                "max-height": "23",
                src: _vm.publicPath + "assets/img/icons/account-settings.svg"
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }