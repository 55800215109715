import getters from './getters'
import actions from './actions'
import mutations from './mutations'

const state = {
  event_videos: [],
  has_video_request: false,
  video_requests_status: [],
  video_requests: [],
}

export default {
  state,
  getters,
  actions,
  mutations
}
