<template>
  <div style="" class="open-street-map fill-height">
    <v-overlay :value="show">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <editable-map
      ref="map"
      editable
      :options="mapOptions"
      style="height: 100%"
      :maxZoom="config.map_max_zoom"
      :minZoom="config.map_min_zoom"
      @ready="ready"
      :center="defaultCenter"
      @update:bounds="mapMovement"
      @update:zoom="mapZoom"
    >
      <TileLayers />
      <!-- <l-control position="topleft" class="" v-if="showAddressBar === true">
        <div style="width: 350px">
          <v-text-field
            append-icon="mdi-magnify"
            full-width
            label="Search address"
            dark
            hide-details
            solo
            outlined
            @click:append="searchPlace"
            v-model="search"
          >
          <v-icon
            slot="append"
            color="red"
          >
            mdi-plus
          </v-icon>
          </v-text-field>
        </div>
      </l-control> -->
      <!-- <l-control
        position="topleft"
        class="leaflet-control leaflet-bar"
        v-if="isMobileView"
      >
        <a
          class="control leaflet-control--object-visibility"
          @click="updateToggleProperty('trackers')"
        ></a>
        <a
          class="control leaflet-control--object-label"
          @click="updateToggleProperty('object_labels')"
        ></a>
        <a
          class="control leaflet-control--object-clusters"
          @click="updateToggleProperty('clusters')"
        ></a>
      </l-control> -->
      <l-control position="bottomright" v-if="isMobileView">
        <v-fab-transition>
          <v-btn
            color="primary"
            fab
            elevation="20"
            @click="modal.objects = true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-fab-transition>
      </l-control>
      <l-control position="bottomleft">
        <v-tooltip bottom color="tooltip" v-if="!uiSettings.map_control">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="white"
              elevation="20"
              width="40"
              height="40"
              min-width="40"
              light
              @click="saveUISettings({prop: 'map_control', value: true})"
            >
              <v-icon color="primary">mdi-tune</v-icon>
            </v-btn>
          </template>
          <span>Controls</span>
        </v-tooltip>
        <v-sheet
          v-resize="onResize"
          v-if="uiSettings.map_control"
          elevation="3"
          width="auto"
          rounded
          dark
          class="pa-1 cursor-pointer"
          style="overflow: auto"
          :height="contentHeight > 425 ? 'auto' : contentHeight - 20"
        >
          <v-container>
            <v-row class="d-flex flex-grow-1 justify-end"
              ><v-btn icon color="grey" small @click="saveUISettings({prop: 'map_control', value: false})">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>

            <v-row class="d-flex flex-column" v-if="!historyRouteData">
              <p class="text-subtitle-1">Object Controls</p>
              <div class="d-flex flex-row flex-wrap mb-2">
                <v-tooltip bottom color="tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="d-flex flex-column mr-2"
                      style="width: 50px"
                    >
                      <div>
                        <v-btn
                          :depressed="toggles.trackers"
                          :outlined="!toggles.trackers"
                          :color="toggles.trackers ? '#ffa700' : 'grey'"
                          height="45px"
                          width="45px"
                          min-width="40px"
                          class=""
                          @click="updateToggleProperty('trackers')"
                        >
                          <v-img
                            width="30px"
                            height="30px"
                            :src="`${publicPath}assets/img/images/object-arrow.svg`"
                          ></v-img>
                        </v-btn>
                      </div>
                      <span
                        class="text-body-2 text-center"
                        style="color: #c8c8c8"
                        >Tracker</span
                      >
                    </div>
                  </template>
                  <span>Tracker</span>
                </v-tooltip>
                <v-tooltip bottom color="tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="d-flex flex-column mr-2"
                      style="width: 50px"
                    >
                      <div>
                        <v-btn
                          :depressed="toggles.object_labels"
                          :outlined="!toggles.object_labels"
                          :color="toggles.object_labels ? '#ffa700' : 'grey'"
                          height="45px"
                          width="45px"
                          min-width="40px"
                          class=""
                          @click="updateToggleProperty('object_labels')"
                        >
                          <v-img
                            width="30px"
                            height="30px"
                            :src="`${publicPath}assets/img/images/text.svg`"
                          ></v-img>
                        </v-btn>
                      </div>
                      <span
                        class="text-body-2 text-center"
                        style="color: #c8c8c8"
                        >Object Label</span
                      >
                    </div>
                  </template>
                  <span>Object Label</span>
                </v-tooltip>

                <v-tooltip bottom color="tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="d-flex flex-column mr-2"
                      style="width: 50px"
                    >
                      <div>
                        <v-btn
                          :depressed="toggles.clusters"
                          :outlined="!toggles.clusters"
                          :color="toggles.clusters ? '#ffa700' : 'grey'"
                          height="45px"
                          width="45px"
                          min-width="40px"
                          class=""
                          @click="updateToggleProperty('clusters')"
                        >
                          <v-img
                            width="30px"
                            height="30px"
                            :src="`${publicPath}assets/img/images/clusters.svg`"
                          ></v-img>
                        </v-btn>
                      </div>
                      <span
                        class="text-body-2 text-center"
                        style="color: #c8c8c8"
                        >Cluster</span
                      >
                    </div>
                  </template>
                  <span>Cluster</span>
                </v-tooltip>
              </div>
            </v-row>

            <v-row class="d-flex flex-column" v-if="!historyRouteData">
              <v-divider class="mb-2"></v-divider>
              <p class="text-subtitle-1">Place Controls</p>
              <div class="d-flex flex-row flex-wrap mb-2">
                <v-tooltip bottom color="tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="d-flex flex-column mr-2"
                      style="width: 50px"
                    >
                      <div>
                        <v-btn
                          :depressed="toggles.zones"
                          :outlined="!toggles.zones"
                          :color="toggles.zones ? '#ffa700' : 'grey'"
                          height="45px"
                          width="45px"
                          min-width="40px"
                          class=""
                          @click="updateMapToggles('zones')"
                        >
                          <v-img
                            width="30px"
                            height="30px"
                            :src="`${publicPath}assets/img/images/zones.svg`"
                          ></v-img>
                        </v-btn>
                      </div>
                      <span
                        class="text-body-2 text-center"
                        style="color: #c8c8c8"
                        >Zone</span
                      >
                    </div>
                  </template>
                  <span>Zone</span>
                </v-tooltip>
                <v-tooltip bottom color="tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="d-flex flex-column mr-2"
                      style="width: 50px"
                    >
                      <div>
                        <v-btn
                          :depressed="toggles.markers"
                          :outlined="!toggles.markers"
                          :color="toggles.markers ? '#ffa700' : 'grey'"
                          height="45px"
                          width="45px"
                          min-width="40px"
                          class=""
                          @click="updateMapToggles('markers')"
                        >
                          <v-img
                            width="30px"
                            height="30px"
                            :src="`${publicPath}assets/img/images/marker.svg`"
                          ></v-img>
                        </v-btn>
                      </div>
                      <span
                        class="text-body-2 text-center"
                        style="color: #c8c8c8"
                        >Marker</span
                      >
                    </div>
                  </template>
                  <span>Marker</span>
                </v-tooltip>

                <v-tooltip bottom color="tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="d-flex flex-column mr-2"
                      style="width: 50px"
                    >
                      <div>
                        <v-btn
                          :depressed="toggles.routes"
                          :outlined="!toggles.routes"
                          :color="toggles.routes ? '#ffa700' : 'grey'"
                          height="45px"
                          width="45px"
                          min-width="40px"
                          class=""
                          @click="updateMapToggles('routes')"
                        >
                          <v-img
                            width="30px"
                            height="30px"
                            :src="`${publicPath}assets/img/images/routes.svg`"
                          ></v-img>
                        </v-btn>
                      </div>
                      <span
                        class="text-body-2 text-center"
                        style="color: #c8c8c8"
                        >{{ $t('ROUTES') }}</span
                      >
                    </div>
                  </template>
                  <span>{{ $t('ROUTES') }}</span>
                </v-tooltip>
              </div>
            </v-row>

            <v-row class="d-flex flex-column" v-if="!historyRouteData">
              <v-divider class="mb-2"></v-divider>
              <p class="text-subtitle-1">Map Controls</p>
              <div class="d-flex flex-row flex-wrap mb-2">
                <!-- <v-tooltip bottom color="tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="d-flex flex-column mr-2"
                      style="width: 50px"
                    >
                      <div>
                        <v-btn
                          :depressed="toggles.traffic"
                          :outlined="!toggles.traffic"
                          :color="toggles.traffic ? '#ffa700' : 'grey'"
                          height="45px"
                          width="45px"
                          min-width="40px"
                          class=""
                          @click="updateMapToggles('traffic')"
                        >
                          <v-img
                            width="30px"
                            height="30px"
                            :src="`${publicPath}assets/img/images/traffic.svg`"
                          ></v-img>
                        </v-btn>
                      </div>
                      <span
                        class="text-body-2 text-center"
                        style="color: #c8c8c8"
                        >Traffic</span
                      >
                    </div>
                  </template>
                  <span>Traffic</span>
                </v-tooltip> -->

                <v-tooltip bottom color="tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="d-flex flex-column mr-2"
                      style="width: 50px"
                    >
                      <div>
                        <v-btn
                          :depressed="toggles.fit_all"
                          :outlined="!toggles.fit_all"
                          :color="toggles.fit_all ? '#ffa700' : 'grey'"
                          height="45px"
                          width="45px"
                          min-width="40px"
                          class=""
                          @click="fitAllToggleClicked = true; updateMapToggles('fit_all')"
                        >
                          <v-img
                            width="30px"
                            height="30px"
                            :src="`${publicPath}assets/img/images/fit.svg`"
                          ></v-img>
                        </v-btn>
                      </div>
                      <span
                        class="text-body-2 text-center"
                        style="color: #c8c8c8"
                        >Fit All</span
                      >
                    </div>
                  </template>
                  <span>Fit All</span>
                </v-tooltip>
              </div>
            </v-row>

            <v-row class="d-flex flex-column" v-if="historyRouteData">
              <p class="text-subtitle-1">History Controls</p>
              <div class="d-flex flex-row flex-wrap mb-2">
                <v-tooltip bottom color="tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="d-flex flex-column mr-2"
                      style="width: 50px"
                    >
                      <div>
                        <v-btn
                          :depressed="toggles.history.route"
                          :outlined="!toggles.history.route"
                          :color="toggles.history.route ? '#ffa700' : 'grey'"
                          height="45px"
                          width="45px"
                          min-width="40px"
                          class=""
                          @click="updateToggleHistoryProperty('route')"
                        >
                          <v-img
                            width="30px"
                            height="30px"
                            :src="`${publicPath}assets/img/images/route-route.svg`"
                          ></v-img>
                        </v-btn>
                      </div>
                      <span
                        class="text-body-2 text-center"
                        style="color: #c8c8c8"
                        >{{ $t('ROUTE') }}</span
                      >
                    </div>
                  </template>
                  <span>{{ $t('ROUTE') }}</span>
                </v-tooltip>
                <v-tooltip bottom color="tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="d-flex flex-column mr-2"
                      style="width: 50px"
                    >
                      <div>
                        <v-btn
                          :depressed="toggles.history.snap"
                          :outlined="!toggles.history.snap"
                          :color="toggles.history.snap ? '#ffa700' : 'grey'"
                          height="45px"
                          width="45px"
                          min-width="40px"
                          class=""
                          @click="updateToggleHistoryProperty('snap')"
                        >
                          <v-img
                            width="30px"
                            height="30px"
                            :src="`${publicPath}assets/img/images/route-snap.svg`"
                          ></v-img>
                        </v-btn>
                      </div>
                      <span
                        class="text-body-2 text-center"
                        style="color: #c8c8c8"
                        >Route Snap</span
                      >
                    </div>
                  </template>
                  <span>Route Snap</span>
                </v-tooltip>

                <v-tooltip bottom color="tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="d-flex flex-column mr-2"
                      style="width: 50px"
                    >
                      <div>
                        <v-btn
                          :depressed="toggles.history.arrows"
                          :outlined="!toggles.history.arrows"
                          :color="toggles.history.arrows ? '#ffa700' : 'grey'"
                          height="45px"
                          width="45px"
                          min-width="40px"
                          class=""
                          @click="updateToggleHistoryProperty('arrows')"
                        >
                          <v-img
                            width="30px"
                            height="30px"
                            :src="`${publicPath}assets/img/images/route-arrow.svg`"
                          ></v-img>
                        </v-btn>
                      </div>
                      <span
                        class="text-body-2 text-center"
                        style="color: #c8c8c8"
                        >Route Arrow</span
                      >
                    </div>
                  </template>
                  <span>Route Arrow</span>
                </v-tooltip>

                <v-tooltip bottom color="tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="d-flex flex-column mr-2"
                      style="width: 50px"
                    >
                      <div>
                        <v-btn
                          :depressed="toggles.history.data_points"
                          :outlined="!toggles.history.data_points"
                          :color="
                            toggles.history.data_points ? '#ffa700' : 'grey'
                          "
                          height="45px"
                          width="45px"
                          min-width="40px"
                          class=""
                          @click="updateToggleHistoryProperty('data_points')"
                        >
                          <v-img
                            width="30px"
                            height="30px"
                            :src="`${publicPath}assets/img/images/route-data-point.svg`"
                          ></v-img>
                        </v-btn>
                      </div>
                      <span
                        class="text-body-2 text-center"
                        style="color: #c8c8c8"
                        >{{ $t('ROUTE') }} {{ $t('DATA_POINTS') }}</span
                      >
                    </div>
                  </template>
                  <span>{{ $t('ROUTE') }} {{ $t('DATA_POINTS') }}</span>
                </v-tooltip>

                <v-tooltip bottom color="tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="d-flex flex-column mr-2"
                      style="width: 50px"
                    >
                      <div>
                        <v-btn
                          :depressed="toggles.history.stops"
                          :outlined="!toggles.history.stops"
                          :color="toggles.history.stops ? '#ffa700' : 'grey'"
                          height="45px"
                          width="45px"
                          min-width="40px"
                          class=""
                          @click="updateToggleHistoryProperty('stops')"
                        >
                          <v-img
                            width="30px"
                            height="30px"
                            :src="`${publicPath}assets/img/images/route-stop.svg`"
                          ></v-img>
                        </v-btn>
                      </div>
                      <span
                        class="text-body-2 text-center"
                        style="color: #c8c8c8"
                        >{{ $t('ROUTE') }} {{ $t('STOP') }}</span
                      >
                    </div>
                  </template>
                  <span>{{ $t('ROUTE') }} {{ $t('STOP') }}</span>
                </v-tooltip>

                <v-tooltip bottom color="tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="d-flex flex-column"
                      style="width: 50px"
                    >
                      <div>
                        <v-btn
                          :depressed="toggles.history.events"
                          :outlined="!toggles.history.events"
                          :color="toggles.history.events ? '#ffa700' : 'grey'"
                          height="45px"
                          width="45px"
                          min-width="40px"
                          class=""
                          @click="updateToggleHistoryProperty('events')"
                        >
                          <v-img
                            width="30px"
                            height="30px"
                            :src="`${publicPath}assets/img/images/route-event.svg`"
                          ></v-img>
                        </v-btn>
                      </div>
                      <span
                        class="text-body-2 text-center"
                        style="color: #c8c8c8"
                        >{{ $t('ROUTE') }} {{ $t('EVENT') }}</span
                      >
                    </div>
                  </template>
                  <span>{{ $t('ROUTE') }} {{ $t('EVENT') }}</span>
                </v-tooltip>
              </div>
            </v-row>
          </v-container>
        </v-sheet>
      </l-control>

      <l-marker v-if="searchPopup !== null" :lat-lng="searchPopup.latlng" :opacity="0" ref="searchMarker">
        <l-popup>
          <!-- <v-btn block color="primary">
            <v-icon right dark>mdi-map-marker-plus</v-icon>
            Save as marker
          </v-btn> -->
          <v-container class="pa-1">
            <v-row>
              <v-col cols="12" class="py-0"
                ><p class="text-subtitle-1">{{ searchPopup.address }}</p></v-col
              >
              <v-col cols="6"
                ><v-tooltip bottom color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn block v-bind="attrs" v-on="on">
                      <v-icon dark> mdi-content-copy </v-icon>
                    </v-btn></template
                  >
                  <span>Copy as message</span>
                </v-tooltip></v-col
              >
              <v-col cols="6">
                <v-tooltip bottom color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn block color="primary" v-bind="attrs" v-on="on">
                      <v-icon dark> mdi-map-marker-plus </v-icon>
                    </v-btn>
                  </template>
                  <span>Save as marker</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <!-- <v-row>

            </v-row> -->
          </v-container>
        </l-popup>
      </l-marker>
    </editable-map>
    <v-snackbar dark top
      text
      :value="searchPopup !== null"
      v-if="searchPopup !== null" timeout="-1"
    >
      <v-container class="pa-1">
            <v-row>
              <v-col cols="12" class="py-0"
                ><p class="text-subtitle-1">{{ searchPopup.address }}</p></v-col
              >
              <v-col cols="6"
                ><v-tooltip bottom color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn block v-bind="attrs" v-on="on">
                      <v-icon dark> mdi-content-copy </v-icon>
                    </v-btn></template
                  >
                  <span>Copy as message</span>
                </v-tooltip></v-col
              >
              <v-col cols="6">
                <v-tooltip bottom color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn block color="primary" v-bind="attrs" v-on="on" @click="saveAsMarker">
                      <v-icon dark> mdi-map-marker-plus </v-icon>
                    </v-btn>
                  </template>
                  <span>Save as marker</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <!-- <v-row>

            </v-row> -->
          </v-container>
    </v-snackbar>
    <ObjectsForm
      :show="modal.objects"
      v-on:show-objects-dialog="setShowObjectsModal"
    />
    <ConfirmDialog
      :info="confirmDetails"
      :show="historyProgress.showConfirm"
      v-on:show-confirm-dialog="confirmHistoryLoad"
    />
  </div>
</template>

<script>
import { mapFunctions } from '@/plugins/map'
import { globals } from '@/plugins/global'
import { historyFunctions } from '@/plugins/history'
import TileLayers from '@/components/includes/TileLayers.vue'
import { mapGetters, mapActions } from 'vuex'
// import 'leaflet-ajax/dist/leaflet.ajax.js'

import L, { latLng } from 'leaflet'
// import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
// import Vue2LeafletPolylineDecorator from 'vue2-leaflet-polylinedecorator'
import { LControl, LPopup } from 'vue2-leaflet'
import { EditableMap } from 'vue2-leaflet-editable'

import ObjectsForm from '@/components/forms/ObjectsForm.vue'
import ConfirmDialog from '@/components/includes/ConfirmDialog.vue'
export default {
  components: {
    EditableMap,
    LControl,
    LPopup,
    ObjectsForm,
    ConfirmDialog,
    TileLayers
  },
  data () {
    return {
      show: false,
      search: '',
      searchPopup: null,
      mapLayers: {
        objects: {
          markers: {},
          tails: {}
        },
        place: {
          zones: {},
          routes: {},
          markers: {},
          draft: {},
          draw_layer: false,
          valid_draw_layer: false
        },
        maps: {},
        history: {
          events: {}
        }
      },
      modal: {
        objects: false
      },
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      mapOptions: {
        zoomSnap: 0.5
      },
      map: null,
      // iconSize: [28, 28],

      historyToggleClicked: false,
      fitAllToggleClicked: false,
      toggleSettings: {
        place: [],
        object: [],
        fit_all: [],
        traffic: []
      },
      // historyRouteData: [],
      // event: null
      historyProgress: {
        interval: null,
        counter: 0,
        showConfirm: false,
        showHistoryLayers: true
      },
      historyRouteData: null,
      confirmDetails: {
        title: '',
        message: '',
        action: {
          yes: '',
          no: ''
        }
      }
      // showAddressBar: false,
    }
  },
  computed: {
    ...mapGetters({
      // markers: "map/markers",

      center: 'map/center',
      bounds: 'map/bounds',
      follow: 'map/follow',

      visibleObjects: 'map/visibleObjects',

      polylines: 'map/polylines',
      placeMarkers: 'places/placeMarkers',
      placeRoutes: 'places/placeRoutes',
      placeZones: 'places/placeZones',
      placesData: 'places/placesData',
      placeDraft: 'places/placeDraft',
      toggles: 'map/toggles',
      getMarkerIcon: 'objects/getMarkerIcon',
      mapList: 'settings/mapList',
      mapSettings: 'settings/mapSettings',
      history: 'map/history',
      // historyRouteData: "map/historyRouteData",
      getObjectOdometer: 'objects/getObjectOdometer',
      getObjectEngineHours: 'objects/getObjectEngineHours',
      eventId: 'map/eventId',
      objectsNew: 'objects/objectsNew',
      objectLayers: 'objects/objectLayers',
      historyFilter: 'objects/historyFilter',
      rawHistory: 'map/rawHistory',
      // historyProgress: "map/historyProgress",

      searchAddressState: 'settings/searchAddressState'
    }),
    showAddressBar: function () {
      return this.searchAddressState
    },
    mapListMenu: function () {
      this.$log.debug(this.mapList)
      return this.mapList
    },
    selectedMap: function () {
      // name: "OSM Map",
      // value: "osm",
      if (this.mapSettings) {
        return this.mapSettings
      }
      return {
        name: 'OSM Map',
        value: 'osm'
      }
    },
    
    placeToggles () {
      const toggles = []
      try {
        if (this.toggles.zones) {
          toggles.push('zones')
        }
        if (this.toggles.routes) {
          toggles.push('routes')
        }
        if (this.toggles.markers) {
          toggles.push('markers')
        }
        // this.place_toggles = toggles
        this.$log.debug(toggles)
      } catch (error) {
        this.handleError(error)
      }
      return toggles
    },
    objectToggles () {
      const toggles = []
      try {
        if (this.toggles.trackers) {
          toggles.push('trackers')
        }
        if (this.toggles.clusters) {
          toggles.push('clusters')
        }
        if (this.toggles.object_labels) {
          toggles.push('labels')
        }
        this.$log.debug(toggles)
      } catch (error) {
        this.handleError(error)
      }
      return toggles
    },
    fitAllToggle () {
      return this.toggles.fit_all ? 'fit_all' : ''
    },
    trafficToggle () {
      return this.toggles.traffic ? 'traffic' : ''
    }
  },
  watch: {
    objectsNew: {
      deep: true,
      handler () {
        try {
          this.$log.info('OBJECTS CHANGED')
          this.updateObjectPolylines()
          // this.loadObjectMarkers();
          this.setBounds()
          // this.loadObjectTails();
        } catch (error) {
          this.handleError(error)
        }
      }
    },
    'toggles.zones': function () {
      // this.$log.debug("TOGGLE ZONES HAS BEEN CHANGED");
      this.mapControlPlaces('zones')
    },
    'toggles.markers': function () {
      // this.$log.debug("TOGGLE MARKERS HAS BEEN CHANGED");
      this.mapControlPlaces('markers')
    },
    'toggles.routes': function () {
      // this.$log.debug("TOGGLE ROUTES HAS BEEN CHANGED");
      this.mapControlPlaces('routes')
    },
    'toggles.trackers': function () {
      // this.$log.debug("TOGGLE TRACKERS HAS BEEN CHANGED");
      this.mapControlObjectMarkers()
    },
    'toggles.clusters': function () {
      // this.$log.debug("TOGGLE CLUSTERS HAS BEEN CHANGED");
      this.mapControlClusters()
    },
    'toggles.object_labels': function () {
      // this.$log.debug("TOGGLE LABELS HAS BEEN CHANGED");
      this.mapControlObjectLabels()
    },
    'toggles.fit_all': function () {
      // this.$log.debug("TOGGLE FIT ALL HAS BEEN CHANGED");

      if (this.toggles.fit_all) {
        const bounds = this.mapLayers.objects.markers.getBounds()
        this.map.fitBounds(bounds)
      }
    },
    'toggles.traffic': function () {
      // this.$log.debug("TOGGLE TRAFFIC HAS BEEN CHANGED");
      if (this.toggles.traffic) {
        // console.log("switch map layer toggle is true");
        if ('gmap,ghyb,gter'.includes(this.selectedMap.value)) {
          // switchMapLayer(gsValues.map_layer)
          console.log('traffic go')
        } else {
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'error',
            title: this.$t('LIVE_TRAFFIC'),
            text: this.$t('LIVE_TRAFFIC_FOR_THIS_MAP_IS_NOT_AVAILABLE')
          })
        }
      } else {
        if ('gmap,ghyb,gter'.includes(this.selectedMap.value)) {
          console.log('traffic go')
          // switchMapLayer(this.selectedMap.value)
          // console.log("switch map layer");
        }
      }
    },
    'toggles.history.route': function () {
      // this.$log.debug("TOGGLE TRAFFIC HAS BEEN CHANGED");
      this.historyRouteRoute()
      this.$log.debug(
        'toggles.history.route changed',
        this.toggles.history.route
      )
    },
    'toggles.history.snap': function () {
      // this.$log.debug("TOGGLE TRAFFIC HAS BEEN CHANGED");
      this.$log.debug(
        'toggles.history.snap changed',
        this.toggles.history.snap
      )
      this.historyRouteSnap()
    },
    'toggles.history.arrows': function () {
      // this.$log.debug("TOGGLE TRAFFIC HAS BEEN CHANGED");
      this.$log.debug(
        'toggles.history.arrows changed',
        this.toggles.history.arrows
      )
      this.historyRouteArrows()
    },
    'toggles.history.data_points': function () {
      // this.$log.debug("TOGGLE TRAFFIC HAS BEEN CHANGED");
      this.$log.debug(
        'toggles.history.data_points changed',
        this.toggles.history.data_points
      )

      if (this.toggles.history.data_points) {
        // this.map.setZoom(14)
      }

      this.show = true
      setTimeout(() => {
        this.$log.debug('load history datapoints after  after 2 seconds')
        this.historyRouteDataPoints()
        this.show = false
      }, 2000)
    },
    'toggles.history.stops': function () {
      // this.$log.debug("TOGGLE TRAFFIC HAS BEEN CHANGED");
      this.$log.debug(
        'toggles.history.stops changed',
        this.toggles.history.stops
      )
      this.show = true
      setTimeout(() => {
        this.$log.debug('load history stops after  after 2 seconds')
        this.historyRouteStops()
        this.show = false
      }, 2000)
    },
    'toggles.history.events': function () {
      // this.$log.debug("TOGGLE TRAFFIC HAS BEEN CHANGED");
      this.$log.debug(
        'toggles.history.events changed',
        this.toggles.history.events
      )
      this.show = true
      setTimeout(() => {
        this.$log.debug('load history events after  after 2 seconds')
        this.historyRouteEvents()
        this.show = false
      }, 2000)
    },

    follow: {
      // deep: true,
      handler () {
        this.$log.debug('FOLLOW HAS BEEN CHANGED', this.follow)
        this.setBounds()
      }
    },
    placeMarkers: {
      deep: true,
      handler () {
        if (this.historyRouteData === null) {
          this.loadPlaceMarkers()
        }
        // if (this.$router.currentRoute.name !== 'mobile/map' && !Object.prototype.hasOwnProperty.call(this.history, "route")) {

        // }
      }
    },
    placeRoutes: {
      deep: true,
      handler () {
        if (this.historyRouteData === null) {
          this.loadPlaceRoutes()
        }
        // if (this.$router.currentRoute.name !== 'mobile/map' && !Object.prototype.hasOwnProperty.call(this.history, "route")) {

        // }
      }
    },
    placeZones: {
      deep: true,
      handler () {
        if (this.historyRouteData === null) {
          this.loadPlaceZones()
        }
        // if (this.$router.currentRoute.name !== 'mobile/map' && !Object.prototype.hasOwnProperty.call(this.history, "route")) {

        // }
      }
    },
    eventId: async function () {
      this.$log.debug('has event', this.eventId)
      this.loadEventDetails()
    },
    // server: {
    //   // deep: true,
    //   handler() {
    //     // this.setBounds();
    //   },
    // },
    'server.map_lat': function () {
      this.$log.debug('pan to default view')
      this.map.setView(
        [this.server.map_lat, this.server.map_lng],
        this.server.map_zoom
      )
    },
    // progress: {
    //   deep: true,
    //   handler () {
    //     this.$log.debug('progress have been changed', this.progress.value)
    //   }
    // },
    center: function () {
      this.$log.debug('center have been changed', this.center)
      this.map.panTo(this.center)
    },
    bounds: function () {
      this.$log.debug('bounds have been changed', this.center)
      this.map.fitBounds(this.bounds)
    },
    visibleObjects: function () {
      this.loadObjectMarkers()
    },
    'placeDraft.fields.image': function () {
      this.loadPlaceDraft()
    },
    'placeDraft.action': function (newVal, oldVal) {
      const action = newVal
      if (action) {
        this.map.doubleClickZoom.disable()
        if (this.placeDraft.type === 'routes') {
          this.map.editTools.startPolyline()
        } else if (this.placeDraft.type === 'zones') {
          this.map.editTools.startPolygon()
        }
        if (action === 'edit') {
          this.loadPlaceDraft()
        }
      } else {
        this.map.editTools.stopDrawing()
        if (this.map.hasLayer(this.mapLayers.place.draw_layer)) {
          this.map.removeLayer(this.mapLayers.place.draw_layer)
        }
        this.mapLayers.place.draw_layer = false
        this.map.doubleClickZoom.enable()
      }

      if (action === 'edit' || !action) {
        this.loadPlaceMarkers()
        this.loadPlaceRoutes()
        this.loadPlaceZones()
      }
    },
    'historyProgress.showHistoryLayers': function (newVal, oldVal) {
      this.$log.debug(
        'historyProgress.showHistoryLayers',
        this.historyProgress.showHistoryLayers
      )

      if (!this.historyProgress.showHistoryLayers) {
        this.clearHistoryInterval()
        this.show = false
        this.mapLayers.history.events.removeFrom(this.map)
        this.mapLayers.history.route.removeFrom(this.map)
        this.mapLayers.history.route_snap.removeFrom(this.map)
        this.mapLayers.history.arrows.removeFrom(this.map)
        this.mapLayers.history.arrows_snap.removeFrom(this.map)
        this.mapLayers.history.stops.removeFrom(this.map)
        this.mapLayers.history.data_points.removeFrom(this.map)
        this.mapLayers.history.utils.removeFrom(this.map)
      }
    },
    'historyProgress.showConfirm': function (newVal, oldVal) {
      this.$log.debug(
        'historyProgress.showConfirm',
        this.historyProgress.showConfirm
      )
      if (this.historyProgress.showConfirm) {
        this.$log.debug('show confirm is true so hide history layers')
        this.historyProgress.showHistoryLayers = false
      }
    }

    // searchAddressState: function() {
    //   console.log('changed')
    //   this.showAddressBar = this.searchAddressState
    // }
  },
  created () {
    this.onResize('table')
    this.$log.debug('osm created')
    this.$log.debug('created')
    this.loadEventDetails()
    Promise.all([this.initializeObjectData(), this.initializePlacesData()])

      .then(() => {
        //  this.$log.debug('created',Object.keys(this.objectsNew))
        this.loadObjectMarkers()

        if (this.follow.length >= 1) {
          this.$log.debug('mobile and has an object followed so setbounds')
          this.setBounds()
        }
        // console.log('ui settings', this.uiSettings);

        this.toggleSettings.place = this.placeToggles
        this.toggleSettings.object = this.objectToggles
        this.toggleSettings.fit_all = this.fitAllToggle
        this.toggleSettings.traffic = this.trafficToggle

        // if (!this.isMobileView) {
        // this.loadPlaceMarkers();
        // this.loadPlaceRoutes();
        // this.loadPlaceZones();
        // }

        if (this.historyFilter) {
          this.fetchHistory()
        }
        clearInterval(this.$timer.objectLoad)
        this.$timer.objectLoad = setInterval(() => {
          const ctr = Math.floor(Math.random() * 10) + 1

          this.fetchObjectData({
          ctr: ctr
          })
            .then(() => {
              if (this.historyRouteData === null) {
                this.loadObjectMarkers()
              }
            })
            .catch((error) => {
              this.handleError(error)
              clearInterval(this.$timer.objectLoad)
            })
        }, this.config.map_refresh * 1000)
      })
      .catch((error) => {
        this.handleError(error)
      })

    // this.loadObjectMarkers()
    // this.loadEventDetails()
    // clearInterval(this.$timer.objectLoad)

    // Promise.all([
    //   this.fetchObjectData({ ctr: 1 }),
    //   this.initializePlacesData()
    // ])
    //   .then(() => {
    //     this.loadObjectMarkers()
    //     this.$timer.objectLoad = setInterval(() => {
    //       const ctr = Math.floor(Math.random() * 10) + 1

    //       this.fetchObjectData({
    //         ctr: ctr
    //       }).then(() => {
    //         this.loadObjectMarkers()
    //       }).catch((error) => {
    //         this.handleError(error)
    //         clearInterval(this.$timer.objectLoad)
    //       })
    //     }, this.config.map_refresh * 1000)
    //   })
    //   .catch((error) => {
    //     this.handleError(error)
    //   })

    // this.toggleSettings.place = this.placeToggles
    // this.toggleSettings.object = this.objectToggles
    // this.toggleSettings.fit_all = this.fitAllToggle
    // this.toggleSettings.traffic = this.trafficToggle
  },
  methods: {
    ...mapActions({
      checkSession: 'auth/checkSession',
      fetchObjectData: 'objects/fetchObjectData',
      updateMapPlacesDraft: 'places/updateMapPlacesDraft',
      saveSettings: 'settings/saveSettings',
      setHistory: 'map/setHistory',
      updateObjectPolylines: 'map/updateObjectPolylines',
      updateMapToggles: 'map/updateMapToggles',
      updateMapHistoryToggles: 'map/updateMapHistoryToggles',
      // updateMapPlacesData: "map/updateMapPlacesData",
      fetchEvent: 'events/fetchEvent',
      // setPlaceLayers: "places/setPlaceLayers",
      // setPlaceProperty: 'places/setPlaceProperty',
      initializePlacesData: 'places/initializePlacesData',
      updateEventId: 'map/updateEventId',
      // setHistory: 'map/setHistory',
      setHistoryRouteDataLayer: 'map/setHistoryRouteDataLayer',
      setHistoryRouteDataPlay: 'map/setHistoryRouteDataPlay',
      setPlaceMarkerLayer: 'map/setPlaceMarkerLayer',
      initializeObjectData: 'objects/initializeObjectData',
      fetchObjectRouteHistory: 'objects/fetchObjectRouteHistory',
      setHistoryFilter: 'objects/setHistoryFilter',
      setHistoryProgress: 'map/setHistoryProgress',
      setHistoryRouteData: 'map/setHistoryRouteData',
      updateMapFollow: 'map/updateMapFollow',
      savePlaceObject: 'places/savePlaceObject',
      fetchPlacesData: 'places/fetchPlacesData'
    }),
    ready () {
      // this.$log.debug("ready");

      // let map = this.map;
      // this.map = null;
      // this.map = map;
      //  this.map = this.$children[0].mapObject
      // let map = this.$refs.map.mapObject;
      // this.$refs.map.mapObject = null;
      // this.$refs.map.mapObject = map;
      // this.map = null;
      this.map = this.$refs.map.mapObject

      this.$log.debug('READY FUNCTION', this.map)
      this.map.zoomControl.setPosition('topright')
      this.map.on('click', this.setPlaceMarker) // setting a marker

      this.map.on('editable:editing editable:drag', this.drawObject)
      this.map.on('editable:drawing:end', this.drawObject)

      // if(this.mapLayers.objects.markers.length >= 1) {
      // this.$log.debug("is", this.user.map_is);
      // this.mapLayers.utils = L.layerGroup();
      // this.mapLayers.utils.addTo(this.map);
      // this.mapLayers.objects = {
      //   markers: {},
      //   tails: {}
      // };
      this.mapLayers.objects.markers = mapFunctions.createCluster(
        'objects',
        this.user.map_is,
        this.config.map_min_zoom,
        this.config.map_max_zoom,
        this.toggles.clusters
      )
      this.mapLayers.objects.markers.addTo(this.map)
      this.mapLayers.objects.tails = L.layerGroup()
      this.mapLayers.objects.tails.addTo(this.map)
      this.mapLayers.place = {
        zones: {},
        routes: {},
        markers: {},
        draft: {},
        draw_layer: false,
        valid_draw_layer: false
      }
      this.mapLayers.place.markers = mapFunctions.createCluster(
        'markers',
        this.user.map_is,
        this.config.map_min_zoom,
        this.config.map_max_zoom,
        this.toggles.clusters
      )
      this.mapLayers.place.markers.addTo(this.map)

      this.mapLayers.place.zones = L.layerGroup()
      this.mapLayers.place.zones.addTo(this.map)

      this.mapLayers.place.routes = L.layerGroup()
      this.mapLayers.place.routes.addTo(this.map)

      // this.mapLayers.history = L.layerGroup();
      // this.mapLayers.history.addTo(this.map);

      // this.mapLayers.history = {
      //   events: L.LayerGroup(),
      //   route: L.LayerGroup(),
      //   route_snap: L.LayerGroup(),
      //   arrows: L.LayerGroup(),
      //   arrows_snap: L.LayerGroup(),
      //   stops: L.LayerGroup(),
      //   data_points: L.LayerGroup(),
      // }

      this.mapLayers.history.events = L.layerGroup()
      this.mapLayers.history.route = L.layerGroup()
      this.mapLayers.history.route_snap = L.layerGroup()
      this.mapLayers.history.arrows = L.layerGroup()
      this.mapLayers.history.arrows_snap = L.layerGroup()
      this.mapLayers.history.stops = L.layerGroup()
      // this.mapLayers.history.data_points = L.layerGroup()
      this.mapLayers.history.data_points = L.featureGroup()

      // this.mapLayers.history.data_points = L.layerGroup();
      // this.mapLayers.history.data_points.addTo(this.map);

      this.mapLayers.history.utils = L.layerGroup()
      this.mapLayers.history.utils.addTo(this.map)
    },

    loadObjectMarkers () {
      // this.$log.debug('load obj markers', Object.keys(this.objectsNew).length)
      const mapIs = this.user.map_is
      if (this.mapLayers.objects.markers.length >= 1) {
        this.mapLayers.objects.markers.clearLayers()
        this.mapLayers.objects.tails.clearLayers()
      }
      this.mapLayers.objects.markers.clearLayers()
      this.mapLayers.objects.tails.clearLayers()
      if (this.toggles.trackers) {
        // for (const [imei] of Object.entries(this.objectsNew)) {
        // this.$log.debug(this.objectsNew)

        Object.keys(this.objectsNew).forEach((imei) => {
          // this.$log.debug(this.objectsNew[imei])
          // this.$log.debug(imei)
          if (this.objectsNew[imei].visible) {
            // this.$log.debug(this.objectsNew[imei].visible)
            const obj = this.objectsNew[imei]
            const details = {
              name: obj.settings.name,
              icon: 'img/markers/' + this.getMarkerIcon(imei) + '.png',
              lat: obj.data.lat,
              lng: obj.data.lng,
              angle: obj.data.angle
            }

            let marker = mapFunctions.generateMarker(details, mapIs)

            const options = {
              permanent: true,
              offset: [20 * mapIs, 0],
              direction: 'right'
            }
            const content =
              obj.settings.name +
              ' (' +
              obj.data.speed +
              ' kph)'

            marker = mapFunctions.bindTooltip(marker, content, options)
            marker.on('add', () => {
              if (!this.toggles.object_labels) {
                marker.closeTooltip()
              }
              this.loadObjectTail(imei, true)
            })
            // this.loadObjectTail(imei)
            marker.on('remove', () => {
              this.loadObjectTail(imei, false)
              // if (this.objectLayers[imei].layers.tail) {
              //   this.mapLayers.objects.tails.removeLayer(
              //     this.objectLayers[imei].layers.tail
              //   )
              // }
            })
            if (this.toggles.object_labels) {
              marker.openTooltip()
            } else {
              marker.closeTooltip()
            }
            // this.$log.debug(this.mapLayers.objects.markers)
            this.mapLayers.objects.markers.addLayer(marker)
          }
        })
      }
    },
    loadObjectTail (imei, show) {
      if (this.objectsNew[imei].settings.tail_points > 0) {
        if (Object.prototype.hasOwnProperty.call(this.polylines, imei)) {
          const line = mapFunctions.generatePolylines({
            color: this.objectsNew[imei].settings.tail_color,
            points: this.polylines[imei]
          })
          if (this.mapLayers.objects.tails.hasLayer(line)) {
            if (!show) {
              this.mapLayers.objects.tails.removeLayer(line)
            }
          } else {
            if (show) {
              this.mapLayers.objects.tails.addLayer(line)
            }
          }
        }
      }
    },
    setBounds () {
      try {
        if (this.follow.length > 1) {
          // fitbounds
          const bounds = []
          this.follow.forEach((imei) => {
            bounds.push([
              this.objectsNew[imei].data.lat,
              this.objectsNew[imei].data.lng
            ])
          })
          // this.$log.debug(this.follow);
          this.map.fitBounds(bounds)
        } else if (this.follow.length === 1) {
          // pan to

          this.$log.debug('following 1')
          const key = this.follow[0]
          const lat = this.objectsNew[key].data.lat
          const lng = this.objectsNew[key].data.lng

          this.$log.debug('following 1', lat, lng)
          this.map.panTo({ lat: lat, lng: lng })
        } else {
          // let bounds = []
          // for (const [imei, obj] of Object.entries(this.objectsNew)) {
          //   bounds.push(L.latLng(obj.data.lat, obj.data.lng));
          // }
          // this.map.fitBounds(bounds);
          // this.map.setZoom(this.server.map_zoom);
        }
      } catch (error) {
        this.handleError(error)
      }
    },

    clearHistoryInterval () {
      clearInterval(this.historyProgress.interval)
    },
    resetHistoryInterval () {
      this.$log.debug('resetHistoryInterval')
      this.historyProgress.counter = 0
      this.historyProgress.interval = setInterval(() => {
        this.historyProgress.counter++
        this.$log.debug('Oooo Yeaaa!', this.show, this.historyProgress.counter)

        if (this.historyProgress.counter === 10) {
          this.$log.debug('show prompt')
          // clearInterval(ctrInterval);
          this.confirmDetails = {
            title: 'Confirm',
            message:
              'Loading the history is taking time. Would you like to keep waiting?',
            action: {
              yes: this.$t('YES'),
              no: this.$t('NO')
            }
          }
          this.historyProgress.showConfirm = true
        }
      }, 1000)
    },

    async fetchHistory () {
      this.$log.debug('load history', this.historyFilter)

      this.show = true

      this.resetHistoryInterval()
      this.historyRouteData = null
      const response = await this.fetchObjectRouteHistory(this.historyFilter)

      this.$log.debug('fetchObjectRouteHistory', response)
      this.historyRouteData = null

      if (Object.prototype.hasOwnProperty.call(response, 'route')) {
        this.mapLayers.place.markers.clearLayers()
        this.mapLayers.place.routes.clearLayers()
        this.mapLayers.place.zones.clearLayers()

        this.mapLayers.objects.markers.clearLayers()
        this.mapLayers.objects.tails.clearLayers()

        this.historyHideRoute()
        this.historyRouteData =
          historyFunctions.transformToHistoryRoute(response)
        if (this.historyRouteData.route.length < 2) {
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'info',
            title: this.$t('INFORMATION'),
            text: this.$t('NOTHING_HAS_BEEN_FOUND_ON_YOUR_REQUEST')
          })
          this.setHistoryFilter(null).then(() => {
            this.show = false

            this.$log.debug(
              'done with loading of history',
              this.historyProgress.counter
            )
            this.clearHistoryInterval()
          })
        } else {
          const imei = this.historyFilter.imei
          this.historyRouteData.name = this.objectsNew[imei].settings.name
          this.historyRouteData.imei = imei

          // this.setHistory(this.historyRouteData);

          this.historyRouteData.play = []
          this.historyRouteData.play.status = false
          this.historyRouteData.play.position = 0

          this.$log.debug('setHistoryRouteData')
          this.loadHistory()
        }
      } else {
        this.$notify({
                    duration: 5000,
                    closeOnClick: false,
          type: 'info',
          title: this.$t('INFORMATION'),
          text: this.$t('NOTHING_HAS_BEEN_FOUND_ON_YOUR_REQUEST')
        })
        this.setHistoryFilter(null).then(() => {
          this.show = false

          this.$log.debug(
            'done with loading of history',
            this.historyProgress.counter
          )
          this.clearHistoryInterval()
        })
      }

      // })
    },
    async loadHistory () {
      try {
        // if (this.history) {

        this.$log.debug('has this.history', this.historyRouteData)
        this.historyHideRoute()
        // objectFollowAll(!1); //not yet existing function

        if (!this.historyProgress.showHistoryLayers) return false
        this.$log.debug('load this.historyRouteData.route')

        const latlngs = []

        var myRenderer = L.canvas({ padding: 0.5 })
        for (const [key, route] of Object.entries(
          this.historyRouteData.route
        )) {
          latlngs.push(L.latLng(route.lat, route.lng))

          this.historyRouteAddDataPointMarkerToMap(route, key, myRenderer)
        }

        if (!this.historyProgress.showHistoryLayers) return false

        const polylines = mapFunctions.generatePolylines({
          points: latlngs,
          color: this.user.map_rc
        })

        this.mapLayers.history.route.addLayer(polylines)

        if (!this.historyProgress.showHistoryLayers) return false
        // this.mapLayers.history.route.addTo(this.map)

        const arrows = L.polylineDecorator(polylines, {
          patterns: [
            {
              offset: 25,
              repeat: 250,
              symbol: L.Symbol.arrowHead({
                pixelSize: 14,
                headAngle: 40,
                pathOptions: {
                  fillOpacity: 1,
                  weight: 0
                }
              })
            }
          ]
        })
        this.mapLayers.history.arrows.addLayer(arrows)
        if (!this.historyProgress.showHistoryLayers) return false
        // this.mapLayers.history.arrows.addTo(this.map)

        // this.historyRouteAddStartMarkerToMap();
        // this.historyRouteAddEndMarkerToMap();

        const mapIs = this.user.map_is
        // historyRouteAddStartMarkerToMap
        const startRoute = this.historyRouteData.route[0]
        const s = {
          icon: 'img/markers/route-start.svg',
          lat: startRoute.lat,
          lng: startRoute.lng
        }
        const startMarker = mapFunctions.generateMarker(s, mapIs)
        startMarker.on('click', (e) => {
          this.historyRouteShowPoint(e, startRoute, 0)
        })

        this.mapLayers.history.utils.addLayer(startMarker)
        if (!this.historyProgress.showHistoryLayers) return false

        // historyRouteAddEndMarkerToMap
        const endRoute =
          this.historyRouteData.route[this.historyRouteData.route.length - 1]
        const e = {
          icon: 'img/markers/route-end.svg',
          lat: endRoute.lat,
          lng: endRoute.lng
        }
        const endMarker = mapFunctions.generateMarker(e, mapIs)
        endMarker.on('click', (e) => {
          this.historyRouteShowPoint(
            e,
            endRoute,
            this.historyRouteData.route.length - 1
          )
        })
        this.mapLayers.history.utils.addLayer(endMarker)
        if (!this.historyProgress.showHistoryLayers) return false

        this.historyRouteAddStopMarkerToMap()
        if (!this.historyProgress.showHistoryLayers) return false
        // this.$log.debug("historyRouteAddDataPointMarkerToMap");
        await this.historyRouteAddEventMarkerToMap()
        if (!this.historyProgress.showHistoryLayers) return false
        // this.$log.debug("historyRouteRoute");
        this.historyRouteRoute()
        if (!this.historyProgress.showHistoryLayers) return false
        this.historyRouteSnap()
        if (!this.historyProgress.showHistoryLayers) return false
        this.historyRouteDataPoints()
        if (!this.historyProgress.showHistoryLayers) return false
        this.historyRouteStops() //
        if (!this.historyProgress.showHistoryLayers) return false
        this.historyRouteEvents()
        if (!this.historyProgress.showHistoryLayers) return false

        if (this.historyProgress.showHistoryLayers) {
          this.map.fitBounds(latlngs)
        }

        this.mapLayers.history.utils.addTo(this.map)

        // clearInterval(ctrInterval);
        // this.$log.debug(ctr);

        this.setHistoryFilter(null).then(() => {
          this.show = false

          this.$log.debug(
            'done with loading of history',
            this.historyProgress.counter
          )
          this.clearHistoryInterval()
        })
      } catch (error) {
        this.handleError(error)
      }
    },
    historyHideRoute () {
      this.updateMapFollow([])

      // this.mapLayers.history = {
      this.mapLayers.history.events.clearLayers()
      this.mapLayers.history.route.clearLayers()
      this.mapLayers.history.route_snap.clearLayers()
      this.mapLayers.history.arrows.clearLayers()
      this.mapLayers.history.arrows_snap.clearLayers()
      this.mapLayers.history.stops.clearLayers()
      this.mapLayers.history.data_points.clearLayers()

      this.historyRouteStop()
      // }
      // this.mapLayers.history.clearLayers();
      // this.historyRouteData = [];
      // this.setHistoryRouteData([])
      // this.setHistory(null)
    },
    historyRouteStop () {
      // clearTimeout(timer_historyRoutePlay), //uncomment once you get to routing playback

      // this.historyRouteData.play.status = false;
      // this.historyRouteData.play.position = 0;

      if (
        this.historyRouteData !== null &&
        Object.prototype.hasOwnProperty.call(this.historyRouteData, 'play')
      ) {
        //   this.historyRouteData.play.status = false;
        // this.historyRouteData.play.position = 0;
        this.historyRouteData.play = {
          status: false,
          position: 0
        }
      }
    },
    historyRouteAddDataPointMarkerToMap (route, key, myRenderer) {
      try {
        // const route = this.historyRouteData.route[k];
        const lat = route.lat
        const lng = route.lng
        const icon = L.icon({
          iconUrl: `${this.publicPath}assets/img/markers/route-data-point.svg`,
          iconSize: [8, 8],
          iconAnchor: [4, 4],
          popupAnchor: [0, 0]
        })
        const marker = L.circleMarker([lat, lng], {
          renderer: myRenderer,
          fill: true,
          fillColor: '#f00',
          color: '#f00',
          radius: 5,
          fillOpacity: 1
        })
        marker.on('click', (e) => {
          this.historyRouteShowPoint(e, route, key)
        })
        this.mapLayers.history.data_points.addLayer(marker)
      } catch (error) {
        this.handleError(error)
      }
    },
    historyRouteDataPoints () {
      try {
        this.$log.debug('historyRouteDataPoints', this.toggles.history.data_points, this.map.getZoom())
        this.mapLayers.history.data_points.removeFrom(this.map)
        if (this.historyProgress.showHistoryLayers) {
          if (this.toggles.history.data_points) {
            this.mapLayers.history.data_points.addTo(this.map)
            if (this.map.getZoom() < 14) {
              const index = Math.floor(this.mapLayers.history.data_points.getLayers().length / 2)
              const l = this.mapLayers.history.data_points.getLayers()[index]
              this.map.setZoom(14)
              // this.map.panTo(l.getLatLng())
            }
          }
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    historyRouteStops () {
      try {
        this.$log.debug('historyRouteStops')
        this.mapLayers.history.stops.removeFrom(this.map)
        if (
          this.toggles.history.stops &&
          this.historyProgress.showHistoryLayers
        ) {
          this.mapLayers.history.stops.addTo(this.map)
        }
      } catch (error) {
        this.handleError(error)
      }
      // historyRouteStops
    },
    historyRouteEvents () {
      try {
        this.$log.debug('historyRouteEvents')
        this.mapLayers.history.events.removeFrom(this.map)
        if (
          this.toggles.history.events &&
          this.historyProgress.showHistoryLayers
        ) {
          this.mapLayers.history.events.addTo(this.map)
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    async historyRouteShowEvent (e, ev) {
      try {
        this.historyRouteRemoveDrive()
        // const ev = this.historyRouteData.events[key];
        const location = await this.getAddress({
          by: 'latlng',
          lat: ev.lat,
          lng: ev.lng
        })

        let sensorArray = Object.keys(
          this.objectsNew[this.historyRouteData.imei].settings.sensors
        ).map(
          (k) => this.objectsNew[this.historyRouteData.imei].settings.sensors[k]
        )
        sensorArray = globals.sortArrayByElement(sensorArray, 'name')
        let sensorString = ''
        sensorArray.forEach((sensor) => {
          if (sensor.popup === 'true') {
            let sensorValue = []
            if (sensor.type === 'fuelsumup') {
              sensorValue = globals.getSensorValueFuelLevelSumUp(
                this.historyRouteData.imei,
                ev.params,
                sensor
              )
            } else {
              sensorValue = globals.getSensorValue(ev.params, sensor)
            }

            sensorString +=
              '<tr><td><strong>' +
              sensor.name +
              ':</strong></td><td>' +
              sensorValue.value_full +
              '</td></tr>'
          }
        })

        const position = globals.urlPosition(ev.lat, ev.lng)

        var tableString =
          '<table><tr><td><strong>' +
          this.$t('OBJECT') +
          ':</strong></td><td>' +
          this.historyRouteData.name +
          '</td></tr><tr><td><strong>' +
          this.$t('EVENT') +
          ':</strong></td><td>' +
          ev.event_desc +
          '</td></tr><tr><td><strong>' +
          this.$t('ADDRESS') +
          ':</strong></td><td>' +
          location +
          '</td></tr><tr><td><strong>' +
          this.$t('POSITION') +
          ':</strong></td><td>' +
          position +
          '</td></tr><tr><td><strong>' +
          this.$t('ALTITUDE') +
          ':</strong></td><td>' +
          ev.altitude +
          ' ' +
          this.$t('UNIT_HEIGHT') +
          '</td></tr><tr><td><strong>' +
          this.$t('ANGLE') +
          ':</strong></td><td>' +
          ev.angle +
          ' &deg;</td></tr><tr><td><strong>' +
          this.$t('SPEED') +
          ':</strong></td><td>' +
          ev.speed +
          ' ' +
          this.$t('UNIT_SPEED') +
          '</td></tr><tr><td><strong>' +
          this.$t('TIME') +
          ':</strong></td><td>' +
          ev.dt_tracker +
          '</td></tr>'
        const odometer = this.getObjectOdometer(
          this.historyRouteData.imei,
          ev.params
        )

        if (odometer !== -1) {
          tableString +=
            '<tr><td><strong>' +
            this.$t('ODOMETER') +
            ':</strong></td><td>' +
            odometer +
            ' ' +
            this.$t('UNIT_DISTANCE') +
            '</td></tr>'
        }
        const engineHours = this.getObjectEngineHours(
          this.historyRouteData.imei,
          ev.params
        )

        if (engineHours !== -1) {
          tableString +=
            '<tr><td><strong>' +
            this.$t('ENGINE_HOURS') +
            ':</strong></td><td>' +
            engineHours +
            '</td></tr>'
        }
        tableString += sensorString += '</table>'

        const latLng = [ev.lat, ev.lng]

        L.popup({ offset: [0, -28 * this.user.map_is] })
          .setLatLng(latLng)
          .setContent(tableString)
          .openOn(this.map)
      } catch (error) {
        this.handleError(error)
      }
    },
    async historyRouteShowStop (event, stop) {
      try {
        this.historyRouteRemoveDrive()
        // const stop = this.historyRouteData.stops[key];
        const location = await this.getAddress({
          by: 'latlng',
          lat: stop.lat,
          lng: stop.lng
        })

        let sensorArray = Object.keys(
          this.objectsNew[this.historyRouteData.imei].settings.sensors
        ).map(
          (k) => this.objectsNew[this.historyRouteData.imei].settings.sensors[k]
        )
        sensorArray = globals.sortArrayByElement(sensorArray, 'name')
        let sensorString = ''
        sensorArray.forEach((sensor) => {
          if (sensor.popup === 'true') {
            let sensorValue = []
            if (sensor.type === 'fuelsumup') {
              sensorValue = globals.getSensorValueFuelLevelSumUp(
                this.historyRouteData.imei,
                stop.params,
                sensor
              )
            } else {
              sensorValue = globals.getSensorValue(stop.params, sensor)
            }

            sensorString +=
              '<tr><td><strong>' +
              sensor.name +
              ':</strong></td><td>' +
              sensorValue.value_full +
              '</td></tr>'
          }
        })
        const position = globals.urlPosition(stop.lat, stop.lng)

        var tableString =
          '<table><tr><td><strong>' +
          this.$t('OBJECT') +
          ':</strong></td><td>' +
          this.historyRouteData.name +
          '</td></tr><tr><td><strong>' +
          this.$t('ADDRESS') +
          ':</strong></td><td>' +
          location +
          '</td></tr><tr><td><strong>' +
          this.$t('POSITION') +
          ':</strong></td><td>' +
          position +
          '</td></tr><tr><td><strong>' +
          this.$t('ALTITUDE') +
          ':</strong></td><td>' +
          stop.altitude +
          ' ' +
          this.$t('UNIT_HEIGHT') +
          '</td></tr><tr><td><strong>' +
          this.$t('ANGLE') +
          ':</strong></td><td>' +
          stop.angle +
          ' &deg;</td></tr><tr><td><strong>' +
          this.$t('CAME') +
          ':</strong></td><td>' +
          stop.dt_tracker +
          '</td></tr><tr><td><strong>' +
          this.$('LEFT') +
          ':</strong></td><td>' +
          stop.dt_end +
          '</td></tr><tr><td><strong>' +
          this.$('DURATION') +
          ':</strong></td><td>' +
          stop.duration +
          '</td></tr>'
        const odometer = this.getObjectOdometer(
          this.historyRouteData.imei,
          stop.params
        )

        if (odometer !== -1) {
          tableString +=
            '<tr><td><strong>' +
            this.$t('ODOMETER') +
            ':</strong></td><td>' +
            odometer +
            ' ' +
            this.$t('UNIT_DISTANCE') +
            '</td></tr>'
        }
        const engineHours = this.getObjectEngineHours(
          this.historyRouteData.imei,
          stop.params
        )

        if (engineHours !== -1) {
          tableString +=
            '<tr><td><strong>' +
            this.$t('ENGINE_HOURS') +
            ':</strong></td><td>' +
            engineHours +
            '</td></tr>'
        }
        tableString += sensorString += '</table>'

        const latLng = [stop.lat, stop.lng]

        L.popup({ offset: [0, -28 * this.user.map_is] })
          .setLatLng(latLng)
          .setContent(tableString)
          .openOn(this.map)
      } catch (error) {
        this.handleError(error)
      }
    },
    historyRouteRemoveDrive () {
      // try {
      //   if (this.historyRouteData.layers.route_drive) {
      //     this.mapLayers.history.removeLayer(
      //       this.historyRouteData.layers.route_drive
      //     )
      //   }
      // } catch (error) {
      //   this.handleError(error)
      // }
    },
    async historyRouteShowPoint (event, route, key) {
      try {
        this.historyRouteRemoveDrive()
        // const route = this.historyRouteData.route[key];
        const location = await this.getAddress({
          by: 'latlng',
          lat: route.lat,
          lng: route.lng
        })

        let sensorArray = Object.keys(
          this.objectsNew[this.historyRouteData.imei].settings.sensors
        ).map(
          (k) => this.objectsNew[this.historyRouteData.imei].settings.sensors[k]
        )
        sensorArray = globals.sortArrayByElement(sensorArray, 'name')
        let sensorString = ''
        sensorArray.forEach((sensor) => {
          if (sensor.popup === 'true') {
            let sensorValue = []
            if (sensor.type === 'fuelsumup') {
              sensorValue = globals.getSensorValueFuelLevelSumUp(
                this.historyRouteData.imei,
                route.params,
                sensor
              )
            } else {
              sensorValue = globals.getSensorValue(route.params, sensor)
            }

            sensorString +=
              '<tr><td><strong>' +
              sensor.name +
              ':</strong></td><td>' +
              sensorValue.value_full +
              '</td></tr>'
          }
        })
        const position = globals.urlPosition(route.lat, route.lng)

        var tableString =
          '<table><tr><td><strong>' +
          this.$t('OBJECT') +
          ':</strong></td><td>' +
          this.historyRouteData.name +
          '</td></tr><tr><td><strong>' +
          this.$t('ADDRESS') +
          ':</strong></td><td>' +
          location +
          '</td></tr><tr><td><strong>' +
          this.$t('POSITION') +
          ':</strong></td><td>' +
          position +
          '</td></tr><tr><td><strong>' +
          this.$t('ALTITUDE') +
          ':</strong></td><td>' +
          route.altitude +
          ' ' +
          this.$t('UNIT_HEIGHT') +
          '</td></tr><tr><td><strong>' +
          this.$t('ANGLE') +
          ':</strong></td><td>' +
          route.angle +
          ' &deg;</td></tr><tr><td><strong>' +
          this.$t('SPEED') +
          ':</strong></td><td>' +
          route.speed +
          ' ' +
          this.$t('UNIT_SPEED') +
          '</td></tr><tr><td><strong>' +
          this.$t('TIME') +
          ':</strong></td><td>' +
          route.dt_tracker +
          '</td></tr>'

        const odometer = this.getObjectOdometer(
          this.historyRouteData.imei,
          route.params
        )

        if (odometer !== -1) {
          tableString +=
            '<tr><td><strong>' +
            this.$t('ODOMETER') +
            ':</strong></td><td>' +
            odometer +
            ' ' +
            this.$t('UNIT_DISTANCE') +
            '</td></tr>'
        }
        const engineHours = this.getObjectEngineHours(
          this.historyRouteData.imei,
          route.params
        )

        if (engineHours !== -1) {
          tableString +=
            '<tr><td><strong>' +
            this.$t('ENGINE_HOURS') +
            ':</strong></td><td>' +
            engineHours +
            '</td></tr>'
        }
        tableString += sensorString += '</table>'

        const latLng = [route.lat, route.lng]

        let offset = [0, -14 * this.user.map_is]

        if (key === 0 || this.historyRouteData.route.length - 1 === key) {
          offset = [0, -28 * this.user.map_is]
        }
        L.popup({ offset: offset })
          .setLatLng(latLng)
          .setContent(tableString)
          .openOn(this.map)

        // not yet converted
        // 1 == gsValues.map_street_view && (objectUnSelectAll(), utilsStreetView(i, o, n));
      } catch (error) {
        this.handleError(error)
      }
    },

    historyRouteRoute () {
      this.$log.debug(
        'historyRouteRoute',
        this.mapLayers.history.route.getLayers().length
          ? 'not empty route'
          : 'empty route',
        this.mapLayers.history.route_snap.getLayers().length
          ? 'not empty snap'
          : 'empty snap'
      )

      if (this.mapLayers.history.route.getLayers().length) {
        this.mapLayers.history.route.removeFrom(this.map)
      }

      if (this.mapLayers.history.route_snap.getLayers().length) {
        this.mapLayers.history.route_snap.removeFrom(this.map)
      }
      if (this.historyProgress.showHistoryLayers) {
        if (
          this.toggles.history.route ||
          this.mapLayers.history.route_snap.getLayers().length
        ) {
          if (this.toggles.history.snap) {
            this.mapLayers.history.route_snap.addTo(this.map)
            this.$log.debug('add history route snap')
          } else {
            this.mapLayers.history.route.addTo(this.map)
            this.$log.debug('add history route')
          }
        }
      }
    },
    historyRouteAddStartMarkerToMap () {
      this.$log.debug('historyRouteAddStartMarkerToMap')
      try {
        const mapIs = this.user.map_is
        // historyRouteAddStartMarkerToMap
        const route = this.historyRouteData.route[0]
        const s = {
          icon: 'img/markers/route-start.svg',
          lat: route.lat,
          lng: route.lng
        }
        const startMarker = mapFunctions.generateMarker(s, mapIs)
        startMarker.on('click', (e) => {
          this.historyRouteShowPoint(e, route, 0)
        })

        this.mapLayers.history.utils.addLayer(startMarker)
      } catch (error) {
        this.handleError(error)
      }
    },
    historyRouteAddEndMarkerToMap () {
      this.$log.debug('historyRouteAddEndMarkerToMap')
      try {
        const mapIs = this.user.map_is

        // historyRouteAddEndMarkerToMap
        const route =
          this.historyRouteData.route[this.historyRouteData.route.length - 1]
        const e = {
          icon: 'img/markers/route-end.svg',
          lat: route.lat,
          lng: route.lng
        }
        const endMarker = mapFunctions.generateMarker(e, mapIs)
        endMarker.on('click', (e) => {
          this.historyRouteShowPoint(
            e,
            route,
            this.historyRouteData.route.length - 1
          )
        })
        this.mapLayers.history.utils.addLayer(endMarker)
      } catch (error) {
        this.handleError(error)
      }
    },
    historyRouteAddStopMarkerToMap () {
      this.$log.debug('historyRouteAddStopMarkerToMap')
      try {
        const mapIs = this.user.map_is

        Object.values(this.historyRouteData.stops).forEach((stop) => {
          const lat = stop.lat
          const lng = stop.lng

          const icon = {
            icon: 'img/markers/route-stop.svg',
            lat: lat,
            lng: lng
          }
          const m = mapFunctions.generateMarker(icon, mapIs)
          m.on('click', (e) => {
            this.historyRouteShowStop(e, stop)
          })

          this.mapLayers.history.stops.addLayer(m)
        })
        // this.mapLayers.history.stops.addTo(this.map)
      } catch (error) {
        this.handleError(error)
      }
    },
    historyRouteAddEventMarkerToMap () {
      this.$log.debug('historyRouteAddEventMarkerToMap')
      try {
        const mapIs = this.user.map_is
        Object.values(this.historyRouteData.events).forEach((ev) => {
          // // console.log(ev);
          const lat = ev.lat
          const lng = ev.lng
          const icon = {
            icon: 'img/markers/route-event.svg',
            lat: lat,
            lng: lng
          }
          const m = mapFunctions.generateMarker(icon, mapIs)
          m.on('click', (e) => {
            this.historyRouteShowEvent(e, ev)
          })
          this.mapLayers.history.events.addLayer(m)
        })
        // this.mapLayers.history.events.addTo(this.map)
      } catch (error) {
        this.handleError(error)
      }
    },
    historyRouteSnap () {
      this.$log.debug('historyRouteSnap')

      if (
        Object.keys(this.historyRouteData.route).length &&
        this.historyProgress.showHistoryLayers
      ) {
        if (this.toggles.history.snap) {
          this.$log.debug(
            'this.toggles.history.snap',
            this.toggles.history.snap
          )
          if (
            !this.mapLayers.history.route_snap.getLayers().length &&
            !this.mapLayers.history.arrows_snap.getLayers().length
          ) {
            this.$log.debug('route_snap and arrow snap layers are both empty')
            let waypoints = []
            // if we're snapping, we break the waypoint list up a bit.. add every 100th item?
            var jump = Math.floor(
              Object.keys(this.historyRouteData.route).length / 99
            )
            if (jump < 1) {
              jump = 1 // eg: less than 99 points, add all of them.
            }

            for (let i = 0; i < this.historyRouteData.route.length; i += jump) {
              const route = this.historyRouteData.route[i]
              waypoints.push(L.latLng(route.lat, route.lng))
            }

            waypoints.push(
              L.latLng(
                this.historyRouteData.route[
                  Object.keys(this.historyRouteData.route).length - 1
                ].lat,
                this.historyRouteData.route[
                  Object.keys(this.historyRouteData.route).length - 1
                ].lng
              )
            )

            // let routing;
            const routingProperties = {
              waypoints: waypoints,
              show: false,
              showAlternatives: false,
              waypointMode: 'connect', // snap moves the points.
              createMarker: function () {}
            }
            if (this.server.routing_osmr_service_url !== '') {
              routingProperties.router = new L.Routing.OSRMv1({
                serviceUrl: this.server.routing_osmr_service_url,
                polylinePrecision: 5,
                geometryOnly: true
              })

              routingProperties.allowUTurn = true
              routingProperties.continueStraight = false
            }
            const routing = L.Routing.control(routingProperties).addTo(
              this.map
            )

            routing.on('routeselected', (r) => {
              this.$log.debug('routeselected', r)
              waypoints = r.route.coordinates

              this.mapLayers.history.route.removeFrom(this.map)
              this.mapLayers.history.arrows.removeFrom(this.map)

              const routeSnap = L.polyline(waypoints, {
                color: this.user.map_rc,
                opacity: 0.8,
                weight: 3
              })

              this.mapLayers.history.utils.addLayer(routeSnap)

              const arrowSnap = L.polylineDecorator(routeSnap, {
                patterns: [
                  {
                    offset: 25,
                    repeat: 250,
                    symbol: L.Symbol.arrowHead({
                      pixelSize: 14,
                      headAngle: 40,
                      pathOptions: {
                        fillOpacity: 1,
                        weight: 0
                      }
                    })
                  }
                ]
              })

              this.mapLayers.history.arrows_snap.addLayer(arrowSnap)
              this.mapLayers.history.arrows_snap.addTo(this.map)

              this.map.removeControl(routing)
              this.historyRouteArrows()
            })
          } else {
            this.historyRouteRoute()
            this.historyRouteArrows()
          }
        } else {
          this.historyRouteRoute()
          this.historyRouteArrows()
        }
      }
    },
    historyRouteArrows () {
      this.$log.debug('historyRouteArrows')
      try {
        if (this.mapLayers.history.arrows.getLayers().length) {
          this.mapLayers.history.arrows.removeFrom(this.map)
        }

        if (this.mapLayers.history.arrows_snap.getLayers().length) {
          this.mapLayers.history.arrows_snap.removeFrom(this.map)
        }

        if (
          this.toggles.history.arrows &&
          this.historyProgress.showHistoryLayers
        ) {
          if (
            this.toggles.history.snap &&
            this.mapLayers.history.arrows_snap.getLayers().length
          ) {
            this.mapLayers.history.arrows_snap.addTo(this.map)
          } else {
            this.mapLayers.history.arrows.addTo(this.map)
          }
        } else {
          this.$log.debug('remove arrows')
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    setShowObjectsModal: function (isShow, reload = false) {
      try {
        this.modal.objects = isShow
        if (reload && process.env.NODE_ENV === 'production') {
          this.initializeObjectData().catch((error) => {
            this.handleError(error)
          })
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    setPlaceMarker (val) {
      const lat = val.latlng.lat
      const lng = val.latlng.lng

      if (this.placeDraft.action && this.placeDraft.type === 'markers') {
        const fields = this.placeDraft.fields

        fields.lat = lat
        fields.lng = lng
        this.updateMapPlacesDraft({ fields: fields })
        this.loadPlaceDraft()
      }
    },
    loadPlaceDraft () {
      if (this.map.hasLayer(this.mapLayers.place_marker_draft)) {
        this.map.removeLayer(this.mapLayers.place_marker_draft)
      }

      const mapIs = this.user.map_is
      const type = this.placeDraft.type
      if (type === 'markers') {
        if (this.placeDraft.action) {
          if (
            Object.prototype.hasOwnProperty.call(this.placeDraft.fields, 'lat')
          ) {
            this.$log.debug(
              this.placeDraft.action,
              this.placeDraft.fields.image
            )
            const m = {
              icon:
                (this.placeDraft.action === 'edit'
                  ? ''
                  : 'img/markers/places/') + this.placeDraft.fields.image,
              lat: this.placeDraft.fields.lat,
              lng: this.placeDraft.fields.lng
            }

            this.mapLayers.place_marker_draft = mapFunctions.generateMarker(
              m,
              mapIs
            )
            this.mapLayers.place_marker_draft.addTo(this.map)
          }
        }
      } else if (type === 'routes' || type === 'zones') {
        if (this.placeDraft.action === 'edit') {
          let object
          if (type === 'routes') {
            const route = {
              color: this.placeDraft.fields.route_color,
              points: mapFunctions.convertPointStringToLatLng(
                this.placeDraft.fields.route_points
              ),
              name: this.placeDraft.fields.route_name
            }
            object = mapFunctions.generatePolylines(route)
          } else {
            const zone = {
              color: this.placeDraft.fields.zone_color,
              points: mapFunctions.convertPointStringToLatLng(
                this.placeDraft.fields.zone_vertices
              )
            }
            object = mapFunctions.generatePolygon(zone)
          }
          if (this.map.hasLayer(this.mapLayers.place.draw_layer)) {
            this.$log.debug('layer exists')
            this.mapLayers.place.draw_layer = object
          } else {
            this.$log.debug('layer doesnt exists')
            this.mapLayers.place.draw_layer = object
            this.map.addLayer(this.mapLayers.place.draw_layer)
          }
          this.mapLayers.place.draw_layer.enableEdit()
        }
      }
    },
    drawObject (e) {
      const layer = e.layer
      let prop = 'route_points'
      let points = layer.getLatLngs()
      if (this.placeDraft.type === 'zones') {
        prop = 'zone_vertices'
        points = layer.getLatLngs()[0]
      }

      this.$log.debug(points.length, this.placeDraft.type)
      let errorMessage = ''
      if (this.placeDraft.type === 'routes' && points.length > 200) {
        // should be 200
        errorMessage = this.$t('ROUTE_CANT_HAVE_MORE_THAN_NUM_POINTS')
      } else if (this.placeDraft.type === 'zones' && points.length > 40) {
        // should be 40
        errorMessage = this.$t('ZONE_CANT_HAVE_MORE_THAN_NUM_VERTICES')
      }
      if (errorMessage) {
        this.$notify({
                    duration: 5000,
                    closeOnClick: false,
          type: 'error',
          title: this.$t('ERROR'),
          text: errorMessage
        })
      }

      if (this.placeDraft.action === 'add') {
        if (this.map.hasLayer(this.mapLayers.place.draw_layer)) {
          this.mapLayers.place.draw_layer = layer
        } else {
          this.mapLayers.place.draw_layer = layer
          this.map.addLayer(this.mapLayers.place.draw_layer)
        }
      }
      const fields = this.placeDraft.fields

      fields[prop] = ''
      fields[prop] = mapFunctions.convertLatLngToString(points)

      this.updateMapPlacesDraft({ fields: fields })
    },
    updateToggleProperty (prop) {
      this.updateMapToggles(prop)
    },
    updateToggleHistoryProperty (prop) {
      this.updateMapHistoryToggles(prop)
    },
    mapControlPlaces (prop) {
      // if (this.toggles[prop]) {
      //   if (!this.map.hasLayer(this.mapLayers.place[prop])) {
      //     this.map.addLayer(this.mapLayers.place[prop]);
      //   }
      // } else {
      //   if (this.map.hasLayer(this.mapLayers.place[prop])) {
      //     this.map.removeLayer(this.mapLayers.place[prop]);
      //   }
      // }

      switch (prop) {
        case 'markers':
          this.loadPlaceMarkers()
          break
        case 'routes':
          this.loadPlaceRoutes()
          break
        case 'zones':
          this.loadPlaceZones()
          break
      }
    },

    mapControlObjectLabels () {
      // if (this.toggles.object_labels) {
      //   // this.mapLayers.objects.markers.eachLayer((marker) => {
      //   //   marker.openTooltip();
      //   // });
      //   for (const [imei] of Object.entries(this.objectsNew)) {
      //     const marker = this.objectsNew[imei].layers.marker;
      //     marker.openTooltip();
      //   }
      // } else {
      //   for (const [imei] of Object.entries(this.objectsNew)) {
      //     const marker = this.objectsNew[imei].layers.marker;
      //     marker.closeTooltip();
      //   }
      // }
      this.loadObjectMarkers()
    },
    mapControlObjectMarkers () {
      if (this.toggles.trackers) {
        this.loadObjectMarkers()
        // this.loadObjectTails()
      } else {
        this.mapLayers.objects.markers.clearLayers()
        this.mapLayers.objects.tails.clearLayers()
      }
    },
    mapControlClusters () {
      if (this.toggles.clusters) {
        this.mapLayers.objects.markers.options.disableClusteringAtZoom =
          this.config.map_max_zoom
      } else {
        this.mapLayers.objects.markers.options.disableClusteringAtZoom =
          this.config.map_min_zoom
      }
      this.loadObjectMarkers()
      // this.5e4w3s();
    },
    loadPlaceMarkers () {
      try {
        this.mapLayers.place.markers.clearLayers()
        if (this.toggles.markers) {
          // this.mapLayers.objects.markers.clearLayers();
          const mapIs = this.user.map_is
          this.$log.debug('load place markers')
          // Object.keys(this.placeMarkers).forEach((key) => {
          // const markers = []

          // for (const [key] of Object.entries(this.placeMarkers)) {
          for (const [key, placeMarker] of Object.entries(this.placeMarkers)) {
            // this.$log.debug('loadPlaceMarkers', key)
            if (
              placeMarker.visible &&
              placeMarker.data.visible &&
              this.placeDraft.fields.id !== key
            ) {
              this.$log.debug(placeMarker.data.icon)
              const m = {
                name: placeMarker.data.name,
                desc: placeMarker.data.desc,
                icon: placeMarker.data.icon,
                visible: placeMarker.data.visible,
                lat: placeMarker.data.lat,
                lng: placeMarker.data.lng
              }
              let marker = mapFunctions.generateMarker(m, mapIs)
              const options = {
                offset: [0, -28 * mapIs]
              }
              const content = '<b>' + m.name + '</b>'
              const position = [m.lat, m.lng]
              marker = mapFunctions.bindPopup(
                marker,
                content,
                options,
                position
              )
              this.mapLayers.place.markers.addLayer(marker)

              // this.setPlaceMarkerLayer({
              //   id: key,
              //   marker: marker,
              // })
            }
          }
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    loadPlaceRoutes () {
      try {
        this.mapLayers.place.routes.clearLayers()
        if (this.toggles.routes) {
          // Object.keys(this.placeRoutes).forEach((key) => {
          for (const [key, placeRoute] of Object.entries(this.placeRoutes)) {
            if (
              placeRoute.visible &&
              placeRoute.data.visible &&
              this.placeDraft.fields.id !== key
            ) {
              if (placeRoute.data.points) {
                const route = {
                  color: placeRoute.data.color,
                  points: mapFunctions.convertPointStringToLatLng(
                    placeRoute.data.points
                  ),
                  name: placeRoute.data.name
                }
                let line = mapFunctions.generatePolylines(route)

                if (placeRoute.data.name_visible) {
                  const options = { permanent: true, direction: 'top' }
                  const position = route.points[0]
                  const content = route.name
                  line = mapFunctions.bindTooltip(
                    line,
                    content,
                    options,
                    position
                  )
                }

                this.mapLayers.place.routes.addLayer(line)
              }
            }
          }
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    loadPlaceZones () {
      try {
        this.mapLayers.place.zones.clearLayers()
        if (this.toggles.zones) {
          // Object.keys(this.placeZones).forEach((key) => {
          this.$log.debug('load place zones', this.placeZones)
          for (const [key, placeZone] of Object.entries(this.placeZones)) {
            // this.$log.debug(key, placeZone)
            if (
              placeZone.visible &&
              placeZone.data.visible &&
              this.placeDraft.fields.id !== key
            ) {
              if (placeZone.data.vertices) {
                const zone = {
                  color: placeZone.data.color,
                  points: mapFunctions.convertPointStringToLatLng(
                    placeZone.data.vertices
                  ),
                  name: placeZone.data.name,
                  area: placeZone.data.area,
                  name_visible: placeZone.data.name_visible
                }
                let polygon = mapFunctions.generatePolygon(zone)

                if (placeZone.data.name_visible) {
                  const options = { permanent: true, direction: 'top' }
                  const position = polygon.getBounds().getCenter()
                  const content = zone.name
                  polygon = mapFunctions.bindTooltip(
                    polygon,
                    content,
                    options,
                    position
                  )
                }

                this.mapLayers.place.zones.addLayer(polygon)
              }
            }
          }

          // })
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    async loadEventDetails () {
      try {
        if (this.eventId !== null) {
          const event = await this.fetchEvent({ event_id: this.eventId })
          if (event) {
            this.$log.debug('set event', event)
            const data = {}
            const location = await this.getAddress({
              by: 'latlng',
              lat: event.lat,
              lng: event.lng
            })
            let sensorArray = Object.keys(
              this.objectsNew[event.imei].settings.sensors
            ).map((k) => this.objectsNew[event.imei].settings.sensors[k])
            sensorArray = globals.sortArrayByElement(sensorArray, 'name')
            let sensorString = ''
            sensorArray.forEach((sensor) => {
              if (sensor.popup === 'true') {
                let sensorValue = []
                if (sensor.type === 'fuelsumup') {
                  sensorValue = globals.getSensorValueFuelLevelSumUp(
                    event.imei,
                    event.params,
                    sensor
                  )
                } else {
                  sensorValue = globals.getSensorValue(event.params, sensor)
                }

                sensorString +=
                  '<tr><td><strong>' +
                  sensor.name +
                  ':</strong></td><td>' +
                  sensorValue.value_full +
                  '</td></tr>'
              }
            })

            const position = globals.urlPosition(event.lat, event.lng)

            var tableString =
              '<table><tr><td><strong>' +
              this.$t('OBJECT') +
              ':</strong></td><td>' +
              event.name +
              '</td></tr><tr><td><strong>' +
              this.$t('EVENT') +
              ':</strong></td><td>' +
              event.event_desc +
              '</td></tr><tr><td><strong>' +
              this.$t('ADDRESS') +
              ':</strong></td><td>' +
              location +
              '</td></tr><tr><td><strong>' +
              this.$t('POSITION') +
              ':</strong></td><td>' +
              position +
              '</td></tr><tr><td><strong>' +
              this.$t('ALTITUDE') +
              ':</strong></td><td>' +
              event.altitude +
              ' ' +
              this.$t('UNIT_HEIGHT') +
              '</td></tr><tr><td><strong>' +
              this.$t('ANGLE') +
              ':</strong></td><td>' +
              event.angle +
              ' &deg;</td></tr><tr><td><strong>' +
              this.$t('SPEED') +
              ':</strong></td><td>' +
              event.speed +
              ' ' +
              this.$t('UNIT_SPEED') +
              '</td></tr><tr><td><strong>' +
              this.$t('TIME') +
              ':</strong></td><td>' +
              event.dt_tracker +
              '</td></tr>'
            const odometer = this.getObjectOdometer(event.imei, event.params)

            if (odometer !== -1) {
              tableString +=
                '<tr><td><strong>' +
                this.$t('ODOMETER') +
                ':</strong></td><td>' +
                odometer +
                ' ' +
                this.$t('UNIT_DISTANCE') +
                '</td></tr>'
            }
            const engineHours = this.getObjectEngineHours(
              event.imei,
              event.params
            )

            if (engineHours !== -1) {
              tableString +=
                '<tr><td><strong>' +
                this.$t('ENGINE_HOURS') +
                ':</strong></td><td>' +
                engineHours +
                '</td></tr>'
            }
            tableString += sensorString += '</table>'
            data.latLng = [JSON.parse(event.lat), JSON.parse(event.lng)]
            data.content = tableString
            // this.event = data;

            // this.$log.debug(this.event);

            L.popup({ offset: [0, -28 * this.user.map_is] })
              .setLatLng(data.latLng)
              .setContent(data.content)
              .openOn(this.map)
              .on('remove', () => {
                this.updateEventId(null)
              })

            this.map.panTo(data.latLng)
          }
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    confirmHistoryLoad (val) {
      this.historyProgress.showConfirm = false
      this.$log.debug('confirmHistoryLoad', val)
      this.clearHistoryInterval()

      if (val) {
        this.show = true
        this.historyProgress.showHistoryLayers = true
        // location.href = '/subscribe/index.php?imei=' + this.fields.imei
        if (this.historyRouteData !== null) {
          this.$log.debug('this.historyRouteData is not null')
          this.resetHistoryInterval()

          this.mapLayers.history.events.clearLayers()
          this.mapLayers.history.route.clearLayers()
          this.mapLayers.history.route_snap.clearLayers()
          this.mapLayers.history.arrows.clearLayers()
          this.mapLayers.history.arrows_snap.clearLayers()
          this.mapLayers.history.stops.clearLayers()
          this.mapLayers.history.data_points.clearLayers()
          this.mapLayers.history.utils.clearLayers()
          setTimeout(() => {
            this.$log.debug(
              'load history after clearing layers after 2 seconds'
            )
            this.loadHistory()
          }, 2000)
        } else {
          this.fetchHistory()
        }
      } else {
        this.historyProgress.showHistoryLayers = false
        this.show = false
      }
    },
    searchPlace () {
      this.searchPopup = null
      if (this.search) {
        this.getAddress({
          by: 'address',
          address: this.search
        })
          .then((r) => {
            // unfinished fix search when doing a by address search
            this.searchPopup = {
              address: r.address,
              latlng: [r.lat, r.lng]
              // snackbar: true
            }
            const content = "<div class='container'>" +
            "<div class='row'><p class='mb-0'><b>Address: </b>" + r.address + '</p><p><b>Position: </b>' + r.lat + ', ' + r.lng + '</p>' +
            '</div>'
            L.popup({
              offset: [0, -28 * this.user.map_is],
              closeOnClick: false,
              autoClose: false
            })
              .setLatLng([r.lat, r.lng])
              .setContent(content)
              .openOn(this.map)
              .on('remove', () => {
                // this.updateEventId(null);
                this.searchPopup = null
              })

            this.map.panTo([r.lat, r.lng])
          })
          .catch((e) => this.handleError(e))
      } else {
        this.$notify({
                    duration: 5000,
                    closeOnClick: false,
          type: 'info',
          title: this.$t('INFORMATION'),
          text: 'Search address is empty'
        })
      }
    },
    async saveAsMarker () {
      const params = {
        fields: {
          group: '',
          name: 'Search: ' + this.searchPopup.address,
          description: 'Search result.',
          image: 'pin-22.svg',
          visible: true,
          lat: this.searchPopup.latlng[0],
          lng: this.searchPopup.latlng[1]
        },
        type: 'markers',
        action: 'add'
      }

      const response = await this.savePlaceObject(params)
      this.$log.debug(response)
      this.searchPopup = null
      this.map.closePopup()
      if (response.message === 'OK') {
        if (process.env.NODE_ENV === 'production') {
          this.fetchPlacesData({ cmd: 'load_marker_data' })
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'success',
            title: this.$t('INFORMATION'),
            text: this.$t('CHANGES_SAVED_SUCCESSFULLY')
          })
        }
      } else if (response.message === 'ERROR_MARKER_LIMIT') {
        this.$notify({
                    duration: 5000,
                    closeOnClick: false,
          type: 'error',
          title: this.$t('ERROR'),
          text: this.$t('MARKER_LIMIT_IS_REACHED')
        })
      }
    },
    mapMovement () {
      if (this.toggles.fit_all && !this.fitAllToggleClicked) {
        this.updateMapToggles('fit_all')
      } else {
        this.fitAllToggleClicked = false
      }
    },
    mapZoom () {
      if (this.toggles.history.data_points && this.map.getZoom() >= 14) {
        if (this.map.hasLayer(this.mapLayers.history.data_points) === false) {
          this.historyRouteDataPoints()
        }
      } else {
        this.mapLayers.history.data_points.removeFrom(this.map)
      }
    }
  },
  mounted () {
    // this.$nextTick(() => {
    // this.$refs.map.mapObject.ANY_LEAFLET_MAP_METHOD();
    // });
    this.$nextTick(() => {
      this.map = this.$refs.map.mapObject
    })
  }
}
</script>

<style></style>
