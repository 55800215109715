<template>
  <v-container>
    <validation-observer ref="observer">
      <p class="text-body-2 error--text">{{ errorMessage }}</p>
      <v-row>
        <v-col>
          <validation-provider
            v-slot="{ errors }"
            rules="required|email"
            :name="$t('EMAIL')"
            ><v-text-field
              v-model="loginFields.username"
              :error-messages="errors"
              :label="$t('EMAIL')"
              :placeholder="$t('EMAIL')"
              required
              solo
              color="grey"
              dense
              hide-details="auto"
              outlined
            ></v-text-field
          ></validation-provider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            :name="$t('PASSWORD')"
            ><v-text-field
              type="password"
              v-model="loginFields.password"
              :error-messages="errors"
              :label="$t('PASSWORD')"
              :placeholder="$t('PASSWORD')"
              required
              solo
              color="grey"
              dense
              hide-details="auto"
              outlined
            ></v-text-field
          ></validation-provider>
        </v-col>
      </v-row>
      <v-row class="">
        <v-col>
          <v-btn @click="login" color="primary" class="w-100">{{ $t('LOGIN') }}</v-btn>
          <v-btn class="w-100" plain exact to="/mobile/forgot"
            >Forgot Password?</v-btn
          >
        </v-col>
      </v-row>
    </validation-observer>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: {
    token: String
  },
  data () {
    return {
      loginFields: {
        username: '',
        password: ''
      },
      errorMessage: '',
      csrfToken: ''
    }
  },
  methods: {
    ...mapActions({
      authenticate: 'auth/authenticate',
      fetchCsrfToken: 'auth/fetchCsrfToken',
      recoverAccount: 'auth/recoverAccount'
    }),
    async login () {
      const valid = await this.$refs.observer.validate()
      if (valid) {
        this.loginFields.csrf_token = this.csrfToken
        const response = await this.authenticate(this.loginFields)
        switch (response) {
          case 'LOGIN_TRACKING':
            this.$router.push({ name: 'mobile/map' })
            break
          case 'CSRF':
            this.errorMessage =
              'There was a problem with your form, you have to reload the page and try again.'
            break
          case 'LOGIN_INVALID':
            this.errorMessage = this.$t('USERNAME_OR_PASSWORD_INCORRECT')
            break
          case 'ERROR_ACCOUNT_LOCKED':
            this.errorMessage = this.$t('THIS_ACCOUNT_IS_LOCKED')
            break
          case 'ERROR_MANY_FAILED_LOGIN_ATTEMPTS':
            this.errorMessage = this.$t('TOO_MANY_FAILED_LOGIN_ATTEMPTS')
            break
          default:
            this.errorMessage =
              'Unknown error, please check your username, password, and try clearing your cache and trying again.'
            break
        }
        this.fetchCsrfToken()
          .then((response) => {
            this.csrfToken = response
          }).catch((error) => {
            this.handleError(error)
          })
      }
    }
  },
  created () {
    this.loginFields = {
      username: '',
      password: ''
    }
    this.errorMessage = ''
    this.csrfToken = ''

    this.fetchCsrfToken()
      .then((response) => {
        this.csrfToken = response
        if (this.token) {
          this.recoverAccount({ token: this.token, csrf_token: this.csrfToken })
            .then((response) => {
              this.fetchCsrfToken()
                .then((response) => {
                  this.csrfToken = response
                }).catch((error) => {
                  this.handleError(error)
                })
              let message = { type: '', text: '', title: '' }
              switch (response) {
                case 'OK':
                  message = {
                    type: 'success',
                    text:
                      'Username and password sent. Please check your e-mail.',
                    title: 'Info'
                  }
                  break
                case 'ERROR_NOT_SENT':
                  message = {
                    type: 'error',
                    text: "Can't send e-mail. Contact administrator.",
                    title: 'Error'
                  }
                  break
                case 'ERROR_EMAIL_NOT_FOUND':
                  message = {
                    type: 'error',
                    text: 'This e-mail address is not registered.',
                    title: 'Error'
                  }
                  break
                case 'ERROR_RECOVER_EXPIRED':
                  message = {
                    type: 'error',
                    text: 'Recovery link expired.',
                    title: 'Error'
                  }
                  break
                case 'CSRF':
                  message = {
                    type: 'error',
                    text:
                      'There was a problem with your form, you have to reload the page and try again.',
                    title: 'Error'
                  }
                  break
                default:
                  message = {
                    type: 'error',
                    text: response,
                    title: 'Error'
                  }
                  break
              }
                message['duration'] = 5000;
                message['closeOnClick'] = false;
                this.$notify(message)
              // this.setMessage(message)
            })
            .catch((error) => {
              this.handleError(error)
            })
        }
      })
      .catch((e) => this.handleError(e))
  }
}
</script>

<style></style>
