import { mapGetters, mapActions } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import FormTooltip from '@/components/includes/FormTooltip.vue'
export const commandMixin = {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormTooltip
  },
  data: () => ({
    fields: {
      object: '',
      template: '',
      gateway: '',
      type: '',
      command: ''
    },
    loadingTemplates: false,
    templateList: null,
    templateItems: [
      {
        text: 'Custom',
        value: 'custom'
      }
    ]
    // options: {
    //   gateway: [
    //     { value: 'gprs', text: 'GPRS' },
    //     { value: 'sms', text: 'SMS' }
    //   ],
    //   type: [
    //     { value: 'ascii', text: 'ASCII' },
    //     { value: 'hex', text: 'HEX' }
    //   ]
    // }
  }),
  computed: {
    ...mapGetters({
      objectsNew: 'objects/objectsNew',
      cmdTemplateData: 'cmd/cmdTemplateData',
      objectItems: 'objects/objectItems'
      // templateItems: 'cmd/templateItems'
    })
    // templateItems: function () {
    //   let data = [{
    //     text: 'Custom',
    //     value: 'custom'
    //   }]

    //   if (this.fields.gateway === 'sms') {
    //     data = this.smsTemplateItems
    //   } else if (this.fields.gateway === 'gprs') {
    //     data = this.gprsTemplateItems
    //   }
    //   return data;
    // },
  },
  async created () {
    try {
      // this.getCmdTemplateData().catch(error => {
      //   this.handleError(error)
      // })

      this.$log.debug('passed params', this.$route.params.imei)
      // // console.log('templateItems', this.templateItems(this.fields.gateway));

      this.fields = {
        object: '',
        template: '',
        gateway: '',
        type: '',
        command: ''
      }

      if (this.$route.params.imei) {
        this.fields.object = this.$route.params.imei
      } else {
        if (this.objectItems.length) {
          this.fields.object = this.objectItems[0].value
        }
      }
      this.setTemplateItems()
    } catch (error) {
      this.handleError(error)
    }
  },
  watch: {
    objectItems: function () {
      if (!this.fields.object) {
        if (this.objectItems.length) {
          this.fields.object = this.objectItems[0].value
          this.setTemplateItems()
        }
      }
    },
    templateList: function () {
      let data = []

      Object.keys(this.templateList).forEach(id => {
        const template = this.templateList[id]
        data.push({
          text: template.name,
          value: id
        })
      })
      data = data.sort((a, b) =>
        a.text > b.text ? 1 : b.text > a.text ? -1 : 0
      )

      data.unshift({
        text: 'Custom',
        value: 'custom'
      })

      this.templateItems = data

      this.loadingTemplates = false
    }
  },
  methods: {
    ...mapActions({
      getCmdTemplateData: 'cmd/getCmdTemplateData',
      sendCommand: 'cmd/sendCommand',
      getCmdData: 'cmd/getCmdData'
    }),
    async setTemplateItems () {
      if (this.fields.object) {
        this.loadingTemplates = true
        this.fields.template = ''
        this.getCmdTemplateData({ imei: this.fields.object })
          .then(data => {
            this.templateList = data
          })
          .catch(error => {
            this.handleError(error)
          })
      }
    },
    setCommand () {
      try {
        if (this.fields.template !== 'custom') {
          // console.log(this.fields.template, this.cmdTemplateData);
          const cmd = this.cmdTemplateData[this.fields.template]
          // console.log(cmd);
          if (cmd) {
            this.fields.command = cmd.cmd
            this.fields.gateway = cmd.gateway.toLowerCase()
            this.fields.type = cmd.type
          }
        } else {
          // this.fields.command = ''
          // this.fields.type = ''
        }
      } catch (error) {
        this.handleError(error)
      }
    },
    setTemplateToCustom () {
      // console.log("set to custom");
      this.fields.template = 'custom'
    },
    async save () {
      try {
        if (this.checkPrivilege('viewer')) {
          // gprs
          // validations - command cant be empty COMMAND_CANT_BE_EMPTY
          // if hex - check hex format from isHexValid COMMAND_HEX_NOT_VALID

          // sms
          // validations - command cant be empty COMMAND_CANT_BE_EMPTY
          // OBJECT_SIM_CARD_NUMBER_IS_NOT_SET

          let valid = await this.$refs.observer.validate()
          // this.fields.name = this.fields.name ? this.fields.name : "New Object";

          if (
            valid &&
            this.fields.gateway === 'sms' &&
            !this.objectsNew[this.fields.object].settings.sim_number
          ) {
            valid = false
            this.$notify({
                    duration: 5000,
                    closeOnClick: false,
              type: 'error',
              title: this.$t('ERROR'),
              content: this.$t('OBJECT_SIM_CARD_NUMBER_IS_NOT_SET')
            })
          }

          if (valid) {
            const name =
              this.fields.template === 'custom'
                ? 'Custom'
                : this.cmdTemplateData[this.fields.template].name

            const params = {
              cmd: 'exec_cmd_' + this.fields.gateway,
              imei: this.fields.object,
              name: name,
              cmd_: this.fields.command
            }

            if (this.fields.gateway === 'gprs') {
              params.type = this.fields.type
              params.cmd_ = this.fields.command
              // this.fields.type === "hex"
              //   ? this.fields.command.toUpperCase()
              //   : this.fields.command;
            }

            this.$log.debug(params)

            this.sendCommand(params)
              .then(response => {
                switch (response.message) {
                  case 'OK':
                    this.$notify({
                    duration: 5000,
                    closeOnClick: false,
                      type: 'success',
                      title: this.$t('INFORMATION'),
                      content: this.$t('COMMAND_SENT_FOR_EXECUTION')
                    })

                    if (process.env.NODE_ENV === 'production') {
                      this.getCmdData({ gateway: this.fields.gateway })
                    }
                    this.fields = {
                      object: '',
                      template: '',
                      gateway: '',
                      type: '',
                      command: ''
                    }

                    this.$refs.observer.reset()
                    if (this.$route.name === 'devices') {
                      this.closeDialog()
                    }

                    break
                  case 'ERROR_NOT_SENT':
                    this.$notify({
                    duration: 5000,
                    closeOnClick: false,
                      type: 'error',
                      title: this.$t('ERROR'),
                      content: this.$t('UNABLE_TO_SEND_SMS_MESSAGE')
                    })
                    break
                  default:
                    this.$notify({
                    duration: 5000,
                    closeOnClick: false,
                      type: 'error',
                      title: this.$t('ERROR'),
                      content:
                        'Something went wrong! Please contact system administrator.'
                    })
                    break
                }
              })
              .catch(error => {
                this.handleError(error)
              })
          }
        } else {
          this.$notify({
                    duration: 5000,
                    closeOnClick: false,
            type: 'error',
            title: this.$t('ERROR'),
            content: this.$t('THIS_ACCOUNT_HAS_NO_PRIVILEGES_TO_DO_THAT')
          })
        }
      } catch (error) {
        this.handleError(error)
      }
    }
  }
}
